import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import moment from 'moment';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

import Message from './message';
import PaymentForm from './subscribe/payment-form';

const PAYMENT_METHOD_PAYPAL = 'PAYPAL';

export default class UpdatePaymentDetails extends React.Component {
    renderSuccess() {
        if (!this.props.success || this.props.isLoading) {
            return null;
        }

        return <Message type="good" value="Your payment details have been successfully updated." />;
    }

    renderError() {
        if (!this.props.error || this.props.success || this.props.isLoading) {
            return null;
        }

        return <Message type="fail" value={this.props.error} />;
    }

    renderPaymentInfo() {
        if (!this.props.paymentDetails) {
            return null;
        }

        const {cardType, paymentExpiry, paymentInfo, paymentType} = this.props.paymentDetails;

        if (paymentType === PAYMENT_METHOD_PAYPAL) {
            return (
                <dl
                    className="
                        fiso-hawk-update-payment-details__list
                        fiso-hawk-update-payment-details__list--paypal">
                    <dt className="fiso-hawk-update-payment-details__list-heading">PayPal account:</dt>
                    <dd className="fiso-hawk-update-payment-details__list-detail">
                        {paymentInfo}
                    </dd>
                </dl>
            );
        }

        return (
            <dl className="fiso-hawk-update-payment-details__list">
                <dt className="fiso-hawk-update-payment-details__list-heading">
                    {cardType} ending in:
                </dt>
                <dd className="fiso-hawk-update-payment-details__list-detail">
                    {`**** **** **** ${paymentInfo}`}
                </dd>
                <dt className="fiso-hawk-update-payment-details__list-heading">Expiry date:</dt>
                <dd className="fiso-hawk-update-payment-details__list-detail">
                    {/* because vimond provides us a unix timestamp, and users in GMT -<anything>
                        were seeing their expiry date as a previous month, so UTC baseline the timestamp */}
                    {moment.utc(paymentExpiry).format('MM/YYYY')}
                </dd>
            </dl>
        );
    }

    renderActiveOrder() {
        if (!this.props.activeOrder && !this.props.isLoading) {
            return <Message type="fail" value="You have no active subscription." />;
        }

        return (
            <div className="fiso-hawk-update-payment-details__active-order">
                {this.renderPaymentInfo()}
            </div>
        );
    }

    renderForm() {
        if (this.props.disablePaymentUpdate) {
            return (
                <p>
                    If you need to update or change your payment details please
                    contact <a href={`mailto:watch${this.props.sportDetails.route}@foxsports.com.au`}>{`watch${this.props.sportDetails.route}@foxsports.com.au`}</a>
                </p>
            );
        }

        const {isLoading} = this.props;
        const loadingSpinnerWrapperClasses = classNames(
            'fiso-hawk-update-payment-details__spinner-container',
            {
                'fiso-hawk-update-payment-details__spinner-container--hidden': !isLoading
            }
        );

        const paymentFormWrapperClasses = classNames(
            'fiso-hawk-update-payment-details__payment-form-wrapper',
            {
                'fiso-hawk-update-payment-details__payment-form-wrapper--hidden': isLoading
            }
        );

        return (
            <div className="fiso-hawk-update-payment-details__payment-form">
                <div className={loadingSpinnerWrapperClasses}>
                    <LoadingSpinner />
                </div>
                <div className={paymentFormWrapperClasses}>
                    <PaymentForm
                        brainTreeClientInstance={this.props.brainTreeClientInstance}
                        user={this.props.user}
                        error={this.props.error}
                        isLoading={this.props.isLoading}
                        sportDetails={this.props.sportDetails}
                        isEnabled={true}
                        updateSuccess={this.props.success}
                        paymentDetails={this.props.paymentDetails}
                        onSubmitCreditCardSubscription={this.props.onSubmitCreditCardSubscription}
                        onSubmitPaypalSubscription={this.props.onSubmitPaypalSubscription}
                        submitButtonText="Update" />
                </div>
            </div>
        );
    }

    render() {
        const cancelButtonClass = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            'fiso-hawk-button--chevron-left',
            'fiso-hawk-update-payment-details__back-button',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        return (
            <div className="fsui-normalise fiso-hawk-update-payment-details">
                <div className="fiso-hawk-update-payment-details__container">
                    <h1 className="fiso-hawk-update-payment-details__heading-1">Your Payment Details</h1>
                    {this.renderSuccess()}
                    {this.renderError()}
                    {this.renderActiveOrder()}
                    {this.renderForm()}
                    <a
                        href={this.props.profilePageLink}
                        title="Cancel and go to profile page"
                        className={cancelButtonClass}>
                        Go Back
                        <Fsvg name="back-1" />
                    </a>
                </div>
            </div>
        );
    }
}

UpdatePaymentDetails.defaultProps = {
    onSubmitCreditCardSubscription: noop,
    onSubmitPaypalSubscription: noop
};

UpdatePaymentDetails.propTypes = {
    disablePaymentUpdate: propTypes.bool,
    brainTreeClientInstance: propTypes.object,
    activeOrder: propTypes.object,
    user: propTypes.object,
    paymentDetails: propTypes.shape({
        cardType: propTypes.string,
        paymentExpiry: propTypes.oneOfType([
            propTypes.number,
            propTypes.string
        ]),
        paymentInfo: propTypes.oneOfType([
            propTypes.number,
            propTypes.string
        ]),
        paymentType: propTypes.string
    }),
    profilePageLink: propTypes.string,
    isLoading: propTypes.bool,
    error: propTypes.node,
    success: propTypes.bool,
    onSubmitCreditCardSubscription: propTypes.func,
    onSubmitPaypalSubscription: propTypes.func,
    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
