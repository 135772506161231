import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import has from 'lodash/has';
import get from 'lodash/get';

export default class ProductTile extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            hovering: false
        };
    }

    renderPreviousPrice(price) {
        return (
            <div className="fiso-hawk-product-tile__price-previous">
                <span className="fiso-hawk-product-tile__currency">
                    {this.props.product.currency}
                </span>
                <span className="fiso-hawk-product-tile__price-previous-value">
                    {this.props.currencySymbol}
                    {price.toFixed(2)}
                </span>
            </div>
        );
    }

    renderCurrentPrice(price, discountedPrice, hasDiscount) {
        const currentPrice = hasDiscount ? discountedPrice : price;

        return (
            <div
                className={classNames(
                    'fiso-hawk-product-tile__price-current',
                    {'fiso-hawk-product-tile__price-current--discount': hasDiscount})}>
                <span className="fiso-hawk-product-tile__currency">
                    {this.props.product.currency}
                </span>
                <span className="fiso-hawk-product-tile__price">
                    {this.props.currencySymbol}
                    {currentPrice.toFixed(2)}
                </span>
            </div>
        );
    }

    renderProductTile(price, discountedPrice, hasDiscount) {
        const name = get(this.props.product, 'paymentPlan.name', '');

        return (
            <div
                className={classNames(
                    'fiso-hawk-product-tile',
                    'fiso-hawk-product-tile--large',
                    {'fiso-hawk-product-tile--discount': hasDiscount}
                )}>
                {hasDiscount && (
                    <div className="fiso-hawk-product-tile__discounted-text">
                        Discount applied!
                    </div>
                )}
                <div className="fiso-hawk-product-tile__name">
                    {name}
                </div>
                <div className="fiso-hawk-product-tile__prices-container">
                    {hasDiscount && [
                        <div className="fiso-hawk-product-tile__indicator-was">Was</div>,
                        <div className="fiso-hawk-product-tile__indicator-now">Now</div>
                    ]}
                    {hasDiscount && this.renderPreviousPrice(price)}
                    {this.renderCurrentPrice(price, discountedPrice, hasDiscount)}
                </div>
            </div>
        );
    }

    setHovering(isHovering) {
        this.setState({hovering: isHovering});
    }

    renderProductTileWithJoinButton(price, discountedPrice, hasDiscount, isHovering, setHovering) {
        const joinURL = `/subscribe?productGroupId=${this.props.productGroupId}` +
                        `&chosenProductId=${this.props.product.id}` +
                        (this.props.voucher ? `&voucher=${this.props.voucher}` : '');

        const currentPrice = hasDiscount ? discountedPrice : price;
        const name =  get(this.props.product, 'paymentPlan.name', '');

        return (
            // Disable jsx-a11y/interactive-supports-focus for the line below because of the magic we weave around the <a> tag
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            <div
                className={classNames(
                    'fiso-hawk-product-tile',
                    // {'fiso-hawk-product-tile--popular': this.props.popularProduct},
                    {'fiso-hawk-product-tile--discount': hasDiscount}
                )}
                onMouseOver={() => setHovering(true)}
                onMouseOut={() => setHovering(false)}
                onBlur={() => setHovering(false)}
                onFocus={() => setHovering(false)}
                role="button">
                {/* {this.props.popularProduct && (
                    <div className="fiso-hawk-product-tile__popular">
                        Popular
                    </div>
                )} */}
                <div className="fiso-hawk-product-tile__container">
                    <div className="fiso-hawk-product-tile__details">
                        <div className="fiso-hawk-product-tile__name">
                            {name}
                        </div>
                        {hasDiscount && (
                            <div className="fiso-hawk-product-tile__price-previous">
                                <span className="fiso-hawk-product-tile__price-previous-value">
                                    {this.props.currencySymbol}
                                    {price.toFixed(2)}
                                </span>
                            </div>
                        )}
                        <div
                            className={classNames(
                                'fiso-hawk-product-tile__price-current',
                                {'fiso-hawk-product-tile__price-current--discount': hasDiscount})}>
                            <span className="fiso-hawk-product-tile__currency">{this.props.product.currency}</span>
                            <span className="fiso-hawk-product-tile__price">
                                {this.props.currencySymbol}
                                {currentPrice.toFixed(2)}
                            </span>
                        </div>
                    </div>
                    <div className="fiso-hawk-product-tile__btn-container">
                        <a
                            href={joinURL}
                            className={classNames(
                                'fiso-hawk-product-tile__button',
                                'fiso-hawk-button',
                                'fiso-hawk-button--primary',
                                'fiso-hawk-button--full-width',
                                `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`
                            )}>
                            Subscribe
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (!has(this.props.product, 'productPayments[0]')) {
            return null;
        }

        // MW-385: the discount could be a forevermore discount (on recurringDiscountedPrice)
        // or a once-off discount (on discountedPrice) and we need to show the
        // recurringPrice or initPrice depending on if initPrice === 0 (free trial period) for the "was" value
        // and recurringDiscountedPrice or discountedPrice as the "new price"
        const {price} = this.props.product;
        const recurPrice = get(this.props.product.productPayments, '[0].recurringPrice', price);
        const recurDiscPrice = get(this.props.product.productPayments, '[0].recurringDiscountedPrice', price);
        const initDiscPrice = get(this.props.product.productPayments, '[0].discountedPrice', price);
        const initPrice = get(this.props.product.productPayments, '[0].initPrice', price);

        const isFreeTrial = initPrice === 0;
        const hasDiscount = recurPrice > recurDiscPrice || (initPrice !== 0 && initPrice > initDiscPrice);

        let oldPrice = price;
        let newPrice = price;

        if (hasDiscount && !isFreeTrial) {
            // not a free trial, and if one-off voucher, use initial/initialDiscounted price values, since recurring price values are full price
            oldPrice = initPrice;
            newPrice = initDiscPrice;
        } else if (hasDiscount && isFreeTrial) {
            // free trial means initial/initialDiscounted price values are both 0, but the voucher is a recurring kind, so use recurring/recurringDiscount prices
            oldPrice = recurPrice;
            newPrice = recurDiscPrice;
        }

        if (this.props.withJoinButton) {
            return this.renderProductTileWithJoinButton(
                oldPrice,
                newPrice,
                hasDiscount,
                this.state.hovering,
                this.setHovering.bind(this)
            );
        } else {
            return this.renderProductTile(oldPrice, newPrice, hasDiscount);
        }
    }
}

ProductTile.defaultProps = {
    currencySymbol: '$',
    withJoinButton: false,
    product: {
        productPayments: []
    }
};

ProductTile.propTypes = {
    currencySymbol: propTypes.string,
    // popularProduct: propTypes.bool,
    product: propTypes.shape({
        currency: propTypes.string,
        id: propTypes.number,
        paymentPlan: propTypes.shape({
            name: propTypes.string
        }),
        price: propTypes.number,
        productPayments: propTypes.arrayOf(
            propTypes.shape({
                description: propTypes.string,
                discountedPrice: propTypes.number,
                recurringDiscountedPrice: propTypes.number,
                recurringPrice: propTypes.number
            })
        )
    }),
    productGroupId: propTypes.number,
    voucher: propTypes.string,
    withJoinButton: propTypes.bool,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
