import result from 'lodash/result';

export default function getFootballSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'a-league':
        case '1':
            return {
                id: 1,
                label: 'A-League',
                route: 'a-league'
            };

        case 'international-friendlies':
        case '2':
            return {
                id: 2,
                label: 'International Friendlies',
                route: 'international-friendlies'
            };

        case 'asian-champions-league':
        case '4':
            return {
                id: 4,
                label: 'Asian Champions League',
                route: 'asian-champions-league'
            };

        case 'european-championships':
        case '5':
            return {
                id: 5,
                label: 'European Championships',
                route: 'european-championships'
            };

        case 'premier-league':
        case '6':
            return {
                id: 6,
                label: 'Premier League',
                route: 'premier-league'
            };

        case 'w-league':
        case '9':
            return {
                id: 9,
                label: 'W-League',
                route: 'w-league'
            };

        case 'world-cup':
        case '10':
            return {
                id: 10,
                label: 'World Cup',
                route: 'world-cup'
            };

        case 'world-cup-qualifiers':
        case '11':
            return {
                id: 11,
                label: 'World Cup Qualifiers',
                route: 'world-cup-qualifiers'
            };

        case 'asian-cup':
        case '13':
            return {
                id: 13,
                label: 'Asian Cup',
                route: 'asian-cup'
            };

        case 'confederations-cup':
        case '15':
            return {
                id: 15,
                label: 'Confederations Cup',
                route: 'confederations-cup'
            };

        case 'womens-international-friendlies':
        case '17' :
            return {
                id: 17,
                label: 'Women\'s International Friendlies',
                route: 'womens-international-friendlies'
            };

        case 'friendlies':
        case '18' :
            return {
                id: 18,
                label: 'Friendlies',
                route: 'friendlies'
            };

        case 'acl-qualifiers':
        case '20' :
            return {
                id: 20,
                label: 'Asian Champions League Qualifiers',
                route: 'acl-qualifiers'
            };

        case 'bundesliga':
        case '21' :
            return {
                id: 21,
                label: 'Bundesliga',
                route: 'bundesliga'
            };

        case 'la-liga':
        case '22':
            return {
                id: 22,
                label: 'La Liga',
                route: 'la-liga'
            };

        case 'serie-a':
        case '23':
            return {
                id: 23,
                label: 'Serie A',
                route: 'serie-a'
            };

        case 'ffa-cup':
        case '27':
            return {
                id: 27,
                label: 'FFA Cup',
                route: 'ffa-cup'
            };

        case 'club-world-cup':
        case '29' :
            return {
                id: 29,
                label: 'Club World Cup',
                route: 'club-world-cup'
            };

        case 'uefa-champions-league':
        case '30':
            return {
                id: 30,
                label: 'UEFA Champions League',
                route: 'uefa-champions-league'
            };

        case 'fa-cup':
        case '31':
            return {
                id: 31,
                label: 'FA Cup',
                route: 'fa-cup'
            };

        case 'algarve-cup':
        case '32':
            return {
                id: 32,
                label: 'Algarve Cup',
                route: 'algarve-cup'
            };

        case 'europa-league':
        case '33':
            return {
                id: 33,
                label: 'Europa League',
                route: 'europa-league'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised football seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
