import {getMatchStatus} from '@fsa/fs-commons/lib/utils/match-status';
import get from 'lodash/get';

import {CONTENT_URL} from '../../utils/constants';
import {hasMatchVideo} from '../match-video';

export function getMassagedMatchDetailsData(fixtureResults = {}, config) {
    const matchStatus = getMatchStatus(fixtureResults.match_status);
    const teamStartCardUrl = `${CONTENT_URL}/${fixtureResults.sport}/2017/teams/1280/`;
    const hasVideoPlayer = hasMatchVideo(fixtureResults);

    const seriesId = get(fixtureResults, 'series.id');
    const seasonId = get(fixtureResults, 'season.id');
    const videoCarouselSettings = JSON.parse(get(config, `videoCarouselSettings${matchStatus === 'post' ? 'Post' : 'Pre'}Match`, '[]'))
        .map((settings) => Object.assign({}, settings, {seriesId, seasonId}));

    const homeTeam = {
        name: get(fixtureResults, 'team_A.name', ''),
        imageSource: teamStartCardUrl + get(fixtureResults, 'team_A.id') + '_1280_home.png',
        fallbackImageSource: teamStartCardUrl + 'null_1280_home.png'
    };
    const awayTeam = {
        name: get(fixtureResults, 'team_B.name', ''),
        imageSource: teamStartCardUrl + get(fixtureResults, 'team_B.id') + '_1280_away.png',
        fallbackImageSource: teamStartCardUrl + 'null_1280_away.png'
    };
    const title = (homeTeam.name && awayTeam.name) ? `${homeTeam.name} Vs ${awayTeam.name}` : '';
    const location = `${get(fixtureResults, 'venue.name', '')} ${get(fixtureResults, 'venue.city', '')}`;
    const description = `${title} at ${location}`;
    const preMatchTime = get(fixtureResults, 'videos[0].liveBroadcastTime');
    const matchTime = get(fixtureResults, 'match_start_date');
    const matchEndTime = get(fixtureResults, 'match_end_date');

    const timerReminder = {
        hasWideButton: !hasVideoPlayer,
        preMatchTime,
        matchTime,
        matchReminder: {
            description,
            endTime: matchEndTime,
            location,
            organizer: 'Watch AFL',
            startTime: matchTime,
            title
        },
        preMatchReminder: {
            description,
            endTime: matchEndTime,
            location,
            organizer: 'Watch AFL',
            startTime: preMatchTime,
            title
        }
    };

    return {
        fixtureResults,
        hasVideoPlayer,
        matchStatus,
        title,
        teamStartCard: {
            homeTeam,
            awayTeam
        },
        timerReminder,
        videoCarouselSettings: JSON.stringify(videoCarouselSettings)
    };
}

export default getMassagedMatchDetailsData;
