import React from 'react';
import {type DefaultTheme, ThemeProvider} from 'styled-components';

import {ChromecastSenderContext} from '../context';
import type ChromecastSenderState from '../state.js';
import ObservedStateWrapper from './observed-state-wrapper';

export type Props = {
    state: ChromecastSenderState;
    theme: DefaultTheme;
};

export default function StreamotionChromecastSenderComponent({
    state,
    theme,
}: Props): React.ReactElement {
    return (
        <ThemeProvider theme={theme}>
            <ChromecastSenderContext.Provider value={state}>
                <ObservedStateWrapper />
            </ChromecastSenderContext.Provider>
        </ThemeProvider>
    );
}

StreamotionChromecastSenderComponent.displayName =
    'StreamotionChromecastSenderComponent';
