/**
 * When isNaN() is just too confusing
 *
 * note: Trim your strings beforehand - any non numeric characters in numberToCheck will cause a failure
 *
 * Only accepts numbers (including decimals).
 * No funny business like Infinite, NaN or scientific notation allowed.
 * Negative numbers allowed, as are decimals.
 * Leading or trailing zeroes are ok.
 * Doesn't try to parse your number, just lets you know if it is one
 *
 * Based on https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/parseFloat#A_stricter_parse_function
 *
 * @param  {Mixed}      numberToCheck   Value to determine numberiness of.
 *
 * @return {Boolean}                    true if numberToCheck is a simple number. False otherwise.
 */
exports.isReallyNaN = function (numberToCheck) {
    try {
        return !(/^(\-|\+)?([0-9]+(\.[0-9]+)?)$/.test(numberToCheck));
    } catch (e) {
        return true;
    }
};

/**
 * Normalise a bool or string to a bool
 * All other types will return defaultVal
 *
 * @param  {Mixed}              val         Value to normalise (e.g. string or bool)
 * @param  {Boolean}            defaultVal  Default value (e.g. true or false)
 *
 * @return {Boolean|Undefined}              Return providede defaultVal (or undefined)
 */
exports.asBool = function (val, defaultVal) {
    try {
        if (val === 'false' || val === false) {
            return false;
        } else if (val === 'true' || val === true) {
            return true;
        } else {
            return defaultVal;
        }
    } catch (e) {
        console.warn('FS-Commons: couldn\'t normalise arg asBool', val, e.stack);

        return defaultVal;
    }
};

/**
 * Normalise a string or number to a string
 * All other types will return defaultVal
 *
 * @param  {Mixed}             val          Value to normalise (e.g. string or number)
 * @param  {String}            defaultVal   Default value (e.g. 'some default string')
 *
 * @return {String|Undefined}               Return providede defaultVal (or undefined)
 */
exports.asString = function (val, defaultVal) {
    try {
        if (typeof val === 'string') {
            return val;
        } else if (typeof val === 'number') {
            return val.toString();
        } else {
            return defaultVal;
        }
    } catch (e) {
        console.warn('FS-Commons: couldn\'t normalise arg asString', val, e.stack);

        return defaultVal;
    }
};

/**
 * Normalise a number or string to a number
 * All other types will return defaultVal
 *
 * @param  {Mixed}              val         Value to normalise (e.g. string or number)
 * @param  {Number}             defaultVal  Default value (e.g. 0)
 *
 * @return {Number|Undefined}               Return providede defaultVal (or undefined)
 */
exports.asNumber = function (val, defaultVal) {
    try {
        if (typeof val === 'number') {
            return val;
        } else if (typeof val === 'string' && !exports.isReallyNaN(val)) {
            return parseFloat(val);
        } else {
            return defaultVal;
        }
    } catch (e) {
        console.warn('FS-Commons: couldn\'t normalise arg asNumber', val, e.stack);

        return defaultVal;
    }
};

/**
 * Normalise an array or pipe|separated|string to an array.
 *
 * IMPORTANT: Empty string '' will return the defaultVal
 *
 * All other types, including number, will return defaultVal
 *
 * @param  {Mixed}              val         Value to normalise (e.g. string or array)
 * @param  {Array}              defaultVal  Default value (e.g. [1|2|3])
 * @return {Array|Undefined}                Return providede defaultVal (or undefined)
 */
exports.asArray = function (val, defaultVal) {
    try {
        if (Array.isArray(val)) {
            return val;
        } else if (typeof val === 'string' && val !== '') {
            return val.split('|');
        } else {
            return defaultVal;
        }
    } catch (e) {
        console.warn('FS-Commons: couldn\t normalise arg asArray', val, e.stack);

        return defaultVal;
    }
};

/**
 * Normalise a function. If not a function, will return defaultVal
 *
 * @param  {Mixed}              val         Value to normalise (e.g. function)
 * @param  {Function}           defaultVal  Default value (e.g. noop)
 * @return {Function|Undefined}             Return providede defaultVal (or undefined)
 */
exports.asFunction = function (val, defaultVal) {
    try {
        if (typeof val === 'function') {
            return val;
        } else {
            return defaultVal;
        }
    } catch (e) {
        console.warn('FS-Commons: couldn\'t normalise arg asFunction', val, e.stack);

        return defaultVal;
    }
};
