import {type ForwardedRef, useCallback} from 'react';

import setRefValue from './set-ref-value';

type CallbackRef<T> = (value: T | null) => void;

/**
 * Return a callback ref that updates all the provided refs when it receives a new value.
 *
 * NOTE: The number of target refs must be the same every time this is called in the same component.
 *
 * @param targetRefs - a stable array of refs
 * @returns a callback ref that updates the provided refs
 */
export default function useMultiRef<T>(
    ...targetRefs: ForwardedRef<T>[]
): CallbackRef<T> {
    return useCallback(function updateTargetRefs(value: T | null) {
        targetRefs.forEach((targetRef) => setRefValue(targetRef, value));
    }, targetRefs); // eslint-disable-line react-hooks/exhaustive-deps
}
