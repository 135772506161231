import bacon from 'baconjs';
import uaParser from 'ua-parser-js';
import get from 'lodash/get';

import {getLocalStorageValue, setLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';
import Response from '@fsa/fs-commons/lib/request-manager/response';

import {MULTITENANCY_HEADER, AUTHENTICATION_LOCAL_STORAGE_KEY, DEVICE_UDID_LOCAL_STORAGE_KEY, DEVICE_LABEL_STORAGE_KEY} from './constants';

export function streamFromApi(request) {
    return bacon.fromBinder((sink) => {
        request
            .end((error, response) => {
                const resObj = new Response(error, response);

                if (resObj.responseConsideredValid) {
                    sink(response);
                    sink(new bacon.End());
                } else {
                    sink(new bacon.Error(error));
                }
            });

        return () => {
            request.abort();
        };
    });
}

export function streamFromApiWithRetry({
    apiCall,
    retries = 3,
    delay = 1000,
    isRetryable = (error) => error.status !== 404
}) {
    return bacon.retry({
        source: () => (streamFromApi(apiCall())),
        retries,
        isRetryable,
        delay: () => delay
    });
}

export function getVimondHeaders({
    authToken,
    sport = 'afl',
    version
}) {
    const apiVersion = version ? `v=${version}` : '';

    const header = Object
        .assign(
            {},
            MULTITENANCY_HEADER[sport],
            {
                'Content-Type': `application/json;${apiVersion}`,
                'Accept': `application/json;${apiVersion}`
            }
        );

    return authToken ? Object.assign({}, {authorization: authToken}, header) : header;
}

export function authorizationToken() {
    return getLocalStorageValue({key: AUTHENTICATION_LOCAL_STORAGE_KEY, defaultValue: undefined});
}

/**
 * Retreive the Browser Viewer ID from local storage.
 * If it doesnt exist, generate & set it
 *
 * @param  {string} storageKey Unique ID For local storage key
 * @return {string} Browser    UUID
 */
export function getDeviceUdid(storageKey = DEVICE_UDID_LOCAL_STORAGE_KEY) {
    let browserViewerId = getLocalStorageValue({
        key: storageKey
    });

    if (!browserViewerId) {
        browserViewerId = {
            // Generate a uniquish key http: //stackoverflow.com/a/2117523
            uuid: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                /* eslint-disable */
                let r = Math.random() * 16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
                /* eslint-enable */
            }),
            // Timestamp just in case we want to manually expire in the future
            timestamp: Date.now()
        };

        setLocalStorageValue({
            key:   storageKey,
            value: browserViewerId
        });
    }

    return browserViewerId.uuid;
}

/**
 * Retreive the Device Label from local storage.
 * If it doesnt exist, generate & set it
 *
 * @param  {string} storageKey Unique ID For local storage key
 * @return {string} deviceLabel
 */
export function getDeviceLabel(storageKey = DEVICE_LABEL_STORAGE_KEY) {
    if (!process || !process.browser) {
        return 'fiso';
    }

    let userDeviceInfo;
    let browserInfo;
    let browserLabel = getLocalStorageValue({
        key: storageKey
    });

    if (!browserLabel) {
        userDeviceInfo = uaParser(window.navigator.userAgent);

        browserInfo = [
            get(userDeviceInfo, 'browser.name'),
            get(userDeviceInfo, 'device.model'),
            get(userDeviceInfo, 'os.name'),
            get(userDeviceInfo, 'os.version')
        ]
            .filter((item) => item);

        browserLabel = browserInfo.join(' ') || 'Device';

        setLocalStorageValue({
            key:   storageKey,
            value: browserLabel
        });
    }

    return browserLabel;
}
