import superagent from 'superagent';

import {streamFromApiWithRetry, getVimondHeaders, authorizationToken} from '../../utils/api';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function ({
    orderId,
    sport = 'afl',
    userId,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const authToken = authorizationToken();

    if (!authToken) {
        return;
    }

    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/${userId}/orders/${orderId}/transactions`;

    const apiCall = () => superagent
        .get(url)
        .set(getVimondHeaders({authToken, sport, version: 2}));

    return streamFromApiWithRetry({apiCall}).flatMapLatest('.body');
}
