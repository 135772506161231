import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import ImageFallback from '@fsa-streamotion/streamotion-web-fs-ui/src/components/image-fallback/image-fallback';
import RatioAsset from '@fsa-streamotion/streamotion-web-fs-ui/src/components/ratio-asset/ratio-asset';

export default class LiveVideo extends React.Component {
    renderStartCard() {
        if (this.props.shouldRenderStartCard) {
            return (
                <RatioAsset ratio="16x9">
                    <div className="fiso-hawk-live-video__caption-area">
                        <div className="fiso-hawk-live-video__caption-area-play">
                            <Fsvg name="play-bordered" />
                        </div>

                        <div className="fiso-hawk-live-video__caption-area-title">
                            {this.props.title}
                        </div>
                    </div>

                    <button className="fiso-hawk-live-video__startcard-button" onClick={this.props.onClick}>
                        <ImageFallback fallbackSrc={this.props.fallbackStartCardImg}>
                            <img
                                className="fiso-hawk-live-video__startcard-img"
                                alt="start-card"
                                src={this.props.startCardImg} />
                        </ImageFallback>
                    </button>
                </RatioAsset>
            );
        }
    }

    render() {
        return (
            <div className="fsui-normalise fiso-hawk-live-video">
                {this.props.player}

                <div className="fiso-hawk-live-video__startcard-container">
                    {this.renderStartCard()}
                </div>
            </div>
        );
    }
}

LiveVideo.defaultProps = {
    fallbackStartCardImg: '',
    player: null,
    startCardImg: '',
    title: 'AFL Live 24/7',

    onClick: noop
};

LiveVideo.propTypes = {
    shouldRenderStartCard: propTypes.bool,

    fallbackStartCardImg: propTypes.string,
    player: propTypes.element,
    startCardImg: propTypes.string,
    title: propTypes.string,

    onClick: propTypes.func
};
