import {RangeTouchInput} from '@fsa-streamotion/range-touch';
import type {BaseProps} from '@fsa-streamotion/styled-component-helpers';

import clamp from 'lodash/clamp';
import noop from 'lodash/noop';
import {rgba} from 'polished';
import React from 'react';
import styled, {
    css,
    type CSSObject,
    type DefaultTheme,
    type FlattenInterpolation,
    type Interpolation,
    type InterpolationFunction,
    type ThemedStyledProps,
} from 'styled-components';

import {transition} from '../../../../common/animations';
import {white, black} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {
    effectiveViewportMediaQuery,
    THEME_ACCESSORS,
} from '../../../../common/theme-helpers';
import {
    TRACK_HEIGHT_PX,
    TRACK_HEIGHT_PX__LARGE_SCREENS,
    PLAYHEAD_WIDTH_PX,
    PLAYHEAD_WIDTH_PX__LARGE_SCREENS,
} from './constants';

// the scrubber thumb should render above every other element on the range
// eg. key moments stems
const THUMB_ZINDEX = 1;

type VendorPrefixedRangeThumbCssType<P> = (
    cssStrings:
        | TemplateStringsArray
        | CSSObject
        | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...cssInterpolations: Array<
        Interpolation<ThemedStyledProps<P, DefaultTheme>>
    >
) => FlattenInterpolation<ThemedStyledProps<BaseProps, DefaultTheme>>[];

const vendorPrefixedRangeThumbCss: VendorPrefixedRangeThumbCssType<
    BaseProps
> = (...ruleFragments) =>
    ['webkit-slider', 'moz-range', 'ms'].map(
        (vendor) => css`
            /* stylelint-disable */
            &::-${vendor}-thumb {
                ${css(...ruleFragments)}
            }
            /* stylelint-enable */
        `
    );

const Range = styled(RangeTouchInput)`
    appearance: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    margin: 0 -10px;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    width: calc(100% + 20px);
    height: ${PLAYHEAD_WIDTH_PX}px;
    vertical-align: middle;
    touch-action: manipulation;

    ${vendorPrefixedRangeThumbCss`
        appearance: none;
        box-sizing: border-box;
        position: relative;
        transition: ${transition('background', 'transform')};
        border-width: 0;
        border-radius: 50%;
        background: ${white};
        cursor: pointer;
        width: ${PLAYHEAD_WIDTH_PX}px;
        height: ${PLAYHEAD_WIDTH_PX}px;
        z-index: ${THUMB_ZINDEX};
        box-shadow: 0 0 10px 0 ${black};

        :hover {
            background: ${THEME_ACCESSORS.brandColor};
        }

        ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: ${PLAYHEAD_WIDTH_PX__LARGE_SCREENS}px;
            height: ${PLAYHEAD_WIDTH_PX__LARGE_SCREENS}px;
        `}
    `}

    ${['webkit-slider-runnable', 'moz-range'].map(
        (vendor) => css`
            /* stylelint-disable */
            &::-${vendor}-track {
                display: flex;
                align-items: center;
                border: 0;
                border-radius: 0;
                background: transparent;
                cursor: pointer;
                width: 100%;
                user-select: none;
            }
            /* stylelint-enable */
        `
    )}

    &::-ms-track {
        border: 0;
        background: transparent;
        height: ${TRACK_HEIGHT_PX}px;
        color: transparent;

        ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            height: ${TRACK_HEIGHT_PX__LARGE_SCREENS}px;
        `}
    }

    &::-ms-fill-upper {
        border: 0;
        border-radius: 4px;
        background: transparent;
        user-select: none;
    }

    &::-ms-fill-lower {
        border: 0;
        border-radius: 4px;
        background: ${THEME_ACCESSORS.brandColor};
        user-select: none;
    }

    &::-ms-tooltip {
        display: none;
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &.tab-focus:focus {
        outline-offset: 3px;
    }

    &:active {
        ${vendorPrefixedRangeThumbCss`
            transform: scale(1.3);
            box-shadow: 0px 0px 7.7px 0px ${black}, 0 0 0 3.8px ${rgba(
            white,
            0.25
        )};

            ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
                transform: scale(1.2);
                box-shadow: 0px 0px 8.3px 0px ${black}, 0 0 0 6.7px ${rgba(
                white,
                0.25
            )};
            `}
        `}
    }

    &:focus {
        /* Focus styles: e.g. pressed or keyboard focus */
        outline: 0;
    }
`;

export type Props = {
    // eslint-disable-next-line react/boolean-prop-naming
    disabled?: boolean;
    displayTime?: number;
    duration?: number;
    id?: string;
    step?: number | 'any'; // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-step
    onChange?: React.MouseEventHandler;
    getOnMouseDownUp?: () => () => void;
    onChangeHover?: ({
        isHovering,
        hoverTime,
    }: {
        isHovering: boolean;
        hoverTime: number;
    }) => void;
    tabIndex?: -1 | 0;
};

export default class SliderControlScrubber extends React.PureComponent<Props> {
    static displayName = 'SliderControlScrubber';

    static defaultProps = {
        displayTime: 0,
        duration: 0,
        step: 'any',
        onChange: noop,
        onChangeHover: noop,
        getOnMouseDownUp: () => noop,
        tabIndex: 0,
    };

    override componentDidUpdate(): void {
        // Every prop update, we will update the value held by the input
        // We don't want to directly bind the value however, or it'll prevent it from being interactive.
        // See https://reactjs.org/docs/uncontrolled-components.html#default-values for more details
        if (this.scrubberRef.current && this.props.displayTime) {
            this.scrubberRef.current.value = this.props.displayTime.toString();
        }
    }

    scrubberRef = React.createRef<HTMLInputElement>();
    isMouseDown?: boolean;
    hoverTime?: number;
    releaseMouseEvent?: () => void;

    mouseDown = (): void => {
        this.isMouseDown = true;

        if (typeof this.hoverTime === 'number') {
            this.props.onChangeHover?.({
                isHovering: false,
                hoverTime: this.hoverTime,
            });
        }

        this.releaseMouseEvent = this.props.getOnMouseDownUp?.();
    };

    mouseUp = (): void => {
        if (this.isMouseDown) {
            this.isMouseDown = false;
            this.releaseMouseEvent?.();
        }
    };

    mouseMove = (e: React.MouseEvent<HTMLInputElement>): void => {
        if (e.target instanceof HTMLInputElement && !this.isMouseDown) {
            const xPositionRelativeToContainer =
                e.clientX -
                e.target?.getBoundingClientRect().left -
                PLAYHEAD_WIDTH_PX / 2;
            const width = e.target.clientWidth - PLAYHEAD_WIDTH_PX;

            this.hoverTime =
                (this.props.duration ??
                    SliderControlScrubber.defaultProps.duration) *
                clamp(xPositionRelativeToContainer / width, 0, 1);

            this.props.onChangeHover?.({
                isHovering: true,
                hoverTime: this.hoverTime,
            });
        }
    };

    mouseLeave = (): void => {
        this.props.onChangeHover?.({
            isHovering: false,
            hoverTime: this.hoverTime || 0,
        });
    };

    override render(): React.ReactElement {
        return (
            <Range
                className="smwplayer__scrubber"
                thumbWidth={PLAYHEAD_WIDTH_PX}
                defaultValue={this.props.displayTime}
                id={this.props.id}
                max={this.props.duration}
                min="0"
                onChange={this.props.onChange}
                onMouseDown={this.mouseDown}
                onTouchStart={this.mouseDown}
                onMouseLeave={this.mouseLeave}
                onMouseMove={this.mouseMove}
                onTouchMove={this.mouseMove}
                onMouseUp={this.mouseUp}
                onTouchEnd={this.mouseUp}
                step={this.props.step}
                tabIndex={this.props.tabIndex}
                type="range"
                aria-label="seek to time in video"
                disabled={this.props.disabled}
                ref={this.scrubberRef}
            />
        );
    }
}
