import moment from 'moment-timezone';

const DEFAULT_TIMEZONE = 'Australia/Sydney';
const DEFAULT_APPEND   = 'AEST';

/**
 * Displays a date from API Standard ISO format, in the given format.
 * When on the server, the default timezone of Australia/Sydney will always be used
 * and the appended display date will always show ' AEST' for context.
 *
 * On the browser, this will use the user's Timezone, and the 'AEST' context will
 * be dropped on output.
 *
 * @param  {Mixed}      momentAcceptedObject    Valid Moment Parseable String (or object)
 * @param  {String}     format                  Valid Moment Format
 * @param  {Boolean}    supressTimezone         Stop timezone being added to return datestring (defaults to `false`)
 * @return {String}                             Formatted Moment Object into given String with ' AEST' appended on the server.
 */
exports.displayDateFormat = function (momentAcceptedObject, format, supressTimezone = false) {
    let returnString;

    if (process.browser) {
        returnString = moment(momentAcceptedObject).format(format);
    } else {
        returnString = moment(momentAcceptedObject)
            .tz(DEFAULT_TIMEZONE)
            .format(`${format}`);

        if (!supressTimezone) {
            returnString += ` ${DEFAULT_APPEND}`;
        }
    }

    return returnString;
};
