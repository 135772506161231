import bacon from 'baconjs';
import superagent from 'superagent';
import cloneDeep from 'lodash/cloneDeep';

import {MULTITENANCY_HEADER, VIMOND_MIDDLELAYER_URL} from '../../utils/constants';
import {streamFromApi} from '../../utils/api';

/**
 * Forgot Password - Trigger reset password email
 *
 * @param  {String} emailAddress             e.g. email-address of the user
 *
 * @param  {String} vimondEnv         e.g. 'prod'
 * @return {Observable}                      Stream containing fixturesandresults object
 */

export default function forgotPassword({
    emailAddress,
    sport = 'afl',

    vimondEnv = 'prod'
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/${emailAddress}/password`;
    const request = superagent
        .del(url)
        .set(MULTITENANCY_HEADER[sport]);

    return streamFromApi(request).flatMap((response) => {
        if (response.ok && response.statusCode >= 200 && response.statusCode < 400) {
            return true;
        }

        const error = new Error(response.body.description);

        error.response = cloneDeep(response);
        error.status = response.body.status;

        return new bacon.Error(error);
    });
}
