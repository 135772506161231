import type {
    CommonErrorData,
    ScreenConfig,
} from '@fsa-streamotion/player-state';
import {
    mediaQuery,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import isEmpty from 'lodash/isEmpty';
import {observer} from 'mobx-react-lite';
import {rgba} from 'polished';
import React, {useEffect, useRef} from 'react';
import styled, {type DefaultTheme, useTheme} from 'styled-components';

import {transition} from '../../../common/animations';
import gibson from '../../../common/font-helper';
import {Img} from '../../../common/normalized-styled-components';
import {white, black} from '../../../common/palette';
import parseError from '../../../common/parse-error';
import {
    SCREEN_1920_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1024_DESKTOP,
    SCREEN_768_TABLET,
} from '../../../common/screen-sizes';
import toOrdinal from '../../../common/to-ordinal';
import GA05BgImg from '../../../component-library/atoms/ga/05-bg-img';
import type {Props as IcProps} from '../../../component-library/atoms/ic';
import IC97Eq from '../../../component-library/atoms/ic/97-eq';
import IC152MultiAdd1 from '../../../component-library/atoms/ic/152-multi-add-1';
import IC153MultiAdd2 from '../../../component-library/atoms/ic/153-multi-add-2';
import IC154MultiAdd3 from '../../../component-library/atoms/ic/154-multi-add-3';
import IC155MultiAdd4 from '../../../component-library/atoms/ic/155-multi-add-4';
import TM04BufferingIndicator from '../../../component-library/molecules/tm/04-buffering-indicator';
import {usePlayerContext} from '../context';
import ExternalErrorCard from './errors/external-error-card';
import CentreControls from './overlays/centre-controls';

export const NAV_INDICATOR_BELOW_SCREEN_PX = 7;

const getBackgroundColor = ({
    isFocused,
    theme,
}: {
    isFocused: boolean;
    theme: DefaultTheme;
}): string => (isFocused ? theme.brandColor : white);
const Screen = styled.div<{shouldShowIndicator: boolean; isFocused: boolean}>`
    width: 100%;
    height: 100%;
    overflow: hidden;

    ::after {
        position: absolute;
        bottom: -${NAV_INDICATOR_BELOW_SCREEN_PX}px;
        left: 1%; /* To center the line (100% - 98%) / 2 */
        transform: scaleY(
            ${({shouldShowIndicator}) => (shouldShowIndicator ? '1' : '0')}
        );
        transition: ${transition('transform', 'background-color')};
        background-color: ${getBackgroundColor};
        width: 98%; /* The underline is 98% of the screen */
        height: 2px;
        content: '';
    }
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const ControlsContainer = styled(Container)``;

const StartScreen = styled(Container)`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
`;

const BtybLogo = styled(Img)`
    /* stylelint-disable-next-line csstree/validator */
    margin-bottom: max(50px, 13%);
    max-width: 80%;
    height: 25%;
`;

const AudioIndicator = styled.span<{isVisible: boolean}>`
    display: inline-block;
    position: absolute;
    right: 5px;
    bottom: 5px;
    transform: scaleY(${({isVisible}) => (isVisible ? '1' : '0')});
    width: 25px;
    height: 25px;
`;

const ScreenIndicator = styled.span`
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(${({isVisible}) => (isVisible ? '1' : '0')});
    transform-origin: top right;
    width: 20px;
    height: 20px;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 30px;
        height: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 40px;
        height: 40px;
    `}
`;

const BufferIndicator = styled.div<{isVisible: boolean}>`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transform: scaleY(${({isVisible}) => (isVisible ? '1' : '0')});
    width: 100%;
    height: 100%;
`;

const CaptionContainer = styled.div.attrs<{scale: number}>(({scale}) => ({
    style: {
        '--smwplayer-default-font': gibson.medium({
            size: scale * 14,
            lineHeight: '1.571',
        }),
        '--smwplayer-768-font-size': `${scale * 20}px`,
        '--smwplayer-1024-font-size': `${scale * 24}px`,
        '--smwplayer-1280-font-size': `${scale * 34}px`,
        '--smwplayer-1920-font-size': `${scale * 40}px`,
    },
}))`
    display: ${({isDisabled}) => (isDisabled ? 'none' : 'block')};
    /* RxPlayer need a full screen element as container, otherwise the captions will not shown correctly */
    & > div {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    * {
        color: ${white} !important; /* stylelint-disable-line declaration-no-important */
    }

    p,
    span {
        /* stylelint-disable-next-line declaration-no-important */
        font: var(--smwplayer-default-font) !important;

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            font-size: var(--smwplayer-768-font-size) !important;
            line-height: 1.6 !important;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            font-size: var(--smwplayer-1024-font-size) !important;
            line-height: 1.5 !important;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            font-size: var(--smwplayer-1280-font-size) !important;
            line-height: 1.3 !important;
        `}

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            font-size: var(--smwplayer-1920-font-size) !important;
            line-height: 1.25 !important;
        `}
    }

    span {
        /* stylelint-disable-next-line declaration-no-important */
        border-color: ${rgba(black, 0.5)} !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: ${rgba(black, 0.5)} !important;
        text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    }

    span > span {
        background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    }
`;

const VideoContainer = styled.div.attrs<{position: number; scale: number}>(
    ({position, scale}) => ({
        style: {
            '--smwplayer-pseudo-element-content': `'Please select your ${toOrdinal(
                position
            )} video'`,
            '--smwplayer-default-font': gibson.medium({
                size: scale * 14,
                lineHeight: 1.571,
            }),
            '--smwplayer-768-font-size': `${scale * 20}px`,
            '--smwplayer-1024-font-size': `${scale * 24}px`,
            '--smwplayer-1280-font-size': `${scale * 34}px`,
            '--smwplayer-1920-font-size': `${scale * 40}px`,
        },
    })
)`
    position: relative;
    width: 100%;
    height: 100%;

    ::before {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        transition: ${transition('opacity')};
        opacity: ${({showContextual}) => (showContextual ? 1 : 0)};
        padding: 0 5px;
        width: 100%;
        height: 100%;
        font: ${gibson({size: 18, lineHeight: 1.2})};
        content: var(--smwplayer-pseudo-element-content);
    }

    video::cue {
        color: ${white};
        font: var(--smwplayer-default-font);

        ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
            font-size: var(--smwplayer-768-font-size);
            line-height: 1.6;
        `}
        ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
            font-size: var(--smwplayer-1024-font-size);
            line-height: 1.5;
        `}
        ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
            font-size: var(--smwplayer-1280-font-size);
            line-height: 1.3;
        `}
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            font-size: var(--smwplayer-1920-font-size);
            line-height: 1.25;
        `}
    }

    video::-webkit-media-text-track-container {
        overflow: visible;

        ${stylesWhen('shouldDisableCueElement')`
            display: none;
        `}
    }
`;

type ScreenIconsProps = {
    textColor?: string;
} & IcProps;

const SCREEN_ICONS: React.FC<ScreenIconsProps>[] = [
    IC152MultiAdd1,
    IC153MultiAdd2,
    IC154MultiAdd3,
    IC155MultiAdd4,
];

type Props = {
    screenIndex: number;
    scale?: number;
};

const VideoScreen = ({screenIndex, scale}: Props): React.ReactElement => {
    const playerState = usePlayerContext();
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const captionContainerRef = useRef<HTMLDivElement>(null);
    const {brandColor} = useTheme();

    const {
        screenConfigs,
        isSingleLayout,
        globalState,
        generalConfig,
        globalActions,
    } = playerState ?? {};
    const updateUpNextData = globalActions?.updateUpNextData;

    const {
        activeScreenIndex,
        focusedScreenIndex,
        isLowerControlsVisible,
        isLowerTrayVisible,
        screenOrder,
        isInSplitViewMode,
        isUpNextMode,
        commonErrorData: {
            customElementsConfig,
            additionalErrorData,
        } = {} as CommonErrorData,
    } = globalState ?? {};

    const {isHudEnabled} = generalConfig ?? {};

    const screenConfigByIndex = screenConfigs?.[screenIndex];
    const {videoState, playbackData, startScreenData, playerTech} =
        screenConfigByIndex ?? {};
    const {
        isCentreControlsVisible,
        buffering,
        hasFiredFirstPlay,
        isBtybVisible,
        isEnded,
    } = videoState ?? {};
    const {isStalledBuffering = false, stalledBufferingPercentage = 0} =
        buffering ?? {};

    const isScreenFocused = focusedScreenIndex === screenIndex;
    const isCurrentScreenFocused =
        isScreenFocused && isCentreControlsVisible && !isStalledBuffering;
    const isCurrentScreenActive =
        activeScreenIndex === screenIndex &&
        (isLowerControlsVisible || isLowerTrayVisible);

    const isScreenEmpty = isEmpty(
        playerState?.screenConfigs?.[screenIndex]?.playbackData?.sources
    );

    const shouldShowSplitViewIndicators =
        !isSingleLayout && (isCurrentScreenActive || isCurrentScreenFocused);
    const shouldShowAudioIndicator =
        !isSingleLayout && !isScreenEmpty && isCurrentScreenActive;
    const shouldShowNumberIndicator =
        !isSingleLayout &&
        !isScreenEmpty &&
        (isLowerControlsVisible || isLowerTrayVisible);
    const shouldShowCentreControls =
        !isLowerTrayVisible &&
        !isScreenEmpty &&
        isCurrentScreenFocused &&
        hasFiredFirstPlay &&
        !isBtybVisible;

    const errorDatum =
        playbackData?.externalError || playbackData?.nativePlayerTechError;

    const shouldDisableCueElement =
        playerTech?.currentPlaybackHandler?.shouldDisableCueElement ?? false;
    const isTextTrackOff = videoState?.currentCaptionTrackIndex === -1;

    const screenPosition = screenOrder?.[screenIndex] ?? 0;

    const isVideoEndedInUpNextMode = isUpNextMode && isEnded;
    const isVideoEndedInUpNextModeRef = useRef(isVideoEndedInUpNextMode);
    const playerStateRef = useRef(playerState);

    isVideoEndedInUpNextModeRef.current = isVideoEndedInUpNextMode;

    useEffect(
        function syncPlayerStateRef() {
            playerStateRef.current = playerState;
        },
        [playerState]
    );

    useEffect(
        function appendElementsToPage() {
            if (
                videoContainerRef.current?.children?.length === 0 &&
                playerStateRef.current?.screenConfigs?.[screenIndex]
                    ?.videoElement
            ) {
                videoContainerRef.current.appendChild(
                    (
                        playerStateRef.current.screenConfigs[
                            screenIndex
                        ] as ScreenConfig
                    ).videoElement
                );
            }

            if (
                captionContainerRef.current?.children?.length === 0 &&
                playerStateRef.current?.screenConfigs?.[screenIndex]
                    ?.captionElement
            ) {
                captionContainerRef.current.appendChild(
                    (
                        playerStateRef.current.screenConfigs[
                            screenIndex
                        ] as ScreenConfig
                    ).captionElement
                );
            }

            if (
               playerStateRef.current?.screenConfigs?.[screenIndex]
                    ?.rxPlayerCaptionContainerElement
            ) {
                captionContainerRef.current?.appendChild(
                    (
                        playerStateRef.current.screenConfigs[
                        screenIndex
                        ] as ScreenConfig
                    ).rxPlayerCaptionContainerElement
                );
            }
        },
        [screenIndex]
    );

    // enforce video PiP mode in Chrome displaying play pause control
    useEffect(
        function handlePiPToggle() {
            // do nothing if mediaSession is not supported
            if (!navigator.mediaSession) {
                return;
            }

            let cancelUpdateUpNextData: (() => void) | void;

            const resetUpNextAfterReplay = (): void => {
                playerStateRef.current?.setUpNext(null);

                cancelUpdateUpNextData?.();
                cancelUpdateUpNextData = updateUpNextData?.(
                    playbackData,
                    (upNext) => {
                        playerStateRef.current?.setUpNext(upNext);
                    }
                );

                playerTech?.videoElement.removeEventListener(
                    'timeupdate',
                    resetUpNextAfterReplay
                );
            };

            /* eslint-disable compat/compat */
            const handlePiPLiveStream = (): void => {
                navigator.mediaSession.setActionHandler('play', () => {
                    if (isVideoEndedInUpNextModeRef.current) {
                        playerTech?.videoElement.addEventListener(
                            'timeupdate',
                            resetUpNextAfterReplay
                        );
                    }

                    playerTech?.togglePlayPause();
                });
                navigator.mediaSession.setActionHandler('pause', () => {
                    playerTech?.togglePlayPause();
                });
            };

            const clearMediaSessionActionHandler = (): void => {
                navigator.mediaSession.setActionHandler('play', null);
                navigator.mediaSession.setActionHandler('pause', null);
            };

            playerTech?.videoElement.addEventListener(
                'enterpictureinpicture',
                handlePiPLiveStream
            );
            playerTech?.videoElement.addEventListener(
                'leavepictureinpicture',
                clearMediaSessionActionHandler
            );

            return () => {
                playerTech?.videoElement.removeEventListener(
                    'enterpictureinpicture',
                    handlePiPLiveStream
                );
                playerTech?.videoElement.removeEventListener(
                    'leavepictureinpicture',
                    clearMediaSessionActionHandler
                );
                playerTech?.videoElement.removeEventListener(
                    'timeupdate',
                    resetUpNextAfterReplay
                );

                cancelUpdateUpNextData?.();
            };
        },
        [playerTech, updateUpNextData, playbackData]
    );

    const ScreenIndicatorIcon = SCREEN_ICONS[
        screenPosition
    ] as React.FC<ScreenIconsProps>;

    return (
        <Screen
            shouldShowIndicator={!!shouldShowSplitViewIndicators}
            isFocused={!!isCurrentScreenFocused}
        >
            <VideoContainer
                ref={videoContainerRef}
                position={screenPosition + 1}
                showContextual={isScreenEmpty && isInSplitViewMode}
                scale={scale}
                shouldDisableCueElement={
                    shouldDisableCueElement || isTextTrackOff
                }
            />
            <CaptionContainer
                isDisabled={isTextTrackOff}
                ref={captionContainerRef}
                scale={scale}
            />
            <ControlsContainer>
                <BufferIndicator
                    isVisible={
                        isStalledBuffering ||
                        !!screenConfigByIndex?.reservationId
                    }
                >
                    <TM04BufferingIndicator
                        bufferPercentage={stalledBufferingPercentage}
                    />
                </BufferIndicator>
                <CentreControls
                    isHudEnabled={isHudEnabled}
                    screenIndex={screenIndex}
                    isVisible={shouldShowCentreControls}
                />
                <AudioIndicator isVisible={!!shouldShowAudioIndicator}>
                    <IC97Eq />
                </AudioIndicator>
                <ScreenIndicator isVisible={shouldShowNumberIndicator}>
                    <ScreenIndicatorIcon
                        {...(isCurrentScreenFocused
                            ? {textColor: white, color: brandColor}
                            : {color: white, hasDropshadow: true})}
                    />
                </ScreenIndicator>
            </ControlsContainer>

            {/**
             *   When initial playback data is set, hasFiredFirstPlay`->`true and isBtybVisible`->`true should happen in
             *     the same tick, so "flickering" shouldn't happen.
             *   Add playbackData.metadata.poster when we need to show posters between videos
             */}
            {(!hasFiredFirstPlay || isBtybVisible) &&
                startScreenData?.poster && (
                    <GA05BgImg key="poster-image" {...startScreenData.poster} />
                )}
            {isBtybVisible && playbackData?.metadata.btyb && (
                <StartScreen key="start-screen-btyb">
                    <BtybLogo alt="" {...playbackData.metadata.btyb} />
                </StartScreen>
            )}

            {!!errorDatum && screenConfigByIndex && additionalErrorData && (
                <ExternalErrorCard
                    errorDetails={parseError(screenConfigByIndex, errorDatum, {
                        customElementsConfig,
                        additionalErrorData: {
                            ...additionalErrorData,
                            assetId: playbackData.id ?? '',
                        },
                    })}
                    scale={scale}
                />
            )}
        </Screen>
    );
};

VideoScreen.displayName = 'VideoScreen';

export default observer(VideoScreen);
