import noop from 'lodash/noop';
import React from 'react';
import ReactDOM from 'react-dom';
import type {DefaultTheme} from 'styled-components';

import {DEFAULT_THEME} from '../utils/constants';
import type {Logger} from '../utils/types';
import StreamotionChromecastSenderComponent from './component';
import ChromecastSenderState, {
    type ChromecastSenderStateValuesType,
} from './state';

type ArgsType = {
    onSenderClosed?: (() => void) | null;
    logger?: Logger;
    theme?: DefaultTheme;
    initialStateValues?: ChromecastSenderStateValuesType;
};

export default class StreamotionChromecastSender {
    #isOpen = false;
    #mountNode: Element | null = null;
    #closingPromise: Promise<void> | null = null;
    #openingPromise: Promise<void> | null = null;
    #senderState: ChromecastSenderState | null = null;
    #senderStateConstructorArgs: {
        initialValues?: ChromecastSenderStateValuesType;
        logger?: Logger;
    } = {};

    #logger: Logger | null = null;
    onSenderClosed: (() => void) | null = null;
    theme: DefaultTheme | null = null;

    /**
     * Creates an instance of ChromecastSender.
     * @param options - Options for creating the Chromecast sender. @See Logger, DefaultTheme, ChromecastSenderContextType
     */
    constructor({
        onSenderClosed = noop,
        logger = console,
        theme = DEFAULT_THEME,
        initialStateValues = {},
    }: ArgsType = {}) {
        Object.assign(this, {
            theme,
            onSenderClosed,
        });

        this.#logger = logger;

        this.#senderStateConstructorArgs = {
            initialValues: initialStateValues,
            logger,
        };
    }

    async close(): Promise<void> {
        this.#closingPromise = (this.#openingPromise || Promise.resolve())
            // If we are closed too soon after being opened, just wait first
            .finally(() => {
                if (this.mountNode) {
                    ReactDOM.unmountComponentAtNode(this.mountNode);
                }

                this.#logger?.debug('Destroying senderState');
                this.#isOpen = false;
                this.onSenderClosed?.();
                this.#logger?.debug('Sender closed');
            });

        return this.#closingPromise;
    }

    async open(): Promise<void> {
        this.#openingPromise = (this.#closingPromise || Promise.resolve())
            .then(() => {
                this.#senderState = new ChromecastSenderState(
                    this.#senderStateConstructorArgs
                );

                return new Promise<void>((resolve, reject) => {
                    try {
                        if (this.#senderState && this.theme) {
                            ReactDOM.render(
                                <StreamotionChromecastSenderComponent
                                    state={this.#senderState}
                                    theme={this.theme}
                                />,
                                this.mountNode,
                                resolve
                            );
                        }
                    } catch (e) {
                        reject(e);
                    }
                });
            })
            .catch((e) => {
                this.#logger?.error('Error rendering sender', e);
            })
            .finally(() => {
                this.#isOpen = true;
                this.#logger?.debug('Sender opened');
            });

        return this.#openingPromise;
    }

    /**
     * Perform a partial update of the sender state using a shallow merge
     *
     * @param newPartialState - To be partially merged to the state
     */
    updateState(newPartialState: ChromecastSenderStateValuesType): void {
        if (!this.isOpen) {
            throw new Error(
                'StreamotionPlayerChromecastSender: Attempted to update state while sender was not open'
            );
        }

        this.#senderState?.updateValues(newPartialState);
    }

    /**
     * @returns Is the sender open?
     */
    get isOpen(): boolean {
        return this.#isOpen;
    }

    /**
     * @returns The node the sender should render into when `open` is called
     */
    get mountNode(): Element | null {
        return this.#mountNode;
    }

    /**
     * @param mountNode - Which node should the sender render into when `open` is called
     */
    set mountNode(mountNode: Element | null) {
        if (this.mountNode && this.isOpen) {
            const errorMessage =
                'StreamotionPlayerChromecastSender: Attempted to change mount node while the sender was open';

            this.#logger?.error(errorMessage);

            throw new Error(errorMessage);
        }

        this.#mountNode = mountNode;
    }
}
