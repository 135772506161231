import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import clamp from 'lodash/clamp';
import noop from 'lodash/noop';
import React, {useRef, useState} from 'react';
import styled from 'styled-components';

import {fadeInOut} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import gibson from '../../../../common/font-helper';
import {white} from '../../../../common/palette';
import GA02TimeTooltip from '../../../atoms/ga/02-time-tooltip';
import GA10KeyMoment from '../../../atoms/ga/10-key-moment';
import {PLAYHEAD_WIDTH_PX} from './constants';
import ProgressBar from './progress-bar';
import Scrubber from './scrubber';

const ACTIVE_KEY_MOMENT_ZINDEX = 1;

const Wrapper = styled.div`
    position: relative;
    height: ${PLAYHEAD_WIDTH_PX}px;
`;

const TimeTooltipContainer = styled.div`
    position: absolute;
    bottom: 0;
    transform: translate(-50%);
    margin-bottom: 24px;
    ${fadeInOut}
`;

const KeyMomentContainer = styled.div`
    position: absolute;
    bottom: calc(50% + 5px);
    transform: translateX(-50%);
`;

const KeyMomentPositioner = styled.div`
    position: absolute;
    z-index: 0;
    width: 100%;
    pointer-events: none;

    ${stylesWhen('isKeyMomentFocused')`
        z-index: ${ACTIVE_KEY_MOMENT_ZINDEX};
    `}

    &:hover {
        z-index: ${ACTIVE_KEY_MOMENT_ZINDEX};
    }
`;

const KeyMomentsDescription = styled.div`
    position: absolute;
    top: -60px;
    width: 100%;
    text-align: center;
    color: ${white};
    font: ${gibson({size: 18, lineHeight: 1.33})};
`;

const getPositionOnScrubber = (time: number, duration: number): number =>
    (time / duration) * 100;

export type Props = {
    isPlayingAd?: boolean;
    isSeekingDisabled?: boolean;
    bufferedTimeRanges?: {start: number; end: number}[];
    currentTime?: number;
    duration?: number;
    seekingTime?: number;
    isSeeking?: boolean;
    isSeekingTooltipEnabled?: boolean;
    isUserHoldingControls?: boolean;
    shouldUseSlowReveal?: boolean;
    isKeyMomentsVisible?: boolean;
    keyMoments?:
        | {
              displayTime: number;
              code: string;
              name: string;
              onClick?: React.MouseEventHandler<HTMLButtonElement>;
          }[]
        | null;
    latestSecondsViewed?: number;
    onChange?: (value: number) => void;
    getOnMouseDownUp?: () => () => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    onChangeHover?: ({
        isHovering,
        hoverTime,
    }: {
        isHovering: boolean;
        hoverTime: number;
    }) => void;
    className?: string;
    tabIndex?: -1 | 0;
    isLockedToEdge?: boolean;
};

const IM02Scrubber = ({
    isPlayingAd,
    isSeekingDisabled,
    bufferedTimeRanges = [],
    currentTime = 0,
    duration = 0,
    isSeeking,
    seekingTime = 0,
    keyMoments = [],
    isSeekingTooltipEnabled,
    isUserHoldingControls,
    shouldUseSlowReveal,
    isKeyMomentsVisible,
    latestSecondsViewed,
    onChange = noop,
    onChangeHover = noop,
    getOnMouseDownUp = () => noop,
    onKeyDown = noop,
    className,
    tabIndex = 0,
    isLockedToEdge = false,
}: Props): React.ReactElement => {
    const keyMomentRefs = useRef<HTMLDivElement[]>([]);
    const [activeTitle, setActiveTitle] = useState('');

    return (
        <Wrapper
            className={classnames('IM02Scrubber', className)}
            onKeyDown={onKeyDown}
        >
            {!!activeTitle && (
                <KeyMomentsDescription>{activeTitle}</KeyMomentsDescription>
            )}

            {!isPlayingAd &&
                !isLockedToEdge &&
                bufferedTimeRanges
                    .filter(({end}) => end >= currentTime) // hide ranges that end before the playhead
                    .map(({start, end}) => (
                        // start/end: clamp values between 0-100 to prevent overflow
                        <ProgressBar
                            start={clamp((start / duration) * 100, 0, 100)}
                            end={clamp((end / duration) * 100, 0, 100)}
                            key={start}
                        />
                    ))}

            <ProgressBar
                start={0}
                current={
                    isLockedToEdge && !isPlayingAd
                        ? 100
                        : (currentTime / duration) * 100
                }
                end={100}
                isDuration={true}
                isPlayingAd={isPlayingAd}
            />

            {!isPlayingAd && (
                <React.Fragment>
                    <Scrubber
                        displayTime={
                            isLockedToEdge
                                ? duration
                                : isSeeking
                                ? seekingTime
                                : currentTime
                        }
                        disabled={isLockedToEdge || isSeekingDisabled}
                        duration={duration}
                        onChange={(e: React.MouseEvent<HTMLInputElement>) =>
                            e.target instanceof HTMLInputElement &&
                            onChange(e.target.valueAsNumber)
                        } // target.value returns a string
                        onChangeHover={onChangeHover}
                        getOnMouseDownUp={getOnMouseDownUp}
                        tabIndex={tabIndex}
                    />

                    {!!isKeyMomentsVisible &&
                        !!keyMoments &&
                        keyMoments
                            // if showSpoilers is false we only show key moments before current time
                            .filter(
                                ({displayTime}) =>
                                    !shouldUseSlowReveal ||
                                    (latestSecondsViewed &&
                                        displayTime <= latestSecondsViewed)
                            )
                            .map(
                                ({code, name, displayTime, onClick}, index) => (
                                    <KeyMomentPositioner
                                        key={index}
                                        style={{
                                            transform: `translateX(${getPositionOnScrubber(
                                                displayTime,
                                                duration
                                            )}%)`,
                                        }}
                                        ref={(
                                            positionerRef: HTMLDivElement
                                        ) => {
                                            keyMomentRefs.current[index] =
                                                positionerRef;
                                        }}
                                        isKeyMomentFocused={keyMomentRefs.current[
                                            index
                                        ]?.contains(document.activeElement)}
                                    >
                                        <KeyMomentContainer>
                                            <GA10KeyMoment
                                                abbrText={code}
                                                title={name}
                                                onClick={onClick}
                                                onMouseEnter={() =>
                                                    setActiveTitle(name)
                                                }
                                                onMouseLeave={() =>
                                                    setActiveTitle('')
                                                }
                                                onFocus={() =>
                                                    setActiveTitle(name)
                                                }
                                                onBlur={() =>
                                                    setActiveTitle('')
                                                }
                                                onUnmount={() =>
                                                    setActiveTitle('')
                                                }
                                            />
                                        </KeyMomentContainer>
                                    </KeyMomentPositioner>
                                )
                            )}
                </React.Fragment>
            )}

            {!!duration && (
                <CommonTransition
                    in={isUserHoldingControls && isSeekingTooltipEnabled}
                >
                    <div
                        style={{
                            transform: `translateX(${getPositionOnScrubber(
                                seekingTime,
                                duration
                            )}%)`,
                        }}
                    >
                        <TimeTooltipContainer>
                            <GA02TimeTooltip seekingTime={seekingTime} />
                        </TimeTooltipContainer>
                    </div>
                </CommonTransition>
            )}
        </Wrapper>
    );
};

IM02Scrubber.displayName = 'IM02Scrubber';

export default IM02Scrubber;
