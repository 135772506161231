import React from 'react';

const GA146Grid = (): React.ReactElement => (
    <svg width="73px" height="40px" viewBox="0 0 73 40">
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="SplitView-/-Default-/-Grid"
                transform="translate(0.342733, 0.000000)"
                fill="#FFFFFF"
            >
                <rect
                    x="37.3324625"
                    y="0"
                    width="34.6658581"
                    height="18.6666667"
                />
                <rect
                    x="37.3324625"
                    y="21.3333333"
                    width="34.6658581"
                    height="18.6666667"
                />
                <rect x="0" y="0" width="34.6658581" height="18.6666667" />
                <rect
                    x="0"
                    y="21.3333333"
                    width="34.6658581"
                    height="18.6666667"
                />
            </g>
        </g>
    </svg>
);

GA146Grid.displayName = 'GA146Grid';

export default GA146Grid;
