import {PLAYER_LAYOUT_STYLE} from '@fsa-streamotion/player-state';
import {
    mediaQuery,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import {observer} from 'mobx-react-lite';
import {rgba} from 'polished';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {white} from '../../../../common/palette';
import {
    SCREEN_1920_DESKTOP,
    SCREEN_768_TABLET,
} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import GA142Up4 from '../../../../component-library/atoms/ga/142-up4';
import GA142Up3 from '../../../../component-library/atoms/ga/143-up3';
import GA142Up2 from '../../../../component-library/atoms/ga/144-up2';
import GA142Pip from '../../../../component-library/atoms/ga/145-pip';
import GA142Grid from '../../../../component-library/atoms/ga/146-grid';
import IC135Cross from '../../../../component-library/atoms/ic/135-cross';
import {usePlayerContext} from '../../context';

const EXIT_BUTTON_MARGIN = 41;
const LAYOUT_OPTIONS_MARGIN_BOTTOM = 10;

const Container = styled.div`
    display: inline-flex;
    position: absolute;
    bottom: 100px;
    left: 14px;
    flex-wrap: wrap;
    align-items: center;
    transform: scaleY(${({isVisible}) => (isVisible ? 1 : 0)});
    transform-origin: bottom;
    transition: ${transition('transform')};
    margin-bottom: -${LAYOUT_OPTIONS_MARGIN_BOTTOM}px;
    min-height: 50px;
    pointer-events: auto;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        left: 40px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        bottom: 210px;
        left: 70px;
    `}

    > * {
        margin-bottom: ${LAYOUT_OPTIONS_MARGIN_BOTTOM}px;
    }
`;

const LayoutButton = styled(Button)`
    display: ${({isActive, isExpanded}) =>
        isActive || isExpanded ? 'inline-block' : 'none'};
    position: relative;
    align-self: stretch;
    border: 0;
    background: transparent;
    padding: 0;
    color: ${white};

    :not(:last-child) {
        margin-right: 21px;
    }

    /* stylelint-disable no-descending-specificity */
    ::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        transition: ${transition('transform')};
        width: 58px;
        height: 2px;
        content: '';

        ${stylesWhen('isActive')`
            transform: translateX(-50%) scaleX(1);
            background: ${white};
        `}
    }

    :hover,
    :focus {
        ::after {
            transform: translateX(-50%) scaleX(1);
            background: ${THEME_ACCESSORS.brandColor};
        }
    }
`;

const ExitButton = styled(Button)<{isVisible: boolean}>`
    display: ${({isVisible}) => (isVisible ? 'flex' : 'none')};
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('background')};
    margin-right: ${EXIT_BUTTON_MARGIN * 2}px;
    border: 0;
    background: ${rgba(white, 0.2)};
    padding: 0;
    width: 155px;
    height: 50px;
    color: ${white};
    font: ${gibson({size: 14, lineHeight: 1.14})};

    :hover,
    :focus {
        background: ${THEME_ACCESSORS.brandColor};
    }

    ::after {
        position: absolute;
        right: -${EXIT_BUTTON_MARGIN}px;
        background: ${rgba(white, 0.2)};
        width: 1px;
        height: 100%;
        content: '';
    }
`;

const CloseIcon = styled(IC135Cross)`
    margin-right: 11px;
`;

const LayoutIconContainer = styled.span<{isActive: boolean}>`
    transition: ${transition('opacity')};
    opacity: ${({isActive}) => (isActive ? 1 : 0.2)};

    &:hover,
    &:focus {
        opacity: 1;
    }
`;

const layoutTypeToIcon = {
    [PLAYER_LAYOUT_STYLE.LAYOUT_2UP]: GA142Up2,
    [PLAYER_LAYOUT_STYLE.LAYOUT_PIP]: GA142Pip,
    [PLAYER_LAYOUT_STYLE.LAYOUT_3UP]: GA142Up3,
    [PLAYER_LAYOUT_STYLE.LAYOUT_4UP]: GA142Up4,
    [PLAYER_LAYOUT_STYLE.LAYOUT_GRID]: GA142Grid,
};

const layoutTypeScreenRequirements = [
    {
        label: '2up',
        layoutStyle: PLAYER_LAYOUT_STYLE.LAYOUT_2UP,
        minScreenRequirement: 2,
    },
    {
        label: 'pip',
        layoutStyle: PLAYER_LAYOUT_STYLE.LAYOUT_PIP,
        minScreenRequirement: 2,
    },
    {
        label: '3up',
        layoutStyle: PLAYER_LAYOUT_STYLE.LAYOUT_3UP,
        minScreenRequirement: 3,
    },
    {
        label: '4up',
        layoutStyle: PLAYER_LAYOUT_STYLE.LAYOUT_4UP,
        minScreenRequirement: 4,
    },
    {
        label: 'grid',
        layoutStyle: PLAYER_LAYOUT_STYLE.LAYOUT_GRID,
        minScreenRequirement: 4,
    },
];

const SplitViewLayoutSelector = (): React.ReactElement => {
    const playerState = usePlayerContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const {globalState, globalActions, generalConfig} = playerState ?? {};

    const {numScreens, onPlayerInteractionByType} = generalConfig ?? {};

    const shouldShowSelector =
        globalState?.isLowerControlsVisible &&
        globalState.isInSplitViewMode &&
        !globalState.isLowerTrayVisible;

    useEffect(
        function collapseWhenLowerTrayIsOpened() {
            if (globalState?.isLowerControlsVisible) {
                setIsExpanded(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalState?.isLowerTrayVisible]
    );

    return (
        <Container isVisible={shouldShowSelector}>
            <ExitButton
                type="button"
                isVisible={isExpanded}
                onClick={() => globalActions?.setIsInSplitViewMode(false)}
            >
                <CloseIcon size="21px" />
                Exit SplitView
            </ExitButton>
            {layoutTypeScreenRequirements
                .filter(
                    ({minScreenRequirement}) =>
                        typeof numScreens === 'number' &&
                        minScreenRequirement <= numScreens
                )
                .map(({label, layoutStyle}) => {
                    const LayoutGraphic = layoutTypeToIcon[layoutStyle];

                    return (
                        <LayoutButton
                            isActive={layoutStyle === globalState?.layoutStyle}
                            isExpanded={isExpanded}
                            key={label}
                            type="button"
                            onClick={(event: React.MouseEvent) => {
                                event.stopPropagation();

                                if (layoutStyle === globalState?.layoutStyle) {
                                    setIsExpanded(!isExpanded);
                                } else {
                                    globalActions?.setLayoutStyle(layoutStyle);
                                    onPlayerInteractionByType?.(
                                        'layout-selection',
                                        {layoutStyle}
                                    );
                                }
                            }}
                        >
                            <LayoutIconContainer
                                isActive={
                                    layoutStyle === globalState?.layoutStyle
                                }
                            >
                                <LayoutGraphic />
                            </LayoutIconContainer>
                        </LayoutButton>
                    );
                })}
        </Container>
    );
};

SplitViewLayoutSelector.displayName = 'SplitViewLayoutSelector';

export default observer(SplitViewLayoutSelector);
