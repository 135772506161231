import getMatchesStream from '../end-points/matches';
import {DEFAULT_VIMOND_ENV} from '../../utils/constants';

import getMassagedMatchData from './match-data-massager';

export default function getScoresLive({
    sport,
    seriesIds,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return getMatchesStream({
        sport,
        seriesIds,
        type: 'live',
        vimondEnv
    })
        .map(getMassagedMatchData);
}
