import {Observable} from 'rxjs';

/**
 * Create an observable that calls the specified function, passing it a
 * callback which, when called, caused the returned observable to emit the
 * callback's argument.
 *
 * The specified function can optionally return a clean-up function, which
 * will be called upon unsubscription.
 *
 * @param fn - a function to which a callback is passed and which returns a clean-up function
 * @returns a new observable
 */
export default function observableFromCallback<T>(
    fn: (callback: (value: T) => void) => void
): Observable<T> {
    return new Observable((subscriber) =>
        fn((value: T) => {
            subscriber.next(value);
            subscriber.complete();
        })
    );
}
