import {of, type Observable, delay} from 'rxjs';
import {ajax, type AjaxConfig, type AjaxResponse} from 'rxjs/ajax';
import {map, switchMap} from 'rxjs/operators';

const DEFAULT_TIMEOUT_MS = 60000;

/**
 * Load a resource into an <code>ArrayBuffer</code>.
 *
 * @param options                   - (see below)
 * @param url               - the URL of the resource to load
 * @param timeoutMs         - the request timeout in milliseconds.Defaults to 60000.
 * @param delayMs           - the request's sending delay in milliseconds.Defaults to 0.
 * @returns                  - an Observable that produces an <code>ArrayBuffer</code>
 */

type Params = {
    url: string;
    timeoutMs?: number;
    delayMs?: number;
};

export default function loadArrayBufferStream({
    url,
    timeoutMs = DEFAULT_TIMEOUT_MS,
    delayMs = 0,
}: Params): Observable<ArrayBuffer> {
    const xmlRequestProps: AjaxConfig = {
        url,
        method: 'GET',
        responseType: 'arraybuffer',
        timeout: timeoutMs,
    };

    const ajaxResponse: Observable<AjaxResponse<ArrayBuffer>> =
        ajax(xmlRequestProps);

    return of(null).pipe(
        delay(delayMs),
        switchMap(() =>
            ajaxResponse.pipe(
                map((xhr: AjaxResponse<ArrayBuffer>) => {
                    if (!xhr.status || xhr.status < 200 || xhr.status >= 300) {
                        throw new Error(
                            `PlayerSkin: Thumbnail BIF retrieval error, url: ${url}, status ${xhr.status}`
                        );
                    }

                    return xhr.response;
                })
            )
        )
    );
}
