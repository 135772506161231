import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC22Float = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M57.503 28.954v25.447H18.166V28.954h39.337m8.384-8.384H9.782v42.215h56.105V20.57z"
            transform="translate(5 11.766) translate(0 1.02)"
        />
        <path
            d="M46.855 72.609L0 72.609 0 41.195 4.192 41.195 4.192 68.417 46.855 68.417zM85.926 12.06l-3.8 5.31C80.465 8.047 72.677 1.048 63.231.387c-9.446-.66-18.131 5.187-21.072 14.188l3.982 1.3c2.34-7.16 9.24-11.821 16.756-11.32 7.516.5 13.735 6.036 15.105 13.444l-5.338-3.745-2.432 3.41 11.11 7.964 7.965-11.179-3.382-2.39z"
            transform="translate(5 11.766) translate(0 1.02)"
        />
    </Ic>
);

IC22Float.displayName = 'IC22Float';

export default IC22Float;
