import bacon from 'baconjs';
import moment from 'moment';

import get from 'lodash/get';
import find from 'lodash/find';
import trim from 'lodash/trim';
import result from 'lodash/result';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';

import getProductGroups from '../../streams/product-groups/get-product-groups';
import getProducts from '../../streams/products/get-products';
import getProductPayments from '../../streams/product-payments/get-product-payments';
import getVoucher from '../../streams/voucher/get';

import {getCurrencySymbol} from '../../utils/helpers';

export default function getVoucherProductFormData({
    chosenProductId,
    currency$,
    initialData = {},
    isVoucherOptional,
    productGroupId,
    sport = 'afl',
    voucher,

    vimondEnv
}) {
    const productAndVoucherBus = new bacon.Bus();
    const productAndVoucher$ = bacon.update(
        {voucher, chosenProductId},
        [productAndVoucherBus], Object.assign
    );
    const voucher$ = productAndVoucher$
        .map('.voucher')
        .debounce(2000)
        .map(trim);

    const chosenProductId$ = productAndVoucher$
        .map('.chosenProductId')
        .skipDuplicates();

    const currencySymbol$ = currency$
        .map(getCurrencySymbol);

    const products$ = currency$
        .flatMapLatest((currency) => (initialLaterOr(
            initialData && initialData.products,

            getProductGroups({sport, vimondEnv})
                .map('.productGroups')
                .flatMapLatest((productGroups) => {
                    const productGroup = find(productGroups, {id: productGroupId});
                    const productGroupUri = get(productGroup, 'productsUri.uri', '');

                    return getProducts({productGroupUri, currency, sport, vimondEnv});
                })
        )));

    // MW-74 - Hide productGroup description until we need it again ...
    // const currentProductGroup$ = getProductGroups({sport, vimondEnv})
    //     .map('.productGroups')
    //     .map((productGroups) => find(productGroups, {id: productGroupId}));

    const currentProduct$ = bacon.combineWith(
        products$, chosenProductId$,
        ([firstProduct, ...otherProducts], id) => find(otherProducts, {id}) || firstProduct
    );

    const vimondVoucher$ = bacon.combineWith(
        voucher$, currency$,
        (voucherCode, currency) => ({

            /*
            * feature HAWK-531 - VOUCHER: 25% discount voucher on Season Pass
            * * the unique TAKE25OFF voucher will be used by the user and we'll append a prefix according to the user's currency (geo location)
            * * it must be case sensitive
            */
            voucherCode: (currency && voucherCode === 'TAKE25OFF') ? `${currency}_${voucherCode}` : voucherCode,
            sport,
            vimondEnv
        })
    )
        .flatMapLatest(getVoucher);

    const voucherValidWithProduct$ = vimondVoucher$
        .combine(currentProduct$, (voucher, product) => ({voucher, product}))
        .flatMapLatest(({voucher, product}) => {
            if (!product || !voucher) {
                return false;
            }

            // !voucher.product is legacy GLOBAL voucher check. If it doesn’t relate to any product,
            // then it’s a global voucher. Maybe it would be better as
            // voucher.@voucherType === 'GENERAL' but this isn’t broken so I’m not going to fix it.
            const voucherIsForCurrentProduct = !voucher.product ||
                get(voucher, 'products', []).some((voucherProduct) => (
                    result(voucherProduct, 'id.toString') === product.id.toString()
                ));

            const voucherIsNotExpired = !voucher.expiry || moment().isBefore(moment(voucher.expiry));
            const voucherIsNotUsed = voucher.usages > 0;

            if (!voucherIsForCurrentProduct) {
                return bacon.Error({description: 'Voucher is not valid'});
            } else if (!voucherIsNotExpired) {
                return bacon.Error({description: 'Voucher has expired'});
            } else if (!voucherIsNotUsed) {
                return bacon.Error({description: 'Voucher has already been used'});
            }

            return true;
        })
        .startWith(false);

    const isVoucherValid$ = bacon.mergeAll(
        vimondVoucher$.errors().mapError(false), // can’t even get voucher
        voucherValidWithProduct$.mapError(false) // voucher isn’t valid
    );

    const voucherErrorMessage$ = bacon.mergeAll(
        productAndVoucher$.map(''),
        vimondVoucher$.errors().mapError('.response.body.error.description'),
        voucherValidWithProduct$.errors().mapError('.description')
    )
        .map((errorMessage) =>

        /*
        * feature HAWK-531 - VOUCHER: 25% discount voucher on Season Pass
        * * We must show this custom error message when the voucher is TAKE25OFF since we cannot change the generic 'Voucher with code 'xxxxx' was not found' message from VIMOND
        * * the regex should be /_TAKE25OFF'/ in order to match the "currency_TAKE25OFF" with the single quote from the message 'Voucher with code 'xxxxx' was not found'.
        *   Otherwise if the regex was only /TAKE25OFF/ it would match something like "TAKE25OFFa" or "aTAKE25OFF" as well.
        *   For cases like "TAKE25OFFa" or "aTAKE25OFF" we want to show the standard message saying that those vouchers were not found.
        */
            /_TAKE25OFF'/.test(errorMessage) ? 'Voucher with code \'TAKE25OFF\' does not apply to this pass' : errorMessage
        )
        .toProperty('');

    const currentProductWithProductPayment$ = bacon
        .combineTemplate({
            isVoucherValid: isVoucherValid$,
            product: currentProduct$,
            voucher: vimondVoucher$
        })
        .map(({isVoucherValid, product, voucher}) => ({
            product,
            sport,
            vimondEnv,
            voucher: isVoucherValid && voucher
        }))
        .flatMapLatest(getProductPayments)
        .map('.productPaymentList')
        .combine(
            currentProduct$,
            (productPayments, product) => Object.assign(
                {},
                product,
                {productPayments}
            ));

    const safeParseJson = (payload) => {
        try {
            return JSON.parse(payload) || {};
        } catch (e) {
            return {};
        }
    };

    const splitByNewline = ((str) => {
        if (!str) {
            return [];
        }

        return str.replace(/\n/g, '\\n').split('\\n');
    }); // Split by explicit '\n' characters coming from Vimond

    const productDescriptionText = currentProduct$
        .map('.description')
        .map((description) => description && description.replace(/\\"/g, ''))
        .map(safeParseJson)
        // MW-74 - Hide groupDescription for now, until someone needs it again
        // .combine(
        //     currentProductGroup$.map('.description'),
        // ({productShortDescription, voucherDescription, productRemaningDescription}, groupDescription) => ({
        .map(({productShortDescription, voucherDescription, productRemaningDescription}) => ({
            productShortDescription: splitByNewline(productShortDescription),
            productRemaningDescription: splitByNewline(productRemaningDescription),
            voucherDescription: splitByNewline(voucherDescription)
        }));

    const isVoucherLoading$ = bacon.mergeAll(
        voucher$.map(true),
        isVoucherValid$.mapError(false).map(false)
    )
        .toProperty(false);

    const validatedVoucherCode$ = bacon.combineWith(
        isVoucherValid$, voucher$, currency$,
        (isVoucherValid, voucherCode, currency) => {
            if (isVoucherValid && voucherCode) {
                /*
                * feature HAWK-531 - VOUCHER: 25% discount voucher on Season Pass
                * * the unique TAKE25OFF voucher will be used by the user and we'll append a prefix according to the user's currency (geo location)
                * * it must be case sensitive
                */
                return  (currency && voucherCode === 'TAKE25OFF') ? `${currency}_${voucherCode}` : voucherCode;
            } else {
                return '';
            }
        });

    const isPaymentRequired$ = currentProductWithProductPayment$
        .map('.productPayments.0')
        .map(({recurringDiscountedPrice, discountedPrice} = {}) => (
            recurringDiscountedPrice !== 0 || discountedPrice !== 0
        ));

    const freeAccessPayment$ = isPaymentRequired$
        .combine(vimondVoucher$, (isPaymentRequired, voucher) => {
            if (isPaymentRequired || !voucher) {
                return;
            }

            return {
                paymentMethod: 'VOUCHER',
                voucher: get(voucher, 'code', '')
            };
        });

    return bacon.combineTemplate({
        initialVoucher: voucher,
        isVoucherLoading: isVoucherLoading$,
        isVoucherOptional,
        isVoucherValid: isVoucherValid$,
        voucherError: voucherErrorMessage$,

        currencySymbol: currencySymbol$,
        currentProduct: currentProductWithProductPayment$, // Not the current product, we apply voucher and shit

        products: products$,

        productDescriptionText,

        validatedVoucherCode: validatedVoucherCode$,

        isPaymentRequired: isPaymentRequired$,
        freeAccessPayment: freeAccessPayment$,

        onVoucherChanged: (voucher) => { productAndVoucherBus.push({voucher}); }
    });
}
