import sortBy from 'lodash/sortBy';

export const CODEC_MAP_TO_CHANNEL_COUNT: Record<string, number> = {
    'audio/mp4;codecs="mp4a.40.2"': 2,
    'audio/mp4;codecs="ac-3"': 6,
    'audio/mp4;codecs="ec-3"': 8,
};

// Always provide the tracks in a consistent order based on codec
const CODECS_ORDER = [
    'audio/mp4;codecs="mp4a.40.2"', // Stereo
    'audio/mp4;codecs="ac-3"', // Dolby Digital 5.1'
    'audio/mp4;codecs="ec-3"', // Dolby Digital Plus'
];

// eslint-disable-next-line import/prefer-default-export
export function sortAudioTracksByCodec<
    T extends {codec: string | null | undefined}
>(tracks: T[]): T[] {
    return sortBy<T>(tracks, ({codec}) => CODECS_ORDER.indexOf(codec ?? ''));
}
