import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';
import Tabs from '@fsa-streamotion/streamotion-web-fs-ui/src/components/tabs/tabs';

import ProgrammeList from './programme-list';

export default class TvGuideUpcoming extends React.PureComponent {
    render() {
        let listContainer = null;

        if (this.props.isProgrammesLoading) {
            listContainer = <LoadingSpinner />;
        } else {
            listContainer = (
                <ProgrammeList
                    getSiblingOffsetHeight={() => this.tabsElement.offsetHeight}
                    programmes={this.props.programmes}
                    sportDetails={this.props.sportDetails} />
            );
        }

        // Normalised as it can be it's own widget.
        return (
            <div className="fsui-normalise fiso-hawk-tv-guide-upcoming">
                <div ref={(tabs) => { this.tabsElement = tabs; }} className="fiso-hawk-tv-guide-upcoming__tabs-container">
                    <Tabs
                        tabs={this.props.tabs}
                        selectedTabIndex={this.props.selectedTabIndex}
                        onTabClick={this.props.onTabsClick} />
                </div>
                <div className="fiso-hawk-tv-guide-upcoming__list-container">
                    {listContainer}
                </div>
            </div>
        );
    }
}

TvGuideUpcoming.defaultProps = {
    programmes: [],
    tabs: [],
    onTabsClick: noop
};

TvGuideUpcoming.propTypes = {
    sportDetails: propTypes.shape({
        route: propTypes.string
    }),
    isProgrammesLoading: propTypes.bool,

    tabs: propTypes.arrayOf(propTypes.node),
    selectedTabIndex: propTypes.number,

    programmes: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string,
        programmeTitle: propTypes.string,
        title: propTypes.string,
        live: propTypes.bool,
        replay: propTypes.bool,
        startTime: propTypes.string,
        endTime: propTypes.string
    })),

    onTabsClick: propTypes.func
};
