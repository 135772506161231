import {parse} from 'url';
import {version as playerSkinVersion} from '@fsa-streamotion/player-skin';
import {getDeviceUdid} from '../api';
import {getUserDetail} from '../auth';
import {version as hawkWidgetsVersion} from '../../../../package.json';

export default function getYouboraOptions(videoDetails, sport, videoSource) {
    return {
        'user.name': getUserDetail().id,
        'app.releaseVersion': `ps${playerSkinVersion}-hw${hawkWidgetsVersion}`,
        'content.transactionCode': getDeviceUdid(),
        'content.title': videoDetails?.title ?? '',
        'content.duration': videoDetails?.accurateDuration ?? 0,
        'content.cdn': 'AKAMAI',
        'content.isLive': videoDetails?.assetTypeName === 'live',
        'content.metadata': {
            content_id: videoDetails?.id ?? ''
        },
        'content.customDimension.1': sport === 'afl' ? 'watchafl' : 'watchnrl',
        'content.customDimension.6': videoDetails?.categoryTitle ?? '', // category
        'content.customDimension.7': videoDetails?.metadata?.['fixture-id']?.content ?? '', // eventName
        'content.customDimension.8': getVideoAssetType(videoDetails), // show
        'content.customDimension.9': getVideoDeliveryType(videoDetails), // deliveryType
        'content.customDimension.10': parse(videoSource)?.hostname, // resourceHostname
        'content.customDimension.13': videoDetails?.id ?? '',
    };
}

function getVideoAssetType(videoDetails) {
    return videoDetails?.assetTypeName === 'episode' ? (videoDetails?.categoryTitle ?? '') : '';
}

/**
 * Returns
 *     T for 24/7 channel (Fox Footy)
 *     L for live stream
 *     O for onDemand (VOD)
 *
 * @param  {Object} videoDetails Video Object from Vimond
 * @return {String}           Delivery Type mapped to Akamai tags.
 */
function getVideoDeliveryType(videoDetails) {
    const videoTitle = videoDetails?.title;
    const assetTypeName = videoDetails?.assetTypeName;
    const linearChannelNames = ['Fox Footy 24/7', 'Fox League 24/7'];

    if (linearChannelNames.includes(videoTitle) && assetTypeName === 'live') {
        return 'T';
    } else if (assetTypeName === 'live') {
        return 'L';
    } else {
        return 'O';
    }
}
