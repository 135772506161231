import {DEFAULT_ERROR_PREFIX} from './constants';
import logError from './util/log-error';

/**
 * Normalise an array or pipe|separated|string to an array.
 *
 * IMPORTANT: Empty string '' will return the defaultVal
 *
 * All other types, including number, will return defaultVal
 *
 * @param val         - Value to normalise (e.g. string or array)
 * @param defaultVal  - Default value (e.g. [1|2|3])
 * @param errorPrefix - The prefix for the error message. Defaults to `Transformers`.
 *
 * @returns provided value or defaultVal
 */
export default function asArray(
    val: unknown,
    defaultVal?: string[],
    errorPrefix = DEFAULT_ERROR_PREFIX
): string[] | undefined {
    try {
        if (Array.isArray(val)) {
            return val;
        }

        if (typeof val === 'string' && val !== '') {
            return val.split('|');
        }
    } catch (e) {
        logError(`${errorPrefix}: couldn't normalise arg asArray`, val, e);
    }

    return defaultVal;
}
