import superagent from 'superagent';

import {streamFromApi} from '../../utils/api';
import authenticated from '../authenticated';
import {MULTITENANCY_HEADER, VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function postCompletePaymentMethod({
    deviceData,
    nonce,
    paymentMethod,
    paymentType,
    sport = 'afl',
    userId,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/${userId}/userpaymentmethod/callback`;

    return authenticated().flatMapLatest((authToken) => {
        const request = superagent
            .post(resourceUrl)
            .query({
                userPaymentMethodId: paymentMethod.userPaymentMethod.id,
                deviceData,
                nonce,
                paymentType
            })
            .set(Object.assign(
                {},
                MULTITENANCY_HEADER[sport],
                {
                    'Accept': 'application/json',
                    'Authorization': authToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            ))
            .send(paymentMethod);

        return streamFromApi(request).map('.body');
    });
}
