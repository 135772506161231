export default class ViaccessOrcaAdapter {
  _getTagFromPlayer() {
    return this.player.videoElement ? this.player.videoElement : null;
  }

  checkExistsPlayer() {
    try {
      if (this._getTagFromPlayer()) {
        return this.checkExistsObjectOnPage(this._getTagFromPlayer());
      }
    } catch (err) {
      return true;
    }
  }

  getVersion() {
    return '7.0.2-viaccessorca-js';
  }

  getPlayhead() {
    return this.player.currentTime;
  }

  getPlayrate() {
    if (this.flags.isPaused) return 0;
    return this.player.videoElement.playbackRate || 1;
  }

  getDroppedFrames() {
    return this.player.videoElement.webkitDroppedFrameCount;
  }

  getDuration() {
    return this.player.duration;
  }

  getBitrate() {
    if (this.player.quality >= 0 && this.player.qualities && this.player.qualities.length) {
      var quality = this.player.qualities[this.player.quality];
      return quality.bitrate || quality.bandwidth;
    }
    return null;
  }

  getRendition() {
    if (this.player.quality >= 0 && this.player.qualities && this.player.qualities.length) {
      var quality = this.player.qualities[this.player.quality];
      var width = quality.width;
      var height = quality.height;
      if (!width || !height) {
        width = this.player.videoElement.videoWidth;
        height = this.player.videoElement.videoHeight;
      }
      return this.getNpawUtils().buildRenditionString(width, height, quality.bitrate || quality.bandwidth);
    }
    return null;
  }

  getTitle() {
    return this.player.videoElement.title || 'Unknown';
  }

  getIsLive() {
    return (typeof this.player.isLive === 'function') ? this.player.isLive() : this.player.isLive;
  }

  getResource() {
    return this.player && this.player.media ? this.player.media.url : undefined;
  }

  getPlayerVersion() {
    return this.player.version;
  }

  getPlayerName() {
    return 'Viaccess Orca';
  }

  registerListeners() {
    this.monitorPlayhead(false, false);
    this.references = {
      'play': this.playListener.bind(this),
      'pause': this.pauseListener.bind(this),
      'playing': this.playingListener.bind(this),
      'error': this.errorListener.bind(this),
      'seeking': this.seekingListener.bind(this),
      'seeked': this.seekedListener.bind(this),
      'buffering': this.bufferingListener.bind(this),
      'ended': this.endedListener.bind(this),
      'abort': this.endedListener.bind(this),
      'destroyed': this.destroyedListener.bind(this),
      'timeupdate': this.timeupdateListener.bind(this),
      'trackschanged': this.changedListener.bind(this)
    };
    for (var key in this.references) {
      if (this.player.addEventListener) {
        this.player.addEventListener(key, this.references[key]);
      }
      if (this.player.on) {
        this.player.on(key, this.references[key]);
      }
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        if (this.player.removeEventListener) {
          this.player.removeEventListener(key, this.references[key]);
          delete this.references[key];
        }
        if (this.player.off) {
          this.player.off(key, this.references[key]);
          delete this.references[key];
        }
      }
    }
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    this.fireStart({}, 'playListener');
    this.fireResume({}, 'playListener');
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    this.firePause({}, 'pauseListener');
  }

  playingListener(e) {
    this.firePlayerLog('playingListener', {});
    this.fireJoin({}, 'playingListener');
    this.fireBufferEnd({}, 'playingListener');
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    try {
      var error = e.data ? e.data : e;
      this.fireError(error.code, error.message, undefined, undefined, 'errorListener');
      this.fireStop({}, false, 'errorListener');
    } catch (err) {
      this.fireError('unknown', 'unknown', undefined, undefined, 'errorListener');
      this.fireStop({}, false, 'errorListener');
    }
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener');
  }

  seekedListener(e) {
    this.firePlayerLog('seekedListener', {});
    this.fireSeekEnd({}, 'seekedListener');
  }

  bufferingListener(e) {
    this.firePlayerLog('bufferingListener', {
      'buffering': e.buffering
    });
    if (e.buffering === true) {
      if (!this.flags.isPaused && !this.flags.isSeeking) {
        this.fireBufferBegin({}, false, 'bufferingListener');
      }
    } else if (e.buffering === false) {
      this.fireBufferEnd({}, 'bufferingListener');
    }
  }

  endedListener(e) {
    this.firePlayerLog('endedListener', {});
    this.fireStop({}, 'endedListener');
  }

  destroyedListener(e) {
    this.firePlayerLog('destroyedListener', {});
    this.fireStop({}, 'destroyedListener');
  }

  changedListener(e) {
    if (e.emittedBy && e.emittedBy === 'manifest') {
      this.firePlayerLog('changedListener', {});
      if (this.flags.isJoined) {
        this.fireStop({}, 'changedListener');
      }
    }
  }

  timeupdateListener(e) {

  }

}