import {
    getLocalStorageValue,
    removeLocalStorageValue,
    setLocalStorageValue,
} from '@fsa-streamotion/browser-utils';

import type {PlayerQualityLevel} from '../types';
import {USER_SELECTED_PLAYER_QUALITY} from './storage-keys';

export type PlayerQuality = {
    level: PlayerQualityLevel;
    height: number;
};

export const DEFAULT_PLAYER_QUALITY: PlayerQuality = {
    level: 'auto',
    height: 0,
};

export function getVideoQualityPreference(): PlayerQuality {
    return getLocalStorageValue({
        key: USER_SELECTED_PLAYER_QUALITY,
        defaultValue: DEFAULT_PLAYER_QUALITY,
    }) as PlayerQuality;
}

export function setVideoQualityPreference(playerQuality: PlayerQuality): void {
    setLocalStorageValue({
        key: USER_SELECTED_PLAYER_QUALITY,
        value: playerQuality,
    });
}

export function removeVideoQualityPreference(): void {
    removeLocalStorageValue(USER_SELECTED_PLAYER_QUALITY);
}
