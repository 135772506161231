import React from 'react';
import propTypes from 'prop-types';

const Banner = ({
    isBannerVisible,
    activeBanner = {},
    promotionalPassUrl
}) => {
    if (!isBannerVisible || !Object.keys(activeBanner).length || !promotionalPassUrl) {
        return null;
    }

    return (
        <figure className="fiso-hawk-banner">
            <a
                href={promotionalPassUrl}
                className="fiso-hawk-banner__link">
                <img
                    src={activeBanner.bannerSmall}
                    width={activeBanner.bannerSmallWidth}
                    height={activeBanner.bannerSmallHeight}
                    alt=""
                    className="fiso-hawk-banner__image" />
                <img
                    src={activeBanner.bannerLarge}
                    width={activeBanner.bannerLargeWidth}
                    height={activeBanner.bannerLargeHeight}
                    alt=""
                    className="fiso-hawk-banner__image
                        fiso-hawk-banner__image--large" />
                <figcaption className="fsui-commons-visually-hidden">
                    {activeBanner.altText}
                </figcaption>
            </a>
        </figure>
    );
};

Banner.propTypes = {
    promotionalPassUrl: propTypes.string,

    activeBanner: propTypes.shape({
        bannerSmall: propTypes.string,
        bannerSmallWidth: propTypes.number,
        bannerSmallHeight: propTypes.number,

        bannerLarge: propTypes.string,
        bannerLargeWidth: propTypes.number,
        bannerLargeHeight: propTypes.number,

        altText: propTypes.string
    }),
    isBannerVisible: propTypes.bool
};

export default Banner;
