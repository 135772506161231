// Generate SHA-512 integrity hashes via:
// openssl dgst -sha512 -binary FILENAME.js | openssl base64 -A
const VIDEO_LIBRARY_URLS = {
    hlsjs: {
        src: 'https://resources.streamotion.com.au/immutable/web/video-libraries/hlsjs/1.4.10/hls.min.js',
        integrity:
            'sha512-Tw2aUDnE3Mn2vIh5gwdBAib92rjv8lQbJD4gMue057/ePZrRUDTKP3ryMgAWwVUCWUmapcSgh/iNTL4Z4iADng==',
        version: '1.4.10',
    },
    dashjs: {
        src: 'https://resources.streamotion.com.au/immutable/web/video-libraries/dashjs/4.7.4/dash.all.min.js',
        integrity:
            'sha512-LyDgm9kfqyKlZOe+QjpNA6L/ZpcjNj+cKSJ/bQLTGkKXaxYNpYGN9Fe6DpI0H0w3Da2WcXVX8ACjL14y3iWGBQ==',
        version: '4.7.4',
    },
    rxplayer: {
        src: 'https://resources.streamotion.com.au/immutable/web/video-libraries/rxplayer/4.1.0-es5/rx-player.min.js',
        integrity:
            'sha512-OeREaZEmvkHax39fbDuDjpBWAV05JNnrI8lmy8h3ImD5I00Wn/Gu76W+VmSI34oUSia/YF9+glBDzFxDYeWztg==',
        version: '4.1.0',
    },
} as const;

export default VIDEO_LIBRARY_URLS;
