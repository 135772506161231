import {
    defaultPlaybackData,
    type PlaybackData,
    type TileAnalytics,
    type PlayerState,
} from '@fsa-streamotion/player-state';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import debounce from 'lodash/debounce';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';

import {transition} from '../../../common/animations';
import {coal, white, black} from '../../../common/palette';
import ResponsiveSizer from '../../../common/responsive-sizer';
import {
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
} from '../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../common/theme-helpers';
import {
    IC152MultiAdd1,
    IC153MultiAdd2,
    IC154MultiAdd3,
    IC155MultiAdd4,
    IC134Minus,
    IC17Plus,
    type Props as IcProps,
} from '../../../component-library/atoms/ic';
import {usePlayerContext} from '../context';

const SCREEN_NUMBER_BADGE_Z_INDEX = 1;

const ScreenNumberBadge = styled.span`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: ${SCREEN_NUMBER_BADGE_Z_INDEX};
    width: 20px;
    height: 20px;
    pointer-events: none; /* Let the click fall through to the card underneath */

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 30px;
        height: 30px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 40px;
        height: 40px;
    `}
`;

const StyledResponsiveSizer = styled(ResponsiveSizer)`
    position: relative;
    margin: auto 0;
    overflow: visible;
`;

type AddIconsProps = {
    textColor?: string;
} & IcProps;

const MULTI_ADD_ICONS: React.FC<AddIconsProps>[] = [
    IC152MultiAdd1,
    IC153MultiAdd2,
    IC154MultiAdd3,
    IC155MultiAdd4,
];

const BackingCard = styled.div<{disableHoverScaling: boolean}>`
    position: relative;
    transform-origin: center;
    transition: ${transition('transform')};
    border-radius: 4px;
    background-color: ${coal};
    width: 100%;
    height: 100%;

    &:hover,
    &:focus-within {
        transform: scale(
            ${({disableHoverScaling}) => (disableHoverScaling ? 1 : 1.05)}
        );

        &::after {
            position: absolute;
            right: 10px;
            bottom: -7px;
            left: 10px;
            border-radius: 1px;
            background-color: ${THEME_ACCESSORS.brandColor};
            height: ${({disableHoverScaling}) =>
                disableHoverScaling ? 0 : 2}px;
            content: '';
        }
    }
`;

type Props = {
    id: string;
    render: (element: HTMLElement) => () => void;
    playVideo: ({
        setPlaybackData,
        screenIndex,
        setKeyEventData,
    }: {
        setPlaybackData: PlayerState['setPlaybackData'];
        screenIndex: number;
        setKeyEventData: PlayerState['setKeyEventData'];
    }) => Promise<void>;
    tileAnalytics: TileAnalytics;
    checkIsPlayable?: () => boolean;
};

const RelatedVideoTile = ({
    render,
    id,
    playVideo,
    tileAnalytics,
    checkIsPlayable = () => true,
}: Props): React.ReactElement => {
    const playerState = usePlayerContext();
    const [isFocused, setIsFocused] = useState(false);
    const {brandColor, isRelVideoHoverScalingDisabled} = useTheme();
    const {globalActions, globalState, generalConfig, screenConfigs} =
        playerState ?? {};
    const screenVideoIds = screenConfigs?.map(
        ({playbackData}) => playbackData.id
    );
    const reservationIds = screenConfigs?.map((config) => config.reservationId);

    const screenIndexLoaded = screenVideoIds?.findIndex(
        (videoId) => id && videoId === id
    );
    const screenIndexLoading = reservationIds?.findIndex(
        (videoId) => id && videoId === id
    );

    // Calling setPlaybackData doesn't actually trigger a re-render for this guy, so we track setShowingInScreenIndex in component state
    const [showingInScreenIndex, setShowingInScreenIndex] = useState(
        screenIndexLoaded === -1 ? screenIndexLoading : screenIndexLoaded
    );
    const userReadableScreenNumber =
        typeof showingInScreenIndex === 'number' &&
        typeof globalState?.screenOrder[showingInScreenIndex] === 'number' &&
        (globalState?.screenOrder[showingInScreenIndex] as number) + 1;
    const containerRef = useRef<HTMLDivElement>(null);
    const isInSplitView = globalState?.isInSplitViewMode;
    const shouldShowNumberIcons = showingInScreenIndex !== -1 && isInSplitView;
    const isScreenPlaying = isInSplitView && showingInScreenIndex !== -1;
    const ScreenNumberIconComponent = userReadableScreenNumber
        ? (MULTI_ADD_ICONS[
              userReadableScreenNumber - 1
          ] as React.FC<AddIconsProps>)
        : () => null;

    useEffect(
        function renderContent() {
            // Renders the tile inside containerRef
            const cleanup =
                containerRef.current && render?.(containerRef.current);

            return () => {
                if (typeof cleanup === 'function') {
                    cleanup();
                }
            };
            // Re-run this if the id changes (because it means the tile will need to change)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    useEffect(
        function sreenIndexHasUpdated() {
            setShowingInScreenIndex(
                screenIndexLoaded === -1
                    ? screenIndexLoading
                    : screenIndexLoaded
            );
        },
        [isInSplitView, screenIndexLoaded, screenIndexLoading]
    );

    const focusAndHoverEventHandler = (isFocused: boolean) => () => {
        setIsFocused(isFocused);

        if (shouldShowNumberIcons && typeof showingInScreenIndex === 'number') {
            globalActions?.setFocusedScreenIndex(showingInScreenIndex);
        }
    };

    return (
        <StyledResponsiveSizer height={9} width={16}>
            <BackingCard // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                /*
                    Yeah it's not really semantic to have an onClick on a div, but we actually expect the child rendered
                    inside here to be a button or similar - this `onClick` listener will just catch the propagated event
                 */
                disableHoverScaling={!!isRelVideoHoverScalingDisabled}
                onClick={debounce(
                    async () => {
                        // Check if the asset is playable at the moment
                        if (!checkIsPlayable()) {
                            return;
                        }

                        // Disabled removing the video when it's loading, we can't cancel the currently fetching content on the client.
                        if (screenIndexLoading !== -1) {
                            return;
                        }

                        // This video is already on the screen, remove it
                        if (
                            isScreenPlaying &&
                            typeof showingInScreenIndex === 'number'
                        ) {
                            playerState?.setReservationId(
                                showingInScreenIndex,
                                null
                            );
                            playerState?.setPlaybackData(
                                defaultPlaybackData,
                                showingInScreenIndex
                            );
                            setShowingInScreenIndex(-1);
                            generalConfig?.onPlayerInteractionByType(
                                'splitview-remove',
                                {
                                    ...tileAnalytics,
                                    screenIndex: showingInScreenIndex,
                                }
                            );

                            return;
                        }

                        const nextScreenIndex =
                            playerState?.nextAvailableScreenIndex;
                        const targetScreenIndex =
                            globalState?.isInSplitViewMode &&
                            typeof nextScreenIndex === 'number' &&
                            nextScreenIndex >= 0
                                ? nextScreenIndex // The index of the next screen to insert video.
                                : globalState?.activeScreenIndex; // Single layout, insert to active screen index.

                        if (typeof targetScreenIndex === 'number') {
                            playerState?.setReservationId(
                                targetScreenIndex,
                                id
                            );
                        }

                        const setPlaybackData = (
                            playbackData: PlaybackData
                        ): void =>
                            playerState?.setPlaybackData(
                                playbackData,
                                targetScreenIndex
                            );

                        const setKeyEventData: PlayerState['setKeyEventData'] =
                            (keyEvents, config) => {
                                playerState?.setKeyEventData(keyEvents, config);
                            };

                        // Users can choose to use the provided setPlaybackData callback or define their own with the provided screenIndex.
                        if (typeof targetScreenIndex === 'number') {
                            await playVideo({
                                setPlaybackData,
                                screenIndex: targetScreenIndex,
                                setKeyEventData,
                            });
                            // should this be before?
                            generalConfig?.onPlayerInteractionByType(
                                globalState?.isInSplitViewMode
                                    ? 'splitview-add'
                                    : 'carousel-click',
                                {
                                    ...tileAnalytics,
                                    screenIndex: targetScreenIndex,
                                }
                            );
                            setShowingInScreenIndex(targetScreenIndex);
                        }
                    },
                    500,
                    {leading: true, trailing: false}
                )}
                onMouseOver={focusAndHoverEventHandler(true)}
                onMouseLeave={focusAndHoverEventHandler(false)}
                onFocus={focusAndHoverEventHandler(true)}
                onBlur={focusAndHoverEventHandler(false)}
                ref={containerRef}
            />
            {isInSplitView && (
                <ScreenNumberBadge>
                    {!isScreenPlaying && (
                        <IC17Plus
                            color={isFocused ? brandColor : white}
                            textColor={isFocused ? white : black}
                            ariaLabel={`Add video to screen ${userReadableScreenNumber}`}
                        />
                    )}
                    {!isFocused && isScreenPlaying && (
                        <ScreenNumberIconComponent
                            color={white}
                            ariaLabel={`Now playing in screen ${userReadableScreenNumber}`}
                        />
                    )}
                    {isFocused && isScreenPlaying && (
                        <IC134Minus
                            color={brandColor}
                            accentColor={white}
                            ariaLabel={`Remove screen ${userReadableScreenNumber}`}
                        />
                    )}
                </ScreenNumberBadge>
            )}
        </StyledResponsiveSizer>
    );
};

RelatedVideoTile.displayName = 'RelatedVideoTile';

export default observer(RelatedVideoTile);
