import {isBrowser} from '@fsa-streamotion/browser-utils';

import propTypes from 'prop-types';
import React, {
    forwardRef,
    type ForwardedRef,
    type HTMLProps,
    type JSX,
} from 'react';

import rangeTouchStyle from './range-touch-style';
import useRangeTouch from './use-range-touch';
import useMultiRef from './util/use-multi-ref';

type Props = Omit<HTMLProps<HTMLInputElement>, 'type'> & {
    thumbWidth?: number;
};

function RangeTouchInputInner({
    innerRef,
    thumbWidth,
    style,
    ...restProps
}: Props & {innerRef: ForwardedRef<HTMLInputElement>}): JSX.Element {
    const rangeTouchRef = useRangeTouch({thumbWidth});
    const ref = useMultiRef(rangeTouchRef, innerRef);

    return (
        <input
            {...restProps}
            ref={ref}
            type="range"
            style={{
                ...style,
                ...rangeTouchStyle,
            }}
        />
    );
}

RangeTouchInputInner.propTypes = {
    innerRef: propTypes.oneOfType([
        propTypes.func,
        propTypes.shape({
            current: isBrowser()
                ? propTypes.instanceOf(HTMLInputElement)
                : propTypes.object,
        }),
    ]),
    thumbWidth: propTypes.number,
    style: propTypes.object,
};

RangeTouchInputInner.displayName = 'RangeTouchInputInner';

/**
 * Input element that uses {@link useRangeTouch} to play nice on iOS Safari.
 *
 * In addition to using the hook, this also adds CSS to the input to complement the hook logic.
 */
const RangeTouchInput = forwardRef<HTMLInputElement>((props: Props, ref) => (
    <RangeTouchInputInner {...props} innerRef={ref} />
));

RangeTouchInput.displayName = 'RangeTouchInput';

export default RangeTouchInput;
