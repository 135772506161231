import {Observable} from 'rxjs';

/**
 * Create an RxJS <code>Observable</code> from a <code>Promise</code>-returning callback.
 *
 * The observable is cancellable via an <code>AbortController</code>.
 *
 * You may need to polyfill <code>AbortController</code> for older browsers, otherwise cancellation won't be supported.
 *
 * @param callback - a promise-returning callback that's provided an AbortSignal if the browser supports it
 * @returns an observable that wraps the promise
 */
export default function fromPromiseCallback<T>(
    callback: (signal?: AbortSignal) => Promise<T>
): Observable<T> {
    return new Observable((subscriber) => {
        const controller =
            typeof AbortController === 'undefined'
                ? undefined
                : new AbortController(); // eslint-disable-line compat/compat
        const promise = controller ? callback(controller.signal) : callback();

        promise
            .then((value) => subscriber.next(value))
            .catch((error) => {
                if (
                    !(error instanceof DOMException) ||
                    error.name !== 'AbortError'
                ) {
                    subscriber.error(error);
                }
            })
            .finally(() => subscriber.complete());

        return () => controller?.abort();
    });
}
