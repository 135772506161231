import bacon from 'baconjs';

import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {USER_LOCAL_STORAGE_KEY} from '../../utils/constants';
import NEW_USER_STREAM from '../new-user';

export default function () {
    return bacon.update(
        getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY}, null),
        NEW_USER_STREAM, () => getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY}, null)
    );
}
