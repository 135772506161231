import {waitFor} from '@fsa/fs-commons/lib/utils/wait-for';

export function facebookPixelTrackAddToCart({
    type
}) {
    waitForFacebookPixel()
        .then(() => {
            window.fbq('track', 'AddToCart', {
                content_type: type
            });
        })
        .catch(() => {
            console.info('Facebook pixel not available for AddToCart'); // eslint-disable-line no-console
        });
}

export function facebookPixelTrackCurrencyConversion({
    value,
    currency
}) {
    waitForFacebookPixel()
        .then(() => {
            window.fbq('track', 'Purchase', {
                value,
                currency
            });
        })
        .catch(() => {
            console.info('Facebook pixel not available for CurrencyConversion'); // eslint-disable-line no-console
        });
}

function waitForFacebookPixel() {
    return waitFor({
        testFunction: () => {
            if (process.browser) {
                return !!window.fbq;
            } else {
                return true;
            }
        },
        retries: 40,
        delay: 250
    });
}
