/// <reference types="user-agent-data-types" />
import isNil from './util/is-nil';

type BrowserPrefix = '' | 'webkit' | 'o' | 'moz' | 'ms' | 'khtml';
type PlatformName =
    | 'ios'
    | 'mac'
    | 'android'
    | 'windows'
    | 'linux'
    | 'chromeos';
type BrowserType = 'edge' | 'firefox' | 'safari' | 'chrome';

const HISENSE_KEYS = [
    'Hisense',
    'Hisense_GetFirmWareVersion',
    'Hisense_GetDeviceID',
    'Hisense_GetModelName',
] as const;

function windowHasProperty(key: string): boolean {
    return Object.prototype.hasOwnProperty.call(window, key);
}

/**
 * Are we currently in a browser environment?
 *
 * This returns <code>typeof window !== 'undefined'</code> unless <code>window.docsHelper.isBrowser</code> exists, in which case
 * <code>!!window.docsHelper.isBrowser</code> is returned instead, allowing us to simulate not being in a browser for the purpose of
 * simulating server-side rendering in docs pages running in the browser.
 *
 * @returns whether we're in a browser or pretending to be in a browser
 */
export function isBrowser(): boolean {
    if (typeof window !== 'undefined') {
        return window.docsHelper?.isBrowser ?? true;
    }

    return false;
}

/**
 * Are we currently in a server environment?
 *
 * Uses the inverse of isBrowser(), please see that function above
 *
 * @returns whether we're in a server or pretending to be in a server
 */
export function isServer(): boolean {
    return !isBrowser();
}

/**
 * Are we currently in an Apple computer's Safari browser?
 *
 * @returns whether we're in an Apple computer's Safari browser
 */
export function isSafariComputer(): boolean {
    return (
        isBrowser() &&
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor)
    );
}

/**
 * Are we currently in a Safari browser?
 *
 * @returns whether we're in a Safari browser
 */
export function isSafari(): boolean {
    return (
        isBrowser() &&
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent) &&
        !/PlayStation/.test(navigator.userAgent)
    );
}

/**
 * Are we currently in an iOS system?
 *
 * @returns whether we're in an iOS system
 */
export function isIos(): boolean {
    if (!isBrowser() || windowHasProperty('MSStream')) {
        return false;
    }

    const {userAgent, maxTouchPoints} = window.navigator;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return true;
    }

    // Safari on iPadOS pretends to be running on macOS (i.e. request desktop site) by default.
    // Detect it via maxTouchPoints, which is 0 on macOS and >= 2 on iPadOS.
    return (
        /Mac/.test(userAgent) &&
        typeof maxTouchPoints === 'number' &&
        maxTouchPoints >= 2
    );
}

/**
 * Are we currently on a Windows system?
 *
 * @returns whether we're on a Windows system
 */
export function isWindows(): boolean {
    // eslint-disable-next-line compat/compat
    return isBrowser() && navigator.userAgentData?.platform === 'Windows';
}

/**
 * Are we currently in a Microsoft Edge browser?
 *
 * @returns whether we're in a Microsoft Edge browser
 */
export function isMsEdge(): boolean {
    return isMsEdgeChromium() || isMsEdgeLegacy();
}

/**
 * Are we currently in a Firefox browser?
 *
 * @returns whether we're in a Firecox browser
 */
export function isFirefox(): boolean {
    return isBrowser() && /Firefox/.test(navigator.userAgent);
}

/**
 * Are we currently on a Chromecast device?
 *
 * @returns whether we're on a Chromecast device
 */
export function isChromecast(): boolean {
    return isBrowser() && /CrKey/.test(navigator.userAgent);
}

/**
 * Are we currently in a Microsoft Edge (Chromium) browser?
 *
 * @returns whether we're in a Microsoft Edge (Chromium) browser
 */
export function isMsEdgeChromium(): boolean {
    return (
        isBrowser() &&
        // eslint-disable-next-line compat/compat
        !!navigator?.userAgentData?.brands?.some(
            ({brand}) => brand === 'Microsoft Edge'
        )
    );
}

/**
 * Are we currently in a Microsoft Edge (EdgeHTML/Legacy) browser?
 *
 * @returns whether we're in a Microsoft Edge (EdgeHTML/Legacy) browser
 */
export function isMsEdgeLegacy(): boolean {
    return isBrowser() && /Edge/.test(navigator.userAgent);
}

/**
 * Are we currently in a Chrome browser?
 *
 * @returns whether we're in a Chrome browser
 */
export function isChrome(): boolean {
    return (
        isBrowser() &&
        !isMsEdgeChromium() &&
        !isSafari() &&
        /Chrome/.test(navigator.userAgent)
    );
}

/**
 * Are we currently on a Webmaf environment?
 *
 * @returns whether we're on a Webmaf environment
 */
export function isWebmaf(): boolean {
    return (
        isBrowser() &&
        window.external && // eslint-disable-line compat/compat
        Object.prototype.hasOwnProperty.call(window.external, 'user') // eslint-disable-line compat/compat
    );
}

/**
 * Are we currently on a Hisense environment?
 *
 * @returns whether we're on a Hisense environment
 */
export function isHisense(): boolean {
    return isBrowser() && HISENSE_KEYS.some(windowHasProperty);
}

/**
 * Are we currently on a LG device?
 *
 * @returns whether we're on a LG device
 */
export function isLg(): boolean {
    return isBrowser() && (window.isLGWebOs ?? false);
}

/**
 * Are we currently on a LG 2020 device?
 *
 * @returns whether we're on a LG 2020 device
 */
export function isLg2020(): boolean {
    return isLg() && /Chrome\/79/.test(navigator.userAgent);
}

/**
 * Are we currently on a Playstation 5 device?
 *
 * @returns whether we're in a PS5 device
 */
export function isPs5(): boolean {
    return isBrowser() && /PlayStation 5/.test(navigator.userAgent);
}

/**
 * Are we currently on a Playstation 4 Webmaf 3 device?
 *
 * @returns whether we're in a PS5 device
 */
export function isPs4(): boolean {
    return isBrowser() && /PlayStation 4/.test(navigator.userAgent);
}

/**
 * Our own EME implementation, which invokes `videoElement.setMediaKeys()` before playback actually encounters DRM periods,
 * helps reduce the buffer after the pre-roll ads on most of devices,
 * but results in playback can not be played at all on some Hisenses with specific chipset/firmware(NT72671D/V0000.06.12N.N0622).
 *
 * @returns whether should use EME from dash.js
 */
export function shouldUseDashjsEmeForHisense(): boolean {
    if (!isHisenseU6()) {
        return false;
    }

    const chipset = window.Hisense_GetDeviceInfo?.()?.chipset?.toUpperCase(); // eslint-disable-line new-cap

    if (!chipset) {
        return false;
    }

    return isHisenseU6() && ['NT72671D'].includes(chipset);
}

/**
 * Are we currently on a Hisense U5 environment?
 *
 * @returns whether we're on a Hisense U5 environment
 */
export function isHisenseU5(): boolean {
    if (!isHisense()) {
        return false;
    }

    const osVersion = window.Hisense_GetDeviceInfo?.().osVersion; // eslint-disable-line new-cap

    if (!osVersion) {
        return false;
    }

    return /^U5/.test(osVersion);
}

/**
 * Are we currently on a Hisense U6 environment?
 *
 * @returns whether we're on a Hisense U6 environment
 */
export function isHisenseU6(): boolean {
    if (!isHisense()) {
        return false;
    }

    const osVersion = window.Hisense_GetOSVersion?.(); // eslint-disable-line new-cap

    if (!osVersion) {
        return false;
    }

    // eslint-disable-next-line new-cap
    return /^U06/.test(osVersion);
}

/**
 * Are we currently on a Tizen environment?
 *
 * @returns whether we're on a Tizen environment
 */
export function isTizen(): boolean {
    return (
        isBrowser() &&
        !!window.webapis &&
        Object.prototype.hasOwnProperty.call(window.webapis, 'avplay')
    );
}

/**
 * Are we currently on a Tizen 2017?
 *
 * @returns whether we're on a Tizen 2017
 */
export function isTizen2017(): boolean {
    return isTizen() && getTizenPlatformVersion() === '3.0';
}

/**
 * Are we currently on a Tizen 2018?
 *
 * @returns whether we're on a Tizen 2018
 */
export function isTizen2018(): boolean {
    return isTizen() && getTizenPlatformVersion() === '4.0';
}

/**
 * Are we currently on a Tizen 2019?
 *
 * @returns whether we're on a Tizen 2019
 */
export function isTizen2019(): boolean {
    return isTizen() && getTizenPlatformVersion() === '5.0';
}

/**
 * Are we currently on a Tizen 2020?
 *
 * @returns whether we're on a Tizen 2020
 */
export function isTizen2020(): boolean {
    return isTizen() && getTizenPlatformVersion() === '5.5';
}

/**
 * Are we currently on a Tizen 2021?
 *
 * @returns whether we're on a Tizen 2021
 */
export function isTizen2021(): boolean {
    return isTizen() && getTizenPlatformVersion() === '6.0';
}

/**
 * Are we currently on a Tizen 2022?
 *
 * @returns whether we're on a Tizen 2022
 */
export function isTizen2022(): boolean {
    return isTizen() && getTizenPlatformVersion() === '6.5';
}

/**
 * Are we currently on a Tizen 2023?
 *
 * @returns whether we're on a Tizen 2023
 */
export function isTizen2023(): boolean {
    return isTizen() && getTizenPlatformVersion() === '7.0';
}

/**
 * Are we currently on a Xbox
 *
 * @returns whether we're on a Xbox
 */
export function isXbox(): boolean {
    return isBrowser() && /Xbox/.test(window.navigator.userAgent);
}

/**
 * Are we currently in full screen mode?
 *
 * @returns whether we're in full screen mode
 */
export function isFullScreen(): boolean {
    if (!isBrowser()) {
        return false;
    }

    // This is the standard full screen property, so when it exists, don't perform legacy fullscreen checks.
    if (Object.prototype.hasOwnProperty.call(document, 'fullscreenElement')) {
        return document.fullscreenElement !== null;
    }

    // Check legacy/deprecated full screen properties
    return !!(
        document.fullscreen || // eslint-disable-line compat/compat
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        !isNil(document.msFullscreenElement) ||
        document.oFullscreen ||
        document.khtmlFullscreen
    );
}

type FullscreenElementKey =
    | 'fullscreenElement'
    | 'mozFullScreenElement'
    | 'webkitFullscreenElement'
    | 'msFullscreenElement'
    | 'oFullscreenElement'
    | 'khtmlFullscreenElement';

const FULLSCREEN_ELEMENT_KEYS: Record<BrowserPrefix, FullscreenElementKey> = {
    '': 'fullscreenElement',
    'webkit': 'webkitFullscreenElement',
    'moz': 'mozFullScreenElement',
    'ms': 'msFullscreenElement',
    'o': 'oFullscreenElement',
    'khtml': 'khtmlFullscreenElement',
};

/**
 * Is the element in full screen mode?
 *
 * @param element - The element we are checking
 *
 * @returns whether we're in full screen mode
 */
export function isElementFullscreen(element: Element): boolean {
    const browser = isBrowser();

    if (!browser) {
        return false;
    }

    const fullscreenPrefix = getFullscreenPrefix(browser);

    if (fullscreenPrefix === undefined) {
        return false;
    }

    const fullscreenElement = element || document.body;

    return (
        document[FULLSCREEN_ELEMENT_KEYS[fullscreenPrefix]] ===
        fullscreenElement
    );
}

/**
 * Does the browser support AirPlay?
 *
 * @returns whether the browser supports AirPlay
 */
export function supportsAirPlay(): boolean {
    return (
        isBrowser() &&
        (isIos() || isSafari()) &&
        windowHasProperty('WebKitPlaybackTargetAvailabilityEvent')
    );
}

/**
 * Does the browser support fullscreen mode?
 *
 * @returns whether the browser supports fullscreen mode
 */
export function supportsFullscreen(): boolean {
    const browser = isBrowser();

    return browser && getFullscreenPrefix(browser) !== undefined;
}

/**
 * Gets the browser-specific full screen prefix.
 *
 * @param isBrowser - whether we are in a browser
 *
 * @returns the full screen prefix
 */
export function getFullscreenPrefix(
    isBrowser: boolean
): BrowserPrefix | undefined {
    if (!isBrowser) {
        return '';
    }

    // Native fullscreen support. No prefix needed.
    if (typeof document.exitFullscreen === 'function') {
        return '';
    }

    if (document.mozCancelFullScreen) {
        return 'moz';
    }

    if (document.webkitExitFullscreen) {
        return 'webkit';
    }

    if (document.oExitFullscreen) {
        return 'o';
    }

    if (document.khtmlExitFullscreen) {
        return 'khtml';
    }

    return undefined;
}

/**
 * Get the platform name
 *
 * @returns the platform name
 */
export function getPlatformName(): null | PlatformName {
    if (!isBrowser()) {
        return null;
    }

    const {userAgent} = window.navigator;

    if (/Mac/.test(userAgent)) {
        if (isIos()) {
            return 'ios';
        }

        return 'mac';
    }

    if (/Android/.test(userAgent)) {
        return 'android';
    }

    if (/Win/.test(userAgent)) {
        return 'windows';
    }

    if (/\bCrOS\b/.test(userAgent)) {
        return 'chromeos';
    }

    if (/Linux/.test(userAgent)) {
        return 'linux';
    }

    return null;
}

/**
 * Get browser type
 *
 * @returns browser type
 */
export function getBrowserType(): null | BrowserType {
    if (!isBrowser()) {
        return null;
    }

    if (isMsEdge()) {
        return 'edge';
    }

    if (isFirefox()) {
        return 'firefox';
    }

    if (isSafari()) {
        return 'safari';
    }

    if (isChrome()) {
        return 'chrome';
    }

    return null;
}

function getTizenPlatformVersion(): string | undefined {
    try {
        return window.tizen?.systeminfo.getCapability(
            'http://tizen.org/feature/platform.version'
        );
    } catch (e) {
        console.error(
            'PlayerTech ERROR: Unable to get tizen platform version',
            e
        );

        return undefined;
    }
}
