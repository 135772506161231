import {isIos} from '@fsa-streamotion/browser-utils';
import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import noop from 'lodash/noop';
import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import BA01CtrlBtn from '../../../atoms/ba/01-ctrl-btn';
import IA01VertRange from '../../../atoms/ia/01-vert-range';
import {IC21Mute, IC04Vol} from '../../../atoms/ic';

const VolumeControlContainer = styled.div`
    position: relative;
    width: 40px;
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 70px;
    `}
`;

const StyledIA01VertRange = styled(IA01VertRange)`
    position: absolute;
    top: -10px;
    left: 50%;
    /* this is required to sit above the progress bar in the player */
    z-index: 1; /* stylelint-disable-line */

    &::before {
        /* This element is to retain mouse "hover" state when mouse is in the gutter between button and slider */
        position: absolute;
        left: -20px;
        width: 30px;
        height: 42px;
        content: '';
    }
`;

export type Props = {
    /** Volume percentage (0-1) */
    volume?: number;
    /** Is muted (0-1) */
    isMuted?: boolean;
    /** Callback on focus */
    onFocus?: React.FocusEventHandler;
    /** Callback on toggle mute */
    setMutedFunction?: (isMuted: boolean) => void;
    /** Callback on volume changed */
    setVolumeFunction?: (volume: number) => void;
    /** Callback on key down */
    onKeyDown?: React.KeyboardEventHandler;
    /** Class names */
    className?: string;
    /** Tab index of the input button */
    tabIndex?: -1 | 0;
};

const IM01InputVol = ({
    volume = 1,
    isMuted = true,
    onFocus = noop,
    setMutedFunction = noop,
    setVolumeFunction = noop,
    onKeyDown,
    className,
    tabIndex = 0,
}: Props): React.ReactElement => {
    const [isHovered, setIsHovered] = useState(false);
    const [isTouchOpened, setIsTouchOpened] = useState(false);
    const [isKeyboardFocused, setIsKeyboardFocused] = useState(false);

    const isTouchedRecently = useRef<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const volumeButtonRef = useRef<HTMLButtonElement>(null);

    function handleTouchStart(event: React.TouchEvent<HTMLDivElement>): void {
        isTouchedRecently.current = true;

        setTimeout(() => {
            isTouchedRecently.current = false;
        });

        if (isIos()) {
            // On IOS devices, the volume property is not settable in JavaScript. Limiting the feature to mute toggle.
            // see https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html
            event.preventDefault();
            setMutedFunction(!isMuted);
        } else if (
            volumeButtonRef.current === event.target ||
            volumeButtonRef.current?.contains(event.target as HTMLDivElement)
        ) {
            // Only prevent the default if we're on the icon to open/close.
            event.preventDefault();
            setIsTouchOpened(!isTouchOpened);
        }
    }

    function handleVolumeButtonClick(event: React.MouseEvent): void {
        event.preventDefault();

        if (!isTouchedRecently.current) {
            setMutedFunction(!isMuted);
        }
    }

    function handleMouseEnter(): void {
        setIsHovered(true);
    }

    function handleMouseLeave(): void {
        setIsHovered(false);
    }

    useEffect(function setUpListenersForVolumeInput() {
        function handleKeyDown(event: KeyboardEvent): void {
            const checkVolumeContainsFocus = (): boolean =>
                !!containerRef.current?.contains?.(document.activeElement); // work out if the keypress "relates" to the volume control by checking it currently contains focus

            switch (event.key) {
                case 'Tab':
                    event.stopPropagation();
                    setTimeout(() => {
                        setIsKeyboardFocused(checkVolumeContainsFocus());
                    }); // wait a tick for the tab event to take effect
                    break;

                case 'Escape':
                    event.stopPropagation();
                    setIsKeyboardFocused(false);
                    break;

                default:
                    break;
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <VolumeControlContainer
            ref={containerRef}
            onKeyDown={onKeyDown}
            onTouchStart={handleTouchStart}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={classnames('IM01InputVol', className)}
        >
            <StyledIA01VertRange
                value={volume}
                label="Adjust volume level"
                tabIndex={-1}
                isDisabled={isMuted}
                isVisible={isHovered || isTouchOpened || isKeyboardFocused}
                onFocus={onFocus}
                onValueChange={setVolumeFunction}
            />

            <BA01CtrlBtn
                ref={volumeButtonRef}
                aria-label={
                    isMuted
                        ? 'Unmute the current video / Show volume slider'
                        : 'Mute the current video / Show volume slider'
                }
                tabIndex={tabIndex}
                onFocus={onFocus}
                onClick={handleVolumeButtonClick}
                onDoubleClick={(event) => {
                    // Avoiding double-taps.
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                {isMuted ? (
                    <IC21Mute color={white} />
                ) : (
                    <IC04Vol color={white} volumePercentage={volume} />
                )}
            </BA01CtrlBtn>
        </VolumeControlContainer>
    );
};

IM01InputVol.displayName = 'IM01InputVol';

export default IM01InputVol;
