import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC21Mute = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M61.432 17.96H65.624V49.723H61.432z"
            transform="translate(12 16.5) rotate(-45 63.528 33.842)"
        />
        <path
            d="M47.647 31.769H79.41V35.961H47.647z"
            transform="translate(12 16.5) rotate(-45 63.528 33.865)"
        />
        <path
            d="M34.67 15.54v35.828L22.357 43.32V23.756L34.67 15.65M43.054 0l-29.08 19.116v28.675l29.08 19.13V0zM0 46.436L8.384 47.204 8.384 19.619 0 20.388z"
            transform="translate(12 16.5)"
        />
    </Ic>
);

IC21Mute.displayName = 'IC21Mute';

export default IC21Mute;
