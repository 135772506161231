/* eslint-disable import/prefer-default-export */
import {isBrowser} from './environment';
import noop from './util/noop';

let supportsPassive: boolean | undefined;

export function getEventListenerArgs(): boolean | {passive: true};
export function getEventListenerArgs(
    options: AddEventListenerOptions
): boolean | AddEventListenerOptions;

/**
 * Detects if passive event listeners are supported (e.g. they are NOT in IE11)
 * The implementation for "addEventListener" varies between browsers and passing an options
 * object will cause the listener to use capture mode (when that might not be desirable) as object evaluates to bool "true"
 *
 * If passive mode supported, it will add passive:true to the options object and return
 * If passive mode supported not, it will return the capture boolean
 *
 * @param options - listener options
 * @returns when passive supported, options object. When not supported, useCapture boolean
 * @example
 * Usage examples:
 * ```
 * window.addEventListener('scroll', getEventListenerArgs({capture: true, once: true}));
 * window.addEventListener('scroll', getEventListenerArgs({once: true}));
 * window.addEventListener('scroll', getEventListenerArgs());
 * ```
 */
export function getEventListenerArgs(
    options?: AddEventListenerOptions
): boolean | AddEventListenerOptions {
    // options.capture, aka useCapture
    const {capture = false} = options ?? {};

    if (!isBrowser()) {
        return capture;
    }

    // Calculate supportsPassive the first time.
    if (supportsPassive === undefined) {
        supportsPassive = isPassiveSupported();
    }

    if (supportsPassive) {
        // merge {passive:true} into our options object and return
        return {...options, passive: true};
    }

    // Passive isn't supported, so just pass through the capture option
    return capture;
}

/**
 *
 */
function isPassiveSupported(): boolean {
    let result = false;

    const opts = Object.defineProperty<AddEventListenerOptions>({}, 'passive', {
        get: () => {
            // eslint-disable-line getter-return
            result = true;
        },
    });

    try {
        window.addEventListener('scroll', noop, opts);
    } catch (e) {
        // console.log(e);
    } finally {
        window.removeEventListener('scroll', noop, opts);
    }

    return result;
}

/**
 * This function creates an event listener that only calls back once.
 * Ideally, it should be the same as `window.addEventListener('xxx', callback, {once: true});`.
 * Unfortunately, PS4 does not support `{once: true}`, so we create this function instead.
 *
 * @example
 * ```
 * const removeListener = listenOnce(element, 'event-type', callback);
 *
 * removeListener(); // call removeListener to remove event listener
 * ```
 * @param target   - html element or any other object has `addEventListener` and `removeEventListener`
 * @param type     - event type
 * @param callback - event listener callback function
 * @returns a function to remove the event listener manually.
 */
export function listenOnce(
    target: EventTarget,
    type: string,
    callback: EventListener
): () => void {
    const listener: EventListener = (event) => {
        target.removeEventListener(type, listener);
        callback(event);
    };

    target.addEventListener(type, listener);

    return () => target.removeEventListener(type, listener);
}
