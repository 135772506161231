import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba, mix} from 'polished';
import React from 'react';
import styled, {type DefaultTheme, useTheme} from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {black, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 21;
const CHECKMARK_OFFSET_HOVER_PX = CHECKMARK_SIZE_PX / -2;
const PADDING_PX = 28 * 2; // * 2 accounts for both ends
const PADDING_SCREEN_1920_PX = 36 * 2;

export const BUTTON_WIDTH_DEFAULT_PX = 188;
export const BUTTON_WIDTH_SCREEN_1920_PX = 267;

const getBackgroundColor = ({theme}: {theme: DefaultTheme}): string =>
    mix(0.6, theme.brandColor, theme.brandColorVariant);

const StyledButton = styled(Button)`
    position: relative;
    transition: ${transition('background-color', 'color')};
    outline: none;
    border: none;
    background-color: ${rgba(white, 0.2)};
    padding: 0;
    width: ${BUTTON_WIDTH_DEFAULT_PX}px;
    height: 41px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};
    font: ${gibson({size: 14, lineHeight: 1.29})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${BUTTON_WIDTH_SCREEN_1920_PX}px;
        font-size: 24px;
        line-height: 1.25;
        height: 61px;
    `}

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${BUTTON_WIDTH_DEFAULT_PX - PADDING_PX}px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        content: attr(data-label);

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: ${BUTTON_WIDTH_SCREEN_1920_PX - PADDING_SCREEN_1920_PX}px;

        `}
    }

    /* stylelint-disable-next-line order/order */
    &:hover,
    &:focus {
        background-color: ${THEME_ACCESSORS.brandColor};
        text-shadow: none;
        font-weight: 600;
    }

    &:active {
        background-color: ${THEME_ACCESSORS.brandColor};
        text-shadow: none;
        color: ${white};
    }

    &[aria-pressed='true'] {
        background-color: ${getBackgroundColor};
        text-shadow: none;
        font-weight: 600;

        &:hover {
            background-color: ${THEME_ACCESSORS.brandColor};
        }
    }

    &:disabled {
        background-color: ${rgba(white, 0.1)};
        color: ${rgba(white, 0.2)};
        font-weight: normal;
    }
`;

type AriaPressedType = boolean | 'mixed';

const CheckedMarkCircle = styled.span<{isPressed?: AriaPressedType}>`
    position: absolute;
    top: ${CHECKMARK_OFFSET_HOVER_PX}px;
    right: ${CHECKMARK_OFFSET_HOVER_PX}px;
    transform: scale(${({isPressed}) => (isPressed ? '100%' : 0)});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
`;

export type Props = {
    /** aria-pressed attribute describes a toggle button */
    'aria-pressed'?: AriaPressedType;
    /** discribes if the button is disabled */
    // eslint-disable-next-line react/boolean-prop-naming
    'isDisabled'?: boolean;
    /** additional classname(s) */
    'className'?: string;
    /** Callback on element click */
    'onClick'?: React.MouseEventHandler<HTMLButtonElement>;
    /** Text content of button */
    'label': string;
} & React.ComponentPropsWithoutRef<'button'>;

const BA35CaptionSubRadBtn = ({
    'aria-pressed': isPressed,
    className,
    label,
    isDisabled,
    onClick,
    ...htmlAttributes
}: Props): React.ReactElement => {
    const {brandColor} = useTheme();

    return (
        <StyledButton
            {...htmlAttributes}
            aria-pressed={isPressed}
            className={classnames('BA35CaptionSubRadBtn', className)}
            onClick={onClick}
            type="button"
            data-label={label}
            disabled={isDisabled}
        >
            <CheckedMarkCircle isPressed={isPressed} role="presentation">
                <IC31Tick
                    color={brandColor}
                    secondaryColor={white}
                    size={`${CHECKMARK_SIZE_PX}px`}
                    hasDropshadow={true}
                />
            </CheckedMarkCircle>
        </StyledButton>
    );
};

BA35CaptionSubRadBtn.displayName = 'BA35CaptionSubRadBtn';

export default BA35CaptionSubRadBtn;
