import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {MEDIA_OTT_URL} from '../../utils/constants';

export default function getAlertNoticeConfig({
    alertNoticeConfigPath,
    freq = 30,
    sport = 'afl',
    vimondEnv = 'prod'
}) {
    const apiUrl = alertNoticeConfigPath ? alertNoticeConfigPath : `${MEDIA_OTT_URL}/${sport}/alerts/${vimondEnv}.json`;

    return getApiFromRetryWithError({
        url: apiUrl,
        freq
    }).flatMapLatest((response) => {
        if (response.responseConsideredValid) {
            return response.parseResponseText();
        } else {
            // If anyone mucks up the alert notice, just pretend we don't have any data
            // to work with.
            return {};
        }
    });
}
