import {stringify} from 'querystring';
import bacon from 'baconjs';
import pickBy from 'lodash/pickBy';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {PROXY_RESOURCE_URL} from '../../utils/constants';

/**
 * Get all the latest episodes available for given sports
 * https://ottapi-staging.foxsports.com.au/hawk-proxy-api/proxy/categories/2360
 * https://ottapi-staging.foxsports.com.au/hawk-proxy-api/proxy/categories/2360?query=test&page=1&size=1
 * Reference: http://ottapi-staging.foxsports.com.au/hawk-proxy-api/swagger-ui.html#!/category-resource/getCategoriesUsingGET
 *
 * @param  {String} categoryId               e.g. // category ID for categories: 2360
 * @param  {String} vimondEnv         e.g. 'staging'
 *
 * @param  {Number} freq                     e.g. polling frequency
 * @param  {String} page                     e.g. 5 // pagination page index 0-based
 * @param  {String} query                    e.g. query
 * @param  {String} size                     e.g. 8 // pagination page size
 * @return {Object} categories response
 */
export default function getCategories({
    categoryId,
    freq = 0,
    page = null,
    query = null,
    size = null,
    sport = 'afl',
    vimondEnv = 'prod'
}) {
    const queryParams = stringify(pickBy(
        {
            page,
            query,
            size,
            sport
        },
        (argVal) => argVal !== null && argVal !== undefined
    ));

    const url = `${PROXY_RESOURCE_URL[vimondEnv]}categories/${categoryId}` +
        (queryParams ? `?${queryParams}` : '');

    return getApiFromRetryWithError({
        url,
        freq
    }).flatMapLatest((response) => {
        if (response.responseConsideredValid) {
            return response.parseResponseText();
        }

        const errorMessage = `FS-HAWKOTT: unable to get category data (${url}): ${response.responseStatus} ${response.superAgentErr}`;

        return new bacon.Error(errorMessage);
    });
}
