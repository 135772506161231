import type RxPlayer from 'rx-player';
import type {
    IAudioRepresentation,
    IPeriod,
    IVideoRepresentation,
} from 'rx-player/types';

import type PlaybackRx from '.';
import type {PlayerQualityLevel} from '../../types';
import NativeCustomBitrate from '../native/custom-bitrate';

export default class RxPlayerCustomBitrate extends NativeCustomBitrate {
    declare playbackHandler: RxPlayer;

    constructor(playbackTech: PlaybackRx, playbackHandler: RxPlayer) {
        super(playbackTech, playbackHandler);

        this.playbackHandler.addEventListener(
            'newAvailablePeriods',
            this.setInitialBitrate
        );

        this.playbackHandler.videoElement?.addEventListener(
            'fs-period-change',
            this.updateQualitySettings
        );

        this.playbackHandler.addEventListener(
            'videoRepresentationChange',
            this.eventQualityChangeRequested
        );

        this.autoSwitch = true;
    }

    override destroy(): void {
        this.playbackHandler.removeEventListener(
            'newAvailablePeriods',
            this.setInitialBitrate
        );

        this.playbackHandler.videoElement?.addEventListener(
            'fs-period-change',
            this.updateQualitySettings
        );

        this.playbackHandler.removeEventListener(
            'videoRepresentationChange',
            this.eventQualityChangeRequested
        );
    }

    override setBitrateToAuto(canSetPreference = true): void {
        if (canSetPreference) {
            this.bitrateDeleteUserPreferred();
            this.bitrateSaveUserPreferredQuality('auto', 0);
        }

        this.bitrateCurrentQuality = 'auto';

        if (this.levels && this.levels.length > 0) {
            const maxHeight = this.levels[this.levels.length - 1]?.height;

            this.currentHeight = maxHeight ?? Number.MAX_SAFE_INTEGER;

            const availableIds = this.levels
                .map((item) => item.id)
                .filter<string>((id): id is string => typeof id === 'string');

            this.playbackHandler.lockVideoRepresentations(availableIds);
        } else {
            this.playbackHandler.unlockVideoRepresentations();
        }

        this.broadcastCurrentBitrateDetails();
    }

    override setMaxBitrate({
        selectedHeight,
        quality = 'low',
    }: {
        selectedHeight: number;
        quality: PlayerQualityLevel;
    }): void {
        this.bitrateCurrentQuality = quality;

        this.setQualityBasedOnHeight(selectedHeight);

        this.broadcastCurrentBitrateDetails();
    }

    updateQualitySettings = (): void => {
        if (!this.levels.length) {
            return;
        }

        const {height} = this.bitrateGetUserPreferredQuality();

        this.setQualityBasedOnHeight(
            height === 0 ? Number.MAX_SAFE_INTEGER : height
        );
    };

    setQualityBasedOnHeight = (maxHeight: number): void => {
        const availableIds = this.levels
            .filter((level) => level.height <= maxHeight)
            .map((level) => level.id)
            .filter<string>((id): id is string => typeof id === 'string');

        this.playbackHandler.lockVideoRepresentations(availableIds as string[]);
        this.currentHeight = maxHeight;
    };

    eventQualityChangeRequested = (
        videoRepresentation: IAudioRepresentation | null
    ): void => {
        const newQualityIndex = this.levels.findIndex(
            (level) => level.id === videoRepresentation?.id
        );

        this.currentIndex = newQualityIndex;
        this.currentHeight = this.levels[newQualityIndex]?.height ?? -1;

        this.bitrateSaveUserPreferred(
            this.levels[newQualityIndex]?.bitrate ?? -1
        );
        this.broadcastCurrentBitrateDetails();
    };

    setInitialBitrate = (periods: IPeriod[]): void => {
        let availableRepresentations: IVideoRepresentation[] = [];

        for (const period of periods) {
            const videoTrack = this.playbackHandler.getVideoTrack(period.id);

            if (videoTrack && videoTrack.representations.length > 0) {
                availableRepresentations = videoTrack.representations;
            }
        }

        if (availableRepresentations.length === 0) {
            return;
        }

        this.levels = availableRepresentations
            .map((representation) => ({
                id: representation.id,
                bitrate: representation.bitrate ?? 0,
                height: representation.height ?? 0,
                width: representation.width ?? 0,
            }))
            .sort(
                ({bitrate: bitrateA}, {bitrate: bitrateB}) =>
                    bitrateA - bitrateB
            );

        const {level, height} = this.bitrateGetUserPreferredQuality();

        if (level === 'auto') {
            this.setBitrateToAuto(false);
        } else {
            this.setMaxBitrate({selectedHeight: height, quality: level});
        }

        this.triggerBitrateLevelsLoaded({
            levels: this.levels,
            canManuallyAdjustBitrate: true,
        });
    };
}
