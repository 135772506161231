// OFFICIAL STYLEGUIDE COLOURS
// https://app.zeplin.io/project/5b8a715c8e466eaf86b74bc6/screen/5b909484695d8f52c0c13b02
export const athensGray = '#eeeef1';
export const suvaGrey = '#919191';
export const black = '#000';
export const coal = '#0d0f19';
export const ironStone = '#2a2c37';
export const midnight = '#0a0a10';
export const red = '#ff2323';
export const blue = '#1a69ce';
export const satellite = '#aaafb8';
export const shark = '#202129';
export const silverLining = '#d2d2db';
export const slate = '#aaacb8';
export const white = '#fff';
export const woodsmoke = '#14141a';
