import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import styled from 'styled-components';

import {
    SCREEN_768_TABLET,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';

const Arrow = styled.div`
    border: 6px solid transparent;
    border-bottom-width: 0;
    border-top-color: ${THEME_ACCESSORS.brandColor};
    width: 0;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        border-width: 7px 7px 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        border-width: 8px 8px 0;
    `}
`;

export default Arrow;
