import React from 'react';
import bacon from 'baconjs';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import FooterComponent from '../components/footer';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function Footer(element, settings) {
    this.element  = element;

    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        links: JSON.parse(this.settings.links)
    };
}

Footer.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Footer.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Footer',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Footer.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <FooterComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Footer.prototype.getData = function () {
    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            links: this.config.links,
            sportDetails: getSportDetails(this.config.sport)
        }),
        iso: bacon.combineTemplate({
        })
    });
};

Footer.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <FooterComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <FooterComponent {...data.view} />,
            'hawkwidgets-footer',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

Footer.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

Footer.prototype.pageBoot = function () {
    pageBoot(Footer, 'hawkwidgets-footer');
};

export default function (element, settings) {
    return new Footer(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(Footer, 'hawkwidgets-footer');
