import {getFullscreenPrefix, isBrowser, isIos} from './environment.js';
import {lockOrientation, unlockOrientation} from './orientation.js';

type EnterFullscreenArgs = {
    videoElement: HTMLVideoElement;
    fullscreenElement?: Element | null;
};

/**
 * Enter full-screen mode with the specified video element, optionally specifying another element that
 * contains it (e.g. the root of a video player UI).
 *
 * On iOS, only the video element can enter full-screen. In other runtimes, the parent element (defaulting
 * to the video element if not provided) enters full-screen mode.
 *
 * This uses vendor prefixed functions on old browsers.
 *
 * @param videoElement      - the video element
 * @param fullscreenElement - the full screen element
 */
export function enterFullscreen({
    videoElement,
    fullscreenElement = videoElement,
}: EnterFullscreenArgs): void {
    const fullscreenPrefix = getFullscreenPrefix(isBrowser());

    // iOS. Yay ios.... (Can only fullscreen video element).
    if (isIos() && videoElement.webkitEnterFullscreen) {
        videoElement.webkitEnterFullscreen();

        return;
    }

    if (fullscreenPrefix === undefined) {
        return;
    }

    switch (fullscreenPrefix) {
        case '':
            fullscreenElement?.requestFullscreen();
            break;

        case 'moz':
            fullscreenElement?.mozRequestFullScreen?.();
            break;

        default:
            fullscreenElement?.[`${fullscreenPrefix}RequestFullscreen`]?.();
            break;
    }

    lockOrientation('landscape-primary').catch(console.debug); // eslint-disable-line no-console
}

/**
 * Exit full-screen mode.
 *
 * On iOS, the specified video element is taken out of full-screen directly.
 *
 * This uses vendor prefixed functions on old browsers.
 *
 * @param videoElement - the video element
 */
export function exitFullscreen(videoElement: HTMLVideoElement): void {
    const fullscreenPrefix = getFullscreenPrefix(isBrowser());

    // iOS. Wooo. (Can only fullscreen video element).
    if (isIos() && videoElement.webkitExitFullscreen) {
        videoElement.webkitExitFullscreen();

        return;
    }

    if (fullscreenPrefix === undefined) {
        return;
    }

    if (fullscreenPrefix === '') {
        document.exitFullscreen();
    } else if (fullscreenPrefix === 'moz') {
        document.mozCancelFullScreen?.();
    } else {
        document[`${fullscreenPrefix}ExitFullscreen`]?.();
    }

    unlockOrientation();
}
