import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

const Success = ({
    successUrl,
    sportDetails,
    email
}) => (
    <section className="fiso-hawk-subscribe__success">
        <h1 className="fiso-hawk-subscribe__success-heading-1">Success</h1>
        <div className="fiso-hawk-subscribe__success-row">
            <div
                className={classNames(
                    'fiso-hawk-subscribe__success-col',
                    'fiso-hawk-subscribe__success-col--copy')}>
                <p>Success! Thank you for joining Watch {sportDetails.label}. You have been sent a confirmation email to {email}.</p>
                <p>
                    Didn't receive your confirmation email?
                    Or need to update your email address? Please contact our Care
                    team at <a href={`mailto:watch${sportDetails.label.toLowerCase()}@foxsports.com.au`} className="fiso-hawk-link">
                    watch{sportDetails.label.toLowerCase()}@foxsports.com.au</a>.
                </p>
            </div>
            <div
                className={classNames(
                    'fiso-hawk-subscribe__success-col',
                    'fiso-hawk-subscribe__success-col--icon-tick')}>
                <Fsvg name="tick-fancy" />
            </div>
        </div>
        <div className="fiso-hawk-subscribe__success-row">
            <div className="fiso-hawk-subscribe__success-col">
                <a
                    href={successUrl}
                    className={classNames(
                        'fiso-hawk-button',
                        'fiso-hawk-button--continue',
                        'fiso-hawk-button--full-width',
                        'fiso-hawk-button--ghost',
                        'fiso-hawk-button--chevron-right',
                        `fiso-hawk-fill-secondary--${sportDetails.route}`)}>
                    Start watching
                    <Fsvg name="next-1" />
                </a>
            </div>
        </div>
    </section>
);

Success.propTypes = {
    successUrl: propTypes.string,
    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    }),
    email: propTypes.string
};

export default Success;
