import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = {
    /** The colour of the exclamation mark */
    accentColor?: string;
} & IcProps;

const IC130Alert = ({
    accentColor = black,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <g transform="translate(7 7)">
            <circle cx="42.621" cy="42.621" r="42.621" />
            <circle fill={accentColor} cx="44.158" cy="62.953" r="4.192" />
            <path fill={accentColor} d="M39.966 19.647H48.35V51.815H39.966z" />
        </g>
    </Ic>
);

IC130Alert.displayName = 'IC130Alert';

export default IC130Alert;
