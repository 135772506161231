export default class GenericAdsAdapter {
  getVersion() {
    return '7.0.3-jwplayer-ads-jsclass'
  }

  getDuration() {
    return this.duration;
  }

  getResource() {
    return this.resource;
  }

  getPlayhead() {
    return this.playhead;
  }

  getTitle() {
    return this.title;
  }

  getPosition() {
    var position = this.getNpawReference().Constants.AdPosition.Midroll;
    if (this.getVideo().getAdapter() && !this.getVideo().getAdapter().flags.isJoined) {
      position = this.getNpawReference().Constants.AdPosition.Preroll;
    } else if (this.position === this.getNpawReference().Constants.AdPosition.Preroll || this.position === this.getNpawReference().Constants.AdPosition.Midroll || this.position === this.getNpawReference().Constants.AdPosition.Postroll) {
      position = this.position;
    }
    return position;
  }

  getGivenBreaks() {
    var ret = null;
    if (this.manager && this.manager.adsManager && this.manager.adsManager.getCuePoints) {
      ret = this.manager.adsManager.getCuePoints().length;
    }
    return ret;
  }

  getBreaksTime() {
    var ret = null;
    if (this.manager && this.manager.adsManager && this.manager.adsManager.getCuePoints) {
      var cuelist = this.manager.adsManager.getCuePoints();
      for (var index in cuelist) {
        if ((!cuelist[index] && cuelist[index] !== 0) || cuelist[index] === -1) {
          cuelist[index] = this.getVideo()._adapter.getDuration();
        }
      }
      ret = cuelist;
    }
    return ret;
  }

  getGivenAds() {
    return (this.pod && this.pod.getTotalAds) ? this.pod.getTotalAds() : null;
  }

  getAudioEnabled() {
    return !this.player.getMute();
  }

  getIsSkippable() {
    return this.skippable;
  }

  getIsFullscreen() {
    if (typeof this.player.getFullscreen === 'function') {
      return this.player.getFullscreen();
    }
    var videoObject = this.player.getContainer();
    return (window.innerHeight <= videoObject.clientHeight + 30 && window.innerWidth <= videoObject.clientWidth + 30);
  }

  getIsVisible() {
    return this.getNpawUtils().calculateAdViewability(this.player.getContainer());
  }

  getCreativeId() {
    return this.creativeId;
  }

  registerListeners() {
    this.monitorPlayhead(true, false, 1200);
    this.references = {
      adTime: this.adTimeListener.bind(this),
      adStarted: this.adStartedListener.bind(this),
      adImpression: this.adImpressionListener.bind(this),
      adPause: this.adPauseListener.bind(this),
      adPlay: this.adPlayListener.bind(this),
      adSkipped: this.adSkippedListener.bind(this),
      adComplete: this.adCompleteListener.bind(this),
      adClick: this.adClickListener.bind(this),
      adError: this.adErrorListener.bind(this),
      adsManager: this.managerListener.bind(this)
    };
    for (var key in this.references) {
      this.player.on(key, this.references[key]);
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.off(key, this.references[key]);
      }
      delete this.references;
    }
  }

  managerListener(e) {
    this.manager = e;
  }

  adTimeListener(e) {
    this.playhead = e.position;
    this.duration = e.duration;
    if (this.getVideo()._adapter) this.getVideo()._adapter.firePause();
    if (e.ima && e.ima.ad) this._imaMetadata(e.ima.ad);
    if (!this.firstStarted) {
      this.fireStart();
      this.firstStarted = true;
    }
    this.fireJoin();
    this._quartileHandler();
  }

  _imaMetadata(ad) {
    this.creativeId = ad.getCreativeId() || ad.getCreativeAdId();
    this.resource = ad.getMediaUrl();
    this.clickThrough = ad.g ? ad.g.clickThroughUrl : null;
    this.pod = ad.getAdPodInfo();
    this.skippable = !((ad.getSkipTimeOffset() <= 0));
  }

  _quartileHandler() {
    var time = this.playhead / this.duration;
    if (time > 0.75) {
      this.fireQuartile(3);
    } else if (time > 0.5) {
      this.fireQuartile(2);
    } else if (time > 0.25) {
      this.fireQuartile(1);
    }
  }

  adStartedListener(e) {
    this.position = e.adposition;
    this.resource = e.mediafile && e.mediafile.file ? e.mediafile.file : e.tag;
    this.title = e.adtitle;
  }

  adImpressionListener(e) {
    this.position = e.adposition;
    this.resource = e.mediafile && e.mediafile.file ? e.mediafile.file : e.tag;
    this.title = e.adtitle || e.tag;
    this.clickThrough = e.clickThroughUrl;
  }

  adPauseListener(e) {
    this.firePause();
  }

  adPlayListener(e) {
    this.fireResume();
  }

  adSkippedListener(e) {
    this.fireSkip();
    this.firstStarted = false;
  }

  adCompleteListener(e) {
    this.fireStop();
    this.firstStarted = false;
    this.resetValues();
  }

  adClickListener(e) {
    this.fireClick(this.clickThrough);
  }

  adErrorListener(e) {
    if (e.message) {
      var msg = e.message.toLowerCase();
      if (msg.indexOf('ad tag empty') > -1) {
        this.fireManifest(this.getNpawReference().Constants.ManifestError.EMPTY, e.message);
      } else if (msg.indexOf('error loading ad tag') > -1 || msg.indexOf('invalid ad tag') > -1 || msg.indexOf('any valid ads') > -1) {
        this.fireManifest(this.getNpawReference().Constants.ManifestError.WRONG, e.message);
      } else {
        if (e.adErrorCode && (e.adErrorCode === 60006 || e.adErrorCode === 10403)) {
          this.fireManifest(this.getNpawReference().Constants.ManifestError.WRONG, e.message);
        } else {
          this.fireError(e.adErrorCode, e.message);
          this.fireStop();
          this.resetValues();
          if (msg.indexOf('timeout') > -1) {
            this.fireBreakStop();
          }
        }
      }
    }
  }

  resetValues() {
    this.playhead = undefined;
    this.duration = undefined;
    this.position = undefined;
    this.resource = undefined;
    this.title = undefined;
  }
}