/**
 *
 * @param array - source array, which will not be mutated
 * @param indexA - swap target
 * @param indexB - swap destination
 * @returns a new array where indexA and indexB are swapped
 *
 * @example
 * swap(['a', 'b', 'z'], 0, 2) --\> ['z', 'b', 'a']
 *
 * @example
 */
export default function swap<T>(
    array: T[],
    indexA: number,
    indexB: number
): T[] {
    return Object.assign([...array], {
        [indexA]: array[indexB],
        [indexB]: array[indexA],
    });
}
