import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {formatSecondsIntoStandardLabel} from '../../../../common/format-time';
import {black, white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const stemSizePx = 7;

const StyledToolTipContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledTooltip = styled.output`
    transform-origin: bottom right;
    transition: ${transition('transform', 'opacity')};
    background-color: ${rgba(black, 0.5)};
    padding: 7px 14px;
    text-align: center;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};
    font: ${gibson.medium({size: 12})};
    pointer-events: none;
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 18px;
    `}
`;

const StyledTooltipStem = styled.div`
    box-sizing: border-box;
    border-top: ${stemSizePx}px solid ${rgba(black, 0.5)};
    border-right: ${stemSizePx}px solid transparent;
    border-left: ${stemSizePx}px solid transparent;
    width: ${stemSizePx}px;
    height: ${stemSizePx}px;
    content: '';
`;

export type Props = {
    seekingTime?: number;
};
const GA02TimeTooltip = ({seekingTime = 0}: Props): React.ReactElement => (
    <StyledToolTipContainer>
        <StyledTooltip>
            {formatSecondsIntoStandardLabel(seekingTime)}
        </StyledTooltip>
        <StyledTooltipStem />
    </StyledToolTipContainer>
);

GA02TimeTooltip.displayName = 'GA02TimeTooltip';

export default GA02TimeTooltip;
