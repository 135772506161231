import get from 'lodash/get';
import propTypes from 'prop-types';
import React from 'react';

const CHART_WIDTH = 500;
const CHART_HEIGHT = 20;
const POSITION_WIDTH = 5;
const CURRENT_POSITION_MARKER_WIDTH = 2;
const UPDATE_INTERVAL_MS = 500;
const MAX_NUM_OF_POSITIONS = CHART_WIDTH / POSITION_WIDTH;

const COLORS = [
    '#FFFFFF',
    '#E77B23',
    '#E5B700',
    '#ECF000',
    '#87A01C',
    '#9EBB24',
    '#00bf6f',
];

type Props = {
    rangeIntervals: number[];
    currentValue: number;
};

type State = {
    currentPosition: number;
};

// this is the type of graph used by youtube for their diagnostics
// intervals between values are represented as different colours,
// and the background colour of a slice representing the previous interval
// https://flowingdata.com/2015/07/02/changing-price-of-food-items-and-horizon-graphs/
export default class HorizonChart extends React.Component<Props, State> {
    static displayName = 'HorizonChart';

    static propTypes = {
        rangeIntervals: propTypes.arrayOf(propTypes.number).isRequired,
        currentValue: propTypes.number.isRequired,
    };

    override state: State = {
        currentPosition: 0,
    };

    override componentDidMount(): void {
        const chartCanvas = this.canvasRef?.current?.getContext('2d');

        if (chartCanvas) {
            // the outline of the entire graph
            chartCanvas.fillStyle = '#FFFFFF';
            chartCanvas.fillRect(0, 0, CHART_WIDTH, CHART_HEIGHT);
        }

        this.intervalId = window.setInterval(() => {
            const {rangeIntervals, currentValue} = this.props;

            // some general safety checks
            // none of this makes sense without at least 2 interval values
            if (
                !rangeIntervals ||
                !(rangeIntervals.length >= 2) ||
                isNaN(currentValue)
            ) {
                return;
            }

            // default to 1 so we never divide by 0
            const interval =
                get(rangeIntervals, 1, 0) - get(rangeIntervals, 0, 0) || 1;

            const rangeIndex = rangeIntervals.findIndex(
                (_, index) =>
                    index < rangeIntervals.length - 1 &&
                    currentValue >= (rangeIntervals[index] as number) &&
                    currentValue < (rangeIntervals[index + 1] as number)
            );

            // if we get higher than the largest value, then fix to our last color

            const isGreaterThanLargestValue =
                rangeIntervals.length > 0
                    ? currentValue >=
                      (rangeIntervals[rangeIntervals.length - 1] as number)
                    : false;
            const lastColor = COLORS[rangeIntervals.length - 1] ?? '';

            const backgroundColor = isGreaterThanLargestValue
                ? lastColor
                : COLORS[rangeIndex] || '#FFFFFF';
            const foregroundColor = isGreaterThanLargestValue
                ? lastColor
                : COLORS[rangeIndex + 1] || '#FFFFFF';

            const currentValuePercentage = (currentValue % interval) / interval;
            const foregroundHeight = Number(
                (CHART_HEIGHT * currentValuePercentage).toFixed(0)
            );

            if (chartCanvas) {
                // draw the background fill for the current position slice
                chartCanvas.fillStyle = backgroundColor;
                chartCanvas.fillRect(
                    this.state.currentPosition * POSITION_WIDTH,
                    0,
                    POSITION_WIDTH,
                    CHART_HEIGHT
                );

                // draw the foreground value for the current value
                chartCanvas.fillStyle = foregroundColor;
                chartCanvas.fillRect(
                    this.state.currentPosition * POSITION_WIDTH,
                    CHART_HEIGHT - foregroundHeight,
                    POSITION_WIDTH,
                    foregroundHeight
                );

                // draw the marker for our current position
                chartCanvas.fillStyle = '#000000';
                chartCanvas.fillRect(
                    this.state.currentPosition * POSITION_WIDTH +
                        POSITION_WIDTH,
                    0,
                    CURRENT_POSITION_MARKER_WIDTH,
                    CHART_HEIGHT
                );
            }

            this.setState((prevState) => ({
                currentPosition:
                    (prevState.currentPosition + 1) % MAX_NUM_OF_POSITIONS,
            }));
        }, UPDATE_INTERVAL_MS);
    }

    override shouldComponentUpdate(): boolean {
        return false;
    }

    override componentWillUnmount(): void {
        clearInterval(this.intervalId);
    }

    intervalId: number | undefined;
    canvasRef = React.createRef<HTMLCanvasElement>();

    override render(): React.ReactElement {
        return (
            <canvas
                ref={this.canvasRef}
                width={CHART_WIDTH}
                height={CHART_HEIGHT}
            />
        );
    }
}
