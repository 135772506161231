import {isServer} from '@fsa-streamotion/browser-utils';

import bacon from 'baconjs';

import {DEFAULT_CACHE_TTL_MS, DEFAULT_REQUEST_TIMEOUT_MS, DEFAULT_RETRY_COUNT} from './constants';
import {defaultResponseValidator} from './helpers';
import {getFeed, stopFeed} from './request-manager';
import type Response from './response';
import type {GetApiFromBinderArgs, GetApiFromRetryWithErrorArgs} from './types';

/**
 * Get a stream for an API endpoint
 *
 * @param cacheTtlMs        - How long this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param freqMs            - How frequently (in milliseconds) to poll
 * @param requestTimeoutMs  - How long (in milliseconds) to wait before abandoning request
 * @param url               - URL to fetch
 * @param validateResponse  - Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns  Bacon EventStream (will return errors, mapError to catch if required)
 */

export function getApiFromBinder({
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    freqMs,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    url,
    validateResponse = defaultResponseValidator,
}: GetApiFromBinderArgs): bacon.EventStream<Response, Response> {
    return bacon.fromBinder((sink) => {
        const onResponse = (superAgentObject: Response): void => {
            if (validateResponse?.(superAgentObject)) {
                sink(superAgentObject);
            } else {
                sink(new bacon.Error(superAgentObject));
            }
        };

        getFeed({url, onResponse, freqMs, cacheTtlMs, requestTimeoutMs});

        return () => void stopFeed(url, onResponse);
    });
}

/**
 * Get a stream for an API endpoint, and on connection issues
 * retry the request x times.
 *
 * After failing all retries, the final error will be released to the
 * stream as a value.
 *
 * @param cacheTtlMs       - How long this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param delayMs          - Retry interval on error (in milliseconds) - e.g. 5 seconds between unsuccessful attempts
 * @param freqMs           - How frequently (in milliseconds) to poll
 * @param requestTimeoutMs - How long (in milliseconds) to wait before abandoning request
 * @param retries          - How many retries (not including initial) attempts
 * @param url              - URL to fetch
 * @param validateResponse - Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns Bacon EventStream (Returns errors AS values in stream)
 */
export function getApiFromRetryWithError({
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    delayMs = 5 * 1000,
    freqMs = 0,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    retries = DEFAULT_RETRY_COUNT,
    url,
    validateResponse = defaultResponseValidator,
}: GetApiFromRetryWithErrorArgs): bacon.EventStream<unknown, Response> {
    return bacon
        .retry({
            source: () =>
                getApiFromBinder({
                    url,
                    freqMs,
                    cacheTtlMs,
                    validateResponse,
                    requestTimeoutMs,
                }),
            retries,
            isRetryable: () => {
                if (isServer()) {
                    return false;
                }

                // Only retry if we're not using a frequency.
                if (freqMs) {
                    return false;
                } else {
                    // On retry - switch to null (or leave as null) to not obtain cached 404 results.
                    freqMs = null; // eslint-disable-line no-param-reassign

                    return true;
                }
            },

            // on failure / error in the stream, delay for x
            // long before going again.
            delay: () => delayMs,
        })
        .mapError((error) => error);
}
