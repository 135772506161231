import GenericAdsAdapter from "./ads/GenericAdsAdapter";

export default class JwplayerAdapter {
  adsAdapters = {
    genericAdsAdapter: GenericAdsAdapter
  }

  getVersion() {
    return '7.0.3-jwplayer-jsclass';
  }

  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this.player.getConfig().mediaElement);
    } catch (err) {
      return true;
    }
  }

  getPlayhead() {
    if (this.getVideo() && this.getVideo().isBreakStarted) {
      return this._lastPlayhead;
    }
    var ret = this.player.getPosition();
    if (ret && ret < 0) {
      ret = Math.abs(ret);
      if (this.monitor) this.monitor.stop();
    }
    return ret;
  }

  getPlayrate() {
    var ret = 1;
    if (this.tag && this.tag.playbackRate) {
      ret = this.tag.playbackRate;
    } else if (this.player.getPlaybackRate) {
      ret = this.player.getPlaybackRate();
    }
    return ret;
  }

  getDuration() {
    return this.duration || this.player.getDuration() || null;
  }

  getBitrate() {
    var ret = -1;
    var quality = this.player.getVisualQuality();
    if (typeof this.player.getCurrentQuality() === 'number' && this.player.getQualityLevels() && this.player.getCurrentQuality() !== -1) {
      quality = this.player.getQualityLevels()[this.player.getCurrentQuality()];
      if (quality && quality.bitrate) {
        ret = quality.bitrate;
      }
    }
    quality = this.player.getVisualQuality();
    if (ret <= 0 && quality && quality.bitrate) {
      ret = quality.bitrate;
    } else if (ret <= 0 && quality && quality.level && quality.level.bitrate) {
      ret = quality.level.bitrate;
    }
    return ret;
  }

  getRendition() {
    var ret = null;
    var quality = this.player.getVisualQuality();
    if (quality && quality !== null && quality.level) {
      ret = this.getNpawUtils().buildRenditionString(quality.level.width, quality.level.height, quality.level.bitrate || 0)
    } else if (typeof this.player.getCurrentQuality() === 'number' && this.player.getQualityLevels() && this.player.getCurrentQuality() !== -1) {
      quality = this.player.getQualityLevels()[this.player.getCurrentQuality()];
      if (quality && quality.label === 'auto') {
        ret = this.getNpawUtils().buildRenditionString(quality.bandwidth)
      } else if (quality && quality.label) {
        ret = quality.label;
      } else if (this.player.getCurrentQuality() === -1) {
        ret = 'auto';
      }
    }
    return ret;
  }

  getTitle() {
    return this.title;
  }

  getIsLive() {
    var ret = false;
    if (typeof this.isLive === 'boolean') {
      ret = this.isLive;
    } else if (this.getDuration() <= 0) {
      ret = true;
    }
    return ret;
  }

  getResource() {
    return this.player.getPlaylistItem().file;
  }

  getPlayerVersion() {
    return this.player.version;
  }

  getPlayerName() {
    return 'JWPlayer';
  }

  setOptions(e) {
    if (e && e.media) {
      this.isLive = e.media.isLive || this.isLive;
      this.title = e.media.title || this.title;
    }
    if (e) {
      this.isLive = e['content.isLive'] || this.isLive;
      this.title = e['content.title'] || this.title;
    }
    this.getVideo().mergeOptions(e);
  }

  registerListeners() {
    this._lastPlayhead = 0;
    this.monitorPlayhead(true, true);
    this.references = {
      beforePlay: this.beforeplayListener.bind(this),
      buffer: this.bufferListener.bind(this),
      play: this.playListener.bind(this),
      pause: this.pauseListener.bind(this),
      error: this.errorListener.bind(this),
      seek: this.seekingListener.bind(this),
      seeked: this.seekedListener.bind(this),
      complete: this.endedListener.bind(this),
      meta: this.metaListener.bind(this),
      playlistItem: this.playlistItemListener.bind(this),
      setupError: this.setupErrorListener.bind(this),
      idle: this.idleListener.bind(this),
      firstFrame: this.firstFrameListener.bind(this),
      time: this.timeListener.bind(this),
      adsManager: this.managerListener.bind(this),
      remove: this.removeListener.bind(this),
      playbackRateChanged: this.playrateListener.bind(this)
    };
    for (var key in this.references) {
      this.player.on(key, this.references[key]);
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.off(key, this.references[key]);
      }
      delete this.references;
    }
  }

  timeListener(e) {
    this._lastPlayhead = this.getPlayhead();
    if (this.fatalErrored) return null;
    if (e.position !== 0) this._start({}, 'timeListener');
    var actualPosition = e.position;
    if (!e.currentTime && !e.position) return;
    if ((this.previousPosition && this.previousPosition < actualPosition) || (this.getIsLive())) {
      this.fireJoin({}, 'timeListener');
    }
    this.previousPosition = actualPosition;
  }

  playlistItemListener(e) {
    this.firePlayerLog('playlistItemListener', {});
    var playlistItem = this.player.getPlaylistItem();
    if (playlistItem && playlistItem.file.indexOf('/VideoError.mp4') > -1) {
      return null;
    }
    this.duration = undefined;
    this.throughput = -1;
    this.setOptions(this.player.getConfig().youbora);
    this.setOptions(playlistItem.youbora);
    this.fireStop({}, 'playlistItemListener');
  }

  setupErrorListener(e) {
    this.firePlayerLog('setupErrorListener', {});
    this.fireError(e.message, undefined, undefined, undefined, 'setupErrorListener');
  }

  checkForClientSideAds() {
    var adsAdapter = this.getVideo().getAdsAdapter();
    var willShowCSAIAds = false;
    if (adsAdapter && typeof adsAdapter.isDAI !== 'undefined') {
      if (!adsAdapter.isDAI) {
        willShowCSAIAds = adsAdapter.player.getCuePoints().includes(-1);
      }
    }
    return willShowCSAIAds;
  }

  idleListener(e) {
    this.firePlayerLog('idleListener', {});
    if ((!e.reason || e.reason === 'complete') && !this.checkForClientSideAds()) {
      this.fireStop({}, 'idleListener');
    }
  }

  firstFrameListener(e) {
    this.firePlayerLog('firstFrameListener', {});
    this.tag = this.player.getConfig().mediaElement;
    this.fireJoin({}, 'firstFrameListener');
  }

  beforeplayListener(e) {
    this.firePlayerLog('beforeplayListener', {});
    this.duration = undefined;
    this.throughput = -1;
    if (!this.player.getPlaylistItem().youbora) {
      this.setOptions(this.player.getConfig().youbora)
    }
    if (!this.flags.isStarted) {
      this._start({}, 'beforeplayListener');
      this.tag = this.player.getConfig().mediaElement;
      this.fatalErrored = false;
      if (!this.getVideo().getAdsAdapter()) {
        this.getVideo().setAdsAdapter(this.getAdapterClass('genericAdsAdapter'), this.plugin);
      }
    }
  }

  managerListener(e) {
    this.firePlayerLog('managerListener', {});
    if (!this.getVideo().getAdsAdapter()) {
      this.getVideo().setAdsAdapter(this.getAdapterClass('genericAdsAdapter'), this.plugin);
      this.getVideo().getAdsAdapter().managerListener(e);
    }
  }

  bufferListener(e) {
    this.firePlayerLog('bufferListener', {});
    if (!this.flags.isPaused) {
      this.fireBufferBegin({}, false, 'firstFrameListener');
    }
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    this.fireBufferEnd({}, 'playListener');
    this.fireSeekEnd({}, 'playListener');
    this.fireResume({}, 'playListener');
    this.monitor.skipNextTick();
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    if (this.flags.isSeeking) {
      this.fireSeekEnd({}, 'playListener');
    }
    this.firePause({}, 'pauseListener');
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    if (e.message && e.message.toLowerCase().includes('casting failed to load')) {
      return;
    }
    if (e.code) {
      this.fireError(e.code, e.message, undefined, undefined, 'errorListener');
    } else {
      this.fireError(e.message, undefined, undefined, undefined, 'errorListener');
    }
    if (!e.message || e.message.toLowerCase().includes('network error') || e.message.toLowerCase().includes('could not be played') || e.message.toLowerCase().includes('cannot load') || e.message.toLowerCase().includes('cannot be played') || e.message.toLowerCase().includes('down') || e.message.toLowerCase().includes('fragloaderror') || e.message.toLowerCase().includes('levelloadtimeout') || e.message.toLowerCase().includes('file not found')) {
      this.fireStop({}, 'errorListener');
    }
    if (e.code) {
      if (e.code < 300000 || e.code > 309000) {
        this.fireStop({}, 'errorListener');
        this.fatalErrored = true;
      }
    }
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener');
  }

  seekedListener(e) {
    this.firePlayerLog('seekedListener', {});
    if (this.player.getState() !== 'paused') {
      this.fireSeekEnd({}, 'seekedListener');
      this.monitor.skipNextTick();
    }
  }

  endedListener(e) {
    if (!this.checkForClientSideAds()) {
      this.firePlayerLog('endedListener', {});
      this.fireStop({}, 'endedListener');
    }
  }

  removeListener(e) {
    this.firePlayerLog('removeListener', {});
    this.getVideo().fireStop({}, 'removeListener');
  }

  playrateListener(e) {
    if (this.monitor) this.monitor.skipNextTick();
  }

  _start(e, triggeredEvent) {
    if (!this.flags.isStarted && this.player && this.getVideo()) {
      this._getDrm();
      this.fireStart(e, triggeredEvent);
    }
  }

  _getDrm(e) {
    var item = this.player.getPlaylistItem(this.player.getPlaylistIndex());
    for (var i in item.allSources) {
      var element = item.allSources[i];
      if (element.file === item.file && element.drm && typeof Object.keys === 'function') {
        var drms = Object.keys(element.drm);
        if (drms.length === 1) {
          this.getVideo().mergeOptions({
            'content.drm': drms[0]
          });
        }
        break;
      }
    }
  }

  metaListener(e) {
    this.duration = e.duration || this.duration;
    if (e.metadata) {
      this.duration = e.metadata.duration || this.duration;
    }
  }
}