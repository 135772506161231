import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = {
    textColor?: string;
} & IcProps;

const IC152MultiAdd4 = ({
    textColor = black,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M41.63 0C18.638 0 0 18.638 0 41.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C83.252 18.642 64.618.009 41.629 0z"
            transform="translate(8 8)"
        />
        <path
            fill={textColor}
            d="M51.377 58.214v-6.74h4.493V45.37h-4.493V24.419h-8.742L27.349 45.712v5.762h16.41v6.74h7.618zm-7.424-12.698h-9.425l9.425-13.04v13.04z"
            transform="translate(8 8)"
        />
    </Ic>
);

IC152MultiAdd4.displayName = 'IC152MultiAdd4';

export default IC152MultiAdd4;
