export function waitFor({
    testFunction,
    retries = 5,
    delay = 1000
}) {
    return new Promise((resolve, reject) => {
        let retryCount = 0;

        const tryFunction = () => {
            retryCount++;

            if (testFunction()) {
                resolve(true);
            } else if (retryCount < retries) {
                setTimeout(tryFunction, delay);
            } else {
                reject(false);
            }
        };

        tryFunction();
    });
}
