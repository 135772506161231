import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

import {loadScript} from '@fsa/fs-commons/lib/utils/lazy-load';

export default class AddToCalendar extends React.PureComponent {
    componentDidMount() {
        this.loadAddToCalendar();
    }

    componentDidUpdate() {
        this.loadAddToCalendar();
    }

    loadAddToCalendar() {
        if (!this.props.init || !process || !process.browser || this.addToCalendar.querySelector('ul.atcb-list')) {
            return;
        }

        if (window.addtocalendar && (typeof window.addtocalendar.load === 'function')) {
            window.addtocalendar.load();
        } else {
            loadScript('/assets/js/addtocalendar.min.js');
        }
    }

    render() {
        return (
            <div
                className="fsui-normalise fiso-hawkwidget-add-to-calendar addtocalendar"
                ref={(addToCalendar) => {
                    this.addToCalendar = addToCalendar;
                }}>
                <var className="atc_event">
                    <var className="atc_date_start">{moment(this.props.startTime).format('YYYY-MM-DD HH:mm')}</var>
                    <var className="atc_date_end">{moment(this.props.endTime).format('YYYY-MM-DD HH:mm')}</var>
                    <var className="timezone">{this.props.timeZone}</var>
                    <var className="atc_title">{this.props.title}</var>
                    <var className="atc_description">{this.props.description}</var>
                    <var className="atc_location">{this.props.location}</var>
                    <var className="atc_organizer">{this.props.organizer}</var>
                    <var className="atc_organizer_email">{this.props.email}</var>
                </var>
            </div>
        );
    }
}

AddToCalendar.defaultProps = {
    timeZone: 'UTC'
};

AddToCalendar.propTypes = {
    description: propTypes.string,
    email: propTypes.string,
    endTime: propTypes.string,
    init: propTypes.bool,
    location: propTypes.string,
    organizer: propTypes.string,
    startTime: propTypes.string,
    timeZone: propTypes.string,
    title: propTypes.string
};
