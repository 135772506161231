import {isPs4, isPs5, isTizen2019} from '@fsa-streamotion/browser-utils';

import VIDEO_LIBRARY_URLS from '../../utils/video-library-urls';
import type {DashjsConfig} from './types';

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_DASHJS_CONFIG: DashjsConfig = {
    script: VIDEO_LIBRARY_URLS.dashjs,
    settings: {
        streaming: {
            cacheInitSegments: false,
            eventControllerRefreshDelay: 150,

            // Certain live channels fail on PS5 when the fragment delay is too short.
            // According to the docs default liveDelayFragmentCount value is 4 which is a trade off between stability and latency:
            // http://reference.dashif.org/dash.js/latest/samples/live-streaming/live-delay-comparison-using-fragmentCount.html
            delay: {liveDelayFragmentCount: isPs5() || isPs4() ? 4 : 2},
            buffer: {
                stallThreshold: 0.3,
                setStallState: true,
                reuseExistingSourceBuffers: true, // Don't attempt to disable this, otherwise we won't get the benefit from prebuffer, a long loading will occur when switching periods.
                useChangeTypeForTrackSwitch: !isPs5(), // `SourceBuffer.prototype.changeType` is not fully implemented on PS5, see also https://github.com/Dash-Industry-Forum/dash.js/pull/4149
                fastSwitchEnabled: !isTizen2019(),
            },
            gaps: {
                threshold: 0.6,
                // Do not enable seek fix for PS5, otherwise, we get wrong currentTime when the first period finishes.
                enableSeekFix: !isPs5(),
                enableStallFix: true,
            },
            lastMediaSettingsCachingInfo: {
                // dash.js takes `audioChannelConfiguration` into account but doesn't consider codec,
                // which probably causes that dash.js selects a track with different codec when switching period,
                // so disable this and use `setCustomInitialTrackSelectionFunction` instead.
                enabled: false,
            },
            text: {
                extendSegmentedCues: !isPs4(),
            },
            timeShiftBuffer: {
                calcFromSegmentTimeline: true,
            },
        },
    },
};
