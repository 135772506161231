import React from 'react';
import bacon from 'baconjs';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import ResetPasswordComponent from '../components/reset-password';
import resetPassword from '../streams/reset-password';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function ResetPassword(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        supportContactUrl: this.settings.supportContactUrl,
        sport: this.settings.sport || 'afl',
        vimondEnv: this.settings.vimondEnv  || 'prod',
        token: this.settings.token
    };
}

ResetPassword.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

ResetPassword.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Reset Password',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

ResetPassword.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <ResetPasswordComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

ResetPassword.prototype.getData = function () {
    const {supportContactUrl, sport, vimondEnv, token} = this.config;

    const resetPasswordBus = new bacon.Bus();

    const resetPasswordStream = resetPasswordBus
        .flatMapLatest((password) => resetPassword({
            password,
            sport,
            token,
            vimondEnv
        }))
        .startWith(false);

    const resetPasswordError = resetPasswordStream
        .errors()
        .mapError('.message')
        .startWith(null);

    const isLoading = bacon.mergeAll(
        resetPasswordBus.map(true),
        resetPasswordStream.map(false),
        resetPasswordError.map(false)
    );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            supportContactUrl,
            isSuccess: resetPasswordStream,
            error: resetPasswordError,
            isLoading: isLoading.toProperty(false),
            onSubmit: (password) => {
                resetPasswordBus.push(password);
            },
            sportDetails: getSportDetails(sport)
        }),
        iso: {}
    });
};

ResetPassword.prototype.render = function (data) {
    if (this.element &&  this.root) {
        this.root.render(
            <ResetPasswordComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <ResetPasswordComponent {...data.view} />,
            'hawkwidgets-reset-password',
            {
                settings:  this.settings,
                data:      data.iso
            }
        );
    }
};

ResetPassword.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new ResetPassword(element, settings);
}

pageBoot(ResetPassword, 'hawkwidgets-reset-password');
