export default function logError(
    msg: string,
    val: unknown,
    error: unknown
): void {
    if (error instanceof Error) {
        console.warn(msg, val, error.stack);
    } else {
        console.warn(msg, val);
    }
}
