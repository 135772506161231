import bacon from 'baconjs';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import superAgent from 'superagent';
import trimStart from 'lodash/trimStart';

import Response from '@fsa/fs-commons/lib/request-manager/response';

import {filterEventOn} from './video-normalised-events';
import {MULTITENANCY_HEADER, DEFAULT_VIMOND_ENV, VIMOND_MIDDLELAYER_URL, VIMOND_EVENT_SERVICE_URL} from './constants';
import {getDeviceUdid, getVimondHeaders} from './api';
import {getUserDetail} from './auth';

export function getVimondTracking({ // eslint-disable-line import/prefer-default-export
    authToken,
    sport = 'afl',
    videoNormalisedEvents,
    vimondEnv = DEFAULT_VIMOND_ENV,
    trackingData = {}
}) {
    const playerReady = videoNormalisedEvents
        .filter(filterEventOn(['mounted', 'sourceupdate']));

    return playerReady
        .flatMapLatest(({playerTech}) => {
            const playerSample = videoNormalisedEvents
                .filter(filterEventOn(['firstplay']))
                .map((event) => {
                    postPlayback({
                        logData: trackingData.logData,
                        logUri: trackingData.logUri,
                        sport,
                        vimondEnv
                    });

                    return event;
                })
                .take(1)
                .concat(
                    bacon.interval(10 * 1000, true)
                )
                .map(() => playerTech);

            const duration = playerSample
                .map((playerTech) => Math.floor(playerTech.duration) * 1000); // milliseconds

            const currentTime = playerSample
                .map((playerTech) => Math.floor(playerTech.currentTime) * 1000); // milliseconds

            const isPaused = playerSample
                .map((playerTech) => !playerTech.isPlaying);

            const hasEnded = bacon
                .mergeAll(
                    videoNormalisedEvents.filter(filterEventOn(['ended'])).map(true),
                    videoNormalisedEvents.filter(filterEventOn(['replay', 'play'])).map(false)
                ).toProperty(false);

            const kickedClientIds = isPaused
                .combine(hasEnded, (paused, ended) => !(paused || ended))
                .flatMapLatest((isPlaying) => {
                    let clientIds = [];

                    if (isPlaying) { // poll only if video is playing
                        clientIds = getKickedClientIds({
                            authToken,
                            sport,
                            vimondEnv
                        });
                    }

                    return clientIds;
                });

            return bacon.combineTemplate({
                authToken,
                currentTime,
                duration,
                hasEnded,
                isPaused,
                kickedClientIds
            })
                .skipDuplicates(isEqual)
                .doAction(({
                    authToken,
                    currentTime = 0,
                    isPaused,
                    duration = 0,
                    hasEnded
                }) => {
                    postPlayerEvents({
                        authToken,
                        sport,
                        vimondEnv,
                        payload: getVimondTrackingMetaData(Object.assign(
                            {},
                            trackingData,
                            {
                                duration,
                                position: currentTime,
                                isPaused,
                                hasEnded
                            }
                        ))
                    });
                });
        })
        .startWith(null);
}

function postPlayback({
    logData,
    logUri,
    sport,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    if (!logUri) {
        return;
    }

    superAgent
        .post(`${VIMOND_MIDDLELAYER_URL[vimondEnv]}${trimStart(logUri, '/')}`)
        .set(Object.assign(
            {},
            MULTITENANCY_HEADER[sport],
            {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        ))
        .send(`payload=${logData}`)
        .end((err) => {
            if (err) {
                console.error('Unable to log playback: ', err.status, err.message);
            }
        });
}

function postPlayerEvents({
    authToken,
    sport,
    payload = {},
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    superAgent
        .post(VIMOND_EVENT_SERVICE_URL[vimondEnv])
        .set(getVimondHeaders({authToken, sport}))
        .send(payload)
        .end((err) => {
            if (err) {
                console.error('Unable to log player events: ', err.status, err.message);
            }
        });
}

export function getVimondTrackingMetaData(trackingData = {}) {
    const userDetail = getUserDetail();

    let playerState = 'playing';

    if (trackingData.hasEnded) {
        playerState = 'stop';
    } else if (trackingData.isPaused) {
        playerState = 'pause';
    }

    return {
        client: {
            playerState,
            viewingSession: getDeviceUdid()
        },
        eventName: 'player-events',
        originator: 'player',
        originatorId: userDetail.id,
        progress: {
            assetId: trackingData.id,
            category: {
                id: trackingData.categoryId,
                name: trackingData.categoryTitle || ''
            },
            liveResumePossible: false,
            offset: {
                duration: trackingData.duration,
                pos: trackingData.position
            },
            playbackType: trackingData.live ? 'live' : 'vod',
            title: trackingData.title || ''
        },
        streamRules: {
            subscriptionLimitConcurrentStream: trackingData.maxStreams
        },
        versions: ['1.0']
    };
}

/**
 * Returns list of client IDs that needs to be kicked from streaming
 *
 * @param  {string} authToken           authentication token from vimond
 * @param  {string} vimondEnv    execution environment
 * @return {array}                      array of client IDs
 */
function getKickedClientIds({
    authToken,
    sport,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return bacon.fromBinder((sink) => {
        const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/authentication/user`;
        const request = superAgent
            .get(url)
            .set(getVimondHeaders({authToken, sport}))
            .end((err, res) => {
                let kickedClientIds;
                const resObj = new Response(err, res);
                const respText = resObj.parseResponseText();

                if (resObj.responseConsideredValid) {
                    kickedClientIds = get(respText, 'response.kickedClientIds', null);

                    sink(kickedClientIds ? kickedClientIds.split(',') : []);
                    sink(new bacon.End());
                } else {
                    sink(new bacon.Error(respText));
                }
            });

        return () => {
            request.abort();
        };
    });
}
