export const THUMBNAIL_VISIBILITY_DELAY = 3 * 1000;
export const SKIP_MARKER_HIDE_DELAY_MS = 15_000;

export const PLAYER_LAYOUT_STYLE = {
    LAYOUT_SINGLE: 'Standard',
    LAYOUT_PIP: 'PIP',
    LAYOUT_2UP: '2up',
    LAYOUT_3UP: '3up',
    LAYOUT_4UP: '4up',
    LAYOUT_GRID: 'Grid',
} as const;

export type PlayerLayoutStyleValue =
    (typeof PLAYER_LAYOUT_STYLE)[keyof typeof PLAYER_LAYOUT_STYLE];

type LayoutPlayersCappedToSize = {
    [key in PlayerLayoutStyleValue]: {
        [key: number]: boolean;
    };
};

export const LAYOUT_PLAYERS_CAPPED_TO_SIZE: LayoutPlayersCappedToSize = {
    [PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE]: {}, // single not capped
    [PLAYER_LAYOUT_STYLE.LAYOUT_PIP]: {
        2: true, // just the little fella
    },
    [PLAYER_LAYOUT_STYLE.LAYOUT_2UP]: {}, // side-by-side, be the same! BE FREE!
    [PLAYER_LAYOUT_STYLE.LAYOUT_3UP]: {
        2: true, // Little guys be little!
        3: true,
    },
    [PLAYER_LAYOUT_STYLE.LAYOUT_4UP]: {
        2: true, // Wow so many little fellas!
        3: true,
        4: true,
    },
    [PLAYER_LAYOUT_STYLE.LAYOUT_GRID]: {}, // HARDCODE MODE ACTIVATED, BETTER HAVE FTT(B|P|C)
};

export const SCRUBBER_TYPE = {
    none: Symbol('Hidden scrubber'),
    fromEdge: Symbol('Live scrubber'),
    currentAndDuration: Symbol('Live Event / VOD scrubber'),
};

/**
 * The order below matters.
 * LAYOUT_2UP should be before PIP.
 * LAYOUT_4UP should be fore GRID.
 *
 * This is because we automatically upgrade the layout by using Array.find
 *
 */
export const PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS = {
    [PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE]: 1,
    [PLAYER_LAYOUT_STYLE.LAYOUT_2UP]: 2,
    [PLAYER_LAYOUT_STYLE.LAYOUT_PIP]: 2,
    [PLAYER_LAYOUT_STYLE.LAYOUT_3UP]: 3,
    [PLAYER_LAYOUT_STYLE.LAYOUT_4UP]: 4,
    [PLAYER_LAYOUT_STYLE.LAYOUT_GRID]: 4,
};

export const PLAYER_TECH_VIDEO_QUALITY_CATEGORY = {
    AUTO: 'auto',
    LOW: 'low',
    HD: 'hd',
} as const;

export type VideoQualityValue =
    (typeof PLAYER_TECH_VIDEO_QUALITY_CATEGORY)[keyof typeof PLAYER_TECH_VIDEO_QUALITY_CATEGORY];

/**
 * This number indicates that the captions are turned off.
 *
 */
export const CAPTIONS_OFF_INDEX = -1;

/**
 * This number indicates that default audio track if there is no preference.
 */
export const AUDIO_DEFAULT_INDEX = 0;

/**
 */
/**
 * This constant is exposed on this library. See src/index.js
 * It can be used to pass as type on the setKeyEventData() API.
 * This is used to decide which styling to apply -- Kayo or Flash
 *
 */
export const KEY_EVENT_TYPE = {
    MOMENT: Symbol('Key Moments for Kayo'),
    SEGMENT: Symbol('Key Segments for Flash'),
};

export const ANALYTICS_EVENT_TYPE = {
    CAPTIONS_OPTIONS_EVENT_TYPE: 'captions-options',
    ENTER_SPLITVIEW_EVENT_TYPE: 'enter-splitview',
    EXIT_SPLITVIEW_EVENT_TYPE: 'exit-splitview',
    FOCUS_SWITCH_EVENT_TYPE: 'focus-switch',
    LAYOUT_SELECTION_EVENT_TYPE: 'layout-selection',
    POSITION_SWITCH_EVENT_TYPE: 'position-switch',
    RELATED_VIDEOS_EVENT_TYPE: 'related-videos',
    SPLITVIEW_ADD_EVENT_TYPE: 'splitview-add',
    SPLITVIEW_REMOVE_EVENT_TYPE: 'splitview-remove',
    UP_NEXT_EVENT_TYPE: 'up-next',
    UP_NEXT_DISMISS_EVENT_TYPE: 'up-next-dismiss',
    VIDEO_FEATURE_EVENT_TYPE: 'video-feature',
    VIDEO_START_EVENT_TYPE: 'video-start',
    VIDEO_STOP_EVENT_TYPE: 'video-stop',
};

export type AnalyticsEventTypeValue =
    (typeof ANALYTICS_EVENT_TYPE)[keyof typeof ANALYTICS_EVENT_TYPE];

export const ANALYTICS_EVENT_NAME = {
    SELECT_OPTION: 'select-option',
    HUD_SELECT_OPTION: 'hud-select-option',
    SELECT_CAST: 'select-cast',
    SELECT_QUALITY: 'select-quality',
    SELECT_MULTIVIEW: 'select-multiview',
    SELECT_CLOSED_CAPTIONS: 'select-closed-captions',
    SELECT_KEY: 'select-key',
} as const;

export type AnalyticsEventNameValue =
    (typeof ANALYTICS_EVENT_NAME)[keyof typeof ANALYTICS_EVENT_NAME];

export const TRANSITION_DURATION_S = 0.1;
export const TRANSITION_DURATION_MS = Math.round(TRANSITION_DURATION_S * 1000);
export const AUDIO_CODEC_CODE: ReadonlyMap<string, string> = new Map ([
    ['mp4a.40.2', 'AAC'],
    ['ac-3', 'AC3'],
    ['ec-3', 'EAC3'],
 ]);
