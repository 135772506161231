import type Response from './response';
import type {CallbackArgs} from './types';

/**
 * getLowestFrequency gets lowest frequency to poll, 'false' if frequency not available on
 * any callbacks else the smallest positive value among the callbacks, else '0'
 *
 * @param callbacks    - Array of callbacks with polling frequency
 *
 * @returns             lowest frequency for callbacks or false
 */

export function getLowestFrequency(
    callbacks: Array<CallbackArgs> = []
): number | false {
    return callbacks.reduce<number | false>((lowest, {frequency}) => {
        if (lowest) {
            if (frequency && frequency < lowest) {
                return frequency;
            }
        } else if (frequency && frequency > 0) {
            return frequency;
        }

        return lowest || false;
    }, false);
}

/**
 * Default validator to use for superagent, relying on its internal "ok" check.
 * Super agent object which will have its `responseOk` property returned as a simple validation check
 *
 * @param superAgentObject  - Super agent object which will have its `responseOk` property returned as a simple validation check
 *        responseOk        - internal superagent responseOk validation bool
 * @returns True if response is valid, false if it is not valid (e.g. stream should retry)
 */
export const defaultResponseValidator = (superAgentObject: Response): boolean =>
    superAgentObject.responseOk;
