import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = {
    isOn?: boolean;
} & IcProps;

const IC173KeyMPlayHead = ({
    isOn = false,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            fillOpacity={isOn ? 1 : 0}
            d="M85.4 8.2H81l1.9-1.9-3.9-4-5.9 5.9H14.6c-1.5 0-2.8 1.2-2.8 2.8v47.5c0 1.5 1.2 2.8 2.8 2.8H20l-4.5 4.5 3.9 3.9 8.4-8.4h20.4V76c-3.6.8-6.2 4-6.2 7.8 0 4.4 3.6 8 8 8s8-3.6 8-8c0-3.8-2.7-7-6.2-7.8V61.2h33.7c1.5 0 2.8-1.2 2.8-2.8V10.9c-.1-1.5-1.3-2.7-2.9-2.7zM25.6 55.7h-8.2v-42h50.2l-42 42zm57 0H33.5l42-42h7.2l-.1 42z"
            transform="translate(8 8)"
        />
        <path
            fillOpacity={isOn ? 0 : 1}
            d="M48.5 46.8l2.8-11.1 11.1-2.8-11.1-2.7-2.8-11.1-2.7 11.1-11.1 2.7 11.1 2.8zM29.9 50.5l1.4-5.7 5.7-1.4-5.7-1.4-1.4-5.7-1.4 5.7-5.7 1.4 5.7 1.4zM64.3 27.5l.8-3.4 3.4-.8-3.4-.8-.8-3.4-.8 3.4-3.4.8 3.4.8zM61.8 42.3l6.2 1.5 1.5 6.2 1.5-6.2 6.3-1.5-6.3-1.5-1.5-6.2-1.5 6.2z"
            transform="translate(8 8)"
        />
        <path
            fillOpacity={isOn ? 0 : 1}
            d="M85.4 8.2H14.6c-1.5 0-2.8 1.2-2.8 2.8v47.5c0 1.5 1.2 2.8 2.8 2.8h33.7V76c-3.6.8-6.2 4-6.2 7.8 0 4.4 3.6 8 8 8s8-3.6 8-8c0-3.8-2.7-7-6.2-7.8V61.3h33.7c1.5 0 2.8-1.2 2.8-2.8V11c-.2-1.5-1.4-2.8-3-2.8zm-2.8 47.6H17.4v-42h65.3v42h-.1z"
            transform="translate(8 8)"
        />
    </Ic>
);

IC173KeyMPlayHead.displayName = 'IC173KeyMPlayHead';

export default IC173KeyMPlayHead;
