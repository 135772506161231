import moment from 'moment';
import padStart from 'lodash/padStart';
import {SERIES_IDS, ROUND_TYPE} from './constants';

/**
 * Reference: https://bitbucket.foxsports.com.au/projects/FISO/repos/video-player/browse/src/js/common/marble-video-parser.js#138
 * Parse a number of seconds into HH:MM:SS (omit hours if < 1)
 *
 * @param  {int}      durationSeconds number of seconds
 * @return {string}   formatted string HH:MM:SS
 */
export function getHHMMSS(durationSeconds) {
    let timeTokens = [];

    if (isNaN(Number(durationSeconds))) {
        return '0'; // An invalid number of seconds provided
    }

    const duration = moment.duration(durationSeconds, 'seconds');

    if (duration.hours()) {
        timeTokens = [
            duration.hours(),
            padStart(duration.minutes(), 2, '0'),
            padStart(duration.seconds(), 2, '0')
        ];
    } else {
        timeTokens = [
            duration.minutes(),
            padStart(duration.seconds(), 2, '0')
        ];
    }

    return timeTokens.join(':');
}

export function getCurrencySymbol(currency) {
    let currencySymbol;

    switch (currency) {
        case 'GBP':
            currencySymbol = '£';
            break;
        case 'EUR':
            currencySymbol = '€';
            break;
        default:
            currencySymbol = '$';
    }

    return currencySymbol;
}

export function exists(value) {
    return value !== null;
}

/**
 * Returns the round type for given sport series
 *
 * @param  {String} sport       sport name
 * @param  {Number} seriesId    series id
 * @return {Object}             round type "round", "day" or "none"
 */
export function getRoundType(sport, seriesId) {
    switch (sport) {
        case 'basketball':
            switch (seriesId) {
                case SERIES_IDS.basketball.nba:
                    return ROUND_TYPE.days;

                default:
                    return ROUND_TYPE.rounds;
            }

        case 'baseball':
            return ROUND_TYPE.days;

        case 'cricket':
            switch (seriesId) {
                case SERIES_IDS.cricket.oneDayInternational:
                case SERIES_IDS.cricket.internationalTests:
                    return ROUND_TYPE.none;

                default:
                    return ROUND_TYPE.rounds;
            }

        case 'football':
            switch (seriesId) {
                case SERIES_IDS.football.internationalFriendlies:
                    return ROUND_TYPE.days;
                default:
                    return ROUND_TYPE.rounds;
            }

        case 'icehockey':
            return ROUND_TYPE.days;

        case 'league':
            switch (seriesId) {
                case SERIES_IDS.league.stateOfOrigin:
                case SERIES_IDS.league.cityCountry:
                case SERIES_IDS.league.fourNations:
                case SERIES_IDS.league.worldClubSeries:
                case SERIES_IDS.league.aucklandNines:
                    return ROUND_TYPE.none;

                case SERIES_IDS.league.testMatches:
                    return ROUND_TYPE.days;

                default:
                    return ROUND_TYPE.rounds;
            }

        case 'netball':
            switch (seriesId) {
                case SERIES_IDS.netball.tests:
                    return ROUND_TYPE.none;

                default:
                    return ROUND_TYPE.rounds;
            }

        case 'rugby':
            switch (seriesId) {
                case SERIES_IDS.rugby.internationals:
                    return ROUND_TYPE.none;

                default:
                    return ROUND_TYPE.rounds;
            }

        default:
            return ROUND_TYPE.rounds;
    }
}
