import querystring from 'querystring';
import React from 'react';
import bacon from 'baconjs';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml, handleStreamForIsoRender} from '@fsa/fs-commons/lib/iso/render';

import {createRoot} from 'react-dom/client';
import BannerComponent from '../components/banner';
import getBannerConfig from '../streams/banner-config/get-banner-config';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function Banner(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        bannerConfigPath: settings.bannerConfigPath,
        currency: settings.currency || 'AUD',
        vimondEnv: settings.vimondEnv
    };
}

Banner.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Banner.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Banner',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Banner.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <BannerComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Banner.prototype.getData = function (initialData = false) {
    if (!process || !process.browser) {
        return bacon.later(0, {view: {}, iso: {}});
    }

    const {bannerConfigPath, vimondEnv, currency} = this.config;

    const bannerConfig = initialLaterOr(
        initialData && initialData.bannerConfig,
        getBannerConfig({bannerConfigPath})
    );

    const activeBanner = bannerConfig
        .map((banners) => find(banners, (banner) => moment()
            .isBetween(
                moment(banner.bannerStartDate, 'DD/MM/YYYY HH:mm Z'),
                moment(banner.bannerEndDate, 'DD/MM/YYYY HH:mm Z')
            )));

    const promotionalPassUrl = activeBanner
        .map((banner) => {
            if (isEmpty(banner)) {
                return null;
            }

            if (banner.specialLink) {
                return banner.specialLink;
            }

            return (
                '/subscribe?' +
                querystring.stringify({
                    productGroupId: banner[vimondEnv][currency].productGroupId,
                    chosenProductId: banner[vimondEnv][currency].chosenProductId
                })
            );
        });

    const isBannerVisible = activeBanner
        .map((banner) => !!banner);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            promotionalPassUrl,
            activeBanner,
            isBannerVisible
        }),
        iso: bacon.combineTemplate({
            bannerConfig
        })
    });
};

Banner.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <BannerComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <BannerComponent {...data.view} />,
            'hawkwidgets-banner',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

Banner.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new Banner(element, settings);
}

/**
 *  * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 *   */
pageBoot(Banner, 'hawkwidgets-banner');
