import {isBrowser} from '@fsa-streamotion/browser-utils';

import {defer, Observable, type Observer, of, throwError, timer} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

import {
    DEFAULT_CACHE_TTL_MS,
    DEFAULT_REQUEST_TIMEOUT_MS,
    DEFAULT_RETRY_COUNT,
    DEFAULT_RETRY_DELAY_MS,
} from './constants';
import {defaultResponseValidator} from './helpers';
import {getFeed, stopFeed} from './request-manager';
import type Response from './response';
import type {
    GetApiFromBinderArgs,
    GetApiFromRetryWithErrorRxArgs,
} from './types';

/**
 * Api call retry strategy with delay and max number of retries
 *
 * @param frequencyMs       - How frequency[ms] to poll
 * @param maxRetryAttempts  - How many times to retry the api call
 * @param retryDelayMs      - How often[ms] to retry the api call
 *
 * @returns A function that returns an Rx Observable
 */
type DelayRetryStrategyArgs = {
    frequencyMs?: number;
    maxRetryAttempts?: number;
    retryDelayMs?: number;
};

const delayRetryStrategy =
    ({
        frequencyMs = 0,
        maxRetryAttempts = DEFAULT_RETRY_COUNT,
        retryDelayMs = DEFAULT_RETRY_DELAY_MS,
    }: DelayRetryStrategyArgs = {}) =>
    (error: unknown, retryAttempt: number) => {
        if (!isBrowser() || retryAttempt > maxRetryAttempts || frequencyMs) {
            return throwError(() => error);
        }

        return timer(retryDelayMs);
    };

function getFrequency(frequencyMs: number, isRetried: boolean): number | null {
    if (isRetried) {
        return frequencyMs ? frequencyMs : null;
    } else {
        return frequencyMs;
    }
}

/**
 * Get an observable for an API endpoint
 *
 * @param url               - URL to fetch
 * @param freqMs            - How frequency[ms] to poll
 * @param requestTimeoutMs  - How long (in milliseconds) to wait before abandoning request
 * @param cacheTtlMs        - How long[ms] this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param validateResponse  - Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns Rx Observable
 */
export function getApiFromBinderRx({
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    url,
    freqMs,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    validateResponse = defaultResponseValidator,
}: GetApiFromBinderArgs): Observable<Response> {
    return new Observable((observer: Observer<Response>) => {
        const onResponse = (superAgentObject: Response): void => {
            if (validateResponse?.(superAgentObject)) {
                observer.next(superAgentObject);
            } else {
                observer.error(superAgentObject);
            }
        };

        getFeed({
            cacheTtlMs,
            freqMs,
            onResponse,
            requestTimeoutMs,
            url,
        });

        return () => stopFeed(url, onResponse);
    });
}

/**
 * Get a stream for an API endpoint, and on connection issues retry the request x times.
 *
 * After failing all retries, the final error will be released to the stream as a value.
 *
 * @param url               - URL to fetch
 * @param frequencyMs       - How frequency[ms] to poll
 * @param delayMs           - How long[ms] to wait to wait between retries
 * @param retries           - How many retries (not including initial) attempts
 * @param requestTimeoutMs  - How long (in milliseconds) to wait before abandoning request
 * @param cacheTtlMs        - How long[ms] this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param validateResponse  - Superagent response object validator that returns <code>true</code> or <code>false</code> if the response is valid or not valid respectively
 *
 * @returns - Rx Observable
 */
export function getApiFromRetryWithErrorRx({
    url,
    freqMs = 0,
    delayMs = DEFAULT_RETRY_DELAY_MS,
    retries = DEFAULT_RETRY_COUNT,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS,
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    validateResponse = defaultResponseValidator,
}: GetApiFromRetryWithErrorRxArgs): Observable<Response> {
    let isRetried = false;

    return defer(() => {
        const callFrequency = getFrequency(freqMs, isRetried);

        if (!isRetried) {
            isRetried = true;
        }

        return getApiFromBinderRx({
            url,
            freqMs: callFrequency,
            cacheTtlMs,
            validateResponse,
            requestTimeoutMs,
        });
    }).pipe(
        retry({
            delay: delayRetryStrategy({
                frequencyMs: freqMs,
                maxRetryAttempts: retries,
                retryDelayMs: delayMs,
            }),
        }),
        catchError((e) => of(e))
    );
}
