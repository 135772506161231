import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

export function googleSetDimension(dimensionIndex, dimensionValue) {
    if (process.browser && window && (typeof window.ga === 'function')) {
        window.ga('set', `dimension${dimensionIndex}`, dimensionValue);
    }
}

export function googleTrackEvent(eventCategory, eventAction, eventLabel, eventValue) {
    if (process.browser && window && (typeof window.ga === 'function')) {
        const eventObject = {
            hitType: 'event',
            eventCategory,
            eventAction,
            eventLabel,
            eventValue
        };

        window.ga('send', omitBy(eventObject, isNil));
    }
}

export function googleTagManagerPushDataLayer(dataLayer) {
    if (process.browser && has(window, 'dataLayer.push') && (typeof window.dataLayer.push === 'function')) {
        window.dataLayer.push(dataLayer);
    }
}
