import {
    ANALYTICS_EVENT_NAME,
    type HudContent,
} from '@fsa-streamotion/player-state';

import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';

import IGM28HudBtn from '../../../component-library/molecules/igm/28-hud-btn';
import {usePlayerContext} from '../context';

type Props = {
    isSelected?: boolean;
    render?: HudContent['tab']['render'];
    sectionKey?: string;
    title?: string;
    setCurrentHudSection?: (sectionKey?: string) => void;
    onFocus?: () => void;
};

const HudTab = (
    {
        isSelected,
        render,
        sectionKey,
        title,
        setCurrentHudSection,
        onFocus,
    }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
): React.ReactElement => {
    const {generalConfig} = usePlayerContext() ?? {};
    let containerRef = useRef<HTMLButtonElement>(null);

    if (ref && typeof ref !== 'function') {
        containerRef = ref;
    }

    useEffect(
        function renderContent() {
            // Renders the icon inside containerRef
            const cleanup =
                containerRef?.current && render?.(containerRef?.current);

            return () => {
                if (typeof cleanup === 'function') {
                    cleanup();
                }
            };
        },
        [render, title]
    );

    useEffect(
        function sendAdobeEventsWhenSelected() {
            if (isSelected) {
                generalConfig?.onPlayerInteractionByType(
                    ANALYTICS_EVENT_NAME.HUD_SELECT_OPTION,
                    sectionKey
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSelected]
    );

    return (
        <IGM28HudBtn
            isSelected={isSelected}
            key={sectionKey}
            aria-controls={`panel-${sectionKey}`}
            aria-label={title}
            id={`tab-${sectionKey}`}
            onClick={() => setCurrentHudSection?.(sectionKey)}
            ref={containerRef}
            onFocus={onFocus}
        />
    );
};

HudTab.displayName = 'HudTab';

export default observer<Props, HTMLButtonElement>(HudTab, {forwardRef: true});
