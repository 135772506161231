/**
 * Mapping of thumbnail times to their index.
 */
export default class ThumbnailRegistry {
    times: number[] = [];
    indexed = false;
    indexOf: (value: number) => number = () => -1;

    /**
     * Add a time to the index.
     *
     * Preconditions:
     * - Must be greater than previously added time.
     * - Must not have called <code>done()</code>.
     *
     * @param time - a point in time
     */
    add(time: number): void {
        const {times, indexed} = this;

        if (indexed) {
            throw new Error('Cannot add more times to a finalised index');
        }

        const lastNumber = times[times.length - 1];

        if (lastNumber !== undefined && time <= lastNumber) {
            throw new Error('Times must be indexed in order');
        }

        times.push(time);
    }

    /**
     * Call this after adding all times to finalise this index.
     *
     */
    ready(): void {
        this.indexed = true;

        const {times} = this;

        switch (times.length) {
            case 0:
                this.indexOf = () => -1;
                break;

            case 1:
                this.indexOf = () => 0;
                break;

            case 2:
                this.indexOf = (seconds) =>
                    seconds < Number(times[1]) ? 0 : 1;
                break;

            default: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const interval = times[times.length - 2]! / (times.length - 2);

                this.indexOf = (seconds) => {
                    // Estimate the index. We're going to assume intervals are stable across the whole time range so that this estimate
                    // will be very close.
                    let index = (seconds / interval) | 0; // eslint-disable-line no-bitwise

                    if (seconds > Number(times[index])) {
                        // Search forward
                        while (
                            index < times.length - 1 &&
                            seconds > Number(times[index + 1])
                        ) {
                            ++index;
                        }
                    } else if (seconds < Number(times[index - 1])) {
                        // Search backward
                        while (
                            index > 0 &&
                            seconds < Number(times[index - 1])
                        ) {
                            --index;
                        }
                    }

                    return index;
                };
            }
        }
    }

    /**
     * Get the point in time, in seconds, of the thumbnail at the specified index.
     *
     * @param index - the index of the thumbnail
     * @returns the point in time, in seconds, at which the thumbnail occurs
     */
    secondsAtIndex(index: number): number | undefined {
        return this.times[index];
    }

    get length(): number {
        return this.times.length;
    }
}
