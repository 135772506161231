import type Hls from 'hls.js';
import sortBy from 'lodash/sortBy';

import NativeAudio from '../native/audio';
import {Events} from './types';

export default class HlsAudio extends NativeAudio {
    declare playbackHandler: Hls;

    override setup(): void {
        this.playbackHandler.on(
            Events.AUDIO_TRACKS_UPDATED,
            this.onHlsEventAudioTracksUpdated,
            this
        );
    }

    override destroy(): void {
        this.playbackHandler.off(
            Events.AUDIO_TRACKS_UPDATED,
            this.onHlsEventAudioTracksUpdated,
            this
        );
    }

    /**
     * Handler for HLS's hlsAudioTracksUpdated event, so if the metadata is available then initialise the audio tracks
     *
     */
    onHlsEventAudioTracksUpdated(): void {
        const newTracksList = this.playbackHandler.audioTracks;

        if (this.currentTrackIndex === -1 && newTracksList.length) {
            this.currentTrackIndex = 0;
        }

        this.tracks = sortBy(newTracksList, ['id']).map((track, index) => {
            if (this.currentTrackIndex === -1 && track.default) {
                this.currentTrackIndex = index;
            }

            return {
                channelCount: parseInt(track.attrs.CHANNELS ?? ''),
                enabled: index === this.currentTrackIndex,
                id: track.id,
                kind: track.default ? 'main' : '',
                label: track.name,
                language: track.lang,
            };
        });

        this.dispatchAudioTracksUpdatedEvent();
    }

    /**
     * Sets the active audio track's index
     *
     * @param index - The index of the audio track
     */
    override set currentAudioTrackIndex(index: number) {
        if (!this.tracks[index]) {
            return;
        }

        const hlsTrack = this.playbackHandler.audioTracks.find(
            (track) => track.id === this.tracks[index]?.id
        );

        if (!hlsTrack) {
            return;
        }

        this.playbackHandler.audioTrack = hlsTrack.id;
        this.currentTrackIndex = index;

        this.dispatchAudioTracksUpdatedEvent();
    }
}
