import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {ironStone, black, white} from '../../../../common/palette';
import GA72HudBg from '../../../atoms/ga/72-hud-bg';

const HudContentContainer = styled.section.attrs({
    role: 'tabpanel',
})`
    display: flex;
    flex-direction: column;
    border-left: 1px ${rgba(white, 0.15)};
    width: ${({hidden}) => (hidden ? 0 : '321px')};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* This scrollbar-* property is for Firefox 64+ */
    scrollbar-color: ${ironStone} ${rgba(black, 0.8)};

    ::-webkit-scrollbar-track {
        background-color: ${rgba(black, 0.8)};
    }

    ::-webkit-scrollbar {
        background-color: ${rgba(black, 0.8)};
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${ironStone};
    }
`;

export type Props = {
    children?: React.ReactNode;
    hidden?: boolean;
    id?: string;
};

const IGM01HudContent = React.forwardRef<HTMLDivElement, Props>(
    ({children, ...htmlAttributes}, ref) => (
        <HudContentContainer {...htmlAttributes}>
            <GA72HudBg ref={ref}>{children}</GA72HudBg>
        </HudContentContainer>
    )
);

IGM01HudContent.displayName = 'IGM01HudContent';

export default IGM01HudContent;
