export default class BcoveImaAdsAdapter {
  isUsed() {
    return this.player.ima3 && this.player.ads;
  }

  getVersion() {
    var ret;
    if (typeof google !== 'undefined' && google.ima) {
      ret = 'bcove-ima-jsclass ' + google.ima.VERSION;
    }
    return ret;
  }

  getPlayhead() {
    var player = this.getVideo().getAdapter().player;
    if (this.player.ads && this.player.ads.ad) {
      return this.player.ads.ad.currentTime;
    }
    return player.currentTime();
  }

  getDuration() {
    let ret;
    if (this.player.ads && this.player.ads.ad && this.player.ads.ad.duration) {
      ret = this.player.ads.ad.duration
    } else {
      var player = this.getVideo().getAdapter().player;
      if (this.player.ima3 && this.player.ima3.currentAdEventInfo) {
        return this.player.ima3.currentAdEventInfo.creativeLength[0];
      }
      ret = (player && player.duration) ? player.duration() : null;
    }
    return ret;
  }

  getPosition() {
    let ret = this.getNpawReference().Constants.AdPosition.Midroll;
    const videoAdapter = this.getVideo().getAdapter();
    if (this.player.ads && this.player.ads.ad) {
      switch (this.player.ads.ad.type) {
        case 'PREROLL':
          ret = this.getNpawReference().Constants.AdPosition.Preroll;
          break;
        case 'MIDROLL':
          ret = this.getNpawReference().Constants.AdPosition.Midroll;
          break;
        case 'POSTROLL':
          ret = this.getNpawReference().Constants.AdPosition.Postroll;
          break;
      }
    } else {
      if (!videoAdapter.flags.isJoined) {
        ret = this.getNpawReference().Constants.AdPosition.Preroll;
      } else if (videoAdapter.getPlayhead() > 0.1 && videoAdapter.getPlayhead() < videoAdapter.getDuration()) {
        ret = this.getNpawReference().Constants.AdPosition.Midroll;
      } else if (videoAdapter.getPlayhead() >= videoAdapter.getDuration()) {
        ret = this.getNpawReference().Constants.AdPosition.Postroll;
      }
    }
    return ret;
  }

  getResource() {
    var ret;
    if (this.player.ima3) {
      ret = this.player.ima3.currentAd.getMediaUrl();
    }
    return ret;
  }

  getTitle() {
    var ret;
    if (this.player.ima3 && this.player.ima3.currentAd) {
      ret = this.player.ima3.currentAd.getTitle();
    }
    return ret;
  }

  getPlayerVersion() {
    var ret;
    if (this.player.ima3) {
      ret = this.player.ima3.VERSION;
    }
    return ret;
  }

  getGivenBreaks() {
    var ret;
    if (this.player.ima3) {
      ret = this.player.ima3.adsManager.getCuePoints().length;
    }
    return ret;
  }

  getBreaksTime() {
    var ret;
    if (this.player.ima3) {
      var cuelist = this.player.ima3.adsManager.getCuePoints();
      for (var index in cuelist) {
        if ((!cuelist[index] && cuelist[index] !== 0) || cuelist[index] === -1) {
          cuelist[index] = this.getVideo().getAdapter().getDuration();
        }
      }
      ret = cuelist;
    }
    return ret;
  }

  getGivenAds() {
    var ret;
    if (this.player.ima3 && this.player.ima3.currentAd && this.player.ima3.currentAd.getAdPodInfo()) {
      ret = this.player.ima3.currentAd.getAdPodInfo().getTotalAds();
    }
    return ret;
  }

  getAudioEnabled() {
    var ret;
    if (this.player.muted()) {
      ret = false;
    } else {
      ret = !!this.player.volume();
    }
    return ret;
  }

  getIsSkippable() {
    var ret = false;
    if (this.player.ima3) {
      ret = this.player.ima3.currentAd && this.player.ima3.currentAd.isSkippable();
    }
    return ret;
  }

  getIsFullscreen() {
    var ret;
    if (this.getPlugin().deviceDetector.isIphone()) {
      ret = !this.getPlugin().deviceDetector.isInBackground;
    } else {
      var container;
      if (this.player.ima3) {
        container = this.player.ima3.el;
      }
      ret = container ? (window.innerHeight <= container.clientHeight + 30 && window.innerWidth <= container.clientWidth + 30) : false;
    }
    return ret;
  }

  getIsVisible() {
    var ret;
    if (this.getPlugin().deviceDetector.isIphone()) {
      ret = !this.getPlugin().deviceDetector.isInBackground;
    } else if (this.player.ima3) {
      ret = this.getNpawUtils().calculateAdViewability(this.player.ima3.el);
    }
    return ret;
  }

  getBitrate() {
    var bitrate;
    if (this.player.ima3 && this.player.ima3.currentAd && this.player.ima3.currentAd.getVastMediaBitrate()) {
      bitrate = this.player.ima3.currentAd.getVastMediaBitrate();
      this.lastBitrate = bitrate;
    }
    bitrate = this.lastBitrate && !bitrate ? this.lastBitrate : -1;
    return bitrate;
  }

  getCreativeId() {
    var ret;
    if (this.player.ima3 && this.player.ima3.currentAd) {
      ret = this.player.ima3.currentAd.getCreativeAdId() || this.player.ima3.currentAd.getCreativeId();
    } else if (this.titles) {
      ret = this.titles[(this.getVideo().requestBuilder.lastSent.adNumber || 1) - 1]._creativeId;
    }
    return ret;
  }

  registerListeners() {
    this.monitorPlayhead(true, false);
    this.references = {
      'ads-ad-started': this.startJoinListener.bind(this),
      'ima3-paused': this.pausedListener.bind(this),
      'ima3-resumed': this.resumedListener.bind(this),
      'ima3-complete': this.adEndedListener.bind(this),
      'ima3-skipped': this.skippedListener.bind(this),
      'ima3-ad-error': this.errorListener.bind(this),
      'ima3-first-quartile': this.firstQuartileListener.bind(this),
      'ima3-midpoint': this.secondQuartileListener.bind(this),
      'ima3-third-quartile': this.thirdQuartileListener.bind(this),
      'ads-pod-ended': this.podEndListener.bind(this),
      'ads-click': this.clickListener.bind(this),
      'ads-first-quartile': this.firstQuartileListener.bind(this),
      'ads-midpoint': this.secondQuartileListener.bind(this),
      'ads-third-quartile': this.thirdQuartileListener.bind(this)
    };
    if (this.player) {
      for (var key in this.references) {
        this.player.on(key, this.references[key]);
      }
    }
  }

  startJoinListener(e) {
    if (this.getVideo().getAdapter()) {
      this.getVideo().getAdapter().firePause();
    }
    this.fireStart();
    this.fireJoin();
  }

  pausedListener(e) {
    this.firePause();
  }

  resumedListener(e) {
    this.fireResume();
  }

  skippedListener(e) {
    this.fireSkip();
    if (this.getVideo().getAdapter()) this.getVideo().getAdapter().fireResume();
  }

  adEndedListener(e) {
    var playheadVal = this.getVideo().requestBuilder.lastSent.adPosition === this.getNpawReference().Constants.AdPosition.Preroll ? 0 : this.getVideo().getAdapter().lastPlayhead;
    this.fireStop({
      playhead: playheadVal,
      adPlayhead: this.getDuration(),
    });
    this.lastBitrate = undefined;
  }

  errorListener(e) {
    if (this.player.ima3) {
      if (e.getError && e.getError()) {
        var error = e.getError();
        this.fireError(error.getCode(), error.getMessage());
      } else {
        this.fireError();
      }
    }
  }

  clickListener(e) {
    if (this.player.ima3) {
      var clickurl;
      if (this.player.ima3.currentAd && this.player.ima3.currentAd.g) {
        clickurl = this.player.ima3.currentAd.g.clickThroughUrl;
      }
      this.fireClick(clickurl);
    }
  }

  firstQuartileListener(e) {
    this.fireQuartile(1);
  }

  secondQuartileListener(e) {
    this.fireQuartile(2);
  }

  thirdQuartileListener(e) {
    this.fireQuartile(3);
  }

  podEndListener(e) {
    this.fireBreakStop();
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.off(key, this.references[key]);
      }
      delete this.references;
    }
  }

  adPlayerErrorListener(e) {
    var error = e.data.AdError;
    var noResponseErrors = [1012, 1005, 301, 402, 302];
    var emptyResponse = [1007, 1009, 303];
    var wrongResponse = [403, 100, 503, 101, 102];
    if (noResponseErrors.indexOf(error.getErrorCode()) >= 0) {
      this.fireManifest(youbora.Constants.ManifestError.NO_RESPONSE, error.getMessage());
    } else if (emptyResponse.indexOf(error.getErrorCode()) >= 0) {
      this.fireManifest(youbora.Constants.ManifestError.EMPTY, error.getMessage());
    } else if (wrongResponse.indexOf(error.getErrorCode()) >= 0) {
      this.fireManifest(youbora.Constants.ManifestError.WRONG, error.getMessage());
    } else {
      this.fireError(error.getErrorCode(), error.getMessage());
    }
  }
}