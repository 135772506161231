import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {DEFAULT_VIMOND_ENV, PROXY_RESOURCE_URL} from '../../utils/constants';

export default function getVimondAssetData({
    assetId,
    freq = 0,
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const url = `${PROXY_RESOURCE_URL[vimondEnv]}videos/${assetId}?sport=${sport}`;

    return getApiFromRetryWithError({
        url,
        freq
    }).flatMapLatest((response) => {
        if (response.responseConsideredValid) {
            return response.parseResponseText();
        }

        const errorMessage = `FS-HAWKOTT: unable to get vimond asset data (${url}): ${response.responseStatus} ${response.superAgentErr}`;

        return new bacon.Error(errorMessage);
    });
}

