import {
    stylesWhenNot,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import {black} from '../../../../common/palette';
import ScreenArea from './screen-area';

const CentreControls = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('opacity', 'width')};
    margin: 0;
    padding: 0;
    width: ${({theme}) =>
        theme.isHudOpen ? `${theme.effectiveViewportWidthPx}px` : '100%'};
    height: 100%;

    ${stylesWhen('hasBackgroundColor')`
        background-color: ${rgba(black, 0.5)};
    `}

    ${stylesWhenNot('isVisible')`
        visibility: hidden;
        opacity: 0;
    `}
`;

export type Props = {
    /** Addtional class names(s), e.g. for custom styled component styles */
    className?: string;
    /** Are the controls currently visible? */
    isVisible?: boolean;
    /** top centre controls (e.g. buttons)  */
    topCentreControls?: React.ReactNode;
    /** top left controls (e.g. buttons)  */
    topLeftControls?: React.ReactNode;
    /** top right controls (e.g. buttons)  */
    topRightControls?: React.ReactNode;
    /** middle centre controls (e.g. buttons)  */
    middleCentreControls?: React.ReactNode;
    /** middle left controls (e.g. buttons)  */
    middleLeftControls?: React.ReactNode;
    /** middle right controls (e.g. buttons)  */
    middleRightControls?: React.ReactNode;
    /** bottom centre controls (e.g. buttons)  */
    bottomCentreControls?: React.ReactNode;
    /** bottom left controls (e.g. buttons)  */
    bottomLeftControls?: React.ReactNode;
    /** bottom right controls (e.g. buttons)  */
    bottomRightControls?: React.ReactNode;
    /** should show background color?  */
    hasBackgroundColor?: boolean;
    /** styles for control wrapper  */
    controlsStyle?: object;
};

const TM05CentreControls = ({
    isVisible = true,
    className,
    topCentreControls,
    topLeftControls,
    topRightControls,
    middleCentreControls,
    middleLeftControls,
    middleRightControls,
    bottomCentreControls,
    bottomLeftControls,
    bottomRightControls,
    hasBackgroundColor,
    controlsStyle,
}: Props): React.ReactElement => (
    <CentreControls
        isVisible={isVisible}
        hasBackgroundColor={hasBackgroundColor}
        className={classnames('TM05CentreControls', className)}
    >
        {!!(topCentreControls || topLeftControls || topRightControls) && (
            <ScreenArea
                style={controlsStyle}
                verticallyAlign="top"
                centreControls={topCentreControls}
                leftControls={topLeftControls}
                rightControls={topRightControls}
            />
        )}

        {!!(
            middleCentreControls ||
            middleLeftControls ||
            middleRightControls
        ) && (
            <ScreenArea
                style={controlsStyle}
                centreControls={middleCentreControls}
                leftControls={middleLeftControls}
                rightControls={middleRightControls}
            />
        )}

        {!!(
            bottomCentreControls ||
            bottomLeftControls ||
            bottomRightControls
        ) && (
            <ScreenArea
                style={controlsStyle}
                verticallyAlign="bottom"
                centreControls={bottomCentreControls}
                leftControls={bottomLeftControls}
                rightControls={bottomRightControls}
            />
        )}
    </CentreControls>
);

TM05CentreControls.displayName = 'TM05CentreControls';

export default TM05CentreControls;
