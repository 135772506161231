// Converts a stream of video IDs into a stream that produces {indexOfSeconds, urlAtIndex} starting with SD images and then HD images.
import {
    type Observable,
    map,
    EMPTY,
    concat,
    retryWhen,
    throwError,
    timer,
    of,
} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import type {Thumbnail} from '../../state/types';
import createThumbnailRegistry from './create-thumbnail-registry';
import loadArrayBufferStream from './load-array-buffer-stream';

const SD_DELAY_MS = 1000; // default delay before loading thumbnails
const HD_DELAY_MS = 10000; // default delay between loading SD then HD thumbnails
const HD_RETRIES = 5;
const HD_RETRY_LINEAR_BACKOFF_MS = 1000;

type Params = {
    url?: string | null;
    delayMs: number | null;
    duration?: number;
    retries?: number;
    retryLinearBackoffMs?: number;
};

export type ThumbnailStream = Observable<Thumbnail>;

const createThumbnailStream = ({
    url,
    delayMs,
    duration,
    retries = 0,
    retryLinearBackoffMs = 0,
}: Params): ThumbnailStream => {
    let buffer = url && delayMs ? loadArrayBufferStream({url, delayMs}) : EMPTY;

    if (retries > 0) {
        buffer = buffer.pipe(
            retryWhen((errors) =>
                errors.pipe(
                    mergeMap((error, errorIndex) => {
                        const retryAttempt = errorIndex + 1;

                        if (retryAttempt > retries) {
                            return throwError(error);
                        }

                        return timer(retryAttempt * retryLinearBackoffMs);
                    })
                )
            )
        );
    }

    return buffer.pipe(
        catchError(() => EMPTY),
        map(createThumbnailRegistry),
        map(({indexOfSeconds, totalThumbnails, ...props}) => ({
            indexOfSeconds,
            totalThumbnails:
                typeof duration === 'number'
                    ? indexOfSeconds(duration)
                    : totalThumbnails,
            ...props,
        }))
    );
};

/**
 * Given SD and HD BIF URLs, generate a stream of BIF indexes. The first event contains the SD thumbnail index
 * and the second contains the SD thumbnail index. Each BIF index will be delayed to limit bandwidth contention
 * at the start of the associated video. If <code>duration</code> is specified, the total number of thumbnails
 * will be capped accordingly.
 *
 * @param options         - see below
 * @param sd              - the SD thumbnail BIF's URL
 * @param hd              - the HD thumbnail BIF's URL
 * @param duration        - an optional duration to cap <code>totalThumbnails</code>
 * @param sdDelayMs  - Default is 1000, an optional number of milliseconds to delay loading SD thumbnails
 * @param hdDelayMs - Default is 10000, an optional number of milliseconds to delay loading HD thumbnails after loading SD thumbnails
 *
 * @returns an event stream producing BIF indexes .<BifRegistry>
 */

type ProgressiveThumbnailParams = {
    sd?: string | null;
    hd?: string | null;
    duration?: number;
    sdDelayMs?: number | null;
    hdDelayMs?: number | null;
};

export default function getProgressiveThumbnailRegistry({
    sd,
    hd,
    duration,
    sdDelayMs = SD_DELAY_MS,
    hdDelayMs = HD_DELAY_MS,
}: ProgressiveThumbnailParams): Observable<Thumbnail | never[]> {
    return concat(
        sd || hd ? EMPTY : of([]),
        createThumbnailStream({
            url: sd,
            delayMs: sdDelayMs,
            duration,
        }),
        createThumbnailStream({
            url: hd,
            delayMs: hdDelayMs,
            duration,
            retries: HD_RETRIES,
            retryLinearBackoffMs: HD_RETRY_LINEAR_BACKOFF_MS,
        })
    );
}
