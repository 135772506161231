/* eslint-disable no-invalid-this */
import type Hls from 'hls.js';
// eslint-disable-next-line no-duplicate-imports
import {type HlsListeners} from 'hls.js';
import round from 'lodash/round';

import type PlaybackHls from '.';
import NativeBuffer from '../native/buffer';
import {Events} from './types';

export default class HlsBuffer extends NativeBuffer {
    // Interval that calculates how much % we have left during stalled buffers.
    bufferPercentage: number | null = 0;
    bufferingFragments: Record<
        string,
        {
            total?: number;
            loaded?: number;
        }
    > = {};

    declare playbackHandler: Hls;
    declare playbackTech: PlaybackHls;

    constructor(playbackTech: PlaybackHls, playbackHandler: Hls) {
        super(playbackTech, playbackHandler);

        this.playbackHandler.on(
            Events.FRAG_LOADING,
            this.eventHlsFragLoadProgress
        );
    }

    override destroy(): void {
        super.destroy();

        this.playbackHandler.off(
            Events.FRAG_LOADING,
            this.eventHlsFragLoadProgress
        );
    }

    override trySetBufferStart(event: Event): boolean {
        if (super.trySetBufferStart(event)) {
            // We're starting a new buffer;
            this.bufferPercentage = null;
            this.bufferingFragments = {};

            return true;
        }

        return false;
    }

    override trySetBufferFinished(): boolean {
        if (super.trySetBufferFinished()) {
            // Always clear out our previous buffer calculations on a finish.
            this.bufferPercentage = null;
            this.bufferingFragments = {};

            return true;
        }

        return false;
    }

    eventHlsFragLoadProgress: HlsListeners[Events.FRAG_LOADING] = (
        _,
        detail
    ) => {
        // Frags will always be loading, but we only
        // want to report if we are in a stalled buffer scenario.
        if (!this.isBuffering) {
            return;
        }

        const {url} = detail.frag;
        const loaded = detail.part?.stats?.loaded;
        const total = detail.part?.stats?.total;

        this.bufferingFragments[url] = {total, loaded};

        const requestsInFlight = Object.keys(this.bufferingFragments).reduce(
            (acc, fragmentUrl) => {
                acc.loaded += this.bufferingFragments[fragmentUrl]?.loaded ?? 0;
                acc.total += this.bufferingFragments[fragmentUrl]?.total ?? 0;

                return acc;
            },
            {
                loaded: 0,
                total: 0,
            }
        );

        let bufferPercentage =
            (requestsInFlight.loaded / requestsInFlight.total) * 100;

        if (isNaN(bufferPercentage)) {
            bufferPercentage = 0;
        }

        const bufferPercentageRounded = round(bufferPercentage, 2);

        // Throw new percentage if it's different!
        if (
            this.bufferPercentage === null ||
            this.bufferPercentage < bufferPercentage
        ) {
            this.bufferPercentage = bufferPercentage;
            this.triggerBufferingPercentage(bufferPercentageRounded);
        }
    };
}
