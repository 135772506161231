import type {Thumbnail} from '@fsa-streamotion/player-state';

import range from 'lodash/range';
import React from 'react';
import styled from 'styled-components';

import VPTM03ThumbnailCarousel from '.';
import gibson from '../../../../common/font-helper';
import {formatSecondsIntoStandardLabel} from '../../../../common/format-time';
import {black, white} from '../../../../common/palette';

const TimeInfo = styled.div`
    text-align: right;
    text-shadow: 0 1px 1px rgba(${black}, 0.3);
    color: ${white};
    font: ${gibson({size: 12})};
`;

type Props = {
    thumbnailRegistry: Thumbnail;
    seekingTime?: number;
    duration?: number;
};

const ThumbnailCarousel = ({
    thumbnailRegistry,
    seekingTime = 0,
    duration = 1,
}: Props): React.ReactElement => {
    const getCarouselUrls = (
        thumbnailRegistry: Thumbnail,
        seekingTime: number
    ): (string | undefined)[] => {
        const urlIndexAtSeekPosition =
            thumbnailRegistry.indexOfSeconds(seekingTime);
        const thumbnailIndex = Math.min(
            thumbnailRegistry.totalThumbnails - 1,
            urlIndexAtSeekPosition
        );

        /* use -5, + 6 to get a range of 11 numbers allowing for an even number of thumbnails on either side.
        Main thumbnail will be the center |0,1,2,3,4,[5],6,7,8,9,10| */
        const indices = range(thumbnailIndex - 5, thumbnailIndex + 6);

        return indices.map(thumbnailRegistry.urlAtIndex);
    };
    const position = seekingTime / duration;

    return (
        <VPTM03ThumbnailCarousel
            urls={getCarouselUrls(thumbnailRegistry, seekingTime)}
            activeThumbnailUrlIndex={5}
            activeThumbnailContent={
                <TimeInfo>
                    {formatSecondsIntoStandardLabel(seekingTime)}
                </TimeInfo>
            }
            position={position}
        />
    );
};

ThumbnailCarousel.displayName = 'ThumbnailCarousel';

export default ThumbnailCarousel;
