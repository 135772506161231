import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';
import RatioAsset from '@fsa-streamotion/streamotion-web-fs-ui/src/components/ratio-asset/ratio-asset';

import VideoErrorScreen from './video-error-screen';

export default class HawkPlayer extends React.PureComponent {
    renderErrorScreen() {
        if (!this.props.videoError) {
            return null;
        }

        return (
            <VideoErrorScreen {...this.props.videoError} redirectUrl={this.props.redirectUrl} sportDetails={this.props.sportDetails} />
        );
    }

    renderLoadingScreen() {
        if (!this.props.isLoadingPlayUrl) {
            return null;
        }

        return (
            <LoadingSpinner />
        );
    }

    renderPosterSplash() {
        // Only show the poster over video player when we have one AND loading or in error.
        // If not in error and/or not loading, despite having a poster, we should bugger off.
        // Means the player is trying to show off.
        if (this.props.poster && (this.props.isLoadingPlayUrl || this.props.videoError)) {
            return (
                <img
                    className="fiso-hawk-hawk-player__poster"
                    src={this.props.poster}
                    alt={this.props.title} />
            );
        } else {
            return null;
        }
    }

    render() {
        const classes = classnames(
            'fsui-normalise fiso-hawk-hawk-player',
            {'fiso-hawk-hawk-player--loading-or-error': (this.props.isLoadingPlayUrl || this.props.videoError)}
        );

        return (
            <div className={classes}>
                <RatioAsset ratio="16x9">
                    {this.renderPosterSplash()}
                    {this.renderLoadingScreen()}
                    {this.props.videoPlayer}
                    {this.renderErrorScreen()}
                </RatioAsset>
            </div>
        );
    }
}

HawkPlayer.defaultProps = {
    videoError: null,
    videoPlayer: null
};

HawkPlayer.propTypes = {
    isLoadingPlayUrl: propTypes.bool,

    poster: propTypes.string,
    title: propTypes.string,

    videoError: propTypes.object,
    videoPlayer: propTypes.node,

    redirectUrl: propTypes.string,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
