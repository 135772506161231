import result from 'lodash/result';

export default function getLeagueSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'nrl-premiership':
        case '1':
            return {
                id: 1,
                label: 'NRL Premiership',
                route: 'nrl-premiership'
            };

        case 'test-matches':
        case '2':
            return {
                id: 2,
                label: 'Test Matches',
                route: 'test-matches'
            };

        case 'state-of-origin':
        case '3':
            return {
                id: 3,
                label: 'State of Origin',
                route: 'state-of-origin'
            };

        case 'city-v-country':
        case '4':
            return {
                id: 4,
                label: 'City v Country',
                route: 'city-v-country'
            };

        case 'world-cup':
        case '6':
            return {
                id: 6,
                label: 'World Cup',
                route: 'world-cup'
            };

        case 'four-nations':
        case '7':
            return {
                id: 7,
                label: 'Four Nations',
                route: 'four-nations'
            };

        case 'super-league':
        case '10':
            return {
                id: 10,
                label: 'Super League',
                route: 'super-league'
            };

        case 'Test Matches':
        case '11':
            return {
                id: 11,
                label: 'test-matches',
                route: 'Test Matches'
            };

        case 'all-stars':
        case '17':
            return {
                id: 17,
                label: 'All Stars',
                route: 'all-stars'
            };

        case 'auckland-nines':
        case '23':
            return {
                id: 23,
                label: 'Auckland Nines',
                route: 'auckland-nines'
            };

        case 'world-club-series':
        case '24':
            return {
                id: 24,
                label: 'World Club Series',
                route: 'world-club-series'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised League seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
