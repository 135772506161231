import {observer} from 'mobx-react-lite';
import React from 'react';

import IM01InputVol from '../../../component-library/molecules/im/01-input-vol';
import {useChromecastSenderContext} from '../context';

const VolumeSlider = observer(() => {
    const chromecastSenderContext = useChromecastSenderContext();
    const {isMuted, setIsMuted, setVolume, volume} =
        chromecastSenderContext?.values ?? {};

    return (
        <IM01InputVol
            volume={volume}
            setVolumeFunction={setVolume}
            setMutedFunction={setIsMuted}
            isMuted={isMuted}
        />
    );
});

export default VolumeSlider;
