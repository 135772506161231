export default class HlsJsTech {
  getTech(adapter) {
    var tech = adapter.getTech();
    return tech.vhs || tech.hls || tech.hls_ || tech.hlsplayer;
  }

  getRendition(adapter) {
    var ret = null;
    var currentLevel = this.getTech(adapter).currentLevel;
    if (typeof currentLevel !== 'undefined' && currentLevel !== -1 && !!this.getTech(adapter).levels) {
      var level = this.getTech(adapter).levels[currentLevel];
      if (level) {
        ret = this.getNpawUtils().buildRenditionString(level.width, level.height, level.bitrate);
      }
    }
    return ret;
  }

  isUsed(adapter) {
    return !!this.getTech(adapter);
  }

  getHls(adapter) {
    return this.getTech(adapter);
  }

  getResource(adapter) {
    var ret = null;
    if (this.getTech(adapter) && this.getTech(adapter).url) {
      ret = this.getTech(adapter).url;
    } else if (this.getHls(adapter) && this.getHls(adapter).source_) {
      ret = this.getHls(adapter).source_.src;
    }
    return ret;
  }

  getBitrate(adapter) {
    var ret = null;
    var currentLevel = this.getTech(adapter).currentLevel;
    if (typeof currentLevel !== 'undefined' && currentLevel !== -1 && !!this.getTech(adapter).levels) {
      var level = this.getTech(adapter).levels[currentLevel];
      if (level && level.bitrate) {
        ret = level.bitrate;
      }
    }
    return ret;
  }
}