import React from 'react';
import propTypes from 'prop-types';

import ImageFallback from '@fsa-streamotion/streamotion-web-fs-ui/src/components/image-fallback/image-fallback';

const TeamStartCard = ({
    homeTeam = {},
    awayTeam = {}
}) => (
    <div className="fiso-hawk-team-start-card">
        <figure className="fiso-hawk-team-start-card__figure">
            <ImageFallback fallbackSrc={homeTeam.fallbackImageSource}>
                <img
                    alt={homeTeam.name + ' logo'}
                    className="fiso-hawk-team-start-card__image"
                    src={homeTeam.imageSource}  />
            </ImageFallback>
            <ImageFallback fallbackSrc={awayTeam.fallbackImageSource}>
                <img
                    alt={awayTeam.name + ' logo'}
                    className="fiso-hawk-team-start-card__image fiso-hawk-team-start-card__image--away"
                    src={awayTeam.imageSource}  />
            </ImageFallback>
            <figcaption className="fiso-hawk-team-start-card__figure-caption">
                {homeTeam.name} Vs {awayTeam.name}
            </figcaption>
        </figure>
    </div>
);

TeamStartCard.propTypes = {
    homeTeam: propTypes.shape({
        name:  propTypes.string,
        imageSource: propTypes.string,
        fallbackImageSource: propTypes.string
    }),
    awayTeam: propTypes.shape({
        name:  propTypes.string,
        imageSource: propTypes.string,
        fallbackImageSource: propTypes.string
    })
};

export default TeamStartCard;
