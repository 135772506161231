import superagent from 'superagent';
import merge from 'lodash/merge';

import {streamFromApi, getVimondHeaders} from '../../utils/api';
import authenticated from '../authenticated';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function ({
    order,
    payment,
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/order`;
    const orderWithPayment = {
        order: {
            id: order.id,
            userId: order.userId,
            productPaymentId: order.productPaymentId,
            referrer: order.referrer,
            payment: merge({endUserIp: order.ip}, payment)
        }
    };

    return authenticated().flatMapLatest((authToken) => {
        const request = superagent
            .put(resourceUrl)
            .set(getVimondHeaders({authToken, sport}))
            .send(orderWithPayment);

        return streamFromApi(request).map('.body');
    });
}
