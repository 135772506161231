import noop from 'lodash/noop';
import {rgba} from 'polished';
import React, {useEffect} from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {white, slate, black, coal} from '../../../../common/palette';

const KEY_MOMENT_SHADOW = `0 2px 4px 0 ${rgba(black, 0.29)}`;

const KeyMomentContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 37px;
    pointer-events: none;
`;

const Stem = styled.div`
    box-shadow: ${KEY_MOMENT_SHADOW};
    background-color: ${slate};
    width: 2px;
    height: 10px;
`;

const Thumb = styled.div`
    border-radius: 50%;
    box-shadow: ${KEY_MOMENT_SHADOW};
    background-color: ${slate};
    width: 9px;
    height: 9px;
`;

const Head = styled.button`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('width', 'height')};
    outline: none;
    border: none;
    border-radius: 2px;
    box-shadow: ${KEY_MOMENT_SHADOW};
    background-color: ${slate};
    padding: 0 8px;
    width: 100%;
    height: 24px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: ${coal};
    font: ${gibson({size: 14})};
    font-weight: 600;
    pointer-events: all;

    &:active,
    &:focus,
    &:hover {
        background-color: ${white};
        width: calc(100% + 4px);
        height: 26px;

        ~ ${Thumb} {
            background-color: ${white};
        }

        ~ ${Stem} {
            transition: ${transition('height')};
            background-color: ${white};
            height: 12px;
        }
    }
`;

export type Props = {
    abbrText?: string;
    title?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
    onFocus?: React.FocusEventHandler<HTMLButtonElement>;
    onBlur?: React.FocusEventHandler<HTMLButtonElement>;
    onUnmount?: () => void;
};

const GA10KeyMoment = ({
    abbrText,
    title = '',
    onClick = noop,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onUnmount,
}: Props): React.ReactElement => {
    useEffect(() => () => onUnmount && onUnmount(), []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <KeyMomentContainer>
            <Head
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onFocus}
                onBlur={onBlur}
            >
                <abbr style={{textDecoration: 'none'}} title={title}>
                    {abbrText}
                </abbr>
            </Head>
            <Stem />
            <Thumb />
        </KeyMomentContainer>
    );
};

GA10KeyMoment.displayName = 'GA10KeyMoment';

export default GA10KeyMoment;
