import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

export default class Toggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false
        };
    }

    handleChange() {
        this.setState({isChecked: !this.state.isChecked});
        this.props.onToggleClick(this.state.isChecked);
    }

    render() {
        return (
            <div className="fiso-hawk-toggle">
                <span className="fiso-hawk-toggle__text">
                    {this.state.isChecked ? this.props.toggleOnLabel : this.props.toggleOffLabel}
                </span>
                <label className="fiso-hawk-toggle__switch">
                    <input
                        type="checkbox"
                        className="fiso-hawk-toggle__checkbox"
                        checked={this.state.isChecked}
                        onChange={this.handleChange.bind(this)} />
                    <div className="fiso-hawk-toggle__slider" />
                </label>
            </div>
        );
    }
}

Toggle.defaultProps = {
    onToggleClick: noop,
    toggleOnLabel: '',
    toggleOffLabel: ''
};

Toggle.propTypes = {
    onToggleClick: propTypes.func,
    toggleOnLabel: propTypes.string,
    toggleOffLabel: propTypes.string
};
