import classnames from 'classnames';
import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import {white} from '../../../../common/palette';
import {
    SCREEN_667_PHABLET_LANDSCAPE,
    SCREEN_768_TABLET,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    useEffectiveViewport,
} from '../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../common/theme-helpers';

const LowerControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: ${transition('transform')};
    width: 100%;
`;

// reused by marker buttons
export const ScrubberAndButtonRowContainer = styled.div`
    position: relative;
    margin: 0 14px 14px;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 40px 21px;
    `}

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 0 70px 70px;
    `}
`;

const ButtonRow = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin-top: 6px;
    `}

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 0 10px;
        margin-top: 21px;
    `}
`;

const ButtonRowLayoutItem = styled.div`
    display: flex;
    align-items: center;
    gap: 21px;
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        gap: 28px;
    `}
`;

const Divider = styled.div`
    margin: 0 6px;
    border-right: 1px solid ${rgba(white, 0.25)};
    width: 0;
    height: 24px;
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        height: 28px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 48px;
        border-right-width: 2px;
    `}
`;

const DisplayTimeContainer = styled.div`
    min-width: 129px;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        /* Avoids awkward asymmetrical margins on mobile landscape size */
        margin-left: 7px;
    `}

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        min-width: 180px;
    `}
`;

const MiddleButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        flex-grow: 0.5;
    `}
`;

const ThumbnailWrapper = styled.div`
    position: absolute;
    bottom: 100%;
    margin-bottom: 31px;
    width: 100%;
`;

export type Props = {
    /** Skip back button (eg. go back 15 seconds) */
    skipBackButton?: React.ReactNode;
    /** Skip forward button (eg. foward 15 seconds) */
    skipForwardButton?: React.ReactNode;
    /** Play button */
    playButton?: React.ReactNode;
    /** Speed button */
    playbackSpeedButton?: React.ReactNode;
    /** Go to live button */
    goToLiveButton?: React.ReactNode;
    /** Volume slider element */
    volumeSlider?: React.ReactNode;
    /** Display time element */
    displayTime?: React.ReactNode;
    /** Full screen button */
    fullscreenButton?: React.ReactNode;
    /** Scrubber bar element */
    scrubber?: React.ReactNode;
    /** Pop out button */
    popOutPlayerButton?: React.ReactNode;
    /** Related video button */
    relatedVideosButton?: React.ReactNode;
    /** Play next one button */
    nextButton?: React.ReactNode;
    /** Key Moments button */
    toggleKeyMomentsButton?: React.ReactNode;
    /** Thumbnail element to display when hovering on scrubber */
    hoverThumbnail?: React.ReactNode;
    /** Thumbnail carousel to display when dragging on scrubber */
    thumbnailCarousel?: React.ReactNode;
    /** Whether lower control is visible */
    isVisible?: boolean;
    /** Whether in ad break */
    isInAdBreak?: boolean;
    /** Function to handle keyboard inputs */
    onKeyDown?: React.KeyboardEventHandler;
    /** Pass-through CSS class name */
    className?: string;
    /** Distance in CSS units to move these controls down when not visible (defaults to 100% of height) */
    moveDownBy?: string | number;
};

const OR01LowerControls = ({
    skipBackButton,
    skipForwardButton,
    playButton,
    playbackSpeedButton,
    goToLiveButton,
    volumeSlider,
    displayTime,
    fullscreenButton,
    scrubber,
    popOutPlayerButton,
    nextButton,
    relatedVideosButton,
    hoverThumbnail,
    thumbnailCarousel,
    toggleKeyMomentsButton,
    isVisible,
    isInAdBreak,
    onKeyDown,
    className,
    moveDownBy,
}: Props): React.ReactElement => {
    const isTabletUp = useEffectiveViewport(SCREEN_768_TABLET);
    const isDesktopUp = useEffectiveViewport(SCREEN_1280_DESKTOP);
    const isPhabletLandscapeUp = useEffectiveViewport(
        SCREEN_667_PHABLET_LANDSCAPE
    );

    return isInAdBreak ? (
        <LowerControlsContainer
            className={classnames(className, 'OR01LowerControls')}
            onKeyDown={onKeyDown}
        >
            <ScrubberAndButtonRowContainer>
                {isVisible && scrubber}
                <ButtonRow>
                    <ButtonRowLayoutItem>
                        {isDesktopUp && (
                            <React.Fragment>
                                {isVisible && playButton}
                                {isVisible && <Divider />}
                            </React.Fragment>
                        )}
                        {isDesktopUp && (
                            <React.Fragment>
                                {isVisible && volumeSlider}
                                {isVisible && <Divider />}
                            </React.Fragment>
                        )}
                        <DisplayTimeContainer>
                            {displayTime}
                        </DisplayTimeContainer>
                    </ButtonRowLayoutItem>
                    <ButtonRowLayoutItem>
                        {isVisible && popOutPlayerButton}
                        {isVisible && fullscreenButton}
                    </ButtonRowLayoutItem>
                </ButtonRow>
            </ScrubberAndButtonRowContainer>
        </LowerControlsContainer>
    ) : (
        <LowerControlsContainer
            className={classnames(className, 'OR01LowerControls')}
            style={
                isVisible
                    ? undefined
                    : {transform: `translateY(${moveDownBy ?? '100%'})`}
            }
            onKeyDown={onKeyDown}
        >
            <ScrubberAndButtonRowContainer>
                {!!thumbnailCarousel && (
                    <ThumbnailWrapper>{thumbnailCarousel}</ThumbnailWrapper>
                )}
                {hoverThumbnail}
                {scrubber}
                <ButtonRow>
                    <ButtonRowLayoutItem>
                        {isDesktopUp && (
                            <React.Fragment>
                                {skipBackButton}
                                {playButton}
                                {skipForwardButton}
                            </React.Fragment>
                        )}
                        {isTabletUp && playbackSpeedButton}
                        {goToLiveButton}
                        {!!goToLiveButton && <Divider />}
                        {isTabletUp && (
                            <React.Fragment>
                                {volumeSlider}
                                <Divider />
                            </React.Fragment>
                        )}
                        <DisplayTimeContainer>
                            {displayTime}
                        </DisplayTimeContainer>
                    </ButtonRowLayoutItem>
                    {isPhabletLandscapeUp && toggleKeyMomentsButton && (
                        <MiddleButtonsWrapper isDesktopUp={isDesktopUp}>
                            {isTabletUp && relatedVideosButton}
                            <React.Fragment>
                                {isDesktopUp && <Divider />}
                                {toggleKeyMomentsButton}
                            </React.Fragment>
                        </MiddleButtonsWrapper>
                    )}

                    <ButtonRowLayoutItem>
                        {!toggleKeyMomentsButton && relatedVideosButton}

                        {nextButton}
                        {isTabletUp && <Divider />}

                        {popOutPlayerButton}

                        {fullscreenButton}
                    </ButtonRowLayoutItem>
                </ButtonRow>
            </ScrubberAndButtonRowContainer>
        </LowerControlsContainer>
    );
};

OR01LowerControls.displayName = 'OR01LowerControls';

export default OR01LowerControls;
