import type {
    CurrentHoverPosition,
    Thumbnail,
} from '@fsa-streamotion/player-state';

import React from 'react';
import styled from 'styled-components';

import VPTM02HoverThumbnail from '.';
import gibson from '../../../../common/font-helper';
import {formatSecondsIntoStandardLabel} from '../../../../common/format-time';
import {black, white} from '../../../../common/palette';

const HoverThumbnailContainer = styled.div`
    transform: translate(-50%, -100%);
    width: 215px;
`;

const TimeInfo = styled.div`
    text-align: right;
    text-shadow: 0 1px 1px rgba(${black}, 0.3);
    color: ${white};
    font: ${gibson({size: 12})};
`;

type Props = {
    thumbnailRegistry: Thumbnail;
    currentHoverPosition: CurrentHoverPosition;
    duration: number;
};

const HoverThumbnail = ({
    thumbnailRegistry,
    currentHoverPosition,
    duration,
}: Props): React.ReactElement => {
    const getHoverThumbnailUrl = (
        thumbnailRegistry: Thumbnail,
        currentHoverPosition: CurrentHoverPosition
    ): string | undefined => {
        const urlIndexOfCurrentTime = thumbnailRegistry.indexOfSeconds(
            currentHoverPosition.hoverTime
        );
        const thumbnailIndex = Math.min(
            thumbnailRegistry.totalThumbnails - 1,
            urlIndexOfCurrentTime
        );

        return thumbnailRegistry.urlAtIndex(thumbnailIndex);
    };

    const percentage = (currentHoverPosition.hoverTime / duration) * 100;

    return (
        <div
            style={{
                transform: `translateX(${percentage}%)`,
                height: 0,
            }}
        >
            <HoverThumbnailContainer>
                <VPTM02HoverThumbnail
                    src={getHoverThumbnailUrl(
                        thumbnailRegistry,
                        currentHoverPosition
                    )}
                    shouldShowArrow={true}
                    content={
                        <TimeInfo>
                            {formatSecondsIntoStandardLabel(
                                currentHoverPosition.hoverTime
                            )}
                        </TimeInfo>
                    }
                    isVisible={!!currentHoverPosition.isHovering}
                />
            </HoverThumbnailContainer>
        </div>
    );
};

HoverThumbnail.displayName = 'HoverThumbnail';

export default HoverThumbnail;
