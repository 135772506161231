/**
 * See https://confluence.foxsports.com.au/confluence/display/tech/Stats+API+-+Match+Status
 *
 * @param  {String} matchStatus Match status code
 *
 * @return {String}             Status normalised to pre, post or live
 */
export function getMatchStatus(matchStatus) {
    switch (matchStatus) {
        case 'Bye':         // tennis
        case 'Delayed':     // baseball, gridiron, tennis, golf
        case 'Postponed':   // baseball, football, gridiron, tennis
        case 'Pre Game':    // afl, basketball, cricket, football, gridiron, league, rugby, tennis
        case 'Pre Match':   // gridiron, icehockey
        case 'Pre Meeting': // motor
        case 'Pre Race':    // motor
        case 'Pre Tourney': // golf
        case 'Pre-Game':    // baseball, gridiron
        case 'Pre-Tourney': // golf
        case 'WaitStart':   // motor
            return 'pre';

        case 'Cancelled':                  // baseball, football, golf
        case 'Checkered':                  // motor
        case 'Complete':                   // cricket, tennis
        case 'Ended':                      // ?
        case 'Final - (After Postponed)':  // tennis
        case 'Final - (After Suspension)': // tennis
        case 'Final':                      // baseball, golf, gridiron
        case 'Full Time':                  // afl, basketball, football, gridiron, league, rugby
        case 'Invalid':                    // cricket
        case 'Not Required':               // football
            return 'post';

        default: // There are a lot of match statuses for live matches. Rather than list them all here, assume that any match that isn't a known pre or post is live
            return 'live';
    }
}

export function isPre(matchStatus) {
    return getMatchStatus(matchStatus) === 'pre';
}

export function isLive(matchStatus) {
    return getMatchStatus(matchStatus) === 'live';
}

export function isPost(matchStatus) {
    return getMatchStatus(matchStatus) === 'post';
}

/**
 * Test if the given match status is a known "break" match status
 * "Break" indicates a live match that is currently paused, e.g. during a tea break, rain delay or at stumps
 *
 * @param  {String}  matchStatus Match status as returned by stats API
 * @return {Boolean}             True if match status matched a known break status. Otherwise false
 */
export function isInBreak(matchStatus) {
    return [
        '1/4 Time',                 // gridiron
        '3 Qtr Time',               // AFL
        '3 Qtr Time',               // basketball
        '3/4 Time',                 // gridiron
        'Dinner Break',             // cricket
        'End of OT',                // basketball
        'End of the 10th Overtime', // gridiron
        'End of the 1st Overtime',  // gridiron
        'End of the 2nd Overtime',  // gridiron
        'End of the 3rd Overtime',  // gridiron
        'End of the 4th Overtime',  // gridiron
        'End of the 4th QTR',       // gridiron
        'End of the 5th Overtime',  // gridiron
        'End of the 6th Overtime',  // gridiron
        'End of the 7th Overtime',  // gridiron
        'End of the 8th Overtime',  // gridiron
        'End of the 9th Overtime',  // gridiron
        'Extra Time FT',            // football
        'Extra Time FT',            // league
        'Extra Time FT',            // rugby
        'Extra Time Half Time',     // AFL
        'Extra Time Half Time',     // football
        'Half Time',                // AFL, basketball, football, gridiron, league, rugby
        'Innings Break',            // cricket
        'Lunch Break',              // cricket
        'Play Suspended',           // tennis
        'Qtr Time',                 // AFL, basketball
        'Shoot Out FT',             // football
        'Stumps',                   // cricket
        'Suspended',                // baseball, golf
        'Tea Break'                 // cricket
    ].indexOf(matchStatus) > -1;
}
