/* eslint-disable no-param-reassign */

import find from 'lodash/find';
import reject from 'lodash/reject';
import tweenjs from '@tweenjs/tween.js';

let _window; // eslint-disable-line no-underscore-dangle
let frameRate = 30;

// @forceAnimationSpeed
let forceAnimationSpeed = false;

// @scrollLeft
let scrollLeftAnimations = []; // element, timer, start, adjustment

// @scrollTop
let scrollTopAnimations = []; // element, timer, start, adjustment

if (process && process.browser) {
    _window = window;
} else {
    _window = {};
}

/**
 * Used for tests. Overrides animation length
 * for all animations. All functions should check this speed.
 * @param  {mixed} animationSpeed Speed to run all animations for, or false to disable.
 * @return {void}
 */
exports.forceAnimationSpeed = function (animationSpeed) {
    forceAnimationSpeed = animationSpeed;
};

/**
 * Get the speed of an animation.  Will check for forcedAnimationSpeed.
 * @param  {integer} animationLength Requested speed of an animation
 * @return {integer}                 Speed to use for animation.
 */
exports.getAnimationLength = function (animationLength) {
    if (forceAnimationSpeed !== false) { // eslint-disable-line no-negated-condition
        return forceAnimationSpeed;
    } else {
        return animationLength;
    }
};

/**
 * Adjust scrollTop of an element over a period of time.
 * Duplicate calls on currently animated elements will simply adjust
 * the previous animation.
 * @param  {DOMNode} element          Element to adjust Scroll
 * @param  {integer} scrollAdjustment Amount to adjust by (in px, no suffix)
 * @param  {integer} animationLength  Time to animate for
 * @return {Function}                 Function to cancel animation
 */
exports.scrollTop = function (element, scrollAdjustment, animationLength) {
    let currentElement = find(scrollTopAnimations, {element});
    let scrollTopAnimation;

    if (currentElement) {
        // Already running animation on this element.
        // Cancel animation, add remainder to next adjustment.
        currentElement.cancel();
    }

    // Not running
    let tween = new tweenjs.Tween({scrollTop: element.scrollTop})
        .to({scrollTop: element.scrollTop + scrollAdjustment}, animationLength)
        .easing(tweenjs.Easing.Cubic.Out)
        .onUpdate(function () {
            element.scrollTop = this.scrollTop; // eslint-disable-line no-invalid-this
        })
        .onComplete(function () {
            scrollTopAnimation.cancel();
        })
        .start();

    scrollTopAnimation = {
        timeout: null,
        element,
        tween,
        cancel: function () { // eslint-disable-line object-shorthand
            exports.cancelRequestAnimationFrame.call(_window, scrollTopAnimation.timeout);
            scrollTopAnimation.tween.stop();
            scrollTopAnimations = reject(scrollTopAnimations, scrollTopAnimation);
        }
    };

    let animate = function (time) {
        scrollTopAnimation.timeout = exports.requestAnimationFrame.call(_window, animate, element);
        tweenjs.update(time);
    };

    scrollTopAnimations.push(scrollTopAnimation);
    animate();

    return scrollTopAnimation;
};

/**
 * Adjust scrollLeft of an element over a period of time.
 * Duplicate calls on currently animated elements will simply adjust
 * the previous animation.
 * @param  {DOMNode} element          Element to adjust Scroll
 * @param  {integer} scrollAdjustment Amount to adjust by (in px, no suffix)
 * @param  {integer} animationLength  Time to animate for
 * @return {Function}                 Function to cancel animation
 */
exports.scrollLeft = function (element, scrollAdjustment, animationLength) {
    let currentElement = find(scrollLeftAnimations, {element});
    let scrollLeftAnimation;

    animationLength = exports.getAnimationLength(animationLength || 250);

    if (currentElement) {
        // Already running animation on this element.
        // Cancel animation, add remainder to next adjustment.
        currentElement.cancel();
    }

    // Not running
    let tween = new tweenjs.Tween({scrollLeft: element.scrollLeft})
        .to({scrollLeft: element.scrollLeft + scrollAdjustment}, animationLength)
        .easing(tweenjs.Easing.Cubic.Out)
        .onUpdate(function () {
            element.scrollLeft = this.scrollLeft; // eslint-disable-line no-invalid-this
        })
        .onComplete(function () {
            scrollLeftAnimation.cancel();
        })
        .start();

    scrollLeftAnimation = {
        timeout: null,
        element,
        tween,
        cancel: function () { // eslint-disable-line object-shorthand
            exports.cancelRequestAnimationFrame.call(_window, scrollLeftAnimation.timeout);
            scrollLeftAnimation.tween.stop();
            scrollLeftAnimations = reject(scrollLeftAnimations, scrollLeftAnimation);
        }
    };

    let animate = function (time) {
        scrollLeftAnimation.timeout = exports.requestAnimationFrame.call(_window, animate, element);
        tweenjs.update(time);
    };

    scrollLeftAnimations.push(scrollLeftAnimation);
    animate();

    return scrollLeftAnimation;
};

/**
 * The following have been taken/adapted from
 * http://notes.jetienne.com/2011/05/18/cancelRequestAnimFrame-for-paul-irish-requestAnimFrame.html
 * which was an adaptation of paul irish's request animation - which funked the window object
 * and I don't like that >_>
 */

/**
 * Get a request Animation Frame which falls back to setTimeout.
 * @return {Function}
 */
exports.requestAnimationFrame = (function () {
    return _window.requestAnimationFrame    ||
        _window.webkitRequestAnimationFrame ||
        _window.mozRequestAnimationFrame    ||
        _window.oRequestAnimationFrame      ||
        _window.msRequestAnimationFrame     ||
        function (callback /* , DOMElement */) {
            return setTimeout(callback, 1000 / frameRate);
        };
})();

/**
 * Get a Cancel Request Animation Frame which falls back to clearTimeout.
 * @return {Function}
 */
exports.cancelRequestAnimationFrame = (function () {
    return _window.cancelAnimationFrame           ||
        _window.webkitCancelRequestAnimationFrame ||
        _window.mozCancelRequestAnimationFrame    ||
        _window.oCancelRequestAnimationFrame      ||
        _window.msCancelRequestAnimationFrame     ||
        clearTimeout;
})();
