import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import gibson from '../../../../common/font-helper';
import {Figure} from '../../../../common/normalized-styled-components';
import {white, black} from '../../../../common/palette';
import {
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';
import Arrow from './arrow';
import ThumbnailImage from './image';

const StyledArrow = styled(Arrow)`
    border-top-color: ${({theme}) => theme.brandColor || white};
`;

const Container = styled(Figure)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
`;

const Thumbnail = styled.div`
    position: relative;
    border-bottom: 2px solid ${({theme}) => theme.brandColor || white};

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        border-bottom-width: 3px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        border-bottom-width: 4px;
    `}
`;

const Content = styled.div`
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column-reverse;
    background-image: linear-gradient(
        to top,
        ${rgba(black, 0.9)},
        transparent 28px
    );
    padding: 5px 7px;
    width: 100%;
    height: 100%;
    text-align: right;
    color: ${white};
    font: ${gibson.medium({size: 12})};

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        background-image: linear-gradient(to top, ${rgba(
            black,
            0.9
        )}, transparent 35px);
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        background-image: linear-gradient(to top, ${rgba(
            black,
            0.9
        )}, transparent 42px);
        padding: 10px 14px;
        font-size: 18px;
    `}
`;

export type Props = {
    /** URL to load the thumbnail image from */
    src?: string;
    /** Content to display over the thumbnail. Typically, a timestamp. */
    content?: React.ReactNode;
    /** Whether to show an arrow at the bottom of the thumbnail */
    shouldShowArrow?: boolean;
};

const HoverThumbnailView = ({
    src = EMPTY_IMAGE_SRC,
    content,
    shouldShowArrow = true,
}: Props): React.ReactElement => (
    <Container>
        <Thumbnail>
            <ThumbnailImage src={src} />
            {!!content && <Content>{content}</Content>}
        </Thumbnail>
        {!!shouldShowArrow && <StyledArrow />}
    </Container>
);

HoverThumbnailView.displayName = 'HoverThumbnailView';

export default HoverThumbnailView;
