import bacon from 'baconjs';

import first from 'lodash/first';
import get from 'lodash/get';
import merge from 'lodash/merge';
import groupBy from 'lodash/groupBy';

import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import login from '../../authentication/streams/login';
import userSetLocalStorage from '../../streams/user/user-set-local-storage';
import getUserOrders$ from '../../streams/user/user-get-orders';
import postUser from '../../streams/user/user-post';

import {getLoginRedirectUrl} from '../../utils/location';
import {TEAM_ID_NAME_MAP, USER_LOCAL_STORAGE_KEY, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function getUserFormData({
    dataCollectionUrl,
    isEnabled,
    aflPrivacyUrl,
    nrlPrivacyUrl,
    privacyUrl,
    sport = 'afl',
    teamDetail,
    termsUrl,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const createUserBus = new bacon.Bus();

    const localUserDetails$ = bacon.later(0, getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY}));
    const activeOrder$ = localUserDetails$
        .flatMapLatest((userDetails) => {
            const userId = get(userDetails, 'id');

            if (userId) {
                return getUserOrders$({
                    sport,
                    userId,
                    vimondEnv
                })
                    .map((activeOrders) => {
                        // I "think" looking for '.active' in the response is how the middleware used to do responses
                        // However in my current tests, it's an array, where status is ACTIVE.
                        //
                        // Due to 3rd party and I don't know what's truely what, I'll keep the previous logic
                        // as well as the new apiRest logic as I see now, to determine if we're active.
                        //
                        // We just need to return something 'truey' in order for the redirect to happen.
                        // Otherwise we attempt to resubscribe users.
                        const middleWareActiveOrder = first(get(activeOrders, 'active'));
                        const apiRestActiveOrder = get(activeOrders, '[0].status') === 'ACTIVE';

                        return middleWareActiveOrder || apiRestActiveOrder;
                    });
            } else {
                return false;
            }
        });

    const existingUser$ = localUserDetails$
        .combine(activeOrder$, (localUserDetails, activeOrder) => {
            if (localUserDetails && (!activeOrder || !!teamDetail)) {
                return localUserDetails;
            } else {
                return null;
            }
        });

    const userCreated$ = createUserBus
        .flatMapLatest((userDetails) => postUser({sport, user: userDetails, vimondEnv}))
        .combine((createUserBus), merge); // Ensure we merge our original bus details with the response from ;

    const userCreatedErrors$ = userCreated$
        .errors()
        .mapError(({response}) => {
            const errors = get(response, 'body.errors', []);
            const groupedErrors = groupBy(errors, 'code');

            return {
                firstName: get(groupedErrors, 'USER_INVALID_FIRSTNAME', []).map((error) => error.description),
                lastName: get(groupedErrors, 'USER_INVALID_LASTNAME', []).map((error) => error.description),
                mobile: get(groupedErrors, 'USER_INVALID_MOBILENUMBER', []).map((error) => error.description),
                email: get(groupedErrors, 'USER_INVALID_EMAIL', []).map((error) => error.description),
                password: get(groupedErrors, 'USER_INVALID_PASSWORD', []).map((error) => error.description)
            };
        });

    const loginCreatedUser$ = userCreated$
        .flatMapLatest((userDetails) => login({
            username: userDetails.userName,
            password: userDetails.password,
            sport,
            vimondEnv
        })
            .map(userDetails) // Force back to what this stream had, not the response of vimond on create
            .doAction((userDetails) => {
                if (userDetails) {
                    userSetLocalStorage(userDetails);
                }
            }));

    const userToSubmit = bacon.mergeAll(
        loginCreatedUser$,
        existingUser$
    );

    const isLockedOff$ = bacon.mergeAll(
        isEnabled.map((isEnabled) => !isEnabled), // from main widget, provides initial value, toggles between true and false
        loginCreatedUser$.map(true)          // lock user if we create and login a user.
    )
        // If we have an active user, always be locked off regardless of isEnabled and loginCreatedUser
        .combine(existingUser$, (isLockedOff, existingUser) => !!existingUser || isLockedOff);

    const formErrors$ = bacon.mergeAll(
        createUserBus.map({}), // clear errors on load.
        userCreatedErrors$
    ).toProperty({});

    const isLoading$ = bacon.mergeAll(
        createUserBus.map(true),
        loginCreatedUser$.mapError(false).map(false)
    ).startWith(false);

    return bacon.combineTemplate({
        isLockedOff: isLockedOff$,
        isLoading: isLoading$,
        dataCollectionUrl,
        termsUrl,
        aflPrivacyUrl,
        nrlPrivacyUrl,
        privacyUrl,

        teams: TEAM_ID_NAME_MAP[sport],
        favouriteTeamId: get(teamDetail, 'id'),
        existingUser: existingUser$,

        formErrors: formErrors$,

        onClickSubmit: (userDetails) => { createUserBus.push(userDetails); },

        redirectUrl: getLoginRedirectUrl(),

        activeOrder: activeOrder$,
        user: userToSubmit.startWith(false),
        userCreated: userCreated$.startWith(null)
    });
}
