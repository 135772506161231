import {observer} from 'mobx-react-lite';
import React, {useRef, useEffect} from 'react';
import {TransitionGroup} from 'react-transition-group';
import styled from 'styled-components';

import {fadeInOut} from '../../../../../common/animations';
import CommonTransition from '../../../../../common/common-transition';
import {SCREEN_1280_DESKTOP} from '../../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../../common/theme-helpers';
import BA99Marker from '../../../../../component-library/atoms/ba/99-marker';
import {usePlayerContext} from '../../../context';

const StyleDiv = styled.div`
    display: grid;
    position: absolute;
    bottom: 0;
    grid-template-rows: 30px;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-column-gap: 7px;
    align-content: end;
    margin-bottom: 20px;
    overflow: hidden;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        grid-template-rows: 40px;
        grid-column-gap: 14px;
        margin-bottom: 32px;
    `}
`;

// needs a wrapper, to preserve the hover transition of BA99Marker
const StyledSpan = styled.span`
    display: flex;
    pointer-events: all;
    ${fadeInOut}
`;

// Simpler component to manage the space between marker buttons, and letting them fade in and out on mount/unmount
function MarkerButtonRow(): React.ReactElement {
    const {activeScreenConfig, globalState} = usePlayerContext() ?? {};
    const {actions, playbackData} = activeScreenConfig ?? {};
    const firstMarkerButton = useRef<HTMLButtonElement>(null);
    const shouldMarkerBtnBeVisible =
        !globalState?.isLowerTrayVisible && !globalState?.isUpNextMode;

    useEffect(
        function focusOnFirstWhenMarkerButtonsReappear() {
            firstMarkerButton.current?.focus(); // eslint-disable-line no-unused-expressions
        },
        [globalState?.controlsShownOnUserInteractionSeq]
    );

    return (
        <TransitionGroup component={StyleDiv}>
            {playbackData?.markers
                .map(({endTime, startTime, ...rest}) => ({
                    ...rest,
                    endTime: endTime ?? Infinity,
                }))
                .filter(
                    (_, index) =>
                        globalState?.markerButtonsVisibilityMap[index] &&
                        shouldMarkerBtnBeVisible
                )
                .map(
                    (
                        {title, endTime},
                        index // Note: we didn't use `title` in Binge. Lets try and use it here and try and avoid manually overriding for a more sustainable solution
                    ) => (
                        <CommonTransition in={true} key={title + endTime}>
                            <StyledSpan>
                                <BA99Marker
                                    ref={index === 0 ? firstMarkerButton : null}
                                    title={title}
                                    onClick={() =>
                                        void actions?.setCurrentTime(
                                            Math.floor(endTime)
                                        )
                                    }
                                >
                                    {title}
                                </BA99Marker>
                            </StyledSpan>
                        </CommonTransition>
                    )
                )}
        </TransitionGroup>
    );
}

MarkerButtonRow.displayName = 'MarkerButtonRow';

export default observer(MarkerButtonRow);
