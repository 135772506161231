import type {HudContent} from '@fsa-streamotion/player-state';

import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';

import IGM01HudContent from '../../../component-library/molecules/igm/01-hud-content';

type Props = {
    isVisible?: boolean;
    render?: HudContent['panel']['render'];
    sectionKey?: string;
};

const HudPanel = ({
    isVisible,
    render,
    sectionKey,
}: Props): React.ReactElement => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(
        function renderContent() {
            // Renders the panel content inside containerRef
            const cleanup =
                containerRef?.current && render?.(containerRef.current);

            return () => {
                if (typeof cleanup === 'function') {
                    cleanup();
                }
            };
        },
        [render]
    );

    return (
        <IGM01HudContent
            key={sectionKey}
            hidden={!isVisible}
            id={`panel-${sectionKey}`}
            aria-labelledby={`tab-${sectionKey}`}
            ref={containerRef}
        />
    );
};

HudPanel.displayName = 'HudPanel';

export default observer(HudPanel);
