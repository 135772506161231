import classnames from 'classnames';
import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import range from 'lodash/range';
import React from 'react';
import styled from 'styled-components';

import IGM28HudBtn from '../28-hud-btn';
import CommonTransition from '../../../../common/common-transition';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import {spaceKey} from '../../../../streamotion-player/web/component/utils/keydown-handler';
import IC25ArrowR from '../../../atoms/ic/25-arrow-r';

const HudContSelectWrapper = styled.div`
    width: 70px;
    height: 100%;
`;

const TabList = styled.div.attrs({
    'role': 'tablist',
    'aria-label': 'Heads Up Display',
})`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${THEME_ACCESSORS.hudSidebarBackground};
    width: 70px;
    height: 100%;
`;

const ButtonSet = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 96px;
`;

export type Props = {
    /** Optional extra class names for the component */
    className?: string;
    /** A click handler e.g. for closing the HUD */
    onCloseClick: () => void;
    /** Buttons appearing in the middle of the tab list, e.g. Synopsis, Summary */
    middleButtons?: React.ReactNode[];
    /** Buttons appearing in the bottom of the tab list, e.g. notifications*/
    lowerButtons?: React.ReactNode[];
    /** Whether or not this element is visible, affecting this element's tab accessibility */
    isInteractive?: boolean;
};

type State = {
    buttonRefs?: React.RefObject<HTMLButtonElement>[];
    focusedButtonIndex?: number | null;
};

class IGM02HudContSelect extends React.Component<Props, State> {
    static displayName = 'IGM02HudContSelect';

    static defaultProps = {
        middleButtons: [],
        lowerButtons: [],
        isInteractive: true,
    };

    override state: State = {
        focusedButtonIndex: null,
    };

    static getDerivedStateFromProps(
        props: Props,
        state: State
    ): Partial<State> | null {
        const numButtons =
            (props.middleButtons?.length || 0) +
            (props.lowerButtons?.length || 0);

        return numButtons === state.buttonRefs?.length
            ? null
            : {
                  buttonRefs: range(numButtons).map(
                      React.createRef<HTMLButtonElement>
                  ),
              };
    }

    setFocusedButtonIndex = (newIndex: number): void => {
        this.setState(() => ({focusedButtonIndex: newIndex}));
    };

    focusSpecificButton = (index: number): void => {
        this.state.buttonRefs?.[index]?.current?.focus();
    };

    focusNextButton = (): void => {
        const totalButtons =
            (this.props.middleButtons?.length || 0) +
            (this.props.lowerButtons?.length || 0);

        this.focusSpecificButton(
            (totalButtons + (this.state.focusedButtonIndex || 0) + 1) %
                totalButtons
        );
    };

    focusPrevButton = (): void => {
        const totalButtons =
            (this.props.middleButtons?.length || 0) +
            (this.props.lowerButtons?.length || 0);

        this.focusSpecificButton(
            (totalButtons + (this.state.focusedButtonIndex || 0) - 1) %
                totalButtons
        );
    };

    handleKeyDown = (e: React.KeyboardEvent): void => {
        switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowUp':
                this.focusPrevButton();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'ArrowRight':
            case 'ArrowDown':
                this.focusNextButton();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'Home':
                this.focusSpecificButton(0);
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'End':
                this.focusSpecificButton(
                    (this.props.middleButtons?.length || 0) +
                        (this.props.lowerButtons?.length || 0) -
                        1
                );
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'Escape':
                this.props.onCloseClick();
                e.preventDefault();
                e.stopPropagation();
                break;
            case spaceKey:
                noop();
                e.preventDefault();
                e.stopPropagation();
                break;
            default:
                break;
        }
    };

    override render(): React.ReactElement {
        const {
            onCloseClick,
            middleButtons,
            lowerButtons,
            className,
            isInteractive,
        } = this.props;

        const numMiddleButtons = this.props.middleButtons?.length || 0;

        return (
            <HudContSelectWrapper>
                <CommonTransition
                    in={
                        isInteractive &&
                        middleButtons &&
                        middleButtons.length > 0
                    }
                    onEntered={() =>
                        isNull(this.state.focusedButtonIndex)
                            ? this.state.buttonRefs?.[0]?.current?.focus()
                            : this.state.focusedButtonIndex &&
                              this.state.buttonRefs?.[
                                  this.state.focusedButtonIndex
                              ]?.current?.focus()
                    }
                >
                    <TabList
                        className={classnames('IGM02HudContSelect', className)}
                    >
                        <ButtonSet key="top-buttons">
                            <IGM28HudBtn
                                aria-label="Close Heads Up Display"
                                onClick={onCloseClick}
                                tabIndex={isInteractive ? 0 : -1}
                            >
                                <IC25ArrowR />
                            </IGM28HudBtn>
                        </ButtonSet>
                        <ButtonSet
                            key="middle-buttons"
                            onKeyDown={this.handleKeyDown}
                        >
                            {middleButtons &&
                                middleButtons.map(
                                    (button, index) =>
                                        React.isValidElement(button) &&
                                        React.cloneElement(
                                            button as React.ReactElement<{
                                                onFocus: React.FocusEventHandler;
                                                ref: React.RefObject<HTMLButtonElement>;
                                                tabIndex: number;
                                            }>,
                                            {
                                                onFocus: (
                                                    e: React.FocusEvent
                                                ) => {
                                                    this.setFocusedButtonIndex(
                                                        index
                                                    );
                                                    button.props.onFocus?.(e);
                                                },
                                                key: `middle-button-${index}`,
                                                ref: this.state.buttonRefs?.[
                                                    index
                                                ],
                                                tabIndex: isInteractive
                                                    ? 0
                                                    : -1,
                                            }
                                        )
                                )}
                        </ButtonSet>
                        <ButtonSet
                            key="lower-buttons"
                            onKeyDown={this.handleKeyDown}
                        >
                            {lowerButtons &&
                                lowerButtons.map(
                                    (button, index) =>
                                        React.isValidElement(button) &&
                                        React.cloneElement(
                                            button as React.ReactElement<{
                                                onFocus: React.FocusEventHandler;
                                                ref: React.RefObject<HTMLButtonElement>;
                                                tabIndex: number;
                                            }>,
                                            {
                                                onFocus: (
                                                    e: React.FocusEvent
                                                ) => {
                                                    this.setFocusedButtonIndex(
                                                        index + numMiddleButtons
                                                    );
                                                    button.props.onFocus?.(e);
                                                },
                                                key: `lower-button-${index}`,
                                                ref: this.state.buttonRefs?.[
                                                    index + numMiddleButtons
                                                ],
                                                tabIndex: isInteractive
                                                    ? 0
                                                    : -1,
                                            }
                                        )
                                )}
                        </ButtonSet>
                    </TabList>
                </CommonTransition>
            </HudContSelectWrapper>
        );
    }
}

export default IGM02HudContSelect;
