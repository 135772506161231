import {stopAll} from '@fsa-streamotion/request-manager';

import appendInfoPanel from './append-info-panel';
import rerunFisoScript from './rerun-fiso-script';

/**
 * Intended to be used on docs pages only.
 *
 * Given a widget name and a DOM element, return a curried function.
 * This returned function can be passed as a callback to a widget initIso
 * promise resolution.
 *
 * @param  {String}      widgetName      Should match the widget's bootloader name, e.g. 'mcnrl-router'
 * @param  {HTMLElement} widgetMountNode HTML element where widget is mounted. Defaults to document.getElementById('iso-boot')
 *
 * @returns {Function}                    Promise resolver which will iso boot output from a widget's initIso promise and create an info panel
 */
export default function showInitialData(widgetName, widgetMountNode = document.getElementById('iso-boot')) {
    return function onIsoInitComplete(args) {
        return new Promise((resolve) => {
            const onServerRenderComplete = function () {
                console.log('%c   ┏(-_-)┓ ┏(-_-)┓   SERVER-RENDER-COMPLETE    ┏( -_-)┛┗(-_- )┓   ', 'background: red; color: yellow;'); // eslint-disable-line no-console
                stopAll(); // reset all request manager. so client doesn't get unrealistically cached data from the server render
                rerunFisoScript();

                resolve();
            };

            widgetMountNode.innerHTML = args.html;
            const scriptData = widgetMountNode.querySelector('script').innerHTML;

            // The following aliasing of `eval` is needed because Parcel 2 silently fails to export functions which call eval directly.
            // For further information please see https://github.com/parcel-bundler/parcel/issues/7442
            // @TODO revert to direct usage after the above bug is fixed in Parcel.
            const ieval = eval; // eslint-disable-line no-eval

            ieval(scriptData);

            fetch('//--------SERVER-RENDER-COMPLETED--------/').catch(onServerRenderComplete); // eslint-disable-line compat/compat

            const [componentId] = Object.keys(window.fisoBoot[widgetName]);
            let widgetBootArgs = window.fisoBoot[widgetName][componentId];

            if (typeof widgetBootArgs === 'string') {
                widgetBootArgs = JSON.parse(widgetBootArgs);
            }

            appendInfoPanel({
                expiresIn: args.expiresIn,
                initialData: JSON.stringify(widgetBootArgs.data || widgetBootArgs.hydration, null, 2),
                markup: args.html,
                settings: JSON.stringify(widgetBootArgs.settings, null, 2),
                widgetMountNode,
            });
        });
    };
}
