import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {DEFAULT_VIMOND_ENV, PROXY_RESOURCE_URL} from '../../utils/constants';

export default function ({
    freq = 0,
    sport,
    seriesId,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const retries = (process && process.browser) ? 3 : 0;
    const url = `${PROXY_RESOURCE_URL[vimondEnv]}sports/${sport}/series/${seriesId}/currentseason.json`;

    return getApiFromRetryWithError({
        url,
        freq,
        retries
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            }
            const errorMessage = `FS-HAWK-WIDGETS: Unable to get Current Season (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
