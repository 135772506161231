import type {BaseProps} from '@fsa-streamotion/styled-component-helpers';

import {
    type CSSObject,
    type FlattenSimpleInterpolation,
    css,
    type InterpolationFunction,
    type ThemedStyledProps,
    type Interpolation,
    type FlattenInterpolation,
    type ThemeProps,
    type DefaultTheme,
} from 'styled-components';

export const TRANSITION_DURATION_S = 0.1;
export const TRANSITION_DURATION_MS = Math.round(TRANSITION_DURATION_S * 1000);
export const TRANSITION_TIMING_FUNCTION = 'ease-in';

type ALLOWED_TRANSITION_CLASSNAME =
    | 'appear'
    | 'appearActive'
    | 'enter'
    | 'enterActive'
    | 'enterDone'
    | 'exit'
    | 'exitActive'
    | 'exitDone';
export const TRANSITION_CLASSNAMES: {
    [key in ALLOWED_TRANSITION_CLASSNAME]: string;
} = {
    appear: 'appear',
    appearActive: 'appear-active',
    enter: 'enter',
    enterActive: 'enter-active',
    enterDone: 'enter-done',
    exit: 'exit',
    exitActive: 'exit-active',
    exitDone: 'exit-done',
};

/**
 * Create the value of a transition rule for one or more CSS properties, using standard
 * values for duration and timing function.
 *
 * @param props - An array of CSS properties
 * @returns The transition rule value
 */
export const transition = (...props: string[]): string =>
    props
        .map(
            (prop) =>
                `${prop} ${TRANSITION_DURATION_S}s ${TRANSITION_TIMING_FUNCTION}`
        )
        .join(', ');

export const fadeInOut: FlattenSimpleInterpolation = css`
    transition: opacity ${TRANSITION_DURATION_MS}ms linear;

    &.enter {
        opacity: 0;
    }

    &.enter-active {
        opacity: 1;
    }

    &.enter-done {
        opacity: 1;
    }

    &.exit {
        opacity: 1;
    }

    &.exit-active {
        opacity: 0;
    }

    &.exit-done {
        opacity: 0;
    }
`;

type EffectiveViewportMediaQueryReturnedType<P> = (
    cssStrings:
        | TemplateStringsArray
        | CSSObject
        | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...cssInterpolations: Array<
        Interpolation<ThemedStyledProps<P, DefaultTheme>>
    >
) => FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>> | '';

export const transformEnterExit =
    <P extends BaseProps = BaseProps>(
        cssStrings:
            | TemplateStringsArray
            | CSSObject
            | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
        ...cssInterpolations: Array<
            InterpolationFunction<ThemeProps<DefaultTheme>>
        >
    ): EffectiveViewportMediaQueryReturnedType<P> =>
    (...ruleFragmentsExited) =>
        css`
            /* The following is intentionally scattered to express overriding order */
            /* stylelint-disable csstree/validator, order/order, indentation */

            &.${TRANSITION_CLASSNAMES.enterDone} {
                ${css<P>(cssStrings, ...cssInterpolations)}
            }

            &.${TRANSITION_CLASSNAMES.exitDone} {
                ${css<P>(...ruleFragmentsExited)}
            }

            &.${TRANSITION_CLASSNAMES.exit} {
                ${css<P>(cssStrings, ...cssInterpolations)}
            }

            &.${TRANSITION_CLASSNAMES.enter},
                &.${TRANSITION_CLASSNAMES.appear} {
                ${css<P>(...ruleFragmentsExited)}
            }

            &.${TRANSITION_CLASSNAMES.exitActive} {
                transition: transform ${TRANSITION_DURATION_MS}ms linear;
                ${css<P>(...ruleFragmentsExited)}
            }

            &.${TRANSITION_CLASSNAMES.enterActive},
                &.${TRANSITION_CLASSNAMES.appearActive} {
                transition: transform ${TRANSITION_DURATION_MS}ms linear;
                ${css<P>(cssStrings, ...cssInterpolations)}
            }

            /* stylelint-enable csstree/validator, order/order, indentation */
        `;
