import React from 'react';
import propTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import {loadStyle} from '@fsa-streamotion/streamotion-web-browser-utils/src/lazy-load';
import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import HawkMatchCard from './match-card';
import SpecialEventCard from './special-event-card';

export default class LiveEvents extends React.Component {
    UNSAFE_componentWillMount() { // eslint-disable-line camelcase, react/sort-comp
        this.loadTeamLogoStylesheet(this.props.seriesList, this.props.sport);
    }

    UNSAFE_componentWillUpdate(newProps) { // eslint-disable-line camelcase
        if (!isEqual(newProps.seriesList, this.props.seriesList)) {
            this.loadTeamLogoStylesheet(newProps.seriesList, newProps.sport);
        }
    }

    loadTeamLogoStylesheet(seriesList, sport) {
        seriesList
            .forEach(function (seriesId) {
                loadStyle(`/assets/images/team-logos/${sport}/${seriesId}/208x156/${seriesId}.css`);
            });
    }

    renderCard({type, item}) {
        switch (type) {
            case 'match':
                return (
                    <HawkMatchCard isStackable={true} {...item} />
                );
            case 'event':
                return (
                    <SpecialEventCard
                        title={item.title}
                        imgSrc={`https://media.foxsports.com.au/technology/resources/fe/ott/${this.props.sport}/live-special/${item.id}.png`}
                        fallbackImgSrc={item.imageUrl}
                        onClick={() => this.props.onPlayLiveEvent(item)} />
                );
            default:
                return null;
        }
    }

    render() {
        if (isEmpty(this.props.liveEvents)) {
            return null;
        }

        return (
            <div className="fsui-normalise fiso-hawk-live-events">
                {this.props.seriesList.map((seriesId) => (
                    <link
                        key={seriesId}
                        rel="stylesheet"
                        href={`/assets/images/team-logos/${this.props.sport}/${seriesId}/208x156/${seriesId}.css`} />
                ))}
                <div className="fiso-hawk-live-events__title">
                    <Fsvg name="live-now" ariaLabel="Live" />
                    <span>
                        now
                    </span>
                </div>

                <ul className="fiso-hawk-live-events__list">
                    {this.props.liveEvents.map(({type, id, item}) => (
                        <li key={`${type}-${id}`} className="fiso-hawk-live-events__card">
                            {this.renderCard({type, item})}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

LiveEvents.defaultProps = {
    liveEvents: [],
    seriesList: [],
    onPlayLiveEvent: noop
};

LiveEvents.propTypes = {
    liveEvents: propTypes.arrayOf(propTypes.shape({
        type: propTypes.oneOf(['match', 'event']),
        id: propTypes.oneOfType([propTypes.string, propTypes.number]), // uniquely identifies item
        item: propTypes.object // details of match or event
    })),
    seriesList: propTypes.arrayOf(propTypes.number),
    sport: propTypes.string,
    onPlayLiveEvent: propTypes.func
};
