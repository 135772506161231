import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import type {AudioPreferences} from '../types';

function getAudioTrackWeightByPreferences(audioPreferences: AudioPreferences) {
    return function getAudioTrackWeight(track: AudioPreferences) {
        let weight = 0;

        if (audioPreferences.codec && track.codec === audioPreferences.codec) {
            weight -= 100;
        }

        if (
            !isEmpty(audioPreferences.audioChannelConfiguration) &&
            isEqual(
                track.audioChannelConfiguration,
                audioPreferences.audioChannelConfiguration
            )
        ) {
            weight -= 10;
        }

        if (
            audioPreferences.language &&
            track.language === audioPreferences.language
        ) {
            weight -= 1;
        }

        return weight;
    };
}

export default function sortAudioTracksByPreferences<
    T extends AudioPreferences
>(tracks: T[], audioPreferences: AudioPreferences): T[] {
    return sortBy<T>(
        tracks,
        getAudioTrackWeightByPreferences(audioPreferences)
    );
}
