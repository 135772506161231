import React from 'react';
import classnames from 'classnames';

import {getMatchStatus} from '@fsa/fs-commons/lib/utils/match-status';

import ScoreCentreClock from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/components/card/clock';

export default class OTTClock extends ScoreCentreClock {
    render() {
        const normalisedMatchStatus = getMatchStatus(this.props.match_status);
        const className = classnames(
            'fiso-sc-card-clock',
            `fiso-sc-card-clock--${normalisedMatchStatus}`
        );
        let clock;

        switch (normalisedMatchStatus) {
            // HAWK cards should display the clock for 'pre' AND 'post' according to HAWK-270
            case 'pre':
            case 'post': // that's the unique change from 'render' of 'iso-scorecentre/src/js/components/card/clock.js'
                clock = this.renderPre();
                break;

            case 'live':
                clock = this.renderLive();
                break;

            default:
                return;
        }

        return (
            <div className={className}>
                {clock}
            </div>
        );
    }
}
