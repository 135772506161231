import {DEFAULT_ERROR_PREFIX} from './constants';
import isReallyNaN from './is-really-nan';
import logError from './util/log-error';

/**
 * Normalise a number or string to a number
 * All other types will return defaultVal
 *
 * @param val         - value to normalise (e.g. string or number)
 * @param defaultVal  - default value (e.g. 0)
 * @param errorPrefix - the prefix for the error message, defaults to `Transformers`
 *
 * @returns provided value or defaultVal
 */
export default function asNumber(
    val: unknown,
    defaultVal?: number,
    errorPrefix = DEFAULT_ERROR_PREFIX
): number | undefined {
    try {
        if (typeof val === 'number') {
            return val;
        }

        if (typeof val === 'string' && !isReallyNaN(val)) {
            return parseFloat(val);
        }
    } catch (e) {
        logError(`${errorPrefix}: couldn't normalise arg asNumber`, val, e);
    }

    return defaultVal;
}
