import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';

import {usePlayerContext} from '../../context';

const UpNextContainer = styled.div`
    width: 100%;
    height: 100%;
    pointer-events: auto;

    ${stylesWhenNot('isUpNextMode')`
        display: none;
    `}
`;

const UpNext = (): React.ReactElement => {
    const playerState = usePlayerContext();
    const upNextRef = useRef<HTMLDivElement>(null);
    const {globalState, globalActions} = playerState ?? {};
    const {isUpNextMode} = globalState ?? {};
    const {pushUpNextElement, clearUpNext} = globalActions ?? {};

    useEffect(function pushUpNextElementOnMount() {
        pushUpNextElement?.(upNextRef?.current);

        return () => {
            pushUpNextElement?.(null);
            clearUpNext?.();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <UpNextContainer isUpNextMode={isUpNextMode} ref={upNextRef} />;
};

UpNext.displayName = 'UpNext';

export default observer(UpNext);
