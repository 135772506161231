export default class BcoveAdsAdapter {
  isUsed() {
    return !this.player.ima3 && this.player.ads;
  }

  getVersion() {
    return '7.0.4-videojs-bcove-ads-jsclass';
  }

  getPlayhead() {
    if (this.player.ads.ad) {
      return this.player.ads.ad.currentTime;
    }
    return undefined;
  }

  getPosition() {
    switch (this.player.ads.pod.ads_pod_index) {
      case 0:
        return this.getNpawReference().Constants.AdPosition.Preroll;
      case -1:
        return this.getNpawReference().Constants.AdPosition.Postroll;
    }
    return this.getNpawReference().Constants.AdPosition.Midroll;
  }

  getResource() {
    if (this.player.ads.currentSrc) {
      return this.player.ads.currentSrc;
    }
    return undefined;
  }

  getTitle() {
    if (this.player.ads.ad && this.player.ads.ad.title) {
      return this.player.ads.ad.title;
    }
    return undefined;
  }

  getDuration() {
    if (this.player.ads.ad && this.player.ads.ad.duration) {
      this.lastDuration = this.player.ads.ad.duration;
      return this.lastDuration;
    }
    return undefined;
  }

  getPlayerName() {
    return 'bcove-ads';
  }

  getPlayerVersion() {
    return this.player.ads.VERSION;
  }

  registerListeners() {
    this.monitorPlayhead(true, false);
    this.references = [];
    this.references['ads-ad-started'] = this.adStartListener.bind(this);
    this.references['ads-pause'] = this.adPausedListener.bind(this);
    this.references['ads-play'] = this.adResumedListener.bind(this);
    this.references['ads-ad-ended'] = this.adEndedListener.bind(this);
    this.references['ima3-skipped'] = this.adSkippedListener.bind(this);
    this.references['ima3-ad-error'] = this.errorListener.bind(this);
    this.references['adserror'] = this.errorListener.bind(this);
    this.references['ads-click'] = this.clickListener.bind(this);
    this.references['ads-first-quartile'] = this.firstQuartileListener.bind(this);
    this.references['ads-midpoint'] = this.secondQuartileListener.bind(this);
    this.references['ads-third-quartile'] = this.thirdQuartileListener.bind(this);
    for (var key in this.references) {
      this.player.on(key, this.references[key]);
    }
  }

  firstQuartileListener(e) {
    this.fireQuartile(1);
  }

  secondQuartileListener(e) {
    this.fireQuartile(2);
  }

  thirdQuartileListener(e) {
    this.fireQuartile(3);
  }

  adStartListener(e) {
    if(this.getVideo().getAdapter()) {
      this.getVideo().getAdapter().firePause();
    }
    this.fireStart();
    this.fireJoin();
  }

  adPausedListener(e) {
    this.firePause();
  }

  adResumedListener(e) {
    this.fireResume();
  }

  adEndedListener(e) {
    this.fireStop({
      adPlayhead: this.lastDuration
    });
    const videoAdapter = this.getVideo().getAdapter();
    if (videoAdapter) videoAdapter.fireResume();
  }

  adSkippedListener(e) {
    this.fireStop({
      skipped: true
    });
    const videoAdapter = this.getVideo().getAdapter();
    if (videoAdapter) videoAdapter.fireResume();
  }

  errorListener(e) {
    this.fireError('Ad error');
  }

  clickListener(e) {
    var clickurl = null;
    if (this.player.ima3 && this.player.ima3.currentAd && this.player.ima3.currentAd.g) {
      clickurl = this.player.ima3.currentAd.g.clickThroughUrl;
    }
    this.fireClick({
      'url': clickurl
    });
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.off(key, this.references[key]);
      }
      this.references = [];
    }
  }
}