import React from 'react';
import propTypes from 'prop-types';
import omit from 'lodash/omit';

export default class FormCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false
        };
    }

    toggleCheckbox() {
        this.setState({
            isChecked: !this.state.isChecked
        });

        this.props.handleCheckboxChange(this.props.name, !this.state.isChecked);
    }

    requiredOrNot() {
        return (this.props.required ? '' : ' (optional)');
    }

    render() {
        return (
            <label className="fiso-hawk-form-checkbox fiso-hawk-form-checkbox__row">
                <span
                    className="
                        fiso-hawk-form-checkbox__col
                        fiso-hawk-form-checkbox__col--input">
                    <input
                        {...omit(this.props, ['handleCheckboxChange'])}
                        type="checkbox"
                        className="fiso-hawk-form-checkbox__input"
                        checked={this.state.isChecked}
                        onChange={this.toggleCheckbox.bind(this)} />
                </span>

                <span
                    className="
                        fiso-hawk-form-checkbox__col
                        fiso-hawk-form-checkbox__col--label
                        fiso-hawk-form-checkbox__label">
                    {this.props.title}{this.requiredOrNot()}
                </span>
            </label>
        );
    }
}

FormCheckbox.propTypes = {
    handleCheckboxChange: propTypes.func,
    name: propTypes.string,
    title: propTypes.oneOfType([
        propTypes.arrayOf(
            propTypes.oneOfType([
                propTypes.element,
                propTypes.string
            ])
        ),
        propTypes.element,
        propTypes.string
    ]),
    required: propTypes.oneOfType([
        propTypes.bool,
        propTypes.string
    ])
};
