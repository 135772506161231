type Params = {
    /** Does the event bubble up through the DOM? */
    bubbles?: boolean;

    /** Can the event be cancelled? */
    cancelable?: boolean;

    /** The click count */
    detail?: unknown;
};

/**
 * Create a new CustomEvent using the constructor, falling back to an alternative method
 * on IE11/safari.
 *
 * @param type   - the event type
 * @param params - optional event params
 * @returns a new custom event
 */
export default function newCustomEvent(
    type: string,
    params?: Params
): CustomEvent {
    if (typeof CustomEvent === 'function') {
        return new CustomEvent(type, params);
    } else {
        const {bubbles, cancelable, detail} = params || {};
        const event = document.createEvent('CustomEvent');

        event.initCustomEvent(type, bubbles, cancelable, detail);

        return event;
    }
}
