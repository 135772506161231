import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import {fadeInOut} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import {Section} from '../../../../common/normalized-styled-components';
import HoverThumbnailView, {type Props as ViewProps} from './view';

const Container = styled(Section)`
    pointer-events: none;
    ${fadeInOut}
`;

export type Props = {
    /** Whether the thumbnail is visible */
    isVisible?: boolean;
    /** Optional additional class name */
    className?: string;
} & ViewProps;

const TM02HoverThumbnail = React.forwardRef<HTMLElement, Props>(
    ({src, content, shouldShowArrow, isVisible = true, className}, ref) => (
        <CommonTransition in={isVisible}>
            <Container
                className={classnames('TM02HoverThumbnail', className)}
                ref={ref}
            >
                <HoverThumbnailView {...{src, content, shouldShowArrow}} />
            </Container>
        </CommonTransition>
    )
);

TM02HoverThumbnail.displayName = 'TM02HoverThumbnail';

export default TM02HoverThumbnail;
