import superagent from 'superagent';

import {MEDIA_OTT_URL} from '../../utils/constants';
import {streamFromApiWithRetry} from '../../utils/api';

export default function getSeriesConfig({
    sport
}) {
    const seriesConfigPath = `/${sport}/series/config.json`;

    const url = `${MEDIA_OTT_URL}${seriesConfigPath}`;

    const apiCall = () => superagent.get(url);

    return streamFromApiWithRetry({apiCall})
        .map('.body')
        .map('.matchCentreSeries');
}
