export default class ShakaTech {
  getResource(adapter) {
    var ret = '';
    var sp = this._getShaka(adapter);
    if (typeof sp.getManifestUri === 'function') {
      ret = sp.getManifestUri();
    } else if (typeof sp.getCurrentSrc === 'function') {
      ret = sp.getCurrentSrc();
    }
    return ret;
  }

  getBitrate(adapter) {
    var ret = -1;
    var sp = this._getShaka(adapter);
    if (typeof sp.getStats === 'function') {
      ret = sp.getStats().streamBandwidth
    } else if ((typeof sp.getVideoTracks === 'function') && (typeof sp.getVideoTrackCurrentIndex === 'function')) {
      var videoIndex = sp.getVideoTrackCurrentIndex();
      var videoTracks = sp.getVideoTracks();
      for (var i in videoTracks) {
        var track = videoTracks[i];
        if (track.index == videoIndex) {
          ret = track.bitrate;
        }
      }
    }
    return ret;
  }

  isUsed(adapter) {
    return !!this._getShaka(adapter);
  }

  getRendition(adapter) {
    var shaka = this._getShaka(adapter);
    var stats = shaka.getStats();
    var ret = this.getNpawUtils().buildRenditionString(stats.width, stats.height, stats.streamBandwidth);
    var tracks = shaka.getVariantTracks();
    for (var i in tracks) {
      var track = tracks[i];
      if (track.active && track.type === 'video') {
        ret = this.getNpawUtils().buildRenditionString(track.width, track.height, track.bandwidth);
        break;
      }
    }
    return ret;
  }

  _getShaka(adapter) {
    var tech = adapter.getTech();
    return tech.shakaPlayer || tech._shakaplayerInstance;
  }
}