import noop from 'lodash/noop';
import property from 'lodash/property';
import {observer} from 'mobx-react-lite';
import {rgba} from 'polished';
import React, {useEffect, useState, useRef} from 'react';
import styled, {useTheme} from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {white} from '../../../../common/palette';
import {
    SCREEN_1920_DESKTOP,
    SCREEN_768_TABLET,
    SCREEN_PHONE,
} from '../../../../common/screen-sizes';
import {
    effectiveViewportMediaQuery,
    THEME_ACCESSORS,
} from '../../../../common/theme-helpers';
import {
    IC103Loading,
    IC16Set,
    IC135Cross,
} from '../../../../component-library/atoms/ic';
import CAM03Finite from '../../../../component-library/molecules/cam/03-finite';
import OR02Tray from '../../../../component-library/organisms/or/02-tray';
import {Z_INDEX_OVER_SCREENS} from '../../../utils/constants';
import {usePlayerContext} from '../../context';
import RelatedVideoTile from '../related-video-tile';
import {TILE_DIMENSIONS_BY_VIEWPORT} from '../utils/constants';
import getKeyDownHandler, {spaceKey} from '../utils/keydown-handler';
import {Instructions} from './upper-tray/tabs/caption-options';

const TrayPositioner = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: ${Z_INDEX_OVER_SCREENS};
    width: ${({theme}) =>
        theme.isHudOpen ? `${theme.effectiveViewportWidthPx}px` : '100%'};
    pointer-events: none;
`;

const StyledOR02Tray = styled(OR02Tray)`
    pointer-events: auto;
`;

const TrayTabContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const StyledInstructions = styled(Instructions)`
    display: none;
    position: absolute;
    bottom: 12px;
    left: 50px;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        display: block;
    `}
`;

const CenteredContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const SplitViewButton = styled(Button)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${transition('background', 'color')};
    margin: 0 15px;
    border: 0;
    background: ${rgba(white, 0.2)};
    width: 74px;
    color: ${white};
    font: ${gibson.semibold({size: 14})};

    ${effectiveViewportMediaQuery({minHeightPx: SCREEN_1920_DESKTOP})`
        margin: 0 22px;
    `}

    > svg {
        margin-bottom: 8px;
    }

    :hover {
        background: ${THEME_ACCESSORS.brandColor};
        color: ${THEME_ACCESSORS.brandForegroundColor};
    }
`;

type Props = {
    postCloseFocus?: () => void;
};

const LowerTray = (
    props: Props,
    ref: React.ForwardedRef<HTMLDivElement>
): React.ReactElement => {
    const playerState = usePlayerContext();
    const theme = useTheme();
    const {brandColor} = useTheme();

    const {
        globalState,
        globalActions,
        generalConfig,
        activeScreenConfig,
        isSplitViewDisabled,
    } = playerState ?? {};
    const {isLowerTrayVisible, relatedVideos, isInSplitViewMode} =
        globalState ?? {};

    const [tileDimensions, setTileDimensions] = useState(
        TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_PHONE]
    );

    useEffect(function updateButtonWidthOnScreenSizeChange() {
        const mediaQuery768 = window.matchMedia(
            `(min-width: ${SCREEN_768_TABLET}px)`
        );
        const mediaQuery1920 = window.matchMedia(
            `(min-width: ${SCREEN_1920_DESKTOP}px)`
        );

        function updateToLargestPossibleSize(): void {
            if (
                mediaQuery1920.matches &&
                tileDimensions !==
                    TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_1920_DESKTOP]
            ) {
                setTileDimensions(
                    TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_1920_DESKTOP]
                );
            } else if (
                mediaQuery768.matches &&
                tileDimensions !==
                    TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_768_TABLET]
            ) {
                setTileDimensions(
                    TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_768_TABLET]
                );
            } else if (
                tileDimensions !== TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_PHONE]
            ) {
                setTileDimensions(TILE_DIMENSIONS_BY_VIEWPORT[SCREEN_PHONE]);
            }
        }

        // Run once on start to set to correct initial size
        updateToLargestPossibleSize();

        const args: [string, () => void] = [
            'change',
            updateToLargestPossibleSize,
        ];

        mediaQuery768.addEventListener(...args);
        mediaQuery1920.addEventListener(...args);

        return () => {
            mediaQuery768.removeEventListener(...args);
            mediaQuery1920.removeEventListener(...args);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(
        function requestRelatedVideos() {
            if (
                generalConfig?.canHaveRelatedVideos &&
                isLowerTrayVisible &&
                activeScreenConfig?.playbackData
            ) {
                // Just blindly call this every time - let the consumer app do throttling etc if necessary
                return globalActions?.onRequestRelatedVideos(
                    activeScreenConfig.playbackData
                );
            }
            // Re-generate the related videos content when switching video
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLowerTrayVisible, activeScreenConfig?.playbackData?.id]
    );

    // If we have proper related videos, filter out any empty categories
    const filteredRelatedVideosTabs = Array.isArray(relatedVideos)
        ? relatedVideos.filter(property('tiles.length'))
        : [];

    const instructionCopyElement = isInSplitViewMode ? (
        <React.Fragment>
            <b>CLICK</b> a video to add/remove stream or <b>CLICK</b> outside of
            tray to close options
        </React.Fragment>
    ) : (
        <React.Fragment>
            <b>CLICK</b> a video to start stream or <b>Click</b> outside of tray
            to close options
        </React.Fragment>
    );

    let relatedVideosTabs;
    const isRequestingContent =
        !!relatedVideos?.length && !filteredRelatedVideosTabs.length;

    if (!relatedVideos || isRequestingContent) {
        relatedVideosTabs = [
            {
                name: 'Loading',
                node: (
                    <CenteredContent>
                        <IC103Loading
                            size="56px"
                            color={theme.brandColor}
                            loadingSpinnerUrl={theme.loadingSpinnerUrl}
                        />
                    </CenteredContent>
                ),
            },
        ];
    } else if (filteredRelatedVideosTabs.length) {
        relatedVideosTabs = filteredRelatedVideosTabs.map(({label, tiles}) => ({
            name: label,
            node: (
                <TrayTabContainer>
                    <CenteredContent>
                        <CAM03Finite
                            itemHeightPx={tileDimensions.heightPx * 1.1 + 10}
                            itemWidthPx={tileDimensions.widthPx}
                            itemGapPx={tileDimensions.gapPx}
                            hasOuterGap={true}
                        >
                            {tiles.map(
                                ({
                                    render,
                                    id,
                                    playVideo,
                                    tileAnalytics,
                                    checkIsPlayable,
                                }) => (
                                    <CenteredContent key={id}>
                                        <RelatedVideoTile
                                            {...{
                                                render,
                                                id,
                                                playVideo,
                                                tileAnalytics,
                                                checkIsPlayable,
                                            }}
                                        />
                                    </CenteredContent>
                                )
                            )}
                        </CAM03Finite>
                    </CenteredContent>
                    <StyledInstructions>
                        {instructionCopyElement}
                    </StyledInstructions>
                </TrayTabContainer>
            ),
        }));
    } else {
        relatedVideosTabs = [
            {
                name: 'No Videos',
                node: (
                    <CenteredContent>
                        Sorry, there are no related videos available.
                    </CenteredContent>
                ),
            },
        ];
    }

    const mediaKeysAndActionsRef = useRef({
        Escape: () => {
            globalActions?.setTrayVisibility({lower: false});
            // Move focus to button that opened tray
            props.postCloseFocus?.();
        },
        ArrowLeft: noop,
        ArrowRight: noop,
        ArrowUp: noop,
        ArrowDown: noop,
        [spaceKey]: noop,
    });
    const inputHandler = getKeyDownHandler(mediaKeysAndActionsRef);

    // isSplitViewDisabled is true when user set numScreens to 1;
    const splitViewToggle = !isSplitViewDisabled && (
        <SplitViewButton
            type="button"
            onClick={() =>
                globalActions?.setIsInSplitViewMode(
                    !globalState?.isInSplitViewMode
                )
            }
            activeColor={brandColor}
        >
            {globalState?.isInSplitViewMode ? (
                <React.Fragment>
                    <IC135Cross size="21px" />
                    Exit Splitview
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <IC16Set size="30px" />
                    Splitview
                </React.Fragment>
            )}
        </SplitViewButton>
    );

    return (
        <TrayPositioner {...props} ref={ref}>
            <StyledOR02Tray
                isTop={false}
                isVisible={isLowerTrayVisible}
                onTrayClose={() => {
                    globalActions?.setTrayVisibility({lower: false});
                }}
                tabItems={relatedVideosTabs}
                onKeyDown={inputHandler}
                splitViewToggle={splitViewToggle}
                onClick={(e: React.MouseEvent) =>
                    playerState?.handleInteraction(e.nativeEvent)
                }
            />
        </TrayPositioner>
    );
};

LowerTray.displayName = 'LowerTray';

export default observer(LowerTray, {forwardRef: true});
