import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC135Cross = ({...args}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M21.613 2.33 19.84.556l-8.632 8.632L2.574.556.8 2.33l8.632 8.632L.8 19.595l1.774 1.774 8.633-8.632 8.632 8.632 1.774-1.774-8.632-8.633z"
            fillRule="nonzero"
            transform="scale(4.3)"
        />
    </Ic>
);

IC135Cross.displayName = 'IC135Cross';

export default IC135Cross;
