import GenericAdsAdapter from "./ads/GenericAdsAdapter";

export default class ReactNativeVideoAdapter {
  adsAdapters = {
    genericAdsAdapter: GenericAdsAdapter
  }

  getVersion() {
    return '7.0.1-react-native-video-jsclass';
  }

  getPlayhead() {
    const adsAdapter = this.getVideo().getAdsAdapter();
    return adsAdapter && adsAdapter.isShowingAds() ? this.lastPlayhead : this.playhead;
  }

  getIsLive() {
    return typeof this.duration !== 'undefined' ? !this.duration : undefined;
  }

  getDuration() {
    return this.duration;
  }

  getResource() {
    return this.resource;
  }

  getRendition() {
    if (this.width && this.height) {
      return this.getNpawUtils().buildRenditionString(this.width, this.height);
    }
    return;
  }

  getPlayerName() {
    return 'React Native Video';
  }

  setPlatform(platform) {
    this.reactPlatform = platform;
  }

  registerListeners() {
    if (this.reactPlatform && this.reactPlatform == 'ios') {
      this.monitorPlayhead(true, true, 400);
    }
    if (!this.getVideo().getAdsAdapter()) {
      this.plugin.setAdsAdapter(this.getAdapterClass('genericAdsAdapter'), this.getVideo().getVideoKey());
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
  }

  onLoad(e) {
    if (this.reactPlatform && this.reactPlatform == 'ios' && !this.monitor) {
      this.monitorPlayhead(true, true, 400);
    }
    const size = e.naturalSize;
    if (size) {
      this.width = Math.round(size.width);
      this.height = Math.round(size.height);
    }
    this.duration = e.duration;
    this.fireStart({}, 'onLoad');
    if (!this.flags.isJoined) {
      this.onBuffering = false;
      this.fireJoin({}, 'onLoadStart');
      if (this.reactPlatform && this.reactPlatform == 'ios' && this.monitor) {
        this.monitor.skipNextTick();
      }
    }
    if (this.pausePlayhead && Math.abs(this.getPlayhead() - this.pausePlayhead) > 0.5) {
      this.fireSeekEnd({}, 'onLoadStart');
    } else {
      if (this.pausePlayhead) {
        this.chronos.pause.pauseTime = undefined;
      }
    }
    this.pausePlayhead = undefined;
    this.fireResume({}, 'onLoadStart');
  }

  onLoadStart(e) {
    if (this.reactPlatform && this.reactPlatform == 'ios' && !this.monitor) {
      this.monitorPlayhead(true, true, 400);
    }
    if (e.src) {
      this.resource = e.src.uri;
    }
    this.fireStart({}, 'onLoadStart');
  }

  onPlaybackRateChange(e) {
    this.playrate = e.playbackRate;
    if (this.getVideo().getAdsAdapter() && this.getVideo().getAdsAdapter().isShowingAds()) {
      return;
    }
    if (this.playrate && this.playhead > 0.1) {
      this.fireStart({}, 'onPlaybackRateChange');
      if (!this.flags.isJoined) {
        this.onBuffering = false;
        if (this.reactPlatform && this.reactPlatform == 'ios' && !this.monitor) {
          this.monitorPlayhead(true, true, 400);
        }
        this.fireJoin({}, 'onPlaybackRateChange');
        if (this.reactPlatform && this.reactPlatform == 'ios' && this.monitor) {
          this.monitor.skipNextTick();
        }
      }
      if (this.pausePlayhead && Math.abs(this.getPlayhead() - this.pausePlayhead) > 0.5 && !this.flags.isPaused) {
        this.fireSeekEnd({}, 'onPlaybackRateChange');
        this.pausePlayhead = undefined;
      } else if (this.pausePlayhead && Math.abs(this.getPlayhead() - this.pausePlayhead) < 0.5 && !this.onBuffering) {
        this.cancelSeek();
        this.pausePlayhead = undefined;
      }
      this.fireResume({}, 'onPlaybackRateChange');
    } else if (this.playhead > 0.1) {
      if (this.reactPlatform && this.reactPlatform == 'android') {
        this.pausePlayhead = this.getPlayhead();
        this.fireSeekBegin({}, true, 'onPlaybackRateChange');
      }
      if (this.reactPlatform && this.reactPlatform == 'ios') {
        this.lastPlayhead = this.playhead;
      }
      this.firePause({}, 'onPlaybackRateChange');
      if (this.reactPlatform && this.reactPlatform == 'android') {
        this.chronos.pause.startTime = new Date().getTime();
      }
    }
  }

  onProgress(e) {
    const adsAdapter = this.getVideo().getAdsAdapter();
    if (adsAdapter && adsAdapter.isShowingAds() && this.reactPlatform && this.reactPlatform == 'android' && !this.lastPlayhead) {
      this.lastPlayhead = this.playhead;
    } else if (adsAdapter && !adsAdapter.isShowingAds() && !this.flags.isPaused) {
      this.lastPlayhead = undefined;
    }
    if (adsAdapter && !adsAdapter.isShowingAds() && !this.duration) {
      this.duration = e.seekableDuration;
    }
    this.playhead = e.currentTime;
    if (e.playableDuration - e.currentTime > 0.5) {
      this.fireStart({}, 'onProgress');
    }
    if (this.playhead > 0.5 && !this.flags.isJoined) {
      this.onBuffering = false;
      this.fireJoin({}, 'onProgress');
      if (this.reactPlatform && this.reactPlatform == 'ios' && this.monitor) {
        this.monitor.skipNextTick();
      }
    }
    if (this.pausePlayhead && !this.flags.isPaused) {
      if (Math.abs(this.getPlayhead() - this.pausePlayhead) > 2) {
        this.fireSeekEnd({}, 'onProgress');
      } else if (this.onBuffering) {
        this.fireBufferBegin({}, true, 'onProgress');
        this.fireBufferEnd({}, 'onProgress');
        this.onBuffering = false;
      } else {
        this.chronos.pause.pauseTime = undefined;
      }
      this.pausePlayhead = undefined;
    }
    if (this.playrate) {
      this.onBuffering = false;
    }
  }

  onBuffer(e) {
    if (e.isBuffering && typeof this.getPlayhead() !== 'undefined' && this.reactPlatform && this.reactPlatform == 'android') {
      this.onBuffering = true;
      this.fireBufferBegin({}, false, 'onBuffer');
    }
  }

  onError(e) {
    let errorCode;
    let errorException;
    if(this.reactPlatform && this.reactPlatform == 'android') {
      errorCode = e.error.errorCode;
      errorException = e.error.errorException;
    } else if (this.reactPlatform && this.reactPlatform == 'ios') {
      errorCode = e.error.code;
      errorException = e.error.localizedDescription;
    }
    this.fireError(errorCode, errorException);
  }

  onEnd(e) {
    if (this.reactPlatform && this.reactPlatform == 'ios' && this.getVideo().getAdsAdapter()) {
      this.stopTimer = setTimeout(() => {
        this.fireStop();
      }, this.getVideo().options['ad.postRollTimeout'] || 1000);
      return;
    }
    this.fireStop();
  }

  onAdEvent(e) {
    if (this.getVideo() && this.getVideo().getAdsAdapter()) {
      this.getVideo().getAdsAdapter().onAdEvent(e);
    }
  }
}
