import React from 'react';

import stringifySrcsetOptions from './stringify-srcset-options';

type Props = {
    srcsetOptions?: {[key: number]: string};
    minWidthPx?: number;
    minHeightPx?: number;
    orientation?: 'landscape' | 'portrait';
    sizes?: string;
};

const SrcsetSource = ({
    srcsetOptions,
    minWidthPx,
    minHeightPx,
    orientation,
    sizes,
}: Props): React.ReactElement => {
    const mediaQuery = [
        minWidthPx && `(min-width: ${minWidthPx}px)`,
        minHeightPx && `(min-height: ${minHeightPx}px)`,
        orientation && `(orientation: ${orientation})`,
    ]
        .filter(Boolean)
        .join(' and ');

    return (
        <source
            media={mediaQuery || undefined}
            sizes={sizes}
            srcSet={stringifySrcsetOptions(srcsetOptions)}
        />
    );
};

SrcsetSource.displayName = 'SrcsetSource';

export default SrcsetSource;
