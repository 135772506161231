import {
    mediaQuery,
    stylesWhenNot,
} from '@fsa-streamotion/styled-component-helpers';

import noop from 'lodash/noop';
import {rgba} from 'polished';
import React from 'react';
import styled, {css} from 'styled-components';

import {transition} from '../../../../common/animations';
import {slate, white, black} from '../../../../common/palette';
import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import VisuallyHidden from '../../../../common/visually-hidden';

const STEP = 0.1;

const Slider = styled.label`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    touch-action: none;
    transform: rotate(-90deg);
    transform-origin: center left;
    border: 18px solid transparent;
    border-radius: 4px;
    background: transparent;
    width: 142px;
    height: 42px;

    ${stylesWhenNot('isVisible')`
        visibility: hidden;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 150px;
    `}
`;

const Level = styled.progress<{isDisabled: boolean}>`
    appearance: none;
    transition: ${transition('color', 'background')};
    border: 0;
    border-radius: 5px;
    background: ${rgba(white, 0.5)};
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: ${({isDisabled}) => (isDisabled ? slate : white)};

    &::-webkit-progress-bar {
        background-color: inherit;
    }

    &::-webkit-progress-value {
        transition: none;
        border-radius: 0;
        background: currentColor;
        min-width: 8px;
        max-width: 99%;
    }

    &::-moz-progress-bar {
        transition: none;
        border-radius: 0;
        background: currentColor;
        min-width: 8px;
        max-width: 99%;
    }

    &::-ms-fill {
        display: none;
    }
`;

const InteractiveControl = styled.input`
    appearance: none;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: 0;
    margin: 0;
    border: none;
    background: transparent;
    padding: 0;
    width: 100%;
    vertical-align: middle; /* stylelint-disable-line */
    touch-action: manipulation;

    ${['webkit-slider', 'moz-range', 'ms'].map(
        (vendor) => css`
            /* stylelint-disable-next-line */
            &::-${vendor}-thumb {
                appearance: none;
                box-sizing: border-box;
                position: relative;
                transition: ${transition('background', 'border', 'transform')};
                border: 2px solid transparent;
                border-radius: 100%;
                box-shadow: 0 0 12px 0 ${black};
                background: ${({isDisabled}) => (isDisabled ? slate : white)};
                width: 10px;
                height: 10px;

                ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
                width: 20px;
                height: 20px;
            `}
            }
        `
    )}

    &::-ms-thumb {
        /* box-shadow on the thumb causes overflow cut off in edge */
        box-shadow: none;
    }
    /* stylelint-disable */
    ${['webkit-slider-runnable', 'moz-range'].map(
        (vendor) => css`
            /* stylelint-disable-next-line */
            &::-${vendor}-track {
                display: flex;
                align-items: center;
                border: 0;
                width: 100%;
                background: transparent !important; /* stylelint-disable-line */
                height: 100%;
                user-select: none;
            }
        `
    )}

    &::-ms-track {
        border: 0;
        background: transparent;
        height: 20px;
        color: transparent;
    }
    /* stylelint-enable */

    &::-ms-fill-upper {
        border: 0;
        border-radius: 4px;
        background: transparent;
        height: 8px;
        user-select: none;
    }

    &::-ms-fill-lower {
        border: 0;
        border-radius: 4px;
        background: ${({isDisabled}) => (isDisabled ? slate : white)};
        height: 6px;
        user-select: none;
    }

    &::-ms-tooltip {
        display: none;
    }

    &::-moz-focus-outer {
        border: 0;
    }

    &.tab-focus:focus {
        outline-offset: 3px;
    }

    &:active {
        /* Pressed styles */
        &::-webkit-slider-thumb {
            box-shadow: 0 0 7.7px 0 ${black}, 0 0 0 3.8px ${rgba(white, 0.25)};
        }

        &::-moz-range-thumb {
            box-shadow: 0 0 7.7px 0 ${black}, 0 0 0 3.8px ${rgba(white, 0.25)};
        }

        &::-ms-thumb {
            box-shadow: 0 0 7.7px 0 ${black}, 0 0 0 3.8px ${rgba(white, 0.25)};
        }
    }

    /* stylelint-disable-next-line order/order */
    &:focus,
    &:hover {
        /* Focus styles: e.g. pressed or keyboard focus */
        outline: 0;

        &::-webkit-slider-thumb {
            transform: scale(1.3);
            background: ${THEME_ACCESSORS.brandColor};
        }

        &::-moz-range-thumb {
            transform: scale(1.3);
            background: ${THEME_ACCESSORS.brandColor};
        }

        &::-ms-thumb {
            /*
            scaling causes overflow cut off in edge  but also
            edge seems to apply -webkit rule so explicitly override here
            */
            transform: scale(1);
            background: ${THEME_ACCESSORS.brandColor};
        }
    }
`;

export type Props = {
    /** Value of the volume, can take 0~1, step=1  */
    value?: number;
    /** Visually hidden label for accessibility */
    label?: string;
    /** Is the slider disabled */
    isDisabled?: boolean;
    /** Is the slider visible */
    isVisible?: boolean;
    /** Callback when the slider is focused */
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    /** Callback when value of slider is updated */
    onValueChange?: (value: number) => void;
    /** Custom classname for the slider */
    className?: string;
} & React.ComponentPropsWithoutRef<'input'>;

const IA01VertRange = ({
    value = 1,
    label,
    isDisabled = false,
    isVisible = true,
    onFocus = noop,
    onValueChange = noop,
    className,
    ...htmlAttributes
}: Props): React.ReactElement => (
    <Slider
        isVisible={isVisible}
        aria-hidden={!isVisible}
        className={className}
    >
        {!!label && <VisuallyHidden>{label}</VisuallyHidden>}
        <Level
            aria-hidden="true"
            max="1"
            value={value}
            isDisabled={isDisabled}
        />

        <InteractiveControl
            {...htmlAttributes}
            isDisabled={isDisabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                void onValueChange(Number(event.target.value))
            }
            onFocus={onFocus}
            min="0"
            max="1"
            step={STEP}
            type="range"
            value={value}
        />
    </Slider>
);

IA01VertRange.displayName = 'IA01VertRange';

export default IA01VertRange;
