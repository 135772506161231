import superagent from 'superagent';

import {streamFromApi, getVimondHeaders} from '../../utils/api';
import authenticated from '../authenticated';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV, PAYMENT_PROVIDER_ID, CALLBACK_URL} from '../../utils/constants';

export default function postInitPaymentMethod({
    callbackUrl,
    paymentProviderId,
    sport = 'afl',
    userId,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/${userId}/userpaymentmethod`;
    const userPaymentMethod = {
        userPaymentMethod: {
            userId,
            paymentProviderId: paymentProviderId || PAYMENT_PROVIDER_ID[sport][vimondEnv],
            callbackUrl: callbackUrl || CALLBACK_URL[sport]
        }
    };

    return authenticated().flatMapLatest((authToken) => {
        const request = superagent
            .post(resourceUrl)
            .set(getVimondHeaders({authToken, sport, version: 1}))
            .send(userPaymentMethod);

        return streamFromApi(request).map('.body');
    });
}
