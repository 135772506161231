import type React from 'react';

/**
 * @example
 * ```
 * getKeyDownHandler({
 *   ArrayLeft: () => {},
 *   ArrayRight: () => {},
 *   Esc: Object.assign((...args) => {}, {skipPreventDefault: true})
 * })
 * ```
 *
 * @param keyActionsRef - React ref
 * @returns onKeyDown event handler, with keys mapped according to keyActionsRef
 */
export default function getKeyDownHandler(
    keyActionsRef: React.RefObject<
        Record<
            string,
            ((event: React.KeyboardEvent | Event) => void) & {
                skipPreventDefault?: boolean;
            }
        >
    >
) {
    return (event: React.KeyboardEvent | Event) => {
        const action = keyActionsRef.current?.[event.key];

        if (typeof action !== 'function') {
            return;
        }

        action(event);

        if (!action.skipPreventDefault) {
            event.preventDefault();
        }

        event.stopPropagation();
    };
}

export const spaceKey = ' ';
