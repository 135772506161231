import {stringify} from 'querystring';
import bacon from 'baconjs';
import pickBy from 'lodash/pickBy';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {DEFAULT_VIMOND_ENV, PROXY_RESOURCE_URL} from '../../utils/constants';

/**
 * Get all the latest episodes available for given sports
 * http://origin-ottapi-staging-foxsports.com.au/hawk-proxy-api/proxy/videos?sport=afl&assetTypes=episode&size=8
 *
 * @param  {String} sport                    e.g. afl
 * @param  {String} seriesId                 e.g. 1,2
 * @param  {String} seasonId                 e.g. 120
 * @param  {String} roundNumber              e.g. 23
 * @param  {String} matchId                  e.g. id of the match
 * @param  {String} fixtureId                e.g. 1583
 * @param  {String} teamIds                  e.g. // comma-seperated team IDs
 * @param  {String} assetTypes               e.g. // comma-seperated live, match, news ...etc
 * @param  {String} categoryIds              e.g. // comma-seperated category IDs
 * @param  {String} title                    e.g. // video title
 * @param  {String} text                     e.g. // free text search
 * @param  {String} page                     e.g. 5 // pagination page index 0-based
 * @param  {String} size                     e.g. 8 // pagination page size
 * @param  {String} groupBy                  e.g. group by [round]
 * @param  {Number} freq                     e.g. polling frequency
 * @param  {String} vimondEnv         e.g. 'staging'
 * @return {Object} latestEpisode response
 */
export default function getVideosListStream({
    sport,
    assetTypes,

    vimondEnv = DEFAULT_VIMOND_ENV,
    size = 8,
    page = null,
    seriesId = null,
    seasonId = null,
    roundNumber = null,
    matchId = null,
    fixtureId = null,
    teamIds = null,
    categoryIds = null,
    title = null,
    text = null,
    groupBy = null,
    freq = 0
}) {
    const queryParams = stringify(pickBy(
        {
            sport,
            seriesId,
            seasonId,
            roundNumber,
            matchId,
            fixtureId,
            teamIds,
            assetTypes,
            categoryIds,
            title,
            text,
            page,
            size,
            groupBy
        },
        (argVal) => argVal !== null && argVal !== undefined
    ));

    const url = `${PROXY_RESOURCE_URL[vimondEnv]}videos` +
        (queryParams ? `?${queryParams}` : '');

    return getApiFromRetryWithError({
        url,
        freq
    }).flatMapLatest((response) => {
        if (response.responseConsideredValid) {
            return response.parseResponseText();
        }

        const errorMessage = `FS-HAWKOTT: unable to get video list data (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

        return new bacon.Error(errorMessage);
    });
}
