import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC27ArrowD = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M39.138 45.109L0 5.566 5.509 0 39.138 33.976 72.766 0 78.276 5.566z"
            transform="translate(10.924 27)"
        />
    </Ic>
);

IC27ArrowD.displayName = 'IC27ArrowD';

export default IC27ArrowD;
