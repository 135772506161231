import {
    type BaseProps,
    mediaQuery,
} from '@fsa-streamotion/styled-component-helpers';

import {
    css,
    type CSSObject,
    type DefaultTheme,
    type FlattenInterpolation,
    type Interpolation,
    type InterpolationFunction,
    type ThemedStyledProps,
} from 'styled-components';

import {silverLining} from './palette';

type ThemeKey = Exclude<
    keyof DefaultTheme,
    | 'effectiveViewportWidthPx'
    | 'loadingSpinnerUrl'
    | 'isHudOpen'
    | 'isRelVideoHoverScalingDisabled'
>;

type ThemeAccessors = {
    [K in ThemeKey]: (obj: {theme: DefaultTheme}) => DefaultTheme[ThemeKey];
} & {
    effectiveViewportWidthPx: (obj: {
        theme: DefaultTheme;
    }) => number | undefined;
    loadingSpinnerUrl: (obj: {theme: DefaultTheme}) => string | undefined;
    isHudOpen: (obj: {theme: DefaultTheme}) => boolean;
    isRelVideoHoverScalingDisabled: (obj: {theme: DefaultTheme}) => boolean;
};

export const THEME_ACCESSORS: ThemeAccessors = {
    brandColor: ({theme}) => theme?.brandColor,
    brandForegroundColor: ({theme}) =>
        theme?.brandForegroundColor ?? silverLining, // optional foreground colour when brandColor is used as a background and clashes with silverLining
    brandColorVariant: ({theme}) => theme?.brandColorVariant,
    hudBackground: ({theme}) => theme?.hudBackground,
    hudSidebarBackground: ({theme}) => theme?.hudSidebarBackground,
    effectiveViewportWidthPx: ({theme}) => theme?.effectiveViewportWidthPx,
    isHudOpen: ({theme}) => !!theme?.isHudOpen,
    loadingSpinnerUrl: ({theme}) => theme?.loadingSpinnerUrl,
    isRelVideoHoverScalingDisabled: ({theme}) =>
        !!theme?.isRelVideoHoverScalingDisabled,
};

type EffectiveViewportMediaQueryReturnedType<P> = (
    cssStrings:
        | TemplateStringsArray
        | CSSObject
        | InterpolationFunction<ThemedStyledProps<P, DefaultTheme>>,
    ...cssInterpolations: Array<
        Interpolation<ThemedStyledProps<P, DefaultTheme>>
    >
) => ({
    theme,
}: {
    theme: DefaultTheme;
}) => FlattenInterpolation<ThemedStyledProps<P, DefaultTheme>> | '';

export function effectiveViewportMediaQuery<P extends BaseProps>({
    minWidthPx,
    minHeightPx /* , minHeightPx, orientation*/,
}: {
    minWidthPx?: number;
    minHeightPx?: number;
}): EffectiveViewportMediaQueryReturnedType<P> {
    // eslint-disable-line spaced-comment
    const cssMediaQuery = mediaQuery<P>({
        minWidthPx,
        minHeightPx /*, orientation*/, // eslint-disable-line spaced-comment
    });

    return function cssRules(...ruleFragments) {
        return function interpolation({theme}) {
            if (!theme?.isHudOpen) {
                return cssMediaQuery(...ruleFragments);
            } else if (
                theme?.effectiveViewportWidthPx &&
                typeof minWidthPx === 'number' &&
                theme.effectiveViewportWidthPx >= minWidthPx
            ) {
                return css(...ruleFragments);
            } else {
                return '';
            }
        };
    };
}
