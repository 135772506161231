import {isTizen} from '@fsa-streamotion/browser-utils';

import type {MediaPlayerClass} from 'dashjs';

import type PlaybackDash from '.';
import NativeLivestream from '../native/livestream';
import type {OnEdgeLeniencyEvent, DashManifestLoadedEvent} from '../types';

export default class DashLivestream extends NativeLivestream {
    lastDuration = 0;
    hasMultiplePeriod = false;
    declare playbackHandler: MediaPlayerClass;
    declare playbackTech: PlaybackDash;

    constructor(playbackTech: PlaybackDash, playbackHandler: MediaPlayerClass) {
        super(playbackTech, playbackHandler);

        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.setOnEdgeLeniency
        );
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.setBestGuessFragmentDuration
        );
    }

    override destroy(): void {
        super.destroy();
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.setOnEdgeLeniency
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.setBestGuessFragmentDuration
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
            this.dispatchFakeDurationchangeEvent
        );
        this.lastDuration = 0;
    }

    override setupLiveListeners(): void {
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.computeLive
        );
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.computeHasMultiplePeriod,
            this
        );
    }

    override destroyLiveListeners(): void {
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.computeLive
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.MANIFEST_LOADED,
            this.computeHasMultiplePeriod,
            this
        );
    }

    /**
     * Set on-edge leniency based on liveDelay or liveDelayFragmentCount settings.
     *
     * Leniency will be the effective live delay from the edge, plus an additional four fragments.
     *
     * @param event - dash.js <code>"manifestLoaded"</code> event
     */
    override trySetOnEdgeLeniency = (event: OnEdgeLeniencyEvent[0]): void => {
        const minBufferTime =
            (event as DashManifestLoadedEvent)?.data?.minBufferTime || 0;
        const {liveDelay, liveDelayFragmentCount = 0} =
            this.playbackHandler.getSettings()?.streaming?.delay ?? {};
        const leniency = 2 * minBufferTime;

        // Calculate the live playhead position based on dash.js settings
        const effectiveLiveDelay =
            liveDelay || liveDelayFragmentCount * minBufferTime || 0;

        // Absolute leniency is the playhead delay from the edge of live plus a constant leniency based on fragment duration
        this.onEdgeLeniency = effectiveLiveDelay + leniency;
    };

    override tryComputeLive = (event: OnEdgeLeniencyEvent[0]): void => {
        if (event) {
            const manifestType = (event as DashManifestLoadedEvent).data?.type;

            if (manifestType) {
                const isLive = manifestType === 'dynamic';

                this.triggerIsLive(isLive);

                this.playbackHandler.on(
                    window.dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
                    this.dispatchFakeDurationchangeEvent
                );

                this.dispatchFakeDurationchangeEvent();
            }
        }
    };

    computeHasMultiplePeriod(event: OnEdgeLeniencyEvent[0]): void {
        this.hasMultiplePeriod =
            (event as DashManifestLoadedEvent).data?.Period_asArray?.length > 1;
    }

    // For live streams, dashjs doesnt actually dispatch durationchange events on the video element, because of its sliding window
    // Consumers of PlayerTech rely on this though, so we artificially broadcast them from here, once every fragment
    dispatchFakeDurationchangeEvent = (): void => {
        const newDuration = this.playbackTech.duration;

        if (newDuration !== this.lastDuration) {
            this.videoElement?.dispatchEvent(new Event('durationchange'));
        }

        this.lastDuration = newDuration;
    };

    setBestGuessFragmentDuration = (event: OnEdgeLeniencyEvent[0]): void => {
        // Dash doesn't reveal the fragment duration to us, but we can make a good guess of it from the 'minBufferTime' specified in the manifest
        const minBufferTime = (event as DashManifestLoadedEvent).data
            ?.minBufferTime;

        if (!Number.isNaN(minBufferTime) && minBufferTime !== undefined) {
            this.bestGuessFragmentDuration = minBufferTime;
        }
    };

    override setCurrentTimeToEdge(): void {
        if (!isTizen()) {
            super.setCurrentTimeToEdge();
        }

        const edgeOfStreamTime = this.playbackHandler.getDVRWindowSize();

        // If we're already considered on edge, don't jump again.
        if (this.live && !this.onEdge && edgeOfStreamTime) {
            this.playbackTech.currentTime =
                edgeOfStreamTime - this.bestGuessFragmentDuration; // don't go all the way to the edge so we don't accidently trigger the 'ended' event.
        }
    }
}
