import React from 'react';
import styled, {useTheme} from 'styled-components';

import gibson from '../../../../common/font-helper';
import {IC103Loading} from '../../../atoms/ic';

const BufferingIndicator = styled.div`
    position: relative;
    width: 1em;
    height: 1em;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 1px auto 0;
        font: ${gibson.medium({size: '0.25em'})};
        content: attr(title);
    }

    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        width: unset;
        height: unset;
        text-align: center;
        font-size: 14px;

        &::after {
            display: block;
            position: static;
            transform: unset;
            margin-top: 7px;
            margin-right: 0;
            margin-left: 0.25em;
            text-align: center;
            font-size: inherit;
        }
    }
`;

export type Props = {
    /** Screen reader label for the loading indicator */
    assistiveLabel?: string;
    /** Total pixel width/height of element */
    sizePx?: number;
    /** Additional CSS class name(s) */
    className?: string;
    /** Number from 0-100 representing current buffer state */
    bufferPercentage?: number;
};

const TM04BufferingIndicator = ({
    assistiveLabel = 'Buffering video',
    bufferPercentage,
    className,
    sizePx = 100,
}: Props): React.ReactElement => {
    const {brandColor, loadingSpinnerUrl} = useTheme();
    const progressBarProps = Number.isFinite(bufferPercentage)
        ? {
              'aria-valuemax': 100,
              'aria-valuemin': 0,
              'aria-valuenow': bufferPercentage,
              'aria-valuetext': assistiveLabel,
              'role': 'progressbar', // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role#Examples
              'title': `${bufferPercentage}%`,
          }
        : {
              'aria-label': assistiveLabel,
          };

    return (
        <BufferingIndicator
            {...progressBarProps}
            style={{fontSize: `${sizePx}px`}}
            className={className}
        >
            <IC103Loading
                color={brandColor}
                loadingSpinnerUrl={loadingSpinnerUrl}
            />
        </BufferingIndicator>
    );
};

TM04BufferingIndicator.displayName = 'TM04BufferingIndicator';

export default TM04BufferingIndicator;
