import result from 'lodash/result';

export default function getBasketballSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'nbl':
        case '9':
            return {
                id: 9,
                label: 'NBL',
                route: 'nbl'
            };

        case 'nba':
        case '39':
            return {
                id: 39,
                label: 'NBA',
                route: 'nba'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Basketball seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
