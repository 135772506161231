import React from 'react';

const GA142Up4 = (): React.ReactElement => (
    <svg width="74px" height="30px" viewBox="0 0 74 30">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.561822, 0.000000)" fill="#FFFFFF">
                <rect x="0" y="0" width="52.5639913" height="28.9782609" />
                <rect
                    x="55.2595806"
                    y="0.673913043"
                    width="17.5213304"
                    height="7.41304348"
                />
                <rect
                    x="55.2595806"
                    y="10.7826087"
                    width="17.5213304"
                    height="7.41304348"
                />
                <rect
                    x="55.2595806"
                    y="20.8913043"
                    width="17.5213304"
                    height="7.41304348"
                />
            </g>
        </g>
    </svg>
);

GA142Up4.displayName = 'GA142Up4';

export default GA142Up4;
