export default class ShakaTech {
  getResource(adapter) {
    let ret = '';
    const sp = this._getShaka(adapter);
    if (typeof sp.getManifestUri === 'function') {
      ret = sp.getManifestUri();
    } else if (typeof sp.getCurrentSrc === 'function') {
      ret = sp.getCurrentSrc();
    }
    return ret;
  }

  getBitrate(adapter) {
    let ret = -1;
    const sp = this._getShaka(adapter);
    if (typeof sp.getStats === 'function') {
      ret = sp.getStats().streamBandwidth
    } else if ((typeof sp.getVideoTracks === 'function') && (typeof sp.getVideoTrackCurrentIndex === 'function')) {
      const videoIndex = sp.getVideoTrackCurrentIndex();
      const videoTracks = sp.getVideoTracks();
      for (let i in videoTracks) {
        const track = videoTracks[i];
        if (track.index == videoIndex) {
          ret = track.bitrate;
        }
      }
    }
    return ret;
  }

  isUsed(adapter) {
    return !!this._getShaka(adapter)
  }

  getRendition(adapter) {
    const shaka = this._getShaka(adapter);
    const stats = shaka.getStats();
    let ret = this.getNpawUtils().buildRenditionString(stats.width, stats.height, stats.streamBandwidth);
    const tracks = shaka.getVariantTracks();
    for (let i in tracks) {
      const track = tracks[i];
      if (track.active && track.type === 'video') {
        ret = this.getNpawUtils().buildRenditionString(track.width, track.height, track.bandwidth);
        break;
      }
    }
    return ret;
  }

  _getShaka(adapter) {
    const tech = adapter.getTech();
    return tech.shakaPlayer || tech._shakaplayerInstance;
  }
}