import React from 'react';
import {createRoot} from 'react-dom/client';
import bacon from 'baconjs';
import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names'; // Darren wants me to write: REALLY SEAN? SPORT NAMES?
import {setLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';

import {
    CONTENT_URL,
    TEAM_ID_NAME_MAP,
    DEFAULT_VIMOND_ENV,
    SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY,
    LOCAL_STORAGE_EXPIRES_IN
} from '../utils/constants';

import {redirectPage} from '../utils/location';
import {trackLoggedInStatus} from '../utils/event-tracking/authentication';

import postOrder from '../streams/order/post-order';
import putOrder from '../streams/order/put-order';

import SubscribeComponent from '../components/subscribe';

import {
    trackFavouriteTeam,
    trackPaymentType,
    trackPersonalDetailsComplete,
    trackSubscriptionComplete,
    trackSubscriptionPackage,
    trackCurrencyConversion,
    trackAddToCart,
    registerSale
} from '../utils/event-tracking/subscription';

import getVoucherProductFormData from './subscribe/voucher-product';
import getUserFormData from './subscribe/user';
import getPaymentFormData from './subscribe/payment';

const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.
const ISO_ERROR_EXPIRES_IN = 10; // 10 secs

function Subscribe(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        baseUrl: this.settings.baseUrl,
        club: this.settings.club || '',
        currency: this.settings.currency || 'AUD',
        specialHeaderImageUrl: decodeURIComponent(this.settings.specialHeaderImageUrl || ''),
        specialHeaderText: decodeURIComponent(this.settings.specialHeaderText || ''),
        sport: this.settings.sport || 'afl',
        successUrl: this.settings.successUrl,
        vimondEnv: this.settings.vimondEnv || DEFAULT_VIMOND_ENV,

        // Voucher and Product settings;
        chosenProductId: asNumber(this.settings.chosenProductId),
        productGroupId: asNumber(this.settings.productGroupId),
        pubsClubsProductGroupId: asNumber(this.settings.pubsClubsProductGroupId, 0),
        voucher: this.settings.voucher  || '',

        // User Settings
        dataCollectionUrl: this.settings.dataCollectionUrl,
        nrlPrivacyUrl: this.settings.nrlPrivacyUrl,
        aflPrivacyUrl: this.settings.aflPrivacyUrl,
        privacyUrl: this.settings.privacyUrl,
        termsUrl: this.settings.termsUrl
    };
}

Subscribe.prototype.init = function (initialData = {}) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Subscribe.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,
                identifier: 'Subscribe',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Subscribe.prototype.initComponentStream = function (initialData = {}) {
    const data = this.getData(initialData);
    const reactElement = data.map((data) => <SubscribeComponent {...data.view} />);
    const iso = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Subscribe.prototype.getData = function (initialData) {
    let trackedAddToCartProduct = false;

    const {specialHeaderImageUrl, sport, vimondEnv} = this.config;
    const searchTerm = this.config.club.toLowerCase().replace(/-/g, ' ');

    const teamDetail = TEAM_ID_NAME_MAP[sport].reduce((foundTeam, {name, id}) => {
        if (foundTeam || name.toLowerCase() !== searchTerm) { // return accumulator if already found or the name isn't the search term
            return foundTeam;
        }

        return {
            id,
            name,
            image: `${CONTENT_URL}/${sport}/2017/teams/member_page/${id}.png`
        };
    }, null);

    const currencyBus = new bacon.Bus();
    const currency$ = bacon.update(
        (teamDetail || specialHeaderImageUrl) ? 'AUD' : this.config.currency, // The only currency is AUD for teams
        [currencyBus], (prev, newValue) => newValue
    );

    const isPubsClubs = this.config.pubsClubsProductGroupId === this.config.productGroupId;

    const voucherProductFormData$ = initialLaterOr(
        false, // @TODO: we need to make this work for server-side
        getVoucherProductFormData({
            currency$,

            voucher: this.config.voucher,
            isVoucherOptional: (teamDetail === null), // voucher is only mandatory for club's page
            productGroupId: this.config.productGroupId,
            chosenProductId: this.config.chosenProductId,

            initialData: get(initialData, 'voucherProductFormData', {}),
            sport,
            vimondEnv
        }));

    const userFormEnabled = voucherProductFormData$
        .map('.validatedVoucherCode')
        .map((validatedVoucherCode) => {
            if (teamDetail && !validatedVoucherCode) {
                return false;
            } else {
                return true;
            }
        })
        .startWith(!teamDetail)
        .skipDuplicates(isEqual);

    const userFormData$ = getUserFormData({
        isEnabled: userFormEnabled,
        dataCollectionUrl: this.config.dataCollectionUrl,
        nrlPrivacyUrl: this.config.nrlPrivacyUrl,
        aflPrivacyUrl: this.config.aflPrivacyUrl,
        privacyUrl: this.config.privacyUrl,
        termsUrl: this.config.termsUrl,

        sport,

        teamDetail, // contains which team to pre-select if any

        vimondEnv
    });

    // Only enable the payment form is we have a user and a current product.
    const paymentFormEnabled = bacon.combineTemplate({
        user: userFormData$.map('.user'),
        currentProduct: voucherProductFormData$.map('.currentProduct')
    })
        .map(({user, currentProduct}) => user && currentProduct);

    const paymentFormData$ = getPaymentFormData({
        isEnabled$: paymentFormEnabled,

        baseUrl: this.config.baseUrl,
        product: voucherProductFormData$.map('.currentProduct'),

        vimondEnv
    })
        // Payment Form Stream shouldn't show if
        // a) We're on a team page (teamDetail)
        // b) isPaymentRequired says no (from voucher + current product)
        .combine(
            voucherProductFormData$.map('.isPaymentRequired'),
            (paymentForm, isPaymentRequired) => {
                const emptyPaymentForm = {};

                if (teamDetail || specialHeaderImageUrl) {
                    return emptyPaymentForm;
                } else if (isPaymentRequired) {
                    return paymentForm;
                } else {
                    return emptyPaymentForm;
                }
            }
        );

    const isPageLoadingBus = new bacon.Bus();

    const isPaymentRequired$ = voucherProductFormData$.map('.isPaymentRequired');

    const subscribe = bacon.combineTemplate({
        validatedVoucherCode: voucherProductFormData$.map('.validatedVoucherCode'),
        currentProduct: voucherProductFormData$.map('.currentProduct'),
        isPaymentRequired: isPaymentRequired$,
        freeAccessPayment: voucherProductFormData$.map('.freeAccessPayment'),
        user: userFormData$.map('.user'),
        currency: currency$,
        payment: paymentFormData$.map('.payment')
    })
        .flatMapLatest(({validatedVoucherCode, currentProduct, isPaymentRequired, freeAccessPayment, user, currency, payment}) => {
            if (
                (isPaymentRequired && currentProduct && user && payment) || // Normal Signup. Product + User + Payment
                (!isPaymentRequired && currentProduct && user && freeAccessPayment) ||
                (specialHeaderImageUrl && currentProduct && user)) { // Free Signup. Product + User.
                isPageLoadingBus.push(true);

                return postOrder({
                    user,
                    product: currentProduct,
                    voucher: isPaymentRequired && validatedVoucherCode,
                    payment: isPaymentRequired ? payment : freeAccessPayment,
                    currency,
                    vimondEnv
                })
                    .map('.order')
                    .flatMapLatest((order) => {
                        const paymentObject = isPaymentRequired ? payment : freeAccessPayment;

                        return putOrder({
                            order,
                            payment: paymentObject,
                            sport,
                            vimondEnv
                        });
                    })
                    .doAction(({order}) => {
                        if (specialHeaderImageUrl) {
                            setLocalStorageValue({
                                key: SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY,
                                value: {
                                    id: get(order, 'productPaymentId'),
                                    startDate: asNumber(moment(get(order, 'startDate')).format('x')), // bloody startDate/endDate are in different timestamp formats sometimes
                                    endDate: asNumber(moment(get(order, 'endDate')).format('x')) // bloody startDate/endDate are in different timestamp formats sometimes
                                },
                                expiresIn: LOCAL_STORAGE_EXPIRES_IN
                            });
                        }

                        registerSale({
                            totalCost: asNumber(get(order, 'price')),
                            orderId: get(order, 'id'),
                            productId: get(order, 'productId'),
                            currency: get(order, 'currency')
                        });
                    })
                    .map(true);
            } else {
                return false;
            }
        });

    const subscribeErrorMessage$ = bacon.mergeAll(
        voucherProductFormData$.map(''),
        userFormData$.map(''),
        paymentFormData$.map(''),

        subscribe
            .errors()
            .mapError('.error.description') // This is where the description really is.
            .map('Payment failed. Please contact support for help.') // This is what we're going live with, as the description is pretty shit.
    );

    const isPageLoading$ = bacon.mergeAll(
        isPageLoadingBus.map(true),
        subscribe.mapError(false).map(false)
    )
        .toProperty(false)
        .skipDuplicates(isEqual);

    const userCreatedTracking = userFormData$
        .map('.userCreated')
        .skipDuplicates(isEqual)
        .doAction((userCreatedDetails) => {
            if (!userCreatedDetails) {
                return;
            }

            const favouriteTeam = find(TEAM_ID_NAME_MAP[sport], {id: parseInt(userCreatedDetails.city)});
            const favouriteTeamName = get(favouriteTeam, 'name', '');

            trackLoggedInStatus(true);
            trackFavouriteTeam(favouriteTeamName);
            trackPersonalDetailsComplete();
        });

    const subscriptionTracking = bacon.combineTemplate({
        success: subscribe,
        isPaymentRequired: voucherProductFormData$.map('.isPaymentRequired'),
        currentProduct: voucherProductFormData$.map('.currentProduct'),
        paymentType: paymentFormData$.map('.paymentType')
    })
        .skipDuplicates(isEqual)
        .doAction(({success, isPaymentRequired, currentProduct, paymentType}) => {
            if (!success) {
                return;
            }

            const subscriptionPackageName = get(currentProduct, 'paymentPlan.name', '');

            if (isPaymentRequired && paymentType) {
                trackPaymentType(paymentType);
            }

            trackSubscriptionPackage(subscriptionPackageName);
            trackSubscriptionComplete();
            trackCurrencyConversion({
                value: get(currentProduct, 'price'),
                currency: get(currentProduct, 'currency')
            });
        });

    // If you're logged in, and have an active order, go to the home page
    const redirectIfActiveOrder = userFormData$
        .map('.activeOrder')
        .doAction((activeOrder) => {
            if (activeOrder && !teamDetail) {
                // If you have an active order, *and* you’re not a Club Member

                redirectPage('/');
            }
        });

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            teamDetail,

            voucherProductFormData: voucherProductFormData$,
            userFormData:           userFormData$,
            paymentFormData:        paymentFormData$,

            isPaymentRequired:      isPaymentRequired$,
            isPubsClubs,
            isLoading:              isPageLoading$,

            specialHeaderImageUrl,
            specialHeaderText:      this.config.specialHeaderText,

            successfullySubscribed: subscribe.startWith(null),
            subscribeErrorMessage:  subscribeErrorMessage$.startWith(''),

            successUrl:             this.config.successUrl,

            sportDetails:           getSportDetails(sport)
        }),
        iso: bacon.combineTemplate({
            voucherProductFormData: voucherProductFormData$
        }),

        subscribe,
        redirectIfActiveOrder,
        subscriptionTracking,
        userCreatedTracking
    }).doAction(() => {
        initialData = {}; // eslint-disable-line no-param-reassign

        // Nasty way of tracking that this has been added to 'cart'.
        // Make sure we only do it once though please.
        if (!trackedAddToCartProduct) {
            trackAddToCart({type: 'product'});
            trackedAddToCartProduct = true;
        }
    });
};

Subscribe.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <SubscribeComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <SubscribeComponent {...data.view} />,
            'hawkwidgets-subscribe',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

Subscribe.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

Subscribe.prototype.pageBoot = function () {
    pageBoot(Subscribe, 'hawkwidgets-subscribe');
};

export default function (element, settings) {
    return new Subscribe(element, settings);
}

pageBoot(Subscribe, 'hawkwidgets-subscribe');
