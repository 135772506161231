import ShakaTech from "./additionalContexts/ShakaTech";
import HlsJsTech from "./additionalContexts/HlsJsTech";
import StreamrootHlsJsTech from "./additionalContexts/StreamrootHlsJsTech";
import DashShakaTech from "./additionalContexts/DashShakaTech";
import DashTech from "./additionalContexts/DashTech";
import ContribHlsTech from "./additionalContexts/ContribHlsTech";
import GenericAdsAdapter from "./ads/GenericAdsAdapter";
import BcoveImaAdsAdapter from "./ads/BcoveImaAdsAdapter";

export default class VideoJsAdapter {
  adsAdapters = {
    bcoveImaAdsAdapter: BcoveImaAdsAdapter,
    genericAdsAdapter: GenericAdsAdapter
  };

  additionalContexts = {
    contribHlsTech: ContribHlsTech,
    dashTech: DashTech,
    dashShakaTech: DashShakaTech,
    hlsJsTech: HlsJsTech,
    streamrootHlsJsTech: StreamrootHlsJsTech,
    shakaTech: ShakaTech
  };


  isInAds(willStop = false) {
    try {
      const adsAdapter = this.getVideo().getAdsAdapter();
      if (willStop && adsAdapter && typeof adsAdapter.isDAI !== 'undefined') {
        if (!adsAdapter.isDAI) {
          // This means we are using Google IMA with CSAI
          return adsAdapter.player.getCuePoints().includes(-1);
        }
      }
      return (this.player.ads && this.player.ads.inAdBreak && (typeof this.player.ads.inAdBreak === 'function') && this.player.ads.inAdBreak());
    } catch (e) {
      return false;
    }
  }

  getBitrate() {
    let ret = null;
    const tech = this.getUsedTech();
    if (tech && tech.getBitrate) {
      ret = tech.getBitrate(this);
    }
    return ret;
  }

  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this.player.el());
    } catch (err) {
      return true;
    }
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    this.firePause({}, 'pauseListener');
  }

  bufferingListener(e) {
    this.firePlayerLog('bufferingListener', {});
    if (!this.flags.isSeeking) {
      this.fireBufferBegin({}, false, 'bufferingListener');
    }
  }

  getRendition() {
    let ret = null;
    const tech = this.getUsedTech();
    if (tech && tech.getRendition) {
      ret = tech.getRendition(this);
    } else if (this.player.videoHeight() && this.player.videoWidth()) {
      ret = this.getNpawUtils().buildRenditionString(this.player.videoHeight(), this.player.videoWidth());
    }
    return ret;
  }

  getPlayerVersion() {
    let ret = null;
    if (typeof videojs !== 'undefined') {
      ret = videojs.VERSION;
    }
    return ret;
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    if (this.isInAds()) {
      return;
    }
    if (!this.flags.isStarted) {
      this.lastSrc = this.getResource();
      this._startEvent('playListenerNotStarted');
    } else {
      if (this.lastSrc && this.lastSrc !== this.getResource()) {
        this.fireStop({}, 'playListenerNewResource');
        this.acumBytesFunc();
        this.lastPlayhead = undefined;
        this._startEvent('playListenerNewResource');
        this.lastSrc = this.getResource();
      }
    }
  }

  loadAdsAdapter() {
    if (this.getVideo()) {
      if (!this.getVideo().getAdsAdapter() || this.getVideo().getAdsAdapter().isGeneric) {
        let adapter = null;
        if (this.getAdapterClass('bcoveImaAdsAdapter').isUsed(this)) {
          adapter = this.getAdapterClass('bcoveImaAdsAdapter');
        } else if (this.getVideo().getAdsAdapter() === null) {
          adapter = this.getAdapterClass('genericAdsAdapter');
          adapter.initProperties();
        }
        if (adapter) {
          this.getVideo().setAdsAdapter(adapter, this.plugin, true);
        }
      } else {
        if (this.getVideo().getAdsAdapter().registerImaEvents) this.getVideo().getAdsAdapter().registerImaEvents();
      }
    }
  }

  errorModifyer(fatal, nonfatal, ignore) {
    this.fatalList = fatal || [];
    this.nonFatalList = nonfatal || [];
    this.ignoreList = ignore || [];
  }

  getPlayrate() {
    return this.player.playbackRate();
  }

  timeupdateListener(e) {
    if (this.isInAds()) {
      return;
    }
    if (this.getPlayhead() > 0.1) {
      this.fireJoin({}, 'timeupdateListener');
      if (this.joinPolling) this.joinPolling.stop();
      if (!this.flags.isPaused && this.lastSeekPlayhead && this.lastSeekPlayhead !== this.getPlayhead()) {
        this.fireSeekEnd({}, 'timeupdateListener');
        this.lastSeekPlayhead = false;
      }
    }
    if (!this.flags.isJoined) {
      if (this.lastPlayhead === undefined) {
        this.lastPlayhead = this.getPlayhead();
      }
      if (this.lastPlayhead !== this.getPlayhead()) {
        if (this.player.options_ && this.plugin && !this.getVideo().options['content.id']) {
          this.getVideo().options['content.id'] = this.player.options_['data-video-id'];
        }
        this.fireStart({}, 'timeupdateListener');
        this.fireJoin({}, 'timeupdateListener');
        if (this.joinPolling) this.joinPolling.stop();
        this.lastPlayhead = undefined;
      }
    }
    if (this.getVideo()._adsAdapter && !this.getVideo()._adsAdapter.flags.isStarted) {
      this.lastPlayhead = this.getPlayhead();
    }
  }

  getPlayhead() {
    let ret = this.player.currentTime();
    if (this.getVideo()._adsAdapter && this.getVideo()._adsAdapter.flags.isStarted) {
      ret = this.lastPlayhead || ret;
    }
    return ret;
  }

  getDuration() {
    var ret = this.player.duration();
    if (this.player.mediainfo && typeof this.player.mediainfo.duration !== 'undefined') {
      ret = this.player.mediainfo.duration
    };
    return ret
  }

  aderrorListener(e) {
    this.loadAdsAdapter();
    if (this.getVideo().getAdsAdapter() && this.getVideo().getAdsAdapter().adPlayerErrorListener) {
      this.getVideo().getAdsAdapter().adPlayerErrorListener(e)
    }
  }

  _jointimePolling(e) {
    if (this.getVideo()) {
      if (!this.getVideo().getAdsAdapter() || !this.getVideo().getAdsAdapter().flags.isStarted) {
        if (this.lastPlayhead !== 0 && typeof this.lastPlayhead === 'number' && this.lastPlayhead !== this.getPlayhead()) {
          this.fireJoin({}, 'jointimePolling');
          if (this.joinPolling) {
            this.joinPolling.stop()
          }
        };
        this.lastPlayhead = this.getPlayhead()
      }
    } else if (this.joinPolling) {
      this.joinPolling.stop()
    }
  }

  acumBytesFunc() {
    this.acumBytes += this.getTotalBytes() || 0
  }

  adsEndedListener(e) {
    this.firePlayerLog('adsEndedListener', {});
    this.adsEnded = true;
    if (this.getVideo().requestBuilder.lastSent.adPosition === this.getNpawReference().Constants.AdPosition.Postroll) {
      this.getVideo().getAdapter().fireStop({}, 'adsEndedListener');
      this.acumBytesFunc();
      this.lastPlayhead = undefined;
    }
  }

  getVersion() {
    return '7.0.10-videojs-jsclass'
  }

  seekedListener(e) {
    this.firePlayerLog('seekedListener', {});
    this.fireSeekEnd({}, 'seekedListener');
    this.lastSeekPlayhead = this.getPlayhead()
  }

  getTech() {
    return this.player.tech({
      IWillNotUseThisInPlugins: true
    })
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      try {
        for (var key in this.references) {
          this.player.off(key, this.references[key])
        }
      } catch (err) { };
      delete this.references
    }
  }

  _startEvent(triggerListener) {
    this.loadAdsAdapter();
    if (this.player.options_ && this.plugin && !this.plugin.options['content.id']) {
      this.plugin.options['content.id'] = this.player.options_['data-video-id']
    };
    this.fireStart({}, triggerListener);
    if (!this.flags.isJoined) {
      if (!this.joinPolling) {
        this.joinPolling = new (this.getNpawReference()).Timer(this._jointimePolling.bind(this), 500)
      };
      this.joinPolling.start()
    }
  }

  getIsLive() {
    var ret = null;
    if (this.player.mediainfo) {
      ret = true;
      if (typeof this.player.mediainfo.duration === 'number') {
        ret = (this.player.mediainfo.duration < 1 || this.player.mediainfo.duration === Infinity)
      }
    };
    if (this.player.duration && (typeof this.player.duration === 'function')) {
      ret = true;
      var duration = this.player.duration();
      if (typeof duration === 'number') {
        ret = (duration < 1 || duration === Infinity)
      }
    };
    if (this.player.isLive && this.player.isLive === 'function') {
      ret = this.player.isLive()
    };
    return ret
  }

  conditionalStop(e) {
    this.firePlayerLog('conditionalStop', {});
    if (this.isInAds(true)) {
      return
    }
    if (this.plugin) {
      this.adsEnded = false;
      this.fireStop({}, 'conditionalStop');
      if (this.plugin && !this.getVideo().options['content.id']) {
        this.getVideo().options['content.id'] = null
      };
      this.acumBytesFunc();
      this.lastPlayhead = undefined
    }
  }

  getTitle() {
    var ret = null;
    if (this.player.mediainfo) {
      ret = this.player.mediainfo.name
    };
    return ret
  }

  getTotalBytes() {
    var ret = null;
    var tech = this.getUsedTech();
    if (tech && tech.getTotalBytes) {
      ret = tech.getTotalBytes(this)
    };
    return ret
  }

  registerListeners() {
    this.monitorPlayhead(false, false, 1200);
    this.acumBytes = 0;
    this.ignoreList = [];
    this.fatalList = [];
    this.nonFatalList = [];
    this.references = {
      loadstart: this.loadstartListener.bind(this),
      adsready: this.loadAdsAdapter.bind(this),
      play: this.playListener.bind(this),
      timeupdate: this.timeupdateListener.bind(this),
      pause: this.pauseListener.bind(this),
      playing: this.playingListener.bind(this),
      ended: this.conditionalStop.bind(this),
      dispose: this.disposeListener.bind(this),
      seeking: this.seekingListener.bind(this),
      seeked: this.seekedListener.bind(this),
      error: this.errorListener.bind(this),
      adserror: this.aderrorListener.bind(this),
      adsEnded: this.adsEndedListener.bind(this),
      texttrackchange: this.textListener.bind(this),
      waiting: this.bufferingListener.bind(this)
    };
    if (this.player) {
      for (var key in this.references) {
        this.player.on(key, this.references[key])
      }
    };
    setTimeout(this.loadAdsAdapter.bind(this), 50)
  }

  getResource() {
    var ret = null;
    var tech = this.getUsedTech();
    if (tech && tech.getResource) {
      ret = tech.getResource(this)
    };
    if (!ret) {
      ret = this.player.currentSrc()
    };
    return ret
  }

  textListener(e) {
    if (this.player.textTracks) {
      var tracks = this.player.textTracks();
      var len = tracks.length;
      for (var i = 0; i < len; ++i) {
        if (tracks[i] && tracks[i].mode === 'showing') {
          this.getVideo().options['content.subtitles'] = tracks[i].label || tracks[i].language
        }
      }
    }
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener')
  }

  disposeListener(e) {
    this.firePlayerLog('disposeListener', {});
    if (this.plugin) {
      this.getVideo().fireStop({}, 'disposeListener');
      this.getVideo().removeAdsAdapter();
      this.getVideo().removeAdapter()
    }
  }

  loadstartListener(e) {
    this.firePlayerLog('loadstartListener', {});
    this.loadAdsAdapter()
  }

  getPlayerName() {
    var name = 'videojs';
    if (this.getAdapterClass('contribHlsTech').isUsed(this)) {
      name += '-hls'
    } else if (this.getAdapterClass('hlsJsTech').isUsed(this)) {
      name += '-hlsjs'
    } else if (this.getAdapterClass('shakaTech').isUsed(this)) {
      name += '-shaka'
    } else if (this.getAdapterClass('dashShakaTech').isUsed(this)) {
      name += '-dash-shaka'
    } else if (this.getAdapterClass('dashTech').isUsed(this)) {
      name += '-dash'
    };
    if (this.player.FreeWheelPlugin) {
      name += '-fw'
    };
    if (this.player.mediainfo) {
      name += '-bcove'
    };
    return name
  }

  playingListener(e) {
    this.firePlayerLog('playingListener', {});
    this._startEvent('playingListener');
    if (!this.isInAds() && !this.flags.isAdPaused) {
      this.fireResume({}, 'playingListener');
    };
    if (this.getPlayhead() < 1) {
      this.fireSeekEnd({}, 'playingListener');
    };
    this.fireBufferEnd({}, 'playingListener');
  }

  getUsedTech() {
    var ret = null;
    if (this.getPlugin().getAdaptersProperties().usedTech) {
      switch (this.getPlugin().getAdaptersProperties().usedTech.toLowerCase()) {
        case 'hls':
          if (this.getAdapterClass('contribHlsTech').isUsed(this)) {
            ret = this.getAdapterClass('contribHlsTech')
          };
          break;
        case 'hlsjs':
          if (this.getAdapterClass('hlsJsTech').isUsed(this)) {
            ret = this.getAdapterClass('hlsJsTech')
          };
          break;
        case 'shaka':
          if (this.getAdapterClass('shakaTech').isUsed(this)) {
            ret = this.getAdapterClass('shakaTech')
          };
          break;
        case 'dashshaka':
          if (this.getAdapterClass('dashShakaTech').isUsed(this)) {
            ret = this.getAdapterClass('dashShakaTech')
          };
          break;
        case 'streamrootHlsJsTech':
          if (this.getAdapterClass('streamrootHlsJsTech').isUsed(this)) {
            ret = this.getAdapterClass('streamrootHlsJsTech')
          };
          break;
        case 'dash':
          if (this.getAdapterClass('dashTech').isUsed(this)) {
            ret = this.getAdapterClass('dashTech')
          };
          break
      };
      if (ret != null) {
        return ret
      }
    };
    if (this.getAdapterClass('contribHlsTech').isUsed(this)) {
      ret = this.getAdapterClass('contribHlsTech');
    } else if (this.getAdapterClass('hlsJsTech').isUsed(this)) {
      ret = this.getAdapterClass('hlsJsTech');
    } else if (this.getAdapterClass('shakaTech').isUsed(this)) {
      ret = this.getAdapterClass('shakaTech');
    } else if (this.getAdapterClass('dashShakaTech').isUsed(this)) {
      ret = this.getAdapterClass('dashShakaTech');
    } else if (this.getAdapterClass('dashTech').isUsed(this)) {
      ret = this.getAdapterClass('dashTech');
    } else if (this.getAdapterClass('streamrootHlsJsTech').isUsed(this)) {
      ret = this.getAdapterClass('streamrootHlsJsTech');
    }
    return ret
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    var ignore = false;
    var fatal = false;
    var code = null;
    var msg = null;
    if (this.player.error && this.player.error()) {
      code = Number(this.player.error().code);
      msg = this.player.error().message;
    };
    if (code !== null) {
      if (this.ignoreList.includes(code)) {
        ignore = true
      };
      if (!fatal && this.fatalList.includes(code)) {
        fatal = true
      } else if (fatal && this.nonFatalList.includes(code)) {
        fatal = false
      }
    };
    if (!ignore) {
      if (fatal) {
        this.fireFatalError(code, msg, undefined, undefined, 'errorListener');
        this.acumBytesFunc();
        this.lastPlayhead = undefined;
        this.crashed = true
      } else {
        this.fireError(code, msg, undefined, undefined, 'errorListener')
      }
    }
  }
}