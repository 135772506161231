import {isBrowser} from './environment';

const loadedStyles = new Set<string>();
const loadedScripts = new Set<string>();
const pendingScriptPromises = new Map<string, Promise<Event>>();

let exportLoadedStyles: Set<string>;
let exportLoadedScripts: Set<string>;
let exportPendingScriptPromises: Map<string, Promise<Event>>;

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'test') {
    exportLoadedStyles = loadedStyles;
    exportLoadedScripts = loadedScripts;
    exportPendingScriptPromises = pendingScriptPromises;
}

// These guys exported ONLY for testing purposes. They will use empty stubs when env !== 'test'
// We're assuming they've been assigned if tests are running - an assumption that breaks if NODE_ENV !== 'test'
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const _loadedStyles = exportLoadedStyles!;
export const _loadedScripts = exportLoadedScripts!;
export const _pendingScriptPromises = exportPendingScriptPromises!;
/* eslint-enable @typescript-eslint/no-non-null-assertion */

type CrossOrigin = 'anonymous' | 'use-credentials' | '' | null;

type BaseLoadArgs = {
    crossOrigin?: CrossOrigin;
    id?: string;
    integrity?: string;
    skipCache?: boolean;
    url: string;
};

type LoadStyleArgs = BaseLoadArgs & {
    media?: string;
};

/**
 * Clientside lazy loading of stylesheet resources.
 *
 * @remarks
 * This will be ignored on the server.
 *
 * @param crossOrigin - crossOrigin attribute, e.g. `"anonymous"` or `"use-credentials"`
 * @param id          - ID attribute to use on the link element
 * @param integrity   - subresource integrity hash attribute
 * @param media       - inline media query attribute for conditional stylesheets
 * @param skipCache   - should cache be bypassed to force additional placement of link element with duplicate URLs?
 * @param url         - URL from which the stylesheet should be loaded
 * @returns promise resolving with emitted event from onload, or rejecting with emitted onerror
 */
export async function loadStyle({
    crossOrigin = null,
    id = '',
    integrity = '',
    media = '',
    skipCache = false,
    url,
}: LoadStyleArgs): Promise<Event | undefined> {
    if (
        !isBrowser() || // don't lazy load stuff on the server
        (skipCache === false && loadedStyles.has(url)) // we've already loaded this stylesheet
    ) {
        return;
    }

    loadedStyles.add(url); // we're not as fussy as <script> here for whatever reason?

    return new Promise<Event>((onload, onerror) => {
        document.head.appendChild(
            Object.assign<HTMLLinkElement, Partial<HTMLLinkElement>>(
                document.createElement('link'),
                {
                    crossOrigin,
                    href: url,
                    id,
                    integrity,
                    media,
                    onerror,
                    onload,
                    rel: 'stylesheet',
                }
            )
        );
    });
}

type LoadScriptArgs = BaseLoadArgs & {
    async?: boolean;
    referrerPolicy?: ReferrerPolicy;
    type?: string;
};

/**
 * Clientside lazy loading of javascript resources. Will be ignored on the server
 *
 * @param async          - async attribute. set this to `false` to force synchronous evaluation for injected <script>
 * @param crossOrigin    - crossOrigin attribute, e.g. "anonymous" or "use-credentials"
 * @param id             - ID attribute to use on the <script>. This will also cause de-duping so ensure that they are unique
 * @param integrity      - subresource integrity hash attribute
 * @param referrerPolicy - referrerPolicy attribute. use this to control referrer header information provided with script network request
 * @param skipCache      - should cache be bypassed to force additional placement of <script> element with duplicate URLs?
 * @param type           - the script type (e.g. 'text/javascript')
 * @param url            - URL from which the stylesheet should be loaded
 * @returns promise resolving with emitted event from onload, or rejecting with emitted onerror
 */
export async function loadScript({
    async = false,
    crossOrigin = null,
    id = '',
    integrity = '',
    referrerPolicy = '',
    skipCache = false,
    type = '',
    url,
}: LoadScriptArgs): Promise<Event | undefined> {
    if (
        !isBrowser() || // don't lazy load on the server
        (id && document.getElementById(id)) || // you gave us an ID but it already exists. we're going to play it safe
        (skipCache === false && loadedScripts.has(url)) // the script is already loaded and you're not telling us to bypass cache
    ) {
        return;
    }

    const pendingPromise = pendingScriptPromises.get(url);

    if (pendingPromise) {
        // There is a pending request already. Return that promise
        return pendingPromise;
    }

    const scriptLoadPromise = new Promise<Event>((onload, onerror) => {
        const script = document.createElement('script');

        script.async = async;
        script.crossOrigin = crossOrigin;
        script.id = id;
        script.integrity = integrity;
        script.onerror = onerror;
        script.onload = onload;
        script.referrerPolicy = referrerPolicy;
        script.src = url;
        script.type = type;

        document.head.appendChild(script);
    })
        .then((event) => {
            loadedScripts.add(url);

            return event;
        })
        .finally(() => {
            pendingScriptPromises.delete(url);
        });

    pendingScriptPromises.set(url, scriptLoadPromise);

    return scriptLoadPromise;
}

export function purgeLoadedStyles(): void {
    // for testing purposes
    loadedStyles.clear();
}

export function purgeScriptRequests(): void {
    // for testing purposes
    const outstandingPromises = Object.keys(pendingScriptPromises);

    if (outstandingPromises.length) {
        throw `Promises still registered: ${outstandingPromises.join(', ')}`;
    }

    loadedScripts.clear();
}
