type Value = string | number | null | boolean;

/**
 * Returns the number of decimal places for given value
 *
 * Returns 0 for any invalid value
 *
 * @param val - value for which decimal places need to be count (e.g. string, number etc.)
 *
 * @returns number of deciaml places in value
 */
export default function getDecimalPlaces(value: Value): number {
    const match = `${value}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    if (!match) {
        return 0;
    }

    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0) - (match[2] ? Number(match[2]) : 0)
    );
}
