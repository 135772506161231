import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {red, white} from '../../../../common/palette';

export type Props = {
    /** Colour for the LIVE text */
    color?: string;
    textColor?: string;
} & IcProps;

const IC20Live = ({
    color = red,
    textColor = white,
    ...otherProps
}: Props): React.ReactElement => (
    <Ic {...{...otherProps, color}}>
        <path
            fill={color}
            d="M0 0H73.042V43.25H0z"
            transform="translate(13 29)"
        />
        <path
            fill={textColor}
            d="M24.692 27.864L16.867 27.864 16.867 14.365 20.025 14.365 20.025 25.251 24.692 25.251zM30.072 27.864L26.9 27.864 26.9 14.365 30.072 14.365zM45.052 14.365L40.036 27.864 37.017 27.864 32.014 14.365 35.382 14.365 38.54 23.658 41.698 14.365zM55.896 27.864L46.981 27.864 46.981 14.365 55.728 14.365 55.728 16.895 50.139 16.895 50.139 19.689 55.421 19.689 55.421 22.219 50.125 22.219 50.125 25.349 55.896 25.349z"
            transform="translate(13 29)"
        />
    </Ic>
);

IC20Live.displayName = 'IC20Live';

export default IC20Live;
