import result from 'lodash/result';

export default function getRugbySeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'super-rugby':
        case '1':
            return {
                id: 1,
                label: 'Super Rugby',
                route: 'super-rugby'
            };

        case 'internationals':
        case '2':
            return {
                id: 2,
                label: 'Internationals',
                route: 'internationals'
            };

        case 'world-cup':
        case '3':
            return {
                id: 3,
                label: 'World Cup',
                route: 'world-cup'
            };

        case 'rugby-championship':
        case '4':
            return {
                id: 4,
                label: 'Rugby Championship',
                route: 'rugby-championship'
            };

        case 'six-nations':
        case '5':
            return {
                id: 5,
                label: 'Six Nations',
                route: 'six-nations'
            };

        case 'itm-cup':
        case '7':
            return {
                id: 7,
                label: 'ITM Cup',
                route: 'itm-cup'
            };

        case 'national-rugby-championships':
        case '9':
            return {
                id: 9,
                label: 'National Rugby Championships',
                route: 'national-rugby-championships'
            };

        case 'irb-world-sevens':
        case '10':
            return {
                id: 10,
                label: 'Sevens World Series',
                route: 'irb-world-sevens'
            };

        case 'brisbane-tens':
        case '11':
            return {
                id: 11,
                label: 'Brisbane 10s',
                route: 'brisbane-tens'
            };

        case 'womens-sevens':
        case '12':
            return {
                id: 12,
                label: 'Women\'s Sevens Series',
                route: 'womens-sevens'
            };

        case 'womens-tens':
        case '13':
            return {
                id: 13,
                label: 'Women\'s 10s',
                route: 'womens-tens'
            };

        case 'u20s-championship':
        case '14':
            return {
                id: 14,
                label: 'Under 20\'s',
                route: 'u20s-championship'
            };

        case 'womens-world-cup':
        case '15':
            return {
                id: 15,
                label: 'World Cup Women\'s',
                route: 'womens-world-cup'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Rugby seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
