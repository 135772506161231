import React from 'react';
import {createRoot} from 'react-dom/client';
import bacon from 'baconjs';
import moment from 'moment';
import get from 'lodash/get';

import {asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import getLadder from '../streams/ladder';
import LadderComponent from '../components/ladder';

const EXPIRY_SECONDS = 15; // Typically 30 seconds.

function Ladder(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        apiKey:           this.settings.apiKey,
        bettingProvider:  this.settings.bettingProvider,
        seasonId:         this.settings.seasonId,
        seriesId:         asNumber(this.settings.seriesId),
        sport:            this.settings.sport || 'afl',
        hideTableCaption: this.settings.hideTableCaption
    };
}

Ladder.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Ladder.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'HAWK: Ladder',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

Ladder.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <LadderComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Ladder.prototype.getData = function (initialData) {
    const {apiKey, bettingProvider, seasonId, seriesId, sport, hideTableCaption} = this.config;

    const ladderStream = initialLaterOr(
        initialData && initialData.ladderData,
        getLadder({
            apiKey,
            bettingProvider,
            seasonId,
            seriesId,
            sport
        })
    );

    const lastUpdated = get(initialData, 'lastUpdated', moment().format());

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            hideTableCaption,

            ladderData: ladderStream,

            lastUpdated,

            seriesId,

            sportDetails: getSportDetails(this.config.sport)
        }),
        iso: bacon.combineTemplate({
            ladderData: ladderStream,
            lastUpdated
        })
    });
};

Ladder.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <LadderComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <LadderComponent {...data.view} />,
            'hawkwidgets-ladder',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

/**
 * Removes the widget from the page and closes streams
 *
 * @return {undefined}
 */
Ladder.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new Ladder(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(Ladder, 'hawkwidgets-ladder');
