import React from 'react';
import {createRoot} from 'react-dom/client';
import bacon from 'baconjs';
import merge from 'lodash/merge';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';

import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {USER_LOCAL_STORAGE_KEY, DEFAULT_VIMOND_ENV} from '../utils/constants';
import {redirectNoLogin} from '../utils/auth';
import getUserStream from '../streams/user/user-get';
import getUserOrdersStream from '../streams/user/user-get-orders';
import getUserFutureOrdersStream from '../streams/user/user-get-future-orders';
import getUserOrdersHistoryStream from '../streams/user/user-get-orders-history';
import getUserTransactionsStream from '../streams/user/user-get-transactions';
import getProducts from '../streams/products/get-products';
import stopSubscription from '../streams/order/put-order-stop';
import ManageSubscriptionComponent from '../components/manage-subscription';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function ManageSubscription(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        profilePageLink: this.settings.profilePageLink,
        resubscribeUrl: this.settings.resubscribeUrl,
        supportUrl: this.settings.supportUrl,
        loginRoute: this.settings.loginRoute,

        vimondEnv: this.settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

ManageSubscription.prototype.init = function (initialData = false) {
    redirectNoLogin(() => {
        this.closeStreams = this.getData(initialData).onValue(this.render.bind(this));
    }, {loginRoute: this.config.loginRoute});
};

ManageSubscription.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: ManageSubscription',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

ManageSubscription.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);

    const reactElement = data.map((data) => <ManageSubscriptionComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

ManageSubscription.prototype.getData = function (initialData) {
    if (!process || !process.browser) {
        return bacon.later(0, {
            view: {
                sportDetails: getSportDetails(this.config.sport)
            },
            iso: {}
        });
    }

    const {sport, vimondEnv, profilePageLink, resubscribeUrl, supportUrl} = this.config;

    const updateUserBus = new bacon.Bus();
    const doStopSubBus = new bacon.Bus();

    const initialUserStream = getUserStream({sport, vimondEnv});
    const currentUser = initialUserStream.combine(updateUserBus.toProperty(null), merge);
    const user = getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY});

    const currentOrder$ = getUserOrdersStream({
        sport,
        userId: user.id,
        vimondEnv
    })
        .map((activeOrders) => first(activeOrders))
        .startWith({});

    const futureOrder$ = getUserFutureOrdersStream({
        sport,
        userId: user.id,
        vimondEnv
    })
        .map((futureOrders) => first(futureOrders))
        .startWith({});

    const activeOrder$ = bacon.combineWith(
        currentOrder$, futureOrder$,
        (currentOrder, futureOrder) => isEmpty(currentOrder) ? futureOrder : currentOrder
    )
        .startWith({});

    const activeOrderErrorStream = bacon.mergeAll(
        activeOrder$.errors().mapError(true),
        activeOrder$.map(isEmpty)
    );

    const orderHistory = getUserOrdersHistoryStream({
        sport,
        userId: user.id,
        vimondEnv
    }).startWith([]);

    const transactions = activeOrder$
        .combine(orderHistory, (order, orders) => (orderBy([order, ...orders], 'registered', 'desc')))
        .flatMapLatest((orders) => (
            bacon.combineAsArray(
                orders.map((order) => isEmpty(order) ? false : getUserTransactionsStream({
                    orderId: order.id,
                    sport,
                    userId: user.id,
                    vimondEnv
                }))
            )
        ))
        .map((transactionsLists) => flatten(transactionsLists).filter(Boolean))
        .startWith([]);

    const stopSubStream = doStopSubBus
        .flatMapLatest(stopSubscription)
        .startWith(null);

    const stopSubSuccessStream = stopSubStream
        .map((activeOrder) => activeOrder)
        .startWith(null);

    const stopSubErrorStream = stopSubStream
        .filter(false)
        .mapError('.message')
        .startWith(null);

    const isLoading = bacon.mergeAll(
        doStopSubBus.map(true),
        stopSubStream.map(false),
        stopSubErrorStream.map(false)
    );

    const activeProductStream = activeOrder$
        .flatMapLatest((activeOrder) => {
            if (initialData && initialData.activeProductStream) {
                return bacon.later(0, initialData.activeProductStream);
            } else {
                if (!isEmpty(activeOrder)) {
                    const {currency, productGroupUri} = activeOrder;

                    return getProducts({
                        currency,
                        productStatus: '', // User can be subscribed to a product before it became unavailable
                        productGroupUri: productGroupUri.uri + '/products',
                        sport,
                        vimondEnv
                    });
                }

                return bacon.later(0, null);
            }
        });

    const productIdStream = activeOrder$.map('.productId');

    const productEndDateStream = initialLaterOr(
        initialData && initialData.productEndDateStream,
        bacon.combineWith(
            activeProductStream, productIdStream,
            (activeProducts, productId) => find(activeProducts, {id: productId})
        )
            .map('.endDate')
    );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            currentUser,
            activeOrder: activeOrder$,
            activeOrderError: activeOrderErrorStream,
            productEndDate: productEndDateStream,
            profilePageLink,
            transactions,
            stopSubOrder: stopSubSuccessStream,
            stopSubscription: ({orderId}) => {
                doStopSubBus.push({orderId, sport, vimondEnv});
            },
            resubscribeUrl,
            supportUrl,
            isLoading: isLoading.toProperty(false),
            sportDetails: getSportDetails(this.config.sport)
        }),
        iso: bacon.combineTemplate({
            activeProductStream,
            productEndDateStream
        })
    }).doAction(() => {
        initialData = null; // eslint-disable-line no-param-reassign
    });
};

ManageSubscription.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <ManageSubscriptionComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <ManageSubscriptionComponent {...data.view} />,
            'hawkwidgets-manage-subscription',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

ManageSubscription.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

ManageSubscription.prototype.pageBoot = function () {
    pageBoot(ManageSubscription, 'hawkwidgets-manage-subscription');
};

export default function (element, settings) {
    return new ManageSubscription(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(ManageSubscription, 'hawkwidgets-manage-subscription');
