import result from 'lodash/result';

export default function getGridironSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'nfl':
        case '271':
            return {
                id: 271,
                label: 'NFL',
                route: 'nfl'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Gridiron seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
