import superagent from 'superagent';
import get from 'lodash/get';
import trimStart from 'lodash/trimStart';

import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';
import {streamFromApiWithRetry, getVimondHeaders} from '../../utils/api';

export default function ({
    product,
    sport = 'afl',
    voucher,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const productPaymentsUri = trimStart(get(product, 'productPaymentsUri.uri', ''), '/');
    const queryString = voucher ? `?voucherCode=${voucher.code}` : '';
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}${productPaymentsUri}${queryString}`;

    const apiCall = () => superagent
        .get(url)
        .set(getVimondHeaders({sport, version: 2}));

    return streamFromApiWithRetry({apiCall}).flatMapLatest('.body');
}
