import querystring from 'querystring';

/**
 * Pull out undefined properties before stringifying using node's querystring.
 * Note that empty string, null or empty object will all be unaffected
 *
 * @param  {Object} argsObj Object containing properties which may be undefined
 * @return {String}         querystring.stringify without undefined properties
 */
export default function nicerStringify(argsObj) {
    if (!argsObj) {
        return '';
    }

    const noUndefinedProperties = Object.keys(argsObj)
        .reduce((returnObj, key) => {
            if (argsObj[key] !== undefined) {
                returnObj[key] = argsObj[key];
            }

            return returnObj;
        }, {});

    return querystring.stringify(noUndefinedProperties);
}
