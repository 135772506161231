import type {PlayerState} from '@fsa-streamotion/player-state';

import React from 'react';

export const PlayerContext = React.createContext<PlayerState | null>(null);

/**
 * @returns An instance of the current player state
 */
export const usePlayerContext = (): PlayerState | null =>
    React.useContext(PlayerContext);
