import {css} from 'styled-components';

import fuzzyTest from './util/fuzzy-test';
import type {
    BaseProps,
    CssStrings,
    CssInterpolationArgs,
    CssInterpolationResult,
    CssInterpolations,
    Predicate,
} from './util/types';

type Result<P extends BaseProps> = (
    ...ifArgs: CssInterpolationArgs<P>
) => (
    ...esleArgs: CssInterpolationArgs<P>
) => (props: P) => CssInterpolationResult<P>;

/**
 * This combines <code>stylesWhen</code> and <code>stylesWhenNot</code> into a single operation.
 *
 * @example Property Extractor
 * ```
 * type Props = {
 *     someProperty?: boolean; // doesn't have to be boolean - any type will do
 * };
 *
 * const MyStyledDiv = styled.div<Props>`
 *     ${stylesIfElse<Props>('someProperty')`
 *         color: red;
 *     ``
 *         color: blue;
 *     `}
 * `;
 * ```
 *
 * @example Props test callback
 * ```
 * type Props = {
 *     someOtherProperty: number;
 * };
 *
 * const MyStyledDiv = styled.div<Props>`
 *     ${stylesIfElse<Props>(({someOtherProperty}) => someOtherProperty > 10)`
 *         font-weight: bold;
 *     ``
 *         text-decoration: underline;
 *     `}
 * `;
 * ```
 *
 * @example Nesting
 * ```
 * type Props = {
 *     someProperty?: boolean; // doesn't have to be boolean - any type will do
 *     someOtherProperty: number;
 * };
 *
 * const MyStyledDiv = styled.div<Props>`
 *     ${stylesIfElse<Props>(({someOtherProperty}) => someOtherProperty < 10)`
 *         font-weight: bold;
 *
 *         ${stylesIfElse<Props>('someProperty')`
 *             text-decoration: underline;
 *         ``
 *             font-style: italic;
 *         `}
 *     ``
 *         color: red;
 *     `}
 *  `;
 * ```
 *
 * @typeParam P - React component props
 * @param test - test to apply to conditionally apply styles
 * @returns `styled-components` interpolation function
 */
export default function stylesIfElse<P extends BaseProps>(
    test: string | Predicate<P>
): Result<P> {
    return (
            cssStringsIf: CssStrings<P>,
            ...cssInterpolationsIf: CssInterpolations<P>
        ) =>
        (
            cssStringsElse: CssStrings<P>,
            ...cssInterpolationsElse: CssInterpolations<P>
        ) =>
        (props: P) =>
            fuzzyTest({test, props})
                ? css<P>(cssStringsIf, ...cssInterpolationsIf)
                : css<P>(cssStringsElse, ...cssInterpolationsElse);
}
