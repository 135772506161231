import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = {
    textColor?: string;
} & IcProps;

const IC152MultiAdd2 = ({
    textColor = black,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M41.63 0C18.638 0 0 18.638 0 41.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C83.252 18.642 64.618.009 41.629 0z"
            transform="translate(8 8)"
        />
        <path
            fill={textColor}
            d="M54.356 58.605V52.5H38.728l6.984-6.3c2.686-2.393 7.081-5.91 7.081-11.819 0-5.909-4.64-9.962-12.258-9.962-7.619 0-11.77 4.541-12.21 5.567l3.322 5.177c1.855-2.344 4.786-4.542 8.155-4.542 3.321 0 5.421 1.856 5.421 4.737 0 2.882-1.904 5.08-5.079 7.96L28.716 53.624l.928 4.982h24.712z"
            transform="translate(8 8)"
        />
    </Ic>
);

IC152MultiAdd2.displayName = 'IC152MultiAdd2';

export default IC152MultiAdd2;
