import bacon from 'baconjs';
import dataCollector from 'braintree-web/data-collector';

export default function (configuration) {
    return bacon.fromBinder((sink) => {
        dataCollector.create(configuration, (error, dataCollectorInstance) => {
            if (error) {
                console.error('Braintree error: DataCollector', error);

                sink(new bacon.Error(error));
            } else {
                sink(dataCollectorInstance);
                sink(new bacon.End());
            }
        });
    });
}
