import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC16Set = (args: IcProps): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M26.46 2.76H14.61c-.45 0-.84.36-.84.84v8.34c0 .45.36.84.84.84h11.85c.45 0 .84-.36.84-.84V3.57c0-.45-.39-.81-.84-.81zm-.84 8.31h-10.2V4.41h10.2v6.66zm-9.72 6.15H3.12a.41.41 0 0 0-.42.42v9.21c0 .24.18.42.42.42H15.9c.24 0 .42-.18.42-.42v-9.21a.43.43 0 0 0-.42-.42zm-.42 9.21H3.54v-8.37h11.94v8.37zM3.27 14.94h1.68c.03-3.42 1.74-6.42 4.5-8.13L8.97 9.6l1.23.21.78-4.47c.03-.15 0-.33-.09-.48a.59.59 0 0 0-.39-.27L6 3.87 5.76 5.1l2.58.45C5.25 7.59 3.3 11.1 3.27 14.94zm21.81-.06c-.03 3.42-1.74 6.42-4.5 8.13l.48-2.79-1.23-.21-.78 4.47c-.06.33.18.66.51.72l4.47.78.21-1.23-2.58-.45c3.12-2.07 5.07-5.58 5.1-9.42h-1.68z"
            fillRule="evenodd"
            transform="scale(3.3)"
        />
    </Ic>
);

IC16Set.displayName = 'IC16Set';

export default IC16Set;
