import clamp from 'lodash/clamp';
import {useCallback, useRef} from 'react';

import newCustomEvent from './util/new-custom-event';
import round from './util/round';

const EVENT_TYPES = {
    start: 'touchstart',
    move: 'touchmove',
    end: 'touchend',
} as const;

type RangeTouchRef = (inputElement: HTMLInputElement | null) => void;

type Args = {
    thumbWidth?: number;
};

/**
 * ✨ Magical hook to use with input[type=range] to make iOS Safari sliders play nice 🦄
 *
 * @remarks
 * Known issues
 *
 * - Doesn't handle vertical orientation
 *
 * Adapted from RangeTouch
 *
 * @param thumbWidth - width of the range control's thumb
 * @returns the ref to pass to the range input
 * @see https://github.com/sampotts/rangetouch/blob/master/src/js/rangetouch.js
 *
 * @example
 * ```
 * const ref = useRangeTouch();
 *
 * return (
 *     <input
 *         ref={ref}
 *         type="range"
 *         style={{
 *             userSelect: 'none',
 *             webkitUserSelect: 'none',
 *             touchAction: 'manipulation',
 *         }}
 *     />
 * );
 * ```
 */
export default function useRangeTouch({thumbWidth = 15}: Args): RangeTouchRef {
    const rangeTouchRef = useRef<HTMLInputElement | null>();

    const calculateValue = useCallback(
        function calculateValueImpl(event: TouchEvent): number {
            const input = event.target as HTMLInputElement;
            const [touch] = event.changedTouches;
            const min = input.min ? parseFloat(input.min) : 0;
            const max = input.max ? parseFloat(input.max) : 0;
            const step =
                input.step && input.step !== 'any' ? parseFloat(input.step) : 1;
            const delta = max - min;

            // Calculate percentage
            const clientRect = input.getBoundingClientRect();
            const effectiveThumbWidth =
                ((100 / clientRect.width) * ((thumbWidth || 0) / 2)) / 100;
            let percent = clamp(
                (100 / clientRect.width) *
                    ((touch?.clientX || 0) - clientRect.left),
                0,
                100
            );

            // Factor in the thumb offset
            if (percent < 50) {
                percent -= (100 - percent * 2) * effectiveThumbWidth;
            } else if (percent > 50) {
                percent += (percent - 50) * 2 * effectiveThumbWidth;
            }

            // Find the closest step to the mouse position
            return min + round(delta * (percent / 100), step);
        },
        [thumbWidth]
    );

    const onTouchEvent = useCallback(
        function onTouchEventImpl(event: TouchEvent): void {
            // If not enabled, bail
            if (event.target && (event.target as HTMLInputElement).disabled) {
                return;
            }

            event.preventDefault();

            // Set value - can't set event.target.value directly :(
            const input = event.target as HTMLInputElement;

            Object.getOwnPropertyDescriptor(
                Object.getPrototypeOf(input),
                'value'
            )?.set?.call(input, calculateValue(event));

            // Trigger input event
            rangeTouchRef.current?.dispatchEvent(
                newCustomEvent(
                    event.type === EVENT_TYPES.end ? 'change' : 'input',
                    {bubbles: true}
                )
            );
        },
        [calculateValue]
    );

    return useCallback(
        function rangeTouchCallbackRef(
            inputElement: HTMLInputElement | null
        ): void {
            const previousInputElement = rangeTouchRef.current;

            // Clean up previous element's listeners
            if (previousInputElement) {
                for (const eventType of Object.values(EVENT_TYPES)) {
                    previousInputElement.removeEventListener(
                        eventType,
                        onTouchEvent
                    );
                }
            }

            rangeTouchRef.current = inputElement;

            if (inputElement) {
                for (const eventType of Object.values(EVENT_TYPES)) {
                    inputElement.addEventListener(eventType, onTouchEvent);
                }
            }
        },
        [onTouchEvent]
    );
}
