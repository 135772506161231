import React from 'react';
import propTypes from 'prop-types';
import paypal from 'braintree-web/paypal';
import noop from 'lodash/noop';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

export default class PaymentPaypal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handlePaypalCreated = this.handlePaypalCreated.bind(this);
        this.handlePaypalButtonClick = this.handlePaypalButtonClick.bind(this);
        this.handleNonceCreated = this.handleNonceCreated.bind(this);

        this.state = {
            email: '',
            nonce: ''
        };
    }

    componentDidMount() {
        if (!this.props.brainTreeClientInstance) {
            return;
        }

        paypal.create({
            client: this.props.brainTreeClientInstance
        }, this.handlePaypalCreated);
    }

    componentDidUpdate() {
        if (this.props.updateSuccess) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        if (this.paypalInstance) {
            this.paypalInstance.teardown(function (err) {
                if (err) {
                    console.error('Could not tear down Paypal');
                }
            });
        }
    }

    handlePaypalCreated(error, instance) {
        if (error) {
            this.props.onPaypalError(error);

            return;
        }
        this.paypalInstance = instance;
        this.paypalButton.addEventListener('click', this.handlePaypalButtonClick, false);
        this.paypalButton.removeAttribute('disabled');
        this.props.onPaypalInitialised(instance);
    }

    handlePaypalButtonClick(event) {
        event.preventDefault();
        this.paypalInstance.tokenize({
            flow: 'vault',
            billingAgreementDescription: this.props.billingAgreementDescription,
            locale: this.props.locale,
            enableShippingAddress: false,
            enableBillingAddress: false
        }, this.handleNonceCreated);
        this.props.onPaypalButtonClick();
    }

    handleNonceCreated(tokenizeError, payload) {
        if (tokenizeError) {
            if (tokenizeError.type !== 'CUSTOMER') {
                console.error('Error tokenizing:', tokenizeError);
            }
            this.props.onPaypalError(tokenizeError);

            return;
        }

        this.paypalButton.setAttribute('disabled', true);
        this.setState({
            nonce: payload.nonce,
            email: payload.details.email
        });
        this.props.onNonceRecieved(payload.nonce);
    }

    renderPaypalButton() {
        if (this.state.nonce) {
            return null;
        }

        return (
            <input
                type="image"
                ref={(input) => { this.paypalButton = input; }}
                disabled={true}
                name="Check out with PayPal"
                alt="Check out with PayPal"
                src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" />
        );
    }

    renderPaypalSuccess() {
        if (!this.state.nonce) {
            return null;
        }

        return (
            <p>
                <strong>Paypal Account</strong>
                <br />
                {this.state.email}
            </p>
        );
    }

    render() {
        return (
            <div>
                <p className="fiso-hawk-payment-paypal__description">
                    <span className="fiso-hawk-payment-paypal__padlock-icon">
                        <Fsvg name="lock" />
                    </span>
                    Secure payment
                </p>
                {this.renderPaypalSuccess()}
                {this.renderPaypalButton()}
            </div>
        );
    }
}

PaymentPaypal.defaultProps = {
    billingAgreementDescription: 'Watch AFL',
    locale: 'en_US',
    onNonceRecieved: noop,
    onPaypalButtonClick: noop,
    onPaypalInitialised: noop,
    onPaypalError: noop,

    updateSuccess: false
};

PaymentPaypal.propTypes = {
    billingAgreementDescription: propTypes.string,
    locale: propTypes.string,
    brainTreeClientInstance: propTypes.object,
    onNonceRecieved: propTypes.func,
    onPaypalButtonClick: propTypes.func,
    onPaypalInitialised: propTypes.func,
    onPaypalError: propTypes.func,

    updateSuccess: propTypes.bool
};
