export default class NativeAdsAdapter {
  getVersion() {
    return '7.0.3-chromecast-caf-ads-js'
  }

  getPlayerName() {
    return 'Chromecast CAF Ads'
  }

  getPlayhead() {
    return this.player.getBreakClipCurrentTimeSec()
  }

  getDuration() {
    return this.player.getBreakClipDurationSec()
  }

  getPosition() {
    var ret = this.getNpawReference().Constants.AdPosition.Midroll;
    var adapter = this.plugin.getAdapter();
    if (adapter) {
      if (!adapter.flags.isJoined || adapter.getPlayhead() < 1) {
        ret = this.getNpawReference().Constants.AdPosition.Preroll
      } else if (adapter.getPlayhead() + 1 >= adapter.getDuration()) {
        ret = this.getNpawReference().Constants.AdPosition.Postroll
      }
    };
    return ret
  }

  getTitle() {
    return 'unknown'
  }

  getResource() {
    return 'unknown'
  }

  getCreativeId() {
    return this.clipId
  }

  getIsVisible() {
    return true
  }

  getIsFullscreen() {
    return true
  }

  getIsSkippable() {
    return this.isSkippable
  }

  getGivenAds() {
    return this.adsInBreak
  }

  getBreaksTime() {
    var breaks = this.player.getBreaks();
    var cuePoints = [];
    for (var breakN in breaks) {
      var position = breaks[breakN].position;
      var adapter = this.plugin ? this.plugin.getAdapter() : null;
      if (position === -1 && adapter) {
        position = adapter.getDuration()
      };
      if (cuePoints.indexOf(position) === -1) {
        cuePoints.push(position)
      }
    };
    return cuePoints
  }

  getGivenBreaks() {
    var breaks = this.player.getBreaks();
    var cuePoints = [];
    for (var breakN in breaks) {
      var position = breaks[breakN].position;
      var adapter = this.plugin ? this.plugin.getAdapter() : null;
      if (position === -1 && adapter) {
        position = adapter.getDuration()
      };
      if (cuePoints.indexOf(position) === -1) {
        cuePoints.push(position)
      }
    };
    return cuePoints.length
  }

  registerListeners() {
    var Events = cast.framework.events;
    this.monitorPlayhead(true, false, 1200);
    this.references = {};
    this.references[Events.EventType.BREAK_CLIP_LOADING] = this.playListener.bind(this);
    this.references[Events.EventType.BREAK_CLIP_STARTED] = this.joinListener.bind(this);
    this.references[Events.EventType.BREAK_CLIP_ENDED] = this.stopListener.bind(this);
    this.references[Events.EventType.BREAK_STARTED] = this.breakStartListener.bind(this);
    this.references[Events.EventType.BREAK_ENDED] = this.breakEndListener.bind(this);
    this.references[Events.EventType.PAUSE] = this.pauseListener.bind(this);
    this.references[Events.EventType.PLAYING] = this.playingListener.bind(this);
    for (var key in this.references) {
      this.player.addEventListener(key, this.references[key])
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.removeEventListener(key, this.references[key])
      };
      this.references = {}
    }
  }

  playListener(e) {
    var adapter = this.plugin.getAdapter();
    if (adapter) {
      adapter.fireStart({}, 'playListener-ads');
      adapter.firePause({}, 'playListener-ads')
    };
    if (typeof e !== 'undefined') {
      this.isSkippable = e.whenSkippable !== undefined;
      this.clipId = e.breakClipId
    } else {
      this.isSkippable = false;
      this.clipId = null
    }
  }

  joinListener(e) {
    if (!this._quartileTimer) {
      this._quartileTimer = setInterval(this.sendQuartile.bind(this), 1000);
    };
    var adapter = this.plugin ? this.plugin.getAdapter() : null;
    if (adapter) adapter.firePause({}, 'joinListener-ads');
    if (this.plugin && this.plugin.isBreakStarted) {
      this.fireStart({}, 'joinListener');
      this.fireJoin({}, 'joinListener')
    }
  }

  breakStartListener(e) {
    this.adsInBreak = e.total;
    this.fireBreakStart();
    if (!this._quartileTimer) {
      this._quartileTimer = setInterval(this.sendQuartile.bind(this), 1000);
    };
    this.fireStart({}, 'breakStartListener');
    this.fireJoin({}, 'breakStartListener');
  }

  stopListener(e) {
    this.fireStop({}, 'stopListener');
  }

  breakEndListener(e) {
    this.adsInBreak = undefined;
    this.fireBreakStop();
    var adapter = this.plugin ? this.plugin.getAdapter() : null;
    if (adapter) {
      adapter.fireResume({}, 'endListener-ads');
    }
  }

  sendQuartile(e) {
    var playhead = this.getPlayhead();
    var duration = this.getDuration();
    if (playhead > duration / 4) {
      this.fireQuartile(1);
      if (playhead > duration / 2) {
        this.fireQuartile(2);
        if (playhead > duration * 0.75) {
          this.fireQuartile(3);
          if (this._quartileTimer) {
            clearInterval(this._quartileTimer);
            this._quartileTimer = undefined;
          }
        }
      }
    }
  }

  pauseListener(e) {
    if (!this.flags.isBuffering) {
      this.firePause({}, 'pauseListener')
    }
  }

  playingListener(e) {
    this.fireResume({}, 'playingListener')
  }

}