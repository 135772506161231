import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {DEFAULT_VIMOND_ENV, PROXY_RESOURCE_URL, DEFAULT_MATCHES_COUNT, DEFAULT_STREAM_TIMEOUT as delay} from '../../utils/constants';

export default function getMatchesStream({
    count,
    sport,
    seriesIds,
    type,

    freq = 0,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const retries = process && process.browser ? 1 : 0;

    const url = getMatchEndPoint(count, sport, seriesIds, type, vimondEnv);

    return getApiFromRetryWithError({delay, freq, retries, url})
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            }

            const errorMessage = `FS-HAWK-WIDGETS: unable to get ${type} matches data (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        })
        // @TODO Backend hands us empty object vs empty array when there's nothing upcoming
        .map((apiResponse) => {
            if (isEqual(apiResponse, {})) {
                return [];
            } else {
                return apiResponse;
            }
        });
}

function getMatchEndPoint(count, sport, seriesIds, type, vimondEnv)  {
    let url = `${PROXY_RESOURCE_URL[vimondEnv]}` +
        `scores/${type}.json;sport=${sport}` +
        (seriesIds ? `:${seriesIds.join(',')}` : '');

    if (['complete', 'upcoming'].indexOf(type) !== -1) {
        url += `?limit=${count || DEFAULT_MATCHES_COUNT[type]}`;
    }

    return url;
}
