import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC15Skf = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M55.938 26.551L52.179 26.551 52.179 2.795 46.771 2.795 46.771 0 55.938 0zM72.623 26.97c-3.598.2-7.04-1.489-9.083-4.458l2.166-2.152c1.547 2.272 4.141 3.604 6.889 3.536 3.116 0 6.4-1.845 6.4-5.87 0-4.024-3.242-5.883-6.4-5.883-1.738-.07-3.452.42-4.89 1.398l-1.957-1.118L68.15 0h13.876v2.99h-11.08l-1.398 6.987c1.249-.493 2.584-.73 3.926-.698 4.472 0 9.405 2.878 9.405 8.692 0 5.813-5.045 8.999-10.257 8.999zM30.268 29.359L21.883 29.331 23.714 12.66 6.428 8.943 8.804.894 32.112 6.4z"
            transform="translate(7 16) translate(1.02)"
        />
        <path
            d="M30.38 58.411c-5.646.205-11.134-1.89-15.208-5.803-4.074-3.914-6.387-9.313-6.41-14.962-.168-6.903 3.437-11.36 8.636-15.288l.768-5.715-5.687-1.062C5.674 20.709.126 27.291.377 37.856.6 54.008 13.835 66.943 29.988 66.796h.545c7.616-.14 30.743 0 30.953 0V58.41c-.895-.014-23.379-.14-31.106 0z"
            transform="translate(7 16) translate(1.02)"
        />
    </Ic>
);

IC15Skf.displayName = 'IC15Skf';

export default IC15Skf;
