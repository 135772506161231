export default class DashShakaTech {
  getBitrate(adapter) {
    return adapter.player.dash.shakaPlayer.getStats().streamBandwidth;
  }

  getResource(adapter) {
    let ret = '';
    const sp = adapter.player.dash.shakaPlayer;
    if (typeof sp.getManifestUri === 'function') {
      ret = sp.getManifestUri();
    } else if (typeof sp.getAssetUri === 'function') {
      ret = sp.getAssetUri();
    }
    return ret;
  }

  isUsed(adapter) {
    return adapter.player.dash && adapter.player.dash.shakaPlayer;
  }

  getRendition(adapter) {
    const shaka = adapter.player.dash.shakaPlayer;
    const stats = shaka.getStats();
    let ret = this.getNpawUtils().buildRenditionString(stats.width, stats.height, stats.streamBandwidth);
    const tracks = shaka.getVariantTracks();
    for (let i in tracks) {
      const track = tracks[i];
      if (track.active && track.type === 'video') {
        ret = this.getNpawUtils().buildRenditionString(track.width, track.height, track.bandwidth);
      }
    }
    return ret;
  }
}