/**
 * Intended for use on the docs page
 *
 * Looks for a docs.min.js script tag. If found, it will rerun the script
 * by injecting it to the document head.
 *
 * @returns {void}
 */
export default function rerunFisoScript() {
    [...document.getElementsByTagName('script')]
        .filter((script) => /js\/docs\.min\.js/.test(script.src))
        .map(({src}) => Object.assign(
            document.createElement('script'),
            {src}
        ))
        .forEach((newScriptEl) => {
            setTimeout(() => { // Execute the original docs.min.js script again now ESI sections are loaded.
                console.log('rebooting fiso scripts'); // eslint-disable-line no-console

                document.getElementsByTagName('head')[0].appendChild(newScriptEl);
            });
        });
}
