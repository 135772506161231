import {
    mediaQuery,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import React from 'react';
import styled from 'styled-components';

import gibson from '../../../../common/font-helper';
import {coal, white, red} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';

const ContextualHelp = styled.div`
    position: relative;
    margin-top: 7px;
    border-left: 4px solid ${red};
    background-color: ${white};
    padding: 14px;
    color: ${coal};
    font: ${gibson.light({size: 16, lineHeight: 1.25})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        border-left-width: 5px;
        font: ${gibson.light({size: 20, lineHeight: 1.3})};
    `}

    a {
        color: inherit;
    }
`;

const StyledArrow = styled.div`
    position: absolute;
    top: -14px;
    right: 11px;
    border-right: 14px solid transparent;
    border-bottom: 14px solid ${white};
    border-left: 14px solid transparent;
    width: 0;
    height: 0;

    ${stylesWhen('isLeftAligned')`
        left: 11px;
    `}

    ${stylesWhen('hasDownArrow')`
        transform: rotate(180deg);
        top: unset;
        bottom: -14px;
    `}
`;

export type Props = {
    /** Positioning of the arrow header */
    arrowOrientation?: 'left' | 'right';
    /** Whether to show the arrow in downward direction */
    hasDownArrow?: boolean;
    /** Content for the help dialog */
    children: React.ReactNode;
    /** Whether to show the arrow head or not */
    showArrow?: boolean; // eslint-disable-line react/boolean-prop-naming
} & React.ComponentPropsWithoutRef<'div'>;

const IA05InputHelp = ({
    arrowOrientation = 'right',
    children,
    hasDownArrow,
    showArrow = true,
    ...htmlAttributes
}: Props): React.ReactElement => (
    <ContextualHelp {...htmlAttributes}>
        {!!showArrow && (
            <StyledArrow
                hasDownArrow={hasDownArrow}
                isLeftAligned={arrowOrientation === 'left'}
            />
        )}
        {children}
    </ContextualHelp>
);

IA05InputHelp.displayName = 'IA05InputHelp';

export default IA05InputHelp;
