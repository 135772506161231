import querystring from 'querystring';

export function redirectPage(redirectTo) {
    if (!process || !process.browser) {
        return;
    }

    if (redirectTo) {
        window.location = redirectTo;
    } else {
        window.location.reload();
    }
}

export function getUrlParameter(name) {
    if (!process || !process.browser) {
        return;
    }

    const queryString = window.location.search.replace('?', '');

    return querystring.parse(queryString)[name];
}

export function getAllUrlParameters() {
    if (!process || !process.browser) {
        return;
    }

    const queryString = window.location.search.replace('?', '');

    return queryString;
}

export const removeQueryStringProps = (...propsToFilter) => (queryString) => {
    if (!process || !process.browser) {
        return;
    }

    const newObj = querystring.parse(queryString);

    propsToFilter.forEach((key) => delete newObj[key]);

    return querystring.stringify(newObj);
};

/**
 * PrependQueryString dissects a url and appends parameters before it
 * this is useful for order specific query parameters (don't ask)
 *
 * @category Function
 * @param {object} parameters The parameters to prepend to the querystring.
 * @param {string} url The original url to prepend the new parameters to.
 * @returns {number} Returns the url with the querystring prepended
 * @example
 *
 * prependQueryString({
 *    parameters: {
 *        test: true
 *    },
 *    url: 'http://www.example.com?example=true'
 * });
 * // => 'http://www.example.com?test=true&example=true'
 */
export function prependQueryString({
    parameters = {},
    url = ''
}) {
    if (!Object.keys(parameters).length) {
        return url;
    }

    const [path = '', queryString = ''] = url.split('?');

    const urlWithQueryString = `${path}?${querystring.stringify(parameters)}`;

    return queryString ? urlWithQueryString.concat(`&${queryString}`) : urlWithQueryString;
}

/**
 * Returns current URL so users are redirected back to the page after login
 *
 * @param  {String}   loginRoute      Login url to direct user
 * @param  {String}   redirectParam   Querystring key to check for
 * @param  {Array}    queryParams     Querystring params to add
 * @return {String}                   Returns absolute-relative URL to login page, with redirect URL
 */
export function getLoginRedirectUrl(
    {
        loginRoute = '/login',
        redirectParam = 'return'
    } = {}
) {
    if (!process || !process.browser) {
        return loginRoute;
    }

    const queryString = window.location.search.replace('?', '');

    return `${loginRoute}?${redirectParam}=${window.location.pathname}${queryString ? `&${queryString}` : ''}`;
}

/**
 * Returns current pathname
 *
 * @return {String}                   Returns location.pathname
 */
export function getCurrentPath() {
    if (!process || !process.browser) {
        return '/';
    }

    return window.location.pathname;
}
