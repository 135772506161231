import bacon from 'baconjs';
import superagent from 'superagent';
import first from 'lodash/first';
import filter from 'lodash/filter';
import without from 'lodash/without';

import {streamFromApi, getVimondHeaders} from '../../utils/api';
import authenticated from '../authenticated';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function getProductPayments({
    currency,
    payment,
    product,
    sport = 'afl',
    user,
    voucher,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/order`;
    const productPayment = getProductPayment({product, voucher, currency});
    let order;

    if (productPayment) {
        order = {
            order: {
                userId: user.id,
                productPaymentId: productPayment.id,
                referrer: 'foxSports', // TODO: add referrer here
                voucherCode: voucher || undefined,
                payment
            }
        };

        return authenticated().flatMapLatest((authToken) => {
            const request = superagent
                .post(resourceUrl)
                .set(getVimondHeaders({authToken, sport}))
                .send(order);

            return streamFromApi(request).map('.body');
        });
    } else {
        console.error('Product payment plan not setup correctly');

        return bacon.later(0, new bacon.Error('Product not available'));
    }
}

// TODO: Remove this after TAKE25OFF is not valid because backend is unable to do this
function getProductPayment({product, voucher, currency}) {
    const voucherProductPayments = filter(product.productPayments, {description: 'TAKE25OFF'});
    const brainTreeProductPayments = without(product.productPayments, ...voucherProductPayments);

    if (voucher === `${currency}_TAKE25OFF` && voucherProductPayments.length !== 0) {
        return first(voucherProductPayments);
    } else {
        return first(brainTreeProductPayments);
    }
}
