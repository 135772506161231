import React from 'react';

const GA144Up2 = (): React.ReactElement => (
    <svg width="73px" height="20px" viewBox="0 0 73 20">
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="2-Up"
                transform="translate(0.000000, 0.108696)"
                fill="#FFFFFF"
            >
                <rect x="0" y="0" width="35.0426609" height="19.5434783" />
                <rect
                    x="37.7382502"
                    y="0"
                    width="35.0426609"
                    height="19.5434783"
                />
            </g>
        </g>
    </svg>
);

GA144Up2.displayName = 'GA144Up2';

export default GA144Up2;
