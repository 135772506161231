import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import get from 'lodash/get';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import FormField from '../form-field';
import FormInput from '../form-input';
import FormSelect from '../form-select';
import FormCheckbox from '../form-checkbox';
import Message from '../message';
import {
    trackPersonalDetailsFirstName,
    trackPersonalDetailsLastName,
    trackPersonalDetailsEmail,
    trackPersonalDetailsMobile
} from '../../utils/event-tracking/subscription';

export default class CreateUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnClickSubmit = this.handleOnClickSubmit.bind(this);
        this.changeField = this.changeField.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
        this.renderServerErrors = this.renderServerErrors.bind(this);

        this.state = {
            firstname: props.existingUser ? 'User already logged in.' : '',
            lastname: '',
            email: get(props, 'existingUser.email', ''),
            mobile: '',
            favouriteTeamId: props.favouriteTeamId,
            password: '',
            receiveEmail: false
        };
    }

    changeField(fieldName, fieldValue) {
        this.setState({[`${fieldName}`]: fieldValue});
    }

    togglePassword() {
        const passwordInput = this.password.input;

        if (passwordInput.getAttribute('type') === 'password') {
            passwordInput.setAttribute('type', 'text');
        } else {
            passwordInput.setAttribute('type', 'password');
        }
    }

    handleOnClickSubmit(event) {
        event.preventDefault();

        const user = {
            userName: this.state.email,
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            email: this.state.email,
            mobileNumber: this.state.mobile,
            password: this.state.password,
            city: this.state.favouriteTeamId,
            receiveEmail: this.state.receiveEmail
        };

        this.props.onClickSubmit(user);
    }

    renderServerErrors() {
        return (
            this.props.createAccountAndLoginErrors.map((error, index) => <Message key={index} type="fail" value={error} />)
        );
    }

    render() {
        const {isLoading, existingUser, formErrors, isLockedOff} = this.props;

        const createAccountFormClasses = classNames(
            'fiso-hawk-subscribe__user-form',
            {
                'fiso-hawk-subscribe__col--create-inactive': isLockedOff
            }
        );

        const teams = this.props.teams
            .filter((team) => team.id !== 0) // Ignore the VIP team.
            .map((team) => (
                <option key={team.id} value={team.id}>
                    {team.name}
                </option>
            ));

        const submitButtonClasses = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`,
            {
                'fiso-hawk-button--loading': isLoading
            },
            {
                'fiso-hawk-button--chevron-right': !isLoading
            }
        );

        const submitButtonText = this.props.isPaymentRequired ? 'Continue' : 'Subscribe';

        const termsLinkElement = (
            <a
                href={this.props.termsUrl}
                target="_blank"
                rel="noopener noreferrer"
                key="terms"
                className="fiso-hawk-link">
                Terms and Conditions
            </a>
        );

        const privacyLinkElement = (
            <a
                href={this.props.privacyUrl}
                target="_blank"
                rel="noopener noreferrer"
                key="privacy"
                className="fiso-hawk-link">
                Privacy Policy
            </a>
        );

        const sportInfo = this.props.sportDetails.route === 'nrl' ? {
            privacyUrl: this.props.nrlPrivacyUrl,
            name: 'NRL'
        } : {
            privacyUrl: this.props.aflPrivacyUrl,
            name: 'AFL'
        };

        const agreeCheckboxTitle = [
            ' and acknowledge that my personal information will be treated by FOX SPORTS in accordance with the FOX SPORTS ',
            privacyLinkElement,
            ` and by the ${sportInfo.name} in accordance with the `,
            <a
                href={sportInfo.privacyUrl}
                target="_blank"
                rel="noopener noreferrer"
                key={`privacy-${sportInfo.name}`}
                className="fiso-hawk-link">
                {sportInfo.name} Privacy Policy
            </a>
        ];

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form
                ref={(form) => { this.createAccountForm = form; }}
                className={createAccountFormClasses}
                onSubmit={this.handleOnClickSubmit}
                method="POST">
                <h2 className="fiso-hawk-subscribe__heading-2">Enter your details below</h2>

                {this.renderServerErrors()}

                <p className="fiso-hawk-subscribe__p">Create an account. Simple sign up.</p>

                <FormField errors={formErrors.email}>
                    <FormInput
                        defaultValue={this.state.email}
                        type="email"
                        name="email"
                        disabled={isLockedOff}
                        required={true}
                        title="Email address"
                        handleChange={this.changeField}
                        onBlur={trackPersonalDetailsEmail} />
                </FormField>
                {((!!existingUser || formErrors.email) && !this.props.isLockedOff) && (
                    <p className="fiso-hawk-subscribe__please-login">
                        <a
                            className="fiso-hawk-link"
                            href={this.props.redirectUrl}>
                            Please log in here to continue
                        </a>
                    </p>
                )}

                <FormField errors={formErrors.password}>
                    <FormInput
                        defaultValue={this.state.password}
                        ref={(input) => { this.password = input; }}
                        type="password"
                        name="password"
                        disabled={isLockedOff}
                        required={true}
                        title="Password"
                        handleChange={this.changeField} />
                </FormField>

                <FormCheckbox
                    name="togglePassword"
                    disabled={isLockedOff}
                    title="Show Password"
                    handleCheckboxChange={this.togglePassword} />

                <FormField errors={formErrors.firstName}>
                    <FormInput
                        defaultValue={this.state.firstname}
                        name="firstname"
                        required={true}
                        disabled={isLockedOff}
                        title="First name"
                        handleChange={this.changeField}
                        onBlur={trackPersonalDetailsFirstName} />
                </FormField>

                <FormField errors={formErrors.lastName}>
                    <FormInput
                        defaultValue={this.state.lastname}
                        name="lastname"
                        required={true}
                        disabled={isLockedOff}
                        title="Last name"
                        handleChange={this.changeField}
                        onBlur={trackPersonalDetailsLastName} />
                </FormField>

                <FormField errors={formErrors.mobile}>
                    <FormInput
                        defaultValue={this.state.mobile}
                        type="tel"
                        name="mobile"
                        disabled={isLockedOff}
                        required={false}
                        title="Mobile"
                        handleChange={this.changeField}
                        onBlur={trackPersonalDetailsMobile} />
                </FormField>

                <FormSelect
                    defaultValue={this.state.favouriteTeamId}
                    children={teams}
                    disabled={isLockedOff}
                    handleChange={this.changeField}
                    title={`Your favourite ${this.props.sportDetails.label} team`}
                    required={true}
                    name="favouriteTeamId" />

                <FormCheckbox
                    name="agree"
                    required={true}
                    disabled={isLockedOff}
                    title={[
                        'I agree to the ',
                        termsLinkElement,
                        ...agreeCheckboxTitle
                    ]}
                    defaultValue={this.state.agree}
                    handleCheckboxChange={this.changeField} />

                {this.props.sportDetails.route === 'nrl' && (
                    <FormCheckbox
                        name="receiveEmail"
                        disabled={isLockedOff}
                        title={
                            'I would like to receive marketing communications from the NRL and the business ' +
                            'partners of FOX SPORTS and the NRL.'
                        }
                        defaultValue={this.state.receiveEmail}
                        handleCheckboxChange={this.changeField} />
                )}

                <FormCheckbox
                    name="understand"
                    required={true}
                    disabled={isLockedOff}
                    title={[
                        `I understand I cannot watch this content in Australia${this.props.sportDetails.label === 'AFL' ? '' : ', New Zealand and some Pacific Nations'}. `,
                        <a
                            href={this.props.termsUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            key="faq"
                            className="fiso-hawk-link">
                            More
                        </a>
                    ]}
                    defaultValue={this.state.understand}
                    handleCheckboxChange={this.changeField} />

                <button
                    type="submit"
                    disabled={isLockedOff || isLoading}
                    className={submitButtonClasses}>
                    {submitButtonText}
                    { !isLoading && <Fsvg name="next-1" /> }
                </button>

                <p>
                    <a
                        href={this.props.dataCollectionUrl}
                        className="fiso-hawk-link"
                        target="_blank"
                        rel="noopener noreferrer">
                        View our data collection statement
                    </a>
                </p>

                {!!this.props.nrlPrivacyUrl && (
                    <p>
                        <a
                            href={this.props.nrlPrivacyUrl}
                            className="fiso-hawk-link"
                            target="_blank"
                            rel="noopener noreferrer">
                            View the NRL Privacy Policy
                        </a>
                    </p>
                )}
            </form>
        );
    }
}

CreateUserForm.defaultProps = {
    isLockedOff: false,
    isLoading: false,
    teams: [],
    createAccountAndLoginErrors: [],
    formErrors: {},
    onClickSubmit: noop
};

CreateUserForm.propTypes = {
    isLockedOff: propTypes.bool,
    isLoading: propTypes.bool,
    isPaymentRequired: propTypes.bool,
    dataCollectionUrl: propTypes.string,
    termsUrl: propTypes.string,
    privacyUrl: propTypes.string,
    nrlPrivacyUrl: propTypes.string,
    aflPrivacyUrl: propTypes.string,
    favouriteTeamId: propTypes.number,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    }),

    teams: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number,
            name: propTypes.string
        })
    ),
    existingUser: propTypes.shape({
        firstName: propTypes.string,
        email: propTypes.string
    }),
    createAccountAndLoginErrors: propTypes.arrayOf(
        propTypes.string
    ),
    formErrors: propTypes.shape({
        firstName: propTypes.arrayOf(
            propTypes.string
        ),
        lastName: propTypes.arrayOf(
            propTypes.string
        ),
        mobile: propTypes.arrayOf(
            propTypes.string
        ),
        email: propTypes.arrayOf(
            propTypes.string
        ),
        password: propTypes.arrayOf(
            propTypes.string
        )
    }),
    onClickSubmit: propTypes.func,
    redirectUrl: propTypes.string
};
