import {
    ANALYTICS_EVENT_NAME,
    PLAYER_LAYOUT_STYLE,
} from '@fsa-streamotion/player-state';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import noop from 'lodash/noop';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useRef} from 'react';
import styled, {useTheme} from 'styled-components';

import {transition} from '../../../../common/animations';
import {white} from '../../../../common/palette';
import BA01CtrlBtn from '../../../../component-library/atoms/ba/01-ctrl-btn';
import {IC220AudioSettings} from '../../../../component-library/atoms/ic';
import IC08Cc from '../../../../component-library/atoms/ic/08-cc';
import IC11AirP from '../../../../component-library/atoms/ic/11-air-p';
import IC30Back from '../../../../component-library/atoms/ic/30-back';
import OR03UpperControls from '../../../../component-library/organisms/or/03-upper-controls';
import {usePlayerContext} from '../../context';
import getKeyDownHandler, {spaceKey} from '../utils/keydown-handler';

const PositionedOR03UpperControls = styled(OR03UpperControls)`
    position: absolute;
    top: 0;
    left: 0;
    transition: ${transition('opacity', 'transform', 'width')};
    width: ${({theme}) =>
        theme.isHudOpen ? `${theme.effectiveViewportWidthPx}px` : '100%'};
    pointer-events: all;

    ${stylesWhen('shouldLowerControlsOpacity')`
        opacity: 0.5;
    `}
`;

const UpperControls = (
    _: unknown,
    ref: React.ForwardedRef<HTMLButtonElement>
): React.ReactElement => {
    const playerState = usePlayerContext();
    const theme = useTheme();
    const {
        activeScreenConfig,
        isSingleLayout,
        globalActions,
        generalConfig,
        globalState,
    } = playerState ?? {};
    const {
        isUpperControlsVisible,
        isChromecastConnected,
        isChromecastAvailable,
        shouldUseVideoTitle,
        isUpNextMode,
        isHudVisible,
    } = globalState ?? {};
    const {
        exitPlayer,
        setTrayVisibility,
        setLayoutStyle,
        setIsInSplitViewMode,
    } = globalActions ?? {};
    const {
        canUseAirplay,
        canUserViewExitButton,
        onClickChromecast,
        onPlayerInteractionByType,
    } = generalConfig ?? {};
    const {actions, playbackData, playerTech, videoState} =
        activeScreenConfig ?? {};
    const {
        currentTime,
        isInAdBreak,
        adBreakAdCount,
        adIndex,
        isPausedFor5Seconds,
        isOnEdge,
    } = videoState ?? {};

    const shouldLowerControlsOpacity = isSingleLayout && isPausedFor5Seconds;

    const shouldShowAirplayButton = useMemo(
        () =>
            // Only bother running playerTech.supportsAirPlay once
            !isUpNextMode && !!canUseAirplay && playerTech?.supportsAirPlay,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [canUseAirplay]
    );
    const exitSplitView = (): void => {
        setLayoutStyle?.(PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE);
        setIsInSplitViewMode?.(false);
    };

    const adTitle =
        typeof adBreakAdCount === 'number' &&
        typeof adIndex === 'number' &&
        adBreakAdCount > 1
            ? `Ad ${adIndex + 1} of ${adBreakAdCount}`
            : 'Ad';

    const backButtonLabel = isSingleLayout
        ? shouldUseVideoTitle && !!playbackData?.metadata.title
            ? undefined
            : 'Exit'
        : 'Exit Splitview';

    const backButton = canUserViewExitButton ? (
        <BA01CtrlBtn
            label={isInAdBreak ? adTitle : backButtonLabel}
            onClick={isSingleLayout ? exitPlayer : exitSplitView}
            tabIndex={-1}
        >
            <IC30Back color={white} />
        </BA01CtrlBtn>
    ) : null;

    const audioSettingsButton = !isUpNextMode && (
        <BA01CtrlBtn
            ref={ref}
            label="Audio & Settings"
            onClick={(event) => {
                setTrayVisibility?.({upper: true});
                // prevent click events from bubbling and closing the player
                event.preventDefault();
                event.stopPropagation();
            }}
            shouldHideLabelOnMobile={true}
        >
            <IC220AudioSettings color={white} />
        </BA01CtrlBtn>
    );

    const airplayButton = shouldShowAirplayButton ? (
        <BA01CtrlBtn
            aria-label="Airplay"
            onClick={() => {
                playerTech?.showAirPlayTargetPicker();
                onPlayerInteractionByType?.(
                    ANALYTICS_EVENT_NAME.SELECT_CAST,
                    'airplay'
                );
            }}
        >
            <IC11AirP color={white} />
        </BA01CtrlBtn>
    ) : null;

    const chromecastButton =
        !isUpNextMode && !isInAdBreak && isChromecastAvailable ? (
            <BA01CtrlBtn
                aria-label="Chromecast"
                onClick={() => {
                    try {
                        onPlayerInteractionByType?.(
                            ANALYTICS_EVENT_NAME.SELECT_CAST,
                            'chromecast'
                        );

                        if (playbackData) {
                            onClickChromecast?.({
                                playbackData,
                                currentTime: isOnEdge ? -1 : currentTime ?? 0,
                            });
                        }
                    } catch (error) {
                        generalConfig?.logger.error(
                            'onClickChromecast error',
                            error
                        );
                    }
                }}
            >
                <IC08Cc
                    isConnected={isChromecastConnected}
                    color={white}
                    accentColor={theme.brandColor}
                />
            </BA01CtrlBtn>
        ) : null;

    const mediaKeysAndActionsRef = useRef({
        Escape: actions?.exitPlayer ?? noop,
        [spaceKey]: noop,
    });
    const inputHandler = getKeyDownHandler(mediaKeysAndActionsRef);

    useEffect(() => {}, [isHudVisible]); // eslint-disable-line no-empty-function, @typescript-eslint/no-empty-function

    return (
        <PositionedOR03UpperControls
            isInAdBreak={isInAdBreak}
            isVisible={isUpperControlsVisible}
            backButton={backButton}
            videoTitle={
                shouldUseVideoTitle && isSingleLayout
                    ? playbackData?.metadata.title
                    : undefined
            }
            audioSettingsButton={audioSettingsButton}
            airplayButton={airplayButton}
            chromecastButton={chromecastButton}
            shouldLowerControlsOpacity={shouldLowerControlsOpacity}
            onKeyDown={inputHandler}
        />
    );
};

UpperControls.displayName = 'UpperControls';

export default observer(UpperControls, {forwardRef: true});
