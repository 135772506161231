/* eslint-disable arrow-body-style */
import {isPs4} from '@fsa-streamotion/browser-utils';

import attempt from 'lodash/attempt';
import isArrayBuffer from 'lodash/isArrayBuffer';
import method from 'lodash/method';
import noop from 'lodash/noop';

import type {KeySystemConfig, KeySystemType} from '../../types';
import {ERROR_CODES} from '../../utils/error-codes';
import PlayerTechError, {
    VIDEO_FS_ERROR_TYPES,
} from '../../utils/player-tech-error';
import PlayerTechXhrError from '../../utils/player-tech-xhr-error';
import type {BasicMediaKeySystem, PlaybackHandlerArgs} from '../types';

const PLAY_READY_KEY_SYSTEM = 'com.microsoft.playready';
const WIDEVINE_KEY_SYSTEM = 'com.widevine.alpha';
const FAIR_PLAY_KEY_SYSTEM = 'com.apple.fps.1_0';

const MESSAGE_LICENSE_REQUEST_CANCELED_BY_CLEANUP =
    'EME: License request canceled by a cleanup function';

const DRM_ORDER = [
    FAIR_PLAY_KEY_SYSTEM,
    PLAY_READY_KEY_SYSTEM,
    WIDEVINE_KEY_SYSTEM,
];

type NativeEmeConstructorParam = Pick<
    PlaybackHandlerArgs,
    | 'videoElement'
    | 'src'
    | 'options'
    | 'availableKeySystems'
    | 'keySystems'
    | 'onError'
>;

type MassageRequestParams = {
    uri: string;
    method?: string;
    messageBody?: string | ArrayBuffer;
    headers: Record<string, string>;
    withCredentials?: boolean;
    responseType?: XMLHttpRequest['responseType'];
};

type MassageRequestSuccessResponse = {
    xhrResponse: Uint8Array;
    xhrRequestObject: XMLHttpRequest;
};

export default class NativeEme {
    videoElement: HTMLVideoElement;
    src: string;
    options: PlaybackHandlerArgs['options'];
    keySystems: PlaybackHandlerArgs['keySystems'];
    availableKeySystems: PlaybackHandlerArgs['availableKeySystems'];
    onError: PlaybackHandlerArgs['onError'];
    hasGeneratedRequest = false;
    activeKeySystem: MediaKeySystemAccess | BasicMediaKeySystem | null = null;
    activeKeySystemName: KeySystemType | null = null;
    activeKeySystemOptions: KeySystemConfig | undefined = undefined;
    certificate: Uint8Array | null = null;

    constructor({
        videoElement,
        src,
        options = {startAt: 0},
        availableKeySystems = [],
        keySystems = {},
        onError = noop,
    }: NativeEmeConstructorParam) {
        this.videoElement = videoElement;
        this.src = src;
        this.options = options;
        this.keySystems = keySystems;
        this.availableKeySystems = availableKeySystems;
        this.onError = onError;

        this.setup();
    }

    private _activeSessions: Array<MediaKeySession> = [];
    private _activeXhrs: Array<{
        request: XMLHttpRequest;
        abortRequest: () => void;
    }> = []; // Format: [{request, abortRequest}, ...]

    private _fairPlayLicenseUriParams: string | null = null;
    private _onWillDestroyCallbacks: Array<() => void> = [];
    private _setMediaKeysPromise: Promise<void> | null = null;

    /**
     * Timeout (setTimeout) if we have a heartbeat set in settings.
     */
    heartbeatTimeout = -1;

    setup(): void {
        this.activeKeySystem =
            this.availableKeySystems.sort((a, b) => {
                return (
                    DRM_ORDER.indexOf(a.keySystem) -
                    DRM_ORDER.indexOf(b.keySystem)
                );
            })[0] || null;

        this.activeKeySystemName = (this.activeKeySystem?.keySystem ||
            null) as KeySystemType | null;

        this.activeKeySystemOptions = this.activeKeySystemName
            ? this.keySystems?.[this.activeKeySystemName]
            : undefined;

        // @TODO Potentially check for WebKitMediaKeys + FairPlay.
        if (window.WebKitMediaKeys) {
            this.debug('EME: Using WebKitMediaKeys');

            // Support Safari EME with FairPlay
            // (also used in early Chrome or Chrome with EME disabled flag)

            // Safari has Both WebKitMediaKeys and MediaKeys, but they behave well different.
            // We're going to run on the assumption, if you're WebKitMediaKeys available, you're FairPlay.
            // Need to get FairPlay Certificate, and apply as key.
            this.makeMessageRequest({
                uri: this.activeKeySystemOptions?.certificateUri || '',
                method: 'GET',
                messageBody: undefined,
                headers: {
                    'Pragma': 'Cache-Control: no-cache',
                    'Cache-Control': 'max-age=0',
                },
                withCredentials: this.options.withCredentials,
            })
                .then(({xhrResponse}) => {
                    this.certificate = new Uint8Array(xhrResponse);

                    this.videoElement.addEventListener(
                        'webkitneedkey',
                        this._onWebkitNeedKey,
                        false
                    );
                    // @TODO errors too?
                })
                .catch((error) => {
                    if (
                        error?.message ===
                        MESSAGE_LICENSE_REQUEST_CANCELED_BY_CLEANUP
                    ) {
                        this.debug('EME: Setup aborted due to cleanup');

                        return;
                    }

                    console.error(
                        'VideoFsNativeEme: Unable to load or apply _fairPlayCertificate',
                        error
                    );
                    this.onError(
                        ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                        'Unable to load or apply _fairPlayCertificate',
                        error
                    );
                });
        } else if (window.MediaKeys) {
            this.debug('EME: Using MediaKeys');

            // Support EME 05 July 2016
            // Chrome 42+, FireFox 47+, Legacy Edge, Edge, Safari 12.1+ on macOS 10.14+

            let hasPlayerBeenDestroyed = false;

            // Need to createMediaKeys and go from there.
            this._setMediaKeysPromise = (
                this.activeKeySystem as MediaKeySystemAccess
            )
                ?.createMediaKeys()
                .then((createdMediaKeys) => {
                    if (hasPlayerBeenDestroyed) {
                        return;
                    }

                    return this.videoElement?.setMediaKeys(createdMediaKeys);
                });

            const playerDestroyedPromise = new Promise<void>((_, reject) => {
                this._onWillDestroyCallbacks.push((): void => {
                    hasPlayerBeenDestroyed = true;
                    reject(
                        'EME: Tried to set video element media keys after player had been destroyed'
                    );
                });
            });

            Promise.race([this._setMediaKeysPromise, playerDestroyedPromise])
                .then(() => {
                    if (!hasPlayerBeenDestroyed) {
                        this.videoElement.addEventListener(
                            'encrypted',
                            this._onEncryptedEvent
                        );
                    }
                })
                .catch((error) => {
                    if (!hasPlayerBeenDestroyed) {
                        console.error(
                            'VideoFsNativeEme: Unable to createMediaKeys or setMediaKeys',
                            error
                        );

                        this.onError(
                            ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                            'Unable to createMediaKeys or setMediaKeys',
                            error
                        );
                    }
                });
        } else {
            // We don't have MediaKeys or WebKitMediaKeys, so we can't do DRM.
            // Can potentially support IE11 Win 8.1 with the following (not today/likely)
            // window.MSMediaKeys
            // this.videoElement.addEventListener('msneedkey', this._msneedkeyEvent);
            // this.videoElement.addEventListener('mskeyerror', this._emeError);

            console.error(
                'VideoFsEME: No MediaKeys or WebKitMediaKeys available to browser.'
            );
            this.onError(
                ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                'No MediaKeys or WebKitMediaKeys available to browser.',
                new PlayerTechError({
                    type: VIDEO_FS_ERROR_TYPES.EME_NO_MEDIA_KEYS,
                    message:
                        'No MediaKeys or WebKitMediaKeys available to browser.',
                })
            );

            return;
        }
    }

    _onWebkitNeedKey = (event: WebKitEncryptBaseEvent): void => {
        if (
            !event.initData ||
            !this.activeKeySystemName ||
            !this.certificate ||
            !window.WebKitMediaKeys
        ) {
            return;
        }

        if (this.hasActiveSession(event.initData)) {
            this.debug('EME: _onWebkitNeedKey previously handled');

            return;
        }

        this.debug('EME: _onWebkitNeedKey', event);

        // If our event initData contains query params, we'll need to keep them for when we POST off for a license request
        // We sanitize the URL below before constructing a URL object because we've seen some junk at the start of the protocol before
        this._fairPlayLicenseUriParams = new URL(
            uintArrayToString(event.initData).replace(/^[^:]+/, 'https')
        ).search;
        this.debug(
            'EME: calculated _fairPlayLicenseUriParams',
            this._fairPlayLicenseUriParams
        );

        const contentId = new URL(uintArrayToString(event.initData).substr(1))
            .hostname;
        const initData = concatInitDataIdAndCertificate(
            event.initData,
            contentId,
            this.certificate
        );

        // Unlike setMediaKeys, this is not promise based.
        this.videoElement.webkitSetMediaKeys(
            new window.WebKitMediaKeys(this.activeKeySystemName)
        );

        const newSession = this.videoElement.webkitKeys.createSession(
            'video/mp4',
            initData
        );

        newSession.initData = event.initData;
        newSession.initDataType = event.initDataType;

        newSession.addEventListener('webkitkeymessage', this._onWebkitMessage);
        newSession.addEventListener('webkitkeyerror', this._onWebkitKeyError);

        this._activeSessions.push(newSession);
    };

    _onWebkitMessage = (event: WebkitKeyMessageEvent): void => {
        const {message, target: session} = event;

        if (session.processing) {
            this.debug('EME: _onWebkitMessage already processing');

            return;
        }

        this.debug('EME: _onWebkitMessage', event);
        session.processing = true;

        this.getLicenseFromMessageRequest(message)
            .then(({xhrResponse, xhrRequestObject}) => {
                this.startHeartBeating(message, xhrRequestObject, session);

                return session.update(new Uint8Array(xhrResponse));
            })
            .catch((error) => {
                if (
                    error?.message ===
                    MESSAGE_LICENSE_REQUEST_CANCELED_BY_CLEANUP
                ) {
                    this.debug('EME: _onMessage aborted due to cleanup');

                    return;
                }

                console.error(
                    'VideoFsNativeEme: _onMessage Failed to update session from license request',
                    error
                );
                this.onError(
                    ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                    error.message,
                    error
                );
            })
            .finally(() => {
                session.processing = false;

                this.debug('EME: _onWebkitMessage completed');
            });
    };

    _onWebkitKeyError = (event: WebkitKeyErrorEvent): void => {
        console.error('VideoFsNativeEme: _onWebkitKeyError failed', event);
        this.onError(ERROR_CODES.MEDIA_ERR_ENCRYPTED, event.message);
    };

    _onEncryptedEvent = (event: MediaEncryptedEventInit): void => {
        if (event.initData && this.hasActiveSession(event.initData)) {
            this.debug('EME: _onEncryptedEvent previously handled');

            return;
        }

        // Make sure we don't fire multiple requests (PS4)
        this.hasGeneratedRequest = true;

        this.debug('EME: _onEncryptedEvent', event);

        const newSession = this.videoElement.mediaKeys?.createSession();

        if (newSession && event.initData && event.initDataType) {
            newSession.initData = event.initData;
            newSession.initDataType = event.initDataType;

            newSession.addEventListener('message', this._onMessage);

            newSession
                .generateRequest(event.initDataType, event.initData)
                .catch((error) => {
                    console.error(
                        'VideoFsNativeEme: _onEncryptedEvent Failed to generate a license request',
                        error
                    );
                    this.onError(
                        ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                        error.message,
                        error
                    );
                    this.hasGeneratedRequest = false;
                });

            this._activeSessions.push(newSession);
        }
    };

    _onMessage = (event: MediaKeyMessageEvent): void => {
        const {message, target: session} = event;

        if (session.processing) {
            this.debug('EME: _onMessage already processing');

            return;
        }

        this.debug('EME: _onMessage', event);
        session.processing = true;

        this.getLicenseFromMessageRequest(message)
            .then(({xhrResponse, xhrRequestObject}) => {
                this.startHeartBeating(message, xhrRequestObject, session);

                return session.update(xhrResponse);
            })
            .catch((error) => {
                if (
                    error?.message ===
                    MESSAGE_LICENSE_REQUEST_CANCELED_BY_CLEANUP
                ) {
                    this.debug('EME: _onMessage aborted due to cleanup');

                    return;
                }

                console.error(
                    'VideoFsNativeEme: _onMessage Failed to update session from license request',
                    error
                );
                this.onError(
                    ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                    error.message,
                    error
                );
            })
            .finally(() => {
                session.processing = false;
                this.debug('EME: _onMessage completed');
            });
    };

    hasActiveSession(newInitData: ArrayBuffer): boolean {
        // The arrayBuffers check doesn't work on PS4 so we'll track it a little differently
        if (isPs4()) {
            return this.hasGeneratedRequest;
        }

        return this._activeSessions.some((session) => {
            if (!session.initData) {
                return false;
            }

            // This does not work for ie11 and playready. Because the session doesn't continue to hold initData.
            return arrayBuffersEqual(newInitData, session.initData);
        });
    }

    getLicenseFromMessageRequest = async (
        licenseMessageRequest: ArrayBuffer
    ): Promise<MassageRequestSuccessResponse> => {
        this.debug('EME: getLicenseFromMessageRequest', licenseMessageRequest);

        // PlayReady requires us to do some massaging from the licenseMessageRequest.
        // Use those for additional headers and the body content to the license server.
        const {playReadyHeaders, playReadyChallengeBody} =
            this.getPlayReadyChallenges(licenseMessageRequest);

        const headers = {
            ...(this.activeKeySystemName === FAIR_PLAY_KEY_SYSTEM && {
                'content-type': 'application/x-www-form-urlencoded',
            }),
            ...playReadyHeaders,

            // Grab headers from any getHttpRequestHeaders method.
            ...(this.activeKeySystemOptions?.getHttpRequestHeaders &&
                (await this.activeKeySystemOptions.getHttpRequestHeaders())),
        };

        return this.makeMessageRequest({
            uri:
                this.activeKeySystemOptions?.licenseUri +
                (this._fairPlayLicenseUriParams || ''),
            messageBody: playReadyChallengeBody || licenseMessageRequest,
            headers,
            withCredentials: this.options.withCredentials,
        });
    };

    makeMessageRequest = ({
        uri,
        messageBody,
        method = 'POST',
        responseType = 'arraybuffer',
        withCredentials = true,
        headers = {},
    }: MassageRequestParams): Promise<MassageRequestSuccessResponse> => {
        return new Promise((resolve, reject) => {
            const licenseRequest = new XMLHttpRequest();

            this._activeXhrs.push({
                request: licenseRequest,
                abortRequest() {
                    licenseRequest.abort();
                    reject({
                        message: MESSAGE_LICENSE_REQUEST_CANCELED_BY_CLEANUP,
                    });
                },
            });

            licenseRequest.open(method, uri, true);
            licenseRequest.responseType = responseType;
            licenseRequest.withCredentials = withCredentials; // If we have cookies for this domain, use them.

            Object.entries(headers).forEach(([key, value]) => {
                licenseRequest.setRequestHeader(key, value);
            });

            licenseRequest.onload = () => {
                this._activeXhrs = this._activeXhrs.filter(
                    ({request}) => request !== licenseRequest
                );

                const {response, status} = licenseRequest;

                if (status && status >= 200 && status < 400) {
                    this.debug(
                        'EME: makeMessageRequest resolving',
                        licenseRequest
                    );

                    resolve({
                        xhrResponse: response,
                        xhrRequestObject: licenseRequest,
                    });
                } else {
                    this.debug(
                        'EME: makeMessageRequest rejecting',
                        licenseRequest
                    );

                    reject(
                        new PlayerTechXhrError({
                            type: VIDEO_FS_ERROR_TYPES.EME_REQUEST,
                            message: `NativeEme: getLicenseFromMessageRequest response ${status}`,
                            XMLHttpRequest: licenseRequest,
                        })
                    );
                }
            };

            licenseRequest.ontimeout = (event) => {
                console.error(
                    'VideoFsNativeEme: getLicenseFromMessageRequest timeout',
                    event
                );
                reject(
                    new PlayerTechXhrError({
                        type: VIDEO_FS_ERROR_TYPES.EME_REQUEST,
                        message:
                            'NativeEme: getLicenseFromMessageRequest timeout',
                        XMLHttpRequest: licenseRequest,
                    })
                );
            };

            licenseRequest.onerror = (event) => {
                console.error(
                    'VideoFsNativeEme: getLicenseFromMessageRequest failed',
                    event
                );
                reject(
                    new PlayerTechXhrError({
                        type: VIDEO_FS_ERROR_TYPES.EME_REQUEST,
                        message:
                            'NativeEme: getLicenseFromMessageRequest error',
                        XMLHttpRequest: licenseRequest,
                    })
                );
            };

            licenseRequest.send(messageBody);
        });
    };

    getPlayReadyChallenges(licenseMessageRequest: ArrayBuffer): {
        playReadyHeaders?: Record<string, string>;
        playReadyChallengeBody?: string;
    } {
        if (this.activeKeySystemName !== PLAY_READY_KEY_SYSTEM) {
            return {};
        }

        this.debug('EME: getPlayReadyChallenges', licenseMessageRequest);

        // Stolen from videofs-contrib-eme https://github.com/videojs/videojs-contrib-eme/blob/master/src/playready.js
        // getMessageContents()
        const xml = new window.DOMParser().parseFromString(
            // @TODO do we want to support UTF-8?
            String.fromCharCode(...new Uint16Array(licenseMessageRequest)),
            'application/xml'
        );
        const [headersElement] = xml.getElementsByTagName('HttpHeaders');
        const playReadyHeaders: Record<string, string> = {};

        if (headersElement) {
            const headerNames = headersElement.getElementsByTagName('name');
            const headerValues = headersElement.getElementsByTagName('value');

            for (let i = 0; i < headerNames.length; i++) {
                const headerName = headerNames[i]?.childNodes[0]?.nodeValue;
                const headerValue = headerValues[i]?.childNodes[0]?.nodeValue;

                if (headerName && headerValue) {
                    playReadyHeaders[headerName] = headerValue;
                }
            }
        }

        const [challengeElement] = xml.getElementsByTagName('Challenge');
        const challengeElementValue =
            challengeElement?.childNodes[0]?.nodeValue;
        let playReadyChallengeBody;

        if (challengeElement && challengeElementValue) {
            playReadyChallengeBody = window.atob(challengeElementValue);
        }
        // eo getMessageContents

        const returnPayload = {
            playReadyHeaders,
            playReadyChallengeBody,
        };

        this.debug('EME: getPlayReadyChallenges returning', returnPayload);

        return returnPayload;
    }

    startHeartBeating(
        message: ArrayBuffer,
        xhrRequestObject: XMLHttpRequest,
        session: MediaKeySession
    ): void {
        if (!this.activeKeySystemOptions) {
            return;
        }

        const {heartbeat, heartbeatHeaderName, heartbeatAdditionalHeaders} =
            this.activeKeySystemOptions;

        if (!heartbeat) {
            // If we haven't been asked to do heartbeat for this type of DRM, don't.
            this.debug('EME: startHeartBeating not required');

            return;
        }

        this.debug('EME: startHeartBeating');

        // Fantastically this is not case sensitive.  \o/
        const licensePollTimeInSeconds = Number(
            xhrRequestObject.getResponseHeader(heartbeatHeaderName ?? '')
        );

        if (
            !licensePollTimeInSeconds ||
            !Number.isFinite(licensePollTimeInSeconds)
        ) {
            // eslint-disable-next-line no-console
            console.debug(
                `VideoFsNativeEme: start heartbeatHeaderName value missing or invalid: ${heartbeatHeaderName}: ${xhrRequestObject.getResponseHeader(
                    heartbeatHeaderName ?? ''
                )}`, // eslint-disable-line max-len
                xhrRequestObject.getAllResponseHeaders()
            );

            return;
        }

        this.debug(
            `EME: startHeartBeating request timeout detected for ${licensePollTimeInSeconds}s`
        );

        // Shouldn't be an existing timer, but who knows.
        clearTimeout(this.heartbeatTimeout);

        this.heartbeatTimeout = window.setTimeout(async () => {
            this.debug('EME: heartbeatTimeout running');

            // But this is specifically for PR 3 and higher. PlayReady 2 just polls off.... 😬
            // But VideoFS doesn't today support anything lower than PR4, _maybe_ for xbox we might need it.
            if (this.activeKeySystemName === PLAY_READY_KEY_SYSTEM) {
                this.debug(
                    'EME: heartbeatTimeout playready, create session and add new listeners'
                );
                // make new temp session.  Let onmessage call us when it needs a license and everything else the same.
                const newSession = this.videoElement.mediaKeys?.createSession();

                if (!newSession || !session.initDataType || !session.initData) {
                    return;
                }

                newSession.initData = session.initData;
                newSession.initDataType = session.initDataType;

                newSession.addEventListener('message', this._onMessage);
                newSession
                    .generateRequest(session.initDataType, session.initData)
                    .catch((error) => {
                        console.error(
                            'VideoFsNativeEme: heartbeatTimeout Failed to generate a license request',
                            error
                        );
                        this.onError(
                            ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                            error.message,
                            error
                        );
                    });

                this._activeSessions.push(newSession);

                return;
            }

            const {playReadyHeaders, playReadyChallengeBody} =
                this.getPlayReadyChallenges(message);

            const headers = {
                ...(this.activeKeySystemName === FAIR_PLAY_KEY_SYSTEM && {
                    'content-type': 'application/x-www-form-urlencoded',
                }),
                ...playReadyHeaders,

                // Grab headers from any getHttpRequestHeaders method.
                ...(this.activeKeySystemOptions?.getHttpRequestHeaders &&
                    (await this.activeKeySystemOptions.getHttpRequestHeaders())),

                // Make sure additional headers override anything specified in the usual headers.
                // This is for things like 'initial request send 0, subsequent send 1' scenarios.
                ...heartbeatAdditionalHeaders,
            };

            return (
                this.makeMessageRequest({
                    uri:
                        this.activeKeySystemOptions?.licenseUri +
                        (this._fairPlayLicenseUriParams || ''),
                    messageBody: playReadyChallengeBody || message,
                    headers,
                    withCredentials: this.options.withCredentials,
                })
                    .then(({/* xhrResponse, */ xhrRequestObject}) => {
                        this.startHeartBeating(
                            message,
                            xhrRequestObject,
                            session
                        );
                    })
                    // need to throw on heartbeat.  This is because this probably isn't a duration based
                    // license, or concurrency error, or whatever, and we need to force stop (because drm isn't going to).
                    .catch((error) => {
                        if (
                            error?.message ===
                            MESSAGE_LICENSE_REQUEST_CANCELED_BY_CLEANUP
                        ) {
                            this.debug('EME: Heartbeat stopped due to cleanup');

                            return;
                        }

                        console.error(
                            'VideoFsNativeEme: heartbeatTimeout failed',
                            error
                        );
                        this.onError(
                            ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                            error.message,
                            error
                        );
                    })
            );
        }, licensePollTimeInSeconds * 1000);
    }

    removeEventListeners(): void {
        if (window.WebKitMediaKeys) {
            // For Safari
            this.videoElement.removeEventListener(
                'webkitneedkey',
                this._onWebkitNeedKey,
                false
            );

            this._activeSessions.forEach((session) => {
                session.removeEventListener(
                    'webkitkeymessage',
                    this._onWebkitMessage
                );
                session.removeEventListener(
                    'webkitkeyerror',
                    this._onWebkitKeyError
                );
            });
        } else if (window.MediaKeys) {
            // For Chrome, MS Edge, Firefox
            this.videoElement.removeEventListener(
                'encrypted',
                this._onEncryptedEvent
            );

            this._activeSessions.forEach((session) => {
                session.removeEventListener('message', this._onMessage);
            });
        }
    }

    async destroy(): Promise<void> {
        clearTimeout(this.heartbeatTimeout);

        this._onWillDestroyCallbacks.forEach(attempt);

        // Chrome requires resetting src and mediaKeys before changing mediaKeys on a media element
        // https://github.com/Dash-Industry-Forum/dash.js/issues/623
        if (window.MediaKeys) {
            if (this.videoElement.src) {
                this.videoElement.src = '';
            }

            // We need to ensure that we don't try to setMediaKeys after the player has been destroyed.
            await this._setMediaKeysPromise;
            await this.videoElement.setMediaKeys(null);
        }

        this.removeEventListeners();

        // Wait until all active sessions are closed
        // @TODO: There was a suggestion from Sean to try the following commented block instead,
        // however, there seems to be a higher incidence of DRM errors with it,
        // so leaving this here as a suggestion for future refactoring
        /*
            await Promise.all(
                this._activeSessions
                    .map(method('close'))
                    .map((closePromise) => closePromise?.catch( // needs ?. because close on safari doesn't return a promise
                        (err) => console.error('VideoFsNativeEme: session close failed', err)
                    ))
            );
        */
        await Promise.all(
            this._activeSessions.map((session) => {
                return session.close();
            })
        ).catch((error) => {
            console.warn('VideoFsNativeEme: session close failed', error);
        });

        // Cancel open licenseRequest(s) if applicable
        this._activeXhrs.forEach(method('abortRequest'));
    }

    debug(...args: [string, unknown?]): void {
        if (this.options.DEBUG_EME) {
            console.debug(...args); // eslint-disable-line no-console
        }
    }
}

function concatInitDataIdAndCertificate(
    initData: Uint16Array | Uint8Array,
    id: string,
    cert: Uint8Array
): Uint8Array {
    const inputIdAsArray = stringToUint16Array(id);
    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    let offset = 0;
    const buffer = new ArrayBuffer(
        initData.byteLength +
            4 +
            inputIdAsArray.byteLength +
            4 +
            cert.byteLength
    );
    const dataView = new DataView(buffer);
    const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);

    initDataArray.set(initData);
    offset += initData.byteLength;

    dataView.setUint32(offset, inputIdAsArray.byteLength, true);
    offset += 4;

    const idArray = new Uint16Array(buffer, offset, inputIdAsArray.length);

    idArray.set(inputIdAsArray);
    offset += idArray.byteLength;

    dataView.setUint32(offset, cert.byteLength, true);
    offset += 4;

    const certArray = new Uint8Array(buffer, offset, cert.byteLength);

    certArray.set(cert);

    return new Uint8Array(buffer, 0, buffer.byteLength);
}

function stringToUint16Array(string: string): Uint16Array {
    const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
    const array = new Uint16Array(buffer);

    for (let i = 0, strLen = string.length; i < strLen; i++) {
        array[i] = string.charCodeAt(i);
    }

    return array;
}

function uintArrayToString(array: Uint16Array | Uint8Array): string {
    const uint16array = new Uint16Array(array.buffer);

    return String.fromCharCode(...uint16array);
}

// Borrowed from https://github.com/videojs/videojs-contrib-eme/blob/master/src/utils.js to compare initData from encrypted events.
function arrayBuffersEqual(
    arrayBuffer1: ArrayBuffer,
    arrayBuffer2: ArrayBuffer
): boolean {
    if ([arrayBuffer1, arrayBuffer2].every(isArrayBuffer) === false) {
        console.error(
            'VideoFsNativeEme: arrayBuffersEqual missing or invalid arrayBuffer to compare',
            arrayBuffer1,
            arrayBuffer2
        );

        return false;
    }

    if (arrayBuffer1 === arrayBuffer2) {
        return true;
    }

    if (arrayBuffer1.byteLength !== arrayBuffer2.byteLength) {
        return false;
    }

    const dataView1 = new DataView(arrayBuffer1);
    const dataView2 = new DataView(arrayBuffer2);

    for (let i = 0; i < dataView1.byteLength; i++) {
        if (dataView1.getUint8(i) !== dataView2.getUint8(i)) {
            return false;
        }
    }

    return true;
}
