import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';
import nicerStringify from '@fsa/fs-commons/lib/utils/nicer-stringify';

import {DEFAULT_VIMOND_ENV, PROXY_RESOURCE_URL, DEFAULT_STREAM_TIMEOUT as delay} from '../../utils/constants';

export default function getLiveSpecialEventsStream({
    count,
    sport,

    freq = 0,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const retries = process && process.browser ? 1 : 0;

    const url = `${PROXY_RESOURCE_URL[vimondEnv]}livespecialevents.json;${encodeURIComponent(sport)}?${nicerStringify({
        page: 0,
        size: count,
        fsapi: true
    })}`;

    return getApiFromRetryWithError({delay, freq, retries, url})
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            }

            const errorMessage = `FS-HAWK-WIDGETS: unable to get live special events data (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
