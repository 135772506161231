import React from 'react';
import bacon from 'baconjs';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import ForgotPasswordComponent from '../components/forgot-password';
import forgotPassword from '../streams/forgot-password';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function ForgotPassword(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        vimondEnv: this.settings.vimondEnv || 'prod'
    };
}

ForgotPassword.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

ForgotPassword.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Forgot Password',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

ForgotPassword.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <ForgotPasswordComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

ForgotPassword.prototype.getData = function () {
    const {sport, vimondEnv} = this.config;

    const forgotPasswordBus = new bacon.Bus();

    const forgotPasswordStream = forgotPasswordBus
        .flatMapLatest((emailAddress) => forgotPassword({
            emailAddress,
            sport,
            vimondEnv
        }))
        .startWith(false);

    const forgotPasswordError = forgotPasswordStream
        .errors()
        .mapError('.message')
        .startWith(null);

    const isLoading = bacon.mergeAll(
        forgotPasswordBus.map(true),
        forgotPasswordStream.map(false),
        forgotPasswordError.map(false)
    );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isSuccess: forgotPasswordStream,
            error: forgotPasswordError,
            isLoading: isLoading.toProperty(false),
            onSubmit: (emailAddress) => {
                forgotPasswordBus.push(emailAddress);
            },
            sportDetails: getSportDetails(this.config.sport)
        }),
        iso: bacon.combineTemplate({
        })
    });
};

ForgotPassword.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <ForgotPasswordComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <ForgotPasswordComponent {...data.view} />,
            'hawkwidgets-forgot-password',
            {
                settings:  this.settings,
                data:      data.iso
            }
        );
    }
};

ForgotPassword.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new ForgotPassword(element, settings);
}

pageBoot(ForgotPassword, 'hawkwidgets-forgot-password');
