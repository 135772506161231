export function bingPixelTrackerRegisterSale({totalCost, currency}) { // eslint-disable-line import/prefer-default-export
    if (process.browser) {
        try {
            window.uetq = window.uetq || [];
            window.uetq.push({
                ec: 'signups',
                ea: 'subscribe',
                gv: totalCost,
                gc: currency
            });
        } catch (e) {
            console.error('Unable to push to bing pixel tracker', e.stack);
        }
    }
}
