import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';

const HudContainer = styled.dialog<{isVisible: boolean}>`
    position: static;
    transition: ${transition('transform')};
    margin: 0;
    border: none;
    background: transparent;
    padding: 0;
    height: 100%;
    pointer-events: all;

    &:not([open]) {
        /* Don't hide it when closed, so it can slide out */
        display: block;
        transform: translateX(100%);
        pointer-events: none;
    }
`;

const HudContentPositioner = styled.div`
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
`;

export type Props = {
    /** Additional classname(s) */
    className?: string;
    /** The buttons for the right-side tabs */
    tabList?: React.ReactNode;
    /** The content panels */
    tabPanels?: React.ReactNode;
    /** Is the HUD visible? */
    isVisible?: boolean;
};

const OR05HudTray = ({
    isVisible,
    tabList,
    tabPanels,
    className,
}: Props): React.ReactElement => (
    <HudContainer
        isVisible={!!isVisible}
        open={isVisible}
        className={classnames('OR05HudTray', className)}
    >
        <HudContentPositioner>
            {tabList}
            {tabPanels}
        </HudContentPositioner>
    </HudContainer>
);

OR05HudTray.displayName = 'OR05HudTray';

export default OR05HudTray;
