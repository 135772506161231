import ReactDOMServer from 'react-dom/server';
import cuid from 'cuid';
import CircularJSON from 'circular-json';
import noop from 'lodash/noop';

import {asStringLiteral, htmlEncode} from '../utils/string';

/**
 * Generate a FISO widget's server-side-rendered HTML.
 *
 * @param {ReactElement} reactElement      - the React element to render
 * @param {String}       tagName           - the widget's tag
 * @param {Object}       fisoBootArguments - the data to attach
 * @returns {String} the widget's server-side-rendered HTML
 */
export function renderToHtml(reactElement, tagName, fisoBootArguments) {
    const uniqueId  = cuid();
    const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

    return `<div id="${uniqueId}">${reactHtml}</div>
<script>
  window.fisoBoot = window.fisoBoot || {};
  window.fisoBoot['${tagName}'] = window.fisoBoot['${tagName}'] || {};
  window.fisoBoot['${tagName}']['${uniqueId}'] = ${asStringLiteral(CircularJSON.stringify(fisoBootArguments))};
</script>`;
}

/**
 * ______ _________________ _____ _____   ___ _____ ___________
 * |  _  \  ___| ___ \ ___ \  ___/  __ \ / _ \_   _|  ___|  _  \
 * | | | | |__ | |_/ / |_/ / |__ | /  \// /_\ \| | | |__ | | | |
 * | | | |  __||  __/|    /|  __|| |    |  _  || | |  __|| | | |
 * | |/ /| |___| |   | |\ \| |___| \__/\| | | || | | |___| |/ /
 * |___/ \____/\_|   \_| \_\____/ \____/\_| |_/\_/ \____/|___/
 *
 * Generate a FISO widget's server-side-rendered HTML.
 *
 * @param {ReactElement} reactElement      - the React element to render
 * @param {String}       tagName           - the widget's tag
 * @param {Object}       fisoBootArguments - the data to attach
 * @returns {String} the widget's server-side-rendered HTML
 * @deprecated since 7.18.0
 */
export function toHtml(reactElement, tagName, fisoBootArguments) {
    if (process.browser && process.env.NODE_ENV !== 'production') {
        console.warn('toHtml in render deprecated');
        console.warn('Please use {renderToHtml} from @fsa/fs-commons/lib/iso/render');
    }

    const uniqueId  = cuid();
    const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

    return `<div id="${uniqueId}">${reactHtml}</div>
<script>
  window.fisoBoot = window.fisoBoot || {};
  window.fisoBoot['${tagName}'] = window.fisoBoot['${tagName}'] || {};
  window.fisoBoot['${tagName}']['${uniqueId}'] = ${JSON.stringify(CircularJSON.stringify(fisoBootArguments))};
</script>`; // eslint-disable-line max-len
}

/**
 * Accepts "event" returned by bacon.subscribe and provides an error reponse
 * if the event type is a bacon error. Will call appropriate callback route with
 * HTML output.
 *
 * @param  {String}      options.identifier             Identifier for warn message
 * @param  {Event}       options.event                  Event from subscribed stream
 * @param  {String}      options.contentType            Specifies what header to use on successful response
 * @param  {Function}    options.onReject               Function to execute on reject
 * @param  {Function}    options.onResolve              Function to execute on Resolve
 * @param  {Function}    options.render                 Render function
 * @param  {Number}      options.expirySecondOnFailure  Expiry second on failure
 * @param  {Number}      options.expirySecondOnSuccess  Expiry second on success
 *
 * @return {void}                                       Executes onResolver or onReject function
 */
export function handleStreamForIsoRender({
    identifier,
    event,

    contentType = 'text/html',
    onReject = noop,
    onResolve = noop,
    render = noop,
    expirySecondOnFailure = 5,
    expirySecondOnSuccess = 30,
    onErrorStatusCode = 424 // 424: Failed Dependency
}) {
    let errorMessage;

    if (event.isError()) {
        errorMessage = htmlEncode(event.error);

        console.error(`Error: ${identifier} failed to render: ${errorMessage}`); // error so slack gets notified.

        onReject({
            html: `<div class = "dev-only" style="display: none;"><pre><code>${errorMessage}</pre></code>`,
            expiresIn: expirySecondOnFailure * 1000, // in ms
            statusCode: onErrorStatusCode
        });
    } else if (event.hasValue()) {
        onResolve({
            contentType,
            html: render(event.value()),
            expiresIn: expirySecondOnSuccess * 1000 // in ms
        });
    }
}
