import React from 'react';
import propTypes from 'prop-types';

const getGreeting = (sport, firstName) => {
    if (firstName) {
        return `Welcome back ${firstName}`;
    } else if (sport === 'afl') {
        return 'Welcome Footy Fans!';
    } else if (sport === 'league') {
        return 'Welcome League Fans!';
    }
};

const CAPTIONS_BY_SPORT = {
    afl: (
        <p className="fiso-hawk-promo-header__content--caption-text">
            Watch AFL is the official way to stream every match of the Toyota AFL Premiership overseas.
        </p>
    ),
    league: (
        <p className="fiso-hawk-promo-header__content--caption-text">
            Watch NRL is the official way to stream every match of the Telstra NRL Premiership overseas.<br />
            <i>This service is not available from Australia, New Zealand and the Pacific Islands.</i>
        </p>
    )
};

const PromoHeader = ({
    banner,
    promoHeaderData,
    userFirstName,
    sport
}) => {
    const {
        promoImageSrc,
        promoTitle,
        promoText
    } = promoHeaderData;

    return (
        <section className="fiso-hawk-promo-header" style={{backgroundImage: `url("${promoImageSrc}")`}}>
            <div className="fiso-hawk-promo-header__content">
                <h1 className="fiso-hawk-promo-header__content--header-text">{getGreeting(sport, userFirstName)}</h1>
                {CAPTIONS_BY_SPORT[sport]}
                {banner}
                {(promoText || promoTitle) && (
                    <div className="fiso-hawk-promo-header__content--offer-box">
                        {promoTitle && <h3>{promoTitle}</h3>}
                        {promoText && <p>{promoText}</p>}
                    </div>
                )}
            </div>
        </section>
    );
};

PromoHeader.propTypes = {
    banner: propTypes.node,
    promoHeaderData: propTypes.shape({
        promoImageSrc: propTypes.string,
        promoTitle: propTypes.string,
        promoText: propTypes.string
    }),
    userFirstName: propTypes.string,
    sport: propTypes.string
};

export default PromoHeader;
