/**
 * Converts an integer into a string with an ordinal postfix.
 * eg: toOrdinal(1) gives 1st
 *
 * @param number - Input number to get the ordinal value
 *
 * @returns Ordinal string for given number
 */
export default function toOrdinal(number: number): string {
    if (!Number.isFinite(number)) {
        return '';
    }

    const base = String(number);
    const lastTwoDigits = number % 100;
    const betweenElevenAndThirteen = lastTwoDigits >= 11 && lastTwoDigits <= 13;
    const lastChar = base.charAt(base.length - 1);

    if (betweenElevenAndThirteen) {
        return `${base}th`;
    } else if (lastChar === '1') {
        return `${base}st`;
    } else if (lastChar === '2') {
        return `${base}nd`;
    } else if (lastChar === '3') {
        return `${base}rd`;
    } else {
        return `${base}th`;
    }
}
