import React from 'react';

const GA143Up3 = (): React.ReactElement => (
    <svg width="74px" height="30px" viewBox="0 0 74 30">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.780911, 0.065217)" fill="#FFFFFF">
                <rect
                    id="Area-vid"
                    x="0"
                    y="0"
                    width="52.5639913"
                    height="28.9782609"
                />
                <rect
                    id="Area-vid"
                    x="55.2595806"
                    y="2.69565217"
                    width="17.5213304"
                    height="10.1086957"
                />
                <rect
                    id="Area-vid"
                    x="55.2595806"
                    y="15.5"
                    width="17.5213304"
                    height="10.1086957"
                />
            </g>
        </g>
    </svg>
);

GA143Up3.displayName = 'GA143Up3';

export default GA143Up3;
