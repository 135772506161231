import superagent from 'superagent';

import {streamFromApi, getVimondHeaders} from '../../utils/api';
import authenticated from '../authenticated';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function putPaymentMethod({
    order,
    paymentMethod,
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/order/${order.id}`;
    const updatePaymentMethod = {
        order: {
            id: order.id,
            userId: order.userId,
            userPaymentMethod: paymentMethod.userPaymentMethod
        }
    };

    return authenticated().flatMapLatest((authToken) => {
        const request = superagent
            .put(resourceUrl)
            .set(getVimondHeaders({authToken, sport, version: 1}))
            .send(updatePaymentMethod);

        return streamFromApi(request).map('.body');
    });
}
