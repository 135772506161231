import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC28RelVid = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M66.36 8.24v24.995H27.726V8.24H66.36M74.6 0H19.488v41.475h55.111V0z"
            transform="translate(13 20)"
        />
        <path
            d="M55.894 51.143L9.847 51.143 9.847 20.27 13.967 20.27 13.967 47.023 55.894 47.023z"
            transform="translate(13 20)"
        />
        <path
            d="M66.36 8.24v24.995H27.726V8.24H66.36M74.6 0H19.488v41.475h55.111V0z"
            transform="translate(13 20)"
        />
        <path
            d="M55.894 51.143L9.847 51.143 9.847 20.27 13.967 20.27 13.967 47.023 55.894 47.023z"
            transform="translate(13 20)"
        />
        <path
            d="M46.048 60.866L0 60.866 0 29.993 4.12 29.993 4.12 56.746 46.048 56.746z"
            transform="translate(13 20)"
        />
    </Ic>
);

IC28RelVid.displayName = 'IC28RelVid';

export default IC28RelVid;
