import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {DEFAULT_VIMOND_ENV, PROXY_RESOURCE_URL} from '../../utils/constants';

export default function ({
    freq = 0,
    sport,
    seriesId,
    seasonId,
    roundNumber,
    teamId,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const retries = (process && process.browser) ? 3 : 0;
    const url = `${PROXY_RESOURCE_URL[vimondEnv]}` +
    `sports/${sport}` +
    `/series/${seriesId}` +
    `/seasons/${seasonId}` +
    `/${teamId === null ? `rounds/${roundNumber}` : `teams/${teamId}`}` +
    '/fixturesandresults.json';

    return getApiFromRetryWithError({
        url,
        freq,
        retries
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            }
            const errorMessage = `FS-HAWK-WIDGETS: Unable to get Fixtures and Results (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
