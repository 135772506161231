import {observer} from 'mobx-react-lite';
import React from 'react';

import TM01DisplayTime from '../../../component-library/molecules/tm/01-display-time';
import {useChromecastSenderContext} from '../context';

const DisplayTime = observer(() => {
    const chromecastSenderContext = useChromecastSenderContext();

    const {currentTime, duration, isLiveStream, isOnEdge, scrubBarType} =
        chromecastSenderContext?.values ?? {};

    return (
        <TM01DisplayTime
            currentTime={currentTime}
            duration={duration}
            isLiveStream={isLiveStream}
            isOnEdge={isOnEdge}
            scrubBarType={scrubBarType}
        />
    );
});

export default DisplayTime;
