import superAgent from 'superagent';

import {getLowestFrequency} from './helpers';
import Response from './response';
let cachedResponses = {}; // Object to contain the requests and data
let gcTimer; // garbage collection timer

// Making response object available on global scope to ease debugging
if (process && process.browser) {
    window.fsRequestManagerCachedResponses = cachedResponses;
}

export function getFeed(
    url,
    callback,
    freq = 0,
    {
        cacheTtl = process && process.browser ? 30 * 1000 : 3 * 1000
    } = {}
) {
    // first check if API response already exists
    if (!cachedResponses[url]) {
        cachedResponses[url] = {
            timestamp: new Date().getTime(),
            responseObject: {},
            nextRefresh: null,
            fetching: true,
            cacheTtl,
            callbacks: [
                {
                    frequency: freq,
                    callback
                }
            ]
        };

        getResponse(url);

        return;
    }

    const cacheStillValid = new Date().getTime() - cachedResponses[url].timestamp < cachedResponses[url].cacheTtl;

    // If the feed exists
    if (cachedResponses[url].fetching) {
        // and is fetching, push our callback to the stack.
        cachedResponses[url].callbacks.push({callback, frequency: freq});

        return;
    } else if (freq === 0 && cacheStillValid) {
        // @todo Wondering if we should also check the response object for validity.
        // Otherwise we can return cached failed objects.
        // also check cachedResponses[url].responseObject.responseConsideredValid.
        // Otherwise we could hand back cached 500 errors, for example.
        // It's more of a wondering question rather than a we should absolutely do this check.
        // If it was 404 for example, we'd probably want to keep that cache as
        // it's unlikely to change within 30 seconds maybe

        // and we're not fetching as well the cache hasn't expired, but want a cached response
        const thisResponseObject = cachedResponses[url].responseObject; // temp var so it's not garbage collected in async-ness

        setTimeout(() => {
            // In the occurance that we have cache, we need to
            // reply to whatever is asking in a async fashion (rather than synchronous)
            callback(thisResponseObject);
        });

        return;
    }

    // We may need to see if we need to refresh our widget timer.
    // Lets find the lowestFrequency currently in our stack before we push ourselves to it.
    const lowestFrequency = getLowestFrequency(cachedResponses[url].callbacks);

    // Feed exists, but isn't fetching right now.
    // Add it to the stack
    cachedResponses[url].callbacks.push({callback, frequency: freq});

    // This feed is currently inactive. Restart it!
    if (cachedResponses[url].nextRefresh === null) {
        getResponse(url);

        return;
    }

    // The feed is active, but we have no idea how much longer we have to wait for it.
    // If something has asked for 30 seconds, and we want it in 3 seconds, we should cancel
    // the current timer, and just deal with it now.
    // If the timer is 3 seconds, and we're asking for anything less (or more) we can freaking wait.
    // The measurement for this is going to be 50% less. (So if something is 30 seconds, and we ask for 15)
    if (!lowestFrequency || (lowestFrequency / 2) > freq) {
        clearTimeout(cachedResponses[url].nextRefresh);
        cachedResponses[url].nextRefresh = null;
        getResponse(url);
    } else {
        // Give back the cached copy at this point, will get updated copies on next cycle
        callback(cachedResponses[url].responseObject);
    }
}

export function stopFeed(url, callbackFunction) {
    if (!cachedResponses[url] || !cachedResponses[url].callbacks) {
        return;
    }

    let feedDetails = cachedResponses[url];

    let keptCallbacks = feedDetails.callbacks.reduce((accumulator, currentCallbackObject) => {
        if (currentCallbackObject.callback === callbackFunction) {
            if (feedDetails.fetching) {
                if (feedDetails.callbacks.length === 1) {
                    //  If it's the only callback in the list, actually abort the request.
                    //  Make that we're no longer fetching. Need to ensure the next callback loaded
                    //  for this aborted request starts up again.
                    feedDetails.superAgentRequest.abort();
                    feedDetails.fetching = false;
                } else {
                    // Feed is currently waiting for things to happen.
                    // Set it to a frequency of 0 and it'll finish up.
                    currentCallbackObject.frequency = 0;
                    accumulator.push(currentCallbackObject);
                }
            }
        } else {
            accumulator.push(currentCallbackObject);
        }

        return accumulator;
    }, []);

    if (keptCallbacks.length === 0) {
        clearTimeout(cachedResponses[url].nextRefresh);
        cachedResponses[url].nextRefresh = null;
    }

    cachedResponses[url].callbacks = keptCallbacks;
}

export function stopAll() {
    // Stop all callbacks and timer
    Object.keys(cachedResponses).forEach(function (url) {
        clearTimeout(cachedResponses[url].nextRefresh);
        cachedResponses[url].nextRefresh = null;
        cachedResponses[url] = {};
    });

    // Empty all cached responses
    cachedResponses = {};
}

export function garbageCollectionStart() {
    const collectionFrequency = 30 * 1000;

    gcTimer = setInterval(garbageColection, collectionFrequency);
}

export function garbageCollectionStop() {
    clearTimeout(gcTimer);
}

function getResponse(url) {
    if (!url || !cachedResponses[url]) {
        console.error('Cannot get response without required information');
    }

    cachedResponses[url].fetching = true;
    cachedResponses[url].superAgentRequest = superAgent.get(url);

    if (!process || !process.browser) {
        // Only set a server timeout. Let browsers handle this natively otherwise.
        // 30s as widgets needs to finish in no more than 35.
        cachedResponses[url].superAgentRequest = cachedResponses[url].superAgentRequest.timeout(30 * 1000);
    }

    return cachedResponses[url].superAgentRequest.end(function (err, response) {
        let responseObject = new Response(err, response);

        cachedResponses[url].fetching = false;
        cachedResponses[url].timestamp = new Date().getTime();
        cachedResponses[url].responseObject = responseObject;

        runCallbacks(url, responseObject);
    });
}

function runCallbacks(url, responseObject) {
    let callbackStack  = [];
    let callbacksToRun = [];

    // Always clear the timeout before requesting new timeouts.
    clearTimeout(cachedResponses[url].nextRefresh);
    cachedResponses[url].nextRefresh = null;

    // Run all our callbacks.
    // For callbacks that still have a frequency, add them to a callback stack to
    // call again next time.
    cachedResponses[url].callbacks.forEach(function (callbackDetails) {
        callbacksToRun.push(callbackDetails.callback);

        // And if we have a frequency for this, we'll call him again.
        if (callbackDetails.frequency) {
            callbackStack.push(callbackDetails);
        }
    });

    cachedResponses[url].callbacks = callbackStack;

    if (callbackStack.length > 0) {
        let lowestFrequency = getLowestFrequency(callbackStack);

        if (lowestFrequency) {
            cachedResponses[url].nextRefresh = setTimeout(function () {
                getResponse(url);
            }, lowestFrequency);
        }
    }

    callbacksToRun.forEach(function (callback) {
        callback(responseObject);
    });
}

function garbageColection() {
    Object.keys(cachedResponses).forEach(function (url) {
        if (!cachedResponses[url].callbacks || !cachedResponses[url].callbacks.length) {
            let currentTime = new Date().getTime();
            let garbageCollectionPeriod = cachedResponses[url].cacheTtl;

            if (currentTime - cachedResponses[url].timestamp > garbageCollectionPeriod) {
                delete cachedResponses[url];
            }
        }
    });
}

garbageCollectionStart();
