import {isHisense} from '@fsa-streamotion/browser-utils';

import noop from 'lodash/noop';

import PlaybackNative from '.';
import {ERROR_CODES} from '../../utils/error-codes';
import type {CanPlaySourceParams} from '../types';

export default class PlaybackNativeHisenseModified extends PlaybackNative {
    hasEnded = false;

    endedDuration: number | null = null;
    endedCurrentTime: number | null = null;
    seekToStartAt: () => void = noop;

    // Hisense only playback handler, we're called before native, so if we're hisense, use us,
    // if we're not hisense, never use us.
    static override async canPlaySource(
        ...args: [CanPlaySourceParams]
    ): Promise<CanPlayTypeResult> {
        if (isHisense()) {
            return super.canPlaySource(...args);
        } else {
            return Promise.resolve('');
        }
    }

    override setup(): Promise<boolean> {
        return super.setup().then(() => {
            // magic #t modifier does nothing on hisense browser, specifically seek if we're given a startAt parameter
            if (
                Number.isFinite(this.options.startAt) &&
                this.options.startAt !== -1
            ) {
                const seekToTime =
                    this.options.startAt === 0 ? 1 : this.options.startAt;

                if (seekToTime && isFinite(seekToTime) && this.videoElement) {
                    this.seekToStartAt = () => {
                        if (
                            this.currentTime === undefined ||
                            !this.videoElement
                        ) {
                            return;
                        }

                        this.currentTime = seekToTime;
                        this.videoElement.removeEventListener(
                            'loadedmetadata',
                            this.seekToStartAt
                        ); // ensure we only do this once.
                    };

                    this.videoElement.addEventListener(
                        'loadedmetadata',
                        this.seekToStartAt
                    );
                }
            }

            // need to capture and store duration and current time (and the like) when the video ends.
            // this is because on hisense, ended always triggers a 'reload' of the video source (and that can error too)
            // So we need to listen to ended, store some state, unload the source, and wait for further user interaction.
            // If we hear anything like play, seek, etc, we need to firstly reloaded the source (we can use the reload function which defaults to currentSrc)
            // and continue on the way for what what as asked (replay for example).
            console.debug('PlayerTech: Hisense ended handler ENGAGED'); // eslint-disable-line no-console
            this.videoElement?.addEventListener('ended', this.handleEnded, {
                useCapture: true,
            });

            return true;
        });
    }

    override async destroy(): Promise<void> {
        this.videoElement?.removeEventListener('ended', this.handleEnded, {
            useCapture: true,
        });

        await super.destroy();
    }

    handleEnded = (): void => {
        this.hasEnded = true;
        this.endedCurrentTime = super.currentTime;
        this.endedDuration = super.duration;

        // Seems that on 'ended' the video duration reverts to 'Infinity' immediately
        // but we must be able to assume here, if the video has 'ended' at a current time of x,
        // duration must be that.
        if (!Number.isFinite(this.endedDuration) || !this.endedDuration) {
            this.endedDuration = this.endedCurrentTime;
        }

        if (this.videoElement) {
            // Clear out the source so we don't reload right now
            this.videoElement.src = '';
        }

        // eslint-disable-next-line no-console
        console.debug('PlayerTech: Hisense ended captured', {
            endedCurrentTime: this.endedCurrentTime,
            endedDuration: this.endedDuration,
        });
    };

    reloadSourcePromise(): Promise<void> {
        this.hasEnded = false;
        this.endedDuration = null;
        this.endedCurrentTime = null;

        if (typeof this.options?.requestVideoSourceCallback === 'function') {
            return this.options
                .requestVideoSourceCallback()
                .then((sourceCallback) => {
                    if (
                        sourceCallback &&
                        sourceCallback[0] &&
                        this.videoElement
                    ) {
                        this.videoElement.src = sourceCallback[0].src;
                    }
                })
                .catch((error) => {
                    console.error(
                        'VideoFsNativeHisenseModified: Unable to requestVideoSource after ended',
                        error
                    );
                    this.onError(
                        ERROR_CODES.CUSTOM_ERR_UNKNOWN,
                        'Unable to requestVideoSource after ended',
                        error
                    );
                });
        }

        return Promise.resolve();
    }

    override play(): void {
        if (this.hasEnded) {
            this.reloadSourcePromise().then(() => {
                this.videoElement?.play().then(() => {
                    if (this.videoElement) {
                        // After a play from ended, 'play' doesn't seem to work until we 'nudge'.
                        this.videoElement.currentTime = 0.1;
                    }
                });
            });
        } else {
            super.play();
        }
    }

    override get duration(): number {
        if (this.hasEnded) {
            return this.endedDuration ?? NaN;
        } else if (
            this.videoElement &&
            Number.isFinite(this.videoElement.duration)
        ) {
            // VOD
            return this.videoElement.duration;
        } else {
            if (this.videoElement) {
                // Live Streams don't 'technically' have a duration. They're considered infinite.
                // So for all our playback tech where we see live, we attempt to normalise this behaviour between how HTML5 would
                // do it, vs how all things like Dashjs and HLS.js would.
                // In the event we really can't work it out (cause short DVR streams for example do this),
                // we just continue on that this in an Infinte stream.
                const isSeekable = this.videoElement.seekable.length > 0;
                let seekableDurationSeconds = isSeekable
                    ? this.videoElement.seekable.end(
                          this.videoElement.seekable.length - 1
                      )
                    : 0;

                // CTV-503 - Patch for Hisense U5s & some U4s reporting unexpectedly large value for livestreams
                // Use seekable.start & end values if duration seconds exceeds 24 hours (86400 seconds)
                // @TODO: Remove this after the OTA deployment of the Hisense fix has happened
                if (seekableDurationSeconds > 86400) {
                    seekableDurationSeconds =
                        seekableDurationSeconds -
                        this.videoElement.seekable.start(0);
                }

                return seekableDurationSeconds || Infinity;
            }

            return Infinity;
        }
    }

    override get currentTime(): number {
        if (this.hasEnded) {
            return this.endedCurrentTime ?? NaN;
        } else if (
            this.videoElement &&
            this.videoElement.currentTime > 86400 &&
            this.videoElement.seekable.length > 0
        ) {
            // CTV-503 - Patch for Hisense U5s & some U4s reporting unexpectedly large value for livestreams
            // @TODO: Remove this after the OTA deployment of the Hisense fix has happened
            return (
                this.videoElement.currentTime -
                this.videoElement.seekable.start(0)
            );
        } else {
            return this.videoElement?.currentTime ?? NaN;
        }
    }

    override set currentTime(newCurrentTime) {
        if (this.hasEnded) {
            this.reloadSourcePromise().then(() => {
                // if we're seeking after ended, we'll remain in the 'paused' state until user hits play.
                super.currentTime = newCurrentTime;
            });
        } else if (
            this.videoElement &&
            newCurrentTime >= this.videoElement.duration
        ) {
            // This check here is because if you seek to the end of a video,
            // a video will actually start replaying.
            this.videoElement.currentTime = this.videoElement.duration - 0.1;
        } else if (
            this.videoElement &&
            this.videoElement.currentTime > 86400 &&
            this.videoElement.seekable.length > 0
        ) {
            // CTV-503 - Patch for Hisense U5s & some U4s reporting unexpectedly large value for livestreams
            this.videoElement.currentTime =
                this.videoElement.seekable.start(0) + newCurrentTime;
        } else if (this.videoElement) {
            this.videoElement.currentTime = newCurrentTime;
        }
    }
}
