import classnames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import EMPTY_IMAGE_SRC from '../../../../common/empty-image-src';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
} from '../../../../common/screen-sizes';
import SrcsetSource from '../../../../common/srcset-source';

const StyledPicture = styled.picture`
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
`;

const StyledImg = styled.img`
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

function getLargestImage(
    obj: {[key: number]: string} = {}
): string | undefined {
    // lodash/head is stupidly huge so ima roll my own
    const [firstValue] = Object.entries(obj).sort(
        ([sizePxA], [sizePxB]) => Number(sizePxB) - Number(sizePxA)
    );

    return get(firstValue, '1');
}

const IMG_TAG_KEY = 'image'; // to help re-use of DOM element when waking from lazy mode

const SRCSET_SIZES = [
    `(min-width: ${SCREEN_1024_DESKTOP}px): 100vw`,
    `(min-width: ${SCREEN_768_TABLET}px): ${SCREEN_1024_DESKTOP}px`, // on tablet<->desktop sizes, we're expecting to be in a fixed height container and we'll use a cropped version of desktop, so its wider than 100vw
    '100vw',
]
    .filter(Boolean)
    .join(',');

export type Props = {
    /** Additional class name(s) */
    className?: string;
    /** Image alternate text, e.g. for screen readers and image placeholders */
    alt?: string;
    /** Image to use for browsers that don't support <picture>. Defaults to largest srcSetOption screen size */
    defaultImageSrc?: string;
    /** Whether or not to use the lazySrc instead of the proper URL */
    isBeingLazy?: boolean;
    /** The source of the image to load if we're lazily delaying using the main image */
    lazySrc?: string;
    /** SrcsetOptions object to use for mobile and phablet viewports */
    mobilePhabletSrcsetOptions?: {[key: number]: string};
    /** SrcsetOptions object to use for everything other than mobile and phablet viewports */
    srcsetOptions?: {[key: number]: string};
};

const GA05BgImg = ({
    className,
    alt = '',
    defaultImageSrc,
    isBeingLazy,
    lazySrc = EMPTY_IMAGE_SRC,
    mobilePhabletSrcsetOptions,
    srcsetOptions = {},
}: Props): React.ReactElement => (
    <StyledPicture className={classnames('GA05BgImg', className)}>
        {isBeingLazy ? (
            <StyledImg key={IMG_TAG_KEY} src={lazySrc} alt="" />
        ) : (
            [
                <SrcsetSource
                    key="srcset"
                    minWidthPx={
                        mobilePhabletSrcsetOptions
                            ? SCREEN_768_TABLET
                            : undefined
                    }
                    sizes={isBeingLazy ? undefined : SRCSET_SIZES}
                    srcsetOptions={srcsetOptions}
                />,

                !!mobilePhabletSrcsetOptions && (
                    <SrcsetSource
                        key="mobile"
                        srcsetOptions={mobilePhabletSrcsetOptions}
                        sizes="100vw"
                    />
                ),

                <StyledImg
                    key={IMG_TAG_KEY}
                    src={defaultImageSrc || getLargestImage(srcsetOptions)}
                    alt={alt}
                />,
            ]
        )}
    </StyledPicture>
);

GA05BgImg.displayName = 'GA05BgImg';

export default GA05BgImg;
