import type {SCRUBBER_TYPE} from '@fsa-streamotion/player-state';

import {action, makeObservable, observable} from 'mobx';
import type React from 'react';

import type {Logger} from '../utils/types.js';

export type ChromecastSenderStateValuesType = {
    currentTime?: number;
    duration?: number;
    isLiveStream?: boolean;
    isOnEdge?: boolean;
    scrubBarType?: keyof typeof SCRUBBER_TYPE;
    isMuted?: boolean;
    setIsMuted?: (isMuted: boolean) => void;
    setVolume?: () => void;
    volume?: number;
    setCurrentTime?: (...args: number[]) => void;
    isSeekingDisabled?: boolean;
    isPlayingAd?: boolean;
    onClickChromecastButton?: React.MouseEventHandler<HTMLButtonElement>;
    chromecastStatus?: string;
    onClickClosedCaptionsButton?: React.MouseEventHandler<HTMLButtonElement>;
    isChromecastConnected?: boolean;
    isClosedCaptionsEnabled?: boolean;
    errorMessage?: React.ReactNode;
    onClickGoToLive?: React.MouseEventHandler<HTMLButtonElement>;
    hasControls?: boolean;
    isLoading?: boolean;
    isVisible?: boolean;
    markerButtonsData?: {
        copy?: string;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
    }[];
    markerCopy?: React.ReactNode;
    markerTitle?: string;
    mediaTitle?: string;
    nextUpButtonCopy?: string;
    onClickNextUp?: React.MouseEventHandler<HTMLButtonElement>;
    isPaused?: boolean;
    onClickPlay?: React.MouseEventHandler<HTMLButtonElement>;
    onClickPause?: React.MouseEventHandler<HTMLButtonElement>;
};

export default class ChromecastSenderState {
    logger: Logger;

    /**
     * @Param ChromecastSenderStateValues - Values representing the state of the chromecast sender at any given time, @see ChromecastSenderContextType
     */
    values: ChromecastSenderStateValuesType = {
        chromecastStatus: 'Now Casting',
        currentTime: 0,
        duration: 0,
        errorMessage: '',
        hasControls: true,
        isChromecastConnected: false,
        isClosedCaptionsEnabled: false,
        isLiveStream: false,
        scrubBarType: undefined,
        isMuted: false,
        isOnEdge: false,
        isPaused: true,
        isVisible: true,
        isSeekingDisabled: false,
        isPlayingAd: false,
        markerButtonsData: [],
        markerCopy: '',
        markerTitle: '',
        mediaTitle: '',
        nextUpButtonCopy: '',
        onClickChromecastButton: undefined,
        onClickClosedCaptionsButton: undefined,
        onClickGoToLive: undefined,
        onClickNextUp: undefined,
        onClickPause: undefined,
        onClickPlay: undefined,
        setCurrentTime: undefined,
        setIsMuted: undefined,
        setVolume: undefined,
        volume: 1,
    };

    constructor({
        initialValues = {},
        logger = console,
    }: {
        initialValues?: ChromecastSenderStateValuesType;
        logger?: Logger;
    } = {}) {
        this.logger = logger;
        this.updateValues(initialValues);

        makeObservable(this, {
            updateValues: action,
            values: observable,
        });
    }

    /**
     * Perform a partial update of `this.values` using a shallow merge
     *
     * @param newValues - To be partially merged to the state
     */
    updateValues(newValues: ChromecastSenderStateValuesType): void {
        Object.assign(this.values, newValues);
    }
}
