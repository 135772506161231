import React from 'react';
import propTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import MessageComponent from './message';

export default class Profile extends React.Component {
    renderErrorMessage() {
        let error = null;

        if (isEmpty(this.props.currentUser)) {
            error = (
                <MessageComponent type="fail" value="We could not retrieve your details at this time. Please try again later" />
            );
        }

        return error;
    }

    renderProfileDetails() {
        if (isEmpty(this.props.currentUser)) {
            return;
        }

        const profileDetails = (
            <dl className="fiso-hawk-profile__list">
                <dt className="fiso-hawk-profile__list-heading">Name:</dt>
                <dd className="fiso-hawk-profile__list-detail">
                    {this.props.currentUser.firstName} {this.props.currentUser.lastName}
                </dd>
                <dt className="fiso-hawk-profile__list-heading">Email:</dt>
                <dd className="fiso-hawk-profile__list-detail">
                    {this.props.currentUser.email}
                </dd>
                <dt className="fiso-hawk-profile__list-heading">Mobile:</dt>
                <dd className="fiso-hawk-profile__list-detail">
                    {this.props.currentUser.mobileNumber}
                </dd>
            </dl>
        );

        return profileDetails;
    }

    render() {
        const profileButtonClasses = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--profile',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            'fiso-hawk-button--chevron-right',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        return (
            <section className="fsui-normalise fiso-hawk-profile">
                <div className="fiso-hawk-profile__container">
                    <h1 className="fiso-hawk-profile__heading-1">Your Profile</h1>
                    {this.renderErrorMessage()}
                    {this.renderProfileDetails()}
                    <div className="fiso-hawk-profile__row">
                        <div className="fiso-hawk-profile__col">
                            <a
                                href={this.props.editDetailsUrl}
                                className={profileButtonClasses}>
                                Edit Your Details
                                <Fsvg name="next-1" />
                            </a>
                            <a
                                href={this.props.changePaymentUrl}
                                className={profileButtonClasses}>
                                Change Your Payment Details
                                <Fsvg name="next-1" />
                            </a>
                        </div>
                        <div className="fiso-hawk-profile__col">
                            <a
                                href={this.props.changePasswordUrl}
                                className={profileButtonClasses}>
                                Change Your Password
                                <Fsvg name="next-1" />
                            </a>
                            <a
                                href={this.props.manageSubscriptionUrl}
                                className={profileButtonClasses}>
                                Manage Your Subscription
                                <Fsvg name="next-1" />
                            </a>
                            {/* <a
                                href={this.props.manageDevicesUrl}
                                className={profileButtonClasses}>
                                Manage Your Devices
                                <Fsvg name="next-1" />
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Profile.defaultProps = {
    currentUser: {
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: ''
    }
};

Profile.propTypes = {
    currentUser: propTypes.shape({
        firstName: propTypes.string,
        lastName: propTypes.string,
        email: propTypes.string,
        mobileNumber: propTypes.string
    }),
    editDetailsUrl: propTypes.string,
    changePaymentUrl: propTypes.string,
    changePasswordUrl: propTypes.string,
    // manageDevicesUrl: propTypes.string,
    manageSubscriptionUrl: propTypes.string,
    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
