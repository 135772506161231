import React from 'react';
import propTypes from 'prop-types';
import hostedFields from 'braintree-web/hosted-fields';
import noop from 'lodash/noop';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

export default class PaymentCreditCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleHostedFieldsCreated = this.handleHostedFieldsCreated.bind(this);
    }

    componentDidMount() {
        if (!this.props.brainTreeClientInstance) {
            return;
        }

        hostedFields.create({
            client: this.props.brainTreeClientInstance,
            styles: {
                'input': {
                    'font-size': '14px',
                    'color': '#5e6d79'
                },
                'input.invalid': {
                    color: 'red'
                },
                'input.valid': {
                    color: 'green'
                }
            },
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: ''
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: ''
                },
                expirationDate: {
                    selector: '#expiry-date',
                    placeholder: ''
                }
            }
        }, this.handleHostedFieldsCreated);
    }

    componentDidUpdate() {
        if (this.props.isLoading) {
            return;
        }

        if (this.props.error || this.props.updateSuccess) {
            window.scrollTo(0, 0);

            if (this.hostedFieldsInstance) {
                this.hostedFieldsInstance.clear('number');
                this.hostedFieldsInstance.clear('cvv');
                this.hostedFieldsInstance.clear('expirationDate');
            }
        }
    }

    componentWillUnmount() {
        if (this.hostedFieldsInstance) {
            this.props.onHostedFieldsInstanceDestroyed(this.hostedFieldsInstance);
            this.hostedFieldsInstance.teardown(function (err) {
                if (err) {
                    console.error('Could not tear down Hosted Fields!');
                }
            });
        }
    }

    handleHostedFieldsCreated(error, instance) {
        if (error) {
            console.error('Braintree error', error);

            return;
        }
        this.hostedFieldsInstance = instance;
        this.props.onHostedFieldsInstanceCreated(this.hostedFieldsInstance);
    }

    render() {
        return (
            <div className="fiso-hawk-payment-credit-card">
                <div
                    className="fiso-hawk-payment-credit-card__accepted-cards">
                    <p
                        className="fiso-hawk-payment-credit-card__title">
                        Accepted credit cards
                    </p>
                    <ul className="fiso-hawk-payment-credit-card__accepted-cards-list">
                        <li><Fsvg name="visa" ariaLabel="Visa" /></li>
                        <li><Fsvg name="mastercard" ariaLabel="Mastercard" /></li>
                    </ul>
                </div>
                <p className="fiso-hawk-payment-credit-card__description">
                    <span className="fiso-hawk-payment-credit-card__padlock-icon">
                        <Fsvg name="lock" />
                    </span>
                    Secure payment
                </p>
                <div id="error-message" />
                <label className="fiso-hawk-payment-credit-card__label" htmlFor="card-number">Card Number</label>
                <div className="fiso-hawk-form-input__input" id="card-number" />
                <div className="fiso-hawk-payment-credit-card__row">
                    <div
                        className="
                            fiso-hawk-payment-credit-card__col
                            fiso-hawk-payment-credit-card__col--expiry">
                        <label className="fiso-hawk-payment-credit-card__label" htmlFor="expiry-date">Expiry Date</label>
                        <div className="fiso-hawk-form-input__input" id="expiry-date" />
                    </div>
                    <div
                        className="
                            fiso-hawk-payment-credit-card__col
                            fiso-hawk-payment-credit-card__col--verification">
                        <label className="fiso-hawk-payment-credit-card__label" htmlFor="cvv">CVV</label>
                        <div className="fiso-hawk-form-input__input" id="cvv" />
                    </div>
                </div>
                <input type="hidden" name="payment-method-nonce" />
            </div>
        );
    }
}

PaymentCreditCard.defaultProps = {
    onHostedFieldsInstanceCreated: noop,
    onHostedFieldsInstanceDestroyed: noop,

    updateSuccess: false
};

PaymentCreditCard.propTypes = {
    brainTreeClientInstance: propTypes.object,
    onHostedFieldsInstanceCreated: propTypes.func,
    onHostedFieldsInstanceDestroyed: propTypes.func,

    isLoading: propTypes.bool,

    error: propTypes.node,

    updateSuccess: propTypes.bool
};

