import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC220AudioSettings = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path d="M73.57 47.934h8.4V83.2h-8.4zM26.782 16h-8.4v18.69H14v11.2h17.85v-11.2h-5.068V16zm.868 25.69H18.2v-2.8h9.45v2.8zm-9.268 9.632h8.4V83.2h-8.4zM53.592 16h-8.4v45.234H40.46v11.2h17.85v-11.2h-4.718V16zm.518 52.234h-9.45v-2.8h9.45v2.8zm-8.918 9.114h8.4V83.2h-8.4zM81.97 31.82V16h-8.4v15.82h-4.732v11.2h17.85v-11.2H81.97zm.518 7h-9.45v-2.8h9.45v2.8z" />
    </Ic>
);

IC220AudioSettings.displayName = 'IC220AudioSettings';

export default IC220AudioSettings;
