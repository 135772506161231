/**
 * Retreive videos from a show
 *
 * @param  {array} shows - an array of show objects, containing an array of video
 *
 * @returns {array} videos - and array of objects with the key "videos" mapping to their videos
 */
export function getVideosFromShows(shows = []) {
    return shows.map((show) => ({
        videos: show.values
    }));
}

/**
 * Retreive show video by video ID
 *
 * @param  {array} videos array of show video object
 * @param  {integer} videoId id of show video to lookup
 *
 * @return {object} video object
 */
export function getShowVideoById(videos, videoId) {
    const isVideoIdNumeric = /^\d+$/.test(videoId);

    if (!videos || !isVideoIdNumeric) {
        return;
    }

    const videoIdInt = parseInt(videoId);

    const [selectedVideo] = videos
        .map(({videos}) => videos.filter(({id}) => id === videoIdInt))
        .filter((video) => video.length)
        .slice(-1);

    return selectedVideo && selectedVideo[0];
}

