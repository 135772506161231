export default class Html5Adapter {
  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this.player);
    } catch (err) {
      return true;
    }
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    this.firePause({}, 'pauseListener');
  }

  stalledListener(e) {
    this.firePlayerLog('stalledListener', {});
    this.fireBufferBegin({}, false, 'stalledListener');
  }

  bufferingListener(e) {
    this.firePlayerLog('waitingListener', {});
    this.fireBufferBegin({}, false, 'waitingListener');
  }

  getIsLive() {
    return this.player.duration === Infinity;
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    if (this.player && this.playhead) {
      this.checkReadyState(this.player.readyState, 'playListener');
    }
    if (typeof this.lastResource === 'undefined' || (!!this.getResource() && this.lastResource !== this.getResource())) {
      this.lastResource = this.getResource().split('?')[0];
    }
    this.fireStart({}, 'playListener');
  }

  registerListeners() {
    this.monitorPlayhead(false, false);
    this.references = {
      play: this.playListener.bind(this),
      timeupdate: this.timeupdateListener.bind(this),
      pause: this.pauseListener.bind(this),
      playing: this.playingListener.bind(this),
      error: this.errorListener.bind(this),
      seeking: this.seekingListener.bind(this),
      seeked: this.seekedListener.bind(this),
      ended: this.endedListener.bind(this),
      loadstart: this.loadStartListener.bind(this),
      stalled: this.stalledListener.bind(this),
      waiting: this.bufferingListener.bind(this),
      canplay: this.canPlayListener.bind(this),
      canplaythrough: this.canPlayThroughListener.bind(this),
      loadedmetadata: this.loadedMetadataListener.bind(this),
      loadeddata: this.loadedDataListener.bind(this)
    };
    for (var key in this.references) {
      this.player.addEventListener(key, this.references[key]);
    }
  }

  endedListener(e) {
    const adsAdapter = this.getVideo().getAdsAdapter();
    let willShowCSAIAds = false;
    if (adsAdapter && typeof adsAdapter.isDAI !== 'undefined') {
      if (!adsAdapter.isDAI) {
        // This means we are using Google IMA with CSAI
        willShowCSAIAds = adsAdapter.player.getCuePoints().includes(-1);
      }
    }
    if (!willShowCSAIAds) {
      this.firePlayerLog('endedListener', {});
      this.fireStop({}, 'endedListener');
    }
  }

  getPlayrate() {
    return this.player.playbackRate;
  }

  canPlayListener(e) {
    if (this.player) {
      if (this.playhead || (this.getVideo() && this.getVideo().getIsLive())) {
        this.checkReadyState(this.player.readyState, 'canPlayListener');
      }
    }
  }

  canPlayThroughListener(e) {
    if (this.player) {
      if (this.playhead || (this.getVideo() && this.getVideo().getIsLive())) {
        this.checkReadyState(this.player.readyState, 'canPlayThroughListener');
      }
    }
  }

  timeupdateListener(e) {
    if ((this.getPlayhead() > 0 || (this.getVideo() && this.getVideo().getIsLive())) && !this.player.error) {
      this.fireStart({}, 'timeupdateListener');
      this.fireJoin({}, 'timeupdateListener');
    }
  }

  getResource() {
    return this.player.currentSrc;
  }

  getPlayhead() {
    return this.player.currentTime;
  }

  getDuration() {
    return this.player.duration;
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener');
  }

  getPlayerName() {
    return 'html5'
  }

  playingListener(e) {
    this.firePlayerLog('playingListener', {});
    this.fireJoin({}, 'playingListener');
    this.fireResume({}, 'playingListener');
    this.fireSeekEnd({}, 'playingListener');
    if (this.flags.isBuffering) {
      this.fireBufferEnd({}, 'playingListener');
    }
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    var msg = null;
    var code = null;
    try {
      if (e && e.target && e.target.error) {
        code = e.target.error.code;
        msg = e.target.error.message;
      }
    } catch (err) { };
    this.fireError(code, msg, undefined, undefined, 'errorListener');
  }

  loadedMetadataListener(e) {
    if (this.player) {
      if (this.playhead || (this.getVideo() && this.getVideo().getIsLive())) {
        this.checkReadyState(this.player.readyState, 'loadedMetadataListener');
      }
    }
  }

  getVersion() {
    return '7.0.4-html5-jsclass';
  }

  seekedListener(e) {
    this.firePlayerLog('seekedListener', {});
    this.fireSeekEnd({}, 'seekedListener');
  }

  loadStartListener(e) {
    this.firePlayerLog('loadStartListener', {});
    if (this.flags.isJoined && !!this.getResource() && this.getResource().split('?')[0] !== this.lastResource) {
      this.fireStop({}, 'loadStartListener');
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.removeEventListener(key, this.references[key]);
        delete this.references[key];
      }
    }
  }

  loadedDataListener(e) {
    if (this.player) {
      if (this.playhead || (this.getVideo() && this.getVideo().getIsLive())) {
        this.checkReadyState(this.player.readyState, 'loadedDataListener');
      }
    }
  }
}