import React from 'react';
import styled from 'styled-components';

import Ic, {type Props as IcProps} from '..';
import gibson from '../../../../common/font-helper';
import {Img} from '../../../../common/normalized-styled-components';
import {visuallyHiddenBaseStyles} from '../../../../common/visually-hidden';

const ReducedMotionLoadingText = styled.span`
    ${visuallyHiddenBaseStyles}

    display: block;
    padding-top: 80px;
    text-align: center;
    color: inherit;
    font: ${gibson.medium({size: '0.25em'})};
`;

const LoaderImage = styled(Img)``;

const IcWrapper = styled.div``;

const Wrapper = styled.div`
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
        /* stylelint-disable */
        > ${IcWrapper}, > ${LoaderImage} {
            display: none;
        }
        /* stylelint-enable */

        > ${ReducedMotionLoadingText} {
            position: static;
            clip: initial;
            width: auto;
            height: auto;
            overflow: auto;
        }
    }
`;

const Stroke = styled.circle`
    @keyframes spinner {
        0% {
            stroke-dashoffset: 0;
            ${
                '' /* stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */
            }
            stroke-dasharray: 64px 193px;
        }

        19% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 0.3 * 82);
            stroke-dasharray: calc(3.14px * 0.7 * 82) calc(3.14px * 0.3 * 82); */
            }
            stroke-dashoffset: -77px;
            stroke-dasharray: 180px 77px;
            animation-timing-function: linear;
        }

        27% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 0.6 * 82);
            stroke-dasharray: calc(3.14px * 0.8 * 82) calc(3.14px * 0.2 * 82); */
            }
            stroke-dashoffset: -155px;
            stroke-dasharray: 206px 52px;
            animation-timing-function: linear;
        }

        32% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 0.8 * 82);
            stroke-dasharray: calc(3.14px * 0.7 * 82) calc(3.14px * 0.3 * 82); */
            }
            stroke-dashoffset: -206px;
            stroke-dasharray: 180px 77px;
            animation-timing-function: linear;
        }

        53% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 1.6 * 82);
            stroke-dasharray: calc(3.14px * 0.6 * 82) calc(3.14px * 0.4 * 82); */
            }
            stroke-dashoffset: -412px;
            stroke-dasharray: 155px 103px;
            animation-timing-function: linear;
        }

        59% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 2 * 82);
            stroke-dasharray: calc(3.14px * 0.3 * 82) calc(3.14px * 0.7 * 82); */
            }
            stroke-dashoffset: -515px;
            stroke-dasharray: 77px 180px;
            animation-timing-function: ease-out;
        }

        70% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 2.25 * 82);
            stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */
            }
            stroke-dashoffset: -580px;
            stroke-dasharray: 64px 193px;
            animation-timing-function: ease-in-out;
        }

        100% {
            ${
                '' /* stroke-dashoffset: calc(-3.14px * 2 * 82);
            stroke-dasharray: calc(3.14px * 0.25 * 82) calc(3.14px * 0.75 * 82); */
            }
            stroke-dashoffset: -515px;
            stroke-dasharray: 64px 193px;
        }
    }
    fill: transparent;
    stroke-linecap: round;
    animation: spinner ease-in 2s normal infinite;
`;

export type Props = {
    className?: string;
    display?: 'block' | 'inline-block';
    color?: string;
    /** The loading spinner's image URL, takes precedence over the color setting */
    loadingSpinnerUrl?: string;
} & IcProps;

const IC103Loading = ({
    display = 'inline-block',
    color,
    loadingSpinnerUrl,
    ...props
}: Props): React.ReactElement => (
    <Wrapper>
        {loadingSpinnerUrl ? (
            <LoaderImage src={loadingSpinnerUrl} />
        ) : (
            <IcWrapper>
                <Ic display={display} color={color} {...props}>
                    <circle
                        cx="50"
                        cy="50"
                        r="41"
                        stroke="white"
                        strokeOpacity="0.7"
                        fill="transparent"
                        strokeWidth="9"
                    />
                    <Stroke
                        cx="50"
                        cy="50"
                        r="41"
                        stroke={color}
                        strokeWidth="9"
                    />
                </Ic>
            </IcWrapper>
        )}
        <ReducedMotionLoadingText>Loading ...</ReducedMotionLoadingText>
    </Wrapper>
);

IC103Loading.displayName = 'IC103Loading';

export default IC103Loading;
