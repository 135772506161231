import result from 'lodash/result';

export default function getCyclingSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Cycling seriesRouteOrId not implemented', seriesRouteOrId);

            return {};
    }
}
