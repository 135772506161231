import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = {
    volumePercentage?: number;
} & IcProps;

const IC04Vol = ({
    volumePercentage = 1,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            opacity={volumePercentage > 0 ? 1 : 0}
            d="M55.896 50.083l-2.11-3.633c4.382-2.532 7.081-7.209 7.081-12.27 0-5.06-2.699-9.737-7.081-12.269l2.096-3.633c5.674 3.286 9.168 9.346 9.168 15.902 0 6.557-3.494 12.617-9.168 15.903h.014z"
            transform="translate(11 16)"
        />
        <path
            opacity={volumePercentage > 0.5 ? 1 : 0}
            d="M66.376 57.126l-2.096-3.634c6.869-4.007 11.092-11.36 11.092-19.312 0-7.951-4.223-15.305-11.092-19.312l2.096-3.689c8.168 4.755 13.193 13.494 13.193 22.946 0 9.45-5.025 18.19-13.193 22.945v.056z"
            transform="translate(11 16)"
        />
        <path
            d="M34.67 15.54v35.828L22.357 43.32V23.756L34.67 15.65M43.054 0l-29.08 19.116v28.675l29.08 19.13V0zM0 46.436L8.384 47.204 8.384 19.619 0 20.388z"
            transform="translate(11 16)"
        />
    </Ic>
);

IC04Vol.displayName = 'IC04Vol';
export default IC04Vol;
