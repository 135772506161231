// A temporary workaround for npaw-plugin-nwf and npaw-plugin-adapters pkgs.
// The reason is they use a global variable called `self`, which only exists in the browser or web worker.
// But our code needs to be run by FISO server, which is a node environment, so it will cause an error: ReferenceError: self is not defined.
// To avoid this error, we need to inject the `self` ourselves for now.

// TODO: After the npaw team has fixed this issue, remove this file.

export default (function injectSelf() {
    const globalObject = typeof window === 'object' ? window : global;

    if (!('self' in globalObject)) {
        Object.defineProperty(globalObject, 'self', {
            value: globalObject,
            configurable: true,
            writable: true,
            enumerable: true,
        });
    }
}());
