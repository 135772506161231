import {
    mediaQuery,
    stylesWhen,
    stylesWhenNot,
} from '@fsa-streamotion/styled-component-helpers';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {rgba} from 'polished';
import React from 'react';
import styled, {type DefaultTheme} from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {white, black} from '../../../../common/palette';
import {SCREEN_1024_DESKTOP} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import IA05InputHelp from '../../../atoms/ia/05-input-help';
import IC103Loading from '../../../atoms/ic/103-loading';

const getBackgroundColor = (props: {theme: DefaultTheme}): string =>
    rgba(THEME_ACCESSORS.brandColorVariant(props), 0.9);

const ChromecastControlsContainer = styled.section<{isVisible: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: ${transition('opacity')};
    opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
`;

const DropShadow = styled.div`
    background-image: linear-gradient(
        to bottom,
        ${rgba(black, 0)} 3%,
        ${rgba(black, 0.72)}
    );
    height: 14px;
`;

const ScrubberAndButtonRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${getBackgroundColor};
    padding: 14px 0;

    @supports (backdrop-filter: blur(10px)) {
        backdrop-filter: blur(10px);
    }
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        flex-direction: row;
        flex-wrap: wrap;
    `}
`;

const TitleAndCastIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${rgba(white, 0.1)};
    padding: 0 10px;
    height: 48px;

    ${stylesWhen('hasMarkerButtons')`
        ${stylesWhen('hasControls')`
            justify-content: flex-start;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        box-sizing: border-box;
        margin-bottom: 0;
        height: 67px;
        width: ${({hasControls, hasMarkerButtons}) =>
            hasControls && hasMarkerButtons ? '50%' : '100%'};
    `}
`;

const ScrubberWrapper = styled.div`
    margin: 11px 0 18px;
    padding: 0 21px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        box-sizing: border-box;
        margin-top: 23px;
        width: 100%;
    `}
`;

const MarkerButtonWrapper = styled.div`
    box-sizing: border-box;
    border-bottom: 1px solid ${rgba(white, 0.1)};
    padding: 14px 14px 24px;
    text-align: center;
    color: ${white};
    font: ${gibson.medium({size: 14})};

    ${stylesWhen('hasMarkerCopy')`
        padding-top: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 50%;
        text-align: right;
    `}
`;

const MarkerTitle = styled.span`
    display: block;
    color: ${white};
    font: ${gibson.semibold({size: 14})};
`;

const MarkerCopy = styled.span`
    display: block;
    margin-bottom: 14px;
    color: ${white};
    font: ${gibson.medium({size: 14})};

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-bottom: 0;
    `};
`;

const MarkerButtonText = styled.div`
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin: 0 14px 0 auto;
    `};
`;

const MarkerButtons = styled.div`
    display: inline-grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-column-gap: 7px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-right: 21px;

        ${stylesWhenNot('hasMarkerCopy')`
            margin-left: auto;
        `}
    `};
`;

const ButtonRowLayoutItem = styled.div`
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: left;
    background-color: transparent;
    padding: 0 10px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        gap: 21px;
        width: 100%;
        box-sizing: border-box;
    `}
`;

const ElementWrapper = styled.div`
    margin: 0 3px;
`;

const ClosedCaptionsWrapper = styled(ElementWrapper)`
    margin-left: auto;
`;

const Divider = styled.div`
    display: none;
    border-right: 1px solid ${rgba(white, 0.15)};
    width: 0;
    height: 28px;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: block;
    `}
`;

const StatusWrapper = styled.header`
    box-sizing: border-box;
    margin: 0;
    padding: 0 21px 0 7px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    mask-image: linear-gradient(to left, transparent, rgb(0, 0, 0) 21px);

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: inline-flex;
        align-items: center;
        margin-left: 0;
        width: unset;
    `}
`;

const StyledChromecastStatus = styled.h3`
    display: inline;
    white-space: nowrap;
    color: ${white};
    font: ${gibson.semibold({size: 14})};
`;

const StyledMediaHeading = styled.h3`
    display: inline;
    padding: 0;
    white-space: nowrap;
    color: ${white};
    font: ${gibson.light({size: 14})};
`;

const LoadingIconContainer = styled.div`
    display: flex;
    margin-right: 7px;
`;

const StyledIA05InputHelp = styled(IA05InputHelp)`
    margin: 14px;
`;

const VolumeWrapper = styled.div`
    display: none;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        display: flex;
    `}
`;

export type Props = {
    /** Optional additional className(s) */
    className?: string;
    /** Closed captions button */
    closedCaptionsButton?: React.ReactNode;
    /** Chromecast Button */
    chromecastButton?: React.ReactNode;
    /** Chromecast Status; e.g: Casting to Kayo Sports */
    chromecastStatus?: string;
    /** Disply time node */
    displayTime?: React.ReactNode;
    /** Error message node; could later on contain links */
    errorMessage?: React.ReactNode;
    /** Button to skip to live */
    goToLiveButton?: React.ReactNode;
    /** Are the controls currently visible? */
    hasControls?: boolean;
    /** Is this component visible? */
    isVisible?: boolean;
    /** Marker buttons (Skip intro; Skip recap; etc) */
    markerButtons?: React.ReactNode;
    /** Marker button  title */
    markerTitle?: React.ReactNode;
    /** Marker button copy */
    markerCopy?: React.ReactNode;
    /** Title of media currently in chromecast */
    mediaTitle?: string;
    /** Upnext Button */
    nextUpButton?: React.ReactNode;
    /** Play or pause button */
    playButton?: React.ReactNode;
    /** Scrubber node */
    scrubber?: React.ReactNode;
    /** Skip button */
    skipBackButton?: React.ReactNode;
    /** Skip forward button */
    skipForwardButton?: React.ReactNode;
    /** Volume Slider */
    volumeSlider?: React.ReactNode;
    /** Is Chromecast loading media */
    isLoading?: boolean;
};

const OR04ChromecastControls = ({
    className,
    chromecastButton,
    chromecastStatus,
    closedCaptionsButton,
    displayTime,
    errorMessage,
    goToLiveButton,
    hasControls,
    isLoading,
    isVisible,
    markerButtons,
    markerCopy,
    markerTitle,
    mediaTitle,
    nextUpButton,
    playButton,
    scrubber,
    skipBackButton,
    skipForwardButton,
    volumeSlider,
}: Props): React.ReactElement => (
    <ChromecastControlsContainer
        isVisible={!!isVisible}
        className={classNames('OR04ChromecastControls', className)}
    >
        <DropShadow />
        {!!errorMessage && (
            <StyledIA05InputHelp hasDownArrow={true}>
                {errorMessage}
            </StyledIA05InputHelp>
        )}
        <ScrubberAndButtonRowContainer>
            <TitleAndCastIconContainer
                hasControls={hasControls}
                hasMarkerButtons={!isEmpty(markerButtons)}
            >
                {!!(chromecastStatus || mediaTitle) && (
                    <StatusWrapper>
                        {!!isLoading && (
                            <LoadingIconContainer>
                                <IC103Loading color={white} size="22px" />
                            </LoadingIconContainer>
                        )}
                        {!!chromecastStatus && (
                            <StyledChromecastStatus>
                                {chromecastStatus}
                            </StyledChromecastStatus>
                        )}
                        {!!mediaTitle && (
                            <StyledMediaHeading>
                                {chromecastStatus ? '\xa0-\xa0' : ''}
                                {mediaTitle}
                            </StyledMediaHeading>
                        )}
                    </StatusWrapper>
                )}
                {!!chromecastButton && (
                    <ElementWrapper>{chromecastButton}</ElementWrapper>
                )}
            </TitleAndCastIconContainer>

            {!!hasControls && !isEmpty(markerButtons) && (
                <MarkerButtonWrapper
                    hasMarkerCopy={!!(markerTitle || markerCopy)}
                >
                    {!!(markerTitle || markerCopy) && (
                        <MarkerButtonText>
                            {!!markerTitle && (
                                <MarkerTitle>{markerTitle}</MarkerTitle>
                            )}
                            {!!markerCopy && (
                                <MarkerCopy>{markerCopy}</MarkerCopy>
                            )}
                        </MarkerButtonText>
                    )}
                    <MarkerButtons
                        hasMarkerCopy={!!(markerTitle || markerCopy)}
                    >
                        {markerButtons}
                    </MarkerButtons>
                </MarkerButtonWrapper>
            )}

            {!!hasControls && <ScrubberWrapper>{scrubber}</ScrubberWrapper>}
            {!!hasControls && (
                <ButtonRowLayoutItem>
                    {skipBackButton}
                    {playButton}
                    {skipForwardButton}
                    {!!volumeSlider && (
                        <React.Fragment>
                            <Divider />
                            <VolumeWrapper>{volumeSlider}</VolumeWrapper>
                        </React.Fragment>
                    )}
                    {[goToLiveButton, nextUpButton, displayTime]
                        .filter(Boolean)
                        .map((element, index) => (
                            <React.Fragment key={index}>
                                <Divider />
                                {element}
                            </React.Fragment>
                        ))}
                    {!!closedCaptionsButton && (
                        <ClosedCaptionsWrapper>
                            {closedCaptionsButton}
                        </ClosedCaptionsWrapper>
                    )}
                </ButtonRowLayoutItem>
            )}
        </ScrubberAndButtonRowContainer>
    </ChromecastControlsContainer>
);

OR04ChromecastControls.displayName = 'OR04ChromecastControls';

export default OR04ChromecastControls;
