import superagent from 'superagent';

import {MEDIA_OTT_URL} from '../../utils/constants';
import {streamFromApiWithRetry} from '../../utils/api';

export default function getBannerConfig({
    bannerConfigPath
}) {
    const url = `${MEDIA_OTT_URL}${bannerConfigPath}`;

    const apiCall = () => superagent.get(url);

    return streamFromApiWithRetry({apiCall}).map('.body');
}
