import bacon from 'baconjs';
import superagent from 'superagent';
import cloneDeep from 'lodash/cloneDeep';

import {setLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {VIMOND_MIDDLELAYER_URL, AUTHENTICATION_LOCAL_STORAGE_KEY, DEFAULT_VIMOND_ENV} from '../../utils/constants';
import {streamFromApi, getVimondHeaders} from '../../utils/api';

export default function login({
    password = '',
    rememberMe = false,
    sport = 'afl',
    username = '',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/authentication/user/login`;
    const request = superagent
        .post(url)
        .set(getVimondHeaders({sport, version: 2}))
        .send({
            username,
            password,
            rememberMe
        });

    return streamFromApi(request).flatMap((response) => {
        let error;

        if (response.body.code === 'AUTHENTICATION_OK' && response.headers.authorization) {
            setLocalStorageValue({
                key:        AUTHENTICATION_LOCAL_STORAGE_KEY,
                value:      response.headers.authorization,
                expiresIn:  null // TODO: Replace this with a value when business decides its value
            });

            return response;
        }

        if (response.body.code === 'AUTHENTICATION_OK' && !response.headers.authorization) {
            // If "AUTHENTICATION_OK" happens, but the header was never here, we need to make a generic description
            // and tell customers to try again.  Otherwise it says "Success login".
            error = new Error('Error in authentication, please try again later.');
        } else {
            error = new Error(response.body.description);
        }

        error.response = cloneDeep(response);
        error.status = response.body.status;

        return new bacon.Error(error);
    });
}
