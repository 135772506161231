import get from 'lodash/get';

import getCategories from './end-points/categories';
import {DEFAULT_VIMOND_ENV, VIMOND_IMAGE_URL} from './../utils/constants';

export default function getShows({
    categoryId,
    page,
    size,
    sport,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return getCategories({
        categoryId,
        page,
        size,
        sport,
        vimondEnv
    })
        .map((showsData) => massageShowsData(showsData.category, categoryId, vimondEnv));
}

function massageShowsData(shows = [], categoryId, vimondEnv = DEFAULT_VIMOND_ENV) {
    return shows
        .filter((show) => show.id !== categoryId)
        .map((show) => ({
            id: show.id,
            imageSource: VIMOND_IMAGE_URL[vimondEnv] + get(show, 'metadata.image-pack.content', ''),
            link: '/shows/' + show.id,
            title: get(show, 'title')
        }));
}
