export default function getMassagedMatchData(matchData = []) {
    if (!Array.isArray(matchData)) {
        return matchData;
    }

    return matchData
        .map((matchDatum) => {
            matchDatum.is_clock_running = !!matchDatum.is_clock_running;

            return matchDatum;
        });
}
