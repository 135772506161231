import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import styled from 'styled-components';

import {Img} from '../../../../common/normalized-styled-components';
import {
    SCREEN_667_PHABLET_LANDSCAPE,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
} from '../../../../common/screen-sizes';

export type Props = {
    src?: string;
};

const GA03Thumbnail = styled(Img)`
    display: block;
    margin: 0;
    width: 144px;
    height: 81px;

    ${mediaQuery({minWidthPx: SCREEN_667_PHABLET_LANDSCAPE})`
        width: 192px;
        height: 108px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 206px;
        height: 116px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 280px;
        height: 158px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        width: 338px;
        height: 190px;
    `}
`;

export default GA03Thumbnail;
