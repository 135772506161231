import VIDEO_LIBRARY_URLS from '../../utils/video-library-urls';
import type {RxPlayerConfig} from './types';

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_RXPLAYER_CONFIG: RxPlayerConfig = {
    script: VIDEO_LIBRARY_URLS.rxplayer,
    workerUrl:
        'https://resources.streamotion.com.au/immutable/web/video-libraries/rxplayer/4.1.0-es5/worker.min.js',
    loadVideoSettings: {
        lowLatencyMode: false,
        enableFastSwitching: false,
    },
    keySystemSettings: {
        onKeyOutputRestricted: 'fallback',
        onKeyInternalError: 'fallback',
        onKeyExpiration: 'close-session',
        persistentState: 'required',
    },
};
