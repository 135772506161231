import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

export default class Voucher extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleVoucherChanged = this.handleVoucherChanged.bind(this);
        this.getStatusIcon = this.getStatusIcon.bind(this);
    }

    handleVoucherChanged(event) {
        this.props.onVoucherChanged(event.target.value);
    }

    getStatusIcon() {
        let result;

        if (this.props.success) {
            result = <Fsvg name="tick-circle" />;
        } else if (this.props.error) {
            result = <Fsvg name="exclaim-circle" />;
        } else if (this.props.isLoading) {
            result = <LoadingSpinner />;
        }

        return result;
    }

    render() {
        const {error} = this.props;

        const statusIcon = this.getStatusIcon();

        const inputClassNames = classNames(
            'fiso-hawk-voucher__input',
            {
                'fiso-hawk-voucher__input--state-icon': statusIcon
            }
        );

        return (
            <div className="fiso-hawk-voucher">
                <label
                    htmlFor="voucher-input"
                    className="fiso-hawk-voucher__label">
                    Have you got a voucher code?
                </label>
                <div className="fiso-hawk-voucher__input-wrapper">
                    <input
                        defaultValue={this.props.initialVoucher}
                        className={inputClassNames}
                        onChange={this.handleVoucherChanged}
                        name="voucher-code"
                        id="voucher-input"
                        type="text" />
                    {statusIcon &&
                        <div className="fiso-hawk-voucher__status-icon">
                            {statusIcon}
                        </div>
                    }
                </div>
                {error &&
                    <div className="fiso-hawk-voucher__error-message">
                        {error}
                    </div>
                }
            </div>
        );
    }
}

Voucher.defaultProps = {
    onVoucherChanged: noop
};

Voucher.propTypes = {
    success: propTypes.bool,
    error: propTypes.string,
    isLoading: propTypes.bool,
    onVoucherChanged: propTypes.func,
    initialVoucher: propTypes.string
};
