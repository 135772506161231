import {isSafari} from '@fsa-streamotion/browser-utils';

import type PlaybackNative from '.';
import MediaError from '../../media-error';
import type {SupportedPlaybackHandler} from '../../types';
import {DEFAULT_ERROR_MESSAGES, ERROR_CODES} from '../../utils/error-codes';
import type {PlaybackHandlerArgs} from '../types';

const NETWORK_ERROR_RECOVERY_TIME_MS = 30_000; // We'll give ourselves 30 seconds to recover network

export default class NativeError {
    #timeoutId: number | null = null;

    videoElement: HTMLVideoElement;
    playbackTech: PlaybackNative;
    onError: PlaybackHandlerArgs['onError'];
    isPlayerStalled = false;
    previousBufferedEndTime = 0;
    playbackHandler: SupportedPlaybackHandler;
    shouldNetworkErrorBePresented = false;

    #onEventTimeupdate = (): void => {
        const videoHasNotEnded = !this.#hasVideoEnded();

        if (this.#isInNetworkErrorState() && videoHasNotEnded) {
            this.playbackTech.resetAndRestore();
        }
    };

    #getCurrentBufferedEndTime = (): number =>
        this.videoElement.buffered.length
            ? this.videoElement.buffered.end(
                  this.videoElement.buffered.length - 1
              )
            : 0;

    #onEventStalled = (): void => {
        this.previousBufferedEndTime = this.#getCurrentBufferedEndTime();
        this.isPlayerStalled = true;
    };

    #onEventNotStalled = (): void => {
        if (
            this.previousBufferedEndTime !== this.#getCurrentBufferedEndTime()
        ) {
            this.previousBufferedEndTime = this.#getCurrentBufferedEndTime();
            this.isPlayerStalled = false;
        }
    };

    #onEventOffline = (): void => {
        this.#timeoutId = window.setTimeout(
            this.#dispatchNetworkError,
            NETWORK_ERROR_RECOVERY_TIME_MS
        );
    };

    #onEventOnline = (): void => {
        if (this.#timeoutId) {
            clearTimeout(this.#timeoutId);
        }

        this.#timeoutId = null;
    };

    #VIDEO_EVENT_LISTENERS = {
        timeupdate: this.#onEventTimeupdate,
        stalled: this.#onEventStalled,
        progress: this.#onEventNotStalled,
        loadeddata: this.#onEventNotStalled,
        canplay: this.#onEventNotStalled,
        canplaythrough: this.#onEventNotStalled,
        playing: this.#onEventNotStalled,
        waiting: this.#onEventNotStalled,
        offline: this.#onEventOffline,
        online: this.#onEventOnline,
    };

    constructor(
        playbackTech: PlaybackNative,
        playbackHandler: SupportedPlaybackHandler
    ) {
        this.videoElement = playbackTech.videoElement as HTMLVideoElement;
        this.onError = playbackTech.onError;
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;

        if (isSafari()) {
            Object.entries(this.#VIDEO_EVENT_LISTENERS).forEach(
                ([eventType, handler]) => {
                    this.videoElement.addEventListener(eventType, handler);
                }
            );
        }
    }

    destroy(): void {
        Object.entries(this.#VIDEO_EVENT_LISTENERS).forEach(
            ([eventType, handler]) => {
                this.videoElement.removeEventListener(eventType, handler);
            }
        );
        this.#setInitialState();
    }

    #isInNetworkErrorState = (): boolean => {
        const bufferedLastIndexEndTime = this.#getCurrentBufferedEndTime();
        const currentVideoTime = this.videoElement.currentTime;
        const hasTimeOverlapped = currentVideoTime >= bufferedLastIndexEndTime;
        const hasTimeNotBeenUpdated =
            bufferedLastIndexEndTime === this.previousBufferedEndTime;

        return (
            this.isPlayerStalled && hasTimeOverlapped && hasTimeNotBeenUpdated
        );
    };

    #hasVideoEnded = (): boolean => {
        // Considering the last second as end of video due to some edge cases where
        // the video ends but end of duration has not been reached
        const ONE_SECOND = 1;
        const currentVideoTimeAsInteger = this.videoElement.currentTime;
        const videoDurationAsInteger = this.videoElement.duration;

        return (
            Math.abs(currentVideoTimeAsInteger - videoDurationAsInteger) <=
            ONE_SECOND
        );
    };

    #setInitialState = (): void => {
        this.isPlayerStalled = false;
        this.shouldNetworkErrorBePresented = false;
        this.previousBufferedEndTime = 0;
        this.#timeoutId = null;
    };

    #dispatchNetworkError = (): void => {
        console.error('Network stalled and there is no time available');

        return void this.onError(
            ERROR_CODES.MEDIA_ERR_NETWORK,
            DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_NETWORK],
            new MediaError(
                ERROR_CODES.MEDIA_ERR_NETWORK,
                DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_NETWORK]
            )
        );
    };
}
