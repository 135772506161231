import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC30Back = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M41.365 82.729L0 41.365 41.365 0 47.188 5.823 11.646 41.365 47.188 76.906z"
            transform="translate(22 9)"
        />
    </Ic>
);

IC30Back.displayName = 'IC30Back';

export default IC30Back;
