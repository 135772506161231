import React from 'react';
import styled, {css} from 'styled-components';

export const visuallyHiddenBaseStyles = css`
    position: absolute;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;

export const StyledVisuallyHidden = styled.div`
    ${visuallyHiddenBaseStyles}
`;

type Props = {
    children: React.ReactNode;
} & React.ComponentPropsWithRef<'div'>;

const VisuallyHidden = ({
    children,
    ...passThroughProps
}: Props): React.JSX.Element => (
    <StyledVisuallyHidden {...passThroughProps}>
        {children}
    </StyledVisuallyHidden>
);

VisuallyHidden.displayName = 'VisuallyHidden';

export default VisuallyHidden;
