import result from 'lodash/result';

export default function getBaseballSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'mlb':
        case '270':
            return {
                id: 270,
                label: 'MLB',
                route: 'mlb'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Baseball seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
