import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

const AB_URL_TESTS_RESOURCE_URL = 'https://media.foxsports.com.au/technology/resources/fe/ott/ab-url-testing.json';

export default function getAbTestModifierFunction() {
    return getApiFromRetryWithError({
        url: AB_URL_TESTS_RESOURCE_URL,
        retries: 0
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            } else {
                return {
                    testAmount: 0,
                    sport: {}
                }; // Don't let this be an error.
            }
        });
}
