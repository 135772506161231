import React from 'react';
import bacon from 'baconjs';
import isEqual from 'lodash/isEqual';

import {asBool} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import VideoCarouselWithTabsComponent from '../components/video-carousel-with-tabs';
import videoCarouselWidget from './video-carousel';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;

function VideoCarouselWithTabs(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;
    this.config = {
        displaySpoilers: asBool(this.settings.displaySpoilers, true),
        sport: this.settings.sport || 'afl',
        videoCarouselSettings: JSON.parse(this.settings.videoCarouselSettings)
    };
}

VideoCarouselWithTabs.prototype.init = function (initialData) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

VideoCarouselWithTabs.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Video-Carousel-With-Tabs',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

VideoCarouselWithTabs.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <VideoCarouselWithTabsComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

VideoCarouselWithTabs.prototype.getData = function (initialData) {
    const tabsList = this.config.videoCarouselSettings.map((tab) => tab.tabsTitle);
    const selectedTabIndexBus = new bacon.Bus();
    const selectedTabIndex = bacon.update(
        0,
        [selectedTabIndexBus], (currentIndex, newIndex) => newIndex
    ).skipDuplicates(isEqual);

    const displaySpoilersBus = new bacon.Bus();
    const displaySpoilers = displaySpoilersBus.toProperty(this.config.displaySpoilers);

    const videoCarouselStream = bacon
        .combineTemplate({
            index: selectedTabIndex,
            displaySpoilers
        })
        .flatMapLatest(({index, displaySpoilers}) => {
            const videoCarouselSettings = Object.assign(
                {},
                this.config.videoCarouselSettings[index],
                {displaySpoilers}
            );

            return videoCarouselWidget(null, videoCarouselSettings)
                .initComponentStream(initialData && initialData.videoCarousel);
        });

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            sportDetails: getSportDetails(this.config.sport),
            videoCarousel: videoCarouselStream.map('.reactElement'),
            selectedTabIndex,
            tabsList,
            isContentVisible: displaySpoilers,
            onShowSpoilers: () => {
                displaySpoilersBus.push(true);
            },
            handleTabClick: (index) => { selectedTabIndexBus.push(index); }
        }),
        iso: bacon.combineTemplate({
            videoCarousel: videoCarouselStream.map('.iso')
        })
    }).doAction(() => {
        initialData = null; // eslint-disable-line no-param-reassign
    });
};

VideoCarouselWithTabs.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <VideoCarouselWithTabsComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <VideoCarouselWithTabsComponent {...data.view} />,
            'hawkwidgets-video-carousel-with-tabs',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

VideoCarouselWithTabs.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

VideoCarouselWithTabs.prototype.pageBoot = function () {
    pageBoot(VideoCarouselWithTabs, 'hawkwidgets-video-carousel-with-tabs');
};

pageBoot(VideoCarouselWithTabs, 'hawkwidgets-video-carousel-with-tabs');

export default function (element, settings) {
    return new VideoCarouselWithTabs(element, settings);
}
