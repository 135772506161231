import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import HawkMatchCard from './match-card';
import TeamStartCard from './team-start-card';
import TimerReminder from './timer-reminder';
import ToggleButton from './toggle';

export default class MatchDetails extends React.Component {
    renderMatchStartCard() {
        if (this.props.hasVideoPlayer) {
            return null;
        }

        return <TeamStartCard {...this.props.teamStartCard} />;
    }

    renderTimerReminder() {
        if (this.props.matchStatus !== 'pre') {
            return null;
        }

        return (
            <TimerReminder {...this.props.timerReminder}  />
        );
    }

    renderMatchCardToggleButton() {
        if (this.props.matchStatus === 'pre') {
            return null;
        }

        return (
            <ToggleButton
                onToggleClick={this.props.onMatchCardToggleClick}
                toggleOnLabel="Match Score On"
                toggleOffLabel="Match Score Off" />
        );
    }

    renderVideoContainer() {
        if (!this.props.hasVideoPlayer) {
            return null;
        }

        return (
            <div className="fiso-hawk-match-details__video-container">
                {this.props.matchVideo}
            </div>
        );
    }

    render() {
        const containerClass = classNames(
            'fiso-hawk-match-details__container',
            {
                'fiso-hawk-match-details__container--split': this.props.hasVideoPlayer
            }
        );
        const contentClass = classNames(
            'fiso-hawk-match-details__content',
            {
                'fiso-hawk-match-details__content--without-video': !this.props.hasVideoPlayer
            }
        );
        const seriesId = get(this.props.fixturesAndResults, 'series.id');
        const sport = get(this.props.fixturesAndResults, 'sport', '');

        return (
            <section className="fiso-hawk-match-details fsui-normalise">
                <link
                    rel="stylesheet"
                    href={'/assets/images/team-logos/' +
                        `${sport}/${seriesId}/208x156/${seriesId}.css`} />
                <header className="fiso-hawk-match-details__title">
                    {this.props.showBackLink && (
                        <a href="/match-centre" className="fiso-hawk-match-details__title-link">
                            <Fsvg name="back-1" ariaHidden="true" />
                            <span className="fiso-hawk-match-details__title-link-text">Back to Match Centre</span>
                        </a>
                    )}
                    <h1 className="fiso-hawk-match-details__title-heading">
                        {this.props.title}
                    </h1>
                </header>
                <div className={containerClass}>
                    {this.renderVideoContainer()}
                    <div className={contentClass}>
                        {this.renderMatchStartCard()}
                        <div className="fiso-hawk-match-details__match-card">
                            <HawkMatchCard
                                {...this.props.fixturesAndResults}
                                showMatchScore={this.props.showMatchScore} />
                            {this.renderMatchCardToggleButton()}
                        </div>
                        {this.renderTimerReminder()}
                    </div>
                </div>
                {this.props.videoCarouselWithTabs}
            </section>
        );
    }
}

MatchDetails.defaultProps = {
    showBackLink: true
};

MatchDetails.propTypes = {
    fixturesAndResults: propTypes.object,
    hasVideoPlayer: propTypes.bool,
    matchVideo: propTypes.element,
    matchStatus: propTypes.string,
    showMatchScore: propTypes.bool,
    teamStartCard: propTypes.object,
    timerReminder: propTypes.object,
    title: propTypes.string,
    videoCarouselWithTabs: propTypes.element,
    showBackLink: propTypes.bool,

    onMatchCardToggleClick: propTypes.func
};
