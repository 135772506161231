import noop from 'lodash/noop';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import styled from 'styled-components';

import OR02Tray from '../../../../../component-library/organisms/or/02-tray';
import {Z_INDEX_OVER_SCREENS} from '../../../../utils/constants';
import {usePlayerContext} from '../../../context';
import getKeyDownHandler, {spaceKey} from '../../utils/keydown-handler';
import CaptionOptions from './tabs/caption-options';
import Diagnostics from './tabs/diagnostics';
import QualityOptions from './tabs/quality-options';

const TrayPositioner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX_OVER_SCREENS};
    width: ${({theme}) =>
        theme.isHudOpen ? `${theme.effectiveViewportWidthPx}px` : '100%'};
    pointer-events: none;
`;
const StyledOR02Tray = styled(OR02Tray)`
    pointer-events: auto;
`;

type Props = {
    postCloseFocus: () => void;
};

const UpperTray = (
    props: Props,
    ref: React.ForwardedRef<HTMLDivElement>
): React.ReactElement => {
    const playerState = usePlayerContext();
    const mediaKeysAndActionsRef = useRef({
        Escape: () => {
            playerState?.globalActions?.setTrayVisibility({upper: false});
            // Move focus to button that opened tray
            props.postCloseFocus();
        },
        ArrowLeft: noop,
        ArrowRight: noop,
        ArrowUp: noop,
        ArrowDown: noop,
        [spaceKey]: noop,
    });
    const inputHandler = getKeyDownHandler(mediaKeysAndActionsRef);

    return (
        <TrayPositioner {...props} ref={ref}>
            <StyledOR02Tray
                isTop={true}
                isVisible={playerState?.globalState?.isUpperTrayVisible}
                isPadded={true}
                onTrayClose={() => {
                    playerState?.globalActions?.setTrayVisibility({
                        upper: false,
                    });
                }}
                tabItems={[
                    {
                        name: 'Quality Options',
                        node: <QualityOptions />,
                    },
                    {
                        name: 'Closed Captions',
                        node: <CaptionOptions />,
                    },
                    {
                        name: 'Diagnostics',
                        node: <Diagnostics />,
                    },
                ]}
                onKeyDown={inputHandler}
            />
        </TrayPositioner>
    );
};

UpperTray.displayName = 'UpperTray';

export default observer(UpperTray, {forwardRef: true});
