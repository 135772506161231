import {PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS} from '@fsa-streamotion/player-state';

import {rgba} from 'polished';

import {coal, ironStone} from '../../common/palette';

export const THUMBNAIL_VISIBILITY_DELAY = 3 * 1000;
export const SEEK_ADJUSTMENT_S = 15;

export const Z_INDEX_OVER_SCREENS =
    Math.max(...Object.values(PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS)) + 1;

export const DEFAULT_THEME = {
    brandColor: '#e92754',
    brandForegroundColor: '#fff',
    brandColorVariant: '#131010',
    hudBackground: '#000c',
    hudSidebarBackground: '#000000df',
    isHudOpen: false,
    isRelVideoHoverScalingDisabled: false,
};

const blueCharcoal = '#020a25';
const hudTabBackgroundTop = '#191e2f';
const hudTabBackgroundMiddle = '#1c2133';
const hudTabBackgroundBottom = '#040c22';

export const THEMES = {
    default: {
        value: 'default',
        label: 'Default',
        ...DEFAULT_THEME,
    },
    kayo: {
        value: 'kayo',
        label: 'Kayo',
        brandColor: '#06e277',
        brandForegroundColor: coal,
        brandColorVariant: '#2a2c37',
        hudBackground: '#0a0a10cc',
        hudSidebarBackground: `color-mix(in srgb, ${ironStone} 50%, ${coal})`,
        isRelVideoHoverScalingDisabled: true,
    },
    binge: {
        value: 'binge',
        label: 'Binge',
        brandColor: '#1787f7',
        brandForegroundColor: '#fff',
        hudBackground: DEFAULT_THEME.hudBackground, // doesn't really matter because Binge doesn't have a HUD
        hudSidebarBackground: DEFAULT_THEME.hudSidebarBackground,
        brandColorVariant: '#050a19',
    },
    flash: {
        value: 'flash',
        label: 'Flash',
        brandColor: '#1a69ce',
        brandForegroundColor: '#fff',
        brandColorVariant: '#11121a',
        hudBackground: `linear-gradient(to bottom, ${rgba(
            blueCharcoal,
            0.8
        )} 0%, ${rgba(blueCharcoal, 0.6)} 100%)`,
        hudSidebarBackground: `linear-gradient(to bottom, ${rgba(
            hudTabBackgroundTop,
            0.9
        )} 0%, ${rgba(hudTabBackgroundMiddle, 0.9)} 50%, ${rgba(
            hudTabBackgroundBottom,
            0.9
        )} 100%);`,
        loadingSpinnerUrl: 'https://web.kayosports.com.au/images/loader.svg',
    },
    lifestyle: {
        value: 'lifestyle',
        label: 'LifeStyle',
        brandColor: '#fea918',
        brandForegroundColor: '#fff',
        hudBackground: DEFAULT_THEME.hudBackground,
        hudSidebarBackground: DEFAULT_THEME.hudSidebarBackground,
        brandColorVariant: '#050a19',
    },
};
