import bacon from 'baconjs';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import moment from 'moment';
import superAgent from 'superagent';

import Response from '@fsa/fs-commons/lib/request-manager/response';
import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {USER_LOCAL_STORAGE_KEY} from '../../../utils/constants';
import {prependQueryString} from '../../../utils/location';
import {getVimondHeaders} from '../../../utils/api';

import getAbUrlTests from './ab-url-tests';

export default function getVideoPlayerData({
    authToken,
    deviceLabel,
    deviceUdid,
    sport = 'afl',
    url
}) {
    if (!url) {
        return bacon.later(0, new bacon.Error(getVideoErrorData()));
    }

    // No point hitting the API if we don't have any
    // user auth data at all.
    //
    // This will change in the future, but we need to start dealing with
    // the product group, where FREE_LOGIN may be FREE_WITHOUT_LOGIN
    // (and then there's the normal SUBSCRIPTION or something)
    if (!authToken) {
        return bacon.later(
            0,
            new bacon.Error(
                {
                    isLoginRequired: true,
                    isSubscriptionRequired: true,
                    message: 'To access this video, please login or subscribe'
                }
            )
        );
    }

    const playbackDetails$ = bacon.fromBinder((sink) => {
        const request = superAgent
            .post(url)
            .query({app_name: 'webapp', protocol: 'hls'})
            .set(getVimondHeaders({
                authToken,
                sport,
                version: 2
            }))
            .send({
                playRequest: {
                    authorize: {
                        device: {
                            udid: deviceUdid,
                            label: deviceLabel
                        }
                    }
                }
            })
            .end((err, res) => {
                const resObj = new Response(err, res);
                const respText = resObj.parseResponseText();

                if (resObj.responseConsideredValid) {
                    if (respText.live && !canPlayLiveStream(respText.liveBroadcastTime)) { // check if it's live stream and has started
                        sink(new bacon.Error(getVideoErrorData({
                            code: 'LIVE_STREAM_NOT_STARTED',
                            liveBroadcastTime: respText.liveBroadcastTime
                        })));
                    } else {
                        sink(getVideoData(respText));
                        sink(new bacon.End());
                    }
                } else {
                    sink(new bacon.Error(getVideoErrorData(respText)));
                }
            });

        return () => {
            request.abort();
        };
    });

    const abUrlTests$ = getAbUrlTests();

    return bacon.combineWith(playbackDetails$, abUrlTests$, videoSourcesModifications(sport))
        .toEventStream(); // Expected to be a stream for a .when over a property.
}

/* The following is an example of AB Testing data.
{
    "testAmount": 0.2,
    "sport": {
        "afl": [
            {
                "match": "https://liveottfoxsport-i.akamaihd.net/hls/live/266247/foxfootylinearVsomevariant/master.m3u8",
                "replace": "https://something-else-i.akamaihd.net/master.m3u8"
            }
        ],
        "league": [
            {
                "match": "https://liveottfoxsport-i.akamaihd.net/hls/live/597623/foxleaguelinearV13/master.m3u8",
                "replace": "https://foxsportslinear.akamaized.net/hls/live/596871/msl4v13/master.m3u8?hdnea=exp=1573343759~acl=/*~hmac=8956b1d8f328c1f50bb444f402e54ae736898400571f6f62b43e44b0cdf7a7f0"
            }
        ]
    }
}
*/

function videoSourcesModifications(sport) {
    return (originalPlaybackDetails = {}, abUrlTests = {}) => {
        const {glob} = getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY}, {});
        const playbackDetails = cloneDeep(originalPlaybackDetails);

        /* AB Testing for URL Minipulation */
        playbackDetails.sources = get(playbackDetails, 'sources', [])
            .map((sourceDetail) => {
                const {src} = sourceDetail;
                const sportTests = get(abUrlTests, ['sport', sport], []);
                const matchedSportTest = find(sportTests, ({match}) =>
                    src.indexOf(match) !== -1
                );

                if (matchedSportTest && matchedSportTest.testAmount !== 0) {
                    if (Math.random() < matchedSportTest.testAmount) {
                        console.info('Selected for AB Test'); // eslint-disable-line no-console

                        // if we're subbing the url portion, we'd do the following.
                        // sourceDetail.src = src.replace(matchedSportTest.match, matchedSportTest.replace)
                        // Sadly due to the way our tokens are generated, this is no good for us.

                        sourceDetail.src = matchedSportTest.replace;
                    }
                }

                return sourceDetail;
            });

        /* TestExec for super secret squirrel playback */
        if (glob && glob !== 'false') {
            playbackDetails.sources = get(playbackDetails, 'sources', [])
                .map((sourceDetail) => {
                    sourceDetail.src = prependQueryString({url: sourceDetail.src, parameters: {TestExec: glob}});

                    return sourceDetail;
                });
        }

        return playbackDetails;
    };
}

function getVideoData(videoData) {
    const VALID_MIME_TYPES = {
        hls: 'application/x-mpegURL',
        mp4: 'video/mp4'
    };

    const playbackItems = videoData.playbackItems || [];
    const massagedVideoData = playbackItems.map(({bitrate = 0, mediaFormat = '', url = '', server} = {}) => (
        {
            bitrate,
            mimeType: VALID_MIME_TYPES[mediaFormat.toLowerCase()] || '',
            src: url,
            mediaFormat: VALID_MIME_TYPES[mediaFormat.toLowerCase()],
            cdnProvider: server.includes('akamaized') ? 'AKAMAI' : 'CLOUDFRONT',
            type:  VALID_MIME_TYPES[mediaFormat.toLowerCase()], // check from mediaFormat,
            hasSsai: false,

            // All auths from Vimond require us to pass around cookies and shit from manifest to
            // to the individual fragments.  Chrome and Safari does this without asking.
            // FIREFOX of course wants to be fucking different. Jerkface firefox.
            withCredentials: true
        }
    ));

    return {
        assetId: videoData.assetId,
        sources: massagedVideoData,
        metadata: {
            logData: videoData.logData,
            logUri: get(playbackItems, '0.logUri.uri'),
            maxSubscriptionStreams: get(videoData, 'streamRule.maxSubscriptionStreams')
        }
    };
}

export function canPlayLiveStream(liveBroadcastTime) {
    return moment().isAfter(liveBroadcastTime);
}

export function getVideoErrorData(errorData = {}) {
    const errorCode = errorData.code || '';
    const liveBroadcastTime = (() => {
        if (!errorData.liveBroadcastTime) {
            return 'soon';
        }

        return moment(errorData.liveBroadcastTime)
            .calendar(null, {
                sameDay: '[today at] h:mma',
                nextDay: '[tomorrow at] h:mma',
                nextWeek: '[next] dddd [at] h:mma',
                sameElse: 'Do MMM YYYY [at] h:mma'
            });
    })();

    // @TODO update the copy
    const errorMessages = {
        ASSET_NOT_FOUND: 'Asset not found',
        ASSET_PLAYBACK_INVALID_VIDEO_FORMAT: 'No playback url available for this protocol/video format',
        SERVER_NOT_FOUND: 'No playback url available for this protocol/video format',
        PERMISSION_DENIED: 'The user is not allowed to view this video',
        ASSET_PLAYBACK_INVALID_GEO_LOCATION: 'This video is not available in your region',
        ASSET_NO_ACCESS: 'You don’t have a valid subscription to watch this video',
        DEVICE_INFO_REQUIRED: 'The user device info were not part of the request',
        DEVICE_LIMIT_EXCEEDED: 'You have used your quota of allowed devices',
        SESSION_NOT_AUTHENTICATED: 'To access this video, please login or subscribe',
        UNAUTHORIZED: 'You don’t have a valid subscription to watch this video',
        LIVE_STREAM_NOT_STARTED: `This live stream will commence ${liveBroadcastTime}`
    };

    return {
        message: errorMessages[errorCode] || 'There were some issues playing this video, please try again later.',
        isLoginRequired: errorCode === 'SESSION_NOT_AUTHENTICATED',
        isSubscriptionRequired: ['SESSION_NOT_AUTHENTICATED', 'ASSET_NO_ACCESS', 'UNAUTHORIZED'].indexOf(errorCode) !== -1
    };
}

