/**
 * getLowestFrequency gets lowest frequency to poll, 'false' if frequency not available on
 * any callbacks else the smallest non-zero value among the callbacks, else '0'
 * @param  {Array}       callbacks Array of callbacks
 * @return {Number/Bool}           lowest frequency for callbacks or false
 */
export function getLowestFrequency(callbacks = []) {
    return callbacks
        .map((details) => {
            return details.frequency;
        })
        .reduce((lowest, frequency) => {
            if (lowest) {
                if (frequency && frequency < lowest) {
                    return frequency;
                }
            } else if (frequency && frequency > 0) {
                return frequency;
            }

            if (lowest) {
                return lowest;
            } else {
                return false;
            }
        }, false);
}
