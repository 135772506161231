import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC221NextUp = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M8.436 16.902v28.086l18.685-14L8.436 16.902M0 0l41.18 30.988L0 61.89V0zM46.214 0H50.432V61.891H46.214z"
            transform="translate(25 19)"
        />
    </Ic>
);

IC221NextUp.displayName = 'IC221NextUp';

export default IC221NextUp;
