// Important: Do not let prettier change import order of './player-tech'
// Otherwise it'll cause some weird runtime error
import PlayerTech from './player-tech';
import PlaybackDash from './playback/dash';
import PlaybackEmpty from './playback/empty';
import PlaybackHls from './playback/hls';
import PlaybackNative from './playback/native';
import {
    IS_MUTED,
    USER_SELECTED_PLAYER_QUALITY,
    VOLUME,
} from './utils/storage-keys';
import {VIDEO_EVENTS} from './utils/video-events';

export type {
    PlayerTechOptions,
    SourceConfig,
    ErrorDetail,
    AudioPreferences,
    BaseBitrateInfo,
    ErrorDetailDiagnostics,
    PlayerQualityLevel,
    AudioTrack,
    DashDiagnostics,
    HlsDiagnostics,
    NativeDiagnostics,
    SupportedPlayerTechInstance,
    SupportedDashPlaybackHandler,
} from './types';
export type {TextTracks} from './playback/types';
export type {DashjsConfig} from './playback/dash/types';
export type {HlsjsConfig} from './playback/hls/types';
export type {RxPlayerConfig} from './playback/rx-player/types';

export type {default as MediaError} from './media-error';

export {ERROR_CODES} from './utils/error-codes';
export {default as PlayerTechError} from './utils/player-tech-error';
export {default as PlayerTechXhrError} from './utils/player-tech-xhr-error';

export {
    setVideoQualityPreference,
    getVideoQualityPreference,
    removeVideoQualityPreference,
} from './utils/quality-settings';

export {version} from './version';
export default PlayerTech;

export const PLAYBACK_HANDLERS = {
    PlaybackDash,
    PlaybackEmpty,
    PlaybackHls,
    PlaybackNative,
};

export const STORAGE_KEYS = {
    IS_MUTED,
    USER_SELECTED_PLAYER_QUALITY,
    VOLUME,
};

export const EVENTS = {
    VIDEO: VIDEO_EVENTS,
};
