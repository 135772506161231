import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = {
    textColor?: string;
} & IcProps;

const IC17Plus = ({textColor, ...args}: Props): React.ReactElement => (
    <Ic {...args}>
        <g fillRule="nonzero" transform="scale(3.4)">
            <path d="M14.886 2.1C7.825 2.1 2.1 7.825 2.1 14.886c0 7.062 5.725 12.786 12.786 12.786 7.062 0 12.786-5.724 12.786-12.786C27.67 7.826 21.947 2.102 14.886 2.1z" />
            <path
                fill={textColor}
                d="M20.89 13.629h-4.746V9.097h-2.515v4.532H8.673v2.515h4.956v4.989h2.514v-4.99h4.746z"
            />
        </g>
    </Ic>
);

IC17Plus.displayName = 'IC17Plus';

export default IC17Plus;
