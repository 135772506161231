import PlayerTech, {STORAGE_KEYS} from '@fsa-streamotion/player-tech';
// eslint-disable-next-line no-duplicate-imports
import type {
    AudioPreferences,
    PlayerTechOptions,
} from '@fsa-streamotion/player-tech';

import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import {
    makeObservable,
    action,
    observable,
    computed,
    configure,
    set as mobxSet,
} from 'mobx';
import {
    type Subscription,
    type Observable,
    Subject,
    from,
    BehaviorSubject,
} from 'rxjs';
import {filter, map, switchMap, tap, shareReplay} from 'rxjs/operators';

import {calculateCurrentTimeWithAds} from '../utils/ad-capabilities';
import {
    type PlayerLayoutStyleValue,
    type VideoQualityValue,
    PLAYER_LAYOUT_STYLE,
    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS,
    PLAYER_TECH_VIDEO_QUALITY_CATEGORY,
    LAYOUT_PLAYERS_CAPPED_TO_SIZE,
    ANALYTICS_EVENT_NAME,
} from '../utils/constants';
import getNextLayout from '../utils/get-next-layout';
import getPlayerTechLocalStorageItem from '../utils/get-player-tech-local-storage-item';
import swap from '../utils/swap';
import createGlobalStateStreams from './streams/global-state';
import createVideoStateStreams, {
    INITIAL_VIDEO_STATE,
} from './streams/video-state';
import type {
    Streams,
    ConfigOf4KToast,
    CurrentHoverPosition,
    ErrorDatum,
    GeneralConfig,
    GlobalActions,
    GlobalState,
    HudContent,
    KeyEventConfig,
    KeyEvents,
    PlaybackData,
    PlayerStateConstructorParams,
    PlayerStateSubjects,
    RelatedVideos,
    ScreenConfig,
    Snapshot,
    StartScreenData,
    UpNext,
    VideoState,
} from './types';

const defaultPlayerTechOptions: PlayerTechOptions = {
    requestVideoSourceCallback: () => Promise.resolve(false),
    startAt: -1,
    preload: 'none',
    autoPlay: true,
    muted: false,
};

export const defaultPlaybackData: PlaybackData = {
    id: null,
    assetPlay: null,
    nativePlayerTechError: null,
    externalError: null,
    isClosedCaptionsEnabledForAsset: false,
    isKeyMomentsSlowReveal: true,
    diagnostics: {}, // key-value map to store custom diagnostics info
    options: defaultPlayerTechOptions, // Maps to PlayerTech options

    sources: [],
    markers: [],
    contentWarningLines: [],
    metadata: {},
    thumbnailBIFs: {
        sdUrl: null,
        hdUrl: null,
        sdDelayMs: null,
        hdDelayMs: null,
    },
    stillWatching: null,

    onVideoEnd: noop,
    getYouboraOptions: () => ({}),
    getYouboraAdapterConfig: () => ({}),
    getAdBreaks: noop,
};
const defaultPlaybackDataOptions = Object.keys(defaultPlaybackData);

const defaultConfigOf4KToast: ConfigOf4KToast = {
    messageFrequency: 'video',
    conditions: {
        blacklisted: true,
        display: false,
    },
    copy: {
        title: 'unknown error',
        message: null,
        icon: null,
    },
};

export default class PlayerState {
    generalConfig: GeneralConfig | null = null;
    globalActions: GlobalActions | null = null;
    globalState: GlobalState | null = null;
    screenConfigs: ScreenConfig[] | null = null;
    subjects: PlayerStateSubjects;
    subscriptions: Subscription[];

    constructor({
        numScreens = 1,
        canUseAirplay = true,
        canUseChromecast = true,
        canUserViewExitButton = true,
        youboraAccountId = null,
        youboraHost,
        canHaveRelatedVideos = false,
        canChangePlaybackSpeed = true,
        errorHandlersByCode = null,
        canUserViewHd = true,
        canUserView4k = false,
        showRelatedVideosNoContent = noop,
        onRequestRelatedVideos = noop,
        updateUpNextData = noop,
        isHudEnabled = false,
        onRequestHelp = noop,
        onRequestHudContent = noop,
        isChromecastAvailable = false,
        isChromecastConnected = false,
        onClickChromecast = noop,
        onChangeCaptions = noop,
        onScreenInteractionByType = noop,
        onPlayerInteractionByType = noop,
        onUserInteraction = noop,
        onLayoutStyleChange = noop,
        onTrackOztamSeekAction = noop,
        onTrackOztamPlaybackRateChangeAction = noop,
        getCaptionsPreference = noop,
        setCaptionsPreference = noop,
        getAudioTrackPreference = noop,
        setAudioTrackPreference = noop,
        onPlayingScreenClosed = noop,
        getIfUserNeedsEducation = noop,
        setIfUserNeedsEducationToNo = noop,
        setRefreshRate = noop,
        isProlongedPauseEnabled = false,
        onShowLongPause,
        onHideLongPause,
        waitMsShowingLongPause = 5 * 1000,
        exitPlayer,
        logger = console,
        theme,
        copy,
        uniqueDeviceId,
        device, // NOTE: don't do anything in player-state with device other than add it to generalConfig
        playReadyKeySystem,
        widevineKeySystem,
        mobxToUseProxy = 'always',
        shouldOverrideScrubberBehaviour = () => false,
        shouldAutoplayUpNext = () => true,
        getDashjsConfig,
        getHlsjsConfig,
        getRxPlayerConfig,
        isQualityControlsEnabledInPlayer = true,
        isQualityControlsEnabledInSettings = true,
        commonErrorData = {},
        onShow4KToast = noop,
        configOf4KToast = defaultConfigOf4KToast,
        shouldShow4KToast = true,
    }: PlayerStateConstructorParams = {}) {
        configure({useProxies: mobxToUseProxy});

        // Like bacon busses
        this.subjects = {
            incomingPlaybackDataSubject: new Subject(),
            interactionSubject: new Subject(),

            activeScreenIndexSubject: new BehaviorSubject(0),
            focusedScreenIndexSubject: new Subject(),
            splitViewLayoutTypeSubject:
                new BehaviorSubject<PlayerLayoutStyleValue>(
                    PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
                ),
            isInSplitViewModeSubject: new BehaviorSubject(false),
            hasReservedScreenAddedSubject: new Subject(),
            scrubberKeyInteractionSubject: new Subject(),
            isUserHoldingControlsSubject: new BehaviorSubject(false),

            upNextElementSubject: new BehaviorSubject<Element | null>(null),
            clearUpNextSubject: new Subject(),
            upNextDataSubject: new Subject(),

            stillWatchingElementSubject: new BehaviorSubject<Element | null>(
                null
            ),
            clearStillWatchingSubject: new Subject(),

            isUpperTrayVisibleSubject: new BehaviorSubject(false),
            isLowerTrayVisibleSubject: new BehaviorSubject(false),
            isHudVisibleSubject: new BehaviorSubject(false),
        };

        onLayoutStyleChange(PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE);
        this.generalConfig = {
            numScreens,
            canUseAirplay,
            canUserViewExitButton,
            canUseChromecast,
            youboraAccountId,
            youboraHost,
            canHaveRelatedVideos,
            canChangePlaybackSpeed,
            onClickChromecast,
            onChangeCaptions,
            onShowLongPause,
            onHideLongPause,
            onScreenInteractionByType,
            onUserInteraction,
            onLayoutStyleChange,
            onTrackOztamSeekAction,
            onTrackOztamPlaybackRateChangeAction,
            onPlayerInteractionByType: (event, payload) => {
                onPlayerInteractionByType({
                    event,
                    payload,
                    snapshot: this.snapshot,
                });
            },
            onPlayingScreenClosed: (screenIndex: number) => {
                const videoState =
                    this.screenConfigs?.[screenIndex]?.videoState;

                onPlayingScreenClosed({
                    screenIndex,
                    currentTime: videoState?.currentTime,
                }); // may add more value if we want
            },
            canUserViewHd,
            canUserView4k,
            isHudEnabled,
            logger,
            theme,
            copy,
            fullscreenElement: null, // This will be overwritten when this.setFullscreenElement is called on mount,
            errorHandlersByCode,
            isProlongedPauseEnabled,
            waitMsShowingLongPause,
            uniqueDeviceId,
            device,
            getCaptionsPreference,
            setCaptionsPreference,
            getAudioTrackPreference,
            setAudioTrackPreference,
            getIfUserNeedsEducation,
            setIfUserNeedsEducationToNo,
            setRefreshRate,
            onRequestHelp,
            shouldOverrideScrubberBehaviour,
            shouldAutoplayUpNext,
            isQualityControlsEnabledInPlayer,
            isQualityControlsEnabledInSettings,
            onShow4KToast,
            configOf4KToast,
            shouldShow4KToast,
        };
        this.globalActions = {
            exitPlayer: () => {
                // @TODO: send UpNext event to analytics when exiting player
                //   something needs to change - at the moment it's hard to find out which screenIndex does upNext belong to
                /*
                if (this.globalState.isUpNextMode) {
                    onScreenInteractionByType({
                        type: ANALYTICS_EVENT_TYPE.UP_NEXT_DISMISSED,
                        screenIndex: xxxx // which screen is responsible for this up-next state?
                    });
                }
                */
                if (exitPlayer) {
                    exitPlayer();
                }
            },
            showRelatedVideosNoContent,
            onRequestRelatedVideos,
            updateUpNextData,
            onRequestHudContent,
            setIsFullscreen: (shouldBeFullscreen: boolean) => {
                if (shouldBeFullscreen) {
                    this.activeScreenConfig?.playerTech?.enterFullscreen(
                        this.generalConfig?.fullscreenElement
                    );
                } else {
                    this.activeScreenConfig?.playerTech?.exitFullscreen();
                }

                this.generalConfig?.onPlayerInteractionByType(
                    ANALYTICS_EVENT_NAME.SELECT_OPTION,
                    'toggle-fullscreen'
                );
            },
            setVideoQualityByCategory: (quality: VideoQualityValue) => {
                const validCategories = Object.values(
                    PLAYER_TECH_VIDEO_QUALITY_CATEGORY
                );

                if (!validCategories.includes(quality)) {
                    return;
                }

                this.generalConfig?.onPlayerInteractionByType(
                    ANALYTICS_EVENT_NAME.SELECT_QUALITY,
                    quality
                );

                // Set the upper player height for low quality
                const PLAYER_QUALITY_LOW_CAP = 540;
                const PLAYER_QUALITY_HD_CAP = 1080;
                const layoutStyle = this.globalState?.layoutStyle;
                const screenOrder = this.globalState?.screenOrder;

                this.screenConfigs?.forEach((screenConfig, index) => {
                    const currentScreenOrder = screenOrder?.[index];
                    const canSetHd =
                        layoutStyle &&
                        currentScreenOrder !== undefined &&
                        !LAYOUT_PLAYERS_CAPPED_TO_SIZE[layoutStyle][
                            currentScreenOrder + 1
                        ];
                    const screenPlayerTech = screenConfig.playerTech;
                    // since the first player has no quality cap we will use that to set the preference
                    const canSetPreference = screenOrder?.[index] === 0;

                    let selectedHeight = 0;

                    // if the screen can play HD (is not small screen in splitview) otherwise default to low
                    if (
                        quality === PLAYER_TECH_VIDEO_QUALITY_CATEGORY.AUTO &&
                        canSetHd
                    ) {
                        screenPlayerTech.setBitrateToAuto();
                    } else if (
                        quality === PLAYER_TECH_VIDEO_QUALITY_CATEGORY.HD &&
                        canSetHd
                    ) {
                        selectedHeight = PLAYER_QUALITY_HD_CAP;
                    } else {
                        selectedHeight = PLAYER_QUALITY_LOW_CAP;
                    }

                    if (selectedHeight !== 0) {
                        screenPlayerTech.setMaxBitrate({
                            selectedHeight,
                            quality,
                        });
                    }

                    if (canSetPreference) {
                        screenPlayerTech.saveUserPreferredBitrate({
                            selectedHeight,
                            quality,
                        });
                    }
                });
            },
            setActiveScreenIndex: action((index: number) => {
                this.subjects.activeScreenIndexSubject.next(index);
            }),
            setFocusedScreenIndex: action((index: number) => {
                this.subjects.focusedScreenIndexSubject.next(index);
            }),
            setLayoutStyle: action((layoutStyle: PlayerLayoutStyleValue) => {
                if (this.globalState?.layoutStyle === layoutStyle) {
                    return;
                }

                this.generalConfig?.onLayoutStyleChange(layoutStyle);
                this.subjects.splitViewLayoutTypeSubject.next(layoutStyle);
                this.generalConfig?.onPlayerInteractionByType(
                    ANALYTICS_EVENT_NAME.SELECT_MULTIVIEW,
                    layoutStyle
                );
            }),
            swapScreenOrder: action((positionA: number, positionB: number) => {
                if (this.globalState && this.globalState?.screenOrder) {
                    this.globalState.screenOrder = swap(
                        this.globalState?.screenOrder,
                        positionA,
                        positionB
                    );
                }
            }),
            setTrayVisibility: action(
                ({
                    lower = this.globalState?.isLowerTrayVisible,
                    upper = this.globalState?.isUpperTrayVisible,
                }) => {
                    this.subjects.isLowerTrayVisibleSubject.next(
                        lower ?? false
                    );
                    this.subjects.isUpperTrayVisibleSubject.next(
                        upper ?? false
                    );
                }
            ),
            clearUpNext: () => {
                this.subjects.clearUpNextSubject.next();
            },
            clearStillWatching: () => {
                this.subjects.clearStillWatchingSubject.next();
            },
            pushUpNextElement: (element: Element | null) => {
                this.subjects.upNextElementSubject.next(element);
            },
            pushStillWatchingElement: (element: Element | null) => {
                this.subjects.stillWatchingElementSubject.next(element);
            },
            setIsInSplitViewMode: (isInSplitViewMode: boolean) => {
                if (this.isSplitViewDisabled) {
                    return;
                }

                if (this.globalState) {
                    this.globalState.isInSplitViewMode = isInSplitViewMode;
                }

                this.subjects.isInSplitViewModeSubject.next(isInSplitViewMode);
                this.subjects.isHudVisibleSubject.next(false);

                // Prevent PT saving audio state into local storage while in split view
                this.screenConfigs?.forEach(({playerTech}) => {
                    playerTech.disableAutoVolume = isInSplitViewMode;
                });

                if (!isInSplitViewMode) {
                    this.globalActions?.setLayoutStyle(
                        PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
                    );
                }
            },
            setHudVisibility: (show = this.globalState?.isHudVisible) => {
                this.subjects.isHudVisibleSubject.next(show ?? false);
            },
        };

        this.globalState = {
            activeScreenIndex: 0,
            focusedScreenIndex: 0,
            isInSplitViewMode: false,
            layoutStyle: PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE,
            screenOrder: Array.from({length: numScreens}, (_, index) => index),

            isUpperControlsVisible: true,
            isLowerControlsVisible: true,

            isUpperTrayVisible: false,
            isLowerTrayVisible: false,
            isHudVisible: false,

            isChromecastAvailable,
            isChromecastConnected,

            isMuted: getPlayerTechLocalStorageItem(
                STORAGE_KEYS.IS_MUTED,
                false
            ),
            volume: getPlayerTechLocalStorageItem(STORAGE_KEYS.VOLUME, 1),
            isFullscreen: false,

            relatedVideos: null,
            hudContent: null,

            shouldUseVideoTitle: false,

            isUserHoldingControls: false,
            isScrubberKeyInteracted: false,
            markerButtonsVisibilityMap: {}, // Maps marker buttons' indices to visibility, example: `{0: true, 1: true, 2: false}`
            controlsShownOnUserInteractionSeq: 0, // Add 1 every time controls are triggered open by user interaction

            currentHoverPosition: {
                hoverTime: 0,
                isHovering: false,
            },

            isUpNextMode: false,
            upNext: null,
            isStillWatchingMode: false,
            commonErrorData,
        };

        this.screenConfigs = Array.from(
            {length: numScreens},
            (_, screenIndex) => {
                const videoElement = document.createElement('video');
                const dashHTMLRenderingElement = document.createElement('div');
                const captionContainerElement = document.createElement('div');
                const rxPlayerCaptionContainerElement =
                    document.createElement('div');

                // Just make it fill its container. We'll do the rest with React.
                videoElement.style.height = '100%';
                videoElement.style.width = '100%';

                videoElement.className = 'streamotion-web-video';

                const playerTech = new PlayerTech(videoElement, {
                    ...defaultPlayerTechOptions,
                    getDashjsConfig,
                    getHlsjsConfig,
                    getRxPlayerConfig,
                    enableUserPreferredQuality:
                        isQualityControlsEnabledInPlayer ||
                        isQualityControlsEnabledInSettings,
                    playReadyKeySystem,
                    widevineKeySystem,
                });

                return {
                    // Lots of these will be updated as soon as we get a playerTech
                    actions: {
                        togglePlayPause: playerTech.togglePlayPause,
                        setCurrentTime: (currentTimeSeconds: number) => {
                            const videoState =
                                this.screenConfigs?.[screenIndex]?.videoState;
                            const adData = videoState?.adData;
                            const duration = videoState?.duration;

                            // Unless currentTime is being set to 0, ignore this request if ad breaks are still loading
                            if (currentTimeSeconds !== 0 && !adData) {
                                console.warn(
                                    `Ignoring setCurrentTime(${currentTimeSeconds}) request until ads are resolved`
                                );

                                return;
                            }

                            // Prevent skipping out of adsRemoved timeline
                            const clampedCurrentTimeSeconds = clamp(
                                currentTimeSeconds,
                                0,
                                Number(duration)
                            );

                            this.generalConfig?.onTrackOztamSeekAction(
                                clampedCurrentTimeSeconds
                            );

                            // Set playerTech's currentTime accounting for ad breaks.
                            // NOTE: It's not possible to set a currentTime that lands inside an ad break.
                            playerTech.currentTime =
                                calculateCurrentTimeWithAds(
                                    clampedCurrentTimeSeconds,
                                    adData
                                );
                        },
                        setCaptionTrackByIndex: (
                            trackIndex: number,
                            shouldSetCaptionsPreference = false
                        ) => {
                            const shouldEnableClosedCaptions = trackIndex >= 0;

                            // only set the caption preference if its the primary screen
                            if (shouldSetCaptionsPreference) {
                                this.generalConfig?.setCaptionsPreference(
                                    trackIndex
                                );
                            }

                            this.generalConfig?.onPlayerInteractionByType(
                                ANALYTICS_EVENT_NAME.SELECT_CLOSED_CAPTIONS,
                                'closed-captions'
                            );

                            if (shouldEnableClosedCaptions) {
                                playerTech.textTrackCurrentIndex = trackIndex;
                            } else {
                                try {
                                    playerTech.disableTextTrack();
                                } catch {
                                    // fallback to set trackIndex when playback didn't have disableTextTrack method.
                                    // specifically for avplayer
                                    playerTech.textTrackCurrentIndex =
                                        trackIndex;
                                }
                            }
                        },
                        setAudioTrackByIndex: (
                            trackIndex: number,
                            preferredAudioSettings: AudioPreferences
                        ) => {
                            // Save our preference (codec, language, channelCount) via our consumer's callback for later use
                            this.generalConfig?.setAudioTrackPreference(
                                preferredAudioSettings
                            );

                            playerTech.currentAudioTrackIndex = trackIndex;
                        },
                        currentAudioTrackIndex:
                            playerTech.currentAudioTrackIndex,
                        toggleIsPopOutPlayer: () => {
                            playerTech.toggleIsPopOutPlayer();
                            this.generalConfig?.onPlayerInteractionByType(
                                ANALYTICS_EVENT_NAME.SELECT_OPTION,
                                'popout-player'
                            );
                        },
                        setCurrentTimeToEdge: () => {
                            this.generalConfig?.onTrackOztamSeekAction(
                                playerTech.duration
                            );

                            playerTech.setCurrentTimeToEdge();

                            this.generalConfig?.onPlayerInteractionByType(
                                ANALYTICS_EVENT_NAME.SELECT_OPTION,
                                'jump2live'
                            );
                        },

                        setPlaybackRate: (playbackRate: number) => {
                            playerTech.playbackRate = playbackRate;

                            this.generalConfig?.onTrackOztamPlaybackRateChangeAction(
                                playbackRate,
                                playerTech.playing
                            );
                            this.generalConfig?.onPlayerInteractionByType(
                                ANALYTICS_EVENT_NAME.SELECT_OPTION,
                                playbackRate
                            );
                        },
                        setVolume: (volume: number) => {
                            playerTech.muted = false;
                            playerTech.volume = volume;
                        },
                        stepVolume: (step: number) => {
                            if (step === 0 || step === undefined) {
                                return;
                            }

                            playerTech.volume = parseFloat(
                                clamp(
                                    playerTech.volume + step,
                                    0,
                                    1
                                ).toPrecision(1)
                            );
                        },
                        setIsMuted: (isMuted: boolean) => {
                            playerTech.muted = isMuted;
                            this.generalConfig?.onPlayerInteractionByType(
                                ANALYTICS_EVENT_NAME.SELECT_OPTION,
                                `mute-${isMuted ? 'on' : 'off'}`
                            );
                        },
                        toggleKeyMoments: (screenIndex: number) => {
                            const currentScreenConfig =
                                this.screenConfigs?.[screenIndex];
                            const isKeyMomentsVisible =
                                currentScreenConfig?.videoState
                                    ?.isKeyMomentsVisible;

                            if (currentScreenConfig) {
                                currentScreenConfig.videoState.isKeyMomentsVisible =
                                    !isKeyMomentsVisible;
                            }

                            this.generalConfig?.onPlayerInteractionByType(
                                ANALYTICS_EVENT_NAME.SELECT_KEY
                            );
                        },
                    },
                    playbackData: cloneDeep(defaultPlaybackData),
                    startScreenData: {},
                    keyEvent: null,
                    videoState: {...INITIAL_VIDEO_STATE},
                    playerTech,
                    videoElement,
                    captionElement: dashHTMLRenderingElement,
                    captionContainerElement,
                    rxPlayerCaptionContainerElement,
                    reservationId: null, // This is the video ID that has reserved the screen for loading content.
                };
            }
        );
        // Ask mobx to start using its magic/watching for changes on this class, deeply on these specific keys
        makeObservable(this, {
            // generalConfig doesn't change over the state lifecycle
            globalActions: observable,
            globalState: observable,
            screenConfigs: observable,
            activeScreenConfig: computed,
            screensWithContent: computed,
            numOfScreensWithContent: computed,
            isSingleLayout: computed,
            canShowThumbnailCarousel: computed,
            isSplitViewDisabled: computed,
            nextAvailableScreenIndex: computed,
            lastPositionScreenIndex: computed,
            setFullscreenElement: action,
            setIsChromecastAvailable: action,
            setIsChromecastConnected: action,
            setRelatedVideos: action,
            setErrorData: action,
            setKeyEventData: action,
            setIsUserHoldingControls: action,
            setCurrentHoverPosition: action,
            setReservationId: action,
            setStartScreenData: action,
        });

        this.subscriptions = this.registerReducerStreams();
    }

    get activeScreenConfig(): ScreenConfig | undefined {
        const activeScreenIndex = this.globalState?.activeScreenIndex;

        if (activeScreenIndex === undefined) {
            return undefined;
        }

        return this.screenConfigs?.[activeScreenIndex];
    }

    get isSingleLayout(): boolean {
        return (
            this.globalState?.layoutStyle === PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
        );
    }

    get screensWithContent(): (string | number | null | undefined)[] | null {
        return (
            this.screenConfigs &&
            this.screenConfigs
                .map(({playbackData, reservationId}, screenIndex) => [
                    playbackData.sources.length || reservationId,
                    screenIndex,
                ])
                .filter(first)
                .map(last)
        );
    }

    get numOfScreensWithContent(): number | undefined {
        return this.screensWithContent?.length;
    }

    get isSplitViewDisabled(): boolean {
        return this.generalConfig?.numScreens === 1;
    }

    get isInSplitViewMode(): boolean | undefined {
        return this.globalState?.isInSplitViewMode;
    }

    get nextAvailableScreenIndex(): number | undefined {
        const emptyScreenIndexes = this.screenConfigs
            ? (this.screenConfigs
                  .map((config, index) =>
                      !config.reservationId &&
                      isEmpty(config?.playbackData.sources)
                          ? index
                          : null
                  )
                  .filter((index) => index !== null) as number[])
            : [];

        // Get the screen index of an empty screen that has the lowest position.
        return first(
            sortBy(
                emptyScreenIndexes,
                (screenIndex) => this.globalState?.screenOrder[screenIndex]
            )
        );
    }

    get lastPositionScreenIndex(): number | undefined {
        const nonEmptyScreenIndexes = this.screenConfigs
            ? (this.screenConfigs
                  .map((config, index) =>
                      config.reservationId ||
                      !isEmpty(config?.playbackData.sources)
                          ? index
                          : null
                  )
                  .filter((index) => index !== null) as number[])
            : [];

        const availableScreenSlots =
            this.globalState &&
            PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[
                this.globalState?.layoutStyle
            ];

        // Get the screen index of non empty screen that has the highest position.
        return last(
            sortBy(
                nonEmptyScreenIndexes,
                (screenIndex) => this.globalState?.screenOrder?.[screenIndex]
            ).filter((_, position) => position < Number(availableScreenSlots))
        );
    }

    getVideoState(screenIndex = 0): VideoState | undefined {
        return this.screenConfigs?.[screenIndex]?.videoState;
    }

    get snapshot(): Snapshot {
        return {
            numOfScreensWithContent: this.numOfScreensWithContent,
            activeScreenIndex: this.globalState?.activeScreenIndex,
            screenOrder: this.globalState?.screenOrder,
            layoutStyle: this.globalState?.layoutStyle, // the current number of screens
            screensWithContentLayoutStyle: getNextLayout(
                this.numOfScreensWithContent
            ),

            screenStates: this.screenConfigs?.map((screenConfig) => {
                const {videoState, playbackData} = screenConfig;
                const {currentCaptionTrackIndex, captions, duration} =
                    videoState;
                const {id, isClosedCaptionsEnabledForAsset, metadata} =
                    playbackData;
                const title = metadata?.title;
                const type = metadata?.type;
                const captionLabels = captions?.map(
                    (caption) => caption?.label
                );

                let youboraAdapterOptions;

                if (playbackData.getYouboraOptions) {
                    youboraAdapterOptions = playbackData.getYouboraOptions(
                        screenConfig.playerTech
                    );
                }

                return {
                    videoState: {
                        currentCaptionTrackIndex,
                        currentCaptionTrackLabel:
                            isClosedCaptionsEnabledForAsset
                                ? captionLabels?.[currentCaptionTrackIndex] ||
                                  'Off'
                                : 'Unavaliable',
                        duration,
                    },
                    playbackData: {
                        id,
                        isClosedCaptionsEnabledForAsset,
                        title,
                        type,
                        youboraSessionID: youboraAdapterOptions
                            ? youboraAdapterOptions.content
                                ?.customDimension[15]
                            : undefined,
                    },
                };
            }),
        };
    }

    /**
     * @returns Should we show the thumbnail carousel if they're provided?
     */
    get canShowThumbnailCarousel(): boolean | undefined {
        return (
            this.globalState?.isScrubberKeyInteracted ||
            this.globalState?.isUserHoldingControls
        );
    }

    setPlaybackData(playbackData: PlaybackData, screenIndex = 0): void {
        // Like a bacon bus.push
        this.subjects.incomingPlaybackDataSubject.next({
            playbackData,
            screenIndex,
        });
    }

    async setErrorData(
        errorDatum: ErrorDatum | null,
        screenIndex = 0
    ): Promise<void> {
        const screenConfig = this.screenConfigs?.[screenIndex];

        if (screenConfig) {
            screenConfig.playbackData.externalError = errorDatum;
        }

        if (errorDatum) {
            await screenConfig?.playerTech?.resetPlayer();
        }
    }

    setStartScreenData(data: StartScreenData = {}, screenIndex = 0): void {
        const screenConfig = this.screenConfigs?.[screenIndex];

        if (screenConfig) {
            screenConfig.startScreenData = data;
        }
    }

    setKeyEventData(
        keyEvents: KeyEvents = null,
        config: KeyEventConfig = {},
        screenIndex = 0
    ): void {
        const screenConfig = this.screenConfigs?.[screenIndex];

        if (screenConfig) {
            screenConfig.keyEvent = {
                ...screenConfig.keyEvent,
                ...config,
                events: keyEvents,
            };
        }
    }

    setFullscreenElement(fullscreenElement: HTMLVideoElement | null): void {
        if (this.generalConfig) {
            this.generalConfig.fullscreenElement = fullscreenElement;
        }
    }

    setIsChromecastAvailable(isChromecastAvailable: boolean): void {
        if (this.globalState) {
            this.globalState.isChromecastAvailable = isChromecastAvailable;
        }
    }

    setIsChromecastConnected(isChromecastConnected: boolean): void {
        if (this.globalState) {
            this.globalState.isChromecastConnected = isChromecastConnected;
        }
    }

    setRelatedVideos(relatedVideos: RelatedVideos): void {
        if (this.globalState) {
            this.globalState.relatedVideos = relatedVideos;
        }
    }

    setUpNext(upNext: UpNext | null): void {
        if (this.globalState) {
            this.globalState.upNext = upNext;
        }

        this.subjects.upNextDataSubject.next(upNext);
    }

    setHudContent(hudContent: HudContent[]): void {
        if (this.globalState) {
            this.globalState.hudContent = hudContent;
        }
    }

    setIsUserHoldingControls(isUserHoldingControls: boolean): void {
        if (this.globalState) {
            this.globalState.isUserHoldingControls = isUserHoldingControls;
        }

        this.subjects.isUserHoldingControlsSubject.next(isUserHoldingControls);
    }

    setCurrentHoverPosition(currentHoverPosition: CurrentHoverPosition): void {
        if (this.globalState) {
            this.globalState.currentHoverPosition = currentHoverPosition;
        }
    }

    setReservationId(index: number, id: null | string): void {
        const currentScreenConfig = this.screenConfigs?.[index];

        if (currentScreenConfig) {
            currentScreenConfig.reservationId = id;
        }

        this.subjects.hasReservedScreenAddedSubject.next(id);
    }

    registerReducerStreams(): Subscription[] {
        if (this.generalConfig === null) {
            return [];
        }

        // We create the base playbackData streams here
        // This is an array of streams corresponding to the playback data for each screen: [playbackData$, playbackData$, ...]
        const playbackDataStreams = Array.from(
            {length: this.generalConfig.numScreens},
            (_, index) =>
                this.subjects.incomingPlaybackDataSubject.pipe(
                    filter(({screenIndex}) => screenIndex === index),
                    // Propagate this new data to the store
                    // Note we wrap the `tap` function arg here in `action` to tell mobx that it will mutate the store
                    tap(
                        action(
                            ({playbackData}: {playbackData: PlaybackData}) => {
                                const currentScreenConfig =
                                    this.screenConfigs?.[index];
                                const isPrimaryScreenOrIsReserved =
                                    index ===
                                        this.globalState?.activeScreenIndex ||
                                    !isNil(
                                        this.screenConfigs?.[index]
                                            ?.reservationId
                                    );

                                // If user remove screen, we reset back to initial playback data
                                if (isEmpty(playbackData.sources)) {
                                    if (currentScreenConfig) {
                                        currentScreenConfig.playbackData =
                                            cloneDeep<PlaybackData>(
                                                defaultPlaybackData
                                            );
                                    }

                                    if (
                                        !this.screenConfigs?.[index]?.videoState
                                            .isEnded
                                    ) {
                                        this.generalConfig?.onPlayingScreenClosed(
                                            index
                                        );
                                    }

                                    this.setStartScreenData({}, index); // reset start screen (poster image) when we remove a video
                                } else if (isPrimaryScreenOrIsReserved) {
                                    if (currentScreenConfig) {
                                        currentScreenConfig.playbackData =
                                            Object.assign(
                                                {},
                                                defaultPlaybackData,
                                                pick(
                                                    playbackData,
                                                    defaultPlaybackDataOptions
                                                ),
                                                {
                                                    options: {
                                                        autoPlay: true, // assume autoPlay is true unless playbackData overwrites
                                                        ...playbackData.options,
                                                        dashTTMLRenderingDiv:
                                                            this
                                                                .screenConfigs?.[
                                                                index
                                                            ]?.captionElement,
                                                        captionsContainer:
                                                            this
                                                                .screenConfigs?.[
                                                                index
                                                            ]
                                                                ?.captionContainerElement,
                                                        rxPlayerCaptionContainerDiv:
                                                            this
                                                                .screenConfigs?.[
                                                                index
                                                            ]
                                                                ?.rxPlayerCaptionContainerElement,
                                                        disableAutoVolume:
                                                            this.globalState
                                                                ?.isInSplitViewMode,
                                                        audioPreferences:
                                                            this.generalConfig?.getAudioTrackPreference(),
                                                        setRefreshRate:
                                                            this.generalConfig
                                                                ?.setRefreshRate,
                                                    },
                                                    diagnostics: {
                                                        ...playbackData.diagnostics,
                                                        'Video ID':
                                                            playbackData.id,
                                                    },
                                                }
                                            );

                                        // Properties not exist in playbackData needs to be added with set for reactivity
                                        mobxSet(
                                            currentScreenConfig.playbackData,
                                            omit(
                                                playbackData,
                                                defaultPlaybackDataOptions
                                            )
                                        );
                                    }
                                }

                                this.setUpNext(null);

                                if (currentScreenConfig) {
                                    currentScreenConfig.keyEvent = null; // reset key events
                                    currentScreenConfig.reservationId = null; // reset loading state
                                }
                            }
                        )
                    ),
                    // Resolve the new config for this screen
                    map(() => this.screenConfigs?.[index]?.playbackData),
                    shareReplay({refCount: true, bufferSize: 1})
                )
        );

        const willMostLikelyHaveAds = (
            playbackData?: PlaybackData
        ): boolean => {
            const sources = playbackData?.sources;

            return sources?.some(({adTrackingUrl}) => adTrackingUrl) ?? false;
        };

        // Every time we get new playback data, run setSources on the relevant playerTech
        const updatePlayerTechSourcesStreamsToSubscribe =
            playbackDataStreams.map((playbackData$, index) =>
                playbackData$.pipe(
                    map(function disableAutoPlayForBtybOrAssetHasAds(
                        playbackData
                    ) {
                        if (
                            playbackData?.metadata?.btyb ||
                            willMostLikelyHaveAds(playbackData)
                        ) {
                            const startAt = playbackData?.options?.startAt;

                            return {
                                ...playbackData,
                                options: {
                                    ...playbackData?.options,
                                    startAt:
                                        startAt ??
                                        defaultPlayerTechOptions.startAt,
                                    autoPlay: false,
                                    preload:
                                        playbackData?.options?.preload ??
                                        'auto', // default it to `auto` if nonexisting: allow user to disable it
                                },
                            };
                        }

                        return playbackData;
                    }),
                    // setSources returns a promise, so this is like a .flatMapLatest(() => bacon.fromPromise(foo))
                    // .slice() for when using mobx without Proxy - array-like sources will fail PT Array.isArray check
                    switchMap((playbackData) => {
                        const sources = playbackData?.sources;
                        const options = playbackData?.options;
                        const preferredDashPlaybackHandler =
                            playbackData?.preferredDashPlaybackHandler;
                        const playerTech =
                            this.screenConfigs?.[index]?.playerTech;

                        if (playerTech) {
                            return from(
                                playerTech.setSources({
                                    sources: sources?.slice(),
                                    options,
                                    preferredDashPlaybackHandler,
                                })
                            );
                        }

                        return from(Promise.resolve());
                    })
                )
            );

        // Set up the videoState streams for each screen (e.g. to track currentTime, isPlaying, ...)
        const videoStateStreams = createVideoStateStreams({
            playbackDataStreams,
            playerStateInstance: this,
            subjects: this.subjects,
        });
        const videoStreams = videoStateStreams?.streams;
        const videoStreamsToSubscribe = videoStateStreams?.streamsToSubscribe;

        const globalStateStreams = createGlobalStateStreams({
            playerStateInstance: this,
            subjects: this.subjects,
            videoStreams,
        });
        const globalStreamsToSubscribe = globalStateStreams.streamsToSubscribe;

        // Now subscribe to all these streams
        const streams: Observable<Streams>[] = [
            ...updatePlayerTechSourcesStreamsToSubscribe,
            ...(videoStreamsToSubscribe ?? []),
            ...globalStreamsToSubscribe,
        ];

        return streams.map((stream) => stream.subscribe());
    }

    handleInteraction(event: Event): void {
        this.generalConfig?.onUserInteraction();
        this.subjects.interactionSubject.next(event);
    }

    async destroy(): Promise<void> {
        // Close all opened pop up players.
        this.screenConfigs?.forEach(({playerTech}) => {
            if (playerTech.isPopOutPlayer) {
                playerTech.isPopOutPlayer = false;
            }
        });

        (this.subscriptions || []).forEach((subscription) =>
            subscription.unsubscribe()
        );
        await Promise.all(
            this.screenConfigs?.map(({playerTech}) => playerTech.destroy()) ??
                []
        );
    }
}
