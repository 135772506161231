let supportsPassive = null;

/**
 * Determines whether the browser supports passive event listeners
 * See: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
 * @return {Boolean}        whether the browser supports passive
 */
export function browserSupportsPassive() {
    if (!process.browser) {
        return;
    } else if (supportsPassive !== null) {
        return supportsPassive;
    }

    let opts = Object.defineProperty({}, 'passive', {
        get: function () { // eslint-disable-line object-shorthand
            supportsPassive = true;
        }
    });

    try {
        window.addEventListener('test', null, opts);
    } catch (e) {
        // console.log(e);
    }

    if (supportsPassive) {
        // remove listener if it was successfully added
        window.removeEventListener('test', null, opts);
    }

    return supportsPassive;
}
