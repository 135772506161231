import {waitFor} from '@fsa/fs-commons/lib/utils/wait-for';

export function adwordsTrackConversionCurrency({ // eslint-disable-line import/prefer-default-export
    value,
    currency
}) {
    waitForAdwords().then(() => {
        if (window.hawkAdwordsTrackingId) {
            window.google_trackConversion({
                google_conversion_id: window.hawkAdwordsTrackingId,
                google_conversion_language: 'en',
                google_conversion_format: '3',
                google_conversion_color: 'fff',
                google_conversion_label: 'vvGqCJfPgG4QjqGdpAM',
                google_conversion_value: value,
                google_conversion_currency: currency,
                google_remarketing_only: false
            });
        }
    });
}

function waitForAdwords() {
    return waitFor({
        testFunction: () => {
            if (process.browser) {
                return !!window.google_trackConversion;
            } else {
                return true;
            }
        },
        retries: 40,
        delay: 250
    });
}
