import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import React from 'react';
import styled from 'styled-components';

import {suvaGrey} from '../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';

const List = styled.ul`
    margin: 0;
    background-color: transparent;
    padding: 0;
    width: 162px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 176px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 328px;
    `}
    /* This scrollbar-* property is for Firefox 64+ */
    scrollbar-color: ${suvaGrey} transparent;

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 4px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: ${suvaGrey};
    }
`;

const ListItem = styled.li`
    margin-bottom: 14px;
    overflow-x: hidden;
    list-style: none;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin-bottom: 18px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-bottom: 14px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 19px;
    `}

    &:last-child {
        margin-bottom: 0;
    }
`;

export type Props = {
    children?: React.ReactNode;
};

const TM06TraySectionList = ({children}: Props): React.ReactElement => (
    <List className="TM06TraySectionList" role="tablist">
        {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return (
                    <ListItem key={child.key} role="presentation">
                        {child}
                    </ListItem>
                );
            }

            return null;
        })}
    </List>
);

TM06TraySectionList.displayName = 'TM06TraySectionList';

export default TM06TraySectionList;
