/* eslint-disable react/prop-types */
import {
    CAPTIONS_OFF_INDEX,
    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS,
} from '@fsa-streamotion/player-state';
import {
    mediaQuery,
    stylesWhenNot,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import {observer} from 'mobx-react-lite';
import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import gibson from '../../../../../../common/font-helper';
import {white} from '../../../../../../common/palette';
import {
    SCREEN_1920_DESKTOP,
    useEffectiveViewport,
} from '../../../../../../common/screen-sizes';
import BA35CaptionSubRadBtn, {
    BUTTON_WIDTH_DEFAULT_PX,
    BUTTON_WIDTH_SCREEN_1920_PX,
} from '../../../../../../component-library/atoms/ba/35-caption-sub-rad-btn';
import CAM03Finite from '../../../../../../component-library/molecules/cam/03-finite';
import {usePlayerContext} from '../../../../context';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const ClosedCaptionsButtonsContainer = styled.section`
    display: flex;
`;

const ButtonAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    list-style: none;
`;

const Selector = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    ${stylesWhenNot('title')`
        padding-top: 25px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 35px;
        `}
    `}
    height: 100%;

    > div:first-child {
        margin-bottom: 13px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            margin-bottom: 18px;
        `}
    }
`;

const CaptionItemWrapper = styled.div`
    height: 100%;
    ${stylesWhen('hasBorder')`
        border-left: 1px solid ${rgba(white, 0.15)};
        padding-left: 14px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-left: 18px;
        `}
    `}
`;

const ClosedCaptionsUnavailableContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Instructions = styled.div`
    color: ${rgba(white, 0.6)};
    font: ${gibson({size: 14, lineHeight: 1.29})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 16px;
    `}

    > b {
        text-transform: uppercase;
        font-weight: 600;
    }
`;

const Information = styled(Instructions)`
    font-size: 16px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 20px;
    `}
`;

const SelectorLabel = styled.div`
    margin-bottom: 7px;
    font: ${gibson.medium({size: 14, lineHeight: 1.29})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 14px;
        font-size: 16px;
        line-height: 1.37;
    `}
`;

const ClosedCaptionsSelector = (): React.ReactElement => {
    const {
        activeScreenConfig,
        screenConfigs,
        isSingleLayout,
        globalState,
        generalConfig,
    } = usePlayerContext() ?? {};

    const {screenOrder, layoutStyle} = globalState ?? {};
    const isLargeDesktopUp = useEffectiveViewport(SCREEN_1920_DESKTOP);

    /**
     * an array of objects containing all relevant screen data
     */
    const captionData = (
        isSingleLayout
            ? activeScreenConfig
                ? [activeScreenConfig]
                : []
            : screenConfigs ?? []
    ).map((screenConfig, index) => {
        const rawCaptionTracks = screenConfig.videoState.captions;
        const captionTrackIndex =
            screenConfig.videoState.currentCaptionTrackIndex;
        const captionTracks = Array.isArray(rawCaptionTracks)
            ? rawCaptionTracks.map(({label}, index) => ({
                  label,
                  index,
                  ariaPressed: captionTrackIndex === index,
              }))
            : [];
        const isInvalidCaptionIndex =
            captionTrackIndex > captionTracks.length - 1;
        const hasCaptionDisabled = captionTrackIndex === CAPTIONS_OFF_INDEX;

        return {
            screenPosition: screenOrder?.[index] ?? 0,
            screenIndex: index,
            shouldShowCaptionsSelection:
                screenConfig.playbackData.isClosedCaptionsEnabledForAsset,
            captionTracks,
            offCaption: {
                label: 'Off',
                index: CAPTIONS_OFF_INDEX,
                ariaPressed: !!hasCaptionDisabled || isInvalidCaptionIndex,
            },
            setCaptionTrackByIndex: screenConfig.actions.setCaptionTrackByIndex,
        };
    });

    /**
     * check if captions exist for any of the screens
     */
    const captionsExist = captionData.some(
        (screenCaptionData) =>
            screenCaptionData.shouldShowCaptionsSelection &&
            !isEmpty(screenCaptionData.captionTracks)
    );

    const CaptionItems = captionData
        .sort((a, b) => (a.screenPosition < b.screenPosition ? -1 : 1))
        .filter(
            (_, index) =>
                layoutStyle &&
                index < PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS[layoutStyle]
        )
        .map((screenCaptionData, itemIndex) =>
            chunk(
                [
                    screenCaptionData.offCaption,
                    ...screenCaptionData.captionTracks,
                ],
                2
            ).map((tracks = [], btnGroupIndex) => {
                const title =
                    btnGroupIndex === 0 && !isSingleLayout
                        ? `Screen ${screenCaptionData.screenPosition + 1}`
                        : undefined;
                const hasBorder = itemIndex > 0 && btnGroupIndex === 0;
                const persistCaption = screenCaptionData.screenPosition === 0;
                const showPlaceholderText =
                    btnGroupIndex === 0 &&
                    !screenCaptionData.shouldShowCaptionsSelection;

                return (
                    <CaptionItemWrapper hasBorder={hasBorder}>
                        {!!title && <SelectorLabel>{title}</SelectorLabel>}
                        {showPlaceholderText ? (
                            <Information>
                                There’s currently no Closed Captions available
                                for this stream
                            </Information>
                        ) : (
                            <Selector
                                title={title}
                                key={tracks.reduce(
                                    (acc, curr) => acc + curr.label,
                                    ''
                                )}
                            >
                                {tracks.map(({label, index, ariaPressed}) => (
                                    <ButtonAndTextWrapper
                                        key={`${label}-${index}`}
                                    >
                                        <BA35CaptionSubRadBtn
                                            aria-pressed={ariaPressed}
                                            label={label ?? ''}
                                            onClick={() => {
                                                screenCaptionData.setCaptionTrackByIndex(
                                                    index,
                                                    persistCaption
                                                );
                                                generalConfig?.onPlayerInteractionByType(
                                                    'captions-options',
                                                    {
                                                        targetScreenIndex:
                                                            itemIndex,
                                                        captionLabel: label,
                                                    }
                                                );
                                            }}
                                        />
                                    </ButtonAndTextWrapper>
                                ))}
                            </Selector>
                        )}
                    </CaptionItemWrapper>
                );
            })
        );

    return captionsExist ? (
        <Wrapper>
            <ClosedCaptionsButtonsContainer>
                <CAM03Finite
                    itemWidthPx={
                        isLargeDesktopUp
                            ? BUTTON_WIDTH_SCREEN_1920_PX
                            : BUTTON_WIDTH_DEFAULT_PX
                    }
                    itemHeightPx={41}
                    itemGapPx={isLargeDesktopUp ? 18 : 14}
                    isButtonsInline={true}
                >
                    {CaptionItems.map((col) => col)}
                </CAM03Finite>
            </ClosedCaptionsButtonsContainer>
            <Instructions>
                Select from the options available or hide the settings by
                clicking the Close button
            </Instructions>
        </Wrapper>
    ) : (
        <ClosedCaptionsUnavailableContainer>
            <Information>
                There’s currently <b>no Closed Captions</b> available for this
                stream
            </Information>
        </ClosedCaptionsUnavailableContainer>
    );
};

ClosedCaptionsSelector.displayName = 'ClosedCaptionsSelector';

export default observer(ClosedCaptionsSelector);
