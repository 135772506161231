import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;
const IC05Exp = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M8.384 22.596L8.384 8.384 35.033 8.384 35.033 0 0 0 0 22.596zM8.384 44.954L0 44.954 0 67.313 35.033 67.313 35.033 58.928 8.384 58.928zM81.049 22.596L89.434 22.596 89.434 0 57.391 0 57.391 8.384 81.049 8.384zM81.049 44.954L81.049 58.928 57.391 58.928 57.391 67.313 89.434 67.313 89.434 44.954z"
            transform="translate(5 16)"
        />
    </Ic>
);

IC05Exp.displayName = 'IC05Exp';

export default IC05Exp;
