import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import merge from 'lodash/merge';

import ScoreCentreMatchCard from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/components/match-card';
import MicrodataComponent from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/components/card/microdata/card-microdata';

import OTTCard from './card/ott-card';
import OTTCardLeague from './card/ott-card--league';

export default class HawkMatchCard extends ScoreCentreMatchCard {
    renderCard() {
        switch (this.props.sport) {
            case 'league': {
                return <OTTCardLeague {...this.props} />;
            }
            case 'afl':
            default: {
                return <OTTCard {...this.props} />;
            }
        }
    }

    render() {
        const matchCentreUrl = `/match-centre/${this.props.fixture_id}`;
        const matchCentreLabel = `Watch video for ${this.props.team_A.name} vs ${this.props.team_B.name}`;

        return (
            <div
                className={classnames(
                    'fsui-normalise fiso-hawk-match-card',
                    {'fiso-hawk-match-card--stackable': this.props.isStackable}
                )}>
                <section className="fiso-sc-match-card">
                    <article className="fiso-sc-card">
                        <MicrodataComponent {...this.props} />
                        <a href={matchCentreUrl} title={matchCentreLabel} aria-label={matchCentreLabel}>
                            {this.renderCard()}
                        </a>
                    </article>
                </section>
            </div>
        );
    }
}

HawkMatchCard.defaultProps = Object.assign({}, HawkMatchCard.defaultProps, {
});

HawkMatchCard.propTypes = merge({}, HawkMatchCard.propTypes, {
    showMatchScore: propTypes.bool,
    sport: propTypes.string,
    isStackable: propTypes.bool
});
