/**
 * Retrieves a value from localStorage as encoded by playerTech (i.e. as a JSON stringified object with a value and an expiresIn)
 * NB this is a lite version of the getter function used inside of Player Tech. In particular it does not respect the `valueExpiresIn` prop, nor does it support Cookies
 *
 * @param key - the key to grab from local storage
 * @param defaultValue - a fallback value
 * @returns - the value retrieved from localStorage, or the defaultValue if it's not there
 */
export default function getPlayerTechLocalStorageItem<T>(
    key: string,
    defaultValue: T
): T {
    const localStorageValue = localStorage.getItem(key);

    if (!localStorageValue) {
        return defaultValue;
    }

    try {
        return JSON.parse(localStorageValue).value || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}
