import bacon from 'baconjs';

export default function ({
    hostedFieldsInstance
}) {
    return bacon.fromBinder((sink) => {
        hostedFieldsInstance.tokenize((error, response) => {
            if (error) {
                // TODO: potentially map error because not sure format it comes in
                sink(new bacon.Error(error));
            } else {
                sink(response.nonce);
                sink(new bacon.End());
            }
        });
    });
}
