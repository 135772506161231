import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

const Message = ({
    center,
    type = 'info',
    value
}) => {
    const messageClasses = classNames(
        'fiso-hawk-message',
        {'fiso-hawk-message--center': center},
        {[`fiso-hawk-message--${type}`]: type}
    );

    let iconType;

    switch (type) {
        case 'good':
            iconType = 'tick';
            break;
        case 'warn':
            iconType = 'exclaim';
            break;
        case 'fail':
            iconType = 'cross';
            break;
        default:
            iconType = 'i';
    }

    return (
        <div
            role="alert"
            aria-live="assertive"
            className={messageClasses}>
            <Fsvg name={iconType} />
            <p className="fiso-hawk-message__copy">
                {value}
            </p>
        </div>
    );
};

Message.propTypes = {
    center: propTypes.bool,
    type: propTypes.string,
    value: propTypes.node
};

export default Message;
