import type RxPlayer from 'rx-player';
import type {IPlayerError} from 'rx-player/types';

import type PlaybackRx from '.';
import {ERROR_CODES} from "../..";
import {DEFAULT_ERROR_MESSAGES} from '../../utils/error-codes';
import NativeError from '../native/error';

export default class RxPlayerError extends NativeError {
    declare playbackHandler: RxPlayer;
    declare playbackTech: PlaybackRx;

    constructor(playbackHandler: PlaybackRx, rxPlayerInstance: RxPlayer) {
        super(playbackHandler, rxPlayerInstance);

        this.playbackHandler.addEventListener('error', this.onEventError);
    }

    override destroy(): void {
        this.playbackHandler.removeEventListener('error', this.onEventError);
        super.destroy();
    }

    onEventError = (error: IPlayerError | Error): void => {
        if ('type' in error) {
            switch (error.type) {
                case 'MEDIA_ERROR':
                    return void this.onError(
                        ERROR_CODES.MEDIA_ERR_DECODE,
                        DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_DECODE],
                        error
                    );
                case 'ENCRYPTED_MEDIA_ERROR':
                    return void this.onError(
                        ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                        DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_ENCRYPTED],
                        error
                    );

                case 'NETWORK_ERROR':
                    return void this.onError(
                        ERROR_CODES.MEDIA_ERR_NETWORK,
                        DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_NETWORK],
                        error
                    );
                case 'OTHER_ERROR':
                default:
                    return void this.onError(
                        ERROR_CODES.CUSTOM_ERR_UNKNOWN,
                        DEFAULT_ERROR_MESSAGES[ERROR_CODES.CUSTOM_ERR_UNKNOWN],
                        error
                    );
            }
        }
    };
}
