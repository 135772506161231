import cookies from 'cookies-js';
import moment from 'moment';
import has from 'lodash/has';

/**
 * detects whether localStorage is both supported and available
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 *
 * @return {Boolean} Whether local storage is available
 */
exports.isLocalStorageAvailable = function () {
    let storage, x;

    try {
        storage = window.localStorage;
        x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);

        return true;
    } catch (e) {
        return false;
    }
};

/**
 * Checks if localstorage available and returns value stored against key.
 * If localstorage not available, checks if cookies available and retrieves value stored against key.
 * On no data found, any exception, or no storage formats available - will return `defaultValue`
 *
 * Uses JSON.parse on the return value (not on defaultValue though)
 *
 * @param   {String}    options.key          key to retrieve data from
 * @param   {Object}    options.defaultValue if no data found (null or undefined), return this val
 *
 * @returns {Object}                         found data stored against key. otherwise, default value
 */
exports.getLocalStorageValue = function ({key, defaultValue}) {
    if (!process || !process.browser) {
        return defaultValue;
    }

    try {
        let storedValue;
        let parsedValue;

        if (exports.isLocalStorageAvailable()) {
            storedValue = window.localStorage.getItem(key);
        } else if (cookies.enabled) {
            storedValue = cookies.get(key);
        } else {
            throw 'No localstorage or cookies available';
        }

        if (!storedValue && storedValue !== 0 && storedValue !== '') {
            return defaultValue;
        } else {
            parsedValue = window.JSON.parse(storedValue);

            if (parsedValue && !has(parsedValue, 'value')) {
                // Storage value is in old format, convert it to new!
                parsedValue = {
                    value: parsedValue,
                    valueExpiresIn: null
                };
            }

            if (parsedValue.valueExpiresIn) {
                if (!moment(parsedValue.valueExpiresIn).isAfter()) {
                    return defaultValue;
                }
            }

            return parsedValue.value;
        }
    } catch (e) {
        console.warn(`FS-Commons: Couldn't get the localstorage value for ${key}`, e.stack);

        return defaultValue;
    }
};

/**
 * Sets a localstorage value. Will console.warn if localstorage not available,
 * so check with isLocalStorageAvailable() first. Uses JSON.stringify to store value
 *
 * @param {String} options.key       key of object to store
 * @param {Object} options.value     data to store against key
 * @param {Number} options.expiresIn Time in MS to expire this data after it's set
 *
 * @returns {void}
 */
exports.setLocalStorageValue = function ({
    key,
    value,
    expiresIn = null
}) {
    // No local storage or cookies in fiso. Just go away.
    if (!process || !process.browser) {
        return;
    }

    let valueExpiresIn = null;

    if (expiresIn) {
        valueExpiresIn = moment()
            .add(expiresIn, 'milliseconds')
            .toISOString();
    }

    try {
        const stringifiedValue = window.JSON.stringify({value, valueExpiresIn});

        if (exports.isLocalStorageAvailable()) {
            window.localStorage.setItem(key, stringifiedValue);
        } else if (cookies.enabled) {
            cookies.set(key, stringifiedValue, {
                expires: 365 * 24 * 60 * 60 // in seconds
            });
        } else {
            throw 'No localstorage or cookies available';
        }
    } catch (e) {
        console.warn(`FS-Commons: Couldn't set the localstorage/cookie value for ${key}`, e.stack);
    }
};

/**
* @param {String} key       key of object to remove
*
* @returns {void}
*/
exports.removeLocalStorageValue = function (key) {
    // No local storage or cookies in fiso. Just go away.
    if (!process || !process.browser) {
        return;
    }

    try {
        if (exports.isLocalStorageAvailable()) {
            window.localStorage.removeItem(key);
        } else if (cookies.enabled) {
            cookies.expire(key, {});
        } else {
            throw 'No localstorage or cookies available';
        }
    } catch (e) {
        console.warn(`FS-Commons: Couldn't remove the localstorage/cookie value for ${key}`, e.stack);
    }
};
