import React from 'react';
import bacon from 'baconjs';
import merge from 'lodash/merge';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import {redirectNoLogin} from '../utils/auth';
import {DEFAULT_VIMOND_ENV} from '../utils/constants';
import getUserStream from '../streams/user/user-get';
import putUserStream from '../streams/user/user-put';
import EditYourDetailsComponent from '../components/edit-your-details';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function EditYourDetails(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;
    this.config = {
        sport: this.settings.sport || 'afl',
        profilePageLink: this.settings.profilePageLink,
        loginRoute: this.settings.loginRoute,
        vimondEnv: this.settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

EditYourDetails.prototype.init = function (initialData = {}) {
    redirectNoLogin(() => {
        this.closeStreams = this.getData(initialData).onValue(this.render.bind(this));
    }, {loginRoute: this.config.loginRoute});
};

EditYourDetails.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: EditYourDetails',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

EditYourDetails.prototype.initComponentStream = function (initialData = {}) {
    const data         = this.getData(initialData);

    const reactElement = data.map((data) => <EditYourDetailsComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

EditYourDetails.prototype.getData = function (initialData) {
    if (!process || !process.browser) {
        return bacon.later(0, {
            view: {
                sportDetails: getSportDetails(this.config.sport)
            },
            iso: {}
        });
    }

    const updateUserBus = new bacon.Bus();
    const {sport, vimondEnv} = this.config;

    const initialUserStream = getUserStream({sport, vimondEnv})
        .flatMapLatest((user) => user);

    const currentUser = initialLaterOr(
        initialData && initialData.currentUser,
        initialUserStream.combine(updateUserBus.toProperty(null), merge)
    );

    const updateUserStream = initialUserStream
        .combine(updateUserBus, merge)
        .flatMapLatest((user) => putUserStream({sport, user, vimondEnv}));

    const success = updateUserStream
        .flatMapLatest(true)
        .startWith(false);

    const isLoading = bacon.mergeAll(
        updateUserBus.map(true),
        updateUserStream.map(false)
    ).startWith(false);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            currentUser,
            profilePageLink: this.config.profilePageLink,
            submitDetails: (newDetails) => { updateUserBus.push(newDetails); },
            success,
            isLoading,
            sportDetails: getSportDetails(sport)
        }),
        iso: bacon.combineTemplate({
            currentUser
        })
    });
};

EditYourDetails.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <EditYourDetailsComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <EditYourDetailsComponent {...data.view} />,
            'hawkwidgets-edit-your-details',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

EditYourDetails.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

EditYourDetails.prototype.pageBoot = function () {
    pageBoot(EditYourDetails, 'hawkwidgets-edit-your-details');
};

export default function (element, settings) {
    return new EditYourDetails(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(EditYourDetails, 'hawkwidgets-edit-your-details');
