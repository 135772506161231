import React from 'react';

const GA145Pip = (): React.ReactElement => (
    <svg width="72px" height="40px" viewBox="0 0 72 40">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.001679, 0.000000)" fill="#FFFFFF">
                <path d="M71.9983206,-1.42108547e-14 L71.998,20 L35.3325092,20 L35.332,40 L-2.84217094e-14,40 L-2.84217094e-14,-1.42108547e-14 L71.9983206,-1.42108547e-14 Z" />
                <rect
                    x="37.9991137"
                    y="22.6666667"
                    width="33.999207"
                    height="17.3333333"
                />
            </g>
        </g>
    </svg>
);

GA145Pip.displayName = 'GA145Pip';

export default GA145Pip;
