import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import ScoreCentreLeagueCard from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/components/card/layout/col3';
import NiceOverflower from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/components/nice-overflower';
import {getMatchStatus, isInBreak} from '@fsa/fs-commons/lib/utils/match-status';

import OTTClock from './clock';

export default class OTTCardLeague extends ScoreCentreLeagueCard {
    renderStatus() {
        const clock = (
            <OTTClock
                key="clock"
                {...pick(this.props, [
                    'is_clock_running',
                    'match_start_date',
                    'match_status',
                    'match_time',
                    'period',
                    'periodPrefix',
                    'sport'
                ])} />
        );

        const playingStatus = isInBreak(this.props.match_status) ? 'break' : getMatchStatus(this.props.match_status);

        switch (playingStatus) {
            case 'pre':
                return clock;

            case 'live':
                return [
                    <div className="fiso-sc-card-front__status" key="status">
                        <Fsvg name="live-now" />
                    </div>,
                    clock
                ];

            case 'break':
                return [
                    <div className="fiso-sc-card-front__status" key="status">
                        {this.props.match_status}
                    </div>
                ];

            // HAWK cards should display the "date + FULL TIME" for 'post' according to HAWK-270 (time should not be displayed and it's being hidden through css)
            // that's the unique change from 'renderStatus' of 'iso-scorecentre/src/js/components/card/front.js'
            case 'post':
                return [
                    <div className="fiso-hawk-ott-card__post" key="post">
                        {clock}
                        <div className="fiso-sc-card-front__status" key="status">
                            {this.props.match_status}
                        </div>
                    </div>
                ];

            default:
                return (
                    <div className="fiso-sc-card-front__status" key="status">
                        {this.props.match_status}
                    </div>
                );
        }
    }

    renderChannel() {
        return null;
    }

    renderSeriesName() {
        return (
            <div className="fiso-sc-card-front__series-name">
                <NiceOverflower
                    textContent={this.props.series.name}
                    maxLines={2} />
            </div>
        );
    }

    renderRound() {
        return (
            <div className="fiso-hawk-ott-card__rounds">
                {(this.props.round.name === 'Finals Week 3') ? 'Prelims' : this.props.round.name}
            </div>
        );
    }

    renderVenue() {
        return (
            <div className="fiso-hawkwidgets-ott-card__venue-name">
                <NiceOverflower
                    textContent={this.props.venue.name}
                    maxLines={2} />
            </div>
        );
    }

    renderDetails(team) {
        switch (getMatchStatus(this.props.match_status)) {
            case 'pre':
                return (this.props.showMatchScore) ? this.renderLadderDetails(team) : '';

            default:
            case 'live':
            case 'post':
                return (this.props.showMatchScore) ? this.renderScore(team) : '';
        }
    }

    renderCta() {
        // it used to render betting data in footer which is not needed here
        return null;
    }

    renderFixtureButton() {
        return null;
    }

    renderLogo(team) {
        const {series, sport} = this.props;
        const logoClassName = classnames(
            `fiso-commons-logos--${sport}-${series.id}-208x156`,
            `fiso-commons-logos--${sport}-${series.id}-208x156-team-${team.id}`
        );

        return (
            <figure
                className="fiso-sc-card-front__team-logo fiso-sc-card-front__team-logo--show-code"
                data-shortname={team.code}>
                <figcaption>Team logo for {team.name}</figcaption>
                <div className={logoClassName} />
            </figure>
        );
    }
}

OTTCardLeague.defaultProps = Object.assign({}, OTTCardLeague.defaultProps, {
    showMatchScore: false
});

OTTCardLeague.propTypes = merge({}, OTTCardLeague.propTypes, {
    showMatchScore: propTypes.bool
});
