export default class DashTech {
  getResource(adapter) {
    return this._getDashPlayer(adapter).getSource();
  }

  _getDashPlayer(adapter) {
    return adapter.player.dash.mediaPlayer;
  }

  isUsed(adapter) {
    return adapter.player.dash && adapter.player.dash.mediaPlayer && !adapter.player.dash.shakaPlayer;
  }

  getRendition(adapter) {
    let ret = null;
    const player = this._getDashPlayer(adapter);
    if (player) {
      const level = player.getQualityFor('video');
      if (level) {
        const renditionInfo = player.getBitrateInfoListFor('video')[level];
        if (renditionInfo) {
          ret = this.getNpawUtils().buildRenditionString(renditionInfo.width, renditionInfo.height, renditionInfo.bitrate);
        }
      }
    }
    return ret;
  }

  getBitrate(adapter) {
    let ret = null;
    const player = this._getDashPlayer(adapter);
    if (player) {
      const level = player.getQualityFor('video');
      if (level) {
        ret = player.getBitrateInfoListFor('video')[level].bitrate;
      }
    }
    return ret;
  }
}