import React from 'react';
import bacon from 'baconjs';
import get from 'lodash/get';
import trim from 'lodash/trim';
import trimEnd from 'lodash/trimEnd';
import flatten from 'lodash/flatten';
import fpOrderBy from 'lodash/fp/orderBy';

import {asArray, asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';
import baconRouter, {getBaconRouterHistoryBus} from '@fsa/fs-commons/lib/utils/router';
import {handleStreamForIsoRender, initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';

import {createRoot} from 'react-dom/client';
import {getVideosFromShows, getShowVideoById} from '../utils/video';
import getVideosListStream from '../streams/end-points/videos-list';
import {ROUTES} from '../utils/routes';
import ShowPageComponent from '../components/show-page';

import HawkPlayer from './hawk-player';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;

function ShowPage(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        assetTypes: asArray(this.settings.assetTypes, null),
        baseUrl: trimEnd(this.settings.baseUrl, '/'),
        groupBy: this.settings.groupBy || 'round',
        path: trim(this.settings.path, '/') || '',
        size: asNumber(this.settings.size, 100),
        sport: this.settings.sport,
        supportUrl: this.settings.supportUrl,

        vimondEnv: this.settings.vimondEnv || 'prod'
    };
}

ShowPage.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

ShowPage.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Show Page',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

ShowPage.prototype.initComponentStream = function (initialData = false) {
    const data = this.getData(initialData);
    const reactElement = data.map((data) => <ShowPageComponent {...data.view} />);
    const iso = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

ShowPage.prototype.getData = function (initialData) {
    const {
        assetTypes,
        baseUrl,
        groupBy,
        path,
        size,
        sport,
        supportUrl,

        vimondEnv
    } = this.config;

    const playerOpenStatusBus = new bacon.Bus();

    const routerHistoryBus = getBaconRouterHistoryBus();
    const showsRouter = baconRouter(baseUrl, path, ...ROUTES);

    const currentShow = showsRouter
        .map('.categoryId');
    const currentShowVideo = showsRouter
        .map('.videoId');

    const showDetailsStream = currentShow
        .flatMapLatest((categoryIds) => initialLaterOr(
            initialData && initialData.showDetailsStream,
            getVideosListStream({
                assetTypes,
                categoryIds,
                groupBy,
                size,
                sport,
                vimondEnv
            })
                .map('.videos')
                .map(getVideosFromShows)
        ));

    const selectedVideo = currentShowVideo
        .combine(showDetailsStream, (videoId, allVideos) => ({allVideos, videoId}))
        .flatMapLatest(({allVideos, videoId}) => {
            if (videoId === undefined) {
                return get(allVideos, '[0].videos[0]', {});
            } else {
                return getShowVideoById(allVideos, videoId);
            }
        });

    // Since we just render all show videos in one list, extract the videos out of the showDetails and flatten into one sorted list
    const videosStream = showDetailsStream
        .map((videoLists) => videoLists.map(({videos}) => videos))
        .map(flatten)
        .map(fpOrderBy('liveBroadcastTime', 'desc'));

    const currentAndFutureVideosStream = videosStream
        .map((videoList) => videoList.filter(({liveBroadcastTime}) => liveBroadcastTime >= new Date().toISOString()));

    const pastVideosStream = videosStream
        .map((videoList) => videoList.filter(({liveBroadcastTime}) => liveBroadcastTime < new Date().toISOString()));

    const shouldPlay = bacon
        .update(
            false,
            [playerOpenStatusBus], (_, isPlayerOpen) => isPlayerOpen
        );

    const handleVideoClosed = () => {
        playerOpenStatusBus.push(false);
    };

    const videoPlayer = bacon.combineWith(selectedVideo, shouldPlay,
        (videoDetails, shouldPlay) => HawkPlayer(null, shouldPlay ? {
            autoplay: true,
            onClose: handleVideoClosed,
            sport,
            supportUrl,
            videoDetails,
            vimondEnv,
        } : {})
    )
        .flatMapLatest((playerInstance) => playerInstance.initComponentStream())
        .map('.reactElement');

    const shouldRenderStartCard = shouldPlay.not();

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            shouldRenderStartCard,

            videoPlayer,
            selectedVideo,
            currentAndFutureVideos: currentAndFutureVideosStream,
            pastVideos: pastVideosStream,

            onClickVideoPlay: () => { playerOpenStatusBus.push(true); },
            onClickVideoTile: ({id, title}) => {
                const [categoryId] = path.split('/');
                const newLocation = `${baseUrl}/${categoryId}/${id}`;

                routerHistoryBus.push({
                    location: newLocation,
                    state: id,
                    title
                });
            }
        }),
        iso: bacon.combineTemplate({
            showDetailsStream
        })
    }).doAction(() => {
        // need to fetch data again if categoryId chages, so reset initialData
        initialData = false; // eslint-disable-line no-param-reassign
    });
};

ShowPage.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <ShowPageComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <ShowPageComponent {...data.view} />,
            'hawkwidgets-show-page',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

ShowPage.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

ShowPage.prototype.pageBoot = function () {
    pageBoot(ShowPage, 'hawkwidgets-show-page');
};

pageBoot(ShowPage, 'hawkwidgets-show-page');

export default function (element, settings) {
    return new ShowPage(element, settings);
}

