import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'lodash/noop';
import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import TvGuideUpcoming from './upcoming';

export default class TvGuide extends React.PureComponent {
    renderContent() {
        const tvGuideButtonClasses = classnames(
            'fiso-hawk-button',
            'fiso-hawk-button--primary',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--chevron-right',
            'fiso-hawk-button--extra-margin',
            `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`
        );

        return (
            <div className="fiso-hawk-tv-guide__container">
                <div className="fiso-hawk-tv-guide__container-now-comming-up">
                    <div className="fiso-hawk-tv-guide__container-now">
                        <span className={`fiso-hawk-tv-guide__container-now--header fiso-hawk-color-secondary--${this.props.sportDetails.route}`}>On NOW</span>
                        <br />
                        <strong>{this.props.now.programmeTitle}:</strong> {this.props.now.title}
                    </div>
                    <div className="fiso-hawk-tv-guide__container-comming-up">
                        <span className={`fiso-hawk-tv-guide__container-comming-up--header fiso-hawk-color-secondary--${this.props.sportDetails.route}`}>Up NEXT</span>
                        <br />
                        <strong>{this.props.commingUp.programmeTitle}:</strong> {this.props.commingUp.title}
                    </div>
                </div>

                {this.props.sportDetails.route !== 'nrl' && (
                    <button
                        onClick={this.props.onOpenCloseClick}
                        className={tvGuideButtonClasses}
                        type={'default'}
                        onKeyDown={this.props.isOpen ? (event) => {
                            if (event.key === 'Escape') {
                                this.props.onOpenCloseClick(event);
                            }
                        } : noop}
                    >
                        View TV Guide
                        <Fsvg name="next-1" />
                    </button>
                )}
            </div>
        );
    }

    renderTvGuideList() {
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        /* eslint-disable jsx-a11y/click-events-have-key-events */

        if (this.props.isOpen) {
            return (
                <div
                    className="fiso-hawk-tv-guide__background"
                    onClick={this.props.onOpenCloseClick}
                    tabIndex="-1"
                    onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                            this.props.onOpenCloseClick(event);
                        }
                    }}
                    role="button" aria-label="Close TV Guide List">

                    {/* @TODO A BUTTON SHOULD BE HERE. */}

                    <div
                        className="fiso-hawk-tv-guide__tabs-list-container"
                        onClick={(e) => { e.stopPropagation(); }}>

                        <TvGuideUpcoming
                            sportDetails={this.props.sportDetails}
                            isProgrammesLoading={this.props.isProgrammesLoading}

                            tabs={this.props.tabs}
                            selectedTabIndex={this.props.selectedTabIndex}

                            programmes={this.props.programmes}

                            onTabsClick={this.props.onTabsClick} />
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="fiso-hawk-tv-guide">
                {this.props.sportDetails.route === 'nrl' && (
                    <div className="fiso-hawk-tv-guide__alert">
                        Due to rights restrictions, this 24/7 live channel may not include the content listed in the programme guide. Our service does not include Super League content, and occasionally other matches and events will be unavailable on Watch NRL. This content will be replaced by alternative programming. If you are unsure about anything, please <a className="fiso-hawk-tv-guide__a" href="mailto:watchnrl@foxsports.com.au">contact our Customer Care team</a> for clarification{/* eslint-disable-line max-len */}
                    </div>
                )}
                {this.renderContent()}
                {this.renderTvGuideList()}
            </div>
        );
    }
}

TvGuide.defaultProps = {
    now: {},
    commingUp: {},
    programmes: [],

    tabs: [],

    onOpenCloseClick: noop,
    onTabsClick: noop
};

TvGuide.propTypes = {
    isOpen: propTypes.bool,
    isProgrammesLoading: propTypes.bool,
    sportDetails: propTypes.shape({
        route: propTypes.string
    }),

    now: propTypes.shape({
        id: propTypes.string,
        programmeTitle: propTypes.string,
        title: propTypes.string,
        live: propTypes.bool,
        replay: propTypes.bool,
        startTime: propTypes.string,
        endTime: propTypes.string
    }),
    commingUp: propTypes.shape({
        id: propTypes.string,
        programmeTitle: propTypes.string,
        title: propTypes.string,
        live: propTypes.bool,
        replay: propTypes.bool,
        startTime: propTypes.string,
        endTime: propTypes.string
    }),

    programmes: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string,
        programmeTitle: propTypes.string,
        title: propTypes.string,
        live: propTypes.bool,
        replay: propTypes.bool,
        startTime: propTypes.string,
        endTime: propTypes.string
    })),

    tabs: propTypes.arrayOf(propTypes.node),
    selectedTabIndex: propTypes.number,

    onOpenCloseClick: propTypes.func,
    onTabsClick: propTypes.func
};
