import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

import TabsComponent from '@fsa-streamotion/streamotion-web-fs-ui/src/components/tabs/tabs';
import VideoCarouselSpoilerOverlay from './video-carousel-spoiler-overlay';

const VideoCarouselWithTabs = ({
    isContentVisible,
    selectedTabIndex,
    tabsList,
    videoCarousel,
    handleTabClick = noop,
    onShowSpoilers
}) => (
    <div className="fiso-hawk-video-carousel-with-tabs fsui-normalise">
        <div className="fiso-hawk-video-carousel-with-tabs__tabs-container">
            <TabsComponent
                tabs={tabsList}
                onTabClick={handleTabClick}
                selectedTabIndex={selectedTabIndex} />
        </div>
        {!isContentVisible && (
            <VideoCarouselSpoilerOverlay
                buttonText="Click to view Match Highlights and Post Match Interviews"
                onShowSpoilers={onShowSpoilers} />
        )}
        {videoCarousel}
    </div>
);

VideoCarouselWithTabs.propTypes = {
    isContentVisible: propTypes.bool,
    selectedTabIndex: propTypes.number,
    tabsList: propTypes.array, // eslint-disable-line
    videoCarousel: propTypes.element,

    handleTabClick: propTypes.func,
    onShowSpoilers: propTypes.func
};

export default VideoCarouselWithTabs;
