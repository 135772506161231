import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {STATS_API_URL} from '../utils/constants';

export default function ({
    freq = 0,
    apiKey,
    bettingProvider,
    seasonId,
    seriesId,
    sport
}) {
    const retries = (process && process.browser) ? 3 : 0;
    const url = STATS_API_URL +
        [bettingProvider, 'sports', sport, 'series', seriesId, 'seasons', seasonId].join('/') +
        `/ladder.json?userkey=${apiKey}`;

    return getApiFromRetryWithError({
        url,
        freq,
        retries
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            }
            const errorMessage = `FS-HAWK-WIDGETS: Unable to get Ladder (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
