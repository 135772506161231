import bacon from 'baconjs';
import superagent from 'superagent';
import get from 'lodash/get';

import {asString} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {setLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {streamFromApiWithRetry, getVimondHeaders, authorizationToken} from '../../utils/api';
import {logoutUser, redirectNoLogin} from '../../utils/auth';
import {
    DEFAULT_VIMOND_ENV,
    VIMOND_MIDDLELAYER_URL,
    SUBSCRIPTION_PACK_ID_LOCAL_STORAGE_KEY,
    SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY,
    LOCAL_STORAGE_EXPIRES_IN
} from '../../utils/constants';

// By adding 401 here we resolve unauthorised requests faster
const isRetryable = (error) => error.status !== 404 && error.status !== 401;

export default function getOrders({
    sport = 'afl',
    userId,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const authToken = authorizationToken();

    if (!authToken) {
        return bacon.later(0, null);
    }

    // Below URL for middle layer thingy.
    // const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}layer/order/web/user/${userId}`;

    // This url for api thingy.... because yay different.
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/${userId}/orders`;

    const apiCall = () => superagent
        .get(url)
        .set(getVimondHeaders({authToken, sport, version: 2}));

    return streamFromApiWithRetry({apiCall, isRetryable})
        .map('.body.future')
        .doAction((futureOrders) => {
            // Each time we get the future order, update our localStorage copy of the productId
            // We use this for analytics
            const subscriptionPackId = get(futureOrders, ['0', 'productId']);

            if (subscriptionPackId) {
                setLocalStorageValue({
                    key: SUBSCRIPTION_PACK_ID_LOCAL_STORAGE_KEY,
                    value: asString(subscriptionPackId),
                    expiresIn: LOCAL_STORAGE_EXPIRES_IN
                });
            }

            if (get(futureOrders, ['0', 'price']) === 0) {
                setLocalStorageValue({
                    key: SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY,
                    value: {
                        id: get(futureOrders, ['0', 'productPaymentId']),
                        startDate: get(futureOrders, ['0', 'startDate']),
                        endDate: get(futureOrders, ['0', 'endDate'])
                    },
                    expiresIn: LOCAL_STORAGE_EXPIRES_IN
                });
            }
        })
        .flatMapError((e) => {
            if (e.response.statusCode === 401) {
                // If the user is unauthorized, clear their details out of localStorage and make them log in again
                logoutUser();
                redirectNoLogin();

                return bacon.never();
            } else {
                return new bacon.Error(e);
            }
        });
}
