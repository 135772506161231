import bacon from 'baconjs';
import {getFeed, stopFeed} from './request-manager';

const defaultResponseValidator = (superAgentObject) => superAgentObject.responseOk;

/**
 * Get a stream for an API endpoint, and on connection issues
 * retry the request x times.
 *
 * After failing all retries, the final error will be released to the
 * stream as a value.
 *
 * @param  {String}      options.url             - URL to fetch
 * @param  {Number}      options.freq            - How frequently (in SECONDS!!) to poll
 * @param  {Number}      options.delay           - How long to wait (in milliseconds) to wait between retries
 * @param  {Number}      options.retries         - How many retries (not including initial) attempts
 * @param  {Number}      options.cacheTtlMs      - How long this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param  {Function}    options.isResponseValid - Superagent response object validator that returns <code>true</code> or <code>false</code> if
 *                                                 the response is valid or not valid respectively
 *
 * @return {EventStream}                         - Bacon EventStream (Returns errors AS values in stream)
 */
exports.getApiFromRetryWithError = function ({
    url,
    freq = 0,
    delay = 5 * 1000,
    retries = process.browser ? 5 : 0,
    cacheTtlMs = process && process.browser ? 30 * 1000 : 3 * 1000,
    isResponseValid = defaultResponseValidator
}) {
    return bacon.retry({
        source: () => exports.getApiFromBinder(url, freq, cacheTtlMs, isResponseValid),
        retries,
        isRetryable: () => {
            if (!process.browser) {
                return false;
            }

            // Only retry if we're not using a frequency.
            if (freq) {
                return false;
            } else {
                // On retry - switch to null (or leave as null) to not obtain cached 404 results.
                freq = null; // eslint-disable-line no-param-reassign

                return true;
            }
        },
        delay: () => {
            // on failure / error in the stream, delay for x
            // long before going again.
            return delay;
        }
    }).mapError((error) => (error));
};

/**
 * Get a stream for an API endpoint
 * @param  {String}   url             - URL to fetch
 * @param  {Number}   freq            - How frequently (in SECONDS!!) to poll
 * @param  {Number}   cacheTtlMs      - How long this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param  {Function} isResponseValid - Superagent response object validator that returns <code>true</code> or <code>false</code> if
 *                                      the response is valid or not valid respectively
 *
 * @return {EventStream}              - Bacon EventStream (will return errors, mapError to catch if required)
 */
exports.getApiFromBinder = function (
    url,
    freq,
    cacheTtlMs = process && process.browser ? 30 * 1000 : 3 * 1000,
    isResponseValid = defaultResponseValidator
) {
    return bacon.fromBinder((sink) => {
        const processCallback = function (superAgentObject) {
            if (isResponseValid(superAgentObject)) {
                sink(superAgentObject);
            } else {
                sink(new bacon.Error(superAgentObject));
            }
        };

        getFeed(url, processCallback, freq, {cacheTtlMs});

        return () => {
            stopFeed(url, processCallback);
        };
    });
};
