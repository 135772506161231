import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = {
    rate?: '1' | '1.5' | '2' | '3' | 'slo';
    textColor?: string;
} & IcProps;

/* eslint-disable quote-props */
const RATE_PATH_MAP = {
    '1': (
        <path
            d="M55.476 72.415L51.151 72.415 51.151 56.374 47.686 56.374 47.686 53.301 55.476 53.301zM76.492 72.415L71.446 72.415 68.368 67.848 65.263 72.415 60.217 72.415 65.762 65.301 60.855 58.754 65.873 58.754 68.424 62.907 70.947 58.754 75.965 58.754 71.002 65.301z"
            transform="translate(-.48 6.583) translate(1.36 1.357)"
        />
    ),
    '1.5': (
        <path
            d="M40.632 72.666L36.306 72.666 36.306 56.569 32.839 56.569 32.839 53.485 40.632 53.485zM50.201 72.971c-.898.069-1.759-.372-2.229-1.142-.47-.77-.47-1.738 0-2.507.47-.77 1.331-1.21 2.23-1.142.897-.069 1.758.372 2.228 1.142.47.77.47 1.738 0 2.507-.47.77-1.33 1.21-2.229 1.142zM64.68 72.971c-2.724.192-5.365-.984-7.046-3.139l2.025-2.638c1.15 1.43 2.882 2.266 4.715 2.277.913.077 1.817-.226 2.5-.839.682-.612 1.082-1.48 1.106-2.397-.011-.92-.408-1.794-1.094-2.407-.686-.613-1.597-.909-2.512-.815-1.236-.008-2.449.34-3.495 1l-1.941-1.167 1.47-9.36h11.205v3.485h-7.96l-.555 3.445c.812-.318 1.682-.46 2.552-.417 1.73-.103 3.426.511 4.69 1.699 1.265 1.187 1.986 2.843 1.994 4.579 0 4.444-3.855 6.694-7.655 6.694zM91.389 72.666L86.341 72.666 83.234 68.082 80.156 72.666 75.108 72.666 80.655 65.527 75.746 58.958 80.766 58.958 83.318 63.124 85.842 58.958 90.862 58.958 85.952 65.527z"
            transform="translate(.558 6.435) translate(1.36 1.362)"
        />
    ),
    '2': (
        <path
            d="M59.885 72.415H45.856l-.527-2.768 6.488-5.952c1.802-1.633 2.883-2.878 2.883-4.512-.018-.775-.362-1.507-.947-2.018-.585-.51-1.358-.752-2.13-.667-1.85.127-3.548 1.07-4.63 2.575l-1.886-2.935c1.654-2.121 4.243-3.3 6.932-3.155 4.325 0 6.93 2.297 6.93 5.647s-2.494 5.342-4.02 6.699l-3.964 3.57h8.872l.028 3.516zM78.682 72.415L73.636 72.415 70.559 67.848 67.454 72.415 62.408 72.415 67.953 65.301 63.045 58.754 67.953 58.754 70.503 62.907 73.026 58.754 78.044 58.754 73.137 65.301z"
            transform="translate(-.48 7.583) translate(1.36 1.357)"
        />
    ),
    '3': (
        <path
            d="M52.06 72.971c-2.682.071-5.244-1.11-6.935-3.194l2.053-2.68c1.187 1.35 2.843 2.2 4.632 2.374 1.886 0 3.356-.972 3.356-2.777 0-1.806-1.387-2.584-3.051-2.584h-1.692v-2.972h1.692c1.498 0 2.552-.75 2.552-2.25s-1.249-2.25-2.774-2.25c-1.696.066-3.315.725-4.576 1.861l-1.665-2.778c1.79-1.652 4.17-2.513 6.602-2.388 3.467 0 6.656 1.888 6.656 5.11.105 1.853-1.026 3.552-2.773 4.167v.084c2.004.535 3.387 2.367 3.356 4.444.027 3.722-3.495 5.833-7.434 5.833zM78.713 72.666L73.666 72.666 70.587 68.082 67.48 72.666 62.433 72.666 67.98 65.527 63.071 58.958 67.98 58.958 70.531 63.124 73.055 58.958 78.076 58.958 73.166 65.527z"
            transform="translate(.5 6.435) translate(1.36 1.362)"
        />
    ),
    'slo': (
        <path
            d="M47.51 71.666c-1.497 1.106-3.327 1.664-5.186 1.583-2.542-.096-4.96-1.124-6.795-2.889l2.219-3.277c1.321 1.373 3.095 2.222 4.992 2.388 1.387 0 2.552-.694 2.552-1.972s-1.387-1.805-2.247-2.11c-.86-.306-2.274-.806-2.912-1.084-.835-.323-1.605-.794-2.274-1.39-1.018-.95-1.566-2.301-1.498-3.694.009-1.618.742-3.147 1.997-4.166 1.455-1.08 3.24-1.62 5.048-1.528 2.24.04 4.394.876 6.074 2.361l-1.914 3.139c-1.138-1.15-2.658-1.842-4.271-1.944-1.387 0-2.496.555-2.496 1.722 0 1.166 1.081 1.61 1.941 1.944.86.334 2.247.834 2.857 1.084.983.333 1.89.862 2.663 1.555.987.971 1.53 2.309 1.497 3.695-.002 1.793-.83 3.485-2.246 4.583zM64.901 72.916L53.807 72.916 53.807 53.749 58.272 53.749 58.272 69.194 64.901 69.194zM76.661 73.25c-5.686 0-10.151-3.695-10.151-9.89 0-6.194 4.465-9.916 10.151-9.916s10.151 3.722 10.151 9.916c0 6.195-4.465 9.89-10.151 9.89zm0-15.973c-1.546-.004-3.024.638-4.077 1.772-1.053 1.133-1.586 2.656-1.47 4.2 0 3.1 2.508 5.611 5.602 5.611 3.095 0 5.603-2.512 5.603-5.61.118-1.565-.43-3.105-1.507-4.243-1.078-1.137-2.586-1.766-4.151-1.73z"
            transform="translate(1.038 6.435) translate(1.36 1.362)"
        />
    ),
};
/* eslint-enable quote-props */

const IC32PbRate = ({
    rate = 'slo',
    textColor = black,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M97.754 84.082L26.043 84.082 26.043 45.68 97.754 39.069z"
            transform="translate(1.038 6.435) translate(1.36 1.362)"
        />
        <path
            d="M20.496 63.527c-.968-.74-1.894-1.532-2.773-2.375l-7.184 4.583c2.89 3.085 6.256 5.683 9.971 7.695l-.014-9.903zM29.011 10.055c3.048-1.063 6.245-1.635 9.472-1.694L40.327.027C34.894-.17 29.48.775 24.435 2.805l4.576 7.25zM11.44 25.638c1.416-2.914 3.289-5.582 5.548-7.903l-4.59-7.194c-3.982 3.718-7.138 8.232-9.264 13.25l8.306 1.847zM52.434 11.458c2.903 1.42 5.562 3.296 7.877 5.555l7.183-4.583c-3.706-3.988-8.209-7.149-13.216-9.278l-1.844 8.306zM10.04 49.096c-1.06-3.057-1.63-6.263-1.692-9.5L.028 37.75C-.172 43.195.773 48.622 2.8 53.68l7.239-4.583zM77.812 35.333c-.353-3.729-1.24-7.387-2.635-10.861l-7.197 4.583c.794 2.291 1.316 4.669 1.553 7.083l8.28-.805z"
            transform="translate(1.038 6.435) translate(1.36 1.362)"
        />
        <g fill={textColor}>{RATE_PATH_MAP[rate]}</g>
    </Ic>
);

IC32PbRate.displayName = 'IC32PbRate';

export default IC32PbRate;
