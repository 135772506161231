import get from 'lodash/get';

import type {BaseProps, Predicate} from './types';

type Args<P extends BaseProps> = {
    test: string | Predicate<P>;
    props: P;
};

export default function fuzzyTest<P extends BaseProps>({
    test,
    props,
}: Args<P>): boolean {
    switch (typeof test) {
        case 'string':
            return !!get(props, test);
        case 'function':
            return test(props);
        default:
            return !!test;
    }
}
