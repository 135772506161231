/*
Important note (24 Jan 2020, dashjs 3.0.1):
While all the code below should work in theory (according to dash's infamous docs), the reality is that the controls below
only seem to loosely influence dash's bitrate choices. It's certainly better than nothing, but some occasions arise where dash
flat out ignores your quality change requests.

In the future we may need to investigate adding custom ABR rules: http://cdn.dashjs.org/latest/jsdoc/module-MediaPlayer.html#addABRCustomRule__anchor
 */
import type {
    MediaPlayerClass,
    QualityChangeRequestedEvent,
    DashQualityChangeRenderedEvent,
} from 'dashjs';

import type PlaybackDash from '.';
import type {PlayerQualityLevel} from '../../types';
import NativeCustomBitrate from '../native/custom-bitrate';

export default class DashCustomBitrate extends NativeCustomBitrate {
    declare playbackHandler: MediaPlayerClass;
    declare playbackTech: PlaybackDash;

    constructor(playbackTech: PlaybackDash, playbackHandler: MediaPlayerClass) {
        super(playbackTech, playbackHandler);

        const initialBitrate = this.bitrateGetUserPreferred();

        const newSettings = {
            streaming: {
                lastBitrateCachingInfo: {
                    enabled: false, // We'll handle this ourselves
                },
                buffer: {
                    fastSwitchEnabled: true, // Update quality as fast as possible.
                },
            },
        };

        // Attempt to set the initialBitrate quality as per the user suggestion.
        // This doesn't _always_ work as intended.
        if (initialBitrate !== -1) {
            Object.assign(newSettings.streaming, {
                abr: {
                    autoSwitchBitrate: {
                        video: true,
                    },
                    initialBitrate: {
                        video: initialBitrate,
                    },
                },
            });
        }

        this.playbackHandler.updateSettings(newSettings);

        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED,
            this.setInitialBitrate
        );
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.setInitialBitrate
        );
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.QUALITY_CHANGE_REQUESTED,
            this.eventDashQualityChangeRequested
        );
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED,
            this.eventDashQualityChangeRendered
        );
    }

    override destroy(): void {
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.PLAYBACK_METADATA_LOADED,
            this.setInitialBitrate
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.setInitialBitrate
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.QUALITY_CHANGE_REQUESTED,
            this.eventDashQualityChangeRequested
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED,
            this.eventDashQualityChangeRendered
        );
        super.destroy();
    }

    override set bitrateNextIndex(requestedIndex: number) {
        const bitrateDetail = this.levels[requestedIndex];
        const bitrate = bitrateDetail ? bitrateDetail.bitrate : -1;

        this.nextIndex = requestedIndex;

        this.playbackHandler.updateSettings({
            streaming: {
                abr: {
                    autoSwitchBitrate: {
                        video: false,
                    },
                },
            },
        });
        this.playbackHandler.setQualityFor('video', requestedIndex);

        if (bitrate && isFinite(bitrate)) {
            this.bitrateSaveUserPreferred(bitrate);
        }

        this.autoSwitch = false;
        this.broadcastCurrentBitrateDetails();
    }

    override get bitrateIsAuto(): boolean {
        return this.autoSwitch;
    }

    override bitrateSwitchToAuto(): void {
        this.setBitrateToAuto();
    }

    // Handles payload from DashJS `QUALITY_CHANGE_REQUESTED` event.
    // Important to note, if we have multiple adaptionSets we will get a different payload
    // WARNING: multi adaptionSet payloads utilises `representationId` and is dependant on DashJS's feature branch: `feature/adaptation-set-switching`
    private eventDashQualityChangeRequested = ({
        mediaType,
        newBitrateInfo,
        newQuality,
    }: QualityChangeRequestedEvent): void => {
        if (mediaType === 'video') {
            const autoSwitch =
                this.playbackHandler.getSettings().streaming?.abr
                    ?.autoSwitchBitrate?.video;
            // Check if we have newBitrateInfo thats unique to multi adaptionSet payloads, otherwise fallback to newQuality
            const newIndex = newBitrateInfo
                ? this.levels.findIndex(
                      ({id}) => id === newBitrateInfo.representationId
                  )
                : newQuality;

            this.nextIndex = newIndex;
            this.autoSwitch = autoSwitch ?? false;

            this.broadcastCurrentBitrateDetails();
        }
    };

    // Handles payload from DashJS `QUALITY_CHANGE_RENDERED` event.
    // Important to note, if we have multiple adaptionSets we will get a different payload
    // WARNING: multi adaptionSet payloads utilises `representationId` and is dependant on DashJS's feature branch: `feature/adaptation-set-switching`
    eventDashQualityChangeRendered = ({
        representationId,
        mediaType,
        newQuality,
    }: DashQualityChangeRenderedEvent): void => {
        if (mediaType === 'video') {
            const autoSwitch =
                this.playbackHandler.getSettings().streaming?.abr
                    ?.autoSwitchBitrate?.video;
            // Check if we have representationId thats unique to multi adaptionSet payloads, otherwise fallback to newQuality
            const newIndex = representationId
                ? this.levels.findIndex(({id}) => id === representationId)
                : newQuality;

            this.currentIndex = newIndex;
            this.currentHeight = this.levels[newIndex]?.height ?? -1;
            this.autoSwitch = autoSwitch ?? false;

            this.broadcastCurrentBitrateDetails();
        }
    };

    setInitialBitrate = (): void => {
        // for dashjs version 4.7.0-as2, `this.playbackHandler.getBitrateInfoListFor('video)` returns bitrate value in KB
        // instead of byte in versions 4.7.0 and before
        // in order to ignore this difference caused by different version, we use `getTracksFor('video')` to get bandwidth value from original tracks.
        this.levels = this.playbackHandler
            .getTracksFor('video')
            .flatMap(({bitrateList}) => bitrateList) // This gets all bitrates across adaptionSets
            .map(({id, bandwidth = 0, height = 0, width = 0}) => ({
                id, // representation id
                bitrate: bandwidth,
                height,
                width,
            }))
            .sort(
                ({bitrate: bitrateA}, {bitrate: bitrateB}) =>
                    bitrateA - bitrateB
            ); // Match dashJS's internal sort

        this.triggerBitrateLevelsLoaded({
            levels: this.levels,
            canManuallyAdjustBitrate: true,
        });

        const {level, height} = this.bitrateGetUserPreferredQuality();

        // dont set preference when we just retrieved from local storage
        if (level === 'auto') {
            this.setBitrateToAuto();
        } else if (level === 'low' || level === 'hd') {
            this.setMaxBitrate({
                selectedHeight: height,
                quality: level,
            });
        }
    };

    override setBitrateToAuto(): void {
        this.bitrateCurrentQuality = 'auto';

        this.playbackHandler.updateSettings({
            streaming: {
                abr: {
                    minBitrate: {
                        video: -1,
                    },
                    maxBitrate: {
                        video: -1,
                    },
                    autoSwitchBitrate: {
                        video: true,
                    },
                },
            },
        });

        this.nextIndex = -1;
        this.autoSwitch = true;

        this.broadcastCurrentBitrateDetails();
    }

    override setMaxBitrate({
        selectedHeight,
        quality = 'low',
    }: {
        selectedHeight: number;
        quality?: PlayerQualityLevel;
    }): void {
        this.bitrateCurrentQuality = quality;

        let highestPossibleIndex = 0;
        let highestPossibleMaxBitrate = -1;

        // See test cases in test/unit/playertech/playback/dash/custom-bitrate.js for reasoning
        if (Array.isArray(this.levels) && !!this.levels.length) {
            if (selectedHeight >= (this.levels?.[0]?.height ?? 0)) {
                highestPossibleIndex =
                    [...this.levels, {height: Infinity}].findIndex(
                        ({height}) => (height ?? 0) > selectedHeight
                    ) - 1;
            }

            // maxBitrate has a unit of KB (data in `this.levels` are in byte); +1 because it's upper bound
            highestPossibleMaxBitrate =
                Math.floor(
                    (this.levels[highestPossibleIndex]?.bitrate ?? 0) / 1000
                ) + 1;
        }

        this.playbackHandler.updateSettings({
            streaming: {
                abr: {
                    minBitrate: {
                        video: 0,
                    },
                    maxBitrate: {
                        video: highestPossibleMaxBitrate,
                    },
                    autoSwitchBitrate: {
                        video: true,
                    },
                },
            },
        });

        this.autoSwitch = true;

        this.bitrateSaveUserPreferred(highestPossibleMaxBitrate);
        this.broadcastCurrentBitrateDetails();
    }
}
