import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classnames from 'classnames';

import ImageFallback from '@fsa-streamotion/streamotion-web-fs-ui/src/components/image-fallback/image-fallback';
import RatioAsset from '@fsa-streamotion/streamotion-web-fs-ui/src/components/ratio-asset/ratio-asset';

export default class MatchVideo extends React.Component {
    renderVideoActions() {
        if (!this.props.hasVideo) {
            return null;
        }

        const liveButtonClass = classnames(
            'fiso-hawk-match-video__video-action-btn',
            'fiso-hawk-match-video__video-action-btn--live',
            {
                'fiso-hawk-match-video__video-action-btn--live-show': this.props.shouldRenderLive
            }
        );

        return (
            <div className="fiso-hawk-match-video__video-action">
                <button
                    className="fiso-hawk-match-video__video-action-btn fiso-hawk-match-video__video-action-btn--start"
                    onClick={() => {
                        this.props.onClickPlay('start');
                    }}>
                    Play from Start
                </button>
                <button
                    className={liveButtonClass}
                    onClick={() => {
                        this.props.onClickPlay('live');
                    }}>
                    Play from LIVE
                </button>
            </div>
        );
    }

    renderStartCard() {
        if (!this.props.shouldRenderStartCard) {
            return null;
        }

        return (
            <section className="fiso-hawk-match-video__startcard-container">
                <RatioAsset ratio="16x9">
                    <figure className="fiso-hawk-match-video__team-card">
                        <ImageFallback fallbackSrc={this.props.homeTeam.fallbackLogo}>
                            <img
                                alt={this.props.homeTeam.name + ' logo'}
                                className="fiso-hawk-match-video__team-card-image"
                                data-src={this.props.homeTeam.logo}
                                src={this.props.homeTeam.logo} />
                        </ImageFallback>

                        <ImageFallback fallbackSrc={this.props.awayTeam.fallbackLogo}>
                            <img
                                alt={this.props.awayTeam.name + ' logo'}
                                className="fiso-hawk-match-video__team-card-image fiso-hawk-match-video__team-card-image--away-team" // eslint-disable-line max-len
                                data-src={this.props.awayTeam.logo}
                                src={this.props.awayTeam.logo} />
                        </ImageFallback>
                    </figure>
                </RatioAsset>

                {this.renderVideoActions()}
            </section>
        );
    }

    renderPlayer() {
        return (
            <div className="fiso-hawk-match-video__player">
                {this.props.videoPlayer}
            </div>
        );
    }

    render() {
        return (
            <div className="fiso-hawk-match-video fsui-normalise">
                {this.renderPlayer()}
                {this.renderStartCard()}
            </div>
        );
    }
}

MatchVideo.defaultProps = {
    awayTeam: {},
    homeTeam: {},

    onClickPlay: noop
};

MatchVideo.propTypes = {
    hasVideo: propTypes.bool,
    shouldRenderStartCard: propTypes.bool,
    shouldRenderLive: propTypes.bool,

    awayTeam: propTypes.shape({
        fallbackLogo: propTypes.string,
        logo: propTypes.string,
        name: propTypes.string
    }),
    homeTeam: propTypes.shape({
        fallbackLogo: propTypes.string,
        logo: propTypes.string,
        name: propTypes.string
    }),
    videoPlayer: propTypes.node,

    onClickPlay: propTypes.func
};
