/* eslint-disable valid-jsdoc */

/**
 * Generate a sequence of numbers from <code>start</code> inclusive to <code>end</code> exclusive in increments of <code>step</code>.
 *
 * @param  args - [start=0], end, [step=1]
 */
function* range(...args: number[]): Generator<number> {
    const start = args.length < 2 ? 0 : (args[0] as number);
    const end = args[args.length < 2 ? 0 : 1] || 0;
    const step = args.length < 3 ? 1 : (args[2] as number);

    if ((step > 0 && end >= start) || (step < 0 && end <= start)) {
        for (let i = start; i < end; i += step) {
            yield i;
        }
    }
}

/**
 * Split a BIF into its JPEG thumbnails.
 *
 * @param buffer - a buffer containing the BIF
 */

type Thumbnail = {
    seconds: number;
    url: string;
};

export default function* parseBif(buffer: ArrayBuffer): Generator<Thumbnail> {
    const data = new DataView(buffer);

    // Proceed if this is a BIF v0.
    if (
        data.getUint32(0, true) === 0x46494289 &&
        data.getUint32(4, true) === 0x0a1a0a0d &&
        data.getUint32(8, true) === 0
    ) {
        const separation = data.getUint32(16, true) || 1000;

        for (const i of range(64, 64 + data.getUint32(12, true) * 8, 8)) {
            // using buffer.slice() is very slow on PS4. Instead we create a new DataView per thumbnail and
            // create a blob from the DataView to produce the image URL as this seems to perform much better
            const view = new DataView(
                buffer,
                data.getUint32(i + 4, true),
                data.getUint32(i + 12, true) - data.getUint32(i + 4, true)
            );

            const url = URL.createObjectURL(
                new Blob([view], {type: 'image/jpeg'})
            );

            yield {
                seconds: (data.getUint32(i, true) * separation) / 1000,
                url,
            };
        }
    }
}
