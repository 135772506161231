import React from 'react';
import propTypes from 'prop-types';
import {noop} from 'lodash/noop';

import ScrollList from '@fsa-streamotion/streamotion-web-fs-ui/src/components/scroll-list/scroll-list';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';
import VideoThumbnail from '@fsa-streamotion/streamotion-web-fs-ui/src/components/video-thumbnail/video-thumbnail';

import {getHHMMSS} from  '../utils/helpers';

export default class VideoCarousel extends React.Component {
    renderLoadingIndicator() {
        if (!this.props.isLoadingMoreContent) {
            return null;
        }

        return (
            <li key="loading" className="fiso-hawk-video-carousel__item fiso-hawk-video-carousel__item--spinner">
                <LoadingSpinner />
            </li>
        );
    }

    render() {
        if (this.props.videoThumbnailList.length === 0) {
            return null;
        }

        return (
            <div className="fiso-hawk-video-carousel fsui-normalise">
                <ScrollList
                    direction="horizontal"
                    iconNext="next-1"
                    iconPrevious="back-1"
                    loadMoreContentFunc={() => {
                        if (!this.props.isLoadingMoreContent) {
                            this.props.loadMoreContentFunc(this.props.maximumPage);
                        }
                    }}>
                    <ul className="fiso-hawk-video-carousel__list">
                        {this.props.videoThumbnailList.map((item, index) => (
                            <li key={index} className="fiso-hawk-video-carousel__item">
                                <VideoThumbnail
                                    description={this.props.isContentVisible && item.description ? item.description : ''}
                                    duration={item.duration ? getHHMMSS(item.duration) : 'LIVE'} // video duration 0 means live stream
                                    thumbnail={item.imageUrl}
                                    size="large"
                                    link={null}
                                    onLinkClick={() => { this.props.onClickVideo(item); }} />
                            </li>
                        ))}
                        {this.renderLoadingIndicator()}
                    </ul>
                </ScrollList>
            </div>
        );
    }
}

VideoCarousel.defaultProps = {
    videoThumbnailList: [],
    loadMoreContentFunc: noop,

    onClickVideo: noop
};

VideoCarousel.propTypes = {
    isContentVisible: propTypes.bool,
    isLoadingMoreContent: propTypes.bool,
    maximumPage: propTypes.number,
    videoThumbnailList: propTypes.arrayOf(propTypes.object),

    loadMoreContentFunc: propTypes.func,
    onClickVideo: propTypes.func
};
