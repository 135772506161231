import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;
const IC11AirP = (args: IcProps): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M44.717 52.85l13.638 18.166H31.078L44.717 52.85m0-6.987L22.694 75.208H66.74L44.717 45.863z"
            transform="translate(5 12)"
        />
        <path
            d="M0 0L0 67.313 21.646 67.313 27.934 58.928 8.384 58.928 8.384 8.384 81.049 8.384 81.049 58.928 61.486 58.928 67.774 67.313 89.434 67.313 89.434 0z"
            transform="translate(5 12)"
        />
    </Ic>
);

IC11AirP.displayName = 'IC11AirP';

export default IC11AirP;
