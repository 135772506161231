import bacon from 'baconjs';

import {getFeed, stopFeed} from '../request-manager/request-manager';
import {handleStreamForIsoRender as hs4iso} from '../iso/render';
import {htmlEncode} from '../utils/string';

/**
 * Returns a later EventStream of initialData if provided
 * or falls back to the given stream at given delay.
 *
 * @param  {Mixed}       initialData Initial data to return once
 * @param  {EventStream} stream      Bacon Observable to use when no initialData
 * @param  {Number}      delay       ms to delay for (defaults to 0)
 * @return {EventStream}             Returns initialData later at given ms if given initialData, or the given stream.
 */
export function initialLaterOr(initialData, stream, delay = 0) {
    if (initialData) {
        return bacon.later(delay, initialData);
    } else {
        return stream;
    }
}
export let initalLaterOr = initialLaterOr;

/**
 * Returns a once off EventStream of initialData if provided
 * or falls back to the given stream.
 *
 * @param  {Mixed}       initialData Initial data to return once
 * @param  {EventStream} stream      Bacon Observable to use when no initialData
 * @return {EventStream}             Returns initialData once if given initialData, or the given stream.
 */
export function initialOnceOr(initialData, stream) {
    if (initialData) {
        return bacon.once(initialData);
    } else {
        return stream;
    }
}

/**
 * Returns a stream that will never fire if given initialData
 * or falls back to the given stream.
 *
 * @param  {Mixed}       initialData Initial data (truey flag) to use 'never'
 * @param  {EventStream} stream      Bacon Observable to use when no Initial Data
 * @return {EventStream}             Returns never if given initialData, or the given stream.
 */
export function initialNeverOr(initialData, stream) {
    if (initialData) {
        return bacon.never();
    } else {
        return stream;
    }
}

/**
 * Returns a stream, if initialData is provided, the stream
 * will be returned with `.startWith(initialData)`
 *
 * @param  {Mixed}       initialData Initial data to startWith or falsey to ignore
 * @param  {EventStream} stream      Bacon Stream to minipulate
 * @return {EventStream}             Stream with startWith or as is
 */
export function initialStartWith(initialData, stream) {
    if (initialData) {
        return stream.startWith(initialData);
    } else {
        return stream;
    }
}

/**
 * Delays a given concatStream for delayFor ms, and then returns
 * the concatStream.  If delayFor is 0, no delay happens and the concat
 * stream is returned immediately.
 *
 * @param  {Number}      delayFor     How long to delay the stream
 * @param  {EventStream} concatStream Bacon Stream to return when delay reached
 * @return {EventStream}              The delayed (or concat) stream.
 */
export function delayStream(delayFor, concatStream) {
    if (delayFor) {
        return bacon.later(delayFor, true)
            .concat(concatStream)
            .skip(1);
    } else {
        return concatStream;
    }
}

/*
______ _________________ _____ _____   ___ _____ ___________
|  _  \  ___| ___ \ ___ \  ___/  __ \ / _ \_   _|  ___|  _  \
| | | | |__ | |_/ / |_/ / |__ | /  \// /_\ \| | | |__ | | | |
| | | |  __||  __/|    /|  __|| |    |  _  || | |  __|| | | |
| |/ /| |___| |   | |\ \| |___| \__/\| | | || | | |___| |/ /
|___/ \____/\_|   \_| \_\____/ \____/\_| |_/\_/ \____/|___/
 */

/**
 * @see /lib/utils/string{htmlEncode}
 *
 * @param  {String} string input string
 * @return {htmlEncode} output
 */
export function extractErrMsg() {
    if (process.browser && process.env.NODE_ENV !== 'production') {
        console.warn('extractErrMsg in stream helpers deprecated');
        console.warn('Please use {htmlEncode} from @fsa/fs-commons/lib/utils/string');
    }

    return htmlEncode(...arguments);
}

/**
 * @see /lib/iso/render{handleStreamForIsoRender}
 * @return {hs4sio} output
 */
export function handleStreamForIsoRender() {
    // Though strangely we won't see this.... >_> (need those iso tests)
    // But I don't want slack notifications piling up.
    if (process.browser && process.env.NODE_ENV !== 'production') {
        console.warn('handleStreamForIsoRender in stream helpers deprecated');
        console.warn('Please use {handleStreamForIsoRender} from @fsa/fs-commons/lib/iso/render');
    }

    return hs4iso(...arguments);
}

/**
 * @see lib/request-manager/streams{getSuperAgentFromBinder}
 * @param {String}                   url    URL Of Request
 * @param {Number}                   freq   Freq of Requests
 * @return {getSuperAgentFromBinder} output
 */
export function getStream(url, freq) {
    if (process.browser && process.env.NODE_ENV !== 'production') {
        console.warn('getStream in stream helpers deprecated.');
        console.warn('Please use {getApiFromBinder} from @fsa/fs-commons/lib/request-manager/streams');
        console.warn(' - Probably better to use {getApiFromRetry} from @fsa/fs-commons/lib/request-manager/streams');
    }

    return bacon.fromBinder((sink) => {
        const streamToErrorAsNeeded = function (superAgentObject) {
            if (superAgentObject.responseOk) {
                sink(superAgentObject);
            } else {
                sink(new bacon.Error(superAgentObject));
            }
        };

        getFeed(url, streamToErrorAsNeeded, freq);

        return () => {
            stopFeed(url, streamToErrorAsNeeded);
        };
    });
}

/* eslint-disable */
// Leave this function alone. It's fine it's fine.
// They're elsewhere now. Go away.

/**
 * @see lib/request-manager/streams{getSuperAgentFromBinder}
 * @param {String}                   url    URL Of Request
 * @param {Number}                   freq   Freq of Requests
 * @return {getSuperAgentFromBinder} output
 */
export function getApiFromRetry({
    url,
    freq,
    delay = 5,
    retries = process && process.browser ? 5 : 0
}) {
    if (process.browser && process.env.NODE_ENV !== 'production') {
        console.warn('requestOrRetry in stream helpers deprecated.');
        console.warn('Please use {getApiFromRetryWithError} from @fsa/fs-commons/lib/request-manager/streams');
    }

    return bacon.retry({
        source: () => getStream(url, freq),
        retries,
        isRetryable: function () {
            if (!process || !process.browser) {
                return false;
            }

            // Only retry if we're not using a frequency.
            if (!freq) {
                // On retry - switch to null (or leave as null)
                // to not obtain cached 404 results.
                freq = null;

                return true;
            } else {
                return false;
            }
        },
        delay: function () {
            // on failure / error in the stream, delay for x
            // long before going again.
            return delay;
        }
    });
}
