import bacon from 'baconjs';
import superagent from 'superagent';
import cloneDeep from 'lodash/cloneDeep';

import {MULTITENANCY_HEADER, VIMOND_MIDDLELAYER_URL} from '../../utils/constants';
import {streamFromApi} from '../../utils/api';

/**
 * Reset Password
 *
 * @param  {String} password                 e.g. new password to be used
 * @param  {String} token                    e.g. token received in email for reset password
 *
 * @param  {String} vimondEnv         e.g. 'prod'
 * @return {Observable}                      Stream containing fixturesandresults object
 */

export default function resetPassword({
    password,
    sport = 'afl',
    token,

    vimondEnv = 'prod'
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/password`;
    const request = superagent
        .post(url)
        .set(Object.assign(
            {},
            MULTITENANCY_HEADER[sport],
            {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        ))
        .send({
            password,
            token
        });

    return streamFromApi(request).flatMap((response) => {
        if (response.ok && response.statusCode >= 200 && response.statusCode < 400) {
            return true;
        }

        const error = new Error(response.body.description);

        error.response = cloneDeep(response);
        error.status = response.body.status;

        return new bacon.Error(error);
    });
}
