import getMatchesStream from '../end-points/matches';
import {DEFAULT_VIMOND_ENV} from '../../utils/constants';

import getMassagedMatchData from './match-data-massager';

export default function getScoresPost({
    count,
    sport,
    seriesIds,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return getMatchesStream({
        count,
        sport,
        seriesIds,
        type: 'complete',
        vimondEnv
    })
        .map(getMassagedMatchData);
}
