import type {ForwardedRef} from 'react';

/**
 * Set the value of a ref, whether it's a callback or object ref.
 *
 * @param ref   - the ref
 * @param value - the value to provide to the ref
 */
export default function setRefValue<T>(
    ref: ForwardedRef<T>,
    value: T | null
): void {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
}
