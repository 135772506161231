import {
    mediaQuery,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import gibson from '../../../../common/font-helper';
import {white, black} from '../../../../common/palette';
import {
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';

const LinesContainer = styled.div`
    position: relative;
    padding-left: 25px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    color: ${white};
    font: ${gibson.light({size: 18, lineHeight: 1.3})};

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        font-size: 20px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 24px;
    `}

    &::before {
        position: absolute;
        top: 4px;
        bottom: 8px;
        left: 0;
        background-color: ${white};
        width: 4px;
        content: '';
    }
`;

const TextLine = styled.p`
    margin: 0;

    ${stylesWhen('bold')`
        font-weight: 600;
    `}
`;

export type Props = {
    lines?: {
        type?: 'rating' | 'warning';
        value?: React.ReactNode;
    }[];
    className?: string;
};

export default function TM07Classification({
    lines = [],
    className,
    ...htmlAttributes
}: Props): React.ReactElement {
    return (
        <LinesContainer
            className={classnames('TM07Classification', className)}
            {...htmlAttributes}
        >
            {lines.map(({type, value}, index) => (
                <TextLine key={index} bold={type === 'rating'}>
                    {value}
                </TextLine>
            ))}
        </LinesContainer>
    );
}

TM07Classification.displayName = 'TM07Classification';
