import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import ProductTile from './product-tile';

export default class ChooseSubscription extends React.PureComponent {
    isDiscounted() {
        // Added complexity: if it’s a voucherless, initial period free thing,
        // don’t show the discount, show the original price.
        return this.props.products.some(({price, productPayments}) => {
            const discountedPrice = get(productPayments, '[0].discountedPrice', price);
            const initPrice = get(productPayments, '[0].initPrice', price);

            return price !== discountedPrice && initPrice !== discountedPrice;
        });
    }

    getPricesContent() {
        const isDiscounted = this.isDiscounted();
        const pricesClassName = classNames(
            'fiso-hawk-choose-subscription__prices',
            {'fiso-hawk-choose-subscription__prices--discount': isDiscounted}
        );

        return (
            <div className="fiso-hawk-choose-subscription__content-centralizer">
                <div className="fiso-hawk-choose-subscription__popular-prices-container">
                    <div className={pricesClassName}>
                        {this.props.products.map((product, index) => (
                            <ProductTile
                                key={index}
                                popularProduct={index === this.props.popularProduct}
                                product={product}
                                productGroupId={this.props.productGroupId}
                                currencySymbol={this.props.currencySymbol}
                                voucher={this.props.voucher}
                                withJoinButton={true}
                                sportDetails={this.props.sportDetails} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.products.length > 3) {
            // New product requirement, support up to 3 products
            return null;
        }

        return (
            <div
                className={classNames(
                    'fiso-hawk-choose-subscription',
                    {'fiso-hawk-choose-subscription--is-loading': this.props.isVoucherLoading})}>
                {this.getPricesContent()}
            </div>
        );
    }
}

ChooseSubscription.defaultProps = {
    products: [],
    isVoucherLoading: false,
    voucher: ''
};

ChooseSubscription.propTypes = {
    currencySymbol: propTypes.string,
    popularProduct: propTypes.number,
    products: propTypes.arrayOf(propTypes.shape({
        currency: propTypes.string,
        paymentPlan: propTypes.shape({
            name: propTypes.string
        }),
        id: propTypes.number,
        productPayments: propTypes.arrayOf(
            propTypes.shape({
                discountedPrice: propTypes.number,
                initPrice: propTypes.number,
                recurringDiscountedPrice: propTypes.number,
                recurringPrice: propTypes.number
            })
        )
    })),
    productGroupId: propTypes.number,
    isVoucherLoading: propTypes.bool,
    voucher: propTypes.string,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
