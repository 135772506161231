import superagent from 'superagent';

import {streamFromApi, getVimondHeaders} from '../../utils/api';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function createUser({
    sport = 'afl',
    user,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user`;

    const request = superagent
        .post(url)
        .set(getVimondHeaders({sport, version: 2}))
        .send(user);

    return streamFromApi(request).map('.body');
}
