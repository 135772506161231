import result from 'lodash/result';

export default function getMotorSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'v8-supercars': // TODO: this is deprecated, remove later
        case 'supercars':
        case '1':
            return {
                id: 1,
                label: 'Supercars',
                route: 'supercars'
            };

        case 'moto-gp':
        case '10':
            return {
                id: 10,
                label: 'MotoGP',
                route: 'moto-gp'
            };

        case 'formula-1':
        case '30':
            return {
                id: 30,
                label: 'Formula 1',
                route: 'formula-1'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Motor seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
