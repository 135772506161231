/**
 * Append an info panel to a widget's docs page.
 *
 * @param {Node}   widgetMountNode  - the DOM node in which the widget has been mounted
 * @param {Number} expiresIn        - the widget's TTL in milliseconds
 * @param {String} [settings='']    - the widget's stringified settings
 * @param {String} [markup='']      - the widget's server-side rendered HTML
 * @param {String} [initialData=''] - the widget's server-side rendered settings block
 *
 * @returns {undefined}
 */
export default function appendInfoPanel({
    widgetMountNode,
    expiresIn,
    settings = '',
    markup = '',
    initialData = '',
}) {
    // Add the TTL node
    const expiryNode = document.createElement('h2');

    expiryNode.classList.add('panel-body', 'panel-body2');
    expiryNode.appendChild(document.createTextNode(`${expiresIn / 1000} seconds TTL`));

    widgetMountNode.parentNode.appendChild(expiryNode);

    // Add the settings node
    const settingsNode = document.createElement('div');

    settingsNode.classList.add('panel', 'panel-success');

    const settingsHeadNode = document.createElement('div');

    settingsHeadNode.classList.add('panel-heading');
    settingsHeadNode.appendChild(document.createTextNode('Payload settings'));

    const settingsBodyNode = document.createElement('pre');

    settingsBodyNode.classList.add('panel-body');
    settingsBodyNode.appendChild(document.createTextNode(settings));

    settingsNode.appendChild(settingsHeadNode);
    settingsNode.appendChild(settingsBodyNode);

    widgetMountNode.parentNode.appendChild(settingsNode);

    // Add the initial data node
    const initialDataKb = Math.ceil(initialData.length / 1024);
    const markupKb = Math.ceil(markup.length / 1024);
    const totalKb = initialDataKb + markupKb;

    const initialDataNode = document.createElement('div');

    initialDataNode.classList.add('panel', 'panel-success');

    const initialDataHeadNode = document.createElement('div');

    initialDataHeadNode.classList.add('panel-heading');
    initialDataHeadNode.appendChild(document.createTextNode(
        [`Initial Data: ${initialDataKb} kb`, `Markup: ${markupKb} kb`, `Total: ${totalKb} kb`]
            .join(' | ')
    ));

    const initialDataBodyNode = document.createElement('pre');

    initialDataBodyNode.classList.add('panel-body');
    initialDataBodyNode.appendChild(document.createTextNode(initialData));

    initialDataNode.appendChild(initialDataHeadNode);
    initialDataNode.appendChild(initialDataBodyNode);

    widgetMountNode.parentNode.appendChild(initialDataNode);
}
