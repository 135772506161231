import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {white, black} from '../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';

const StyledButton = styled(Button)`
    transition: ${transition('opacity', 'border-left')};
    margin: 0;
    border: 0;
    border-left: 2px solid transparent;
    background-color: transparent;
    padding: 0 14px;
    width: 162px;
    overflow: hidden;
    text-align: left;
    text-transform: capitalize;
    text-shadow: 1px 1px ${rgba(black, 0.3)};
    color: ${rgba(white, 0.6)};
    font: ${gibson.light({size: 14, lineHeight: 1.3})};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 176px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        font: ${gibson.light({size: 16, lineHeight: 1.38})};
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        padding: 0 35px;
        width: 328px;
        font: ${gibson.light({size: 20, lineHeight: 1.2})};
    `}

    &[aria-selected='true'] {
        border-left: 2px solid ${white};
        color: ${white};
        font-weight: normal;
    }
    /* We explicitly put these at the end so the hover/focus state takes precedence over aria-selected */
    /* stylelint-disable order/order */
    &:hover,
    &:focus-visible {
        outline: none;
        border-left: 2px solid ${THEME_ACCESSORS.brandColor};
        color: ${white};
    }
    /* stylelint-enable order/order */
`;

export type Props = {
    label: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    isActive?: boolean;
};

const BA02TraySectionListItem = React.forwardRef<HTMLButtonElement, Props>(
    ({label, onClick, isActive}, ref) => (
        <StyledButton ref={ref} onClick={onClick} aria-selected={isActive}>
            {label}
        </StyledButton>
    )
);

BA02TraySectionListItem.displayName = 'BA02TraySectionListItem';

export default BA02TraySectionListItem;
