import type {PlaybackData, VideoState} from '@fsa-streamotion/player-state';
import type PlayerTech from '@fsa-streamotion/player-tech';
// eslint-disable-next-line no-duplicate-imports
import type {
    PlayerQualityLevel,
    DashDiagnostics,
    HlsDiagnostics,
    NativeDiagnostics,
} from '@fsa-streamotion/player-tech';

import {version} from '../version';
import {AUDIO_CHANNEL_COUNT_TO_LABEL} from './get-audio-track-label-by-channel-count';

type ScreenConfig = {
    playerTech: PlayerTech;
    videoState: VideoState;
    playbackData: PlaybackData;
};

const isNativeDiagnostics = (
    diagnostics: DashDiagnostics | HlsDiagnostics | NativeDiagnostics
): diagnostics is NativeDiagnostics =>
    diagnostics.playbackHandlerType === 'native';

const isDashOrHlsDiagnostics = (
    diagnostics: DashDiagnostics | HlsDiagnostics | NativeDiagnostics
): diagnostics is DashDiagnostics | HlsDiagnostics =>
    diagnostics.playbackHandlerType === 'dash' ||
    diagnostics.playbackHandlerType === 'hls' ||
    diagnostics.playbackHandlerType === 'rx-player';

type ParsedDiagnosticsType = {
    videoWidth?: number;
    videoHeight?: number;
    videoBitrate?: number;
    videoCodec?: string;
    audioBitrate?: number;
    audioCodec?: string;
    audioTrackName?: string;
    totalFrames?: number;
    droppedFrames?: number;
    bufferedSeconds?: number;
    activeKeySystemName?: string | null;
    cdnProvider?: string;
    container: string;
    position: string;
    subtitle?: string | null;
    hasSsai: boolean;
    timestamp: number;
    version: string;
    videoQuality: PlayerQualityLevel;
    viewport: string;
    videoId?: string | null;
    frameRate?: number;
    audioOption?: string;
};

/**
 * Extract common diagnostics information from a screenConfig object (most often from `activeScreenConfig`)
 *
 * @param screenConfig - Passed in, e.g. screenConfigByIndex, @see ScreenConfig
 * @returns The diagnostics details
 */
export default function parseDiagnostics(
    screenConfig: ScreenConfig
): ParsedDiagnosticsType {
    const {playerTech, videoState, playbackData} = screenConfig;

    const {diagnostics, currentPlaybackHandler} = playerTech;
    const {captions, currentCaptionTrackIndex, videoQuality, audioTracks, currentAudioTrackIndex} = videoState;

    const cdnProvider = diagnostics?.cdnProvider;
    const container = diagnostics?.container;
    const playbackHandlerName = diagnostics?.playbackHandlerName;
    const hasSsai = !!diagnostics?.hasSsai;
    const channelCount = audioTracks?.[currentAudioTrackIndex]?.channelCount;
    const audioOption = AUDIO_CHANNEL_COUNT_TO_LABEL[channelCount ?? -1];

    const target =
        diagnostics && isDashOrHlsDiagnostics(diagnostics)
            ? {
                  videoWidth: diagnostics?.videoWidth,
                  videoHeight: diagnostics?.videoHeight,
                  videoBitrate: diagnostics?.videoBitrate,
                  videoCodec: diagnostics?.videoCodec,
                  audioBitrate: diagnostics?.audioBitrate,
                  audioCodec: diagnostics?.audioCodec,
                  audioTrackName: diagnostics?.audioTrackName,
                  totalFrames: diagnostics?.totalFrames,
                  droppedFrames: diagnostics?.droppedFrames,
                  bufferedSeconds: diagnostics?.bufferedSeconds,
                  frameRate: diagnostics?.frameRate,
              }
            : diagnostics && isNativeDiagnostics(diagnostics)
            ? {
                  videoWidth: diagnostics?.videoWidth,
                  videoHeight: diagnostics?.videoHeight,
                  videoCodec: diagnostics?.videoCodec,
                  totalFrames: diagnostics?.totalFrames,
                  droppedFrames: diagnostics?.droppedFrames,
                  bufferedSeconds: diagnostics?.bufferedSeconds,
                  videoBitrate: undefined,
                  audioBitrate: undefined,
                  audioCodec: diagnostics?.audioCodec,
                  audioTrackName: diagnostics?.audioTrackName,
                  frameRate: diagnostics?.frameRate,
              }
            : {
                  videoWidth: undefined,
                  videoHeight: undefined,
                  videoBitrate: undefined,
                  videoCodec: undefined,
                  audioBitrate: undefined,
                  audioCodec: undefined,
                  audioTrackName: undefined,
                  totalFrames: undefined,
                  droppedFrames: undefined,
                  bufferedSeconds: undefined,
                  frameRate: undefined,
              };

    return Object.assign(target, {
        activeKeySystemName: currentPlaybackHandler?.activeKeySystemName,
        cdnProvider,
        container: `${container} (${playbackHandlerName})`,
        position: currentPlaybackHandler?.videoElement
            ? `${currentPlaybackHandler.currentTime?.toFixed(2)}s`
            : '',
        subtitle: captions?.[currentCaptionTrackIndex]?.label,
        hasSsai,
        timestamp: Date.now(),
        version,
        videoQuality,
        viewport: `${window.innerWidth}×${window.innerHeight}`,
        videoId: playbackData?.id,
        audioOption,
    });
}
