import type {
    MediaPlayerClass,
    DashMetrics,
    BitrateInfo,
    FragmentLoadStartedEvent,
    DashAdapter,
} from 'dashjs';
import last from 'lodash/last';
// eslint-disable-next-line node/no-deprecated-api
import {parse} from 'url';

import NativeDiagnostics from '../native/diagnostics';
import {calculateFrameRate} from '../utils';

export default class DashDiagnostics extends NativeDiagnostics {
    #container = 'Dash';
    #playbackHandlerName = 'MSE/EME';
    fragmentType: string | undefined;

    override playbackHandlerType = 'dash';
    declare dashInstance: MediaPlayerClass;

    private metrics: DashMetrics;
    private adapter: DashAdapter;

    constructor(
        dashInstance: MediaPlayerClass,
        videoElement: HTMLVideoElement,
        src: string,
        cdnProvider: string,
        hasSsai: boolean
    ) {
        super(videoElement, src, cdnProvider, hasSsai);
        this.dashInstance = dashInstance;
        this.metrics = dashInstance.getDashMetrics();
        this.adapter = dashInstance.getDashAdapter();

        this.dashInstance.on(
            'fragmentLoadingStarted',
            this.#onFragmentLoadingStarted
        );
    }

    override destroy(): void {
        super.destroy();
        this.dashInstance.off(
            'fragmentLoadingStarted',
            this.#onFragmentLoadingStarted
        );
    }

    #onFragmentLoadingStarted = (data: FragmentLoadStartedEvent): void => {
        const {mediaType} = data.request ?? {};

        // Only report fragment types for video fragments
        if (mediaType !== 'video') {
            return;
        }

        const fragmentUrlPathname =
            parse(data?.request?.url ?? '').pathname || '';

        this.fragmentType = last(fragmentUrlPathname.split('.'));
    };

    override get videoHeight(): number {
        return (
            this.#getBitrateInfoForType('video')?.height ?? super.videoHeight
        );
    }

    override get videoWidth(): number {
        return this.#getBitrateInfoForType('video')?.width ?? super.videoWidth;
    }

    /**
     * Get the codec for the specified media type. Note that this does not return the codec for the currently playing
     * Representation - it is for the Representation that we have requested. For example, if we are viewing a 480p
     * source and then request 720p, this returns the codec for the 720p source even if we are still viewing the
     * 480p source.
     *
     * @param type - The media type.
     * @returns - Name of the codec
     */
    #getCodecForType = (type: 'video' | 'audio'): string | undefined => {
        if (!this.dashInstance || !this.metrics || !this.adapter) {
            return undefined;
        }

        // Get the index of the current Representation in the current Period
        // NOTE: For some reason, getCurrentRepresentationSwitch() does not work properly for audio
        // when switching between Periods (it'll still return the Representation from the previous Period).
        // I think this is a bug with Dash JS.
        const currentRepresentationSwitch =
            this.metrics.getCurrentRepresentationSwitch(type);

        // If we don't have a video or audio Representation, just return early
        if (!currentRepresentationSwitch) {
            return undefined;
        }

        const periodId = this.dashInstance
            .getActiveStream()
            ?.getStreamInfo()?.index;
        const representationId = currentRepresentationSwitch.to;

        if (typeof periodId === 'number' && representationId) {
            const representationIndex = this.adapter.getIndexForRepresentation(
                representationId,
                periodId
            );

            // In Dash JS, a Track essentially corresponds to an AdaptationSet
            // https://github.com/Dash-Industry-Forum/dash.js/pull/662
            // Fun fact: the Track has a 'codec' property but it doesn't return
            // the codec for the current Representation. Seems like it's for the first
            // Representation in the AdaptationSet.
            const currentTrack = this.dashInstance.getCurrentTrackFor(type);

            // Get all the Representations for our current AdaptationSet
            const representationsArray =
                this.adapter.getVoRepresentations(currentTrack);

            return representationsArray[representationIndex]?.codecs;
        }

        return undefined;
    };

    /**
     * Get bitrate from tracks by height.
     * The bitrate from tracks equals to bandwidth
     * @returns - Bitrate of video.
     * @param bitrateInfo  - bitrateInfo.
     */
    #convertBitrateUnit(
        bitrateInfo: BitrateInfo | null | undefined
    ): Pick<BitrateInfo, 'width' | 'height' | 'bitrate'> | undefined {
        if (!bitrateInfo) {
            return undefined;
        }

        const {width, height} = bitrateInfo;

        try {
            // for dashjs version 4.7.0-as2, `this.playbackHandler.getBitrateInfoFor('video)` returns bitrate value in KB
            // instead of byte in versions 4.7.0 and before
            // in order to ignore this difference caused by different version, we get bandwidth value from mediaInfo which has same value in mpd file.
            const bitrateList = this.dashInstance
                .getTracksFor('video')
                .flatMap(({bitrateList}) => bitrateList); // This gets all bitrates across adaptionSets

            const currentBitrate = bitrateList.find(
                (item) => item.height === bitrateInfo.height
            );

            return {
                width,
                height,
                bitrate: currentBitrate?.bandwidth ?? 0,
            };
        } catch {
            return undefined;
        }
    }

    /**
     * Get the bitrate info for the specified media type.
     *
     * @param type - The media type.
     * @returns - Bitrate of the requested media type.
     */
    #getBitrateInfoForType = (
        type: 'video' | 'audio'
    ): BitrateInfo | undefined => {
        if (!this.dashInstance) {
            return undefined;
        }

        try {
            // This will crash if we're trying to read bitrate while the dash instance is working through an error
            const currentQualityIndex = this.dashInstance.getQualityFor(type);
            const bitrateInfoList =
                this.dashInstance.getBitrateInfoListFor(type);

            return bitrateInfoList?.[currentQualityIndex];
        } catch (e) {
            return undefined;
        }
    };

    override get videoCodec(): string | undefined {
        return this.#getCodecForType('video');
    }

    override get audioCodec(): string | undefined {
        return this.#getCodecForType('audio');
    }

    // TODO: After migrating to dashjs 5.0.0, we could get frame rate from getVoRepresentations instead of calculating it by calculateFrameRateFromString,
    // like what we did in #getBitrateInfoForType.
    override get frameRate(): number | undefined {
        const streamInfo = this.dashInstance.getActiveStream()?.getStreamInfo();
        const periodId = streamInfo?.index;
        const currentRepresentationIndex = this.metrics.getCurrentRepresentationSwitch('video').to;

        if (periodId !== undefined && streamInfo) {
            const adaptation = this.adapter.getAdaptationForType(periodId, 'video', streamInfo);
            const currentRep = adaptation.Representation_asArray.find((rep) => rep.id === currentRepresentationIndex)
            const frameRate = currentRep?.frameRate ? calculateFrameRate(String(currentRep.frameRate)) : undefined

            return frameRate;
        }

        return undefined;
    }

    override get audioTrackName(): string | undefined {
        if (!this.dashInstance) {
            return undefined;
        }

        try {
            const currentTrack = this.dashInstance.getCurrentTrackFor('audio');

            return currentTrack?.labels[0]?.text;
        } catch (e) {
            return '';
        }
    }

    get audioBitrate(): number {
        return this.#getBitrateInfoForType('audio')?.bitrate ?? 0;
    }

    get videoBitrate(): number {
        const bitrateInfo = this.#getBitrateInfoForType('video');
        const convertedBitrateInfo = this.#convertBitrateUnit(bitrateInfo);

        return convertedBitrateInfo?.bitrate ?? 0;
    }

    override get bufferedSeconds(): number | undefined {
        if (!this.dashInstance) {
            return undefined;
        }

        const bufferLength = this.dashInstance.getBufferLength('video');

        return Number.isFinite(bufferLength) ? bufferLength : undefined;
    }

    override get playbackHandlerName(): string {
        return this.#playbackHandlerName;
    }

    override get container(): string {
        return this.#container;
    }

    override get playerVersion(): string {
        return `dashjs ${window.dashjs.Version}`;
    }
}
