import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

import {getCurrencySymbol} from '../utils/helpers';

import MessageComponent from '../components/message';

export default class ManageSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            modal: false
        };
    }

    handleSubmit(e) {
        e.preventDefault();

        const orderId = this.props.activeOrder.id;

        this.props.stopSubscription({orderId});
    }

    getSubscriptionCancelledMessage(order) {
        const endDateTime = moment(order.endDate)
            .calendar(null, {
                sameDay: '[today at] h:mma',
                nextDay: '[tomorrow at] h:mma',
                nextWeek: '[Next] dddd [at] h:mma',
                sameElse: 'Do MMM YYYY [at] h:mma'});

        return `Your subscription has been cancelled. You can still use Watch ${this.props.sportDetails.label} until ${endDateTime}.`;
    }

    renderNoActiveOrderLine() {
        let noActiveOrderLine = null;

        if (this.props.activeOrderError ||
            (this.props.activeOrder && this.props.activeOrder.autorenewStatus === 'STOPPED')) {
            if (moment() < this.props.activeOrder.endDate) {
                noActiveOrderLine = (
                    <span className="fiso-hawk-manage-subscription__detail-line">
                        {this.getSubscriptionCancelledMessage(this.props.activeOrder)}
                    </span>
                );
            } else {
                noActiveOrderLine = (
                    <span className="fiso-hawk-manage-subscription__detail-line">
                        You have no active subscription, but you can <a
                            href={this.props.resubscribeUrl}
                            className="fiso-hawk-link">subscribe now</a>!
                    </span>
                );
            }
        }

        return noActiveOrderLine;
    }

    renderActiveOrderLine() {
        if (isEmpty(this.props.activeOrder) || this.props.activeOrder.autorenewStatus === 'STOPPED') {
            return;
        }

        const {productName, currency, price} = this.props.activeOrder;

        return `${productName},
            ${getCurrencySymbol(currency)}${price.toFixed(2)}
            ${currency}`;
    }

    renderCustomerSupportLine() {
        if (isEmpty(this.props.supportUrl) || isEmpty(this.props.activeOrder) || this.props.activeOrder.autorenewStatus === 'STOPPED') {
            return;
        }

        const strings = [
            'Please contact ',
            <a
                key="customer-support"
                href={this.props.supportUrl}
                className="fiso-hawk-link">customer support</a>,
            ' to change your pass'
        ];

        return strings;
    }

    renderNextBillDateLine() {
        if (isEmpty(this.props.activeOrder) || this.props.activeOrder.autorenewStatus === 'STOPPED') {
            return 'N/A';
        } else if (this.props.activeOrder.autorenewStatus === 'NOT_ELIGIBLE') {
            const endDate = this.props.productEndDate ? ` on the ${moment(this.props.productEndDate).format('Do \of MMMM, YYYY')} AEST` : '';

            return `This pass does not automatically renew, you will not be billed once your pass expires ${endDate}`;
        }

        // Don't have a "Next Billing Date" per se, but I have an "endDate" for the order period I guess. It'll do?
        const nextBillDate = moment(this.props.activeOrder.endDate)
            .calendar(null, {
                sameDay: '[Today at] h:mma ([GMT] Z)',
                nextDay: '[Tomorrow at] h:mma ([GMT] Z)',
                nextWeek: '[Next] dddd [at] h:mma ([GMT] Z)',
                sameElse: 'Do MMM YYYY [at] h:mma ([GMT] Z)'});

        return nextBillDate;
    }

    renderLoadingSpinner() {
        if (!this.props.isLoading) {
            return null;
        }

        return <LoadingSpinner />;
    }

    renderStopSubSuccessMessage() {
        if (isEmpty(this.props.activeOrder)) {
            return;
        }

        const order = this.props.stopSubOrder || this.props.activeOrder;
        let message;

        if (order.autorenewStatus === 'STOPPED') {
            message = (
                <MessageComponent
                    type="good"
                    value={this.getSubscriptionCancelledMessage(order)} />
            );
        } else {
            message = (
                <MessageComponent
                    type="fail"
                    value="Your subscription could not be cancelled at this time. Please try again later." />
            );
        }

        return message;
    }

    renderModal() {
        if (!this.state.modal || isEmpty(this.props.activeOrder)) {
            return null;
        }

        const modalClasses = classNames(
            'fiso-hawk-modal__main',
            {'fiso-hawk-modal__main--is-loading': this.props.isLoading}
        );

        const closeButton = (
            <button
                type="button"
                className={classNames(
                    'fiso-hawk-button--close',
                    `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`)}
                onClick={this.toggleModal}
                aria-label="Close">
                <Fsvg name="cross" />
            </button>
        );

        const comeBackLine = (
            <p className="fiso-hawk-modal__copy">
                You can <a href={this.props.resubscribeUrl} className="fiso-hawk-link">come back anytime</a>
            </p>
        );

        let modal = (
            /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
            <aside className="fiso-hawk-modal__overlay">
                <form className="fiso-hawk-modal__box" onSubmit={this.handleSubmit} method="POST">
                    {closeButton}
                    <h1 className="fiso-hawk-modal__heading-1">We’re sorry to see you go, are you sure?</h1>
                    {this.renderLoadingSpinner()}
                    <div className={modalClasses}>
                        <p className="fiso-hawk-modal__copy">
                            <button
                                type="button"
                                className={classNames(
                                    'fiso-hawk-button',
                                    'fiso-hawk-button--ghost',
                                    'fiso-hawk-button--full-width',
                                    'fiso-hawk-button--chevron-left',
                                    `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`)}
                                onClick={this.toggleModal}>
                                Continue my subscription
                                <Fsvg name="back-1" />
                            </button>
                        </p>
                        <p className="fiso-hawk-modal__copy">
                            <button
                                type="submit"
                                className={classNames(
                                    'fiso-hawk-button',
                                    'fiso-hawk-button--ghost',
                                    'fiso-hawk-button--full-width',
                                    'fiso-hawk-button--chevron-right',
                                    `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`)}>
                                Stop my subscription
                                <Fsvg name="next-1" />
                            </button>
                        </p>
                        {comeBackLine}
                    </div>
                </form>
            </aside>
        );

        if ((this.props.activeOrder && this.props.activeOrder.autorenewStatus === 'STOPPED') ||
            (this.props.stopSubOrder && this.props.stopSubOrder.autorenewStatus === 'STOPPED')) {
            modal = (
                <aside className="fiso-hawk-modal__overlay">
                    <div className="fiso-hawk-modal__box">
                        {closeButton}
                        <h1 className="fiso-hawk-modal__heading-1">Your Subscription</h1>
                        {this.renderStopSubSuccessMessage()}
                        {comeBackLine}
                    </div>
                </aside>
            );
        }

        return modal;
    }

    toggleModal(e) {
        e.preventDefault();

        this.setState({modal: !this.state.modal});
    }

    renderBillHistory() {
        const {transactions} = this.props;

        if (isEmpty(transactions)) {
            return;
        }

        return transactions.map((payment, index) => {
            const transactionDate = moment(payment.registered)
                .calendar(null, {
                    sameDay: '[Today at] h:mma ([GMT] Z)',
                    lastDay: '[Yesterday at] h:mma ([GMT] Z)',
                    lastWeek: '[Last] dddd [at] h:mma ([GMT] Z)',
                    sameElse: 'Do MMM YYYY [at] h:mma ([GMT] Z)'
                });

            // We can't do this right now, but keep it here for when we can!
            // const cardIconClasses = classNames(
            //     'fiso-hawk-manage-subscription__card-icon',
            //     {'fiso-hawk-manage-subscription__card-icon--paypal': payment.paymentType === 'paypal'}
            // );

            const currencySymbol = getCurrencySymbol(payment.currency);

            return (
                <dl
                    key={payment.id}
                    className="
                        fiso-hawk-manage-subscription__list
                        fiso-hawk-manage-subscription__list--payments">
                    <dt className="fiso-hawk-manage-subscription__list-heading">{index === 0 && 'Billing History:'}</dt>
                    <dd className="fiso-hawk-manage-subscription__list-detail">
                        <span className="fiso-hawk-manage-subscription__billHistory">
                            <span className="fiso-hawk-manage-subscription__detail-line">
                                <b>{currencySymbol}{payment.price.toFixed(2)} {payment.currency}</b>
                            </span>
                            <span className="fiso-hawk-manage-subscription__detail-line">
                                {transactionDate}
                            </span>
                            <span className="fiso-hawk-manage-subscription__detail-line">
                                Type: {payment.type}
                            </span>
                            <span className="fiso-hawk-manage-subscription__detail-line">
                                ID: {payment.id}
                            </span>
                            {/* <span
                                className="
                                    fiso-hawk-manage-subscription__detail-line
                                    fiso-hawk-manage-subscription__detail-line--payment-type">
                                <span className={cardIconClasses}>
                                    <Fsvg name={payment.paymentType} ariaLabel="Visa" />
                                </span>
                                {payment.paymentType === 'paypal' ? 'PayPal' : `************${payment.cardNumbers}`}
                            </span> */}
                        </span>
                    </dd>
                </dl>
            );
        });
    }

    renderCancelButton() {
        if (
            isEmpty(this.props.activeOrder) ||
            this.props.activeOrder.autorenewStatus === 'STOPPED' ||
            this.props.activeOrder.autorenewStatus === 'NOT_ELIGIBLE'
        ) {
            return null;
        }

        const cancelButton = (
            <div className="fiso-hawk-manage-subscription__col">
                <button
                    type="button"
                    className="
                        fiso-hawk-button
                        fiso-hawk-button--manage
                        fiso-hawk-button--full-width
                        fiso-hawk-button--ghost"
                    onClick={this.toggleModal}>
                    Cancel Subscription
                </button>
            </div>
        );

        return cancelButton;
    }

    render() {
        return (
            <section className="fsui-normalise fiso-hawk-manage-subscription">
                <div className="fiso-hawk-manage-subscription__container">
                    <h1 className="fiso-hawk-manage-subscription__heading-1">Manage Your Subscription</h1>
                    <dl className="fiso-hawk-manage-subscription__list">
                        <dt className="fiso-hawk-manage-subscription__list-heading">Pass:</dt>
                        <dd className="fiso-hawk-manage-subscription__list-detail">
                            {this.renderNoActiveOrderLine()}
                            <span className="fiso-hawk-manage-subscription__detail-line">
                                {this.renderActiveOrderLine()}
                            </span>
                            <span className="fiso-hawk-manage-subscription__detail-line">
                                {this.renderCustomerSupportLine()}
                            </span>
                        </dd>
                        <dt className="fiso-hawk-manage-subscription__list-heading">Next Billing Date:</dt>
                        <dd className="fiso-hawk-manage-subscription__list-detail">
                            {this.renderNextBillDateLine()}
                        </dd>
                        <dt className="fiso-hawk-manage-subscription__list-heading">Notes:</dt>
                        <dd className="fiso-hawk-manage-subscription__list-detail">
                            For Weekly, Monthly and 12-Month passes, your subscription will automatically renew,
                            unless you cancel your subscription at least 48 hours prior to your renewal date
                        </dd>
                    </dl>
                    <div className="fiso-hawk-manage-subscription__row">
                        <div className="fiso-hawk-manage-subscription__col">
                            <a
                                href={this.props.profilePageLink}
                                title="Cancel and go to profile page"
                                className={classNames(
                                    'fiso-hawk-button',
                                    'fiso-hawk-button--manage',
                                    'fiso-hawk-button--full-width',
                                    'fiso-hawk-button--ghost',
                                    'fiso-hawk-button--chevron-left',
                                    `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`)}>
                                Go Back
                                <Fsvg name="back-1" />
                            </a>
                        </div>
                        {this.renderCancelButton()}
                    </div>
                    {this.renderBillHistory()}
                    {this.renderModal()}
                </div>
            </section>
        );
    }
}

ManageSubscription.defaultProps = {
    activeOrder: {},
    transactions: [],
    productEndDate: 0
};

ManageSubscription.propTypes = {
    activeOrder: propTypes.shape({
        autorenewStatus: propTypes.string,
        currency: propTypes.string,
        id: propTypes.number,
        price: propTypes.number,
        productName: propTypes.string,
        endDate: propTypes.number
    }),
    activeOrderError: propTypes.bool,
    isLoading: propTypes.bool,
    productEndDate: propTypes.number,
    profilePageLink: propTypes.string,
    resubscribeUrl: propTypes.string,
    stopSubscription: propTypes.func,
    stopSubOrder: propTypes.shape({
        autorenewStatus: propTypes.string,
        endDate: propTypes.number
    }),
    supportUrl: propTypes.string,
    transactions: propTypes.arrayOf(propTypes.shape({
        transactionReference: propTypes.string,
        price: propTypes.number,
        type: propTypes.string
    })),
    sportDetails: propTypes.shape({
        route: propTypes.string,
        label: propTypes.string
    })
};
