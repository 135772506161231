import kebabCase from 'lodash/kebabCase';

import getAflSeriesNames from './sport-series-names/afl';
import getBaseballSeriesNames from './sport-series-names/baseball';
import getBasketballSeriesNames from './sport-series-names/basketball';
import getCricketSeriesNames from './sport-series-names/cricket';
import getCyclingSeriesNames from './sport-series-names/cycling';
import getFootballSeriesNames from './sport-series-names/football';
import getGolfSeriesNames from './sport-series-names/golf';
import getGridironSeriesNames from './sport-series-names/gridiron';
import getIcehockeySeriesNames from './sport-series-names/icehockey';
import getLeagueSeriesNames from './sport-series-names/league';
import getMotorSeriesNames from './sport-series-names/motor';
import getNetballSeriesNames from './sport-series-names/netball';
import getRugbySeriesNames from './sport-series-names/rugby';
import getTennisSeriesNames from './sport-series-names/tennis';

/**
 * By sport name, find an object which contains
 * - The name used by the stats API
 * - The display label, e.g. for use on the website
 * - The route, e.g. the sport name token to use for URL
 * - A function which can be used to get information for the sport's series
 *
 * Returns an object with ONLY the series function when invalid sport provided
 * This is so you can safely deep access the series getter function without too much malarky
 *
 * @param  {String} sport Sport name: this can be the API version, or the "nice" version (e.g. league & NRL are both ok)
 *
 * @return {Object}       Object {apiName, label, route, getSeries}, where `getSeries` is a func accepting a series id or route
 */
export function getSportNames(sport) {
    switch (kebabCase(sport)) {
        case 'afl':
            return {
                apiName:   'afl',
                label:     'AFL',
                route:     'afl',
                getSeries: getAflSeriesNames
            };

        case 'baseball':
            return {
                apiName:   'baseball',
                label:     'Baseball',
                route:     'baseball',
                getSeries: getBaseballSeriesNames
            };

        case 'basketball':
            return {
                apiName:   'basketball',
                label:     'Basketball',
                route:     'basketball',
                getSeries: getBasketballSeriesNames
            };

        case 'cricket':
            return {
                apiName:   'cricket',
                label:     'Cricket',
                route:     'cricket',
                getSeries: getCricketSeriesNames
            };

        case 'cycling':
            return {
                apiName:   'cycling',
                label:     'Cycling',
                route:     'cycling',
                getSeries: getCyclingSeriesNames
            };

        case 'football':
            return {
                apiName:   'football',
                label:     'Football',
                route:     'football',
                getSeries: getFootballSeriesNames
            };

        case 'golf':
            return {
                apiName:   'golf',
                label:     'Golf',
                route:     'golf',
                getSeries: getGolfSeriesNames
            };

        case 'nfl': // label
        case 'gridiron': // sport
            return {
                apiName:   'gridiron',
                label:     'Gridiron',
                route:     'gridiron',
                getSeries: getGridironSeriesNames
            };

        case 'ice-hockey': // label
        case 'icehockey': // sport
            return {
                apiName:   'icehockey',
                label:     'Ice Hockey',
                route:     'ice-hockey',
                getSeries: getIcehockeySeriesNames
            };

        case 'nrl': // label
        case 'league': // sport
            return {
                apiName:   'league',
                label:     'NRL',
                route:     'nrl',
                getSeries: getLeagueSeriesNames
            };

        case 'motorsport': // label
        case 'motor': // sport
            return {
                apiName:   'motor',
                label:     'Motorsport',
                route:     'motorsport',
                getSeries: getMotorSeriesNames
            };

        case 'netball':
            return {
                apiName:   'netball',
                label:     'Netball',
                route:     'netball',
                getSeries: getNetballSeriesNames
            };

        case 'rugby':
            return {
                apiName:   'rugby',
                label:     'Rugby',
                route:     'rugby',
                getSeries: getRugbySeriesNames
            };

        case 'tennis':
            return {
                apiName:   'tennis',
                label:     'Tennis',
                route:     'tennis',
                getSeries: getTennisSeriesNames
            };

        default:
            console.info('FISO-commons: Unrecognised sport', sport); // eslint-disable-line no-console

            return {getSeries: () => ({})};
    }
}

/**
 * Similar to getSportNames except also accepts a series ID
 * Return object will contain a series object instead of a getter function
 *
 * @param  {String} sport  Sport API name or label
 * @param  {String} series Series ID or route
 *
 * @return {Object}        Object with matched sport and series
 */
export function getSportSeriesNames(sport, series) {
    const sportNames = getSportNames(sport);
    const seriesNames = sportNames.getSeries(series);

    delete sportNames.getSeries;

    sportNames.series = seriesNames;

    return sportNames;
}
