import React from 'react';
import {createRoot} from 'react-dom/client';
import bacon from 'baconjs';
import get from 'lodash/get';
import moment from 'moment';

import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {displayDateFormat} from '@fsa/fs-commons/lib/utils/date';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import commingUpStreamByChannelAndSize from '../streams/tv-guide/comming-up';
import nowPlayingStreamByChannel from '../streams/tv-guide/now-playing';
import programmesStreamByChannelAndDate from '../streams/tv-guide/programmes';
import TvGuideComponent from '../components/tv-guide/main';

const EXPIRY_SECONDS = 60; // 1 mins

function TvGuide(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        channel: this.settings.channel,
        size: this.settings.size,
        sport: this.settings.sport || 'afl'
    };
}

TvGuide.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

TvGuide.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'HAWK: TvGuide',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

TvGuide.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <TvGuideComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

TvGuide.prototype.processTvGuideData = function (data) {
    const commingUp = get(data, 'commingUp["channel-programme"][0]', {});
    const nowPlaying = get(data, 'nowPlaying["channel-programme"][0]', {});

    return {
        commingUp: {
            id: commingUp.id,
            programmeTitle: commingUp.programmeTitle,
            title: commingUp.title,
            live: commingUp.live,
            replay: (!commingUp.live && commingUp.statsFixtureId !== null),
            startTime: commingUp.startTime,
            endTime: commingUp.endTime
        },
        now: {
            id: nowPlaying.id,
            programmeTitle: nowPlaying.programmeTitle,
            title: nowPlaying.title,
            live: nowPlaying.live,
            replay: (!nowPlaying.live && nowPlaying.statsFixtureId !== null),
            startTime: nowPlaying.startTime,
            endTime: nowPlaying.endTime
        }
    };
};

TvGuide.prototype.getTvGuideStreams = function () {
    const commingUpStream = commingUpStreamByChannelAndSize({
        channel: this.config.channel,
        size:    this.config.size
    });

    const nowPlayingStream = nowPlayingStreamByChannel({
        channel: this.config.channel
    });

    return bacon.combineWith(
        [commingUpStream, nowPlayingStream],
        (commingUp, nowPlaying) => ({commingUp, nowPlaying})
    ).map(this.processTvGuideData.bind(this));
};

TvGuide.prototype.processTvGuideProgrammesData = function (data) {
    const programmes = get(data, 'programmes["channel-programme"]', []).map((programme) => ({
        id: programme.id,
        programmeTitle: programme.programmeTitle,
        title: programme.title,
        live: programme.live,
        replay: (!programme.live && programme.statsFixtureId !== null),
        startTime: programme.startTime,
        endTime: programme.endTime
    }));

    return {programmes};
};

TvGuide.prototype.getTvGuideProgrammesStreams = function () {
    const programmesStream = programmesStreamByChannelAndDate({
        channel:   this.config.channel,
        startDate: displayDateFormat(moment(), 'YYYY-MM-DD'),
        endDate:   displayDateFormat(moment().add(2, 'days'), 'YYYY-MM-DD')
    });

    return bacon.combineWith(
        [programmesStream],
        (programmes) => ({programmes})
    ).map(this.processTvGuideProgrammesData.bind(this));
};

TvGuide.prototype.getData = function (initialData) {
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    const tvGuideOpenBus = new bacon.Bus();
    const tvGuideTabsBus = new bacon.Bus();

    const tvGuideProgrammes = tvGuideOpenBus
        .take(1)
        .flatMapLatest(() =>
            this.getTvGuideProgrammesStreams()
        )
        .startWith([]);

    const isOpen = bacon.update(
        false,
        [tvGuideOpenBus], (tvGuideWasOpen) => !tvGuideWasOpen
    );

    const isProgrammesLoading = bacon.update(
        true,
        [tvGuideProgrammes], false
    );

    const selectedTabIndex = bacon.update(
        0,
        [tvGuideTabsBus], (prevIndex) => (prevIndex) ? 0 : 1
    );
    const tvGuideProgrammesFilteredData = tvGuideProgrammes
        .combine(selectedTabIndex, (programmesResponse, tabIndex) => {
            const dateToFilter = (tabIndex === 0) ? today : tomorrow;

            if (programmesResponse.programmes) {
                return programmesResponse.programmes.filter((item) => moment(item.startTime).isSame(dateToFilter, 'day'));
            }
        });

    const tvGuideStreams = initialLaterOr(
        initialData && initialData.tvGuideNowUpcoming,
        this.getTvGuideStreams()
    );

    let tomorrowWasLastClick = false;

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            sportDetails: getSportDetails(this.config.sport),
            commingUp: tvGuideStreams.map('.commingUp'),
            now: tvGuideStreams.map('.now'),
            programmes: tvGuideProgrammesFilteredData,
            selectedTabIndex,
            isOpen,
            isProgrammesLoading,
            tabs: [
                `Today (${displayDateFormat(today, 'DD/MM')})`,
                `Tomorrow (${displayDateFormat(tomorrow, 'DD/MM')})`
            ],
            onOpenCloseClick: (e) => {
                e.preventDefault();
                tvGuideOpenBus.push();
            },
            onTabsClick: (isTomorrow) => {
                if ((tomorrowWasLastClick && !isTomorrow) || (!tomorrowWasLastClick && isTomorrow)) {
                    tvGuideTabsBus.push();
                }

                // @TODO, This shouldn't bleed to a scoped var holding state outside of a stream.
                tomorrowWasLastClick = isTomorrow;
            }
        }),
        iso: bacon.combineTemplate({
            tvGuideNowUpcoming: tvGuideStreams
        })
    });
};

TvGuide.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <TvGuideComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <TvGuideComponent {...data.view} />,
            'hawkwidgets-tv-guide',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

TvGuide.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

TvGuide.prototype.pageBoot = function () {
    pageBoot(TvGuide, 'hawkwidgets-tv-guide');
};

pageBoot(TvGuide, 'hawkwidgets-tv-guide');

export default function (element, settings) {
    return new TvGuide(element, settings);
}
