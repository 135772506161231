import {
    type PlayerState,
    ANALYTICS_EVENT_NAME,
    SCRUBBER_TYPE,
} from '@fsa-streamotion/player-state';

import noop from 'lodash/noop';
import {observer} from 'mobx-react-lite';
import React, {useRef, useEffect} from 'react';
import styled, {type DefaultTheme, ThemeProvider} from 'styled-components';

import {Section} from '../../../common/normalized-styled-components';
import {black} from '../../../common/palette';
import {DEFAULT_THEME, SEEK_ADJUSTMENT_S} from '../../utils/constants';
import {PlayerContext} from '../context';
import LayoutComposer from './layout-composer';
import getKeyDownHandler, {spaceKey} from './utils/keydown-handler';

const PlayerBackingCard = styled(Section)`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: ${black};
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export type Props = {
    playerState: PlayerState;
};

function StreamotionWebPlayerComponent({
    playerState,
}: Props): React.ReactElement {
    const fullscreenElementRef = useRef(null);
    const {generalConfig, activeScreenConfig} = playerState;
    const {
        videoState: {isInAdBreak},
        playbackData,
    } = activeScreenConfig ?? {videoState: {}};
    const {theme = DEFAULT_THEME, onPlayerInteractionByType} =
        generalConfig ?? {};

    const shouldLockScrubberToEdge =
        playbackData?.scrubBarType &&
        SCRUBBER_TYPE[playbackData?.scrubBarType] === SCRUBBER_TYPE.none;

    useEffect(function propagateFullscreenElementToState() {
        playerState.setFullscreenElement(fullscreenElementRef.current);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const mediaKeysAndActions = {
        /* media keys */
        MediaPlayPause: () =>
            playerState?.activeScreenConfig?.actions.togglePlayPause(),
        MediaStop: () => playerState?.globalActions?.exitPlayer(),
        MediaTrackPrevious: (e: React.KeyboardEvent | Event) => {
            playerState?.activeScreenConfig?.actions.setCurrentTime(
                playerState?.activeScreenConfig.videoState.seekingTime -
                    SEEK_ADJUSTMENT_S
            );
            onPlayerInteractionByType?.(
                ANALYTICS_EVENT_NAME.SELECT_OPTION,
                `-${SEEK_ADJUSTMENT_S}`
            );
            playerState?.handleInteraction(e as Event);
        },
        MediaTrackNext: (e: React.KeyboardEvent | Event) => {
            playerState?.activeScreenConfig?.actions.setCurrentTime(
                playerState?.activeScreenConfig?.videoState.seekingTime +
                    SEEK_ADJUSTMENT_S
            );
            onPlayerInteractionByType?.(
                ANALYTICS_EVENT_NAME.SELECT_OPTION,
                `+${SEEK_ADJUSTMENT_S}`
            );
            playerState?.handleInteraction(e as Event);
        },

        /* Fallback keys */
        Escape: () => playerState?.globalActions?.exitPlayer(),
        ArrowRight:
            shouldLockScrubberToEdge || isInAdBreak
                ? noop
                : (e: React.KeyboardEvent | Event) => {
                      playerState?.activeScreenConfig?.actions.setCurrentTime(
                          playerState?.activeScreenConfig.videoState
                              .seekingTime + SEEK_ADJUSTMENT_S
                      );
                      onPlayerInteractionByType?.(
                          ANALYTICS_EVENT_NAME.SELECT_OPTION,
                          `+${SEEK_ADJUSTMENT_S}`
                      );
                      playerState?.handleInteraction(e as Event);
                  },
        ArrowLeft:
            shouldLockScrubberToEdge || isInAdBreak
                ? noop
                : (e: React.KeyboardEvent | Event) => {
                      playerState?.activeScreenConfig?.actions.setCurrentTime(
                          playerState.activeScreenConfig.videoState
                              .seekingTime - SEEK_ADJUSTMENT_S
                      );
                      onPlayerInteractionByType?.(
                          ANALYTICS_EVENT_NAME.SELECT_OPTION,
                          `-${SEEK_ADJUSTMENT_S}`
                      );
                      playerState.handleInteraction(e as Event);
                  },
        ArrowUp: () => playerState?.activeScreenConfig?.actions.stepVolume(0.1),
        ArrowDown: () =>
            playerState?.activeScreenConfig?.actions.stepVolume(-0.1),
        [spaceKey]: shouldLockScrubberToEdge
            ? noop
            : () => playerState?.activeScreenConfig?.actions.togglePlayPause(),
        Tab: Object.assign(
            (e: React.KeyboardEvent | Event) =>
                playerState?.handleInteraction(e as Event),
            {skipPreventDefault: true}
        ),
    };

    const mediaKeysAndActionsRef = useRef<{
        [key: string]: (event: React.KeyboardEvent | Event) => void;
    }>(mediaKeysAndActions);

    mediaKeysAndActionsRef.current = mediaKeysAndActions;

    const lowerTrayVisibility = !!playerState?.globalState?.isLowerTrayVisible;
    const upperTrayVisibility = !!playerState?.globalState?.isUpperTrayVisible;
    const trayVisibility = lowerTrayVisibility || upperTrayVisibility;

    // Bind event listener to document to catch any unfocused input events
    useEffect(
        function bindInputEventListenerToDocument() {
            const inputHandler = getKeyDownHandler(mediaKeysAndActionsRef);

            if (!trayVisibility) {
                document.addEventListener('keydown', inputHandler);
            }

            return () => document.removeEventListener('keydown', inputHandler);
        },
        [trayVisibility]
    );

    // Bind on click event listener to document to catch any bubbling events
    useEffect(function bindOnClickEventListenerToDocument() {
        // const inputHandler = getKeyDownHandler(mediaKeysAndActionsRef);
        const clickHandler = (event: Event): void => {
            // close trays
            playerState?.globalActions?.setTrayVisibility({
                upper: false,
                lower: false,
            });
            event.preventDefault();
            event.stopPropagation();
        };

        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PlayerContext.Provider value={playerState}>
            <ThemeProvider theme={theme as DefaultTheme}>
                <PlayerBackingCard
                    className="sm-web-player"
                    ref={fullscreenElementRef}
                >
                    <LayoutComposer />
                </PlayerBackingCard>
            </ThemeProvider>
        </PlayerContext.Provider>
    );
}

StreamotionWebPlayerComponent.displayName = 'StreamotionWebPlayerComponent';

export default observer<Props>(StreamotionWebPlayerComponent);
