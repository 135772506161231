import bacon from 'baconjs';
import superagent from 'superagent';

import {streamFromApiWithRetry, getVimondHeaders} from '../../utils/api';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function getVoucher({
    sport = 'afl',
    voucherCode,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    if (!voucherCode) {
        return bacon.later(0, null);
    }

    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/voucher/${voucherCode}`;
    const getVoucher = () => superagent
        .get(url)
        .set(getVimondHeaders({sport, version: 3}));

    return streamFromApiWithRetry({apiCall: getVoucher}).map('.body');
}
