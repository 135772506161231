import React from 'react';
import propTypes from 'prop-types';

import Accordion from './accordion';

const Faq = ({
    faqData = {},
    sportDetails = {
        route: 'afl'
    }
}) => (
    <div className="fiso-hawk-faq">
        <div className="fiso-hawk-faq__content">
            <h2 className="fiso-hawk-faq__title">
                Not sure of something?
            </h2>
            <Accordion data={faqData[sportDetails.route]} />
        </div>
    </div>
);

Faq.propTypes = {
    faqData: propTypes.object,
    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};

export default Faq;
