import React from 'react';
import propTypes from 'prop-types';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

export default class GetApp extends React.Component {
    renderLink({description, url, fsvg} = {}, index) {
        if (!url) {
            return null;
        }

        return (
            <li className="fiso-hawk-get-apps__link-wrapper" key={index}>
                <a className="fiso-hawk-get-apps__link" href={url} title={description}>
                    <Fsvg
                        name={fsvg}
                        ariaLabel={description} />
                </a>
            </li>
        );
    }

    render() {
        const hasAppLinks = this.props.links
            .some(({url}) => !!url);

        return hasAppLinks && (
            <aside className="fsui-normalise fiso-hawk-get-apps">
                <header className="fiso-hawk-get-apps__caption">
                    <h1 className="fiso-hawk-get-apps__title">Get The App</h1>
                    <p className="fiso-hawk-get-apps__description">
                        The companion Watch {this.props.sportDetails.label} apps are available on iOS and Android mobile and tablets.
                        Also available with Airplay and Chromecast.
                    </p>
                </header>
                <ul className="fiso-hawk-get-apps__links">
                    {this.props.links.map(this.renderLink)}
                </ul>
            </aside>
        );
    }
}

GetApp.defaultProps = {
    links: []
};

GetApp.propTypes = {
    links: propTypes.arrayOf(
        propTypes.shape({
            description: propTypes.string,
            url: propTypes.string,
            fsvg: propTypes.string
        })
    ),
    sportDetails: propTypes.shape({
        label: propTypes.string
    })
};
