import {PLAYER_TECH_VIDEO_QUALITY_CATEGORY} from '@fsa-streamotion/player-state';

import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import gibson from '../../../../../../common/font-helper';
import {athensGray} from '../../../../../../common/palette';
import BA03QualityButton from '../../../../../../component-library/atoms/ba/03-quality-button';
import {usePlayerContext} from '../../../../context';
import {Selector, ButtonAndTextWrapper} from './quality-options';

const ButtonInfoText = styled.p`
    margin-left: 7px;
    color: ${athensGray};
    font: ${gibson.medium({size: 14, lineHeight: 1.79})};
`;

const VIDEO_QUALITY_TYPE = {
    HIGH_DEFINITION: 'HD',
    STANDARD_DEFINITION: 'SD',
};

const VIDEO_QUALITY_LABEL = 'AUTO';

const VIDEO_QUALITY = {
    [VIDEO_QUALITY_TYPE.STANDARD_DEFINITION]: {
        name: PLAYER_TECH_VIDEO_QUALITY_CATEGORY.LOW,
        label: VIDEO_QUALITY_LABEL,
        description: 'Up to SD',
    },
    [VIDEO_QUALITY_TYPE.HIGH_DEFINITION]: {
        name: PLAYER_TECH_VIDEO_QUALITY_CATEGORY.AUTO,
        label: VIDEO_QUALITY_LABEL,
        description: 'Up to HD',
    },
};

const VideoQualitySelector = (): React.ReactElement => {
    const {globalActions, generalConfig, activeScreenConfig} =
        usePlayerContext() ?? {};
    const {setVideoQualityByCategory} = globalActions ?? {};
    const {canUserViewHd} = generalConfig ?? {};

    const canUserChangeActiveScreenQuality =
        activeScreenConfig?.videoState?.bitrates?.length &&
        activeScreenConfig.videoState.bitrates.length > 0;

    const selectedQuality = activeScreenConfig?.videoState?.videoQuality;

    const SD = VIDEO_QUALITY[VIDEO_QUALITY_TYPE.STANDARD_DEFINITION];
    const HD = VIDEO_QUALITY[VIDEO_QUALITY_TYPE.HIGH_DEFINITION];
    const shouldHideSdOption =
        canUserViewHd && !canUserChangeActiveScreenQuality;
    // HD button is effectively just "Auto",
    // so it works when we can't configure quality
    const isHdDisabled = !canUserViewHd;

    const [userSelectedQuality, setUserSelectedQuality] =
        useState(selectedQuality);

    useEffect(
        function keepLocalQualityInSyncWithPlayerTech() {
            if (selectedQuality !== userSelectedQuality) {
                setUserSelectedQuality(selectedQuality);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedQuality]
    );

    const onLowQualityClicked = (): void => {
        setUserSelectedQuality(SD?.name);
        // SD only users should actually be setting themselves to "auto" (aka HD)
        // as they only have one option, not locking themselves to SD.
        // When user only can play SD, the manifest only contains bitrates below HD,
        // so by setting to "auto" (HD.name),
        // ABR will select bitrates provided in the manifest.
        const videoQuality = canUserViewHd ? SD?.name : HD?.name;

        if (videoQuality && setVideoQualityByCategory) {
            setVideoQualityByCategory(videoQuality);
        }
    };

    const onAutoQualityClicked = (): void => {
        setUserSelectedQuality(HD?.name);

        if (setVideoQualityByCategory && HD?.name) {
            setVideoQualityByCategory(HD.name);
        }
    };

    return (
        <Selector showTitle={true}>
            {!shouldHideSdOption && (
                <ButtonAndTextWrapper>
                    <BA03QualityButton
                        key={SD?.name}
                        isActive={
                            isHdDisabled || userSelectedQuality === SD?.name
                        }
                        label={SD?.description ?? ''}
                        shortLabel={SD?.label ?? ''}
                        onClick={onLowQualityClicked}
                    />
                </ButtonAndTextWrapper>
            )}
            <ButtonAndTextWrapper>
                <BA03QualityButton
                    isDisabled={isHdDisabled}
                    key={HD?.name}
                    isActive={
                        shouldHideSdOption ||
                        (canUserViewHd && userSelectedQuality === HD?.name)
                    }
                    label={HD?.description ?? ''}
                    shortLabel={HD?.label ?? ''}
                    onClick={onAutoQualityClicked}
                />
                {isHdDisabled && (
                    <ButtonInfoText>
                        Upgrade your package to view in HD
                    </ButtonInfoText>
                )}
            </ButtonAndTextWrapper>
        </Selector>
    );
};

VideoQualitySelector.displayName = 'VideoQualitySelector';

export default observer(VideoQualitySelector);
