import type Hls from 'hls.js';
// eslint-disable-next-line no-duplicate-imports
import {type HlsListeners} from 'hls.js';
import findIndex from 'lodash/findIndex';

import type PlaybackHls from '.';
import type {PlayerQualityLevel} from '../../types';
import NativeCustomBitrate from '../native/custom-bitrate';
import {Events} from './types';

export default class HlsCustomBitrate extends NativeCustomBitrate {
    declare playbackHandler: Hls;
    declare playbackTech: PlaybackHls;

    constructor(playbackTech: PlaybackHls, playbackHandler: Hls) {
        super(playbackTech, playbackHandler);

        this.playbackHandler.on(
            Events.MANIFEST_PARSED,
            this.eventHlsManifestParsed
        );
        this.playbackHandler.on(
            Events.LEVEL_SWITCHING,
            this.eventHlsLevelSwitching
        );
        this.playbackHandler.on(
            Events.LEVEL_SWITCHED,
            this.eventHlsLevelSwitched
        );
    }

    override destroy(): void {
        this.playbackHandler.off(
            Events.MANIFEST_PARSED,
            this.eventHlsManifestParsed
        );
        this.playbackHandler.off(
            Events.LEVEL_SWITCHING,
            this.eventHlsLevelSwitching
        );
        this.playbackHandler.off(
            Events.LEVEL_SWITCHED,
            this.eventHlsLevelSwitched
        );
        super.destroy();
    }

    override set bitrateNextIndex(requestedIndex: number) {
        this.bitrateDeleteUserPreferredQuality();
        const bitrateDetail = this.levels[requestedIndex];
        const bitrate = bitrateDetail ? bitrateDetail.bitrate : -1;

        this.nextIndex = requestedIndex;
        this.playbackHandler.nextLevel = requestedIndex;
        this.bitrateSaveUserPreferred(bitrate ?? -1);

        this.autoSwitch = false;
        this.broadcastCurrentBitrateDetails();
    }

    override get bitrateIsAuto(): boolean {
        return this.autoSwitch;
    }

    override bitrateSwitchToAuto(): void {
        this.bitrateDeleteUserPreferredQuality();
        this.playbackHandler.nextLevel = -1;
        this.playbackHandler.autoLevelCapping = -1;
        this.playbackHandler.config.minAutoBitrate = 0;

        this.bitrateSaveUserPreferred(-1);

        this.autoSwitch = true;
        this.broadcastCurrentBitrateDetails();
    }

    override get capLevelToPlayerSize(): boolean {
        return this.playbackHandler.capLevelToPlayerSize;
    }

    override set capLevelToPlayerSize(shouldCap) {
        this.playbackHandler.capLevelToPlayerSize = shouldCap;
    }

    eventHlsLevelSwitching: HlsListeners[Events.LEVEL_SWITCHING] = (
        _,
        newBitrateDetail
    ) => {
        const autoSwitch = this.playbackHandler.manualLevel === -1;

        this.nextIndex = findIndex(this.levels, {
            bitrate: newBitrateDetail.bitrate,
        });
        this.autoSwitch = autoSwitch;
        this.broadcastCurrentBitrateDetails();
    };

    eventHlsLevelSwitched: HlsListeners[Events.LEVEL_SWITCHED] = (
        _,
        {level}
    ) => {
        const autoSwitch = this.playbackHandler.manualLevel === -1;
        const currentLevel = this.levels[level];

        this.currentIndex = level;
        this.autoSwitch = autoSwitch;

        if (currentLevel !== undefined) {
            this.currentHeight = currentLevel.height;
        }

        this.broadcastCurrentBitrateDetails();
    };

    eventHlsManifestParsed: HlsListeners[Events.MANIFEST_PARSED] = (
        _,
        {levels}
    ) => {
        this.levels = levels.map(({height, width, bitrate}) => ({
            id: bitrate.toString(),
            height,
            width,
            bitrate,
        }));

        this.triggerBitrateLevelsLoaded({
            levels: this.levels,
            canManuallyAdjustBitrate: true,
        });

        const userQuality = this.bitrateGetUserPreferredQuality();
        const userPreferred = this.bitrateGetUserPreferred();
        let startIndex = -1;

        if (userQuality.height) {
            startIndex = this.getLargestLevelIndexFromHeight(
                userQuality.height
            );
        } else if (userPreferred) {
            startIndex = this.getNearestIndexForBitrateLevel(
                this.levels,
                userPreferred
            );
        }

        // Stop HLS downloading segments that aren't the desired bitrate on launch.
        if (startIndex !== -1) {
            this.playbackHandler.startLevel = startIndex;
            this.playbackHandler.nextLevel = startIndex;
        }
    };

    // gets the highest index from all the levels that have a height smaller
    // than or equal to the selected height
    getLargestLevelIndexFromHeight(selectedHeight: number): number {
        return this.levels
            .map(({height}) => height)
            .reduce(
                (acc, height, i) => (height <= selectedHeight ? i : acc),
                -1
            );
    }

    override setBitrateToAuto(): void {
        this.playbackHandler.nextLevel = -1;
        this.playbackHandler.autoLevelCapping = -1;
        this.playbackHandler.config.minAutoBitrate = 0;
        this.bitrateCurrentQuality = 'auto';
        this.broadcastCurrentBitrateDetails();
    }

    override setMaxBitrate({
        selectedHeight,
        quality = 'low',
    }: {
        selectedHeight: number;
        quality?: PlayerQualityLevel;
    }): void {
        let levelIndex = this.getLargestLevelIndexFromHeight(selectedHeight);

        levelIndex = levelIndex === -1 ? 0 : levelIndex;

        this.bitrateCurrentQuality = quality;
        this.setMaximumBitrate(levelIndex);
    }

    // Uses the bitrate itself to stop the player dropping to a
    // stream with a lower bitrate while allowing it to go higher
    setMinimumBitrate(bitrate?: number): void {
        if (bitrate === undefined) {
            return;
        }

        this.playbackHandler.nextLevel = -1;
        this.playbackHandler.autoLevelCapping = -1;
        this.playbackHandler.config.minAutoBitrate = bitrate - 1;
        this.broadcastCurrentBitrateDetails();
    }

    // Uses the index of the selected level in this.levels to stop
    // the player using a stream with a higher index while allowing it
    // to drop lower if necessary
    setMaximumBitrate(levelIndex: number): void {
        this.playbackHandler.nextLevel = -1;
        this.playbackHandler.autoLevelCapping = levelIndex;
        this.playbackHandler.config.minAutoBitrate = 0;
        this.broadcastCurrentBitrateDetails();
    }
}
