import PlayerTechError, {type PlayerTechErrorArgs} from './player-tech-error';

export type PlayerTechXhrErrorArgs = PlayerTechErrorArgs & {
    XMLHttpRequest: XMLHttpRequest | null;
};

export default class PlayerTechXhrError extends PlayerTechError {
    readonly XMLHttpRequest: XMLHttpRequest | null;

    constructor({type, message, XMLHttpRequest}: PlayerTechXhrErrorArgs) {
        super({type, message});
        this.XMLHttpRequest = XMLHttpRequest;
    }
}
