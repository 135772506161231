import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba, mix} from 'polished';
import React from 'react';
import styled, {useTheme, css, type DefaultTheme} from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';
import IC31Tick from '../../ic/31-tick';

const CHECKMARK_SIZE_PX = 17;
const CHECKMARK_LARGE_SIZE_PX = 26;
const PREFIX_WIDTH_DEFAULT_PX = 66;
const PREFIX_WIDTH_SCREEN_1920_PX = 99;
const LABEL_WIDTH_DEFAULT_PX = 147;
const LABEL_WIDTH_SCREEN_1920_PX = 205;
const BUTTON_GAP_PX = 3;

export const BUTTON_WIDTH_DEFAULT_PX =
    PREFIX_WIDTH_DEFAULT_PX + LABEL_WIDTH_DEFAULT_PX + BUTTON_GAP_PX;
export const BUTTON_WIDTH_SCREEN_1920_PX =
    PREFIX_WIDTH_SCREEN_1920_PX + LABEL_WIDTH_SCREEN_1920_PX + BUTTON_GAP_PX;

const buttonSegmentStyles = css`
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: ${transition('background-color')};
    background-color: ${rgba(white, 0.2)};
    height: 100%;

    &:disabled {
        background-color: ${rgba(white, 0.1)};
        color: ${rgba(white, 0.2)};
    }
`;

const Prefix = styled.div`
    ${buttonSegmentStyles};

    text-transform: uppercase;
    font-weight: 600;
`;

const Label = styled.div`
    ${buttonSegmentStyles}
`;

const getBackgroundColor = ({theme}: {theme: DefaultTheme}): string =>
    mix(0.6, theme.brandColor, theme.brandColorVariant);

const StyledButton = styled(Button)`
    display: flex;
    position: relative;
    gap: ${BUTTON_GAP_PX}px;
    transition: ${transition('opacity')};
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    max-width: 100%;
    height: 40px;
    color: ${white};
    font: ${gibson({size: 14})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: 60px;
        font-size: 24px;
    `}

    &:disabled {
        cursor: not-allowed;

        ${/* sc-selector */ Prefix},
        ${Label} {
            background-color: ${rgba(white, 0.1)};
            color: ${rgba(white, 0.2)};
        }
    }

    &:not(:disabled) {
        &:hover {
            ${/* sc-selector */ Prefix},
            ${Label} {
                background-color: ${THEME_ACCESSORS.brandColor};
                font-weight: 600;
            }
        }

        &:focus {
            ${/* sc-selector */ Prefix},
            ${Label} {
                background-color: ${getBackgroundColor};
            }

            ${Label} {
                font-weight: 600;
            }
        }

        &[aria-pressed='true'] {
            ${/* sc-selector */ Prefix},
            ${Label} {
                background-color: ${getBackgroundColor};
            }

            ${Label} {
                font-weight: 600;
            }
        }
    }

    ${Prefix} {
        width: ${PREFIX_WIDTH_DEFAULT_PX}px;

        /* stylelint-disable-next-line csstree/validator */
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: ${PREFIX_WIDTH_SCREEN_1920_PX}px;
        `}
    }

    ${Label} {
        width: ${LABEL_WIDTH_DEFAULT_PX}px;

        /* stylelint-disable-next-line csstree/validator */
        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            width: ${LABEL_WIDTH_SCREEN_1920_PX}px;
        `}
    }
`;

const CheckedMarkCircle = styled.span`
    position: absolute;
    top: ${CHECKMARK_SIZE_PX / -2 + 1}px;
    right: ${CHECKMARK_SIZE_PX / -2 + 1}px;
    transform: scale(${({isActive}) => (isActive ? '100%' : 0)});
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55); /* https://easings.net/#easeInOutBack */
    width: ${CHECKMARK_SIZE_PX}px;
    height: ${CHECKMARK_SIZE_PX}px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        top: ${CHECKMARK_LARGE_SIZE_PX / -2 + 1}px;
        right: ${CHECKMARK_LARGE_SIZE_PX / -2 + 1}px;
        width: ${CHECKMARK_LARGE_SIZE_PX}px;
        height: ${CHECKMARK_LARGE_SIZE_PX}px;
    `}
`;

export type Props = {
    /** Icon to show on the left side, will take precedence over shortLabel */
    icon?: React.ReactNode;
    /** Text on the right side */
    label: string;
    /** Text on the left side, will display in uppercase */
    shortLabel: string;
    /** Click handler */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    /** Whether the button is active */
    isActive?: boolean;
    /** Whether the button is disabled */
    isDisabled?: boolean;
    /** Additional classname(s) */
    className?: string;
} & React.ComponentPropsWithoutRef<'button'>;

const BA03QualityButton = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            icon,
            label,
            shortLabel,
            onClick,
            isActive,
            isDisabled = false,
            className,
            ...htmlAttributes
        },
        ref
    ) => {
        const {brandColor} = useTheme();

        return (
            <StyledButton
                {...htmlAttributes}
                ref={ref}
                onClick={onClick}
                className={classnames('BA03QualityButton', className)}
                aria-pressed={isActive}
                disabled={isDisabled}
            >
                <Prefix>{icon || shortLabel}</Prefix>
                <Label>{label}</Label>
                <CheckedMarkCircle isActive={isActive} role="presentation">
                    <IC31Tick
                        color={brandColor}
                        secondaryColor={white}
                        hasDropshadow={true}
                    />
                </CheckedMarkCircle>
            </StyledButton>
        );
    }
);

BA03QualityButton.displayName = 'BA03QualityButton';

export default BA03QualityButton;
