import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

export default class ResetPassword extends React.Component {
    handleSubmit(event) {
        event.preventDefault();

        const password = this.password.value;
        const confirmPassword = this.confirmPassword.value;

        if (password === confirmPassword) {
            this.props.onSubmit(password);
        } else {
            this.confirmPassword.setCustomValidity('Passwords don’t match');
        }
    }

    renderLoadingSpinner() {
        if (!this.props.isLoading) {
            return null;
        }

        return <LoadingSpinner />;
    }

    renderError() {
        if (!this.props.error) {
            return null;
        }

        return (
            <p className="fiso-hawk-reset-password__text-info fiso-hawk-reset-password__text-info--center">
                Your token isn't valid or has expired. Initiate <a
                    className="fiso-hawk-reset-password__a"
                    href="/account/reset-password/">
                    reset password
                </a> again.
                If you have any questions, please <a
                    className="fiso-hawk-reset-password__a"
                    href={this.props.supportContactUrl}>
                    contact customer support
                </a>
            </p>
        );
    }

    renderSuccess() {
        if (!this.props.isSuccess) {
            return null;
        }

        return (
            <p className="fiso-hawk-reset-password__text-info fiso-hawk-reset-password__text-info--center">
                You have successfully reset your password. You can now <a
                    className="fiso-hawk-reset-password__a"
                    href="/login">
                    log in
                </a> using your new credentials.
            </p>
        );
    }

    renderForm() {
        if (this.props.error || this.props.isLoading || this.props.isSuccess) {
            return null;
        }

        const buttonClassName = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--primary',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--chevron-right',
            'fiso-hawk-button--extra-margin',
            `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`
        );

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form className="fiso-hawk-reset-password__form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                <label className="fiso-hawk-reset-password__label">
                    Please enter a new password
                    <input
                        autoComplete="off"
                        className="fiso-hawk-form-input__input"
                        name="password"
                        placeholder={`Enter new Watch ${this.props.sportDetails.label} password`}
                        required={true}
                        type="password"
                        ref={(input) => {
                            this.password = input;
                        }} />
                </label>
                <input
                    autoComplete="off"
                    className="fiso-hawk-form-input__input"
                    name="confirmpassword"
                    placeholder={`Re-enter new Watch ${this.props.sportDetails.label} password`}
                    required={true}
                    type="password"
                    ref={(input) => {
                        this.confirmPassword = input;
                    }} />
                <button
                    className={buttonClassName}
                    type="submit">
                    Submit
                    <Fsvg name="next-1" />
                </button>
            </form>
        );
    }

    render() {
        return (
            <section className="fsui-normalise fiso-hawk-reset-password">
                <h1 className="fiso-hawk-reset-password__heading">Reset Password</h1>
                {this.renderLoadingSpinner()}
                {this.renderSuccess()}
                {this.renderError()}
                {this.renderForm()}
            </section>
        );
    }
}

ResetPassword.defaultProps = {
    onSubmit: noop,
    isLoading: true
};

ResetPassword.propTypes = {
    error: propTypes.string,

    supportContactUrl: propTypes.string,

    isSuccess: propTypes.bool,
    isLoading: propTypes.bool,

    onSubmit: propTypes.func,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
