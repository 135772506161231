
import NpawPluginAdapters from 'npaw-plugin-adapters';

export default function createVideoFsHtml5Adapter(playerTech) {
    class VideoFsHtml5Adapter extends NpawPluginAdapters.video.Html5 {
        getPlayerName() { return 'VideoFS'; }
        getPlayerVersion() {
            return playerTech.diagnostics?.playerVersion;
        }
        getResource() { return playerTech.currentSource; }
        getRendition() {
            const {bitrateLevels, bitrateCurrentIndex} = playerTech;
            const currentLevel = bitrateLevels?.[bitrateCurrentIndex] || {};
            const {width, height, bitrate, name} = currentLevel;

            if (bitrate) {
                return name
                    ? name
                    : this.getNpawUtils?.().buildRenditionString(
                        width,
                        height,
                        bitrate
                    );
            } else {
                // Before send the start request, youbora calls getIsLive and getRendition to get all the required data.
                // In Safari browser, the bitrate is always undefined, so here return a string to make youbora send the start request correctly.
                return 'unknown';
            }
        }
        getBitrate() {
            const {bitrateLevels, bitrateCurrentIndex} = playerTech;

            return bitrateLevels?.[bitrateCurrentIndex]?.bitrate || null;
        }
        getPlayrate() {
            return playerTech.isPlaying ? playerTech.playbackRate : 0;
        }
        getDroppedFrames() {
            return playerTech?.diagnostics?.droppedFrames;
        }
        seekingListener() {
            if (this.flags.isBuffering) {
                return;
            }

            this.fireSeekBegin();
        }
        // same with video error handler in register-video-handlers.js
        errorListener(event) {
            const error = event?.detail?.error || playerTech.error;

            this.fireFatalError(
                error?.code || 0,
                error?.message || 'unknown',
                {
                    fsFatalErrorRetry: event.type === 'fs-fatal-error-retry'
                }
            );
        }
    }

    return VideoFsHtml5Adapter;
}
