export default class ShakaAdapter {
  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this._getTagFromPlayer());
    } catch (err) {
      return true;
    }
  }

  getBitrate() {
    var stats = this.player.getStats();
    var ret = null;
    if (typeof stats.streamBandwidth !== 'undefined') {
      ret = stats.streamBandwidth;
    };
    if (typeof stats.streamStats !== 'undefined') {
      ret = stats.streamStats.videoBandwidth;
    }
    return ret;
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    this.firePause({}, 'pauseListener');
  }

  bufferingListener(e) {
    this.firePlayerLog('bufferingListener-' + e.buffering, {});
    if (this.flags.isJoined) {
      if (e.buffering === true) {
        this.fireBufferBegin({}, false, 'bufferingListenerEnabled');
      } else if (e.buffering === false) {
        if (this.flags.isSeeking) {
          this.fireSeekEnd({}, 'bufferingListenerIsSeeking');
          this.cancelBuffer()
        } else {
          this.fireBufferEnd({}, 'bufferingListenerDisabled');
        }
      }
    } else {
      if (e.buffering === true) {
        this.fireBufferBegin({}, false, 'bufferingListenerEnabled');
      } else if (e.buffering === false) {
        this.fireJoin({}, 'bufferingListenerNotJoined');
      }
    }
  }

  getLatency() {
    var latency = this.player.getStats().liveLatency;
    return latency ? latency * 1000 : undefined;
  }

  getRendition() {
    var track = this._getActiveTrack();
    return track ? this.getNpawUtils().buildRenditionString(track.width, track.height, track.bandwidth) : null;
  }

  getPlayerVersion() {
    var ret = null;
    if (typeof shaka !== 'undefined') {
      if (shaka.Player) {
        ret = shaka.Player.version;
      }
      if (shaka.player) {
        ret = shaka.player.Player.version;
      }
    }
    return ret;
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    this._callStart('playListener');
  }

  endedListener(e) {
    const adsAdapter = this.getVideo().getAdsAdapter();
    let willShowCSAIAds = false;
    if (adsAdapter && typeof adsAdapter.isDAI !== 'undefined') {
      if (!adsAdapter.isDAI) {
        // This means we are using Google IMA with CSAI
        willShowCSAIAds = adsAdapter.player.getCuePoints().includes(-1);
      }
    }
    if (!willShowCSAIAds) {
      this.firePlayerLog('endedListener', {});
      this.fireStop({}, 'endedListener');
      this.bytes = 0;
      this.supportBytes = false;
    }
  }

  getPlayrate() {
    if (typeof this.player.getPlaybackRate === 'function') {
      return this.player.getPlaybackRate() || 1;
    }
    return 1;
  }

  timeupdateListener(e) {
    if (this.getPlayhead() !== this.initialPlayhead && typeof this.initialPlayhead === 'number') {
      this._callStart('timeupdateListener')
    } else {
      this.initialPlayhead = this.getPlayhead();
    }
  }

  getPlayhead() {
    var tag = this.tag || this._getTagFromPlayer();
    return tag ? tag.currentTime : null;
  }

  getDuration() {
    var tag = this.tag || this._getTagFromPlayer();
    return tag ? tag.duration : null;
  }

  getFramesPerSecond() {
    var track = this._getActiveTrack();
    return track ? track.frameRate : null;
  }

  getThroughput() {
    return this.player.getStats().estimatedBandwidth;
  }

  getDroppedFrames() {
    return this.player.getStats().droppedFrames;
  }

  supportTotalBytes() {
    return this.supportBytes;
  }

  getTotalBytes() {
    return this.bytes;
  }

  _registerTag() {
    this.tag = this.tag || this._getTagFromPlayer();
    if (this.tag) {
      for (var key in this.references) {
        this.tag.addEventListener(key, this.references[key]);
      }
    } else {
      setTimeout(this._registerTag.bind(this), 50);
    }
  }

  getVersion() {
    return '7.0.5-shaka-jsclass';
  }

  seekedListener(e) {
    this.firePlayerLog('seekedListener', {});
    if (!this.isPlayerOnBuffering() || !this.existPlayerBufferingMethod) {
      if (this.flags.isBuffering) {
        this.fireBufferEnd({}, 'seekedListener');
      } else if (this.flags.isSeeking) {
        this.fireSeekEnd({}, 'seekedListener');
      }
    }
  }

  unregisterListeners() {
    this.bytes = 0;
    this.supportBytes = false;
    if (this.monitor) this.monitor.stop();
    var tag = this.tag || this._getTagFromPlayer();
    if (tag && this.references) {
      for (var key in this.references) {
        tag.removeEventListener(key, this.references[key]);
      }
      delete this.references;
    };
    if (this.player && this.referencesPlayer) {
      for (var key2 in this.referencesPlayer) {
        this.player.removeEventListener(key2, this.referencesPlayer[key2]);
      }
      delete this.referencesPlayer;
    }
  }

  getIsLive() {
    return this.player.isLive() ? true : !this.getDuration();
  }

  _callStart(triggeredEvent) {
    if (!this.flags.isStarted) {
      this.fireStart({}, triggeredEvent);
      this.initialPlayhead = this.getPlayhead()
    }
    if (this._initialPlayheadChanged()) {
      this.fireJoin({}, triggeredEvent);
    }
  }

  getTitle() {
    var tag = this.tag || this._getTagFromPlayer();
    return tag ? tag.title : null;
  }

  networkResponseFilter(type, response) {
    try {
      if (type != shaka.net.NetworkingEngine.RequestType.SEGMENT) return;
      if (response && response.data) {
        this.bytes += response.data.byteLength;
      }
    } catch (e) { }
  }

  registerListeners() {
    this.bytes = 0;
    this.supportBytes = false;
    this.existPlayerBufferingMethod = (this.player.isBuffering && ('function' == typeof this.player.isBuffering));
    this.monitorPlayhead(false, true);
    this.references = {
      play: this.playListener.bind(this),
      loadstart: this.autoplayListener.bind(this),
      pause: this.pauseListener.bind(this),
      playing: this.playingListener.bind(this),
      seeking: this.seekingListener.bind(this),
      seeked: this.seekedListener.bind(this),
      ended: this.endedListener.bind(this),
      timeupdate: this.timeupdateListener.bind(this)
    };
    this.referencesPlayer = {
      error: this.errorListener.bind(this),
      buffering: this.bufferingListener.bind(this),
      loading: this.loadingListener.bind(this)
    };
    this.tag = null;
    this._registerTag();
    for (var key2 in this.referencesPlayer) {
      this.player.addEventListener(key2, this.referencesPlayer[key2]);
    }
    if (this.player && this.player.getNetworkingEngine && (typeof this.player.getNetworkingEngine === 'function') && this.player.getNetworkingEngine().registerResponseFilter && (typeof this.player.getNetworkingEngine().registerResponseFilter === 'function')) {
      this.supportBytes = true;
      this.player.getNetworkingEngine().registerResponseFilter(this.networkResponseFilter.bind(this));
    }
  }

  getResource() {
    var tag = this.tag || this._getTagFromPlayer();
    var ret = tag ? tag.currentSrc : null;
    if (this.player.getAssetUri && this.player.getAssetUri()) {
      ret = this.player.getAssetUri();
    } else if (this.player.getManifestUri && this.player.getManifestUri()) {
      ret = this.player.getManifestUri();
    }
    return ret;
  }

  _initialPlayheadChanged() {
    var ret = false;
    if (!this.flags.isJoined) {
      var current = this.getPlayhead();
      var initial = this.initialPlayhead || 0;
      var live = this.getVideo() ? this.getVideo().getIsLive() : this.getIsLive();
      if (((initial !== 0 && live) || !live) && current > initial) {
        ret = true;
      } else if (live) {
        this.initialPlayhead = current;
      }
    }
    return ret;
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener');
    if (this.getVideo() && this.getVideo().getIsLive()) this.autoplayListener();
  }

  _getActiveTrack() {
    var ret = null;
    if (this.player.getVariantTracks) {
      var tracks = this.player.getVariantTracks();
      for (var i in tracks) {
        var track = tracks[i];
        if (track.active && (track.type === 'video' || track.type === 'variant')) {
          ret = track;
        }
      }
    } else if (this.player.getVideoTracks) {
      var tracks2 = this.player.getVideoTracks();
      for (var j in tracks2) {
        var track2 = tracks2[j];
        if (track2.active) {
          ret = track2;
        }
      }
    }
    return ret;
  }

  getPlayerName() {
    return 'Shaka';
  }

  playingListener(e) {
    this.firePlayerLog('playingListener', {});
    this.fireResume({}, 'playingListener');
    this._callStart('playingListener');
    if (!this.isPlayerOnBuffering() || !this.existPlayerBufferingMethod) {
      this.fireJoin({}, 'playingListener');
    }
  }

  isPlayerOnBuffering() {
    if (this.existPlayerBufferingMethod) {
      return this.player.isBuffering();
    }
    return false;
  }

  loadingListener(e) {
    this.firePlayerLog('loadingListener', {});
    if (this.flags.isStarted) {
      this.fireStop({}, 'loadingListener');
      this.bytes = 0;
      this.supportBytes = false;
    }
  }

  autoplayListener(e) {
    this.firePlayerLog('autoplayListener', {});
    var tag = this.tag || this._getTagFromPlayer();
    if (tag && tag.autoplay) {
      this._callStart('autoplayListener');
    }
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    var code = e.detail && e.detail.code ? e.detail.code : e.code;
    var msg = e.detail && e.detail.message ? e.detail.message : 'unknown';
    var category = e.detail && e.detail.category ? e.detail.category : e.category;
    var severity = e.detail && e.detail.severity ? e.detail.severity : e.severity;
    if (category && category < 10 && category > 0) {
      var typeDicc = {
        1: 'network',
        2: 'text',
        3: 'media',
        4: 'manifest',
        5: 'streaming',
        6: 'drm',
        7: 'player',
        8: 'cast',
        9: 'storage'
      };
      msg = typeDicc[category] || msg;
    }
    if (severity === 2 && [1002, 3016].indexOf(code) === -1) {
      this.fireFatalError(code, msg, undefined, undefined, 'errorListener');
    } else {
      this.fireError(code, msg, undefined, undefined, 'errorListener');
    }
  }

  _getTagFromPlayer() {
    return this.player.getMediaElement ? this.player.getMediaElement() : this.player.a;
  }
}