import bacon from 'baconjs';
import superAgent from 'superagent';

import Response from '@fsa/fs-commons/lib/request-manager/response';

import {DEFAULT_VIMOND_ENV, VIMOND_MIDDLELAYER_URL} from '../../utils/constants';
import {authorizationToken, getVimondHeaders} from '../../utils/api';

/**
 * Update Password
 *
 * @param  {String} newPassword              e.g. new password to be used
 * @param  {String} oldPassword              e.g. old password
 * @param  {String} userId                   e.g. userId of the user
 *
 * @param  {String} vimondEnv         e.g. 'prod'
 * @return {Observable}                      result from API
 */

export default function updatePassword({
    newPassword,
    oldPassword,
    sport = 'afl',
    userId,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/password`;
    const authToken = authorizationToken();

    return bacon.fromBinder((sink) => {
        const request = superAgent
            .put(url)
            .set(getVimondHeaders({authToken, sport, version: 2}))
            .send({
                userId,
                oldPassword,
                newPassword
            })
            .end((err, res) => {
                const resObj = new Response(err, res);

                if (resObj.responseConsideredValid) {
                    sink(true);
                    sink(new bacon.End());
                } else {
                    sink(new bacon.Error(updatePasswordErrorMapping(resObj.parseResponseText())));
                }
            });

        return () => {
            request.abort();
        };
    });
}

/**
 * Update Password Error mapping
 *
 * @param  {Object} errorData              Object of error information
 *
 * @return {Object}                        Object with error
 */

export function updatePasswordErrorMapping(errorData = {}) {
    const errorCode = errorData.code || '';

    const errorMessages = {
        USER_INVALID_PASSWORD: 'Old password is incorrect'
    };

    return {
        message: errorMessages[errorCode] || 'Something went wrong, please try again',
        original: errorData
    };
}
