import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {rgba} from 'polished';
import React, {useRef} from 'react';
import styled from 'styled-components';

import gibson, {
    enforceMinFontSize,
    enforceMinLineHeight,
} from '../../../../../common/font-helper';
import {black, athensGray, white} from '../../../../../common/palette';
import {
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
} from '../../../../../common/screen-sizes';
import CtaButton from './cta-button';

const Card = styled.section.attrs<{scale: number}>(({scale}) => ({
    style: {
        'gap': `${10 * scale}px`,
        'font': gibson.light({
            size: enforceMinFontSize(14 * scale),
            lineHeight: `${enforceMinLineHeight(18 * scale)}px`,
        }),
        '--smwplayer-1024-line-height': `${enforceMinLineHeight(24 * scale)}px`,
        '--smwplayer-1024-font-size': `${enforceMinFontSize(18 * scale)}px`,
        '--smwplayer-1280-line-height': `${enforceMinLineHeight(30 * scale)}px`,
        '--smwplayer-1280-font-size': `${enforceMinFontSize(22 * scale)}px`,
    },
}))`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${black};
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    color: ${athensGray};

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        line-height: var(--smwplayer-1024-line-height);
        font-size: var(--smwplayer-1024-font-size);
    `}
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        line-height: var(--smwplayer-1280-line-height);
        font-size: var(--smwplayer-1280-font-size);
    `}
`;

const ErrorDataContainer = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 7px;
    max-width: 700px;
    font-size: 16px;

    > :not(:last-child) {
        margin-right: 5px;
    }

    a {
        color: inherit;
    }
`;

const ErrorText = styled.div<{scale: number}>`
    margin: 0;
    text-align: center;
    color: ${athensGray};
`;

const ErrorMessage = styled.div<{scale: number}>`
    margin: 0;
    border-top: 1px solid ${rgba(white, 0.3)};
    padding: 28px 0 18px;
    text-align: center;
    font-size: 18px;

    > :last-child {
        margin: 0;
        padding: 0;
    }
`;

const ErrorHeader = styled.h2.attrs<{scale: number}>(({scale}) => ({
    style: {
        'font': gibson.semibold({
            size: enforceMinFontSize.heading(34 * scale),
            lineHeight: `${enforceMinLineHeight.heading(40 * scale)}px`,
        }),
        '--smwplayer-1024-line-height': `${enforceMinLineHeight(40 * scale)}px`,
        '--smwplayer-1024-font-size': `${enforceMinFontSize(34 * scale)}px`,
        '--smwplayer-1280-line-height': `${enforceMinLineHeight(46 * scale)}px`,
        '--smwplayer-1280-font-size': `${enforceMinFontSize(40 * scale)}px`,
    },
}))`
    margin: 0 7px 28px;
    text-align: center;

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        line-height: var(--smwplayer-1024-line-height);
        font-size: var(--smwplayer-1024-font-size);
    `}
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        line-height: var(--smwplayer-1280-line-height);
        font-size: var(--smwplayer-1280-font-size);
    `}
`;

const TableDataContainer = styled.dl`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin: 0 auto;
    padding: 0 7px;
`;

const DataLabel = styled.dt`
    text-align: left;
`;

const DataValue = styled.dd`
    margin-left: 0;
    text-align: left;
`;

const DATE_FORMATTER = new Intl.DateTimeFormat('en-AU', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
});
const TIME_FORMATTER = new Intl.DateTimeFormat('en-AU', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
});

type Props = {
    errorDetails?: {
        title?: string;
        message?: string;
        customElementsConfig?: {
            id?: string;
            type?: string;
            link?: string;
            label?: string;
            onClick?: React.MouseEventHandler;
            renderLabel?: (element: HTMLElement | null) => void;
            href?: string;
            tableData?: {
                label?: string;
                description?: string;
            }[];
        }[][];
        timestamp?: number;
    };
    scale?: number;
};

type ErrorDetails = Omit<Props['errorDetails'], 'timestamp'> & {
    timestamp: string;
} & {[key: string]: unknown};

const ExternalErrorCard = ({
    errorDetails = {message: ''},
    scale = 1,
}: Props): React.ReactElement => {
    const {title, message, customElementsConfig = []} = errorDetails;
    const ariaId = 'genericErrorAlertDialog';
    const stableErrorDetails = useRef<ErrorDetails>({
        ...errorDetails,
        timestamp: [DATE_FORMATTER, TIME_FORMATTER]
            .map(
                (formatter) =>
                    errorDetails.timestamp &&
                    formatter.format(errorDetails.timestamp)
            )
            .join(' '),
    });

    function getSingleDiagnostic(label: string, key: string): string {
        return `${label}: ${stableErrorDetails.current[key] ?? 'N/A'}`;
    }

    const errorTextSlots = [
        ...customElementsConfig,
        [
            {
                label: [
                    ['App version', 'appVersion'],
                    ['Asset id', 'assetId'],
                    ['Player', 'player'],
                ]
                    .map(([label, key]) =>
                        getSingleDiagnostic(label as string, key as string)
                    )
                    .join(' | '),
                type: 'text',
            },
        ],
        [
            {
                label: [
                    ['Profile ID', 'profileId'],
                    ['Version', 'version'],
                ]
                    .map(([label, key]) =>
                        getSingleDiagnostic(label as string, key as string)
                    )
                    .join(' | '),
                type: 'text',
            },
        ],
        [
            {
                label: [
                    ['Timestamp', 'timestamp'],
                    ['UDID', 'udid'],
                ]
                    .map(([label, key]) =>
                        getSingleDiagnostic(label as string, key as string)
                    )
                    .join(' | '),
                type: 'text',
            },
        ],
    ];

    return (
        <Card role="alertdialog" aria-describedby={ariaId} scale={scale}>
            {!!title && <ErrorHeader scale={scale}>{title}</ErrorHeader>}

            {!!message && (
                <ErrorDataContainer>
                    <ErrorMessage id={ariaId} scale={scale}>
                        {message}
                    </ErrorMessage>
                </ErrorDataContainer>
            )}

            {errorTextSlots.map((innerElementsConfig = [], index) => (
                <ErrorDataContainer key={index}>
                    {/* eslint-disable-next-line array-callback-return */}
                    {innerElementsConfig.map(
                        ({
                            id,
                            type,
                            label,
                            renderLabel,
                            href,
                            tableData,
                            onClick,
                        }) => {
                            const key = `${type}-${id}`;

                            // eslint-disable-next-line default-case
                            switch (type) {
                                case 'table':
                                    return (
                                        <TableDataContainer>
                                            {tableData?.map(
                                                ({label, description}) => (
                                                    <React.Fragment>
                                                        <DataLabel
                                                            title={label}
                                                            aria-label={label}
                                                        >
                                                            {label}:
                                                        </DataLabel>
                                                        <DataValue>
                                                            {description}
                                                        </DataValue>
                                                    </React.Fragment>
                                                )
                                            )}
                                        </TableDataContainer>
                                    );

                                case 'text':
                                    return (
                                        <ErrorText
                                            key={`${type}-${label}`}
                                            scale={scale}
                                        >
                                            {label}
                                        </ErrorText>
                                    );

                                case 'link':
                                    return (
                                        <a
                                            key={`${type}-${label}`}
                                            href={href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {label}
                                        </a>
                                    );

                                case 'button':
                                    return (
                                        <CtaButton
                                            key={`${type}-${label}`}
                                            onClick={onClick}
                                            href={href}
                                            aria-label={label}
                                        >
                                            {label}
                                        </CtaButton>
                                    );

                                case 'element':
                                    return (
                                        <ErrorText
                                            key={key}
                                            ref={renderLabel}
                                            scale={scale}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        }
                    )}
                </ErrorDataContainer>
            ))}
        </Card>
    );
};

ExternalErrorCard.displayName = 'ExternalErrorCard';

export default ExternalErrorCard;
