import {
    ERROR_CODES,
    type MediaError,
    type ErrorDetail as PlayerTechErrorDetail,
    type ErrorDetailDiagnostics,
} from '@fsa-streamotion/player-tech';

import type {CustomErrorHandlers} from '../state/types';

// More information about error codes,
// visit https://github.com/fsa-streamotion/streamotion-web-app/blob/main/packages/player-tech/src/js/utils/error-codes.js

const defaultErrorHandlers: CustomErrorHandlers = {
    [ERROR_CODES.CUSTOM_ERR_UNKNOWN]: genericErrorHandler, // 0 (Custom)
    [ERROR_CODES.MEDIA_ERR_ABORTED]: genericErrorHandler, // 1
    [ERROR_CODES.MEDIA_ERR_NETWORK]: networkErrorHandler, // 2
    [ERROR_CODES.MEDIA_ERR_DECODE]: decodingErrorHandler, // 3
    [ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED]: unsupportedSourceErrorHandler, // 4
    [ERROR_CODES.MEDIA_ERR_ENCRYPTED]: encryptionErrorHandler, // 5
};

/**
 * This function formats and returns the error information returned by PlayerTech with relevant error handlers.
 * Users can provide a custom object (Object [string]: Function) to override the default handlers.
 *
 * @param error -Error datum containing two error objects from Player Tech.
 * @param customErrorHandlers -Custom error handlers (by default: defaultErrorHandlers)
 * @param logger -Custom logger
 *
 * @returns An object containing error title, message and detail.
 */

type XMLResponse = {
    code: number;
    message?: string;
};

export type ErrorInfo = MediaError | null;

// export type ExtraErrorInfo = PlayerTecherErrorDetail;

export type ErrorLogger = {
    error: (param1: string, param2?: unknown) => void;
};

type BasicErrorDetails = {
    type?: string;
    code?: number;
    title?: string;
    message?: string;
    detail?: string | void;
    commonAssetId?: string;
};

export type ErrorDetails =
    | (BasicErrorDetails & {
          diagnostics?: ErrorDetailDiagnostics;
      })
    | null;

type CovertArrayBufferToObjectResult = {
    arrayBufferNotJSON?: boolean;
    response?: unknown;
};

type ExtractErrorInfoFromDOM = {
    fallbackMessage?: string | null;
    infoInDOM?: string;
};

type PlayerTechError = {
    errorInfo: MediaError | null;
    extraErrorInfo: PlayerTechErrorDetail;
} | null;

export default function getErrorDetails(
    error: PlayerTechError,
    customErrorHandlers: CustomErrorHandlers | null = defaultErrorHandlers,
    logger: ErrorLogger = console
): ErrorDetails {
    if (!error) {
        return null;
    }

    const {errorInfo, extraErrorInfo} = error;
    const code = errorInfo?.code;

    if (code) {
        const errorHandlerByCode =
            customErrorHandlers?.[code] ||
            defaultErrorHandlers?.[code] ||
            genericErrorHandler;

        return errorHandlerByCode(errorInfo, extraErrorInfo, logger);
    } else {
        return genericErrorHandler(errorInfo, extraErrorInfo);
    }
}

function genericErrorHandler(
    errorInfo: ErrorInfo,
    extraErrorInfo: PlayerTechErrorDetail
): ErrorDetails {
    const message = errorInfo?.message;

    return {
        title: 'Unknown Video Error',
        message:
            'Sorry, an unidentified error has occurred. Please try again shortly.',
        detail: getDetailAsHumanTextFromErrorDetailOrMessage(
            extraErrorInfo,
            message
        ),
        diagnostics: extraErrorInfo?.diagnostics,
    };
}

function networkErrorHandler(
    errorInfo: ErrorInfo,
    extraErrorInfo: PlayerTechErrorDetail
): ErrorDetails {
    const message = errorInfo?.message;

    return {
        title: 'Video Network Error',
        message:
            'Sorry, a network error has occurred and caused your playback to stop.' +
            ' Please check your internet and try again.',
        detail: getDetailAsHumanTextFromErrorDetailOrMessage(
            extraErrorInfo,
            message
        ),
        diagnostics: extraErrorInfo?.diagnostics,
    };
}

function decodingErrorHandler(
    errorInfo: ErrorInfo,
    extraErrorInfo: PlayerTechErrorDetail
): ErrorDetails {
    const message = errorInfo?.message;

    return {
        title: 'Video Decode Error',
        message:
            'Sorry, the video could not be loaded,' +
            ' either because the server or network failed or because the format is not supported on your current browser.',
        detail: getDetailAsHumanTextFromErrorDetailOrMessage(
            extraErrorInfo,
            message
        ),
        diagnostics: extraErrorInfo?.diagnostics,
    };
}

function unsupportedSourceErrorHandler(
    errorInfo: ErrorInfo,
    extraErrorInfo: PlayerTechErrorDetail
): ErrorDetails {
    const message = errorInfo?.message;

    return {
        title: 'Browser not supported',
        message:
            'This content may not be compatible with your device.' +
            ' Please ensure you are on the latest OS and browser versions your device supports.',
        detail: getDetailAsHumanTextFromErrorDetailOrMessage(
            extraErrorInfo,
            message
        ),
        diagnostics: extraErrorInfo?.diagnostics,
    };
}

function encryptionErrorHandler(
    errorInfo: ErrorInfo,
    extraErrorInfo: PlayerTechErrorDetail,
    logger: ErrorLogger
): ErrorDetails {
    const message = errorInfo?.message;

    return {
        title: 'Unknown Encrypted Error',
        message:
            'Sorry, an unidentified encrypted error has caused playback to fail.' +
            ' Please check your browser and try again.',
        detail: getDetailAsHumanTextFromErrorDetailOrMessage(
            extraErrorInfo,
            message
        ),
        diagnostics: extraErrorInfo?.diagnostics,
        ...getErrorDetailsFromXMLResponse(extraErrorInfo, logger),
    };
}

function getDetailAsHumanTextFromErrorDetailOrMessage(
    extraErrorInfo: PlayerTechErrorDetail,
    message?: string
): string | undefined {
    if (!extraErrorInfo && !message) {
        return 'Please try again shortly.';
    }

    if (extraErrorInfo !== null && 'message' in extraErrorInfo) {
        return extraErrorInfo?.message || message?.toString?.();
    }

    return message?.toString?.();
}

export function getErrorDetailsFromXMLResponse(
    extraErrorInfo: PlayerTechErrorDetail,
    logger: ErrorLogger
): BasicErrorDetails {
    if (extraErrorInfo === null || !('XMLHttpRequest' in extraErrorInfo)) {
        return extraErrorInfo ? {detail: extraErrorInfo.toString?.()} : {};
    }

    const XMLHttpRequest = extraErrorInfo?.XMLHttpRequest;

    if (!XMLHttpRequest) {
        return extraErrorInfo ? {detail: extraErrorInfo.toString?.()} : {};
    }

    const status = XMLHttpRequest?.status;

    // Network error, we have no status code.
    if (!status) {
        return {
            title: 'Video Network Error',
            message:
                'Sorry, a network error has occurred and caused your playback to stop.' +
                ' Please check your internet and try again.',
            detail: 'Unable to communicate with encryption services.',
        };
    }

    const irdetoDetail = getIrdetoErrorMessageFromHttpResponse(
        XMLHttpRequest,
        logger
    );

    if (irdetoDetail?.type === 'CONCURRENCY_EXCEEDED') {
        return {
            title: 'Stream Limit Exceeded',
            message:
                'You have reached the maximum number of simultaneous streams allowed.',
            detail: irdetoDetail.message,
        };
    }

    if (irdetoDetail?.message) {
        return {
            detail: irdetoDetail.message,
        };
    }

    return {};
}

function getIrdetoErrorMessageFromHttpResponse(
    XMLHttpRequest: XMLHttpRequest,
    logger: ErrorLogger
): BasicErrorDetails {
    const baseErrorDetails = {
        type: 'GENERIC',
        code: 0,
        message: 'An unknown response was returned from license challenge.',
    };

    const {response, arrayBufferNotJSON} = covertResponseToObjectByType(
        XMLHttpRequest,
        logger
    );

    if (response) {
        return formatIrdetoResponseToErrorDetails(response as XMLResponse);
    }

    // we might be XML? Need to check how playready errors come back.
    if (arrayBufferNotJSON) {
        try {
            const responseAsDOM = covertXMLToDOM(XMLHttpRequest?.response);
            const {infoInDOM, fallbackMessage} =
                extractErrorInfoFromDOM(responseAsDOM);

            if (infoInDOM) {
                const infoInObject = JSON.parse(infoInDOM);

                return formatIrdetoResponseToErrorDetails(infoInObject);
            }

            if (fallbackMessage) {
                return {
                    ...baseErrorDetails,
                    message: fallbackMessage,
                };
            }

            // CustomData should have JSON payload now and continue on with something meaningful.
        } catch (e) {
            logger.error('EME: XML parse error', e);
        }
    }

    return baseErrorDetails;
}

function extractErrorInfoFromDOM(dom: Document): ExtractErrorInfoFromDOM {
    const customData = dom.querySelector('detail Exception CustomData');
    const content = customData?.textContent;

    if (!content) {
        // No idea why we don't have CustomData from the DRM server, so let's go for the faultstring instead
        const fallbackMessage = dom.querySelector('faultstring')?.textContent;

        return {fallbackMessage};
    }

    return {infoInDOM: content};
}

function covertXMLToDOM(response?: XMLResponse | ArrayBuffer): Document {
    const parser = new window.DOMParser();

    return parser.parseFromString(
        covertArrayBufferToString(response as ArrayBuffer),
        'application/xml'
    );
}

function formatIrdetoResponseToErrorDetails(
    response: XMLResponse
): BasicErrorDetails {
    const defaultMessage = [response?.code, response?.message]
        .filter(Boolean)
        .join(': ');
    const code = response?.code || 0;
    const isConcurrecyExceededCode = `${code}` === '130401';
    const type = isConcurrecyExceededCode ? 'CONCURRENCY_EXCEEDED' : 'GENERIC';

    return {
        type,
        code,
        message: defaultMessage || JSON.stringify(response),
    };
}

function covertResponseToObjectByType(
    xhr: XMLHttpRequest,
    logger: ErrorLogger
): {
    response?: unknown;
    arrayBufferNotJSON?: boolean;
} {
    const {response} = xhr;

    switch (xhr.responseType) {
        case 'arraybuffer':
            return covertArrayBufferToObject(response as ArrayBuffer);
        case 'json':
            return {response};

        default:
            logger.error(
                'getIrdetoDetailFromXMLHttpRequest, unhandled response type',
                xhr
            );
            sendNewRelicInvalidTypeError(xhr); // TODO Handle NewRelic correctly

            return {};
    }
}

function covertArrayBufferToObject(
    buffer: ArrayBuffer
): CovertArrayBufferToObjectResult {
    try {
        return {
            response: JSON.parse(covertArrayBufferToString(buffer)),
        };
    } catch {
        return {arrayBufferNotJSON: true};
    }
}

function covertArrayBufferToString(buffer: ArrayBuffer): string {
    return String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer)));
}

function sendNewRelicInvalidTypeError(XMLHttpRequest: XMLHttpRequest): void {
    window?.newrelic?.noticeError?.(
        // eslint-disable-line no-unused-expressions
        new Error('getIrdetoDetailFromXMLHttpRequest: unexpected responseType'),
        {
            responseType: XMLHttpRequest?.responseType,
            resultText: XMLHttpRequest?.responseText,
            allResponseHeaders: XMLHttpRequest?.getAllResponseHeaders?.(),
        }
    );
}
