import {isBrowser} from '@fsa-streamotion/browser-utils';

import superagent from 'superagent';

import {DEFAULT_CACHE_TTL_MS, DEFAULT_REQUEST_TIMEOUT_MS} from './constants';
import {getLowestFrequency} from './helpers';
import Response from './response';
import type {CachedResponseObject, CallbackArgs, UrlResponse} from './types';

let cachedResponses: CachedResponseObject = {}; // Object to contain the requests and data
let gcTimer: ReturnType<typeof setTimeout>; // garbage collection timer

if (isBrowser()) {
    // Making response object available on global scope to ease debugging
    window.fsRequestManagerCachedResponses = cachedResponses;
}

/**
 * Adds api call to the cache directory and runs that api call if required
 *
 * @param cacheTtlMs       - How long[ms] this result should stay valid in client cache default 3,000ms server / 30,000ms client.
 * @param freqMs           - How frequency[ms] to poll
 * @param onResponse       - Function to call when the response is satisfied
 * @param requestTimeoutMs - How long (in milliseconds) to wait before abandoning request
 * @param url              - URL to fetch
 */
type GetFeedArgs = {
    cacheTtlMs?: number;
    freqMs?: number | null;
    onResponse: (arg: Response) => void;
    requestTimeoutMs?: number;
    url: string;
};

export function getFeed({
    cacheTtlMs = DEFAULT_CACHE_TTL_MS,
    freqMs = 0,
    onResponse,
    requestTimeoutMs = DEFAULT_REQUEST_TIMEOUT_MS, // timeout quickly on the client but lets have a long expiry on the server to prevent thrashing
    url,
}: GetFeedArgs): void {
    const cachedResponse = cachedResponses[url];

    // first check if API response already exists
    if (!cachedResponse) {
        cachedResponses[url] = {
            timestamp: Date.now(),
            responseObject: null, // Some class object here.
            nextRefresh: undefined,
            fetching: true,
            cacheTtlMs,
            callbacks: [
                {
                    frequency: freqMs,
                    callback: onResponse,
                },
            ],
        };

        getResponse({url, requestTimeoutMs});

        return;
    }

    const cacheStillValid =
        Date.now() - cachedResponse.timestamp < cachedResponse.cacheTtlMs;

    // If the feed exists
    if (cachedResponse.fetching) {
        // and is fetching, push our callback to the stack.
        cachedResponse.callbacks.push({
            callback: onResponse,
            frequency: freqMs,
        });

        return;
    } else if (
        freqMs === 0 &&
        cacheStillValid &&
        cachedResponse.responseObject
    ) {
        // @todo Wondering if we should also check the response object for validity.
        // Otherwise we can return cached failed objects.
        // also check cachedResponses[url].responseObject.responseConsideredValid.
        // Otherwise we could hand back cached 500 errors, for example.
        // It's more of a wondering question rather than a we should absolutely do this check.
        // If it was 404 for example, we'd probably want to keep that cache as
        // it's unlikely to change within 30 seconds maybe

        // and we're not fetching as well the cache hasn't expired, but want a cached response
        const thisResponseObject = cachedResponse.responseObject; // temp var so it's not garbage collected in async-ness

        setTimeout(() => {
            // In the scenario that we have cache, we need to
            // reply to whatever is asking in a async fashion (rather than synchronous)
            onResponse(thisResponseObject);
        });

        return;
    }

    // We may need to see if we need to refresh our widget timer.
    // Lets find the lowestFrequency currently in our stack before we push ourselves to it.
    const lowestFrequency = getLowestFrequency(cachedResponse.callbacks);

    // Feed exists, but isn't fetching right now.
    // Add it to the stack
    cachedResponse.callbacks.push({callback: onResponse, frequency: freqMs});

    // This feed is currently inactive. Restart it!
    if (cachedResponse.nextRefresh === null) {
        getResponse({url, requestTimeoutMs});

        return;
    }

    // The feed is active, but we have no idea how much longer we have to wait for it.
    // If something has asked for 30 seconds, and we want it in 3 seconds, we should cancel
    // the current timer, and just deal with it now.
    // If the timer is 3 seconds, and we're asking for anything less (or more) we can freaking wait.
    // The measurement for this is going to be 50% less. (So if something is 30 seconds, and we ask for 15)
    if (!lowestFrequency || !freqMs || lowestFrequency / 2 > freqMs) {
        clearTimeout(cachedResponse.nextRefresh);
        cachedResponse.nextRefresh = undefined;
        getResponse({url, requestTimeoutMs});
    } else if (cachedResponse.responseObject) {
        // If we have a response object, give back the stale one.
        // We'll get updated on the next cycle anyway + don't return invalid stale objects.
        onResponse(cachedResponse.responseObject);
    }
}

export function stopFeed(
    url: string,
    callbackFunction: (args: Response) => void
): void {
    const feedDetails = cachedResponses[url];

    if (!feedDetails || !feedDetails.callbacks) {
        return;
    }

    const keptCallbacks = feedDetails.callbacks.reduce(
        (accumulator: Array<CallbackArgs>, currentCallbackObject) => {
            if (currentCallbackObject.callback === callbackFunction) {
                if (feedDetails.fetching) {
                    if (feedDetails.callbacks.length === 1) {
                        //  If it's the only callback in the list, actually abort the request.
                        //  Make that we're no longer fetching. Need to ensure the next callback loaded
                        //  for this aborted request starts up again.
                        feedDetails.superAgentRequest?.abort();
                        feedDetails.fetching = false;
                    }

                    // In all cases from here, this callback function has been asked to stop,
                    // there's no need for us to put back into the list of callbacks to respond to.
                }
            } else {
                accumulator.push(currentCallbackObject);
            }

            return accumulator;
        },
        []
    );

    if (keptCallbacks.length === 0) {
        clearTimeout(feedDetails.nextRefresh);
        feedDetails.nextRefresh = undefined;
    }

    feedDetails.callbacks = keptCallbacks;
}

export function stopAll(): void {
    // Stop all callbacks and timer
    Object.entries(cachedResponses).forEach(([url, cachedResponse]) => {
        // clear timer
        clearTimeout(cachedResponse.nextRefresh);
        cachedResponse.nextRefresh = undefined;

        // abort super agent request (may or may not be inflight)
        try {
            cachedResponse.superAgentRequest && // eslint-disable-line no-unused-expressions
                cachedResponse.superAgentRequest.abort();
        } catch (e) {} // eslint-disable-line no-empty

        // clear out the object entirely.
        cachedResponses[url] = {} as UrlResponse;
    });

    // Empty all cached responses
    cachedResponses = {};
}

export function garbageCollectionStart(): void {
    const collectionFrequency = 30 * 1000;

    gcTimer = setInterval(garbageCollection, collectionFrequency);
}

export function garbageCollectionStop(): void {
    clearTimeout(gcTimer);
}

type GetResponseArgs = {
    url: string;
    requestTimeoutMs?: number;
};

function getResponse({url, requestTimeoutMs}: GetResponseArgs): void {
    const cachedResponse = cachedResponses[url] ?? ({} as UrlResponse);

    if (!url || !cachedResponses[url]) {
        console.error('Cannot get response without required information');
    }

    const superAgentRequest = superagent.get(url);

    if (requestTimeoutMs) {
        superAgentRequest.timeout(requestTimeoutMs);
    }

    Object.assign(cachedResponse, {
        fetching: true,
        superAgentRequest,
    });

    return cachedResponse.superAgentRequest?.end(
        (err: unknown, response: superagent.Response): void => {
            const responseObject = new Response({
                err,
                response,
                requestUrl: url,
            });

            Object.assign(cachedResponse, {
                fetching: false,
                timestamp: Date.now(),
                responseObject,
            });

            runCallbacks({url, responseObject, requestTimeoutMs});
        }
    );
}

type RunCallbacksArgs = GetResponseArgs & {
    responseObject: Response;
};

function runCallbacks({
    url,
    responseObject,
    requestTimeoutMs,
}: RunCallbacksArgs): void {
    const callbackStack: Array<CallbackArgs> = [];
    const callbacksToRun: Array<(superAgentObject: Response) => void> = [];
    const cachedResponse = cachedResponses[url];

    // Always clear the timeout before requesting new timeouts.
    clearTimeout(cachedResponse?.nextRefresh);

    if (cachedResponse === undefined) {
        return;
    }

    cachedResponse.nextRefresh = undefined;
    // Run all our callbacks.
    // For callbacks that still have a frequency, add them to a callback stack to
    // call again next time.
    cachedResponse.callbacks.forEach((callbackDetails) => {
        callbacksToRun.push(callbackDetails.callback);

        // And if we have a frequency for this, we'll call him again.
        if (callbackDetails.frequency) {
            callbackStack.push(callbackDetails);
        }
    });

    cachedResponse.callbacks = callbackStack;

    if (callbackStack.length > 0) {
        const lowestFrequency = getLowestFrequency(callbackStack);

        if (lowestFrequency) {
            cachedResponse.nextRefresh = setTimeout(
                () => void getResponse({url, requestTimeoutMs}),
                lowestFrequency
            );
        }
    }

    callbacksToRun.forEach((callback) => void callback(responseObject));
}

function garbageCollection(): void {
    Object.entries(cachedResponses).forEach(([url, cachedResponse]) => {
        if (!cachedResponse.callbacks || !cachedResponse.callbacks.length) {
            const currentTime = Date.now();
            const garbageCollectionPeriod = cachedResponse.cacheTtlMs;

            if (
                currentTime - cachedResponse.timestamp >
                garbageCollectionPeriod
            ) {
                delete cachedResponses[url];
            }
        }
    });
}

garbageCollectionStart();
