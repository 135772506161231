import React from 'react';
import propTypes from 'prop-types';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

export default class Accordion extends React.PureComponent {
    renderItem(item, index) {
        const onClickHanlder = () => {
            this.itemsContent[`item-${index}`].classList.toggle('fiso-hawk-accordion__item-content--visible');
            this.fsvgs[`fsvg-${index}`].classList.toggle('fiso-hawk-accordion__item-content-fsvg--expand');
        };

        return (
            <div className="fiso-hawk-accordion__item" key={index}>
                <button className="fiso-hawk-accordion__item-title" onClick={onClickHanlder}>
                    <span className="fiso-hawk-accordion__item-title-span">
                        {item.title}
                        <div
                            ref={(fsvg) => {
                                if (this.fsvgs === undefined) {
                                    this.fsvgs = {};
                                }
                                this.fsvgs[`fsvg-${index}`] = fsvg;
                            }}
                            className="fiso-hawk-accordion__item-title-svg-container">
                            <Fsvg name="show-hide" />
                        </div>
                    </span>
                </button>
                <div
                    ref={(div) => {
                        if (this.itemsContent === undefined) {
                            this.itemsContent = {};
                        }
                        this.itemsContent[`item-${index}`] = div;
                    }}
                    className="fiso-hawk-accordion__item-content">
                    {item.content}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="fiso-hawk-accordion">
                {this.props.data.map(this.renderItem, this)}
            </div>
        );
    }
}

Accordion.defaultProps = {
    data: []
};

Accordion.propTypes = {
    data: propTypes.arrayOf(
        propTypes.shape({
            title: propTypes.string,
            content: propTypes.object
        })
    )
};
