import React from 'react';
import bacon from 'baconjs';
import get from 'lodash/get';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import {redirectNoLogin} from '../utils/auth';
import UpdatePaymentDetailsComponent from '../components/update-payment-details';

import getBrainTreeClient from '../streams/braintree/get-braintree-client';
import getBrainTreeDataCollector from '../streams/braintree/get-braintree-data-collector';
import getUserOrdersStream from '../streams/user/user-get-orders';
import tokenizeBrainTreeInstance from '../streams/braintree/tokenize-braintree-instance';
import initPaymentMethodStream from '../streams/payment-method/post-init-payment-method';
import completePaymentMethodStream from '../streams/payment-method/post-complete-payment-method';
import updatePaymentMethodStream from '../streams/payment-method/put-payment-method';

import {USER_LOCAL_STORAGE_KEY, DEFAULT_VIMOND_ENV} from '../utils/constants';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function UpdatePaymentDetails(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        baseUrl: settings.baseUrl,
        disablePaymentUpdate: settings.disablePaymentUpdate === 'true' || false,
        loginRoute: settings.loginRoute,
        profilePageLink: settings.profilePageLink,
        vimondEnv: settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

UpdatePaymentDetails.prototype.init = function (initialData = false) {
    redirectNoLogin(() => {
        this.closeStreams = this.getData(initialData)
            .onValue(this.render.bind(this));
    }, {loginRoute: this.config.loginRoute});
};

UpdatePaymentDetails.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,
                identifier: 'UpdatePaymentDetails',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

UpdatePaymentDetails.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);

    const reactElement = data.map((data) => <UpdatePaymentDetailsComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

UpdatePaymentDetails.prototype.getData = function () {
    if (!process || !process.browser) {
        return bacon.later(0, {
            view: {
                isLoading: true,
                sportDetails: getSportDetails(this.config.sport)
            },
            iso: {}
        });
    }

    const {baseUrl, disablePaymentUpdate, sport, vimondEnv} = this.config;

    const sportDetails = getSportDetails(sport);

    const paymentBus = new bacon.Bus();

    const user = getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY});

    const activeOrder = getUserOrdersStream({
        sport,
        userId: user.id,
        vimondEnv
    })
        .map('.0');

    let brainTreeClientInstanceStream;

    if (process.browser) {
        brainTreeClientInstanceStream = getBrainTreeClient({baseUrl});
    } else {
        brainTreeClientInstanceStream = bacon.never();
    }

    const deviceDataStream = brainTreeClientInstanceStream
        .flatMapLatest((client) => {
            const configuration = {
                client,
                kount: true
            };

            return getBrainTreeDataCollector(configuration).flatMapLatest((dataCollectorInstance) => dataCollectorInstance.deviceData);
        });

    const paymentStream = paymentBus
        .combine(deviceDataStream, (payment, deviceData) =>  Object.assign({}, {deviceData}, payment))
        .flatMapLatest(({deviceData, paypalNonce, hostedFieldsInstance}) => {
            if (hostedFieldsInstance) {
                return tokenizeBrainTreeInstance({hostedFieldsInstance}).map((creditCardNonce) => ({
                    deviceData,
                    nonce: creditCardNonce,
                    paymentType: 'CC',
                    paymentMethod: 'BRAINTREE'
                }));
            } else {
                return {
                    deviceData,
                    nonce: paypalNonce,
                    paymentType: 'PayPal',
                    paymentMethod: 'BRAINTREE'
                };
            }
        });

    const initPaymentMethod = paymentBus
        .map(({
            sport,
            userId: user.id,
            vimondEnv
        }))
        .flatMapLatest(initPaymentMethodStream);

    const completePaymentMethod = initPaymentMethod
        .combine(
            paymentStream,
            (paymentMethod, payment) => ({paymentMethod, payment}))
        .flatMapLatest(({paymentMethod, payment}) => {
            if (payment.error) {
                return new bacon.Error(payment.error);
            } else if (payment.name === 'BraintreeError') {
                return new bacon.Error(payment);
            }

            return completePaymentMethodStream({
                deviceData: payment.deviceData,
                nonce: payment.nonce,
                paymentMethod,
                paymentType: payment.paymentType,
                sport,
                userId: user.id,
                vimondEnv
            });
        });

    const updatePaymentDetailsStream = activeOrder
        .combine(
            completePaymentMethod,
            (order, paymentMethod) => ({order, paymentMethod}))
        .flatMapLatest(({order, paymentMethod}) => {
            if (!paymentMethod) {
                return new bacon.Error({message: 'A tokenization network error occurred'});
            } else if (paymentMethod.error) {
                return new bacon.Error(paymentMethod.error);
            } else if (paymentMethod.name === 'BraintreeError') {
                return new bacon.Error(paymentMethod);
            }

            return updatePaymentMethodStream({
                order,
                paymentMethod,
                sport,
                vimondEnv
            });
        })
        .startWith(null);

    const success = updatePaymentDetailsStream
        .map(true)
        .mapError(false)
        .toProperty(false);

    const error = updatePaymentDetailsStream
        .map(null)
        .mapError((error = {}) => {
            const errorMsg = get(error, 'response.body.error.description', error.message) || '';

            // Because the error messages are kinda shit, so we have to do this.
            if (errorMsg.indexOf('Invalid nonce') >= 0) {
                return 'This card has been used previously. Please use a different card to update your payment details.';
            } else if (errorMsg.indexOf('All fields are empty') >= 0) {
                return 'You have not entered any card details. Please enter your card details and try again.';
            } else if (errorMsg.indexOf('Some payment input fields are invalid') >= 0) {
                return 'Some card details were entered incorrectly. Please correct these and try again.';
            } else if (errorMsg.indexOf('the network is offline') >= 0) {
                return 'You don’t appear to be online. Please connect to the internet and try again.';
            } else {
                return [
                    'Your payment details could not be updated at this time. Please try again later, or ',
                    <a key="customer-support" href={`mailto:watch${sportDetails.route}@foxsports.com.au`}>contact support</a>,
                    ' for further help.'
                ];
            }
        })
        .toProperty(null);

    const activePaymentMethod = activeOrder.combine(
        updatePaymentDetailsStream,
        (activeOrder, updatePaymentDetailsStream) => ({activeOrder, updatePaymentDetailsStream}))
        .flatMapLatest(({activeOrder, updatePaymentDetailsStream}) => {
            if (updatePaymentDetailsStream) {
                return updatePaymentDetailsStream.order.userPaymentMethod;
            }

            return activeOrder.userPaymentMethod;
        });

    const isLoading = bacon
        .mergeAll(
            paymentBus.map(true),
            success.map(false),
            error.map(false)
        )
        .toProperty(true);

    const paymentDetails = activePaymentMethod.map((paymentMethod) => {
        if (!get(paymentMethod, 'userPaymentMethodType')) {
            return null;
        }

        const paymentInfo = paymentMethod.userPaymentMethodType ===
            'CREDIT_CARD' ? paymentMethod.paymentInfo.substr(-4) : paymentMethod.paymentInfo;
        let cardType;

        switch (paymentMethod.paymentInfo.substr(0, 1)) {
            case '4':
                cardType = 'Visa';
                break;
            case '5':
                cardType = 'MasterCard';
                break;
            default:
                cardType = 'Card';
        }

        return {
            paymentType: paymentMethod.userPaymentMethodType,
            paymentInfo,
            paymentExpiry: paymentMethod.expireDate,
            cardType
        };
    })
        .startWith(null);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            sportDetails,
            profilePageLink: this.config.profilePageLink,
            disablePaymentUpdate,
            brainTreeClientInstance: brainTreeClientInstanceStream.startWith(null),
            activeOrder,
            paymentDetails,
            isLoading,
            success,
            error,
            onSubmitCreditCardSubscription: ({hostedFieldsInstance}) => {
                paymentBus.push({hostedFieldsInstance});
            },
            onSubmitPaypalSubscription: ({paypalNonce}) => {
                paymentBus.push({paypalNonce});
            }
        }),
        iso: bacon.combineTemplate({})
    });
};

UpdatePaymentDetails.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <UpdatePaymentDetailsComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <UpdatePaymentDetailsComponent {...data.view} />,
            'hawkwidgets-update-payment-details',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

UpdatePaymentDetails.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

UpdatePaymentDetails.prototype.pageBoot = function () {
    pageBoot(UpdatePaymentDetails, 'hawkwidgets-update-payment-details');
};

export default function (element, settings) {
    return new UpdatePaymentDetails(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(UpdatePaymentDetails, 'hawkwidgets-update-payment-details');
