import {isBrowser} from '@fsa-streamotion/browser-utils';

/**
 * How long to wait for request completion (on client vs server)
 */
export const DEFAULT_REQUEST_TIMEOUT_MS = isBrowser() ? undefined : 30 * 1000;

/**
 * How long to persist cached responses in request manager (on client vs server)
 */
export const DEFAULT_CACHE_TTL_MS = (isBrowser() ? 30 : 3) * 1000;

/**
 * How many times to retry on request failure (on client vs server)
 */
export const DEFAULT_RETRY_COUNT = isBrowser() ? 4 : -1;

/**
 * How long to wait between retry attempts (e.g. on request failure)
 */
export const DEFAULT_RETRY_DELAY_MS = 5 * 1000; // 5 seconds
