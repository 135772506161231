import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY} from '../utils/constants';

export default class VideoErrorScreen extends React.PureComponent {
    renderLiveButton() {
        if (!this.props.isLoginRequired) {
            return null;
        }

        return (
            <a
                href={this.props.redirectUrl}
                className={classNames(
                    'fiso-hawk-button',
                    'fiso-hawk-button--primary',
                    'fiso-hawk-video-error-screen__button',
                    'fiso-hawk-video-error-screen__login-btn',
                    `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`)}>
                Log in
            </a>
        );
    }

    renderSubscriptionButton() {
        if (!this.props.isSubscriptionRequired) {
            return null;
        }

        return (
            <a
                href="/"
                className={classNames(
                    'fiso-hawk-button',
                    'fiso-hawk-button--primary',
                    'fiso-hawk-video-error-screen__button',
                    'fiso-hawk-video-error-screen__subscription-btn',
                    `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`)}>
                Subscribe
            </a>
        );
    }

    render() {
        const specialProduct = getLocalStorageValue({key: SUBSCRIPTION_PACK_LOCAL_STORAGE_KEY});
        const isSpecialProductUser = !!get(specialProduct, 'id');
        const specialProductStartDate = moment(get(specialProduct, 'startDate'));
        const specialProductEndDate = moment(get(specialProduct, 'endDate'));

        const specialProductErrorMsg = `This free pass
            is active from ${specialProductStartDate.format('h:mma dddd, D MMMM YYYY')} to ${specialProductEndDate.format('h:mma dddd, D MMMM YYYY ([GMT] Z)')}`;

        const isSpecialProductError = isSpecialProductUser && moment().isBefore(specialProductStartDate);

        return (
            <div className="fiso-hawk-video-error-screen fsui-normalise">
                <div className="fiso-hawk-video-error-screen__message">
                    {isSpecialProductError ? specialProductErrorMsg : this.props.message}
                </div>
                <div className="fiso-hawk-video-error-screen__actions">
                    {this.renderLiveButton()}
                    {!isSpecialProductError && this.renderSubscriptionButton()}
                </div>
            </div>
        );
    }
}

VideoErrorScreen.defaultProps = {
    isLoginRequired: false,
    isSubscriptionRequired: false,
    message: ''
};

VideoErrorScreen.propTypes = {
    isLoginRequired: propTypes.bool,
    isSubscriptionRequired: propTypes.bool,
    message: propTypes.string,

    redirectUrl: propTypes.string,

    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
