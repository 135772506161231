import debounce from 'lodash/debounce';
import invoke from 'lodash/invoke';
import React, {useEffect, useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';

import {Z_INDEX_OVER_SCREENS} from '../../../utils/constants';
import {usePlayerContext} from '../../context';
import Classification from './classification';
import Hud from './hud';
import LowerControls from './lower-controls';
import StillWatching from './still-watching';
import UpNext from './up-next';
import UpperControls from './upper-controls';

const Positioner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Z_INDEX_OVER_SCREENS};
    margin: 0;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
`;

type Props = {
    upperTrayPostCloseIconFocusRef: React.ForwardedRef<HTMLButtonElement>;
    lowerTrayPostCloseIconFocusRef: React.ForwardedRef<HTMLButtonElement>;
};

function Overlays(props: Props): React.ReactElement {
    const theme = useTheme();
    const playerState = usePlayerContext();
    const {globalState} = playerState ?? {};
    const {isHudVisible} = globalState ?? {};
    const ref = useRef<HTMLDivElement>(null);
    const hudRef = useRef<HTMLDivElement>(null);
    const [windowSize, setWindowSize] = useState<{width?: number}>({});

    // On window resize, set a variable so that the calculation effect re-runs
    const debouncedHandleResize = debounce(() => {
        setWindowSize({
            width: window.innerWidth,
        });
    }, 50);

    // On mount/unmount
    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize, {
            passive: true,
        });

        return () => {
            window.removeEventListener('resize', debouncedHandleResize, {
                passive: true,
            });
            invoke(debouncedHandleResize, 'cancel');
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ref.current && hudRef.current) {
            theme.effectiveViewportWidthPx =
                ref.current.offsetWidth - hudRef.current.offsetWidth;
        }
    }, [theme, isHudVisible, windowSize.width]);

    return (
        <Positioner ref={ref}>
            <Classification />
            <UpNext />
            <StillWatching />
            <UpperControls ref={props.upperTrayPostCloseIconFocusRef} />
            <LowerControls ref={props.lowerTrayPostCloseIconFocusRef} />
            <Hud ref={hudRef} />
        </Positioner>
    );
}

Overlays.displayName = 'Overlays';

export default Overlays;
