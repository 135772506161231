import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;
const IC06Play = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M8.41 17.04l36.805 28.319L8.41 73.679v-56.64M0 0v90.731L58.965 45.36 0 0z"
            transform="translate(20.91 4.1)"
        />
    </Ic>
);

IC06Play.displayName = 'IC06Play';

export default IC06Play;
