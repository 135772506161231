import superagent from 'superagent';

import {streamFromApiWithRetry, getVimondHeaders} from '../../utils/api';
import authenticated from '../authenticated';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

import userSetLocalStorage from './user-set-local-storage';

export default function ({
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return authenticated().flatMapLatest((authToken) => {
        if (!authToken) {
            return;
        }

        const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user`;
        const getUser = () => superagent.get(resourceUrl).set(getVimondHeaders({authToken, sport, version: 2}));

        return streamFromApiWithRetry({apiCall: getUser})
            .map('.body')
            .doAction(userSetLocalStorage);
    });
}
