type SrcsetOptions = {[key: number]: string};

export default function stringifySrcsetOptions(
    srcsetOptions: SrcsetOptions = {}
): string {
    return Object.entries(srcsetOptions)
        .map(([width, url]) => `${url} ${width}w`)
        .join(',');
}

/**
 * Given a sizesAtViewports object, return a string which can be used in an image's sizes attribute
 * If given a falsey sizesAtViewports, it'll return it (e.g. for playing nice with react)
 *
 * @example returns undefined
 * ```
 * sizesAtViewportsToSizes();
 * ```
 * @example returns "2560px"
 * ```
 * sizesAtViewportsToSizes({2560: 1000});
 * ```
 * @example returns "(min-width: 2560px) 1000px, 320px"
 * ```
 * sizesAtViewportsToSizes({2560: 1000, 640: 320});
 * ```
 *
 * @param sizesAtViewports - Object with key:value pairs corresponding to viewport:elementWidth pixel values
 *
 * @returns String for use in a sizes attribute of HTML's img or source element
 */
export function sizesAtViewportsToSizes(sizesAtViewports?: {
    [key: number]: number;
}): string | undefined {
    if (!sizesAtViewports) {
        return sizesAtViewports;
    }

    const [smallestViewport, ...ascViewports] = Object.entries(
        sizesAtViewports
    ).sort(([viewportA], [viewportB]) => Number(viewportA) - Number(viewportB)); // initially sort from smallest to largest viewport

    return ascViewports
        .reverse() // now its largest to smallest (but excluding the smallest)
        .map(
            ([viewportPx, elementWidthPx]) =>
                `(min-width: ${viewportPx}px) ${elementWidthPx}px`
        )
        .concat(`${smallestViewport?.[1]}px`)
        .join(',');
}
