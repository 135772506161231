/* eslint-disable import/no-commonjs */

module.exports = { // eslint-disable-line import/unambiguous
    alertNotice: require('./widgets/alert-notice').default,
    banner: require('./widgets/banner').default,
    editYourDetails: require('./widgets/edit-your-details').default,
    fixtureList: require('./widgets/fixture-list').default,
    footer: require('./widgets/footer').default,
    forgotPassword: require('./authentication/widgets/forgot-password').default,
    getApp: require('./widgets/get-app').default,
    hawkPlayer: require('./widgets/hawk-player').default,
    ladder: require('./widgets/ladder').default,
    liveEvents: require('./widgets/live-events').default,
    liveVideo: require('./widgets/live-video').default,
    login: require('./authentication/widgets/login').default,
    // MW-227 - remove Manage Your Devices
    // manageDevices: require('./widgets/manage-devices').default,
    manageSubscription: require('./widgets/manage-subscription').default,
    matchCard: require('./widgets/match-card').default,
    matchDetails: require('./widgets/match-details').default,
    matchVideo: require('./widgets/match-video').default,
    navigation: require('./widgets/navigation').default,
    offerHome: require('./widgets/offer-home').default,
    profile: require('./widgets/profile').default,
    promoHeader: require('./widgets/promo-header').default,
    resetPassword: require('./authentication/widgets/reset-password').default,
    scoresNonLive: require('./widgets/scores-pre-post').default,
    shows: require('./widgets/shows').default,
    showPage: require('./widgets/show-page').default,
    subscribe: require('./widgets/subscribe').default,
    tvGuide: require('./widgets/tv-guide').default,
    tvGuideSimple: require('./widgets/tv-guide-simple').default,
    updatePassword: require('./widgets/update-password').default,
    updatePaymentDetails: require('./widgets/update-payment-details').default,
    videoCarousel: require('./widgets/video-carousel').default,
    videoCarouselWithTabs: require('./widgets/video-carousel-with-tabs').default
    // MW-74 - new offer-home replaces home page welcome-offer + offer
    // welcomeOffer: require('./widgets/welcome-offer').default
    // wordyWords: require('./widgets/wordy-words').default  WordyWords don't need to be mounted client side.
};
