import type Hls from 'hls.js';
// eslint-disable-next-line no-duplicate-imports
import type {LevelLoadedData} from 'hls.js';

import type PlaybackHls from '.';
import NativeLivestream from '../native/livestream';
import type {OnEdgeLeniencyEvent} from '../types';
import {Events} from './types';

const DAZZ_EDGE_FACTOR_MULTIPLIER = 2;

export default class HlsLivestream extends NativeLivestream {
    declare playbackHandler: Hls;
    declare playbackTech: PlaybackHls;

    constructor(playbackTech: PlaybackHls, playbackHandler: Hls) {
        super(playbackTech, playbackHandler);

        this.playbackHandler.on(Events.LEVEL_LOADED, this.setOnEdgeLeniency);
        this.playbackHandler.on(
            Events.LEVEL_LOADED,
            this.setBestGuessFragmentDuration
        );
    }

    override destroy(): void {
        super.destroy();
        this.playbackHandler.off(Events.LEVEL_LOADED, this.setOnEdgeLeniency);
        this.playbackHandler.off(
            Events.LEVEL_LOADED,
            this.setBestGuessFragmentDuration
        );
    }

    override setupLiveListeners(): void {
        this.playbackHandler.on(Events.LEVEL_LOADED, this.computeLive);
    }

    override destroyLiveListeners(): void {
        this.playbackHandler.off(Events.LEVEL_LOADED, this.computeLive);
    }

    override trySetOnEdgeLeniency: (
        _: OnEdgeLeniencyEvent[0],
        data?: OnEdgeLeniencyEvent[1]
    ) => void = (_, data) => {
        const targetSegmentDuration = data?.details.targetduration;

        if (targetSegmentDuration !== undefined) {
            this.onEdgeLeniency =
                this.playbackHandler.config.liveSyncDurationCount *
                    targetSegmentDuration + // 12 seconds behind live if fragments 4s
                targetSegmentDuration * DAZZ_EDGE_FACTOR_MULTIPLIER; // Give us nth more edge factor (for initial loading!)
        }
    };

    override tryComputeLive: (
        _: OnEdgeLeniencyEvent[0],
        data?: LevelLoadedData
    ) => void = (event, data) => {
        if (event === Events.LEVEL_LOADED) {
            const isLive = data?.details.live;

            this.triggerIsLive(!!isLive);
        }
    };

    setBestGuessFragmentDuration = (_: string, data: LevelLoadedData): void => {
        const targetDuration = data.details.targetduration;

        if (targetDuration !== undefined) {
            this.bestGuessFragmentDuration = targetDuration;
        }
    };
}
