import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

import {authorizationToken} from '../../utils/api';
import MessageComponent from '../../components/message';

export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        const username = this.usernameInput.value;
        const password = this.passwordInput.value;
        const rememberMe = true;

        this.props.login({username, password, rememberMe});
    }

    renderLoadingSpinner() {
        if (!this.props.isLoading && authorizationToken() === undefined) {
            return null;
        }

        return (
            <div className="fiso-hawk-login__spinner">
                <LoadingSpinner />
            </div>
        );
    }

    renderLoginForm() {
        const loginContainerClassNames = classNames(
            'fiso-hawk-login__container',
            {'fiso-hawk-login__container--is-loading': this.props.isLoading || authorizationToken() !== undefined}
        );

        return (
            /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
            <div className={loginContainerClassNames}>
                <h1 className="fiso-hawk-login__title">Watch {this.props.sportDetails.label} LIVE & on demand replays</h1>
                <h2 className="fiso-hawk-login__sub-title">
                    Please login to your Watch {this.props.sportDetails.label} account to access your subscription.
                </h2>
                <p className="fiso-hawk-login__copy">You must be outside Australia to view this content</p>
                {!!this.props.isLoginRequired && (
                    <MessageComponent
                        center={true}
                        type="info"
                        value="You must log in to access this content" />
                )}
                <form className="fiso-hawk-login__form" onSubmit={this.handleSubmit} method="POST">
                    <div className="fiso-hawk-login__form-field">
                        <input
                            ref={(input) => {
                                this.usernameInput = input;
                            }}
                            className="fiso-hawk-login__input"
                            name="username"
                            placeholder={`Enter your Watch ${this.props.sportDetails.label} username`}
                            autoComplete="off"
                            type="email"
                            required={true} />
                    </div>
                    <div className="fiso-hawk-login__form-field">
                        <input
                            ref={(input) => {
                                this.passwordInput = input;
                            }}
                            className="fiso-hawk-login__input"
                            placeholder={`Enter your Watch ${this.props.sportDetails.label} password`}
                            name="password"
                            autoComplete="off"
                            type="password"
                            required={true} />
                    </div>
                    <div className="fiso-hawk-login__submit-button-wrapper">
                        <button
                            className={classNames(
                                'fiso-hawk-button',
                                'fiso-hawk-button--primary',
                                'fiso-hawk-button--full-width',
                                'fiso-hawk-button--chevron-right',
                                `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`
                            )}
                            type="submit">
                            Sign In
                            <Fsvg name="next-1" />
                        </button>
                    </div>
                </form>
                <p className="fiso-hawk-login__copy fiso-hawk-login__error-message">
                    {this.props.error}
                </p>
                <p className="fiso-hawk-login__copy">
                    <a
                        className="fiso-hawk-login__forgot-password fiso-hawk-login__link"
                        href={this.props.forgotPasswordUrl}>
                        Forgot your password?
                    </a>
                </p>
                <p className="fiso-hawk-login__copy">
                    <a
                        className="fiso-hawk-login__subscribe-now fiso-hawk-login__link"
                        href={this.props.subscribeUrl}>
                        Don’t have an account? Subscribe now
                    </a>
                </p>
            </div>
        );
    }
    render() {
        return (
            <section className="fsui-normalise fiso-hawk-login">
                {this.renderLoadingSpinner()}
                {this.renderLoginForm()}
            </section>
        );
    }
}

Login.defaultProps = {
    isLoading: false,
    login: noop
};

Login.propTypes = {
    isLoading: propTypes.bool,
    error: propTypes.string,
    isLoginRequired: propTypes.bool,
    login: propTypes.func,
    forgotPasswordUrl: propTypes.string,
    subscribeUrl: propTypes.string,
    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
