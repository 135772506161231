import {DEFAULT_ERROR_PREFIX} from './constants';
import logError from './util/log-error';

/**
 * Normalise a bool or string to a bool
 * All other types will return defaultVal
 *
 * @param val         - Value to normalise (e.g. string or bool)
 * @param defaultVal  - Default value (e.g. true or false)
 * @param errorPrefix - The prefix for the error message, defaults to `Transformers`
 *
 * @returns provided value or defaultVal
 */
export default function asBool(
    val: unknown,
    defaultVal?: boolean,
    errorPrefix = DEFAULT_ERROR_PREFIX
): boolean | undefined {
    try {
        if (val === 'false' || val === false) {
            return false;
        }

        if (val === 'true' || val === true) {
            return true;
        }
    } catch (e) {
        logError(`${errorPrefix}: couldn't normalise arg asBool`, val, e);
    }

    return defaultVal;
}
