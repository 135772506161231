import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

export default class Footer extends React.Component {
    renderLink({url, name} = {}, index) {
        if (!name) {
            return null;
        }

        return (
            <li className="fiso-hawk-footer__link-wrapper" key={index}>
                <a className="fiso-hawk-footer__link" href={url} title={name}>{name}</a>
            </li>
        );
    }

    render() {
        return (
            <footer
                className={classNames(
                    'fsui-normalise',
                    'fiso-hawk-footer',
                    `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`)}>
                <ul className="fiso-hawk-footer__links">
                    {this.props.links.map(this.renderLink)}
                </ul>
            </footer>
        );
    }
}
Footer.defaultProps = {
    links: []
};

Footer.propTypes = {
    links: propTypes.arrayOf(
        propTypes.shape({
            name: propTypes.string,
            url: propTypes.string
        })
    ),
    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
