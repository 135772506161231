import React from 'react';
import styled from 'styled-components';

const LinkText = styled.div`
    margin-bottom: 21px;
    font-size: 18px;
`;

const LinkUrl = styled.a`
    color: inherit;
    font-weight: 600;
`;

type Props = {
    helpLink?: string;
};

function VideoFaqLinkWithText({helpLink}: Props): React.ReactElement {
    return (
        <LinkText>
            For further help, visit{' '}
            <LinkUrl href={helpLink} rel="noopener noreferrer" target="_blank">
                Video FAQ
            </LinkUrl>
        </LinkText>
    );
}

VideoFaqLinkWithText.displayName = 'VideoFaqLinkWithText';

export default VideoFaqLinkWithText;
