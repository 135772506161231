import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {STATS_API_URL} from '../../utils/constants';

export default function ({
    freq = 0,
    sport,
    seasonId,
    seriesId,
    statsApiKey
}) {
    const retries = (process && process.browser) ? 3 : 0;
    const url = STATS_API_URL +
        `sports/${sport}` +
        `/series/${seriesId}` +
        (seasonId ? `/seasons/${seasonId}` : '') +
        '/allteamsandrounds.json' +
       `?userkey=${statsApiKey}`;

    return getApiFromRetryWithError({
        url,
        freq,
        retries
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                const parsedResponse = response.parseResponseText();

                try {
                    // only show the last 3 years of data
                    const {seasons, ...restParsedResponse} = parsedResponse;
                    const minYear = (parsedResponse?.current_season?.year || new Date().getFullYear()) - 3;
                    const filteredSeasons = seasons.filter(({year}) => year >= minYear);

                    return {
                        ...restParsedResponse,
                        seasons: filteredSeasons,
                    };
                } catch (e) {
                    // Something went weird. Let's go back to whatever logic was higher up.
                    return parsedResponse;
                }
            }

            const errorMessage = `FS-HAWK-WIDGETS: Unable to get All Teams And Rounds (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
