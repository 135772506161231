const STEREO = 'Stereo';
const DOLBY51 = 'Dolby 5.1';
const DOLBY71 = 'Dolby 7.1';

export const AUDIO_CHANNEL_COUNT_TO_LABEL: Record<number, string> = {
    2: STEREO,
    6: DOLBY51,
    8: DOLBY71,
}

export default function getAudioTrackLabelByChannelCount(channelCount: number | undefined): string {
    return channelCount
        ? AUDIO_CHANNEL_COUNT_TO_LABEL[channelCount] ?? STEREO
        : STEREO;
}
