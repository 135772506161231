import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {mix, readableColor, rgba} from 'polished';
import React from 'react';
import styled, {type DefaultTheme} from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {coal, black, white} from '../../../../common/palette';
import {SCREEN_1280_DESKTOP} from '../../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';

const getBackgroundColor = ({theme}: {theme: DefaultTheme}): string =>
    mix(0.6, theme.brandColor, theme.brandColorVariant);

const getColor = (props: {theme: DefaultTheme}): string =>
    readableColor(THEME_ACCESSORS.brandColor(props), white, black, true);

const StyledButton = styled(Button)`
    transition: ${transition(
        'background-color',
        'color',
        'opacity',
        'text-shadow'
    )};
    border: 0;
    box-shadow: 0 1px 1px 0 ${rgba(coal, 0.3)};
    background-color: ${rgba(white, 0.2)};
    padding: 6px 17px 7px 14px;
    text-shadow: 0 1px 1px ${rgba(black, 0.3)};
    white-space: nowrap;
    color: ${white};
    font: ${gibson({size: 14, lineHeight: 1.21})};

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        padding: 10px 22px 11px 23px;
        font: ${gibson({size: 16, lineHeight: 1.19})};
    `}

    &:hover,
    &:focus {
        background-color: ${THEME_ACCESSORS.brandColor};
        text-shadow: currentColor 0 0 1px;
        color: ${getColor};
    }

    &:active {
        background-color: ${getBackgroundColor};
        text-shadow: currentColor 0 0 1px;
    }

    &[disabled] {
        opacity: 0.1;
    }
`;

export type Props = {
    /** Content of button, e.g. text */
    children: React.ReactNode;
    /** Additional classname(s) */
    className?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & React.ComponentPropsWithoutRef<'button'>;

const BA99Marker = React.forwardRef<HTMLButtonElement, Props>(
    ({children, className, ...htmlAttributes}, ref) => (
        <StyledButton
            {...htmlAttributes}
            className={classnames('BA99Marker', className)}
            ref={ref}
            type="button"
        >
            {children}
        </StyledButton>
    )
);

BA99Marker.displayName = 'BA99Marker';

export default BA99Marker;
