import {ANALYTICS_EVENT_TYPE} from '@fsa-streamotion/player-state';
import {
    stylesWhen,
    mediaQuery,
} from '@fsa-streamotion/styled-component-helpers';

import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {transition} from '../../../common/animations';
import {black, coal, white} from '../../../common/palette';
import {
    SCREEN_1024_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../common/screen-sizes';
import {THEME_ACCESSORS} from '../../../common/theme-helpers';
import {Z_INDEX_OVER_SCREENS} from '../../utils/constants';
import {usePlayerContext} from '../context';
import Overlays from './overlays';
import LowerTray from './overlays/lower-tray';
import UpperTray from './overlays/upper-tray';
import getScreenLayoutStyles, {
    TRANSFORMS_PER_PLAYER_ORDER,
} from './utils/screen-layout-styles';
import VideoScreen, {NAV_INDICATOR_BELOW_SCREEN_PX} from './video-screen';

const UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX = 768;
const UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX__LARGE = 1080;

const RelativeContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${black};
    width: 100%;
    height: 100%;
    color: ${white};
    /* Cursor could be from any internal element, it's not a cascading option. Sledge-hammer. */
    ${stylesWhen('shouldHideCursor')`
        * { cursor: none !important; }
    `}

    &:fullscreen {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`;

const MultiScreenFormation = styled.ol`
    position: relative;
    margin: auto;
    padding: 0;
    /* These styles retain a 16:9 ratio */
    width: min(100vw, 178vh); /* stylelint-disable-line csstree/validator */
    height: 100%;
    /* stylelint-disable-next-line csstree/validator */
    max-height: min(56.25vw, 100vh);
    list-style: none;

    ${stylesWhen('isUpNextMode')`
        ${mediaQuery({
            minWidthPx: SCREEN_1024_DESKTOP,
            minHeightPx: UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX,
        })`
            position: absolute;
            width: 31vh; /* small player's width divided by viewport's height; keeping design's ratio */
            height: calc(31vh * 0.56); /* Keeping 16:9 */
            top: 158px;
            left: 5vw;
            box-sizing: border-box;
            z-index: ${Z_INDEX_OVER_SCREENS + 1};
            border: 1px solid transparent;
            transition: ${transition(
                'border-color',
                'width',
                'height',
                'transform'
            )};

            &:hover,
            &:focus {
                transform: scale(1.07);

                :after {
                    opacity: 1;
                }
            }

            :after {
                opacity: 0;
                position: absolute;
                bottom: -13px;
                left: 7px;
                right: 7px;
                height: 2px;
                background-color: ${THEME_ACCESSORS.brandColor};
                content: '';
                transform: scale(0.93);
                transition: ${transition('opacity')};
            }
        `}

        ${mediaQuery({
            minWidthPx: SCREEN_1920_DESKTOP,
            minHeightPx: UP_NEXT_SMALL_VIDEO_PLAYER_MIN_HEIGHT_PX__LARGE,
        })`
            top: 240px;
        `}
    `}
    /* prevents outline from showing when focus is on the video */
    &:focus {
        outline: none;
    }
`;

// NB: A transform is applied to this component via inline styles
const LayoutItem = styled.li`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: ${transition('transform')};
    background-color: ${coal};
    /* prevents outline from showing when focus is on the video */
    &:focus {
        outline: none;
    }
`;

const LayoutComposer = (): React.ReactElement => {
    const playerState = usePlayerContext();
    const {isSingleLayout, generalConfig, globalState} = playerState ?? {};
    const {numScreens} = generalConfig ?? {};
    const {
        layoutStyle,
        screenOrder,
        isLowerTrayVisible,
        isUpperTrayVisible,
        isUpNextMode,
        isFullscreen,
        isLowerControlsVisible,
        isUpperControlsVisible,
        isStillWatchingMode,
    } = globalState ?? {};
    const layoutTransformsPerScreen =
        layoutStyle &&
        screenOrder &&
        getScreenLayoutStyles(layoutStyle, screenOrder);
    const [msfTransformStyles, setMsfTransformStyles] = useState<{
        transformOrigin: string;
        transform: string;
    } | null>(null);
    const upperTrayRef = useRef<HTMLDivElement>(null);
    const multiScreenFormationRef = useRef<HTMLOListElement>(null);
    const lowerTrayRef = useRef<HTMLDivElement>(null);

    // init refs to be used with icons that should be focused when the top or bottom tray is closed
    const upperTrayPostCloseIconFocusRef = useRef<HTMLButtonElement>(null);
    const lowerTrayPostCloseIconFocusRef = useRef<HTMLButtonElement>(null);

    useEffect(
        function computeScreenFormationTransformStyles() {
            if (isSingleLayout || !(isUpperTrayVisible || isLowerTrayVisible)) {
                if (msfTransformStyles !== null) {
                    setMsfTransformStyles(null);
                }

                return;
            }

            const upperTrayElement = upperTrayRef.current;
            const lowerTrayElement = lowerTrayRef.current;
            const msfElement = multiScreenFormationRef.current;

            let scale;
            let translateY;

            if (
                typeof upperTrayElement?.offsetTop === 'number' &&
                typeof upperTrayElement?.offsetHeight === 'number' &&
                typeof msfElement?.offsetHeight === 'number' &&
                typeof lowerTrayElement?.offsetTop === 'number'
            ) {
                const upperTrayEndY =
                    upperTrayElement.offsetTop + upperTrayElement.offsetHeight;
                const lowerTrayEndY = lowerTrayElement.offsetTop;
                const unscaledMSFStartY = msfElement?.offsetTop;
                const unscaledMSFHeight =
                    msfElement.offsetHeight + NAV_INDICATOR_BELOW_SCREEN_PX;
                const unscaledMSFAvailableHeight = Math.max(
                    0,
                    lowerTrayEndY - upperTrayEndY
                );

                scale = Math.min(
                    1,
                    unscaledMSFAvailableHeight / unscaledMSFHeight
                );
                translateY =
                    Math.max(0, upperTrayEndY - unscaledMSFStartY) *
                    (1 / scale);
            }

            if (scale === 1 && translateY === 0) {
                if (msfTransformStyles !== null) {
                    setMsfTransformStyles(null);
                }

                return;
            }

            setMsfTransformStyles({
                transformOrigin: 'top center',
                transform: `scale(${scale}) translateY(${translateY}px)`,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layoutStyle, isLowerTrayVisible, isUpperTrayVisible]
    );

    return (
        <RelativeContainer
            shouldHideCursor={
                isFullscreen &&
                !isLowerControlsVisible &&
                !isUpperControlsVisible &&
                !isLowerTrayVisible &&
                !isUpperTrayVisible &&
                !isStillWatchingMode
            }
            onMouseMove={(event: Event) =>
                playerState?.handleInteraction(event)
            }
            onClick={(event: Event) => playerState?.handleInteraction(event)}
        >
            <UpperTray
                ref={upperTrayRef}
                postCloseFocus={() =>
                    upperTrayPostCloseIconFocusRef.current?.focus()
                }
            />
            <MultiScreenFormation
                ref={multiScreenFormationRef}
                style={msfTransformStyles}
                isUpNextMode={isUpNextMode}
            >
                {typeof numScreens === 'number' &&
                    Array.from({length: numScreens}, (_, index) => (
                        <LayoutItem
                            draggable={true}
                            onDragStart={(e) => {
                                e.dataTransfer.setData(
                                    'text/plain',
                                    `${index}`
                                );

                                if (
                                    typeof playerState?.screenConfigs?.[index]
                                        ?.videoElement !== 'undefined'
                                ) {
                                    e.dataTransfer.setDragImage(
                                        playerState.screenConfigs[index]
                                            ?.videoElement as HTMLVideoElement,
                                        20,
                                        20
                                    );
                                }
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.dataTransfer.dropEffect = 'move';
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                const draggedIndex = parseInt(
                                    e.dataTransfer.getData('text/plain')
                                );

                                playerState?.globalActions?.swapScreenOrder(
                                    draggedIndex,
                                    index
                                );
                            }}
                            onClick={(event) => {
                                if (isUpNextMode) {
                                    playerState?.globalActions?.clearUpNext();
                                    playerState?.generalConfig?.onScreenInteractionByType(
                                        {
                                            type: ANALYTICS_EVENT_TYPE.UP_NEXT_DISMISS_EVENT_TYPE,
                                            screenIndex: index,
                                        }
                                    );

                                    // If the video has already reached the end, exit the player.
                                    if (
                                        playerState?.activeScreenConfig
                                            ?.videoState.isEnded
                                    ) {
                                        playerState?.globalActions?.exitPlayer();
                                    }
                                }

                                if (!playerState?.isSingleLayout) {
                                    event.stopPropagation();

                                    playerState?.globalActions?.setActiveScreenIndex(
                                        index
                                    );
                                    playerState?.globalActions?.setFocusedScreenIndex(
                                        index
                                    );
                                }
                            }}
                            onDoubleClick={() => {
                                const screenPosition = screenOrder?.[index];

                                if (!isSingleLayout && screenPosition !== 0) {
                                    const firstPositionScreenIndex =
                                        screenOrder?.findIndex(
                                            (position) => !position
                                        );

                                    if (
                                        typeof firstPositionScreenIndex ===
                                        'number'
                                    ) {
                                        playerState?.globalActions?.swapScreenOrder(
                                            index,
                                            firstPositionScreenIndex
                                        );
                                    }
                                } else if (isSingleLayout) {
                                    playerState?.globalActions?.setIsFullscreen(
                                        !isFullscreen
                                    );
                                    playerState?.globalActions?.setTrayVisibility(
                                        {upper: false, lower: false}
                                    );
                                }
                            }}
                            onMouseOver={() => {
                                playerState?.globalActions?.setFocusedScreenIndex(
                                    index
                                );
                            }}
                            onFocus={() => {
                                playerState?.globalActions?.setFocusedScreenIndex(
                                    index
                                );
                            }}
                            onMouseLeave={() => {
                                if (!playerState?.isSingleLayout) {
                                    playerState?.globalActions?.setFocusedScreenIndex(
                                        -1
                                    );
                                }
                            }}
                            onBlur={() => {
                                if (!playerState?.isSingleLayout) {
                                    playerState?.globalActions?.setFocusedScreenIndex(
                                        -1
                                    );
                                }
                            }}
                            style={layoutTransformsPerScreen?.[index]}
                            // Usually index keys are bad form, but `numPlayers` won't change for the lifecycle of the player
                            key={index}
                            tabIndex={0}
                        >
                            <VideoScreen
                                screenIndex={index}
                                scale={
                                    layoutStyle &&
                                    typeof index === 'number' &&
                                    typeof screenOrder?.[index] === 'number'
                                        ? (
                                              TRANSFORMS_PER_PLAYER_ORDER[
                                                  layoutStyle
                                              ][
                                                  screenOrder?.[index] as number
                                              ] as {
                                                  scale?: number;
                                              }
                                          )?.scale ?? 1
                                        : 1
                                }
                            />
                        </LayoutItem>
                    ))}
            </MultiScreenFormation>
            <LowerTray
                ref={lowerTrayRef}
                postCloseFocus={() =>
                    lowerTrayPostCloseIconFocusRef.current?.focus()
                }
            />
            <Overlays
                upperTrayPostCloseIconFocusRef={upperTrayPostCloseIconFocusRef}
                lowerTrayPostCloseIconFocusRef={lowerTrayPostCloseIconFocusRef}
            />
        </RelativeContainer>
    );
};

LayoutComposer.displayName = 'LayoutComposer';

export default observer(LayoutComposer);
