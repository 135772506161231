import result from 'lodash/result';

export default function getNetballSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'netball-tests':
        case '13':
            return {
                id: 13,
                label: 'Netball Tests',
                route: 'netball-tests'
            };

        case 'anz-championship':
        case '23':
            return {
                id: 23,
                label: 'ANZ Championship',
                route: 'anz-championship'
            };

        case 'netball-world-cup':
        case '24':
            return {
                id: 24,
                label: 'Netball World Cup',
                route: 'netball-world-cup'
            };

        case 'super-netball':
        case '25':
            return {
                id: 25,
                label: 'Super Netball',
                route: 'super-netball'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Netball seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
