export const PLAYBACK_RATES = [1, 0.5, 1.5, 2];

export function getNextPlaybackRate(currentPlaybackRate: number): number | undefined {
    const currentIndex = PLAYBACK_RATES.indexOf(currentPlaybackRate);
    const nextIndex = (currentIndex + 1) % PLAYBACK_RATES.length;

    return PLAYBACK_RATES[nextIndex] as number;
}

export const PLAYBACK_RATE_TO_STRING: {
    [key: number]: 'slo' | '1' | '1.5' | '2';
} = {
    0.5: 'slo',
    1: '1',
    1.5: '1.5',
    2: '2',
};
