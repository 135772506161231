const foundSourcePaths = new Map<RegExp, string>();

/**
 * Find the base path of a given script src tag using regex.
 *
 * @param srcRegex       - regular expression for finding `<script />` tags by `src`
 * @param scriptElements - array of &lt;script&gt; elements
 *
 * @returns found string match (or empty string)
 */
export function getScriptBasePath(
    srcRegex: RegExp,
    scriptElements = [...document.getElementsByTagName('script')]
): string {
    let basePath = foundSourcePaths.get(srcRegex);

    if (basePath === undefined) {
        const scriptElement = scriptElements.find((scriptElement) => {
            const src = scriptElement.getAttribute('src');

            return src && srcRegex.test(src);
        });

        basePath =
            (scriptElement
                ? scriptElement.getAttribute('src')?.match(srcRegex)?.[0]
                : '') ?? '';

        // Cache the result
        foundSourcePaths.set(srcRegex, basePath);
    }

    return basePath;
}

/**
 * Clear the getScriptBasePath results cache.
 */
export function clearScriptBasePaths(): void {
    foundSourcePaths.clear();
}
