import bacon from 'baconjs';

import getBrainTreeClient from '../../streams/braintree/get-braintree-client';
import getBrainTreeDataCollector from '../../streams/braintree/get-braintree-data-collector';
import tokenizeBrainTreeInstance from '../../streams/braintree/tokenize-braintree-instance';

export default function getPaymentFormData({
    isEnabled$, // IS A STREAM

    baseUrl,
    product
}) {
    const paymentBus = new bacon.Bus();

    // Brain Tree
    let brainTreeClientInstance$;

    if (process.browser) {
        brainTreeClientInstance$ = getBrainTreeClient({baseUrl});
    } else {
        brainTreeClientInstance$ = bacon.never();
    }

    // @TODO: upgrade braintree-web after they merge and release https://github.com/braintree/braintree-web/pull/727 (via https://github.com/braintree/braintree-web/issues/724 so some time *after* 3.109.0)
    const deviceData$ = brainTreeClientInstance$
        .flatMapLatest((client) => getBrainTreeDataCollector({
            client,
            kount: true
        }))
        .map('.deviceData');

    const hostedFields$ = paymentBus
        .combine(deviceData$, (payment, deviceData) =>  Object.assign({}, {deviceData}, payment));

    const payment$ = hostedFields$
        .flatMapLatest(({deviceData, paypalNonce, hostedFieldsInstance}) => {
            if (hostedFieldsInstance) {
                return tokenizeBrainTreeInstance({hostedFieldsInstance}).map((creditCardNonce) => ({
                    deviceData,
                    nonce: creditCardNonce,
                    paymentType: 'CC',
                    paymentMethod: 'BRAINTREE'
                }));
            } else {
                return {
                    deviceData,
                    nonce: paypalNonce,
                    paymentType: 'PayPal',
                    paymentMethod: 'BRAINTREE'
                };
            }
        });

    const paymentType$ = hostedFields$
        .map(({hostedFieldsInstance}) => {
            if (hostedFieldsInstance) {
                return 'Credit Card';
            } else {
                return 'PayPal';
            }
        });

    const isLoading$ = bacon.mergeAll(
        paymentBus.map(true),
        payment$.mapError(false).map(false)
    ).toProperty(false);

    return bacon.combineTemplate({
        isEnabled: isEnabled$.map(Boolean),
        isLoading: isLoading$,

        product,

        brainTreeClientInstance: brainTreeClientInstance$.startWith(null),

        onSubmitCreditCardSubscription: ({hostedFieldsInstance}) => {
            paymentBus.push({hostedFieldsInstance});
        },
        onSubmitPaypalSubscription: ({paypalNonce}) => {
            paymentBus.push({paypalNonce});
        },

        paymentType: paymentType$.startWith(null),
        payment: payment$.startWith(null)
    });
}
