/**
 * The possible Error Codes communicated by a PlayerTech class instance
 * @see [MDN MediaError](https://developer.mozilla.org/en-US/docs/Web/API/MediaError)
 */
export const ERROR_CODES = {
    // #region Standard errors

    /**
     * The fetching of the associated resource was aborted by the user's request.
     */
    MEDIA_ERR_ABORTED: 1,

    /**
     * Some kind of network error occurred which prevented the media from being successfully fetched, despite having previously been available.
     */
    MEDIA_ERR_NETWORK: 2,

    /**
     * Despite having previously been determined to be usable, an error occurred while trying to decode the media resource, resulting in an error.
     */
    MEDIA_ERR_DECODE: 3,

    /**
     * The associated resource or media provider object (such as a MediaStream) has been found to be unsuitable.
     */
    MEDIA_ERR_SRC_NOT_SUPPORTED: 4,

    // #endregion

    // #region Custom errors

    /**
     * Custom error: Unknown
     */
    CUSTOM_ERR_UNKNOWN: 0,

    /**
     * Custom error: Any DRM problem
     */
    MEDIA_ERR_ENCRYPTED: 5,

    /**
     * Custom error: Geoblocked
     */
    CUSTOM_ERR_GEOBLOCK: -1,

    // #endregion
} as const;

/* eslint-disable max-len */

/**
 * Maps each of our ERROR_CODES to a human-readable message
 */
export const DEFAULT_ERROR_MESSAGES = {
    [ERROR_CODES.CUSTOM_ERR_GEOBLOCK]:
        'The media is not available in your current region.',
    [ERROR_CODES.CUSTOM_ERR_UNKNOWN]:
        'An unknown error occurred in media playback.',

    [ERROR_CODES.MEDIA_ERR_ABORTED]: 'You aborted the media playback.',
    [ERROR_CODES.MEDIA_ERR_NETWORK]:
        'A network error caused the media download to fail part-way.',
    [ERROR_CODES.MEDIA_ERR_DECODE]:
        'The media could not be loaded, either because the server or network failed or because the format is not supported.',
    [ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED]:
        'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.',
    [ERROR_CODES.MEDIA_ERR_ENCRYPTED]:
        'The media is encrypted and we do not have the keys to decrypt it.',
} as const;

/* eslint-enable max-len */
