export default class HbbTvAdapter {
  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this.player);
    } catch (err) {
      return true;
    }
  }

  getPlayerName() {
    return 'Hbbtv';
  }

  registerListeners() {
    this.states = ['STOPPED', 'PLAYING', 'PAUSED', 'CONNECTING', 'BUFFERING', 'FINISHED', 'ERROR'];
    this.monitorPlayhead(false, true);
    if (this.player) {
      this.player.onPlayStateChange = function(e) {
        this.playStateChangeListener();
      }.bind(this);
    } else {
      Log.error('The player reference set to the adapter is invalid.');
    }
  }

  getResource() {
    return this.player.data || this.player.src;
  }

  playStateChangeListener() {
    this.firePlayerLog('stateValue' + this.player.playState, {});
    switch (this.player.playState) {
      case 0:
        this.firePlayerLog('stopState', {});
        this.fireStop({}, 'stopState');
        break;
      case 1:
        if (this.bufferPlayhead && Math.abs(this.bufferPlayhead - this.player.playPosition) > 10) {
          this.fireSeekBegin({}, true, 'playingState');
          this.bufferPlayhead = undefined;
        }
        this.firePlayerLog('playingState', {});
        this.fireResume({}, 'playingState');
        this.fireSeekEnd({}, 'playingState');
        this.fireBufferEnd({}, 'playingState');
        this.fireJoin({}, 'playingState');
        break;
      case 2:
        this.firePlayerLog('pausedState', {});
        this.firePause({}, 'pausedState');
        break;
      case 3:
        this.firePlayerLog('connectionState', {});
        this.fireStart({}, 'connectionState');
        break;
      case 4:
        this.bufferPlayhead = this.player.playPosition;
        this.firePlayerLog('bufferingState', {});
        this.fireStart({}, 'bufferingState');
        this.fireBufferBegin({}, false, 'bufferingState');
        break;
      case 5:
        this.firePlayerLog('finishedState', {});
        this.fireStop({}, 'finishedState');
        break;
      case 6:
        this.firePlayerLog('errorState', {});
        this.fireError('FAILURE', 'FAILURE', null, null, 'errorState');
        break;
    }
  }

  getPlayhead() {
    return this.player.playPosition / 1000;
  }

  getDuration() {
    return this.player.playTime / 1000;
  }

  getVersion() {
    return '7.0.3-hbbtvhtml5-js';
  }
}
