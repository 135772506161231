import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div<{
    colorMiddle?: string;
    colorLeft: string;
    colorRight: string;
    colorLeftEndInPercentage: number;
}>`
    background: linear-gradient(
        to right,
        ${({colorLeft}) => colorLeft} 0%,
        ${getMiddleColorGradient} ${({colorRight}) => colorRight} 100%
    );
`;

export type Props = {
    /** Additional classname(s) */
    className?: string;
    /** The left most colour on the gradient */
    colorLeft?: string;
    /** The right most colour on the gradient */
    colorRight?: string;
    /** The middle colour on the gradient */
    colorMiddle?: string;
    /** The width of the this graident, if none is set, defaults to 100% */
    widthPx?: number;
    /** The height of the this graident, if none is set, defaults to 100% */
    heightPx?: number;
    /** This controls where the left most colour ends on the gradient in percentage */
    colorLeftEndInPercentage?: number;
} & React.ComponentPropsWithoutRef<'div'>;

const GA25CaGrad = ({
    className,
    colorLeft = 'transparent',
    colorRight = 'transparent',
    colorMiddle,
    heightPx,
    widthPx,
    colorLeftEndInPercentage = 0,
    ...htmlAttributes
}: Props): React.ReactElement => {
    const inlineStyles = {
        width: widthPx ? `${widthPx}px` : '100%',
        height: heightPx ? `${heightPx}px` : '100%',
    };

    return (
        <StyledDiv
            {...htmlAttributes}
            style={inlineStyles}
            className={classnames('GA25CaGrad', className)}
            colorLeft={colorLeft}
            colorRight={colorRight}
            colorMiddle={colorMiddle}
            colorLeftEndInPercentage={colorLeftEndInPercentage}
        />
    );
};

GA25CaGrad.displayName = 'GA25CaGrad';

function getMiddleColorGradient({
    colorMiddle,
    colorLeftEndInPercentage,
}: {
    colorMiddle?: string;
    colorLeftEndInPercentage: number;
}): string {
    if (!colorMiddle) {
        return '';
    }

    return `${colorMiddle} ${colorLeftEndInPercentage}%,`;
}

export default GA25CaGrad;
