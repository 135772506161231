import superagent from 'superagent';

import {MEDIA_OTT_URL} from '../../utils/constants';
import {streamFromApiWithRetry} from '../../utils/api';

export default function getSeasonConfig({sport}) {
    const url = `${MEDIA_OTT_URL}/${sport}/season/config.json`;
    const apiCall = () => superagent.get(url);

    return streamFromApiWithRetry({apiCall}).map('.body');
}
