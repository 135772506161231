import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC72Min = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M67.439 24.496L42.243 24.496 42.243 0 50.628 0 50.628 16.112 67.439 16.112zM25.195 24.496L0 24.496 0 16.112 16.811 16.112 16.811 0 25.195 0zM50.628 67.033L42.243 67.033 42.243 42.537 67.439 42.537 67.439 50.921 50.628 50.921zM25.195 67.033L16.811 67.033 16.811 50.921 0 50.921 0 42.537 25.195 42.537z"
            transform="translate(16 16)"
        />
    </Ic>
);

IC72Min.displayName = 'IC72Min';

export default IC72Min;
