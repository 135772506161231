import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;
const IC07Pau = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M0 0H8.384V89.434H0zM25.586 0H33.97V89.434H25.586z"
            transform="translate(32.034 5)"
        />
    </Ic>
);

IC07Pau.displayName = 'IC07Pau';

export default IC07Pau;
