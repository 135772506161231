import React from 'react';
import {CSSTransition} from 'react-transition-group';
import type {CSSTransitionProps} from 'react-transition-group/CSSTransition';

import {TRANSITION_CLASSNAMES, TRANSITION_DURATION_MS} from './animations';

export type Props = Omit<CSSTransitionProps, 'addEndListener'>;

const CommonTransition: React.FC<Props> = ({
    in: isVisible,
    onEnter,
    onEntering,
    onEntered,
    onExit,
    onExiting,
    onExited,
    children,
}) => (
    <CSSTransition
        in={isVisible}
        timeout={TRANSITION_DURATION_MS}
        unmountOnExit={true}
        mountOnEnter={true}
        classNames={TRANSITION_CLASSNAMES}
        appear={true}
        onEnter={onEnter}
        onEntering={onEntering}
        onEntered={onEntered}
        onExit={onExit}
        onExiting={onExiting}
        onExited={onExited}
    >
        {children}
    </CSSTransition>
);

CommonTransition.displayName = 'CommonTransition';

export default CommonTransition;
