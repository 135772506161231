import result from 'lodash/result';

export default function getGolfSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'the-masters':
        case '156':
            return {
                id: 156,
                label: 'The Masters',
                route: 'the-masters'
            };

        case 'us-open':
        case '157':
            return {
                id: 157,
                label: 'US Open',
                route: 'us-open'
            };

        case 'british-open':
        case '158':
            return {
                id: 158,
                label: 'Open Championship',
                route: 'british-open'
            };

        case 'pga-championship':
        case '159':
            return {
                id: 159,
                label: 'PGA Championship',
                route: 'pga-championship'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Golf seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
