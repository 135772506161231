import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

import FormInputComponent from '../components/form-input';

export default class UpdatePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onUpdatePassword({
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword
        });
    }

    changeField(fieldName, fieldValue) {
        this.setState({[`${fieldName}`]: fieldValue});
    }

    renderSuccess() {
        if (!this.props.isSuccess) {
            return null;
        }

        const profileLinkClass = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            'fiso-hawk-button--chevron-left',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        return (
            <div>
                <div role="alert" aria-live="assertive" className="fiso-hawk-update-password__message">
                    <Fsvg name="tick" />
                    <p className="fiso-hawk-update-password__message-copy">
                        Your password has been updated
                    </p>
                </div>
                <div className="fiso-hawk-update-password__row">
                    <div className="fiso-hawk-update-password__col">
                        <a
                            href={this.props.profilePageLink}
                            title="Profile Page"
                            className={profileLinkClass}>
                            Go Back
                            <Fsvg name="back-1" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderError() {
        if (!this.props.updatePasswordError || this.props.isSuccess || this.props.isLoading) {
            return null;
        }

        return (
            <div
                role="alert"
                aria-live="assertive"
                className="fiso-hawk-update-password__message fiso-hawk-update-password__message--fail">
                <Fsvg name="cross" />
                <p className="fiso-hawk-update-password__message-copy">
                    {this.props.updatePasswordError.message}
                </p>
            </div>
        );
    }

    renderForm() {
        if (this.props.isSuccess || this.props.isLoading) {
            return null;
        }

        const buttonClass = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost'
        );

        const submitButtonClass = classNames(
            buttonClass,
            'fiso-hawk-button--chevron-right',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        const cancelButtonClass = classNames(
            buttonClass,
            'fiso-hawk-button--chevron-left',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                <div className="fiso-hawk-update-password__row">
                    <div className="fiso-hawk-update-password__col">
                        <FormInputComponent
                            type="password"
                            name="oldPassword"
                            required={true}
                            title="Current Password"
                            handleChange={this.changeField.bind(this)} />
                    </div>
                </div>
                <div className="fiso-hawk-update-password__row">
                    <div className="fiso-hawk-update-password__col">
                        <FormInputComponent
                            type="password"
                            name="newPassword"
                            required={true}
                            title="New Password"
                            handleChange={this.changeField.bind(this)} />
                    </div>
                    <div className="fiso-hawk-update-password__col">
                        <FormInputComponent
                            type="password"
                            name="confirmNewPassword"
                            required={true}
                            title="Confirm New Password"
                            pattern={this.state.newPassword}
                            handleChange={this.changeField.bind(this)} />
                    </div>
                </div>
                <div className="fiso-hawk-update-password__row">
                    <div className="fiso-hawk-update-password__col">
                        <a
                            href={this.props.profilePageLink}
                            title="Cancel and go to profile page"
                            className={cancelButtonClass}>
                            Go Back
                            <Fsvg name="back-1" />
                        </a>
                    </div>
                    <div className="fiso-hawk-update-password__col">
                        <button
                            type="submit"
                            className={submitButtonClass}>
                            Save Updates
                            <Fsvg name="next-1" />
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return (
            <div className="fsui-normalise fiso-hawk-update-password">
                <h1 className="fiso-hawk-update-password__heading">Update Password</h1>
                {this.props.isLoading ? <LoadingSpinner /> : null}
                {this.renderSuccess()}
                {this.renderError()}
                {this.renderForm()}
            </div>
        );
    }
}

UpdatePassword.defaultProps = {
    updatePassword: noop
};

UpdatePassword.propTypes = {
    sportDetails: propTypes.shape({
        route: propTypes.string
    }),
    updatePasswordError: propTypes.object,
    profilePageLink: propTypes.string,

    isLoading: propTypes.bool,
    isSuccess: propTypes.bool,

    onUpdatePassword: propTypes.func
};
