import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import classnames from 'classnames';

export default class FormInput extends React.Component {
    requiredOrNot() {
        return (this.props.required ? '' : ' (optional)');
    }

    render() {
        const titleClasses = classnames(
            'fiso-hawk-form-input__label',
            {'fiso-hawk-form-input__label--error': this.props.errorState}
        );
        const inputClasses = classnames(
            'fiso-hawk-form-input__input',
            {'fiso-hawk-form-input__input--error': this.props.errorState}
        );

        return (
            <label className="fiso-hawk-form-input">
                <span className={titleClasses}>
                    {this.props.title}{this.requiredOrNot()}
                </span>

                <input
                    {...omit(this.props, ['handleChange', 'errorState'])}
                    ref={(input) => { this.input = input; }}
                    className={inputClasses}
                    onChange={(e) => { this.props.handleChange(e.target.name, e.target.value); }} />
            </label>
        );
    }
}

FormInput.defaultProps = {
    handleChange: noop,
    type: 'text',
    required: true,
    errorState: false
};

FormInput.propTypes = {
    handleChange: propTypes.func,
    name: propTypes.string,  // eslint-disable-line react/no-unused-prop-types
    value: propTypes.string, // eslint-disable-line react/no-unused-prop-types
    title: propTypes.string,
    type: propTypes.string, // eslint-disable-line react/no-unused-prop-types
    required: propTypes.bool,
    errorState: propTypes.bool
};
