import type superagent from 'superagent';

type ConstructorArgs = {
    err: unknown;
    response?: superagent.Response;
    requestFormat?: string;
    requestUrl: string;
};

export default class Response {
    readonly requestFormat: string;
    readonly requestUrl?: string;

    readonly responseConsideredValid: boolean;
    readonly responseOk: boolean;
    readonly responseStatus: number;
    readonly responseText?: string;

    readonly superAgentErr: unknown;
    readonly superAgentResponse?: superagent.Response;

    #responseParsed?: unknown;

    constructor({
        err,
        response,
        requestFormat = 'json',
        requestUrl,
    }: ConstructorArgs) {
        this.superAgentErr = err;
        this.superAgentResponse = response;
        this.requestFormat = requestFormat;

        this.requestUrl = requestUrl;

        this.responseOk = this.superAgentResponse?.ok ?? false; // give default `false` value in case `response` is undefined.
        this.responseStatus = this.superAgentResponse?.status ?? 0; // remove 'undefined' from status code error messages. Happens on cors/timeouts.
        this.responseText = this.superAgentResponse?.text;

        // responseConsideredValid means no superAgentErr, responseOk, and somewhere between 200 && 399 status codes
        this.responseConsideredValid =
            this.superAgentErr === null &&
            this.responseOk &&
            this.responseStatus >= 200 &&
            this.responseStatus < 400;
    }

    parseResponseText(): unknown {
        let parsedResponseText = this.responseText;

        if (!parsedResponseText) {
            throw `Unable to parse (${this.requestUrl}): ${parsedResponseText}`;
        } else if (this.requestFormat === 'json') {
            try {
                parsedResponseText = JSON.parse(parsedResponseText);
            } catch (e) {
                throw `Unable to parse (${this.requestUrl}): ${parsedResponseText}`;
            }
        }

        this.#responseParsed = parsedResponseText;

        return parsedResponseText;
    }

    get responseParsed(): unknown {
        return this.#responseParsed;
    }
}
