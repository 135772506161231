import result from 'lodash/result';

export default function getAflSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'afl':
        case '1':
            return {
                id: 1,
                label: 'AFL',
                route: 'afl'
            };

        case 'pre-season':
        case '2':
            return {
                id: 2,
                label: 'Pre-season',
                route: 'pre-season'
            };

        case 'aflw':
        case '4':
            return {
                id: 4,
                label: 'AFL Women\'s',
                route: 'aflw'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised AFL seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
