import React from 'react';
import propTypes from 'prop-types';

const AlertNotice = ({
    headerText,
    bodyHtml
}) => {
    if (!headerText && !bodyHtml) {
        return null;
    }

    return (
        <div role="alert" className="fiso-hawk-alert-notice">
            {!!headerText && (
                <div className="fiso-hawk-alert-notice__header">
                    {headerText}
                </div>
            )}

            {!!bodyHtml && (
                <div
                    className="fiso-hawk-alert-notice__body"
                    dangerouslySetInnerHTML={{__html: bodyHtml}} />
            )}
        </div>
    );
};

AlertNotice.propTypes = {
    headerText: propTypes.string,
    bodyHtml: propTypes.string
};

export default AlertNotice;
