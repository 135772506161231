import {
    getLocalStorageValue,
    isMsEdgeChromium,
    isWindows,
} from '@fsa-streamotion/browser-utils';

import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import result from 'lodash/result';
import type RxPlayer from 'rx-player';
import type {IKeySystemOption, ILoadVideoOptions, IManifestLoader, IPeriod, IPeriodChangeEvent} from 'rx-player/types';

import type {BreakToRemove, KeySystemConfig} from '../../types';
import {BITRATE_STORAGE_KEY} from '../../utils/storage-keys';
import PlaybackNative from '../native';
import type {CanPlaySourceParams, KeySystemsOption} from '../types';
import {loadLibrary, triggerCustomEvent, wrapCustomEventListener} from '../../utils/browser';
import RxPlayerAudio from './audio';
import RxPlayerBuffer from './buffer';
import RxPlayerCaptions from './captions';
import {DEFAULT_RXPLAYER_CONFIG} from './constants';
import RxPlayerCustomBitrate from './custom-bitrate';
import RxPlayerDiagnostics from './diagnostics';
import RxPlayerEme from './eme';
import RxPlayerError from './error';
import RxPlayerLivestream from './livestream';
import type {RxPlayerConfig} from './types';

const fileExtensionCheck = /\.mpd($|\?|;)/i;
const PLAYREADY_KEYSYSTEM_KEY = 'com.microsoft.playready';
// some videos may stall when seeking to the end of the video.
// This stall issue exists on the web and most CTV platforms, except for Xbox.
// Add this offset to avoid seeking to the end of the video
const END_SEEK_OFFSET = 1;

const DEFAULT_START_AT = 0.1;

export default class PlaybackRx extends PlaybackNative {
    rxPlayerInstance: RxPlayer | null = null;
    _duration = 0;
    _isPaused = true;
    _segmentRetryTimes = 0;
    _rxPlayerConfig: RxPlayerConfig | null = null;
    _hasFireFirstPlay = false;
    _isPlayerReadyToPlay = false;
    _hasPlayAlreadyBeenCalled = false;
    _intermittentStallDetector = -1;
    _periodChangeDetector = -1;
    _currentPeriod: IPeriodChangeEvent | null = null;
    _loadVideoConfig: ILoadVideoOptions = {
        transport: 'dash',
        autoPlay: false,
        textTrackMode: 'html',
    };

    declare controllerLivestreamInstance: RxPlayerLivestream;
    declare controllerAudioInstance: RxPlayerAudio;
    declare controllerBufferInstance: RxPlayerBuffer;
    declare controllerCaptionsInstance: RxPlayerCaptions;
    declare controllerCustomBitrateInstance: RxPlayerCustomBitrate;
    declare controllerError: RxPlayerError;
    declare controllerDiagnostics: RxPlayerDiagnostics;

    /**
     * #breaksToRemove - all breaks need to be removed
     */
    #breaksToRemove: BreakToRemove[] = [];
    override async removeBreaks(breaksToRemove: BreakToRemove[], timeToRestore: number): Promise<void> {
        this.#breaksToRemove.push(...breaksToRemove);
        this.rxPlayerInstance?.loadVideo({...this._loadVideoConfig, autoPlay: true, startAt: {position: timeToRestore}});
    }

    static override async canPlaySource({
        src,
        type,
        keySystems,
    }: CanPlaySourceParams): Promise<CanPlayTypeResult> {
        const {MediaSource, WebKitMediaSource} = window || {};
        // We need to have MediaSource available in the browser.
        const hasDrmSupportOrIsUnnecessary =
            !keySystems ||
            (await PlaybackRx.getAvailableKeySystems(keySystems)).length > 0;

        // MediaSource is an object on PS4 (Webmaf 3) but a function on other devices
        if (
            (typeof (MediaSource || WebKitMediaSource) === 'function' ||
                typeof MediaSource === 'object') &&
            hasDrmSupportOrIsUnnecessary
        ) {
            if (result(type, 'toLowerCase') === 'application/dash+xml') {
                // Mime Type suggests dash, so we cool.
                return 'probably';
            } else if (fileExtensionCheck.test(src)) {
                // Educated guess with suggested file format being dash.
                return 'probably';
            } else {
                // Neither of these things, so... lets go with no?
                return '';
            }
        }

        return '';
    }

    static override async getAvailableKeySystems(
        keySystems: KeySystemsOption = {}
    ): Promise<MediaKeySystemAccess[]> {
        // PS4 doesn't support window.navigator.requestMediaKeySystemAccess
        // so we'll do a check to make sure it exists before calling it
        // eslint-disable-next-line compat/compat
        if (!window.navigator.requestMediaKeySystemAccess) {
            return [];
        }

        // Workaround for VPW-180 Edge dropping frames. Cause was Playready DRM, fix was to temporarily force it to use something else.
        // TODO revisit this on 4K feature story since we'll need Playready back.
        // Full context in https://foxsportsau.atlassian.net/browse/VPW-180
        const adjustedKeySystems =
            isMsEdgeChromium() && isWindows()
                ? omit(keySystems, [PLAYREADY_KEYSYSTEM_KEY])
                : keySystems;

        const requestMediaKeySystemAccessPromises = Object.entries(
            adjustedKeySystems
        ).map(([keySystemName, keySystemConfig]) => {
            const config = PlaybackRx.getCapabilityConfig(keySystemConfig);

            // eslint-disable-next-line compat/compat
            return window.navigator
                .requestMediaKeySystemAccess(keySystemName, config)
                .catch(() => null);
        });

        return await Promise.all(requestMediaKeySystemAccessPromises).then(
            (keySystemAccesses) =>
                keySystemAccesses.filter(Boolean) as MediaKeySystemAccess[]
        );
    }

    static getCapabilityConfig({
        videoContentType,
        audioContentType,
        videoRobustness,
        audioRobustness,
        ...rest
    }: KeySystemConfig): MediaKeySystemConfiguration[] {
        const strictSupportedConfigurations = {
            ...(audioContentType && {
                audioCapabilities: [
                    {
                        contentType: audioContentType,
                        robustness: audioRobustness || '',
                    },
                ],
            }),
            ...(videoContentType && {
                videoCapabilities: [
                    {
                        contentType: videoContentType,
                        robustness: videoRobustness || '',
                    },
                ],
            }),
            ...pick(rest, ['persistentState', 'distinctiveIdentifier']),
        };

        const looseSupportedConfigurations = {
            ...(audioContentType && {
                audioCapabilities: [
                    {
                        contentType: audioContentType,
                        robustness: audioRobustness || '',
                    },
                ],
            }),
            ...(videoContentType && {
                videoCapabilities: [
                    {
                        contentType: videoContentType,
                        robustness: videoRobustness || '',
                    },
                ],
            }),
            ...pick(rest, ['distinctiveIdentifier']),
        };

        return [strictSupportedConfigurations, looseSupportedConfigurations];
    }

    override get isPlaying(): boolean {
        if (!this.rxPlayerInstance) {
            return false;
        }

        return !this.rxPlayerInstance.isPaused();
    }

    override pause(): void {
        this._isPaused = true; // Store this state so we know what to do after period switching (for PS5)
        super.pause();
    }

    override play(): void {
        // prevent call play before player ready to play
        if (!this._hasFireFirstPlay && !this._isPlayerReadyToPlay) {
            this._hasPlayAlreadyBeenCalled = true;

            return;
        }

        if (this.rxPlayerInstance) {
            this.preloadAuto(); // Make sure we always have a stream ready.
            this.rxPlayerInstance.play();
        }

        this._isPaused = false; // Store this state so we know what to do after period switching (for PS5)
        this._hasFireFirstPlay = true;
    }

    /**
     * Get the RxPlayer config, loading it if it hasn't already been loaded.
     *
     * This is a combination of the default RxPlayer config and, if provided, the
     * external RxPlayer config.
     *
     * @returns the combined RxPlayer config
     */
    private async _getRxPlayerConfig(): Promise<RxPlayerConfig> {
        if (!this._rxPlayerConfig) {
            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Default RxPlayer config:\n\n${JSON.stringify(
                    DEFAULT_RXPLAYER_CONFIG,
                    null,
                    2
                )}`
            );

            const externalRxPlayerConfig =
                await this.options.getRxPlayerConfig?.();

            if (externalRxPlayerConfig || window.rxPlayerConfig) {
                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] External RxPlayer config:\n\n${JSON.stringify(
                        externalRxPlayerConfig,
                        null,
                        2
                    )}`
                );

                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] Window RxPlayer config:\n\n${JSON.stringify(
                        window.rxPlayerConfig,
                        null,
                        2
                    )}`
                );

                // Merge default and provided rx-player configs, preferring provided rx-player config values where there's a conflict.
                this._rxPlayerConfig = merge(
                    {},
                    DEFAULT_RXPLAYER_CONFIG,
                    externalRxPlayerConfig,
                    window.rxPlayerConfig
                );
            } else {
                this._rxPlayerConfig = DEFAULT_RXPLAYER_CONFIG;
            }

            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Effective RxPlayer config:\n\n${JSON.stringify(
                    this._rxPlayerConfig,
                    null,
                    2
                )}`
            );
        }

        return this._rxPlayerConfig;
    }

    private _manifestLoader: IManifestLoader = (info, {resolve, reject}): void => {
        if (info.url) {
            fetch(info.url)
                .then((response) => response.text())
                .then((manifestText) => {
                    const manifest = new DOMParser().parseFromString(manifestText, "application/xml");
                    const periods = manifest.querySelectorAll("Period");
                    const clipIdsToRemove = this.#breaksToRemove.flatMap(
                        ({breakClipIds}) => breakClipIds
                    );

                    periods.forEach((period) => {
                        const periodId = period.getAttribute('id');

                        if (clipIdsToRemove.includes(periodId || '')) {
                            period?.parentNode?.removeChild(period)
                        }
                    });
                    resolve({
                        data: manifest
                    })
                })
                .catch(reject);
        } else {
            reject(new Error('Not a valid url'));
        }
    }

    private playWhenReady = (state: string): void => {
        if (!this.rxPlayerInstance || state !== 'LOADED') {
            return;
        }

        this.rxPlayerInstance.removeEventListener(
            'playerStateChange',
            this.playWhenReady
        );

        this._isPlayerReadyToPlay = true;

        const isLive = this.rxPlayerInstance.isLive();
        const dvrStartTime = this.rxPlayerInstance.getMinimumPosition() || 0;

        if (this.options.startAt === -1) {
            if (!isLive) {
                this.rxPlayerInstance.seekTo({
                    position: dvrStartTime + DEFAULT_START_AT,
                });
            }
        } else {
            this.rxPlayerInstance.seekTo({
                position: dvrStartTime + this.options.startAt,
            });
        }

        // if we prevented the play request before
        // or we should auto play
        // then call play method now
        if (this._hasPlayAlreadyBeenCalled || this.options.autoPlay) {
            this.play();
        }

        this._startIntermittentStallDetect();
    };

    private _reloadPlayer(): void {
        if (
            !this.rxPlayerInstance ||
            this.rxPlayerInstance.state === 'RELOADING'
        ) {
            return;
        }

        this.rxPlayerInstance.reload({
            reloadAt: {
                relative: 1,
            },
            autoPlay: true,
        });
    }

    private _startIntermittentStallDetect(): void {
        const STALL_DETECT_INTERVAL = 3000;
        const BUFFERING_TIMEOUT = 10_000;

        let previousTime = -1;
        let bufferingStartTime = -1;

        window.clearInterval(this._intermittentStallDetector);

        const seekForwardALittle = (): void => {
            const STALL_FIX_SEEK_OFFSET = 0.5;

            previousTime += STALL_FIX_SEEK_OFFSET;

            this.currentTime = previousTime;
        };

        this._intermittentStallDetector = window.setInterval(() => {
            if (!this._hasFireFirstPlay) {
                return;
            }

            const {currentTime} = this;

            if (
                currentTime <= 0 ||
                (previousTime === currentTime && !this.videoElement?.paused)
            ) {
                if (!this.rxPlayerInstance) {
                    return;
                }

                const currentPlayerState = this.rxPlayerInstance.state;

                if (currentPlayerState === 'FREEZING') {
                    seekForwardALittle();
                } else if (currentPlayerState === 'BUFFERING') {
                    if (bufferingStartTime === -1) {
                        bufferingStartTime = Date.now();
                    } else if (
                        Date.now() - bufferingStartTime >
                        BUFFERING_TIMEOUT
                    ) {
                        // buffering takes too many time than expected
                        // seems skip forward cant fix the issues
                        // try reload content to see if playback could recover
                        this._reloadPlayer();

                        bufferingStartTime = -1;
                    }
                }
            } else {
                previousTime = this.currentTime;
                bufferingStartTime = -1;
            }
        }, STALL_DETECT_INTERVAL);
    }

    private triggerPeriodChangeEvents = (event: CustomEvent<IPeriodChangeEvent>): void => {
        const currentPeriod = event.detail;
        const availablePeriods = this.rxPlayerInstance?.getAvailablePeriods();
        const currentPeriodIndex = availablePeriods?.findIndex(({ id }) => id === currentPeriod.id) || 0;
        const lastPeriod = availablePeriods?.[currentPeriodIndex - 1];

        triggerCustomEvent(this.videoElement, 'fs-period-switch-started', {
            periodInfo: {
                fromStreamInfo: {
                    id: lastPeriod?.id,
                },
                toStreamInfo: {
                    id: currentPeriod?.id,
                    start: currentPeriod?.start,
                },
            },
        });

        triggerCustomEvent(this.videoElement, 'fs-period-switch-completed', {
            periodInfo: {
                toStreamInfo: {
                    id: currentPeriod?.id,
                    start: currentPeriod?.start,
                },
            },
        });
    }

    private setupWorker(workerUrl?: string): Promise<void> {
        const playerInstance = this.rxPlayerInstance;
        const {MULTI_THREAD} = window.RxPlayer?.Features || {};

        return new Promise((resolve, reject) => {
            if (!workerUrl || !playerInstance || !MULTI_THREAD) {
                reject(
                    'No specified worker url or feature module, cancel to use worker'
                );

                return;
            }

            const xhrRequest = new XMLHttpRequest();

            xhrRequest.open('GET', workerUrl);

            xhrRequest.onload = () => {
                const {status, response} = xhrRequest;

                if (status && status >= 200 && status < 400) {
                    const workerBlob = new Blob([response], {
                        type: 'application/javascript',
                    });

                    window.RxPlayer?.addFeatures([MULTI_THREAD]);

                    playerInstance
                        .attachWorker({workerUrl: workerBlob})
                        .then(resolve)
                        .catch(() => {
                            reject('Failed to attach work');
                        });
                } else {
                    reject('Failed to download worker file');
                }
            };

            xhrRequest.onerror = () => {
                reject('Failed to download worker file');
            };

            xhrRequest.send();
        });
    }

    override async setup(): Promise<boolean> {
        const {
            script,
            workerUrl,
            loadVideoSettings,
            constructorSettings,
            keySystemSettings,
        } = await this._getRxPlayerConfig();

        let rxPlayerLibrary: RxPlayerWithFeatures | undefined;

        if (script?.src && script.integrity) {
            await loadLibrary({
                src: script.src,
                integrity: script.integrity,
                loadedLibrary: window.RxPlayer,
                id: 'smweb-playertech-rxplayer-script',
            });

            rxPlayerLibrary = window.RxPlayer;
        }

        if (!rxPlayerLibrary) {
            throw 'PlayerTech: RxPlayer was requested, but no suitable library was found.';
        }

        rxPlayerLibrary.LogLevel = this.options.DEBUG_LIBS ? 'DEBUG' : 'ERROR';

        if (!this.videoElement) {
            return false;
        }

        const initialBitrate = getLocalStorageValue({
            key: BITRATE_STORAGE_KEY,
            defaultValue: undefined,
        }) as number | undefined;

        // eslint-disable-next-line new-cap
        this.rxPlayerInstance = new rxPlayerLibrary({
            videoElement: this.videoElement,
            baseBandwidth: initialBitrate,
            ...constructorSettings,
        });

        try {
            await this.setupWorker(workerUrl);
        } catch (e) {
            console.warn(e);
        }

        this.controllerEmeInstance = new RxPlayerEme({
            playbackTech: this,
            playbackHandler: this.rxPlayerInstance,
        });

        let keySystemOption: IKeySystemOption[] = [];

        if (this.keySystems) {
            keySystemOption = Object.entries(this.keySystems).map(
                ([keySystemName, keySystemConfig]) => {
                    const [capabilities] =
                        PlaybackRx.getCapabilityConfig(keySystemConfig);

                    return {
                        type: keySystemName,
                        getLicense: async (
                            message: Uint8Array,
                            messageType: string
                        ) =>
                            await (
                                this.controllerEmeInstance as RxPlayerEme
                            )?.makeLicenseCall({
                                keySystemName,
                                keySystemConfig,
                                message,
                                messageType,
                            }),
                        videoCapabilitiesConfig: capabilities && {
                            type: 'full',
                            value: capabilities.videoCapabilities ?? [],
                        },
                        audioCapabilitiesConfig: capabilities && {
                            type: 'full',
                            value: capabilities.audioCapabilities ?? [],
                        },
                        ...keySystemSettings,
                    };
                }
            );
        }

        this.rxPlayerInstance.addEventListener(
            'playerStateChange',
            this.playWhenReady
        );

        this._loadVideoConfig = {
            ...loadVideoSettings,
            ...this._loadVideoConfig,
            url: this.src || '',
            keySystems: keySystemOption,
            textTrackElement: this.options.rxPlayerCaptionContainerDiv,
        }

        if (this.options.enableRemoveBreakInRxPlayer) {
            this._loadVideoConfig.manifestLoader = this._manifestLoader;
            this.rxPlayerInstance.videoElement?.addEventListener(
                'fs-period-change',
                wrapCustomEventListener<IPeriodChangeEvent>(this.triggerPeriodChangeEvents)
            );
        }

        this.rxPlayerInstance.loadVideo(this._loadVideoConfig);

        this.controllerBufferInstance = new RxPlayerBuffer(
            this,
            this.rxPlayerInstance
        );

        this.controllerAudioInstance = new RxPlayerAudio(
            this,
            this.rxPlayerInstance
        );
        this.controllerAudioInstance.setup();

        this.controllerDiagnostics = new RxPlayerDiagnostics(
            this.rxPlayerInstance,
            this.videoElement,
            this.src ?? '',
            this.cdnProvider,
            this.hasSsai
        );

        this.controllerCaptionsInstance = new RxPlayerCaptions(
            this,
            this.rxPlayerInstance
        );

        this.controllerCaptionsInstance.setup();
        this.controllerCaptionsInstance.setupPiPListeners();

        this.controllerCustomBitrateInstance = new RxPlayerCustomBitrate(
            this,
            this.rxPlayerInstance
        );

        this.controllerLivestreamInstance = new RxPlayerLivestream(
            this,
            this.rxPlayerInstance
        );

        this.controllerError = new RxPlayerError(this, this.rxPlayerInstance);

        const PERIOD_CHANGE_DETECT_INETRVAL = 1000;

        this._periodChangeDetector = window.setInterval(() => {
            if (!this.rxPlayerInstance) {
                this._currentPeriod = null;

                return;
            }

            const availablePeriods: IPeriod[] = this.rxPlayerInstance.getAvailablePeriods();
            const currentPosition = this.rxPlayerInstance.getPosition();

            if (!availablePeriods || !currentPosition) {
                return;
            }

            const newPeriod = availablePeriods.find((period: IPeriod) => period.end ? period.end > currentPosition : true);

            if (newPeriod && this._currentPeriod?.id !== newPeriod.id) {
                this._currentPeriod = newPeriod;
                triggerCustomEvent(this.rxPlayerInstance.videoElement, 'fs-period-change', newPeriod);
            }
        }, PERIOD_CHANGE_DETECT_INETRVAL);

        return true;
    }

    override get duration(): number {
        if (!this.rxPlayerInstance) {
            return 0;
        }

        const {isLive, dvrStart} = this.controllerLivestreamInstance;

        if (isLive) {
            const duration = this.rxPlayerInstance.getLivePosition() || 0;

            this._duration = duration - dvrStart;
        } else {
            if (this._duration > 0) {
                return this._duration;
            }

            this._duration =
                this.rxPlayerInstance.getMaximumPosition() ||
                this.rxPlayerInstance.getMediaDuration();
        }

        return this._duration;
    }

    override get playbackRate(): number {
        return this.rxPlayerInstance?.getPlaybackRate() || 1;
    }

    override set playbackRate(newRate) {
        this.rxPlayerInstance?.setPlaybackRate(newRate);
    }

    override async destroy(): Promise<void> {
        this.rxPlayerInstance?.removeEventListener(
            'playerStateChange',
            this.playWhenReady
        );

        if (this.options.enableRemoveBreakInRxPlayer) {
            this.rxPlayerInstance?.videoElement?.removeEventListener(
                'fs-period-change',
                wrapCustomEventListener<IPeriodChangeEvent>(this.triggerPeriodChangeEvents)
            );
        }

        window.clearInterval(this._intermittentStallDetector);
        window.clearInterval(this._periodChangeDetector);
        this._currentPeriod = null;

        this.rxPlayerInstance?.stop();
        this.rxPlayerInstance?.dispose();

        await super.destroy();
    }

    override get currentTime(): number {
        if (!this.rxPlayerInstance) {
            return this.options.startAt;
        }

        try {
            const currentPosition = this.rxPlayerInstance.getPosition();
            const {isLive, dvrStart} = this.controllerLivestreamInstance;

            if (isLive) {
                return currentPosition - dvrStart;
            }

            return currentPosition;
        } catch (err) {
            return this.options.startAt;
        }
    }

    override set currentTime(seconds: number) {
        let seekToPosition = parseFloat(seconds.toString());
        const maxSafeCurrentTime =
            (this.rxPlayerInstance?.getMaximumPosition() || this._duration) -
            END_SEEK_OFFSET;
        const {isLive, dvrStart} = this.controllerLivestreamInstance;

        if (isLive || dvrStart > 0) {
            seekToPosition += dvrStart;
        }

        this.rxPlayerInstance?.seekTo({
            position: Math.min(seekToPosition, maxSafeCurrentTime),
        });
    }
}
