import {
    PlayerState,
    KEY_EVENT_TYPE,
    type ErrorDatum,
    type HudContent,
    type KeyEventConfig,
    type KeyEvents,
    type PlaybackData,
    type PlayerStateConstructorParams,
    type RelatedVideos,
    type Snapshot,
    type StartScreenData,
    type UpNext,
    type VideoState,
} from '@fsa-streamotion/player-state';
import type {SupportedPlayerTechInstance} from '@fsa-streamotion/player-tech';
// eslint-disable-next-line no-duplicate-imports
import type PlayerTech from '@fsa-streamotion/player-tech';

import noop from 'lodash/noop';
import React from 'react';
import ReactDOM from 'react-dom';

import {DEFAULT_THEME} from '../utils/constants';
import type {Logger} from '../utils/types';
import StreamotionWebPlayerComponent, {
    type Props as StreamotionWebPlayerComponentProps,
} from './component';

export type ScreenClosingStates = {
    activeKeySystemName?: string | null;
    currentSourceType?: string;
    currentTime?: number;
    isEnded?: boolean;
    onEdge?: boolean;
    playing?: boolean;
    screenIndex?: string | number | null;
    src?: string | null;
};

export type ConstructorArgsType = PlayerStateConstructorParams & {
    customCallbacks?: {
        onChangeCaptions?: PlayerStateConstructorParams['onChangeCaptions'];
        onPlayerClosed?: ({
            screenStates,
        }: {
            screenStates?: ScreenClosingStates[];
        }) => void;
        onPlayingScreenClosed?: PlayerStateConstructorParams['onPlayingScreenClosed'];
        onClickChromecast?: PlayerStateConstructorParams['onClickChromecast'];
        onRequestRelatedVideos?: PlayerStateConstructorParams['onRequestRelatedVideos'];
        onRequestHudContent?: PlayerStateConstructorParams['onRequestHudContent'];
        updateUpNextData?: PlayerStateConstructorParams['updateUpNextData'];
        onShowLongPause?: PlayerStateConstructorParams['onShowLongPause'];
        onHideLongPause?: PlayerStateConstructorParams['onHideLongPause'];
        getCaptionsPreference?: PlayerStateConstructorParams['getCaptionsPreference'];
        setCaptionsPreference?: PlayerStateConstructorParams['setCaptionsPreference'];
        getAudioTrackPreference?: PlayerStateConstructorParams['getAudioTrackPreference'];
        setAudioTrackPreference?: PlayerStateConstructorParams['setAudioTrackPreference'];
        onScreenInteractionByType?: PlayerStateConstructorParams['onScreenInteractionByType'];
        onPlayerInteractionByType?: PlayerStateConstructorParams['onPlayerInteractionByType'];
        onUserInteraction?: PlayerStateConstructorParams['onUserInteraction'];
        onLayoutStyleChange?: PlayerStateConstructorParams['onLayoutStyleChange'];
        onTrackOztamSeekAction?: PlayerStateConstructorParams['onTrackOztamSeekAction'];
        onTrackOztamPlaybackRateChangeAction?: PlayerStateConstructorParams['onTrackOztamPlaybackRateChangeAction'];
        shouldOverrideScrubberBehaviour?: PlayerStateConstructorParams['shouldOverrideScrubberBehaviour'];
        shouldAutoplayUpNext?: PlayerStateConstructorParams['shouldAutoplayUpNext'];
    };
};

export default class StreamotionWebPlayer {
    #isOpen = false;
    #mountNode: Element | null = null;
    #closingPromise: Promise<void> | null = null;
    #openingPromise: Promise<void> | null = null;
    #playerState: PlayerState | null = null;
    #playerStateConstructorArgs: PlayerStateConstructorParams = {};
    #isChromecastAvailable = false;
    #isChromecastConnected = false;
    logger: Logger = console;
    onPlayerClosed: ({
        screenStates,
    }: {
        screenStates?: ScreenClosingStates[];
    }) => void = noop;
    numScreens = 1; // eslint-disable-line lines-between-class-members

    constructor({
        numScreens = 1,
        errorHandlersByCode = null,
        youboraAccountId = null,
        youboraHost,
        uniqueDeviceId = null,
        device,
        canUseAirplay = true,
        canUseChromecast = true,
        canUserViewHd = true,
        canHaveRelatedVideos = false,
        canChangePlaybackSpeed = true,
        isHudEnabled = false,
        canUserViewExitButton = true,
        waitMsShowingLongPause = 5 * 1000,
        logger = console,
        theme = DEFAULT_THEME,
        customCallbacks = {},
        getDashjsConfig,
        isQualityControlsEnabledInPlayer = true,
        isQualityControlsEnabledInSettings = true,
        commonErrorData = {},
        getHlsjsConfig,
    }: ConstructorArgsType = {}) {
        const {
            onChangeCaptions = noop,
            onPlayerClosed = noop,
            onPlayingScreenClosed = noop,
            onClickChromecast = noop,
            onRequestRelatedVideos = noop,
            onRequestHudContent = noop,
            updateUpNextData = noop,
            onShowLongPause, // No default, isProlongedPauseEnabled depends on onShowLongPause + onHideLongPause
            onHideLongPause,
            getCaptionsPreference = () => {
                // get the caption preference
                const captionPreference = sessionStorage.getItem(
                    'streamotion-web-player-skin-captions-preference'
                );

                // check if the caption preference is null
                if (
                    captionPreference === null ||
                    captionPreference === undefined
                ) {
                    return -1;
                }

                // return the preference as an integer
                return parseInt(captionPreference);
            },
            setCaptionsPreference = (index) =>
                index &&
                sessionStorage.setItem(
                    'streamotion-web-player-skin-captions-preference',
                    index.toString()
                ),
            getAudioTrackPreference = () => {
                try {
                    if (
                        sessionStorage.getItem(
                            'streamotion-web-player-skin-audio-preference'
                        )
                    ) {
                        return JSON.parse(
                            sessionStorage.getItem(
                                'streamotion-web-player-skin-audio-preference'
                            ) as string
                        );
                    }
                } catch {
                    return {};
                }
            },
            setAudioTrackPreference = (preferredAudioSettings) =>
                sessionStorage.setItem(
                    'streamotion-web-player-skin-audio-preference',
                    JSON.stringify(preferredAudioSettings)
                ),
            onScreenInteractionByType,
            onPlayerInteractionByType,
            onUserInteraction,
            onLayoutStyleChange,
            onTrackOztamSeekAction,
            onTrackOztamPlaybackRateChangeAction,
            shouldOverrideScrubberBehaviour = () => false,
            shouldAutoplayUpNext = () => true,
        } = customCallbacks;

        Object.assign(this, {
            numScreens,
            onPlayerClosed,
            logger,
        });

        this.#playerStateConstructorArgs = {
            numScreens,
            canUseAirplay,
            canUseChromecast,
            errorHandlersByCode,
            canUserViewHd,
            isHudEnabled,
            youboraAccountId,
            youboraHost,
            canHaveRelatedVideos,
            canChangePlaybackSpeed,
            canUserViewExitButton,
            onClickChromecast,
            onRequestRelatedVideos,
            updateUpNextData,
            onRequestHudContent,
            onChangeCaptions,
            onShowLongPause,
            onHideLongPause,
            onScreenInteractionByType,
            onPlayerInteractionByType,
            onUserInteraction,
            onLayoutStyleChange,
            onTrackOztamSeekAction,
            onTrackOztamPlaybackRateChangeAction,
            onPlayingScreenClosed,
            waitMsShowingLongPause,
            isProlongedPauseEnabled: !!onShowLongPause && !!onHideLongPause,
            getCaptionsPreference,
            setCaptionsPreference,
            getAudioTrackPreference,
            setAudioTrackPreference,
            exitPlayer: () => this.close(),
            logger,
            theme,
            uniqueDeviceId,
            device,
            shouldOverrideScrubberBehaviour,
            shouldAutoplayUpNext,
            getDashjsConfig,
            isQualityControlsEnabledInPlayer,
            isQualityControlsEnabledInSettings,
            commonErrorData,
            getHlsjsConfig,
        };
    }

    isNotEmptyPlayback(
        currentPlaybackHandler?: PlayerTech['currentPlaybackHandler']
    ): currentPlaybackHandler is SupportedPlayerTechInstance {
        return (
            !!currentPlaybackHandler &&
            'controllerLivestreamInstance' in currentPlaybackHandler
        );
    }

    /**
     * Close the player
     * This method is very intentionally async because we need to be able to destroy our playerTechs entirely before representing that the player is closed
     *
     */

    async close(): Promise<void> {
        this.#closingPromise = (this.#openingPromise || Promise.resolve())
            // If we are closed too soon after being opened, just wait first
            .finally(async () => {
                // We need to create a closing state here with all the bits needed for the onPlayerClosed callback.
                // Unfortunately after the component is unmounted and the state is destroyed we don't have access
                // to the playback details any more.
                // Also, technically when we close the player, there should be only 1 screen with content, as in,
                // user must exit splitview before closing the player. This is to be robust & future-proof.
                const screenClosingStates: ScreenClosingStates[] | undefined =
                    this.#playerState?.screensWithContent?.map(
                        (screenIndex) => {
                            const {videoState, playerTech} =
                                (typeof screenIndex === 'number' &&
                                    this.#playerState?.screenConfigs?.[
                                        screenIndex
                                    ]) ||
                                {};
                            const {
                                currentPlaybackHandler,
                                currentSourceType,
                                playing,
                                src,
                            } = playerTech || {};
                            const {currentTime, isEnded} = videoState || {};

                            const onEdge = this.isNotEmptyPlayback(
                                currentPlaybackHandler
                            )
                                ? currentPlaybackHandler
                                      ?.controllerLivestreamInstance?.onEdge
                                : undefined;

                            return {
                                activeKeySystemName:
                                    currentPlaybackHandler?.activeKeySystemName,
                                currentSourceType,
                                currentTime,
                                isEnded,
                                onEdge,
                                playing,
                                screenIndex,
                                src,
                            };
                        }
                    );

                if (this.mountNode) {
                    ReactDOM.unmountComponentAtNode(this.mountNode);
                }

                // We have a problem where ReactDOM.unmountComponentAtNode is unmounting
                // the components asynchronously but we want to unmount the components before
                // destroying the state.

                // We have components that are depending on the state to push data for
                // clean up tasks. Destroying the state before unmounting the components means that there will be
                // memory leak on those components. The state will not be there when unmounting happens and no clean up
                // tasks can be executed.

                // Doing clean up here manually without depending on React lifecycle.
                void this.#playerState?.globalActions?.clearUpNext();
                void this.#playerState?.globalActions?.clearStillWatching();

                this.logger.debug('Destroying playerState');
                await this.#playerState?.destroy();
                this.#playerState = null;

                this.#isOpen = false;

                this.onPlayerClosed({screenStates: screenClosingStates});
                this.logger.debug('Player closed');
            });

        return this.#closingPromise;
    }

    /**
     * Open the player
     */

    async open(): Promise<void> {
        this.#openingPromise = (this.#closingPromise || Promise.resolve())
            // If we are opened too soon after being closed, and playerTechs haven't been torn down etc, just wait for that to happen first
            .then(() => {
                this.#playerState = new PlayerState({
                    ...this.#playerStateConstructorArgs,
                    // We don't track these in the one-time playerStateConstructorArgs object because they can change between the player being initialised and the player being opened
                    isChromecastAvailable: this.#isChromecastAvailable,
                    isChromecastConnected: this.#isChromecastConnected,
                });

                return new Promise<void>((resolve, reject) => {
                    try {
                        if (this.#playerState) {
                            ReactDOM.render<StreamotionWebPlayerComponentProps>(
                                <StreamotionWebPlayerComponent
                                    playerState={this.#playerState}
                                />,
                                this.mountNode,
                                resolve
                            );
                        }
                    } catch (e) {
                        reject(e);
                    }
                });
            })
            .catch((e) => {
                this.logger.error('Error rendering player', e);
            })
            .finally(() => {
                this.#isOpen = true;
                this.logger.debug('Player opened');
            });

        return this.#openingPromise;
    }

    /**
     * @returns Is the player open?
     */
    get isOpen(): boolean {
        return this.#isOpen;
    }

    /**
     * @returns The node the player should render into when `open` is called
     */
    get mountNode(): Element | null {
        return this.#mountNode;
    }

    /**
     * @param mountNode - Which node should the player render into when `open` is called
     */
    set mountNode(mountNode) {
        if (this.mountNode && this.isOpen) {
            throw new Error(
                'StreamotionPlayer: Attempted to change mount node while the player was open'
            );
        }

        this.#mountNode = mountNode;
    }

    /**
     * @param relatedVideos - the related videos for this session. Set to null to show a loading state. Set to an empty Array to denote no videos.
     */
    set relatedVideos(relatedVideos: RelatedVideos) {
        if (!this.isOpen) {
            throw new Error(
                'StreamotionPlayer: Attempted to update related videos while the player was closed'
            );
        }

        this.#playerState?.setRelatedVideos(relatedVideos);
    }

    set upNext(upNext: UpNext | null) {
        if (!this.isOpen) {
            console.warn(
                'StreamotionPlayer: Attempted to update up next data while the player was closed'
            );

            return;
        }

        this.#playerState?.setUpNext(upNext);
    }

    /**
     * @param isChromecastAvailable - Should we show the Chromecast icon (is a device available to cast to?)
     */
    set isChromecastAvailable(isChromecastAvailable: boolean) {
        this.#isChromecastAvailable = isChromecastAvailable;

        if (this.isOpen) {
            // The player has already been opened - update the value in its state
            this.#playerState?.setIsChromecastAvailable(isChromecastAvailable);
        }
    }

    /**
     * @param isChromecastConnected - Is the user connected to a cast device?
     */
    set isChromecastConnected(isChromecastConnected: boolean) {
        this.#isChromecastConnected = isChromecastConnected;

        if (this.isOpen) {
            // The player has already been opened - update the value in its state
            this.#playerState?.setIsChromecastConnected(isChromecastConnected);
        }
    }

    /**
     * Sets the HUD content (tabs + panels)
     *
     * @param hudContent - The HUD content object. @see HudContent
     */
    set hudContent(hudContent: HudContent[]) {
        if (!this.isOpen) {
            throw new Error(
                "StreamotionPlayer: Attempted to update the HUD's content while the player was closed"
            );
        }

        this.#playerState?.setHudContent(hudContent);
    }

    /**
     * Returns the appropriate screen's playerTech instance.
     *
     * @param screenIndex - The index of the screen, 0 by default.
     * @returns - The playerTech object for the given screen.
     */
    getPlayerTech(screenIndex = 0): PlayerTech | undefined {
        assertScreenIndex(screenIndex, this.numScreens);

        return this.#playerState?.screenConfigs?.[screenIndex]?.playerTech;
    }

    getVideoState(screenIndex = 0): VideoState | undefined {
        assertScreenIndex(screenIndex, this.numScreens);

        return this.#playerState?.getVideoState(screenIndex);
    }

    /**
     * StillWatching
     * StillWatching.inactiveTimeInSeconds - The time an user need to be inactive before triggering are you still watching feature instead of Up Next.
     * StillWatching.render - the function to render the Are You Still Watching Component into the element provided.
     * @example
     * ```
     * async function render({onContinue, element}) {
     *   // Are you still watching data is fetched from
     *   // eg: https://resources.streamotion.com.au/staging/flash/video-player/still-watching.json
     *
     *   await new Promise((resolve) => {
     *       ReactDOM.render(
     *           <AreYouStillWatching
     *              onButtonClick={onContinue}
     *              iconUrl={iconUrl}
     *              copy={copy}
     *              buttonLabel={buttonLabel}
     *           />
     *           element,
     *           resolve,
     *       );
     *   });
     *
     *   // Clean up function for the skin
     *   return () => void (ReactDOM.unmountComponentAtNode(element));
     * }
     * ```
     */
    /**
     * ContentWarningLine
     * ContentWarningLine.type - type of the warning line: `<rating | warning>`.
     * ContentWarningLine.value - content of the warning line.
     */
    /**
     * UpNext.startTimeInSeconds - a time marker to tell the skin when to show the Up Next UI. If this is undefined, duration is used to calculate the startTime.
     * UpNext.durationInSeconds - how long should Up Next be shown. This is used to calculated the start time when startTimeInSeconds is not provided.
     * UpNext.onNextVideoPlay - the function to play the next video manually through the Next Up button in lower controls.
     * UpNext.render - the function to render the up next container into the element provided.
     * ```
     * @example
     * async function render({onCancel, element}) {
     *   await new Promise((resolve) => {
     *       ReactDOM.render(
     *           <UpNext onCancel={onCancel} />
     *           element,
     *           resolve,
     *       );
     *   });
     *
     *   // Clean up function for the skin
     *   return () => {
     *       ReactDOM.unmountComponentAtNode(element);
     *   }
     * }
     *```
     *
     * PlaybackData
     * PlaybackData.sources - a playerTech sources array (see https://web.kayosports.com.au/streamotion-web-app/player-tech/version/latest/docs/PlayerTech.html)
     * PlaybackData.thumbnailBIFs - a thumbnails object. Contains four attributes: `sdUrl` and `hdUrl`, the attribute values are the BIF files' URLs, and `sdDelayMs` and `hdDelayMs` for delaying the BIF retrievals.
     * PlaybackData.markers - an array of marker objects. Contains the title, type, startTime, endTime, and image object.
     * PlaybackData.options - a playerTech options object (see https://web.kayosports.com.au/streamotion-web-app/player-tech/version/latest/docs/PlayerTech.html)
     * PlaybackData.id - a unique identifier for the video
     * PlaybackData.diagnostics - an object of values that can supplement our core diagnostics
     * PlaybackData.isClosedCaptionsEnabledForAsset - should show close caption options in top tray.
     * PlaybackData.getYouboraOptions - a callback function for retrieving Youbora's options.
     * ```
     * @example
     * // Implementation example
     * // This function is called with the current PlayerTech.
     * // PT can be used to access currentPlaybackHandler.activeKeySystemName for drm details.
     * function getYouboraOptions(PlayerTech) {
     *   return ({
     *     'user.name': '' // Auth0ID,
     *     'content.customDimension.1': 'ares',
     *      ...
     *   })
     * }
     * ```
     * getYouboraAdapterConfig - A callback function to return an object contains custom adapter methods.
     * ```
     * @example
     * // Implementation example
     * // This function is called with the current PlayerTech.
     * function getYouboraAdapterConfig(PlayerTech) {
     *   return ({
     *     getPlayerName: () => 'NewsAustraliaPlayer',
     *     getPlayerVersion: () => 'playerversion-1.0.0',
     *     ...
     *   })
     * }
     * ```
     * contentWarningLines - array of content warning line objects.
     * upNext - data for up next feature.
     * stillWatching - data for stillWatching feature.
     * onVideoEnd - callback function to call when video is ended and up next is cancelled
     * getRelatedVideos - callback function to call when the related videos need to be retrieved
     * getHudContent - callback function to call when the HUD content needs to be retrieved
     */

    // @TODO: at the moment setStartScreen works for only the first playback in a session
    // https://foxsportsau.atlassian.net/browse/VPW-220 will address this
    /**
     * @param startScreenData - The start screen data for incoming source
     * @param screenIndex - Which screen is this for
     */
    setStartScreen(startScreenData: StartScreenData, screenIndex = 0): void {
        this.getPlayerTech(screenIndex)?.pause(); // eslint-disable-line no-unused-expressions
        this.#playerState?.setStartScreenData(startScreenData, screenIndex);
    }

    clearUpNext(): void {
        this.#playerState?.globalActions?.clearUpNext();
    }

    getSnapshot(): Snapshot | undefined {
        return this.#playerState?.snapshot;
    }

    /**
     * @param playbackData - The new playback data
     * @param screenIndex - Which screen is this for
     */
    setPlaybackData(playbackData: PlaybackData, screenIndex = 0): void {
        assertScreenIndex(screenIndex, this.numScreens);

        this.#playerState?.setPlaybackData(playbackData, screenIndex);
    }

    /**
     * CustomElement
     * CustomElement.type - type of the element. `<text, button, link>`
     * CustomElement.label - inner text of the element
     * CustomElement.href - the url for href if the type is button/link
     * CustomElement.onClick - onClick handler if the type is button
     */
    /**
     * ErrorDatum
     * ErrorDatum.title - the title for the error card
     * ErrorDatum.message - error message, eg: "Sorry, Binge is only available within Australia."
     * ErrorDatum.customElementsConfig - This is a config for users to insert extra elements (text, link and button)
     * ```
     * eg. let customElementsConfig = [
     *   [{type: 'text', label: 'Error Code: 12332'}],
     *   [{type: 'text}, label: 'Please click here for more information'}, {type: 'link', label: 'FAQ', href: '/faq'}],
     * ];
     * ```
     *
     * It will be rendered into (approx)
     * ```
     *  <>
     *    <div>
     *      <p>Error Code: 12332</p>
     *    </div>
     *
     *    <div>
     *      <p>Please click here for more information</p><a href="/faq">FAQ</a>
     *    </div>
     * </>
     * ```
     */
    /**
     * @param errorDatum - error data
     * @param screenIndex - integer of the video screen.
     */
    async setErrorData(
        errorDatum: ErrorDatum | null,
        screenIndex = 0
    ): Promise<void> {
        assertScreenIndex(screenIndex, this.numScreens);

        await this.#playerState?.setErrorData(errorDatum, screenIndex);
    }

    /**
     * @param KeyEvents - @see KeyEvents
     * KeyEvent.actualTimeInSeconds - Where should this event marker appear on the scrubber.
     * KeyEvent.offsetTimeInSeconds - Where should this marker scrub to, when clicked.
     * KeyEvent.label - The label of an event. Key moments use this.
     * KeyEvent.description - The description of an event. Key segments use this.
     */
    /**
     * @param KeyEventConfig - @see KeyEventConfig
     * KeyEventConfig.type - This represents the type of key events eg. KEY_EVENT_TYPE.MOMENT | KEY_EVENT_TYPE.SEGMENT
     * KeyEventConfig.instructionalDescription - This is the description on the instruction overlay.
     * KeyEventConfig.shouldUseSlowReveal - This controls if user could see spoilers. eg true = only show events before current playhead.
     */
    /**
     * @param keyEvents - Data for building event markers on scrubber.
     * @param config - general configuration for key events.
     * @param screenIndex - The integer of the video screen.
     */
    setKeyEventData(
        keyEvents: KeyEvents,
        config: KeyEventConfig,
        screenIndex = 0
    ): void {
        assertScreenIndex(screenIndex, this.numScreens);
        assertKeyEventType(config?.type);

        this.#playerState?.setKeyEventData(keyEvents, config, screenIndex);
    }
}

function assertKeyEventType(eventType: symbol | undefined): void {
    const acceptedTypes = Object.values(KEY_EVENT_TYPE);
    const hasValidType = eventType && acceptedTypes.includes(eventType);

    if (!eventType || !hasValidType) {
        throw new Error(
            `StreamotionPlayer: Received invalid key event type - ${String(
                eventType
            )}. ` +
                'Use exported type constant KEY_EVENT_TYPE from this library'
        );
    }
}

function assertScreenIndex(index: number, maxIndex: number): void {
    if (index >= maxIndex) {
        throw new Error(
            `StreamotionPlayer: Tried to access config for screen #${index}, but we were only set up to allow ${maxIndex} screens`
        );
    }
}
