import {useEffect, useState, useRef} from 'react';
import {useTheme} from 'styled-components';

export const SCREEN_PHONE = 0;
export const SCREEN_375_PHABLET = 375;
export const SCREEN_415_PHABLET = 415;
export const SCREEN_568_MOBILE_LANDSCAPE = 568;
export const SCREEN_667_PHABLET_LANDSCAPE = 667;
export const SCREEN_768_TABLET = 768;
export const SCREEN_1024_DESKTOP = 1024;
export const SCREEN_1280_DESKTOP = 1280;
export const SCREEN_1480_DESKTOP = 1480;
export const SCREEN_1680_DESKTOP = 1680;
export const SCREEN_1920_DESKTOP = 1920;
export const SCREEN_2560_DESKTOP = 2560;
export const SCREEN_3840_TV = 3840;

export const SMALLEST_SUPPORTED_WIDTH = 320;

type Params = {
    minViewportWidthPx: number;
    effectiveViewportWidthPx?: number;
    isHudOpen?: boolean;
};

// Note: `isHudOpen` is assumed to be the single truth (`effectiveViewportWidthPx` should just exist when `isHudOpen` is true)
function useHudAwareMatchMedia({
    minViewportWidthPx,
    effectiveViewportWidthPx,
    isHudOpen,
}: Params): boolean {
    const matchMediaRef = useRef(
        window.matchMedia(`(min-width: ${minViewportWidthPx}px)`)
    );
    const [matchMediaResult, setMatchMediaResult] = useState({
        matches: matchMediaRef.current.matches,
    });

    useEffect(
        function updateMediaMatchQuery() {
            if (isHudOpen) {
                return;
            }

            matchMediaRef.current.addEventListener(
                'change',
                setMatchMediaResult
            );

            return () =>
                // eslint-disable-next-line react-hooks/exhaustive-deps
                matchMediaRef.current.removeEventListener(
                    'change',
                    setMatchMediaResult
                );
        },
        [isHudOpen]
    );

    return isHudOpen
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          effectiveViewportWidthPx! >= minViewportWidthPx
        : matchMediaResult.matches;
}

export function useMatchMedia(minViewportWidthPx: number): boolean {
    return useHudAwareMatchMedia({minViewportWidthPx});
}

export function useEffectiveViewport(minViewportWidthPx: number): boolean {
    const {effectiveViewportWidthPx, isHudOpen: isHubOpenStringValue} =
        useTheme() || {};
    const isHudOpen = isHubOpenStringValue ?? false;

    return useHudAwareMatchMedia({
        minViewportWidthPx,
        effectiveViewportWidthPx,
        isHudOpen,
    });
}
