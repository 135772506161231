import {css} from 'styled-components';

import fuzzyTest from './util/fuzzy-test';
import type {
    BaseProps,
    CssStrings,
    CssInterpolations,
    CssInterpolationArgs,
    CssInterpolationResult,
    Predicate,
} from './util/types';

type Result<P extends BaseProps> = (
    ...args: CssInterpolationArgs<P>
) => (props: P) => CssInterpolationResult<P>;

/**
 * Inverse of `stylesWhen`. For conditional rendering of styles in `styled-components`, using either a property extractor string or a callback. Supports nesting.
 * Example usage: `<MyStyledDiv someProperty={false} someOtherProperty={20} />`
 *
 * @example Property Extractor
 * ```
 * const MyStyledDiv = styled.div`
 *     ${stylesWhenNot('someProperty')`
 *         color: red;
 *     `}
 * `;
 * ```
 *
 * @example Props test callback
 * ```
 * const MyStyledDiv = styled.div`
 *     ${stylesWhenNot(({someOtherProperty}) => someOtherProperty < 10)`
 *         font-weight: bold;
 *     `}
 * `;
 * ```
 *
 * @example Nesting
 * ```
 * const MyStyledDiv = styled.div`
 *     ${stylesWhenNot(({someOtherProperty}) => someOtherProperty < 10)`
 *         font-weight: bold;
 *
 *         ${stylesWhenNot('someProperty')`
 *             text-dectoration: underline;
 *         `}
 *     `}
 * `;
 * ```
 *
 * @typeParam P - React component props
 * @param test - test to apply to conditionally apply styles, returning for failed condition
 * @returns `styled-components` interpolation function
 */
export default function stylesWhen<P extends BaseProps>(
    test: string | Predicate<P>
): Result<P> {
    return (
            cssStrings: CssStrings<P>,
            ...cssInterpolations: CssInterpolations<P>
        ) =>
        (props: P) =>
            fuzzyTest<P>({test, props})
                ? []
                : css<P>(cssStrings, ...cssInterpolations);
}
