import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {observer} from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import TM07Classification from '../../../../component-library/molecules/tm/07-classification';
import {usePlayerContext} from '../../context';

const Positioner = styled.div`
    position: absolute;
    top: 25%;
    transform: translateY(-100%);
    transition: ${transition('opacity')};

    ${stylesWhenNot('isVisible')`
        opacity: 0;
    `}
`;

const ClassificationOverlay = (): React.ReactElement => {
    const playerState = usePlayerContext();
    const {
        videoState: {isContentWarningVisible},
        playbackData: {contentWarningLines},
    } = playerState?.activeScreenConfig ?? {videoState: {}, playbackData: {}};
    const {isUpperTrayVisible} = playerState?.globalState ?? {};

    // Why we hide "Classification" when "UpperTray" is visible?
    // ----------------------------------------------------------
    // This is to avoid "Classifications" to cover the "UpperTray".
    // A better solution would be stacking - put "UpperTray" on top of "Classifications".
    // But under current structure "Classifications" and "UpNext" are both under "Overlays", which means we can't make
    //      one of them above "UpperTray" and the other beneath. And "UpNext" needs to be on top.
    // Hiding "Classification" is then a good enough & simple workaround.
    // --> We might remove this in the future if the "Overlays" is altered.
    return (
        <Positioner isVisible={isContentWarningVisible && !isUpperTrayVisible}>
            {!!contentWarningLines && (
                <TM07Classification
                    lines={
                        contentWarningLines as {
                            type?: 'rating' | 'warning';
                            value?: React.ReactNode;
                        }[]
                    }
                />
            )}
        </Positioner>
    );
};

ClassificationOverlay.displayName = 'ClassificationOverlay';

export default observer(ClassificationOverlay);
