/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable no-empty-function */
import {
    enterFullscreen,
    exitFullscreen,
    getLocalStorageValue,
    isElementFullscreen,
    supportsAirPlay,
} from '@fsa-streamotion/browser-utils';

import type {AudioTrack, BaseBitrateInfo, PlayerQualityLevel} from '../types';
import {
    getIsInPopOutMode,
    setIsInPopOutMode,
    supportsPopOutPlayer,
} from '../utils/browser';
import type {PlayerQuality} from '../utils/quality-settings';
import {USER_SELECTED_PLAYER_QUALITY} from '../utils/storage-keys';
import type {BufferRange, PlaybackHandlerArgs, TextTracks} from './types';

class PlaybackEmpty {
    static async canPlaySource(): Promise<CanPlayTypeResult> {
        return '';
    }

    static async getAvailableKeySystems(): Promise<MediaKeySystemAccess[]> {
        return [];
    }

    videoElement: HTMLVideoElement;
    src: string;
    options: PlaybackHandlerArgs['options'];
    onError: PlaybackHandlerArgs['onError'];
    controllerDiagnostics = null;
    isPlaying = false;
    disableAutoVolume = false;
    textTracksList: TextTracks = [];
    currentTextTrackIndex = -1;
    currentAudioTrackIndex = -1;

    /**
     * Creates a new empty playback handler
     *
     * @param videoElement - The videoElement the playerTech is attached to
     * @param src - The src we're trying to play
     * @param options - Playback options
     * @param onError - The error callback from playerTech
     */
    constructor({
        videoElement,
        src,
        options,
        onError,
    }: Pick<
        PlaybackHandlerArgs,
        'videoElement' | 'src' | 'options' | 'onError'
    >) {
        this.videoElement = videoElement;
        this.src = src;
        this.options = options;
        this.onError = onError;
    }

    setup(): Promise<boolean> {
        return new Promise((resolve) => {
            resolve(true);
        });
    }

    destroy(): void {}
    load(): void {}
    preloadMetadata(): void {}
    preloadAuto(): void {}
    play(): void {}
    pause(): void {}
    disableTextTrack(): void {}

    get duration(): number {
        return 0;
    }

    get currentTime(): number {
        return 0;
    }

    set currentTime(newCurrentTime) {
        console.warn('This is empty playback handler:', newCurrentTime);
    }

    set textTrack(index: number) {
        console.warn('This is empty playback handler:', index);
    }

    get audioTracks(): AudioTrack[] {
        return this.videoElement.audioTracks;
    }

    get volume(): number {
        return this.videoElement.volume;
    }

    set volume(newVolume) {
        this.videoElement.volume = newVolume;
    }

    get muted(): boolean {
        return this.videoElement.muted;
    }

    set muted(shouldMute) {
        this.videoElement.muted = shouldMute;
    }

    saveVolume(): void {}
    toggleMute(): void {
        this.videoElement.muted = !this.videoElement.muted;
    }

    get poster(): string | null {
        return this.videoElement.getAttribute('poster');
    }

    set poster(newPoster) {
        if (newPoster) {
            this.videoElement.setAttribute('poster', newPoster);
        } else {
            this.videoElement.removeAttribute('poster');
        }
    }

    get playbackRate(): number {
        return this.videoElement?.playbackRate || 1;
    }

    set playbackRate(newRate) {
        this.videoElement.playbackRate = newRate;
    }

    checkElementIsFullscreen(fullscreenElement: Element): boolean {
        return isElementFullscreen(fullscreenElement);
    }

    enterFullscreen(
        newFullscreenElement: Element | undefined = undefined
    ): void {
        enterFullscreen({
            videoElement: this.videoElement,
            fullscreenElement: newFullscreenElement,
        });
    }

    exitFullscreen(): void {
        exitFullscreen(this.videoElement);
    }

    removeBreaks(): void {}

    toggleFullscreen(fullscreenElement: Element): void {
        if (this.checkElementIsFullscreen(fullscreenElement)) {
            this.exitFullscreen();
        } else {
            this.enterFullscreen(fullscreenElement);
        }
    }

    setCurrentTimeToEdge(): void {}

    get bufferedTimeRanges(): BufferRange[] {
        return [];
    }

    get bitrateLevels(): BaseBitrateInfo[] {
        return [];
    }

    get bitrateCurrentIndex(): number {
        return -1;
    }

    get bitrateNextIndex(): number {
        return -1;
    }

    set bitrateNextIndex(requestedIndex: number) {
        console.warn('This is empty playback handler:', requestedIndex);
    }

    get bitrateIsAuto(): boolean {
        return true;
    }

    bitrateSwitchToAuto(): void {}

    bitrateGetUserPreferredQuality(): PlayerQuality | undefined {
        return getLocalStorageValue({
            key: USER_SELECTED_PLAYER_QUALITY,
            defaultValue: {
                level: 'auto',
                height: 0,
            },
        }) as PlayerQuality;
    }

    get bitrateCurrentQuality(): PlayerQualityLevel {
        return 'auto';
    }

    setBitrateToAuto(): void {}
    setMaxBitrate(): void {}
    saveUserPreferredBitrate(): void {}

    get capLevelToPlayerSize(): boolean | undefined {
        return this.options.capLevelToPlayerSize;
    }

    set capLevelToPlayerSize(shouldCap) {
        console.warn('This is empty playback handler:', shouldCap);
    }

    get supportsAirPlay(): boolean {
        return supportsAirPlay();
    }

    showAirPlayTargetPicker(): void {
        this.videoElement.webkitShowPlaybackTargetPicker();
    }

    get supportsPopOutPlayer(): boolean {
        return supportsPopOutPlayer(this.videoElement);
    }

    get isPopOutPlayer(): boolean {
        return getIsInPopOutMode(this.videoElement);
    }

    set isPopOutPlayer(shouldPopOut) {
        setIsInPopOutMode(this.videoElement, shouldPopOut);
    }

    get shouldDisableCueElement(): boolean {
        return false;
    }

    toggleIsPopOutPlayer(): void {
        if (this.supportsPopOutPlayer) {
            this.isPopOutPlayer = !this.isPopOutPlayer;
        } else {
            console.warn(
                "PlayerTech: Unable to change popOutPlayer mode, since it's not supported."
            );
        }
    }

    get activeKeySystemName(): string | null {
        return null;
    }
}

export default PlaybackEmpty;
