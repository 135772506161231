export default class NagraAdapter {
  checkExistsPlayer() {
    try {
      return this.checkExistsObjectOnPage(this.player.el_);
    } catch (err) {
      return true;
    }
  }

  pauseListener(e) {
    this.firePlayerLog('pauseListener', {});
    this.firePause({}, 'pauseListener');
  }

  stalledListener(e) {
    this.firePlayerLog('stalledListener', {});
    this.fireBufferBegin({}, false, 'stalledListener');
  }

  bufferingListener(e) {
    this.firePlayerLog('waitingListener', {});
    this.fireBufferBegin({}, false, 'waitingListener');
  }

  getIsLive() {
    if (this.player.el_) {
      return this.player.el_.duration === Infinity;
    }
    return false;
  }

  playListener(e) {
    this.firePlayerLog('playListener', {});
    if (this.player) {
      this.checkReadyState(this.player.readyState, 'playListener');
    }
    this.fireStart({}, 'playListener');
  }

  registerListeners() {
    this.monitorPlayhead(false, false);
    this.references = {
      play: this.playListener.bind(this),
      timeupdate: this.timeupdateListener.bind(this),
      pause: this.pauseListener.bind(this),
      playing: this.playingListener.bind(this),
      error: this.errorListener.bind(this),
      seeking: this.seekingListener.bind(this),
      seeked: this.seekedListener.bind(this),
      ended: this.endedListener.bind(this),
      loadstart: this.loadStartListener.bind(this),
      stalled: this.stalledListener.bind(this),
      waiting: this.bufferingListener.bind(this),
      canplay: this.canPlayListener.bind(this),
      canplaythrough: this.canPlayThroughListener.bind(this),
      loadedmediadata: this.loadedMediaDataListener.bind(this),
      loadeddata: this.loadedDataListener.bind(this)
    };
    for (var key in this.references) {
      this.player.on(key, this.references[key]);
    }
  }

  endedListener(e) {
    this.firePlayerLog('endedListener', {});
    this.fireStop({}, 'endedListener');
  }

  getPlayrate() {
    return this.player.playbackRate();
  }

  canPlayListener(e) {
    if (this.player) {
      this.checkReadyState(this.player.readyState, 'canPlayListener');
    }
  }

  canPlayThroughListener(e) {
    if (this.player) {
      this.checkReadyState(this.player.readyState, 'canPlayThroughListener');
    }
  }

  timeupdateListener(e) {
    if ((this.getPlayhead() > 0.1 || (this.getVideo() && this.getVideo().getIsLive())) && !this.player.el_.error) {
      this.fireStart({}, 'timeupdateListener');
      this.fireJoin({}, 'timeupdateListener');
    }
  }

  getResource() {
    return this.player.currentSrc();
  }

  getPlayhead() {
    return this.player.currentTime();
  }

  getDuration() {
    return this.player.duration();
  }

  seekingListener(e) {
    this.firePlayerLog('seekingListener', {});
    this.fireSeekBegin({}, false, 'seekingListener');
  }

  getPlayerName() {
    return 'nagra';
  }

  playingListener(e) {
    this.firePlayerLog('playingListener', {});
    this.fireResume({}, 'playingListener');
    this.fireSeekEnd({}, 'playingListener');
    if (this.flags.isBuffering) {
      this.fireBufferEnd({}, 'playingListener');
    }
  }

  errorListener(e) {
    this.firePlayerLog('errorListener', {});
    var msg = null;
    var code = null;
    try {
      if (e && e.target && e.target.error) {
        code = e.target.error.code;
        msg = e.target.error.message;
      }
    } catch (err) { }
    this.fireError(code, msg, undefined, undefined, 'errorListener');
  }

  loadedMediaDataListener(e) {
    if (this.player) {
      this.checkReadyState(this.player.readyState, 'loadedMediaDataListener');
    }
  }

  getVersion() {
    return '7.0.1-nagra-jsclass';
  }

  seekedListener(e) {
    this.firePlayerLog('seekedListener', {});
    this.fireSeekEnd({}, 'seekedListener');
  }

  loadStartListener(e) {
    this.firePlayerLog('loadStartListener', {});
    if (this.flags.isJoined) {
      this.fireStop({}, 'loadStartListener');
    }
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.removeEventListener(key, this.references[key]);
        delete this.references[key];
      }
    }
  }

  loadedDataListener(e) {
    if (this.player) {
      this.checkReadyState(this.player.readyState, 'loadedDataListener');
    }
  }

  getPlayerVersion() {
    var version = 'Nagra';
    if (window.OTV.versions.product) {
      version += window.OTV.versions.product;
    }
    return version;
  }

  getThroughput() {
    if (this.player && this.player.otvtoolkit().networkStatistics.getNetworkUsage().downloadBitrate) {
      return this.player.otvtoolkit().networkStatistics.getNetworkUsage().downloadBitrate;
    }
    return null;
  }

  getBitrate() {
    if (this.player && this.player.otvtoolkit().playbackStatistics.getStreamBitrate()) {
      return this.player.otvtoolkit().playbackStatistics.getStreamBitrate();
    }
    return null;
  }

  getDroppedFrames() {
    let ret = 0;
    if (this.player.otvtoolkit().renderingStatistics.getFrameDrops()) {
      ret = this.player.otvtoolkit().renderingStatistics.getFrameDrops();
    }
    return ret;
  }

  getFramesPerSecond() {
    let ret = null;
    if (this.player.otvtoolkit().renderingStatistics.getFramesPerSecondNominal()) {
      ret = this.player.otvtoolkit().renderingStatistics.getFramesPerSecondNominal();
    }
    return ret;
  }

  getRendition() {
    let width;
    let height;
    let bandwidth;
    let variantTracks = this.player.tech_.shaka_.getVariantTracks();
    if (variantTracks !== null) {
      for (const variant of variantTracks) {
        if (variant.active === true) {
          width = variant.width;
          height = variant.height;
          bandwidth = variant.bandwidth;
        }
      }
    }
    return this.getNpawUtils().buildRenditionString(width, height, bandwidth);
  }
}