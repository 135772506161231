import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import get from 'lodash/get';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import PaymentCreditCard from '../payment-credit-card';
import PaymentPaypal from '../payment-paypal';
import Message from '../message';

const PAYMENT_METHOD_CREDIT_CARD = 'CREDIT_CARD';
const PAYMENT_METHOD_PAYPAL = 'PAYPAL';

export default class PaymentForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.renderServerErrors = this.renderServerErrors.bind(this);

        this.state = {
            selectedPaymentMethod: PAYMENT_METHOD_CREDIT_CARD,
            agree: false,
            paypalNonce: ''
        };
    }

    handleChangeSelectedPaymentMethod(paymentMethod) {
        this.setState({selectedPaymentMethod: paymentMethod});
    }

    handleOnSubmit(event) {
        event.preventDefault();

        if (this.state.selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD) {
            this.props.onSubmitCreditCardSubscription({
                hostedFieldsInstance: this.hostedFieldsInstance
            });
        } else if (this.state.selectedPaymentMethod === PAYMENT_METHOD_PAYPAL && this.state.paypalNonce) {
            this.props.onSubmitPaypalSubscription({
                paypalNonce: this.state.paypalNonce
            });
        }
    }

    renderServerErrors() {
        return (
            this.props.orderErrors.map((error, index) => <Message key={index} type="fail" value={error} />)
        );
    }

    renderCreditCardPayment() {
        if (!this.props.brainTreeClientInstance) {
            return null;
        }

        const isActive = this.state.selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD;
        const paymentOptionClasses = classNames(
            'fiso-hawk-subscribe__payment-option',
            {
                'fiso-hawk-subscribe__payment-option--active': isActive
            }
        );

        return (
            <div className={paymentOptionClasses}>
                <PaymentCreditCard
                    brainTreeClientInstance={this.props.brainTreeClientInstance}
                    onHostedFieldsInstanceCreated={
                        (instance) => {
                            this.hostedFieldsInstance = instance;
                        }
                    }
                    isLoading={this.props.isLoading}
                    error={this.props.error}
                    updateSuccess={this.props.updateSuccess} />
            </div>
        );
    }

    renderPaypalPayment() {
        if (!this.props.brainTreeClientInstance) {
            return null;
        }

        const isActive = this.state.selectedPaymentMethod === PAYMENT_METHOD_PAYPAL;
        const paymentOptionClasses = classNames(
            'fiso-hawk-subscribe__payment-option',
            'fiso-hawk-subscribe__payment-option--paypal',
            {
                'fiso-hawk-subscribe__payment-option--active': isActive
            }
        );

        return (
            <div className={paymentOptionClasses}>
                <PaymentPaypal
                    billingAgreementDescription={get(this.props.product, 'paymentPlan.name', undefined)}
                    brainTreeClientInstance={this.props.brainTreeClientInstance}
                    onNonceRecieved={
                        (nonce) => {
                            this.setState({paypalNonce: nonce});
                        }
                    }
                    updateSuccess={this.props.updateSuccess} />
            </div>
        );
    }

    renderPaymentButtons() {
        if (this.state.paypalNonce) {
            return null;
        }

        const paymentMethodPaypalButtonClasses = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            'fiso-hawk-button--flat',
            'fiso-hawk-button--stacked',
            {
                'fiso-hawk-button--selected': this.state.selectedPaymentMethod === PAYMENT_METHOD_PAYPAL
            }
        );

        const paymentMethodCreditCardButtonClasses = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            'fiso-hawk-button--flat',
            'fiso-hawk-button--credit-card',
            'fiso-hawk-button--stacked',
            {
                'fiso-hawk-button--selected': this.state.selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD
            }
        );

        return (
            <div className="fiso-hawk-subscribe__row">
                <div
                    className={`
                        fiso-hawk-subscribe__col
                        fiso-hawk-subscribe__col--credit`}>
                    <button
                        type="button"
                        className={paymentMethodCreditCardButtonClasses}
                        onClick={() => void this.handleChangeSelectedPaymentMethod(PAYMENT_METHOD_CREDIT_CARD)}>
                        <Fsvg name="credit-card" />
                        Credit Card
                    </button>
                </div>
                <div
                    className={`
                        fiso-hawk-subscribe__col
                        fiso-hawk-subscribe__col--paypal`}>
                    <button
                        type="button"
                        className={paymentMethodPaypalButtonClasses}
                        onClick={
                            () => {
                                this.handleChangeSelectedPaymentMethod(PAYMENT_METHOD_PAYPAL);
                            }
                        }>
                        <Fsvg name="paypal" />
                        PayPal
                    </button>
                </div>
            </div>
        );
    }

    renderFormButton() {
        const {isLoading, isEnabled, paymentDetails} = this.props;
        const paymentType = get(paymentDetails, 'paymentType', '');
        const submitButtonClasses = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`,
            {
                'fiso-hawk-button--loading': isLoading
            },
            {
                'fiso-hawk-button--chevron-right': !isLoading
            }
        );

        // this logic is pretty convoluted. Show the button if:
        // - it’s going to be a credit card subscription OR update
        // - it’s going to be a PayPal subscription BUT NOT update
        // - it’s going to be a PayPal update but you literally *just* updated it to be a credit card and now you’re changing it again
        // - it’s going to be a PayPal update, and it was PayPal before, but not if you just successfully updated it to be PayPal

        if (
            this.state.selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD ||
            (this.state.paypalNonce && !paymentType) ||
            (this.state.paypalNonce && paymentType === PAYMENT_METHOD_CREDIT_CARD) ||
            (this.state.paypalNonce && paymentType === PAYMENT_METHOD_PAYPAL &&
                !this.props.updateSuccess)
        ) {
            return (
                <button
                    type="submit"
                    disabled={!isEnabled || isLoading}
                    className={submitButtonClasses}>
                    { this.state.selectedPaymentMethod === PAYMENT_METHOD_CREDIT_CARD ? this.props.submitButtonText : 'Continue' }
                    { !isLoading && <Fsvg name="next-1" /> }
                </button>
            );
        }
    }

    render() {
        const subscribeFormClasses = classNames(
            'fiso-hawk-subscribe__payment-form',
            {
                'fiso-hawk-subscribe__col--pay-inactive': !this.props.isEnabled
            }
        );

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form
                ref={(form) => { this.subscribeForm = form; }}
                className={subscribeFormClasses}
                method="POST"
                onSubmit={this.handleOnSubmit}>
                <h2 className="fiso-hawk-subscribe__heading-2">
                    {this.state.paypalNonce ? 'Selected Payment' : 'Please enter your payment details'}
                </h2>

                {this.renderServerErrors()}

                <p className="fiso-hawk-subscribe__p">Major credit cards accepted. Cancel at anytime.</p>

                {this.renderPaymentButtons()}
                {this.renderCreditCardPayment()}
                {this.renderPaypalPayment()}

                {this.renderFormButton()}
            </form>
        );
    }
}

PaymentForm.defaultProps = {
    isLoading: false,
    orderErrors: [],
    onSubmitCreditCardSubscription: noop,
    onSubmitPaypalSubscription: noop,
    submitButtonText: 'Subscribe'
};

PaymentForm.propTypes = {
    isEnabled: propTypes.bool,
    isLoading: propTypes.bool,

    product: propTypes.object,
    brainTreeClientInstance: propTypes.object,
    orderErrors: propTypes.arrayOf(
        propTypes.string
    ),

    onSubmitCreditCardSubscription: propTypes.func,
    onSubmitPaypalSubscription: propTypes.func,

    submitButtonText: propTypes.string,

    error: propTypes.node,

    updateSuccess: propTypes.bool,

    paymentDetails: propTypes.shape({
        paymentType: propTypes.string
    }),

    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
