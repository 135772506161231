export default function registerVideoHandlers(playerTech, youboraPlugin) {
    const videoErrorHandler = (event) => {
        // Custom error fs-fatal-error-retry is in magical detail object from the error. This is because videoFS won't store the error
        // during a retry, and this is the only time we'll see what we're retrying.
        // If it's not set, go to the playerTech.error as you would normally.
        const error = event?.detail?.error || playerTech.error;

        // fireFatalError means it'll also do .stopMonitor() and make the 'level' fatal vs what fireError() does.
        // Neither calls fireStop() though. The params remain the same between the two functions.
        // (code, msg, metadata, level)
        youboraPlugin.getAdapter().fireFatalError(
            error?.code || 0,
            error?.message || 'unknown',
            {
                fsFatalErrorRetry: event.type === 'fs-fatal-error-retry'
            }
        );
    };

    const videoBufferHandler = (event) => {
        const {isBuffering = false, isSeeking = false} = event?.detail || {};

        if (isSeeking) {
            return; // Handled by seek handler don't fire buffer
        }

        if (isBuffering) {
            youboraPlugin.getAdapter().fireBufferBegin();
        } else {
            youboraPlugin.getAdapter().fireBufferEnd();
        }
    };

    const {videoElement} = playerTech;

    // Manually listen to the error retry event, and fire off like we would on a full .error.
    videoElement.addEventListener('fs-fatal-error-retry', videoErrorHandler);
    videoElement.addEventListener('fs-stalled-buffering', videoBufferHandler);

    return () => {
        videoElement.removeEventListener('fs-fatal-error-retry', videoErrorHandler);
        videoElement.removeEventListener('fs-stalled-buffering', videoBufferHandler);
    };
}
