// eslint-disable-next-line no-restricted-imports
import type {DebouncedFunc} from 'lodash';
import throttle from 'lodash/throttle';
import {useRef} from 'react';

import useSemanticMemo from './use-semantic-memo';

type ThrottleOptions = {
    leading: boolean;
    trailing: boolean;
};

type FnType<T> = (...args: T[]) => void;

type ThrottleSettings<T> = {
    fn: FnType<T>;
    waitMs?: number;
    options?: ThrottleOptions;
};

/**
 * Generate a throttled function whose lifecycle is tied to changes in the specified dependency list.
 *
 * This is a React wrapper around Lodash's <code>throttle</code>.
 *
 * @param throttleSettings - Throttle settings
 * @param deps - Dependency list
 * @returns The throttled function
 */
export default function useThrottle<T>(
    {fn, waitMs, options}: ThrottleSettings<T>,
    deps: unknown[]
): DebouncedFunc<FnType<T>> {
    const fnRef = useRef<FnType<T> | null>(null);

    fnRef.current = fn;

    const {leading, trailing} = options ?? {};

    return useSemanticMemo(
        () =>
            throttle(
                (...args: T[]) => fnRef.current?.(...args),
                waitMs,
                options
            ),
        [waitMs, leading, trailing, ...(deps ?? [])]
    );
}
