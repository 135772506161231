import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import {observer} from 'mobx-react-lite';
import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import gibson from '../../../../../../../common/font-helper';
import {suvaGrey} from '../../../../../../../common/palette';
import parseDiagnostics from '../../../../../../../common/parse-diagnostics';
import {
    SCREEN_768_TABLET,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../../../../common/screen-sizes';
import getVideoPlaybackCapabilities from '../../../../../../utils/playback-capabilities';
import {usePlayerContext} from '../../../../../context';
import HorizonChart from './horizon-chart';

const RERENDER_INTERVAL_MS = 1000;
const BUFFER_HEALTH_RANGES = [0, 10, 20, 30, 40, 50, 60];

const DiagnosticsTrayContainer = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
`;

const DiagnosticsEssentialsContainers = styled.dl`
    display: grid;
    flex: 1 0 min(30vw, 200px); /* stylelint-disable-line csstree/validator */
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        grid-template-columns: 1fr 1fr;
    `}
    margin: 0;
    font: ${gibson({lineHeight: 1.1})};
    /* stylelint-disable-next-line csstree/validator */
    font-size: clamp(7px, 1.6vw, 16px);

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        line-height: 1.5;
    `}
`;

const StatContainer = styled.div`
    display: flex;
    flex: 1 0;
    margin-left: 0;
    padding-right: 3px;
`;

const StatLabel = styled.dt`
    display: inline-block;
    width: 40%;
    font-weight: normal;
`;

const StatValue = styled.dd`
    margin-inline-start: unset;
    display: inline-block;
    margin: 0;
    width: 60%;
    overflow: hidden;
    word-wrap: break-word;
    color: ${suvaGrey};
`;

const GraphOuterContainer = styled.div`
    margin: 3px;
    width: 100px;
    min-height: 20px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        width: 300px;
    `}
`;

const GraphInnerContainer = styled.div`
    transform: scaleX(0.2);
    transform-origin: 0 50%;
    margin: 3px 0;
    width: 500px;
    min-height: 20px;

    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        transform: scaleX(0.6);
    `}
`;

const GraphLabel = styled.span<{isLeft: boolean}>`
    /* stylelint-disable-next-line */
    margin-${({isLeft}) => (isLeft ? 'right' : 'left')}: 10px;
    font: ${gibson({size: 14})};
`;

const Diagnostics = (): React.ReactElement => {
    const [, setRenderTrigger] = useState(0);
    const canPlayHighDrmRef = useRef(false);

    useEffect(function diagnosticSetup() {
        const intervalId = setInterval(() => {
            setRenderTrigger((value) => value + 1); // Make sure we always have a new value otherwise the component won't re-render
        }, RERENDER_INTERVAL_MS);

        getVideoPlaybackCapabilities().then(({canPlayHighDrm}) => {
            canPlayHighDrmRef.current = canPlayHighDrm;
        });

        return () => void clearInterval(intervalId);
    }, []);

    const {activeScreenConfig, generalConfig} = usePlayerContext() ?? {};

    const {
        videoWidth = -1,
        videoHeight = -1,
        videoBitrate = 0,
        videoCodec = '',
        audioBitrate = 0,
        audioCodec = '',
        audioTrackName = '',
        totalFrames = 0,
        droppedFrames = 0,
        bufferedSeconds = 0,
        cdnProvider = '',
        container = '',
        activeKeySystemName = '',
        videoQuality = undefined,
        hasSsai = false,
        subtitle = '',
        timestamp = 0,
        position = 0,
        version = '',
        viewport = '',
        frameRate = undefined,
        audioOption = '',
    } = activeScreenConfig ? parseDiagnostics(activeScreenConfig) : {};

    const audioBitrateText = audioBitrate ? `(${(audioBitrate / 1_000).toFixed(2)}Kbps)` : ''
    const audio = [
        audioTrackName,
        [audioOption, audioBitrateText].filter(Boolean).join(''),
        audioCodec
    ].filter(Boolean).join('/');

    const diagnosticsToDisplayEntries = [
        [
            'Video',
            `${videoWidth}×${videoHeight} ${
                videoBitrate
                    ? `(${(videoBitrate / 10 ** 6).toFixed(2)}Mb/s)`
                    : ''
            } ${videoCodec}`,
        ],
        ['Audio', audio],
        ['Frame Rate', typeof frameRate === 'number' ? `${frameRate}fps` : '-'],
        ['Subtitle', subtitle],
        ['Container', container],
        [
            'Key system',
            activeKeySystemName &&
                `${activeKeySystemName}${canPlayHighDrmRef.current ? '+' : ''}`,
        ],
        ['Viewport', viewport],
        ['Dropped Frames', `${droppedFrames}/${totalFrames}`],
        ['User Quality', videoQuality],
        ['Buffer', `${bufferedSeconds.toFixed(2)}s`],
        ['Position', position],
        ['Timestamp', timestamp],
        ['Version', version],
        ['UDID', generalConfig?.uniqueDeviceId],
        ['CDN', cdnProvider],
        ['SSAI', hasSsai ? 'Present' : '-'],
        ...Object.entries(activeScreenConfig?.playbackData.diagnostics ?? {}),
    ];

    return (
        <DiagnosticsTrayContainer>
            <DiagnosticsEssentialsContainers>
                {diagnosticsToDisplayEntries.map(([label, value]) => (
                    <StatContainer key={label}>
                        <StatLabel>{label}:&nbsp;</StatLabel>
                        <StatValue>{value || '-'}</StatValue>
                    </StatContainer>
                ))}
            </DiagnosticsEssentialsContainers>

            <GraphOuterContainer>
                <GraphLabel isLeft={true}>Buffer Health: </GraphLabel>
                <GraphInnerContainer>
                    <HorizonChart
                        currentValue={bufferedSeconds || 0}
                        rangeIntervals={BUFFER_HEALTH_RANGES}
                    />
                </GraphInnerContainer>
            </GraphOuterContainer>
        </DiagnosticsTrayContainer>
    );
};

Diagnostics.displayName = 'Diagnostics';

export default observer(Diagnostics);
