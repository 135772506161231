import remcalc from 'remcalc';
import styled from 'styled-components';

export const Footer = styled.footer`
    display: block;
`;

export const Nav = styled.nav`
    display: block;
`;

export const Section = styled.section`
    display: block;
`;

export const FigCaption = styled.figcaption`
    display: block;
`;

export const Figure = styled.figure`
    display: block;
    margin: ${remcalc(16)} ${remcalc(40)};
`;

export const Main = styled.main`
    display: block;
`;

export const Hr = styled.hr`
    box-sizing: content-box;
    height: 0;
    overflow: visible;
`;

export const A = styled.a`
    background-color: transparent;
    text-decoration-skip: objects;
`;

export const Sup = styled.sup`
    position: relative;
    top: -0.5em;
    vertical-align: baseline;
    line-height: 0;
    font-size: 75%;
`;

export const Img = styled.img`
    border-style: none;
`;

export const Svg = styled.svg`
    &:not(:root) {
        overflow: hidden;
    }
`;

export const Button = styled.button`
    appearance: button;
    margin: 0;
    overflow: visible;
    text-transform: none;
    line-height: 1.15;
    font-family: sans-serif;
    font-size: 100%;

    &::-moz-focus-inner,
    &[type='button']::-moz-focus-inner,
    &[type='reset']::-moz-focus-inner,
    &[type='submit']::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:-moz-focusring,
    &[type='button']:-moz-focusring,
    &[type='reset']:-moz-focusring,
    &[type='submit']:-moz-focusring {
        outline: ${remcalc(1)} dotted ButtonText;
    }
`;

export const Input = styled.input`
    margin: 0;
    overflow: visible;
    line-height: 1.15;
    font-family: sans-serif;
    font-size: 100%;

    &[type='checkbox'],
    &[type='radio'] {
        box-sizing: border-box;
        padding: 0;
    }

    &[type='search'] {
        appearance: textfield;
        outline-offset: ${remcalc(-2)};
    }

    /* stylelint-disable order/order */
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        height: auto;
    }

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button {
        appearance: none;
    }

    &::-webkit-file-upload-button {
        appearance: button;
        font: inherit;
    }
`;
