import result from 'lodash/result';

export default function getCricketSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'icc-cricket-world-cup':
        case '1':
            return {
                id: 1,
                label: 'ICC Cricket World Cup',
                route: 'icc-cricket-world-cup'
            };

        case 'one-day-cup':
        case '3':
            return {
                id: 3,
                label: 'National One-Day Cup',
                route: 'one-day-cup'
            };

        case 'international-t20':
        case '8':
            return {
                id: 8,
                label: 'International T20',
                route: 'international-t20'
            };

        case 'big-bash-league':
        case '9':
            return {
                id: 9,
                label: 'Big Bash League',
                route: 'big-bash-league'
            };

        case 'tour-matches':
        case '10':
            return {
                id: 10,
                label: 'Tour Matches',
                route: 'tour-matches'
            };

        case 'icc-champions-trophy':
        case '14':
            return {
                id: 14,
                label: 'ICC Champions Trophy',
                route: 'icc-champions-trophy'
            };

        case 'international-tests':
        case '25':
            return {
                id: 25,
                label: 'International Tests',
                route: 'international-tests'
            };

        case 'one-day-international':
        case '26':
            return {
                id: 26,
                label: 'One Day International',
                route: 'one-day-international'
            };

        case 'sheffield-shield':
        case '27':
            return {
                id: 27,
                label: 'Sheffield Shield',
                route: 'sheffield-shield'
            };

        case 'womens-international-twenty20':
        case '28':
            return {
                id: 28,
                label: 'Women\'s International Twenty20',
                route: 'womens-international-twenty20'
            };

        case 'womens-domestic-twenty20':
        case '31':
            return {
                id: 31,
                label: 'Women\'s Domestic Twenty20',
                route: 'womens-domestic-twenty20'
            };

        case 'icc-world-twenty20':
        case '34':
            return {
                id: 34,
                label: 'ICC World Twenty20',
                route: 'icc-world-twenty20'
            };

        case 'indian-premier-league':
        case '35':
            return {
                id: 35,
                label: 'Indian Premier League',
                route: 'indian-premier-league'
            };

        case 'champions-league-twenty20':
        case '37':
            return {
                id: 37,
                label: 'Champions League Twenty20',
                route: 'champions-league-twenty20'
            };

        case 'womens-icc-world-cup':
        case '43':
            return {
                id: 43,
                label: 'Women\'s ICC World Cup',
                route: 'womens-icc-world-cup'
            };

        case 'caribbean-premier-league':
        case '44':
            return {
                id: 44,
                label: 'Caribbean Premier League',
                route: 'caribbean-premier-league'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Cricket seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
