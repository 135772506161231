import type {
    CustomiseMediaPlayerErrorEvent,
    ErrorEvent,
    MediaPlayerClass,
} from 'dashjs';

import type PlaybackDash from '.';
import {ERROR_CODES, DEFAULT_ERROR_MESSAGES} from '../../utils/error-codes';
import NativeError from '../native/error';
import type {DashJs} from './types';

export default class DashError extends NativeError {
    declare playbackHandler: MediaPlayerClass;
    declare playbackTech: PlaybackDash;

    dashjs: DashJs;

    constructor(
        playbackHandler: PlaybackDash,
        dashInstance: MediaPlayerClass,
        dashjs: DashJs
    ) {
        super(playbackHandler, dashInstance);

        this.dashjs = dashjs;

        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.ERROR,
            this.onEventError
        );
    }

    override destroy(): void {
        this.playbackHandler.off('error', this.onEventError);
        super.destroy();
    }

    onEventError = (event: ErrorEvent): void => {
        const {error} = event as CustomiseMediaPlayerErrorEvent;
        const {code /* , message, data */} = error;
        const {errors} = this.dashjs.MediaPlayer;

        // http://cdn.dashjs.org/latest/jsdoc/core_errors_Errors.js.html
        switch (code) {
            // Issues while doing DRM or other decoding
            case errors.KEY_SESSION_CREATED_ERROR_CODE:
            case errors.MEDIA_KEY_MESSAGE_ERROR_CODE:
            case errors.MEDIA_KEY_MESSAGE_LICENSER_ERROR_CODE:
            case errors.MANIFEST_LOADER_PARSING_FAILURE_ERROR_CODE:
            case errors.MANIFEST_ERROR_ID_PARSE_CODE:
            case errors.MANIFEST_LOADER_LOADING_FAILURE_ERROR_CODE:
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_DECODE,
                    DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_DECODE],
                    error
                );

            // Issues with playing this type of file
            case errors.CAPABILITY_MEDIAKEYS_ERROR_CODE:
            case errors.CAPABILITY_MEDIASOURCE_ERROR_CODE:
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED,
                    DEFAULT_ERROR_MESSAGES[
                        ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED
                    ],
                    error
                );

            // Good old network issues
            case errors.DOWNLOAD_ERROR_ID_CONTENT_CODE:
            case errors.DOWNLOAD_ERROR_ID_INITIALIZATION_CODE:
            case errors.DOWNLOAD_ERROR_ID_MANIFEST_CODE:
            case errors.DOWNLOAD_ERROR_ID_SIDX_CODE:
            case errors.DOWNLOAD_ERROR_ID_XLINK_CODE:
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_NETWORK,
                    DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_NETWORK],
                    error
                );

            // Issues stopping us from doing DRM in the first place
            case errors.MEDIA_KEY_MESSAGE_NO_CHALLENGE_ERROR_CODE:
            case errors.MEDIA_KEY_MESSAGE_NO_LICENSE_SERVER_URL_ERROR_CODE:
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_ENCRYPTED,
                    DEFAULT_ERROR_MESSAGES[ERROR_CODES.MEDIA_ERR_ENCRYPTED],
                    error
                );

            // Everything else
            case errors.MANIFEST_ERROR_ID_MULTIPLEXED_CODE:
            case errors.MANIFEST_ERROR_ID_NOSTREAMS_CODE:
            default:
                return void this.onError(
                    ERROR_CODES.CUSTOM_ERR_UNKNOWN,
                    DEFAULT_ERROR_MESSAGES[ERROR_CODES.CUSTOM_ERR_UNKNOWN],
                    error
                );
        }
    };
}
