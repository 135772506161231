import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC26ArrowU = (args: IcProps): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M72.766 45.109L39.138 11.133 5.509 45.109 0 39.542 39.138 0 78.276 39.542z"
            transform="translate(10.924 26.076)"
        />
    </Ic>
);

IC26ArrowU.displayName = 'IC26ArrowU';

export default IC26ArrowU;
