import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import {displayDateFormat} from '@fsa/fs-commons/lib/utils/date';

export default class ProgrammeList extends React.Component {
    componentDidMount() {
        if (this.nowElement) {
            this.programmeListElement.scrollTop = this.nowElement.offsetTop - this.props.getSiblingOffsetHeight();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.programmes.length > 0 &&
            this.props.programmes.length > 0 &&
            prevProps.programmes[0].id !== this.props.programmes[0].id) {
            if (moment(this.props.programmes[0].startTime).date() === moment().date()) {
                this.programmeListElement.scrollTop = this.nowElement.offsetTop - this.props.getSiblingOffsetHeight();
            } else {
                this.programmeListElement.scrollTop = this.firstTomorrowElement.offsetTop - this.props.getSiblingOffsetHeight();
            }
        }
    }

    getIconContainer(item) {
        let iconContainer = <meta itemProp="isLiveBroadcast"  content="http://schema.org/False" />;

        if (item.live) {
            iconContainer = (
                <div className="fiso-hawk-programme-list__item-icon fiso-hawk-programme-list__item-icon--live">
                    <meta itemProp="isLiveBroadcast"  content="http://schema.org/True" />
                    Live
                </div>
            );
        } else if (item.replay) {
            iconContainer = (
                <div className="fiso-hawk-programme-list__item-icon fiso-hawk-programme-list__item-icon--replay">
                    <meta itemProp="isLiveBroadcast"  content="http://schema.org/False" />
                    Replay
                </div>
            );
        }

        return iconContainer;
    }

    renderItem(item, index) {
        const itemTitleClassNames = classnames(
            'fiso-hawk-programme-list__item-title',
            {'fiso-hawk-programme-list__item-title--live': item.live},
            {'fiso-hawk-programme-list__item-title--replay': item.replay}
        );
        const title = (
            <div className={itemTitleClassNames}>
                {this.getIconContainer(item)}
                <span className="fiso-hawk-programme-list__item-title-text" itemProp="name">
                    <strong>{item.programmeTitle}:</strong> {item.title}
                </span>
            </div>
        );
        const formattedTimeClasses = classnames(
            `fiso-hawk-color-secondary--${this.props.sportDetails.route}`,
            'fiso-hawk-programme-list__item-date'
        );
        let formattedTime = (
            <div className={formattedTimeClasses}>
                <span itemProp="startDate" content={item.startTime}>{displayDateFormat(item.startTime, 'HH:mmA')}</span>
                {/* spaces are required. The other options are: 1) use &nbsp; 2) 1 line for spans with ' - ' and wave eslint max-len */}
                {' - '}
                <span itemProp="endDate" content={item.endTime}>{displayDateFormat(item.endTime, 'HH:mmA')}</span>
            </div>
        );
        const isNow = moment().isBetween(item.startTime, item.endTime);
        const refCallback = (li) => {
            if (isNow) {
                this.nowElement = li;
            } else if (index === 0) {
                this.firstTomorrowElement = li;
            }
        };

        if (isNow) {
            formattedTime = (
                <div className={formattedTimeClasses}>
                    <meta itemProp="startDate" content={item.startTime} />
                    <meta itemProp="endDate" content={item.endTime} />
                    On NOW
                </div>
            );
        }

        return (
            <li
                key={item.id}
                ref={(li) => { refCallback(li); }}
                className="fiso-hawk-programme-list__item"
                itemScope={true}
                itemType="http://schema.org/BroadcastEvent">
                {formattedTime}
                {title}
            </li>
        );
    }

    renderProgrammes(programmes) {
        if (programmes && programmes.length === 0) {
            return <li className="fiso-hawk-programme-list__item--not-available">No programmes available</li>;
        } else {
            return programmes.map(this.renderItem, this);
        }
    }

    render() {
        return (
            <div ref={(div) => { this.programmeListElement = div; }} className="fiso-hawk-programme-list">
                <ol>
                    {this.renderProgrammes(this.props.programmes)}
                </ol>
            </div>
        );
    }
}

ProgrammeList.defaultProps = {
    programmes: []
};

ProgrammeList.propTypes = {
    programmes: propTypes.arrayOf(propTypes.shape({
        id: propTypes.string,
        programmeTitle: propTypes.string,
        title: propTypes.string,
        live: propTypes.bool,
        replay: propTypes.bool,
        startTime: propTypes.string,
        endTime: propTypes.string
    })),
    getSiblingOffsetHeight: propTypes.func,
    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
