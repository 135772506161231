import superagent from 'superagent';

import {streamFromApi, getVimondHeaders} from '../../utils/api';
import {DEFAULT_VIMOND_ENV, VIMOND_MIDDLELAYER_URL} from '../../utils/constants';

import authenticated from '../authenticated';

export default function ({
    orderId,
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/order/${orderId}/terminate`;

    return authenticated().flatMapLatest((authToken) => {
        const request = superagent
            .put(url)
            .set(getVimondHeaders({authToken, sport, version: 2}));

        return streamFromApi(request).map('.body');
    });
}
