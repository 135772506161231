import {useRef} from 'react';

function areHooksDepsEqual(
    prevDeps?: unknown[],
    nextDeps?: unknown[]
): boolean {
    if (!(prevDeps && nextDeps)) {
        return false;
    }

    if (prevDeps.length !== nextDeps.length) {
        return false;
    }

    return prevDeps.every((dep, index) => nextDeps[index] === dep);
}

/**
 * Like useMemo, but semantically memoized :)
 *
 * @param createValue - Function to create memorized value
 * @param deps - Dependency list
 * @returns Memorized value
 */
export default function useSemanticMemo<T>(
    createValue: () => T,
    deps: unknown[]
): T {
    const depsRef = useRef<unknown[] | undefined>(undefined);
    const valueRef = useRef<T | undefined>(undefined);

    if (!areHooksDepsEqual(depsRef.current, deps)) {
        depsRef.current = deps;
        valueRef.current = createValue();
    }

    return valueRef.current as T;
}
