import React from 'react';
import styled from 'styled-components';

const SizerInner = styled.div`
    width: 100%;
    height: 100%;
`;

// Use aspect-ratio if it's supported, otherwise fall back to the "height: 0, padding-bottom" workaround
// Remove workaround when this feature is widely supported: https://caniuse.com/?search=aspect-ratio
const SizerOuter = styled.div<{width: number; height: number}>`
    width: 100%;

    @supports (aspect-ratio: ${({width, height}) => `${width}/${height}`}) {
        aspect-ratio: ${({width, height}) => `${width}/${height}`};
    }

    @supports not (aspect-ratio: ${({width, height}) => `${width}/${height}`}) {
        position: relative;
        padding-bottom: ${({width, height}) => (height * 100) / width}%;
        height: 0;
        overflow: hidden;

        ${SizerInner} {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
`;

type Props = {
    width: number;
    height: number;
    children: React.ReactNode;
    className?: string;
};

const ResponsiveSizer: React.FC<
    Props & React.ComponentPropsWithoutRef<'div'>
> = ({width, height, children, ...htmlAttributes}) => (
    <SizerOuter
        {...{
            width,
            height,
            ...htmlAttributes,
        }}
    >
        <SizerInner>{children}</SizerInner>
    </SizerOuter>
);

ResponsiveSizer.displayName = 'ResponsiveSizer';

export default ResponsiveSizer;
