import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC97Eq = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M14.016 18.809H22.4V66.726H14.016zM0 37.939H8.384V66.684H0zM28.032 0H36.416V67.075H28.032zM42.104 37.939H50.488V66.684H42.104z"
            transform="translate(25 15.5)"
        />
    </Ic>
);

IC97Eq.displayName = 'IC97Eq';

export default IC97Eq;
