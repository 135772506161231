/* eslint-disable import/prefer-default-export */

type Stringifiable = string | number | boolean;

/**
 * Pull out undefined and non-stringifiable properties before stringifying via `URLSearchParams`.
 * Note that empty string, null or empty object will all be unaffected
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/toString
 *
 * @param argsObj - object containing properties which may be undefined
 *
 * @returns Stringified version of the `URLSearchParams` object, with `undefined` params removed
 */
export function nicerStringify(argsObj: Record<string, unknown>): string {
    if (!argsObj) {
        return '';
    }

    const noUndefinedProperties = Object.fromEntries(
        Object.entries(argsObj)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => [
                key,
                isStringifiable(value) ? value.toString() : '',
            ])
    );

    const params = new URLSearchParams(noUndefinedProperties);

    return params.toString();
}

const STRINGIFIABLE_TYPES = new Set(['string', 'number', 'boolean']);

function isStringifiable(value: unknown): value is Stringifiable {
    return STRINGIFIABLE_TYPES.has(typeof value);
}
