import {
    stylesWhen,
    stylesWhenNot,
} from '@fsa-streamotion/styled-component-helpers';

import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../common/animations';
import {black} from '../../../common/palette';

export {default as IC04Vol} from './04-vol';
export {default as IC05Exp} from './05-exp';
export {default as IC06Play} from './06-play';
export {default as IC07Pau} from './07-pau';
export {default as IC08Cc} from './08-cc';
export {default as IC10Mltv} from './10-mltv';
export {default as IC11AirP} from './11-air-p';
export {default as IC14Skb} from './14-skb';
export {default as IC15Skf} from './15-skf';
export {default as IC16Set} from './16-set';
export {default as IC17Plus} from './17-plus';
export {default as IC19JLive} from './19-j-live';
export {default as IC20Live} from './20-live';
export {default as IC21Mute} from './21-mute';
export {default as IC22Float} from './22-float';
export {default as IC24ArrowL} from './24-arrow-l';
export {default as IC25ArrowR} from './25-arrow-r';
export {default as IC26ArrowU} from './26-arrow-u';
export {default as IC27ArrowD} from './27-arrow-d';
export {default as IC28RelVid} from './28-rel-vid';
export {default as IC30Back} from './30-back';
export {default as IC32PbRate} from './32-pb-rate';
export {default as IC42Error} from './42-error';
export {default as IC72Min} from './72-min';
export {default as IC97Eq} from './97-eq';
export {default as IC103Loading} from './103-loading';
export {default as IC110Dolby} from './110-dolby';
export {default as IC125Grid} from './125-grid';
export {default as IC130Alert} from './130-alert';
export {default as IC134Minus} from './134-minus';
export {default as IC135Cross} from './135-cross';
export {default as IC147Stereo} from './147-stereo';
export {default as IC152MultiAdd1} from './152-multi-add-1';
export {default as IC153MultiAdd2} from './153-multi-add-2';
export {default as IC154MultiAdd3} from './154-multi-add-3';
export {default as IC155MultiAdd4} from './155-multi-add-4';
export {default as IC173KeyMPlayHead} from './173-key-m-play-head';
export {default as IC218XtraInfo} from './218-xtra-info';
export {default as IC220AudioSettings} from './220-audio-settings';
export {default as IC221NextUp} from './221-next-up';
export {default as IC265ClseCapOn} from './265-closed-caption-on';
export {default as IC266ClseCapOff} from './266-closed-caption-off';

/**
 * There are two methods to set the colour of the SVG
 *     1. Set the color of a container element and the colour will be inherited by the SVG
 *     2. Set the color property
 */
const Ic = styled.svg.attrs({
    viewBox: '0 0 100 100',
})`
    display: ${({display}) => display};
    pointer-events: none; /* Don't allow icons to be event targets */

    ${stylesWhen('hasDropshadow')`
        filter: drop-shadow(1px 1px 1px ${black});
    `}

    ${stylesWhenNot('preserveColors')`
        fill: currentColor;

        * {
            transition: ${transition('fill', 'stroke')};
        }
    `}
`;

export type Props = {
    // eslint-disable-next-line react/boolean-prop-naming
    ariaHidden?: boolean;
    ariaLabel?: string;
    children?: React.ReactNode;
    color?: string;
    // eslint-disable-next-line react/boolean-prop-naming
    preserveColors?: boolean;
    display?: 'block' | 'inline-block' | 'flex' | 'inline-flex';
    size?: string;
    hasDropshadow?: boolean;
};
const Icon = ({
    hasDropshadow,
    ariaLabel,
    children,
    color,
    preserveColors,
    display = 'block',
    size = '100%',

    ariaHidden = ariaLabel ? undefined : true,
    ...htmlAttributes
}: Props): React.ReactElement => ( // using aria-hidden true on the assumption that these icons will only be used in the context of a button and are thus decorative
    <Ic
        {...htmlAttributes}
        hasDropshadow={hasDropshadow}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        color={preserveColors ? null : color}
        preserveColors={preserveColors}
        display={display}
        style={
            preserveColors
                ? {
                      fill: color,
                      stroke: color,
                      width: size,
                  }
                : {
                      width: size,
                  }
        }
    >
        {children}
    </Ic>
);

Icon.displayName = 'Icon';

export default Icon;
