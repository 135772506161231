import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import classNames from 'classnames';

import FilterByMenu from '@fsa-streamotion/streamotion-web-fs-ui/src/components/dropdown-menu-group/dropdown-menu-group';
import SeriesSelector from '@fsa-streamotion/streamotion-web-fs-ui/src/components/series-selector/series-selector';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';
import RoundSelector from '@fsa-streamotion/streamotion-web-fs-ui/src/components/item-swipe-selector/item-swipe-selector';
import {getSportNames} from '@fsa/fs-commons/lib/utils/sport-names';
import {getRoundType} from '@fsa-streamotion/streamotion-web-fs-score-centre/src/js/utils/helpers';

import {MEDIA_OTT_URL, ROUND_TYPE} from '../utils/constants';
import MatchCard from './match-card';
import Toggle from './toggle';

const labelSort = ({label: labelA}, {label: labelB}) => {
    if (labelA === labelB) {
        return 0;
    } else if (labelA > labelB) {
        return 1;
    } else {
        return -1;
    }
};

export default class FixtureList extends React.Component {
    renderCard(card, index) {
        return (
            <li key={card.fixture_id} itemProp="itemListElement" itemScope={true} itemType="http://schema.org/ListItem">
                <MatchCard itemProp="item" {...card} sport={this.props.sport} showMatchScore={this.props.showMatchScore} />
                <meta itemProp="name" content={`${card.sport} ${card.round.name} - ${card.team_A.name} vs ${card.team_B.name}`} />
                <meta itemProp="position" content={index} />
            </li>
        );
    }

    renderCards() {
        const cardsOrSpinner = (this.props.roundsLoading) ? <LoadingSpinner /> : this.props.fixturesAndResults.map(this.renderCard, this);
        const olStyle = (this.cardsList && this.props.roundsLoading) ? {height: getComputedStyle(this.cardsList).height} : {};
        const olClass = classNames(
            'fiso-hawk-fixture-list__cards',
            {'fiso-hawk-fixture-list__cards--centralised': this.props.fixturesAndResults.length < 3}
        );

        return (
            <ol
                className={olClass}
                itemScope={true} itemType="http://schema.org/ItemList"
                ref={(ol) => { this.cardsList = ol; }}
                style={olStyle}>
                <meta
                    itemProp="name"
                    content={`${get(this.props.allTeamsAndRounds, '.current_season.current_round.name', '')} ` +
                        `${get(this.props.allTeamsAndRounds, '.current_season.name', '')} Fixtures`} />
                <meta itemProp="itemListOrder" content="Ascending" />
                <meta itemProp="numberOfItems" content={this.props.fixturesAndResults.length} />
                {cardsOrSpinner}
            </ol>
        );
    }

    getSeasonConfig() {
        const allSeasons = get(this.props.allTeamsAndRounds, 'seasons', []);
        const seasonsMenuItems = Object.keys(allSeasons)
            .map((value) => ({label: allSeasons[value].name, value}))
            .sort(labelSort)
            .reverse();

        return {
            name: 'season',
            title: 'Season',
            items: seasonsMenuItems, // Reverse it so it renders in reverse chronological
            selectedIndex: findIndex(allSeasons.slice().reverse(), {id: get(this.props.allTeamsAndRounds, 'season.id')})
        };
    }

    getRoundConfig() {
        const allRounds = get(this.props.allTeamsAndRounds, 'season.rounds', []);
        const items = Object.keys(allRounds)
            .map((value) => ({label: allRounds[value].name, value}))
            .sort(({value: valA}, {value: valB}) => valA - valB); // because round values are numeric
        const {apiName} = getSportNames(this.props.sport);

        if (getRoundType(apiName, this.props.currentSeriesId) !== ROUND_TYPE.rounds) {
            return null;
        }

        return {
            name: 'round',
            title: 'Round',
            items,
            selectedIndex: findIndex(allRounds, {number: this.props.currentRoundNumber})
        };
    }

    getTeamConfig() {
        const allTeams = get(this.props.allTeamsAndRounds, 'season.teams', []);
        const items = Object.keys(allTeams)
            .map((value) => ({label: allTeams[value].name, value}))
            .sort(labelSort);

        // If there are 2 (or fewer) teams, all matches will involve all teams, so there's no point letting the user filter
        if (items.length <= 2) {
            return null;
        }

        return {
            name: 'team',
            title: 'Team',
            items,
            selectedIndex: findIndex(allTeams, {id: this.props.currentTeamId})
        };
    }

    callChangeFilter({season, round, team}) {
        if (season !== undefined) {
            this.props.onSelectSeasonClick(this.props.allTeamsAndRounds.seasons[Number(season)].id);
        } else if (round !== undefined) {
            this.props.onSelectRoundClick(this.props.allTeamsAndRounds.season.rounds[Number(round)].number);
        } else if (team !== undefined) {
            this.props.onSelectTeamClick(this.props.allTeamsAndRounds.season.teams[Number(team)].id);
        }
    }

    render() {
        const {sport} = this.props;
        const rounds = get(this.props.allTeamsAndRounds, 'season.rounds', []).map((round) => {
            if (sport === 'afl' && round.name === 'Finals Week 3') {
                round.name = 'Prelims';
            }

            return round;
        });

        if (!this.seriesIdsListOfCssAlreadyLoaded) {
            this.seriesIdsListOfCssAlreadyLoaded = [this.props.currentSeriesId];
        } else if (this.seriesIdsListOfCssAlreadyLoaded.indexOf(this.props.currentSeriesId) < 0) {
            this.seriesIdsListOfCssAlreadyLoaded.push(this.props.currentSeriesId);
        }
        const seriesLinkTags = this.seriesIdsListOfCssAlreadyLoaded.map((seriesId) => (
            <link rel="stylesheet" key={seriesId} href={`/assets/images/team-logos/${sport}/${seriesId}/208x156/${seriesId}.css`} />
        ));

        const filterByMenuConfig = [
            this.getSeasonConfig(),
            this.getRoundConfig(),
            this.getTeamConfig()
        ];

        const {apiName} = getSportNames(sport);

        const filterByMenuClasses = classNames(
            'fsui-normalise',
            'fiso-hawk-fixture-list__dropdown-menu-group__wrapper',
            {
                'fiso-hawk-fixture-list__dropdown-menu-group__wrapper--has-round':
                getRoundType(apiName, this.props.currentSeriesId) !== ROUND_TYPE.none
            }
        );

        const roundSelectorClasses = classNames(
            'fiso-hawk-fixture-list__round-selector',
            {'fiso-hawk-fixture-list__round-selector--invisible': this.props.currentRoundNumber === null}
        );

        return (
            <section className="fiso-hawk-fixture-list">
                {seriesLinkTags}
                <div className="fiso-hawk-fixture-list__centralised-container">
                    <SeriesSelector
                        onClickUpdateSeries={this.props.onClickUpdateSeries}
                        selectedSeriesId={this.props.currentSeriesId}
                        seriesImageUrl={`${MEDIA_OTT_URL}/series-images`}
                        seriesList={this.props.seriesList}
                        sport={sport} />
                </div>
                <div className="fiso-hawk-fixture-list__centralised-container">
                    <div className={roundSelectorClasses}>
                        <RoundSelector
                            items={rounds}
                            currentItemNumber={this.props.currentRoundNumber}
                            onNewItemSelected={(change) => {
                                this.props.onSelectRoundClick(this.props.currentRoundNumber + change);
                            }}
                            selectorLegend={`${get(this.props.fixturesAndResults, '[0].series.name', '')} Round Selector`} />
                    </div>
                </div>
                <div className="fiso-hawk-fixture-list__centralised-container">
                    <div className={filterByMenuClasses}>
                        <FilterByMenu modalDdlConfigs={filterByMenuConfig} onChange={this.callChangeFilter.bind(this)} />
                    </div>
                </div>
                <div className="fiso-hawk-fixture-list__centralised-container">
                    {this.renderCards()}
                </div>
                <div className="fiso-hawk-fixture-list__toggle fiso-hawk-fixture-list__centralised-container">
                    <Toggle
                        onToggleClick={this.props.onToggleShowScoresClick}
                        toggleOnLabel="Match Score On"
                        toggleOffLabel="Match Score Off" />
                </div>
            </section>
        );
    }
}

FixtureList.defaultProps = {
    showMatchScore: false,
    onToggleShowScoresClick: noop,
    onClickUpdateSeries: noop,
    onSelectTeamClick: noop,
    onSelectRoundClick: noop,
    onSelectSeasonClick: noop,
    currentTeam: null,
    allTeamsAndRounds: {
        current_season: {
            id: 0,
            name: '',
            current_round: {
                name: ''
            }
        }
    },
    fixturesAndResults: [{
        sport: '',
        round: {
            name: ''
        },
        team_A: {
            name: ''
        },
        team_B: {
            name: ''
        }
    }]
};

FixtureList.propTypes = {
    sport: propTypes.string,
    currentSeriesId: propTypes.number,
    currentRoundNumber: propTypes.number,
    currentTeamId: propTypes.number,
    onToggleShowScoresClick: propTypes.func,
    onClickUpdateSeries: propTypes.func,
    onSelectTeamClick: propTypes.func,
    onSelectRoundClick: propTypes.func,
    onSelectSeasonClick: propTypes.func,
    showMatchScore: propTypes.bool,
    roundsLoading: propTypes.bool,
    allTeamsAndRounds: propTypes.shape({
        seasons: propTypes.arrayOf(
            propTypes.shape({
                id: propTypes.number,
                year: propTypes.number,
                name: propTypes.string
            })
        ),
        season: propTypes.shape({
            id: propTypes.number,
            year: propTypes.number,
            name: propTypes.string,
            teams: propTypes.arrayOf(
                propTypes.shape({
                    id: propTypes.number,
                    year: propTypes.number,
                    name: propTypes.string,
                    short_name: propTypes.string
                })
            ),
            rounds: propTypes.arrayOf(
                propTypes.shape({
                    name: propTypes.string,
                    number: propTypes.number,
                    short_name: propTypes.string
                })
            )
        }),
        current_season: propTypes.shape({
            id: propTypes.number,
            year: propTypes.number,
            name: propTypes.string,
            total_rounds: propTypes.number,
            current_round_number: propTypes.number,
            current_round: propTypes.shape({
                name: propTypes.string,
                number: propTypes.number,
                short_name: propTypes.string
            })
        })
    }),
    fixturesAndResults: propTypes.arrayOf(
        propTypes.shape({
            sport: propTypes.string,
            round: propTypes.shape({
                name: propTypes.string
            }),
            team_A: propTypes.shape({
                name: propTypes.string
            }),
            team_B: propTypes.shape({
                name: propTypes.string
            })
        })
    ),
    seriesList: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number,
            label: propTypes.string
        })
    )
};
