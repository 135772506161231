export default class GenericAdsAdapter {
  getVersion() {
    return '7.0.1-react-native-video-ads-jsclass';
  }

  getDuration() {
    return this.duration || 0;
  }

  getResource() {
    return this.resource || 'N/A';
  }

  getPlayhead() {
    const videoAdapter = this.getVideo().getAdapter();
    return videoAdapter ? videoAdapter.playhead : undefined;
  }

  getTitle() {
    return this.title || 'N/A';
  }

  getPosition() {
    var position = this.getNpawReference().Constants.AdPosition.Midroll;
    if (this.getVideo().getAdapter() && !this.getVideo().getAdapter().flags.isJoined) {
      position = this.getNpawReference().Constants.AdPosition.Preroll;
    }
    return position;
  }

  onAdEvent(e) {
    switch (e.event) {
      case 'FIRST_QUARTILE':
        this._quartileHandler(1);
        break;
      case 'MIDPOINT':
        this._quartileHandler(2);
        break;
      case 'THIRD_QUARTILE':
        this._quartileHandler(3);
        break;
      case 'STARTED':
        this.adStartedListener();
        break;
      case 'COMPLETED':
        this.adCompleteListener();
        break;
      case 'CLICK':
        this.adClickListener();
        break;
      case 'PAUSED':
        this.adPauseListener();
        break;
      case 'RESUMED':
        this.adPlayListener();
        break;
      case 'ERROR':
        this.adErrorListener();
        break;
      case 'SKIPPED':
        this.adSkippedListener();
        break;
      case 'CONTENT_RESUME_REQUESTED':
        this.adBreakFinished();
        break;
      case 'CONTENT_PAUSE_REQUESTED':
        const videoAdapter = this.getVideo().getAdapter();
        if (!videoAdapter.flags.isPaused) {
          videoAdapter.firePause();
          videoAdapter.lastPlayhead = videoAdapter.getPlayhead();
        }
        this.showingAds = true;
        break;
      case 'ALL_ADS_COMPLETED':
        if (this.getVideo().getAdapter().reactPlatform && this.getVideo().getAdapter().reactPlatform === 'ios') {
          this.getVideo().getAdapter().fireStop({}, 'onAdEvent');
        }
        break;
    }
  }

  isShowingAds() {
    return this.showingAds;
  }

  _quartileHandler(position) {
    this.fireQuartile(position);
  }

  adStartedListener(e) {
    if (this.getVideo().getAdapter() && this.getVideo().getAdapter().stopTimer) {
      clearTimeout(this.getVideo().getAdapter().stopTimer);
    }
    this.showingAds = true;
    this.getVideo().getAdapter().cancelSeek();
    this.fireStart({}, 'adStartListener');
    this.fireJoin({}, 'adStartListener');
  }

  adPauseListener(e) {
    this.firePause({}, 'adPauseListener');
  }

  adPlayListener(e) {
    this.fireResume({}, 'adPlayListener');
  }

  adSkippedListener(e) {
    this.fireSkip({}, 'adSkippedListener');
  }

  adCompleteListener(e) {
    this.fireStop({}, 'adCompleteListener');
    this.resetValues();
  }

  adClickListener(e) {
    this.fireClick(this.clickThrough);
  }

  adBreakFinished(e) {
    this.fireBreakStop({}, 'adBreakFinished');
    this.showingAds = false;
  }

  adErrorListener(e) {
    if (e.message) {
      var msg = e.message.toLowerCase();
      if (msg.indexOf('ad tag empty') > -1) {
        this.fireManifest(this.getNpawReference().Constants.ManifestError.EMPTY, e.message);
      } else if (msg.indexOf('error loading ad tag') > -1 || msg.indexOf('invalid ad tag') > -1 || msg.indexOf('any valid ads') > -1) {
        this.fireManifest(this.getNpawReference().Constants.ManifestError.WRONG, e.message);
      } else {
        if (e.adErrorCode && (e.adErrorCode === 60006 || e.adErrorCode === 10403)) {
          this.fireManifest(this.getNpawReference().Constants.ManifestError.WRONG, e.message);
        } else {
          this.fireError(e.adErrorCode, e.message);
          this.fireStop();
          this.resetValues();
          if (msg.indexOf('timeout') > -1) {
            this.fireBreakStop();
          }
        }
      }
    }
  }

  resetValues() {
    this.playhead = undefined;
    this.duration = undefined;
    this.position = undefined;
    this.resource = undefined;
    this.title = undefined;
    this.showingAds = false;
  }
}
