import superagent from 'superagent';

import {streamFromApiWithRetry, getVimondHeaders, authorizationToken} from '../../utils/api';
import {DEFAULT_VIMOND_ENV, VIMOND_MIDDLELAYER_URL} from '../../utils/constants';

export default function ({
    sport = 'afl',
    userId,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const authToken = authorizationToken();

    if (!authToken) {
        return;
    }

    // Below URL for middle layer thingy.
    // const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}layer/order/web/user/${userId}?include=historic`;

    // This url for api thingy.... because yay different.
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user/${userId}/orders/history`;

    const apiCall = () => superagent
        .get(url)
        .set(getVimondHeaders({authToken, sport, version: 2}));

    return streamFromApiWithRetry({apiCall}).map('.body');
}
