import React from 'react';
import bacon from 'baconjs';
import md5 from 'js-md5';
import get from 'lodash/get';

import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import {logoutUser, isUserLoggedIn} from '../utils/auth';

import NavigationComponent from '../components/navigation';
import getUserFromLocalStorageStream from '../streams/user/user-get-local-storage';
import getUserOrders from '../streams/user/user-get-orders';
import getUserFutureOrders from '../streams/user/user-get-future-orders';
import {redirectPage, getLoginRedirectUrl, getCurrentPath} from '../utils/location';
import {trackLoggedInStatus} from '../utils/event-tracking/authentication';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 180; // 5 minutes.

function Navigation(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }

    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        navigationHeader: JSON.parse(this.settings.navigationHeader),
        navigationSidebar: JSON.parse(this.settings.navigationSidebar),
        logoutUrl: this.settings.logoutUrl,
        loginUrl: this.settings.loginUrl,
        profileUrl: this.settings.profileUrl,
        selectedHeaderTabIndex: asNumber(this.settings.selectedHeaderTabIndex, undefined),
        vimondEnv: settings.vimondEnv
    };
}

Navigation.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Navigation.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Navigation',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Navigation.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <NavigationComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Navigation.prototype.getData = function () {
    const {navigationHeader, navigationSidebar, logoutUrl, loginUrl, profileUrl, selectedHeaderTabIndex, sport, vimondEnv} = this.config;
    const userDetails$ = getUserFromLocalStorageStream();
    const userName$ = userDetails$
        .map('.fullName').startWith('');
    const imageSize = 48;
    const userImage$ = userDetails$
        .map((data) => `https://www.gravatar.com/avatar/${md5(get(data, 'email', '').toLowerCase())}?s=${imageSize}&d=mm`)
        .startWith('');
    const pathName = getCurrentPath();
    const shouldRenderActionItems$ = bacon.constant(process && process.browser);
    const shouldRenderSubscribeButton$ = bacon.constant(pathName !== '/');

    const hasCurrentOrder$ = userDetails$
        .flatMapLatest((userDetails) => userDetails && getUserOrders({
            sport,
            userId: userDetails.id,
            vimondEnv
        }))
        .map('.0')
        .map(Boolean);

    const hasFutureOrder$ = userDetails$
        .flatMapLatest((userDetails) => userDetails && getUserFutureOrders({
            sport,
            userId: userDetails.id,
            vimondEnv
        }))
        .map('.0')
        .map(Boolean);

    const hasActiveOrder$ = bacon.combineWith(
        hasCurrentOrder$, hasFutureOrder$,
        ((hasCurrentOrder, hasFutureOrder) => hasCurrentOrder || hasFutureOrder)
    );

    const hasActiveSubscription$ = bacon
        .combineWith(
            shouldRenderActionItems$, hasActiveOrder$,
            (shouldRender, hasActiveOrder) => shouldRender && hasActiveOrder
        )
        .flatMapLatest((hasActiveOrder) => hasActiveOrder);

    const userLoggedIn = isUserLoggedIn();

    // Analytics always wants to know if we're logged in (for context)
    trackLoggedInStatus(userLoggedIn);

    const navigationSidebarWithRender = navigationSidebar
        .map((sidebarItem) => {
            let shouldRender;

            switch (sidebarItem.name.toLowerCase()) {
                case 'subscribe':
                    shouldRender = !userLoggedIn;
                    break;

                case 'my profile':
                    shouldRender = userLoggedIn;
                    break;

                default:
                    shouldRender = true;
                    break;
            }

            return Object.assign(
                {},
                sidebarItem,
                {
                    shouldRender
                }
            );
        });

    /* eslint-disable multiline-ternary */
    const updatedLoginUrl = ((pathName === '/' || pathName === loginUrl) ?
        loginUrl :
        getLoginRedirectUrl({
            loginRoute: loginUrl
        })
    );
    /* eslint-enable multiline-ternary */

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            shouldRenderActionItems: shouldRenderActionItems$,
            shouldRenderSubscribeButton: shouldRenderSubscribeButton$,
            hasActiveSubscription: hasActiveSubscription$,
            loginUrl: updatedLoginUrl,
            onLogout: () => {
                logoutUser();
                trackLoggedInStatus(false);
                redirectPage();
            },
            logoutUrl,
            profileUrl,
            navigationHeader,
            navigationSidebar: navigationSidebarWithRender,
            userImage: userImage$,
            imageSize,
            userName: userName$,
            selectedHeaderTabIndex,

            sportDetails: getSportDetails(sport)
        }),
        iso: {}
    });
};

Navigation.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <NavigationComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <NavigationComponent {...data.view} />,
            'hawkwidgets-navigation',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

Navigation.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

Navigation.prototype.pageBoot = function () {
    pageBoot(Navigation, 'hawkwidgets-navigation');
};

pageBoot(Navigation, 'hawkwidgets-navigation');

export default function (element, settings) {
    return new Navigation(element, settings);
}
