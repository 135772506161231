import type RxPlayer from 'rx-player';
import type {IAudioTrack, IRepresentationFilterRepresentation} from 'rx-player/types';

import NativeDiagnostics from '../native/diagnostics';

export default class RxPlayerDiagnostics extends NativeDiagnostics {
    declare rxPlayerInstance: RxPlayer;
    #container = 'RxPlayer';
    #playbackHandlerName = 'MSE/EME';
    fragmentType: string | undefined;

    override playbackHandlerType = 'rx-player';

    constructor(
        rxPlayerInstance: RxPlayer,
        videoElement: HTMLVideoElement,
        src: string,
        cdnProvider: string,
        hasSsai: boolean
    ) {
        super(videoElement, src, cdnProvider, hasSsai);
        this.rxPlayerInstance = rxPlayerInstance;
    }

    get videoRepresentation(): IRepresentationFilterRepresentation | undefined | null {
        return this.rxPlayerInstance.getVideoRepresentation();
    }

    get currentAudioTrack(): IAudioTrack | undefined | null {
        return this.rxPlayerInstance.getAudioTrack();
    }

    override destroy(): void {
        super.destroy();
    }

    override get videoHeight(): number {
        return this.videoRepresentation?.height || super.videoHeight;
    }

    override get videoWidth(): number {
        return this.videoRepresentation?.width || super.videoWidth;
    }

    override get videoCodec(): string | undefined {
        return this.videoRepresentation?.codecs?.[0];
    }

    override get audioCodec(): string | undefined {
        return this.currentAudioTrack?.representations?.[0]?.codec;
    }

    override get frameRate(): number | undefined {
        return this.videoRepresentation?.frameRate;
    }

    override get audioTrackName(): string | undefined {
        return (
            this.currentAudioTrack?.label || this.currentAudioTrack?.language
        );
    }

    get audioBitrate(): number {
        return this.currentAudioTrack?.representations?.[0]?.bitrate ?? 0;
    }

    get videoBitrate(): number {
        return this.videoRepresentation?.bitrate ?? 0;
    }

    override get bufferedSeconds(): number | undefined {
        return this.rxPlayerInstance.getCurrentBufferGap();
    }

    override get playbackHandlerName(): string {
        return this.#playbackHandlerName;
    }

    override get container(): string {
        const currentMode = this.rxPlayerInstance.getCurrentModeInformation();

        if (currentMode?.useWorker) {
            return `${this.#container} - (Worker)`;
        }

        return this.#container;
    }

    override get playerVersion(): string {
        const playerVersion = window.RxPlayer?.version;
        const currentMode = this.rxPlayerInstance.getCurrentModeInformation()
            ?.useWorker
            ? 'multi'
            : 'single';

        return [`rxplayer-${currentMode}`, playerVersion]
            .filter(Boolean)
            .join(' ');
    }
}
