import noop from 'lodash/noop';

import {asString} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {
    getLocalStorageValue,
    removeLocalStorageValue
} from '@fsa/fs-commons/lib/utils/storage';
import nicerStringify from '@fsa/fs-commons/lib/utils/nicer-stringify';

import {redirectPage} from './location';
import {
    AUTHENTICATION_LOCAL_STORAGE_KEY,
    USER_LOCAL_STORAGE_KEY,
    SUBSCRIPTION_PACK_ID_LOCAL_STORAGE_KEY,
    LOGIN_REQUIRED_PARAM
} from './constants';

/**
 * SO!  Using exports. because yay es6 doesn't let me stub inter-related functions.
 * Please don't just randomly convert me to es6.
 */
/* eslint-disable import/no-commonjs */

/**
 * Checks if the user is logged in
 *   Redirects if not found. Defaults to /
 *
 * @param  {Function} callbackOkayAuth   Function to call/return if logged in
 * @param  {String}   options.loginRoute Login url to direct user
 * @param  {String}   redirectParam      Querystring key to check for
 * @return {Mixed}                       Returns callback output if logged in
 */
exports.redirectNoLogin = function (
    callbackOkayAuth = noop,
    {
        loginRoute = '/login',
        redirectParam = 'return'
    } = {}
) {
    if (!process || !process.browser) {
        return callbackOkayAuth();
    }

    const redirectParams = nicerStringify({
        [redirectParam]: window.location.pathname,
        [LOGIN_REQUIRED_PARAM]: true
    });

    const redirectUrl = `${loginRoute}?${redirectParams}`;

    if (exports.isUserLoggedIn()) {
        return callbackOkayAuth();
    } else {
        redirectPage(redirectUrl);

        return;
    }
};

/**
 * Checks if the user is considered authenticated or not.
 *
 * @return {Boolean} True
 */
exports.isUserLoggedIn = function () {
    return !!getLocalStorageValue({key: 'auth'});
};

/**
 * Removes auth related keys from local storage or cookies.
 *  Esentially logs out users.
 *
 * @return {void}
 */
exports.logoutUser = function () {
    removeLocalStorageValue(AUTHENTICATION_LOCAL_STORAGE_KEY);
    removeLocalStorageValue(USER_LOCAL_STORAGE_KEY);
    removeLocalStorageValue(SUBSCRIPTION_PACK_ID_LOCAL_STORAGE_KEY);
};

exports.getUserDetail = function () {
    return getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY, defaultValue: {}});
};

exports.getSubscriptionPackId = function () {
    return asString(getLocalStorageValue({key: SUBSCRIPTION_PACK_ID_LOCAL_STORAGE_KEY, defaultValue: ''}));
};
