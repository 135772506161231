import {
    PLAYER_LAYOUT_STYLE,
    type PlayerLayoutStyleValue,
} from '@fsa-streamotion/player-state';

type CreateTransformRuleParams = {
    translateXPerc?: number;
    translateYPerc?: number;
    scale?: string;
    opacity?: number;
};

type CreatedTransformRule = {
    opacity?: number;
    width?: string;
    height?: string;
    transform: string;
    zIndex: number;
};

function createTransformRule(
    {
        translateXPerc = 0,
        translateYPerc = 0,
        scale,
        ...restStyles
    }: CreateTransformRuleParams = {},
    index: number
): CreatedTransformRule {
    return {
        ...restStyles,
        // We don't use transform scale because that would also scale the children of these screens (like center controls), which we don't want
        ...(scale &&
            typeof Number(scale) === 'number' && {
                width: `${Number(scale) * 100}%`,
                height: `${Number(scale) * 100}%`,
            }),
        // Since transform is relative to width/height, we need to account for the scale
        transform:
            translateXPerc || translateYPerc
                ? `translate(${(1 / Number(scale)) * translateXPerc}%, ${
                      (1 / Number(scale)) * translateYPerc
                  }%)`
                : 'none',
        zIndex: index,
    };
}

function createScaleRule({scale}: {scale?: string} = {}): {transform?: string} {
    // Math.max is to prevent the controls scale too small inside the screen.
    return scale && typeof Number(scale) === 'number'
        ? {transform: `scale(${Math.max(Number(scale), 0.5)})`}
        : {};
}

const HIDDEN_SCREEN = {
    opacity: 0,
    scale: '0',
};

const PIP_VIDEO = {
    scale: '0.24',
    translateXPerc: 72,
    translateYPerc: 59,
};

const MAIN_VIDEO = {};

const TWO_UP_LEFT = {
    scale: '0.49',
    translateYPerc: 25,
};

const TWO_UP_RIGHT = {
    scale: '0.49',
    translateXPerc: 51,
    translateYPerc: 25,
};

const THREE_UP_MAIN = {
    scale: '0.67',
    translateYPerc: 16,
};

const THREE_UP_ANCILLARY_BASE = {
    scale: '0.32',
};

const THREE_UP_ANCILLARY_TOP = {
    translateXPerc: 68,
    translateYPerc: 16,
    ...THREE_UP_ANCILLARY_BASE,
};

const THREE_UP_ANCILLARY_BOTTOM = {
    translateXPerc: 68,
    translateYPerc: 51,
    ...THREE_UP_ANCILLARY_BASE,
};

const FOUR_UP_MAIN = {
    scale: '0.75',
    translateYPerc: 12.5,
};

const FOUR_UP_ANCILLARY_BASE = {
    scale: '0.24',
};

const FOUR_UP_ANCILLARY_TOP = {
    translateXPerc: 76,
    translateYPerc: 12.5,
    ...FOUR_UP_ANCILLARY_BASE,
};

const FOUR_UP_ANCILLARY_MIDDLE = {
    translateXPerc: 76,
    translateYPerc: 38,
    ...FOUR_UP_ANCILLARY_BASE,
};

const FOUR_UP_ANCILLARY_BOTTOM = {
    translateXPerc: 76,
    translateYPerc: 63.5,
    ...FOUR_UP_ANCILLARY_BASE,
};

const GRID_BASE = {
    scale: '0.49',
};

const GRID_TOP_LEFT = {
    ...GRID_BASE,
};

const GRID_TOP_RIGHT = {
    ...GRID_BASE,
    translateXPerc: 50.5,
};

const GRID_BOTTOM_LEFT = {
    ...GRID_BASE,
    translateYPerc: 50.5,
};

const GRID_BOTTOM_RIGHT = {
    ...GRID_BASE,
    translateXPerc: 50.5,
    translateYPerc: 50.5,
};

export const TRANSFORMS_PER_PLAYER_ORDER = {
    [PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE]: [MAIN_VIDEO],
    [PLAYER_LAYOUT_STYLE.LAYOUT_PIP]: [MAIN_VIDEO, PIP_VIDEO],
    [PLAYER_LAYOUT_STYLE.LAYOUT_2UP]: [TWO_UP_LEFT, TWO_UP_RIGHT],
    [PLAYER_LAYOUT_STYLE.LAYOUT_3UP]: [
        THREE_UP_MAIN,
        THREE_UP_ANCILLARY_TOP,
        THREE_UP_ANCILLARY_BOTTOM,
    ],
    [PLAYER_LAYOUT_STYLE.LAYOUT_4UP]: [
        FOUR_UP_MAIN,
        FOUR_UP_ANCILLARY_TOP,
        FOUR_UP_ANCILLARY_MIDDLE,
        FOUR_UP_ANCILLARY_BOTTOM,
    ],
    [PLAYER_LAYOUT_STYLE.LAYOUT_GRID]: [
        GRID_TOP_LEFT,
        GRID_TOP_RIGHT,
        GRID_BOTTOM_LEFT,
        GRID_BOTTOM_RIGHT,
    ],
};

/**
 *
 * @param playerLayout - The current layout of the player
 * @param screenOrder - The order of the screens (e.g. after reordering)
 * @returns The styles each screen should add, adjusted for screen index
 */
export default function getScreenLayoutStyles(
    playerLayout: PlayerLayoutStyleValue,
    screenOrder: number[]
): CreatedTransformRule[] {
    return screenOrder.map((positionIndex) =>
        createTransformRule(
            TRANSFORMS_PER_PLAYER_ORDER[playerLayout][positionIndex] ||
                HIDDEN_SCREEN,
            positionIndex
        )
    );
}

export function getScalingStylesByIndex(
    playerLayout: PlayerLayoutStyleValue,
    positionIndex: number
): {transform?: string} {
    return createScaleRule(
        TRANSFORMS_PER_PLAYER_ORDER[playerLayout][positionIndex]
    );
}
