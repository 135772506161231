import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {white} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../common/theme-helpers';
import {TRACK_HEIGHT_PX, TRACK_HEIGHT_PX__LARGE_SCREENS} from './constants';

const ProgressBar = styled.progress`
    --smwplayer-value-background: ${({theme, isDuration}) =>
        (isDuration ? theme.brandColor : white) ?? white};
    appearance: none;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: none;
    border: none;
    border-radius: 0;
    background-color: ${rgba(white, 0.25)};
    padding: 0;
    height: ${TRACK_HEIGHT_PX}px;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        height: ${TRACK_HEIGHT_PX__LARGE_SCREENS}px;
    `}

    &::-webkit-progress-bar {
        background-color: inherit;
    }

    &::-webkit-progress-value {
        transition: none;
        border-radius: 0;
        background: var(--smwplayer-value-background);
        ${stylesWhen('isDuration')`
            min-width: 8px;
        `}
        max-width: 100%;
    }

    &::-moz-progress-bar {
        transition: none;
        border-radius: 0;
        background: var(--smwplayer-value-background);
        ${stylesWhen('isDuration')`
            min-width: 8px;
        `}
        max-width: 100%;
    }

    &::-ms-fill {
        background: var(--smwplayer-value-background);
    }
`;

export type Props = {
    /** Current progress percentage (0-100) */
    current?: number;
    /** Bar ends percentage (0-100) */
    end?: number;
    /** Bar starts percentage (0-100) */
    start?: number;
    /** Is this the duration bar (brand styling) */
    isDuration?: boolean;
    /** Does this represent the current time of an ad? */
    isPlayingAd?: boolean;
};

const SliderControlProgressBar = ({
    start = 0,
    current = 0,
    end = 0,
    isDuration,
    isPlayingAd,
}: Props): React.ReactElement => (
    <ProgressBar
        aria-hidden="true"
        max={100}
        style={{
            left: `${start}%`,
            width: `${end - start}%`,
        }}
        value={isDuration ? (current / end) * 100 || 0 : 100}
        isDuration={isDuration}
        isPlayingAd={isPlayingAd}
    />
);

SliderControlProgressBar.displayName = 'SliderControlProgressBar';

export default SliderControlProgressBar;
