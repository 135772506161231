import {SCRUBBER_TYPE} from '@fsa-streamotion/player-state';

import {observer} from 'mobx-react-lite';
import React from 'react';

import useThrottle from '../../../common/use-throttle';
import IM02Scrubber from '../../../component-library/molecules/im/02-scrubber';
import {useChromecastSenderContext} from '../context';

const Scrubber = observer(() => {
    // make setCurrentTime function only invoke once every 500ms.
    // every setCurrentTime need 500ms to let ccast tell us if we can seek or not
    const SET_CURRENT_TIME_THROTTLE_TIME = 500;
    const chromecastSenderContext = useChromecastSenderContext();
    const {
        currentTime,
        duration,
        setCurrentTime,
        isSeekingDisabled,
        isPlayingAd,
        scrubBarType,
    } = chromecastSenderContext?.values ?? {};

    const throttledSetCurrentTime =
        setCurrentTime &&
        useThrottle(
            {
                fn: setCurrentTime,
                waitMs: SET_CURRENT_TIME_THROTTLE_TIME,
            },
            []
        );

    // TODO: Modify this feature flag to common condition after the `scrubbarType` migration complete on Kayo and Flash.
    const shouldLockScrubberToEdge = scrubBarType
        ? SCRUBBER_TYPE[scrubBarType] === SCRUBBER_TYPE.none
        : false;

    return (
        <IM02Scrubber
            currentTime={currentTime}
            duration={duration}
            onChange={throttledSetCurrentTime}
            isSeekingDisabled={isSeekingDisabled}
            isPlayingAd={isPlayingAd}
            isLockedToEdge={shouldLockScrubberToEdge}
        />
    );
});

export default Scrubber;
