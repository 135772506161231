import {DEFAULT_ERROR_MESSAGES} from './utils/error-codes';

const isDefaultErrorCode = (
    code: number
): code is keyof typeof DEFAULT_ERROR_MESSAGES =>
    Object.keys(DEFAULT_ERROR_MESSAGES).some((key) => key === code.toString());

export default class MediaError {
    code = 0;
    message = '';

    constructor(code: number, message?: string) {
        this.code = code;
        this.message =
            message ||
            (isDefaultErrorCode(code) && DEFAULT_ERROR_MESSAGES[code]) ||
            DEFAULT_ERROR_MESSAGES[0];
    }
}
