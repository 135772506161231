import noop from 'lodash/noop';

const loadedStyles  = [];
const loadedScripts = [];
const requestedScripts = [];
const callbackStack = {};

exports.loadStyle = function (url, finishCallback = noop, skipCache = false) {
    if (!process.browser) {
        finishCallback();

        return;
    }

    if (skipCache === false && loadedStyles.indexOf(url) !== -1) {
        finishCallback();

        return;
    }

    if (document.createStyleSheet) {
        // IE 9-10 targeted
        document.createStyleSheet(url);
    } else {
        // Normal browsers :P
        let stylesheet  = document.createElement('link');

        stylesheet.rel  = 'stylesheet';
        stylesheet.href = url;
        // .onload
        // .onerror = Not enough browser support for these kids.

        document.getElementsByTagName('head')[0].appendChild(stylesheet);
    }

    loadedStyles.push(url);
    finishCallback();
};

exports.loadScript = function (url, finishCallback = noop, errorCallback = false, skipCache = false, id) {
    let script;
    let onSuccess;
    let onError;
    const onErrorCallback = errorCallback || finishCallback;
    const removeRequestedUrlEntry = function (url) {
        const index = requestedScripts.indexOf(url);

        if (index !== -1) {
            requestedScripts.splice(index, 1);
        }
    };

    if (!process.browser || (id && document.getElementById(id))) {
        finishCallback();

        return;
    }

    if (skipCache === false && loadedScripts.indexOf(url) !== -1) {
        finishCallback();

        return;
    }

    if (requestedScripts.indexOf(url) > -1) { // there is a request already
        callbackStack[url] = callbackStack[url] || {
            success: [],
            error: []
        };

        callbackStack[url].success.push(finishCallback);
        callbackStack[url].error.push(onErrorCallback);

        return;
    }

    requestedScripts.push(url);

    onSuccess = function () {
        removeRequestedUrlEntry(url);
        loadedScripts.push(url);
        finishCallback();

        if (callbackStack[url]) {
            callbackStack[url].success
                .forEach((callback) => { callback(); });
        }
    };

    onError = function () {
        removeRequestedUrlEntry(url);
        onErrorCallback();

        if (callbackStack[url]) {
            callbackStack[url].error
                .forEach((callback) => { callback(); });
        }
    };

    script = document.createElement('script');

    if (id) {
        script.id = id;
    }

    script.onload  = onSuccess; // Supports everything for != IE6-8
    script.onerror = onError; // Zip support for ie8, but everything else looks solid.
    script.src     = url;

    document.getElementsByTagName('head')[0].appendChild(script);
};
