import bacon from 'baconjs';

import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {AUTHENTICATION_LOCAL_STORAGE_KEY} from '../utils/constants';

export const UNAUTHENTICATED_ERROR_MESSAGE = 'FS-HAWK-WIDGETS: Unauthorized Request';

export default function () {
    if (!process || !process.browser) {
        return bacon.later(0, undefined);
    }

    const authenticated = getLocalStorageValue({key: AUTHENTICATION_LOCAL_STORAGE_KEY}, null);

    return authenticated ? bacon.later(0, authenticated) : bacon.later(0, new bacon.Error(UNAUTHENTICATED_ERROR_MESSAGE));
}
