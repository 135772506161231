import type Hls from 'hls.js';
// eslint-disable-next-line no-duplicate-imports
import {type HlsListeners} from 'hls.js';

import type PlaybackHls from '.';
import {ERROR_CODES} from '../../utils/error-codes';
import NativeError from '../native/error';
import {Events} from './types';

const ERROR_RETRY_RECOVERY_TIME_MS = 15 * 1000; // We'll give ourselves 15 seconds to recover between retries in hls

export default class HlsError extends NativeError {
    declare playbackHandler: Hls;
    declare playbackTech: PlaybackHls;
    playing = false;
    lastNetworkErrorRecovery: number | null = null;
    lastMediaErrorRecovery: number | null = null;
    lastMediaErrorAudioSwap: number | null = null;

    constructor(playbackTech: PlaybackHls, playbackHandler: Hls) {
        super(playbackTech, playbackHandler);

        this.playbackHandler.on(Events.ERROR, this.onEventError);
        this.videoElement.addEventListener('play', this.onEventPlay);
        this.videoElement.addEventListener('pause', this.onEventPause);
        this.videoElement.addEventListener('ended', this.onEventEnded);

        // Use the following to lamely attempt to simulate what happens in a MediaError recovery mode.
        // Adjust the timers are required to see if the retry retries or hard-fails.

        /*
        // Throws random media error.
        let counter = 0;

        setInterval(() => {
            counter++;
            console.log(`counter: ${counter}`);

            if (counter === 3) {
                console.warn('MEDIA ERROR RECOVER 1!');
                this.onEventError(null, {fatal: true, type: 'mediaError'});
            } else if (counter === 6) {
                console.warn('MEDIA ERROR RECOVER 2!');
                this.onEventError(null, {fatal: true, type: 'mediaError'});
            } else if (counter === 10) {
                console.warn('MEDIA ERROR RECOVER 3!');
                this.onEventError(null, {fatal: true, type: 'mediaError'});
            }
        }, 1000);
        */

        // Throws random 2 network errors. 3s then 3s later.
        /*
        setTimeout(() => {
            this.onEventError(null, {fatal: true, type: 'networkError'});
            setTimeout(() => {
                this.onEventError(null, {fatal: true, type: 'networkError'});
            }, 3000);
        }, 6000);
        */
    }

    override destroy(): void {
        this.playbackHandler.off(Events.ERROR, this.onEventError);
        this.videoElement.removeEventListener('play', this.onEventPlay);
        this.videoElement.removeEventListener('pause', this.onEventPause);
        this.videoElement.removeEventListener('ended', this.onEventEnded);
        super.destroy();
    }

    onEventPlay = (): void => {
        this.playing = true;
    };

    onEventPause = (): void => {
        this.playing = false;
    };

    onEventEnded = (): void => {
        this.playing = false;
    };

    onEventError: HlsListeners[Events.ERROR] = (_, data) => {
        const errorType = data.type;
        const errorDetails = data.details;
        const errorFatal = data.fatal;

        const isBuffering =
            this.playbackTech.controllerBufferInstance?.isBuffering;

        if (!errorFatal) {
            if (errorType !== 'networkError') {
                return;
            }

            if (!isBuffering || !this.attemptNetworkErrorRecovery()) {
                // prevent show error card if
                // 1. video is still playing
                // 2. or error only occurs once within ERROR_RETRY_RECOVERY_TIME_MS
                return;
            }
        }

        const errorMessage = [errorDetails, errorType]
            .filter(Boolean)
            .join(' ');

        switch (errorDetails) {
            case 'manifestLoadError':
                // Don't retry manifest loading errors. The retry doesn't correctly work on these.
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_NETWORK,
                    errorMessage,
                    data
                );

            case 'manifestIncompatibleCodecsError':
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_DECODE,
                    errorMessage,
                    data
                );

            default:
            // If the detail is specific enough for cases above, move onto generic
            // error handling. (Network/mediaError stuffs)
            // falls-through
        }

        switch (errorType) {
            case 'mediaError':

            case 'muxError':
                if (this.attemptMediaErrorRecovery()) {
                    return void this.onError(
                        ERROR_CODES.MEDIA_ERR_SRC_NOT_SUPPORTED,
                        errorMessage,
                        data
                    );
                } else {
                    return;
                }

            case 'networkError':
                return void this.onError(
                    ERROR_CODES.MEDIA_ERR_NETWORK,
                    errorMessage,
                    data
                );

            case 'otherError':
            default:
                return void this.onError(
                    ERROR_CODES.CUSTOM_ERR_UNKNOWN,
                    errorMessage,
                    data
                );
        }
    };

    /**
     * Attempts to recover from fatal network error in HLS.js
     * If a retry has already been made within ERROR_RETRY_RECOVERY_TIME_MS,
     * further attempts are not attempted.
     *
     * @returns True if the attempt to recover is failed (or isn't attempted), False if we handled it this time.
     */
    attemptNetworkErrorRecovery(): boolean {
        const now = performance.now();

        if (
            !this.lastNetworkErrorRecovery ||
            now - this.lastNetworkErrorRecovery > ERROR_RETRY_RECOVERY_TIME_MS
        ) {
            console.warn(
                'VideoFS-HLS: Fatal network error encountered, try to recover'
            );
            this.lastNetworkErrorRecovery = now;
            this.playbackHandler.startLoad();

            return false;
        } else {
            console.error(
                'VideoFS-HLS: Fatal network error encountered, retry failed'
            );

            return true;
        }
    }

    /**
     * Attempts a media error recovery (and audio swap) in HLS.js.
     * If a retry and audio swap has already been made within ERROR_RETRY_RECOVERY_TIME_MS,
     * further attempts are not attempted.
     *
     * @returns True if the attempt to recover is failed (or isn't attempted), False if we handled it this time.
     */
    attemptMediaErrorRecovery(): boolean {
        const now = performance.now();
        const wasPlaying = this.playing;

        if (
            !this.lastMediaErrorRecovery ||
            now - this.lastMediaErrorRecovery > ERROR_RETRY_RECOVERY_TIME_MS
        ) {
            console.warn(
                'VideoFS-HLS: Fatal media error encountered, try to recover with recoverMediaError'
            );
            this.lastMediaErrorRecovery = now;
            this.playbackHandler.recoverMediaError(); // causes a played video to stop playing/pause
            this.playbackHandler.startLoad();

            if (wasPlaying) {
                setTimeout(() => void this.videoElement.play());
            }

            return false;
        } else if (
            !this.lastMediaErrorAudioSwap ||
            now - this.lastMediaErrorAudioSwap > ERROR_RETRY_RECOVERY_TIME_MS
        ) {
            console.warn(
                'VideoFS-HLS: Fatal media error encountered, try to recover with swapAudioCodec'
            );
            this.lastMediaErrorAudioSwap = now;
            this.playbackHandler.swapAudioCodec();
            this.playbackHandler.recoverMediaError(); // causes a played video to stop playing/pause
            this.playbackHandler.startLoad();

            if (wasPlaying) {
                setTimeout(() => void this.videoElement.play());
            }

            return false;
        } else {
            console.error(
                'VideoFS-HLS: Fatal media error encountered, retry failed'
            );

            return true;
        }
    }
}
