import type PlayerTech from '@fsa-streamotion/player-tech';

import NpawPluginAdapters from 'npaw-plugin-adapters';

import type {
    YouboraAdapter,
    VideoErrorDetail,
    YouboraAdapterConfig,
} from '../../state/types';
import {AUDIO_CODEC_CODE} from '../constants';

const NpawPluginVideoHtml5Adapter = NpawPluginAdapters.video.Html5;

export type AdapterCreator = {
    new (): YouboraAdapter;
};

type Params = {
    playerTechVersion: string;
    playerTech: PlayerTech;
    adapterConfig?: YouboraAdapterConfig;
    videoErrorHandler: (event: VideoErrorDetail) => void;
};

function createVideoFsHtml5Adapter({
    playerTechVersion,
    playerTech,
    adapterConfig,
    videoErrorHandler,
}: Params): AdapterCreator {
    // Use closure instead of a field in class because Youbora will not treat our adapter as a class.
    // It uses our adapter like a method collection, and will copy all methods to its inner adapter instance.
    let isTimeUpdateEventFired = false;

    class VideoFsHtml5Adapter extends NpawPluginVideoHtml5Adapter {
        flags?: YouboraAdapter['flags'];
        getNpawUtils?: YouboraAdapter['getNpawUtils'];

        getPlayerName(): ReturnType<YouboraAdapter['getPlayerName']> {
            return 'VideoFS';
        }

        getPlayerVersion(): ReturnType<YouboraAdapter['getPlayerVersion']> {
            return `pt${playerTechVersion}`;
        }

        getResource(): ReturnType<YouboraAdapter['getResource']> {
            return playerTech.currentSource;
        }

        getRendition(): ReturnType<YouboraAdapter['getRendition']> {
            const {width, height, bitrate} = playerTech.currentBitrateLevel;

            return bitrate
                ? this.getNpawUtils?.().buildRenditionString(
                      width,
                      height,
                      bitrate
                  )
                : // Before send the start request, youbora calls getIsLive and getRendition to get all the required data.
                  // In Safari browser, the bitrate is always undefined, so here return a string to make youbora send the start request correctly.
                  'unknown';
        }

        getBitrate(): ReturnType<YouboraAdapter['getBitrate']> {
            return (
                playerTech.bitrateLevels?.[playerTech.bitrateCurrentIndex]
                    ?.bitrate || null
            );
        }

        getPlayrate(): ReturnType<YouboraAdapter['getPlayrate']> {
            // In before Ming comments on their invalid camelCase.
            // During pause apparently our playRate needs to say 0.
            if (!playerTech.isPlaying) {
                return 0;
            }

            // Otherwise return the current playbackRate.
            return playerTech.playbackRate;
        }

        getAudioCodec(): ReturnType<YouboraAdapter['getAudioCodec']> {
            const fullAudioCodec =
                playerTech.audioTracks?.[playerTech.currentAudioTrackIndex]
                    ?.codec;
            const codec = fullAudioCodec?.match(/codecs="([^"]+)"/)?.[1] ?? '';

            return AUDIO_CODEC_CODE.get(codec) || fullAudioCodec;
        }

        getDroppedFrames(): ReturnType<YouboraAdapter['getDroppedFrames']> {
            return playerTech.diagnostics?.droppedFrames; // fantastically, this value resets when the source changes :D
        }

        // Prevent Youbora to `fireSeekBegin` due to PS4 and Hisense U4/U6 will send seeking event when loading video
        seekingListener(): ReturnType<YouboraAdapter['seekingListener']> {
            return;
        }

        // Prevent Youbora to `fireStop` due to `loadstart` event since the event might be triggered when switching DASH periods on some devices.
        loadStartListener(): ReturnType<YouboraAdapter['loadStartListener']> {
            return;
        }

        //  Prevent Youbora call `fireStop` due to the playback handler still not fully destroyed, we'll call `fireStop` manually on the clean up function
        endedListener(): ReturnType<YouboraAdapter['endedListener']> {
            return;
        }

        stalledListener(): ReturnType<YouboraAdapter['stalledListener']> {
            return;
        }

        bufferingListener(): ReturnType<YouboraAdapter['bufferingListener']> {
            return;
        }

        errorListener(
            event: VideoErrorDetail
        ): ReturnType<YouboraAdapter['errorListener']> {
            videoErrorHandler(event);
        }

        timeupdateListener(
            e?: Event
        ): ReturnType<YouboraAdapter['timeupdateListener']> {
            if (!isTimeUpdateEventFired) isTimeUpdateEventFired = true;

            super.timeupdateListener(e);
        }

        playingListener(
            e?: Event
        ): ReturnType<YouboraAdapter['playingListener']> {
            // On PS5 sometimes the `playing` event will occur again immediately after the `first playing` event.
            // At this time the rendition is `unknown`, so delay this listener until `timeupdate` event happens.
            // So that the `start` request will contain rendition.
            if (!isTimeUpdateEventFired) return;

            super.playingListener(e);
        }
    }

    if (adapterConfig) {
        Object.defineProperties(
            VideoFsHtml5Adapter.prototype,
            Object.getOwnPropertyDescriptors(adapterConfig)
        );
    }

    return VideoFsHtml5Adapter;
}

export default createVideoFsHtml5Adapter;
