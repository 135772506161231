import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';

import {SCREEN_768_TABLET} from '../../../../common/screen-sizes';
import {IC103Loading} from '../../../../component-library/atoms/ic';
import IGM01HudContent from '../../../../component-library/molecules/igm/01-hud-content';
import IGM02HudContSelect from '../../../../component-library/molecules/igm/02-hud-cont-select';
import OR05HudTray from '../../../../component-library/organisms/or/05-hud-tray';
import {usePlayerContext} from '../../context';
import HudPanel from '../hud-panel';
import HudTab from '../hud-tab';

// The smallest player that can show the HUD is the tablet player, so this provides us the min height of the HUD
const MIN_HUD_HEIGHT_PX = (9 / 16) * SCREEN_768_TABLET;

const HudWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    min-height: ${MIN_HUD_HEIGHT_PX}px;
`;

const CenteredContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const Hud = (
    _: unknown,
    ref: React.ForwardedRef<HTMLDivElement>
): React.ReactElement => {
    const theme = useTheme();
    const playerState = usePlayerContext();
    const {activeScreenConfig, globalState, globalActions} = playerState ?? {};
    const {isHudVisible, hudContent} = globalState ?? {};
    const {setHudVisibility} = globalActions ?? {};
    const [currentHudSection, setCurrentHudSection] = useState<
        string | undefined
    >();

    useEffect(
        function requestHudContent() {
            if (isHudVisible && activeScreenConfig?.playbackData) {
                // Just blindly call this every time - let the consumer app do throttling etc if necessary
                globalActions?.onRequestHudContent(
                    activeScreenConfig.playbackData
                );
            }
            // Re-generate the HUD content when switching videos
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isHudVisible, activeScreenConfig?.playbackData?.id]
    );

    const hideHud = (): void => {
        setHudVisibility?.(false);
    };

    const hudSections = Array.isArray(hudContent) ? hudContent : [];

    const buttons = hudSections.map(({sectionKey, tab}, tabIndex) => (
        <HudTab
            isSelected={
                currentHudSection === sectionKey ||
                (!currentHudSection && !tabIndex)
            }
            sectionKey={sectionKey}
            setCurrentHudSection={setCurrentHudSection}
            {...tab}
        />
    ));

    const tabList = (
        <IGM02HudContSelect
            onCloseClick={hideHud}
            middleButtons={buttons}
            isInteractive={isHudVisible}
        />
    );

    const tabPanels =
        hudSections.length === 0 ? (
            <IGM01HudContent>
                <CenteredContent>
                    <IC103Loading
                        size="56px"
                        color={theme.brandColor}
                        loadingSpinnerUrl={theme.loadingSpinnerUrl}
                    />
                </CenteredContent>
            </IGM01HudContent>
        ) : (
            hudSections.map(({sectionKey, panel}, tabIndex) => (
                <HudPanel
                    key={sectionKey}
                    isVisible={
                        sectionKey === currentHudSection ||
                        !(currentHudSection || tabIndex)
                    }
                    sectionKey={sectionKey}
                    {...panel}
                />
            ))
        );

    return (
        <HudWrapper ref={ref}>
            <OR05HudTray
                isVisible={isHudVisible}
                tabList={tabList}
                tabPanels={tabPanels}
            />
        </HudWrapper>
    );
};

Hud.displayName = 'Hud';

export default observer(Hud, {forwardRef: true});
