import {mediaQuery} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import {Button} from '../../../../common/normalized-styled-components';
import {athensGray} from '../../../../common/palette';
import {SCREEN_1920_DESKTOP} from '../../../../common/screen-sizes';
import GA25CaGrad from '../../ga/25-ca-grad';
import IC24ArrowL from '../../ic/24-arrow-l';
import IC25ArrowR from '../../ic/25-arrow-r';

export const NAV_BUTTON_WIDTH_PX = 54;
export const NAV_BUTTON_WIDTH_PX_LARGE = 81;

const ArrowContainer = styled.span`
    display: inline-block;
    position: relative;
    opacity: 0.6;
    width: 40px;
    height: 40px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 60px;
        height: 60px;
    `}
`;

const CarouselNavButton = styled(Button)`
    appearance: none;
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: ${transition('color', 'opacity')};
    border: 0;
    background-color: transparent;
    padding: 0;
    width: ${NAV_BUTTON_WIDTH_PX}px;
    height: 100%;
    line-height: 1;
    color: ${athensGray};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: ${NAV_BUTTON_WIDTH_PX_LARGE}px;
    `}

    &:focus {
        outline: 0;
    }

    &:hover {
        ${ArrowContainer} {
            opacity: 1;
        }
    }

    &:disabled {
        pointer-events: none;
    }
`;

const BackgroundPositioner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const label = {
    left: 'See previous items',
    right: 'See next items',
};

const icon = {
    left: <IC24ArrowL />,
    right: <IC25ArrowR />,
};

const TRAY_BACKGROUND_OPACITY = 0.6;

export type Props = {
    /** The background color */
    backgroundColor?: string;
    /** Can items can be seen passing under this button? */
    hasTransparentFading?: boolean;
    /** This button's direction */
    direction: 'left' | 'right';
    /** Additional classname(s) */
    className?: string;
} & React.ComponentPropsWithoutRef<'button'>;

const BA26CarouselBtn = ({
    backgroundColor = 'transparent',
    hasTransparentFading = true,
    direction,
    className,
    ...htmlAttributes
}: Props): React.ReactElement => {
    // It adds the opacity that is the same as Tray's, so it blends better into the background.
    const mainColor =
        backgroundColor === 'transparent'
            ? backgroundColor
            : rgba(backgroundColor, TRAY_BACKGROUND_OPACITY);
    const secondaryColor = hasTransparentFading ? 'transparent' : mainColor;

    const isLeftButton = direction === 'left';

    const leftColorOnGradient = isLeftButton ? mainColor : secondaryColor;
    const rightColorOnGradient = isLeftButton ? secondaryColor : mainColor;
    const middleColorOnGradient =
        mainColor === 'transparent'
            ? mainColor
            : rgba(mainColor, TRAY_BACKGROUND_OPACITY / 2);

    const leftColorFadePercentageByDirection = 50;
    const leftColorFadeMarkerInPercentage = hasTransparentFading
        ? leftColorFadePercentageByDirection
        : 0;

    return (
        <CarouselNavButton
            {...htmlAttributes}
            className={classnames('BA26CarouselBtn', className)}
            aria-label={label[direction]}
            position={direction}
            tabIndex="-1"
        >
            <BackgroundPositioner>
                <GA25CaGrad
                    colorLeft={leftColorOnGradient}
                    colorMiddle={
                        hasTransparentFading ? middleColorOnGradient : undefined
                    }
                    colorRight={rightColorOnGradient}
                    colorLeftEndInPercentage={leftColorFadeMarkerInPercentage}
                />
            </BackgroundPositioner>

            <ArrowContainer>{icon[direction]}</ArrowContainer>
        </CarouselNavButton>
    );
};

BA26CarouselBtn.displayName = 'BA26CarouselBtn';

export default BA26CarouselBtn;
