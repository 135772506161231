import {
    getLocalStorageValue,
    setLocalStorageValue,
} from '@fsa-streamotion/browser-utils';

import noop from 'lodash/noop';

import type PlaybackNative from '.';
import type {
    BaseBitrateInfo,
    BitrateChangeDetailsInfo,
    PlayerQualityLevel,
    SupportedPlaybackHandler,
} from '../../types';
import {
    type PlayerQuality,
    DEFAULT_PLAYER_QUALITY,
    getVideoQualityPreference,
    removeVideoQualityPreference,
    setVideoQualityPreference,
} from '../../utils/quality-settings';
import {BITRATE_STORAGE_KEY} from '../../utils/storage-keys';
import {triggerCustomEvent} from '../../utils/browser';

export default class NativeCustomBitrate {
    videoElement: HTMLVideoElement;

    levels = [] as BaseBitrateInfo[];
    autoSwitch = true;
    currentIndex = -1;
    nextIndex = -1;
    currentHeight: number;
    currentQuality: PlayerQualityLevel;

    bitrateLevelsLoadedCallback: (
        levels: BaseBitrateInfo[],
        canManuallyAdjustBitrate: boolean
    ) => void = noop;

    bitrateLevelChangeCallback: (
        changeDetails: BitrateChangeDetailsInfo
    ) => void = noop;

    playbackTech: PlaybackNative;
    playbackHandler: SupportedPlaybackHandler;

    constructor(
        playbackTech: PlaybackNative,
        playbackHandler: SupportedPlaybackHandler
    ) {
        this.videoElement = playbackTech.videoElement as HTMLVideoElement;
        this.bitrateLevelsLoadedCallback =
            playbackTech.options.bitrateLevelsLoadedCallback || noop;
        this.bitrateLevelChangeCallback =
            playbackTech.options.bitrateLevelChangeCallback || noop;
        this.playbackTech = playbackTech;
        this.playbackHandler = playbackHandler;

        const {height: currentHeight, level: currentQuality} =
            this.bitrateGetUserPreferredQuality();

        this.currentHeight = currentHeight;
        this.currentQuality = currentQuality;
    }

    destroy(): void {
        return;
    }

    get bitrateLevels(): BaseBitrateInfo[] {
        return this.levels;
    }

    get bitrateCurrentIndex(): number {
        return this.currentIndex;
    }

    get bitrateNextIndex(): number {
        return this.nextIndex;
    }

    set bitrateNextIndex(requestedIndex: number) {
        console.warn(
            'NativeCustomBitrate: unable to request bitrate changes.',
            requestedIndex
        );
    }

    get bitrateIsAuto(): boolean {
        return true;
    }

    get bitrateCurrentQuality(): PlayerQualityLevel {
        return this.currentQuality;
    }

    set bitrateCurrentQuality(quality) {
        this.currentQuality = quality;
    }

    get capLevelToPlayerSize(): boolean {
        return false;
    }

    set capLevelToPlayerSize(shouldCap: boolean) {
        console.warn(
            'NativeCustomBitrate: unable to request bitrate capped to player size.',
            shouldCap
        );
    }

    bitrateSwitchToAuto(): void {
        console.warn('Native playbackHandler is already on automatic ABR.');
    }

    setBitrateToAuto(): void {
        console.warn('NativeCustomBitrate: unable to set to auto.');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setMaxBitrate({
        selectedHeight,
        quality,
    }: {
        selectedHeight: number;
        quality?: PlayerQualityLevel;
    }): void {
        console.warn(
            'NativeCustomBitrate: unable to set to low with heigh:',
            selectedHeight,
            quality
        );
    }

    bitrateGetUserPreferred(): number {
        return getLocalStorageValue({
            key: BITRATE_STORAGE_KEY,
            defaultValue: -1,
        }) as number;
    }

    bitrateSaveUserPreferred(bitrate: number): void {
        setLocalStorageValue({key: BITRATE_STORAGE_KEY, value: bitrate || -1});
    }

    bitrateDeleteUserPreferred(): void {
        localStorage.removeItem(BITRATE_STORAGE_KEY);
    }

    bitrateSaveUserPreferredQuality(
        level = DEFAULT_PLAYER_QUALITY.level,
        height = DEFAULT_PLAYER_QUALITY.height
    ): void {
        setVideoQualityPreference({level, height});
    }

    bitrateGetUserPreferredQuality(): PlayerQuality {
        if (this.playbackTech.options.enableUserPreferredQuality) {
            return getVideoQualityPreference();
        }

        return DEFAULT_PLAYER_QUALITY;
    }

    bitrateDeleteUserPreferredQuality(): void {
        removeVideoQualityPreference();
    }

    getNearestIndexForBitrateLevel(
        bitrateLevels: BaseBitrateInfo[] = [],
        desiredBitrate: number
    ): number {
        if (
            desiredBitrate === -1 ||
            desiredBitrate === null ||
            desiredBitrate === undefined
        ) {
            return -1;
        }

        return bitrateLevels
            .map((level) => level.bitrate ?? 0)
            .reduce(
                (lastFit, thisBitrate, index) => {
                    const differenceToDesired = Math.abs(
                        desiredBitrate - thisBitrate
                    );
                    const previousDifference = lastFit.difference;

                    if (
                        previousDifference === -1 ||
                        differenceToDesired <= previousDifference
                    ) {
                        return {
                            currentIndex: index,
                            difference: differenceToDesired,
                        };
                    }

                    return lastFit;
                },
                {
                    currentIndex: -1,
                    difference: -1,
                }
            ).currentIndex; // only interested in currentIndex, not the difference found.
    }

    broadcastCurrentBitrateDetails(): void {
        this.triggerBitrateLevelChange({
            autoSwitch: this.autoSwitch,
            nextIndex: this.nextIndex,
            currentIndex: this.currentIndex,
            currentHeight: this.currentHeight,
        });
    }

    triggerBitrateLevelsLoaded({
        levels,
        canManuallyAdjustBitrate = false,
    }: {
        levels: BaseBitrateInfo[];
        canManuallyAdjustBitrate: boolean;
    }): void {
        this.bitrateLevelsLoadedCallback(levels, true);
        triggerCustomEvent(this.videoElement, 'fs-bitrate-levels-loaded', {
            levels,
            canManuallyAdjustBitrate,
        });
    }

    triggerBitrateLevelChange(changeDetails: BitrateChangeDetailsInfo): void {
        this.bitrateLevelChangeCallback(changeDetails);
        triggerCustomEvent(this.videoElement, 'fs-bitrate-level-change', {
            changeDetails,
        });
    }
}
