import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = {
    textColor?: string;
} & IcProps;

const IC152MultiAdd1 = ({
    textColor = black,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M41.63 0C18.638 0 0 18.638 0 41.63c0 22.99 18.638 41.629 41.63 41.629 22.99 0 41.629-18.638 41.629-41.63C83.252 18.642 64.618.009 41.629 0z"
            transform="translate(8 8)"
        />
        <path
            fill={textColor}
            d="M48.886 58.116L48.886 24.419 35.163 24.419 35.163 29.84 41.267 29.84 41.267 58.116z"
            transform="translate(8 8)"
        />
    </Ic>
);

IC152MultiAdd1.displayName = 'IC152MultiAdd1';

export default IC152MultiAdd1;
