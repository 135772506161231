import bacon from 'baconjs';
import superagent from 'superagent';

import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';
import authenticated from '../authenticated';
import {streamFromApi, getVimondHeaders} from '../../utils/api';

import userSetLocalStorage from './user-set-local-storage';

export default function ({
    sport = 'afl',
    user,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/user`;
    const request = superagent
        .put(resourceUrl)
        .send(user);

    return authenticated().flatMapLatest((authToken) => {
        request.set(getVimondHeaders({authToken, sport, version: 2}));

        return streamFromApi(request).flatMapLatest((response) => {
            if (response.body.code !== 'AUTHENTICATION_FAILED') {
                return userSetLocalStorage(response.body).map(() =>
                    response.body // Return original response
                );
            }

            return bacon.Error({
                original: new Error(response.body.description),
                response,
                status: response.body.status
            });
        });
    });
}
