import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC14Skb = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M9.167 26.551L5.408 26.551 5.408 2.795 0 2.795 0 0 9.167 0zM25.852 26.97c-3.598.2-7.04-1.489-9.083-4.458l2.166-2.152c1.544 2.268 4.132 3.599 6.875 3.536 3.13 0 6.4-1.845 6.4-5.87 0-4.024-3.228-5.883-6.4-5.883-1.734-.07-3.444.42-4.877 1.398l-1.956-1.118L21.367 0h13.89v2.99H24.174l-1.397 6.987c1.244-.492 2.574-.73 3.912-.698 4.486 0 9.405 2.878 9.405 8.692 0 5.813-5.045 8.999-10.243 8.999zM52.696 29.359L61.08 29.331 59.25 12.66 76.536 8.943 74.146.894 50.837 6.4z"
            transform="translate(8 16)"
        />
        <path
            d="M52.57 58.411c5.647.209 11.137-1.884 15.212-5.799 4.075-3.914 6.387-9.315 6.406-14.966.168-6.903-3.424-11.36-8.622-15.288l-.783-5.715 5.702-1.062c6.805 5.128 12.339 11.71 12.087 22.275-.222 16.147-13.449 29.08-29.597 28.94h-.545c-7.63-.14-30.742 0-30.966 0V58.41c.894-.014 23.379-.14 31.106 0z"
            transform="translate(8 16)"
        />
    </Ic>
);

IC14Skb.displayName = 'IC14Skb';

export default IC14Skb;
