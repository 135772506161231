import cookies from 'js-cookie';
import {v4 as uuid} from 'uuid';

import {isBrowser, isServer} from './environment';
import isNil from './util/is-nil';

type StorageFunctions = {
    getItem: (key: string) => string | null;
    setItem: (key: string, stringifiedValue: string) => void;
    removeItem: (key: string) => void;
};

let _cookies: typeof cookies;
let storageFunctions: StorageFunctions;
let environmentIsSet = false;

/**
 * Browser localStorage functions - just pass them through
 *
 * @returns storage functions
 */
export function localStorageFunctions(): StorageFunctions {
    return window.localStorage;
}

/**
 * Adapt js-cookie functions to localStorage names
 *
 * @returns cookie storage functions
 */
export function cookieFunctions(): StorageFunctions {
    return {
        getItem: (key) => cookies.get(key) ?? null,
        setItem: (key, stringifiedValue) => {
            cookies.set(key, stringifiedValue, {
                expires: 365, // in days
            });
        },
        removeItem: (key) => cookies.remove(key),
    };
}

/**
 * Detects whether localStorage is both supported and available
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 *
 * @returns whether local storage is available
 */
export function isLocalStorageAvailable(): boolean {
    if (!isBrowser()) {
        return false;
    }

    try {
        const storage = localStorageFunctions();
        const key = '__storage_test__';

        storage.setItem(key, '');
        storage.removeItem(key);

        return true;
    } catch (e) {
        return false;
    }
}

/**
 * Check if cookies are enabled
 */
function areCookiesEnabled(): boolean {
    const testKey = uuid(); // unique to avoid clobbering existing keys
    const testValue = 'x';

    try {
        cookies.set(testKey, testValue);

        const result = cookies.get(testKey) === testValue;

        cookies.remove(testKey);

        return result;
    } catch {
        return false;
    }
}

/**
 * Detects local storage and cookie capabilities from the environment
 */
export function setEnvironment(): void {
    if (process.env.NODE_ENV !== 'test' && environmentIsSet) {
        return;
    }

    environmentIsSet = true;

    if (typeof window !== 'undefined') {
        _cookies = cookies;
    }

    if (isLocalStorageAvailable()) {
        storageFunctions = localStorageFunctions();
    } else if (_cookies && areCookiesEnabled()) {
        storageFunctions = cookieFunctions();
    } else {
        throw new Error('No localstorage or cookies available');
    }
}

/**
 * Remove a local storage value.
 *
 * @param key - key of object to remove
 */
export function removeLocalStorageValue(key: string): void {
    if (isServer()) {
        return;
    }

    setEnvironment();

    try {
        storageFunctions.removeItem(key);
    } catch (e) {
        console.warn(
            `BrowserUtils: Couldn't remove the localstorage/cookie value for ${key}`,
            e instanceof Error ? e.stack : e
        );
    }
}

/**
 * Returns value stored against key.
 *
 * On no data found, any exception, or no storage formats available it will
 * return `defaultValue`.
 *
 * Uses JSON.parse on the return value (not on defaultValue though).
 *
 * @param key          - key to retrieve data from
 * @param defaultValue - default value to return if no value is found
 *
 * @returns found data stored against key, otherwise the default value
 */
export function getLocalStorageValue({
    key,
    defaultValue,
}: {
    key: string;
    defaultValue: unknown;
}): unknown {
    if (isServer()) {
        return;
    }

    setEnvironment();

    try {
        const storedValue = storageFunctions.getItem(key);

        if (isNil(storedValue)) {
            return defaultValue;
        }

        const {valueExpiresIn, value} = JSON.parse(storedValue);

        if (Date.now() >= Date.parse(valueExpiresIn)) {
            try {
                removeLocalStorageValue(key);
            } catch (e) {
                console.warn(
                    `BrowserUtils: Couldn't remove expired key: ${key} from localstorage`,
                    e instanceof Error ? e.stack : e
                );
            }

            return defaultValue;
        }

        return value;
    } catch (e) {
        console.warn(
            `BrowserUtils: Couldn't get the localstorage value for ${key}`,
            e instanceof Error ? e.stack : e
        );

        return defaultValue;
    }
}

/**
 * Sets a localstorage value.
 * Will <code>console.warn</code> if localstorage not available, so check with
 * isLocalStorageAvailable() first.
 *
 * Uses JSON.stringify to store value
 *
 * @param key       - key of object to store
 * @param value     - data to store against key
 * @param expiresIn - time in MS to expire this data after it's set
 */
export function setLocalStorageValue({
    key,
    value,
    expiresIn = null,
}: {
    key: string;
    value: unknown;
    expiresIn?: number | null;
}): void {
    if (isServer()) {
        return;
    }

    setEnvironment();

    const valueExpiresIn = expiresIn
        ? new Date(Date.now() + expiresIn).toISOString()
        : null;

    try {
        const stringifiedValue = JSON.stringify({value, valueExpiresIn});

        storageFunctions.setItem(key, stringifiedValue);
    } catch (e) {
        console.warn(
            `BrowserUtils: Couldn't set the localstorage/cookie value for ${key}`,
            e instanceof Error ? e.stack : e
        );
    }
}
