import superagent from 'superagent';

import {streamFromApiWithRetry, getVimondHeaders} from '../../utils/api';
import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';

export default function ({
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const resourceUrl = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}api/web/productgroup`;
    const apiCall = () => superagent
        .get(resourceUrl)
        .set(getVimondHeaders({sport, version: 2}));

    return streamFromApiWithRetry({apiCall})
        .map('.body');
}
