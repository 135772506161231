import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

export default class FormField extends React.Component {
    renderError(error, index) {
        return (
            <div
                key={index}
                className="fiso-hawk-form-field__error">
                {error}
            </div>
        );
    }

    render() {
        const {children, errors} = this.props;

        const formFieldClasses = classNames(
            'fiso-hawk-form-field',
            {
                'fiso-hawk-form-field--error': errors.length
            }
        );

        return (
            <div className={formFieldClasses}>
                <div className="fiso-hawk-form-field__input">
                    {children}
                </div>
                <div className="fiso-hawk-form-field__errors">
                    {errors.map(this.renderError)}
                </div>
            </div>
        );
    }
}

FormField.defaultProps = {
    errors: []
};

FormField.propTypes = {
    children: propTypes.node,
    errors: propTypes.arrayOf(
        propTypes.string
    )
};
