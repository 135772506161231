import React from 'react';
import bacon from 'baconjs';

import {handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';

import {createRoot} from 'react-dom/client';
import {DEFAULT_VIMOND_ENV} from '../utils/constants';
import {redirectNoLogin} from '../utils/auth';
import getUserDetail from '../streams/user/user-get';
import updatePassword from '../streams/user/update-password';
import UpdatePasswordComponent from '../components/update-password';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function UpdatePassword(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        sport: this.settings.sport || 'afl',
        profilePageLink: this.settings.profilePageLink,
        loginRoute: settings.loginRoute,

        vimondEnv: this.settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

UpdatePassword.prototype.init = function (initialData = false) {
    redirectNoLogin(() => {
        this.closeStreams = this.getData(initialData).onValue(this.render.bind(this));
    }, {loginRoute: this.config.loginRoute});
};

UpdatePassword.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Update Password',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

UpdatePassword.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);

    const reactElement = data.map((data) => <UpdatePasswordComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

UpdatePassword.prototype.getData = function () {
    const {sport, vimondEnv, profilePageLink} = this.config;

    const updateUserBus = new bacon.Bus();

    const userDataStream = getUserDetail({sport, vimondEnv});

    const userIdSteam = userDataStream.map('.id');

    const updatePasswordStream = updateUserBus
        .combine(userIdSteam, (userDetails, userId) => ({userDetails, userId}))
        .flatMapLatest(({userDetails, userId}) => updatePassword({
            newPassword: userDetails.newPassword,
            oldPassword: userDetails.oldPassword,
            sport,
            userId,
            vimondEnv
        }));

    const updatePasswordResponse = updatePasswordStream
        .flatMapLatest((response) => response)
        .startWith(false);

    const updatePasswordError = updatePasswordStream
        .errors()
        .mapError((error) => error)
        .startWith(null);

    const isLoading = bacon.mergeAll(
        updateUserBus.map(true),
        updatePasswordResponse.map(false),
        updatePasswordError.map(false)
    )
        .startWith(false);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            sportDetails: getSportDetails(sport),
            updatePasswordError,
            profilePageLink,
            onUpdatePassword: ({oldPassword, newPassword}) => {
                updateUserBus.push({
                    oldPassword,
                    newPassword
                });
            },
            isSuccess: updatePasswordResponse,
            isLoading
        }),
        iso: bacon.combineTemplate({
        })
    });
};

UpdatePassword.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <UpdatePasswordComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <UpdatePasswordComponent {...data.view} />,
            'hawkwidgets-update-password',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

UpdatePassword.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

UpdatePassword.prototype.pageBoot = function () {
    pageBoot(UpdatePassword, 'hawkwidgets-update-password');
};

export default function (element, settings) {
    return new UpdatePassword(element, settings);
}

pageBoot(UpdatePassword, 'hawkwidgets-update-password');
