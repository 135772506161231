export default class NagraReactNativeAdapter {
  getVersion() {
    return '7.0.1-nagra-react-native-jsclass';
  }

  getPlayhead() {
    return this.playhead;
  }

  getIsLive() {
    return !this.duration;
  }

  getDuration() {
    return this.duration;
  }

  getResource() {
    return this.resource;
  }

  setPlatform(platform) {
    this.reactPlatform = platform;
  }

  getRendition() {
    if (this.width && this.height) {
      return this.getNpawUtils().buildRenditionString(this.width, this.height);
    }
    return null;
  }

  getPlayerName() {
    return 'Nagra React Native';
  }

  unregisterListeners() {
    if (this.monitor) this.monitor.stop();
  }

  onLoad(e) {
    var size = e.naturalSize;
    if (size) {
      this.width = Math.round(size.width);
      this.height = Math.round(size.height);
    }
    this.duration = e.duration;
    this.fireStart();
  }

  onLoadStart(e) {
    this.resource = e.src;
    this.fireStart();
    if (!this.flags.isJoined) {
      this.isWaiting = false;
      this.fireJoin();
    }
    this.fireBufferEnd({}, 'onLoadStart');
    this.fireResume();
    this.pausePlayhead = null;
  }

  onPlaying(e) {
    this.fireStart();
    if (!this.flags.isJoined) {
      this.isWaiting = false;
      this.fireJoin();
    }
    if (this.pausePlayhead && Math.abs(this.getPlayhead() - this.pausePlayhead) > 0.5 && !this.flags.isPaused) {
      this.fireSeekEnd({}, 'onPlaying');
      this.pausePlayhead = null;
    }
    if (this.reactPlatform && this.reactPlatform == 'ios' && !this.pausePlayhead) {
      this.fireSeekEnd({}, 'onPlaying');
      this.pausePlayhead = null;
    }
    this.fireBufferEnd({}, 'onPlaying');
    this.fireResume();
  }

  onProgress(e) {
    this.playhead = e.currentTime;
    this.fireStart();
    if (this.playhead > 0.5 && !this.flags.isJoined) {
      this.fireJoin();
    }
    if (this.pausePlayhead && !this.flags.isPaused) {
      if (Math.abs(this.getPlayhead() - this.pausePlayhead) > 2) {
        this.fireSeekEnd({}, 'onProgress');
      } else if (this.isWaiting) {
        this.fireBufferBegin({}, true, 'onProgress');
        this.fireBufferEnd({}, 'onProgress');
      } else {
        this.cancelSeek();
        this.chronos.pause.pauseTime = null;
      }
      this.isWaiting = false;
      this.pausePlayhead = null;
    }
  }

  onSeek(e) {
    if (this.reactPlatform && this.reactPlatform == 'ios') {
      this.fireSeekBegin({}, false, 'onSeek');
    }
  }

  onWaiting(e) {
    this.isWaiting = true;
    this.fireBufferBegin({}, false, 'onWaiting');
  }

  onPaused(e) {
    if (this.reactPlatform && this.reactPlatform == 'android') {
      this.pausePlayhead = this.getPlayhead();
      this.fireSeekBegin({}, true, 'onPaused');
    }
    this.firePause();
    if (this.reactPlatform && this.reactPlatform == 'android') {
      this.chronos.pause.startTime = new Date().getTime();
    }
  }

  onError(e) {
    this.fireError(e.code, e.nativeError);
  }

  onStopped(e) {
    this.fireStop();
  }

  onEnd(e) {
    this.fireStop();
  }
}