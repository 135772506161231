import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';
import {googleSetDimension, googleTrackEvent, googleTagManagerPushDataLayer} from '../analytics-google';
import {adwordsTrackConversionCurrency} from '../analytics-adwords';
import {facebookPixelTrackCurrencyConversion, facebookPixelTrackAddToCart} from '../analytics-facebook-pixel';
import {bingPixelTrackerRegisterSale} from '../analytics-bing-pixel';
import {GOOGLE_DIMENSION_INDEX, USER_LOCAL_STORAGE_KEY} from '../constants';

export function trackSubscriptionPackage(packageName) {
    googleSetDimension(GOOGLE_DIMENSION_INDEX.SUBSCRIPTION, packageName);
}

export function trackSubscriptionPackageChange(packageName) {
    googleSetDimension(GOOGLE_DIMENSION_INDEX.SUBSCRIPTION_CHANGE, packageName);
}

export function trackPersonalDetailsFirstName() {
    googleTrackEvent('SubscriptionPage', 'FilledField', 'FirstName');
}

export function trackPersonalDetailsLastName() {
    googleTrackEvent('SubscriptionPage', 'FilledField', 'LastName');
}

export function trackPersonalDetailsEmail() {
    googleTrackEvent('SubscriptionPage', 'FilledField', 'Email');
}

export function trackPersonalDetailsMobile() {
    googleTrackEvent('SubscriptionPage', 'FilledField', 'Mobile');
}

export function trackPersonalDetailsComplete() {
    googleTrackEvent('SubscriptionPage', 'PersonalDetailsComplete');
}

export function trackFavouriteTeam(favouriteTeam) {
    googleSetDimension(GOOGLE_DIMENSION_INDEX.FAVOURITE_TEAM, favouriteTeam);
}

export function trackPaymentType(paymentType) {
    googleSetDimension(GOOGLE_DIMENSION_INDEX.PAYMENT_PROCESS, paymentType);
}

export function trackSubscriptionComplete() {
    googleTrackEvent('Subscription', 'SubscriptionComplete');
}

export function trackCurrencyConversion({value, currency}) {
    adwordsTrackConversionCurrency({value, currency});
    facebookPixelTrackCurrencyConversion({value, currency});
}

export function trackAddToCart({type}) {
    facebookPixelTrackAddToCart({type});
}

export function registerSale({totalCost, orderId, productId, currency}) {
    const user = getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY, defaultValue: null});

    const userId = user ? user.id : undefined;

    bingPixelTrackerRegisterSale({totalCost, currency});
    googleTagManagerPushDataLayer({
        event: 'subscriptionSuccess',
        revenue: totalCost,
        currencyType: currency,
        productId,
        orderId,
        userId
    });
}
