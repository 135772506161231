import {DEFAULT_ERROR_PREFIX} from './constants';
import logError from './util/log-error';

type UnknownFunction = (...args: unknown[]) => unknown;

/**
 * Normalise a function. If not a function, will return defaultVal
 *
 * @param val         - Value to normalise (e.g. function)
 * @param defaultVal  - Default value (e.g. noop)
 * @param errorPrefix - The prefix for the error message, defaults to `Transformers`
 *
 * @returns provided value or defaultVal
 */
export default function asFunction(
    val: unknown,
    defaultVal?: UnknownFunction,
    errorPrefix = DEFAULT_ERROR_PREFIX
): UnknownFunction | undefined {
    try {
        if (typeof val === 'function') {
            return val as UnknownFunction;
        }
    } catch (e) {
        logError(`${errorPrefix}: couldn't normalise arg asFunction`, val, e);
    }

    return defaultVal;
}
