/**
 * @param currentTime - The currentTime returned from playerTech in seconds
 * @param adData - Array of ad-breaks or ads within ad breaks
 * @param playedAdBreaks - Current a set of played adData
 * @returns Current ad break
 */

export type Ad = {
    adId: string;
    durationInSeconds: number;
    startTimeInSeconds: number;
};

export type AdBreak = {
    startTimeInSeconds: number;
    durationInSeconds: number;
    ads: Ad[];
};

export function getCurrentAdData(
    currentTime: number,
    playedAdBreaks: Set<AdBreak>,
    adData?: AdBreak[] | null
): AdBreak | undefined {
    const currentAdData = adData?.find(
        ({startTimeInSeconds, durationInSeconds}) =>
            startTimeInSeconds <= currentTime &&
            currentTime < startTimeInSeconds + durationInSeconds
    );

    return currentAdData && playedAdBreaks.has(currentAdData)
        ? undefined
        : currentAdData;
}

/**
 * @param currentTime - The currentTime returned from playerTech in seconds
 * @param adData - Array of ad-breaks
 * @returns The total number of ad-break time that has passed in seconds
 */
export function calculateAdSecondsPassed(
    currentTime: number,
    adData?: AdBreak[] | null
): number {
    if (!adData?.length) {
        return 0;
    }

    let adSecondsPassed = 0;

    for (const {startTimeInSeconds, durationInSeconds} of adData) {
        if (startTimeInSeconds > currentTime) {
            // This and the remaining ad breaks are in the future, so we're done.
            break;
        }

        // currentTime's progress within the current ad break (if it's inside it)
        const adBreakProgressInSeconds = currentTime - startTimeInSeconds;

        if (adBreakProgressInSeconds < durationInSeconds) {
            // The ad break is in progress, so add the progress.
            adSecondsPassed += adBreakProgressInSeconds;

            // The remaining ad breaks are in the future.
            break;
        }

        // The current ad break completed before currentTime - add its duration and keep going
        adSecondsPassed += durationInSeconds;
    }

    return adSecondsPassed;
}

/**
 * Convert a <code>currentTime</code> with ads removed to a <code>currentTime</code> with ads included.
 *
 * Use this when calculating the value to set a video element's <code>currentTime</code> property when seeking to a time
 * that doesn't include ad breaks.
 *
 * @param currentTimeAdsRemoved - a <code>currentTime</code> value with ads removed
 * @param adData      - ad breaks Array
 * @returns the <code>currentTime</code> with ads included
 */
export function calculateCurrentTimeWithAds(
    currentTimeAdsRemoved: number,
    adData?: AdBreak[] | null | Ad[]
): number {
    if (!adData?.length) {
        return currentTimeAdsRemoved;
    }

    let currentTimeWithAds = currentTimeAdsRemoved;

    for (const {startTimeInSeconds, durationInSeconds} of adData) {
        // Stop accumulating ad breaks' durations when they're in the future
        if (startTimeInSeconds > currentTimeWithAds) {
            break;
        }

        currentTimeWithAds += durationInSeconds;
    }

    return currentTimeWithAds;
}
