import {SCRUBBER_TYPE} from '@fsa-streamotion/player-state';

import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import gibson from '../../../../common/font-helper';
import {formatSecondsIntoStandardLabel} from '../../../../common/format-time';
import {white} from '../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../common/theme-helpers';

const DisplayTime = styled.span`
    opacity: 0.6;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    color: ${white};
    font: ${gibson.medium({size: 14})};
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        font-size: 16px;
        letter-spacing: 1px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 26px;
    `}
`;

export type Props = {
    /** Optional extra class name */
    className?: string;
    /** Current time in the playback in seconds */
    currentTime?: number;
    /** Total length of the playback in seconds */
    duration?: number;
    /** Are we currently in an ad break? */
    isInAdBreak?: boolean;
    /** Is this a live stream */
    isLiveStream?: boolean;
    /** Is it on edge (applicable only to live stream) */
    isOnEdge?: boolean;
    /** Is the video type is live linear? Live linear type show the count down display time */
    isLiveLinear?: boolean;
    /** Scrub bar types to control style of progress*/
    scrubBarType?: keyof typeof SCRUBBER_TYPE;
};

export default function TM01DisplayTime({
    currentTime,
    duration,
    isInAdBreak,
    isLiveStream,
    isLiveLinear,
    isOnEdge,
    className,
    scrubBarType,
}: Props): React.ReactElement | null {
    // Nothing to display if we're on the edge of live and not in an ad break
    if (isLiveStream && isLiveLinear && isOnEdge && !isInAdBreak) {
        return null;
    }

    const durationWithDefaultValue = duration ?? Number.NaN;
    const currentTimeWithDefaultValue = currentTime ?? Number.NaN;
    const offsetTime = `-${formatSecondsIntoStandardLabel(
        durationWithDefaultValue - currentTimeWithDefaultValue
    )}`;
    const currentTimeAndDuration = `${formatSecondsIntoStandardLabel(
        currentTimeWithDefaultValue
    )} / ${formatSecondsIntoStandardLabel(durationWithDefaultValue)}`;
    const getTimeText = (): string => {
        if (isInAdBreak) {
            return offsetTime;
        } else if (
            scrubBarType &&
            SCRUBBER_TYPE[scrubBarType] === SCRUBBER_TYPE.currentAndDuration
        ) {
            return currentTimeAndDuration;
        } else if (
            scrubBarType &&
            SCRUBBER_TYPE[scrubBarType] === SCRUBBER_TYPE.fromEdge &&
            !isOnEdge
        ) {
            return offsetTime;
        } else {
            return '';
        }
    };

    return scrubBarType ? (
        <DisplayTime
            role="timer"
            className={classnames('TM01DisplayTime', className)}
        >
            {getTimeText()}
        </DisplayTime>
    ) : (
        <DisplayTime
            role="timer"
            className={classnames('TM01DisplayTime', className)}
        >
            {isInAdBreak || (isLiveStream && isLiveLinear)
                ? offsetTime
                : currentTimeAndDuration}
        </DisplayTime>
    );
}

TM01DisplayTime.displayName = 'TM01DisplayTime';
