import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

import FormInputComponent from '../components/form-input';
import MessageComponent from '../components/message';

export default class EditYourDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeField = this.changeField.bind(this);

        this.state = {
            firstname: props.currentUser.firstName,
            lastname: props.currentUser.lastName,
            email: props.currentUser.email,
            mobile: props.currentUser.mobileNumber
        };
    }

    handleSubmit(e) {
        e.preventDefault();

        const userDetails = {
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            email: this.state.email,
            mobileNumber: this.state.mobile
        };

        this.props.submitDetails(userDetails);
    }

    changeField(fieldName, fieldValue) {
        this.setState({[`${fieldName}`]: fieldValue});
    }

    render() {
        const formContainerClasses = classNames(
            'fiso-hawk-edit-your-details__container',
            {
                'fiso-hawk-edit-your-details__container--is-loading': this.props.isLoading
            }
        );

        const buttonClass = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--save',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--ghost',
            'fiso-hawk-button--chevron-right'
        );

        const submitButtonClass = classNames(
            buttonClass,
            'fiso-hawk-button--chevron-right',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        const cancelButtonClass = classNames(
            buttonClass,
            'fiso-hawk-button--chevron-left',
            `fiso-hawk-fill-secondary--${this.props.sportDetails.route}`
        );

        return (
            // TODO: check on https://github.com/evcohen/eslint-plugin-jsx-a11y/issues/323
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form
                className="fsui-normalise fiso-hawk-edit-your-details"
                onSubmit={this.handleSubmit}
                method="POST">
                {this.props.isLoading ? <LoadingSpinner /> : null}
                <div className={formContainerClasses}>
                    <h1 className="fiso-hawk-edit-your-details__heading-1">Edit Your Details</h1>
                    {this.props.success ? <MessageComponent
                        type="good"
                        value="Your details have been updated" /> : null}
                    {this.props.failure ? <MessageComponent
                        type="fail"
                        value="Sorry, something went wrong. Please try again later" /> : null}
                    <div className="fiso-hawk-edit-your-details__row">
                        <div className="fiso-hawk-edit-your-details__col">
                            <FormInputComponent
                                defaultValue={this.state.firstname}
                                name="firstname"
                                required={true}
                                title="First Name"
                                handleChange={this.changeField} />
                        </div>
                        <div className="fiso-hawk-edit-your-details__col">
                            <FormInputComponent
                                defaultValue={this.state.lastname}
                                name="lastname"
                                required={true}
                                title="Last Name"
                                handleChange={this.changeField} />
                        </div>
                    </div>
                    <div className="fiso-hawk-edit-your-details__row">
                        <div className="fiso-hawk-edit-your-details__col">
                            <FormInputComponent
                                defaultValue={this.state.email}
                                type="email"
                                name="email"
                                required={true}
                                title="Email Address"
                                handleChange={this.changeField}
                                disabled={true} />
                        </div>
                        <div className="fiso-hawk-edit-your-details__col">
                            <FormInputComponent
                                defaultValue={this.state.mobile}
                                type="number"
                                name="mobile"
                                required={false}
                                title="Mobile"
                                handleChange={this.changeField} />
                        </div>
                    </div>
                    <div className="fiso-hawk-edit-your-details__row">
                        <div className="fiso-hawk-edit-your-details__col">
                            <a
                                href={this.props.profilePageLink}
                                title="Cancel and go to profile page"
                                className={cancelButtonClass}>
                                Go Back
                                <Fsvg name="back-1" />
                            </a>
                        </div>
                        <div className="fiso-hawk-edit-your-details__col">
                            <button
                                type="submit"
                                className={submitButtonClass}>
                                Save Updates
                                <Fsvg name="next-1" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

EditYourDetails.defaultProps = {
    currentUser: {
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: ''
    },
    submitDetails: noop
};

EditYourDetails.propTypes = {
    currentUser: propTypes.shape({
        firstName: propTypes.string,
        lastName: propTypes.string,
        email: propTypes.string,
        mobileNumber: propTypes.string
    }),
    profilePageLink: propTypes.string,

    failure: propTypes.bool,
    isLoading: propTypes.bool,
    success: propTypes.bool,

    submitDetails: propTypes.func,

    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
