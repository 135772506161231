import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop  from 'lodash/noop';

import ScrollList from '@fsa-streamotion/streamotion-web-fs-ui/src/components/scroll-list/scroll-list';
import ImageFallback from '@fsa-streamotion/streamotion-web-fs-ui/src/components/image-fallback/image-fallback';
import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

export default class Navigation extends React.Component {
    renderSideBarItems(item, index) {
        if (!item.shouldRender) {
            return null;
        }

        const linkClassName = classNames(
            'fiso-hawk-navigation__sidebar-nav-list-link',
            {
                'fiso-hawk-navigation__sidebar-nav-list-link--with-border': item.border
            }
        );

        return (
            <li key={index} className="fiso-hawk-navigation__sidebar-nav-list">
                <a className={linkClassName} href={item.link} itemProp="url">
                    <span itemProp="name">{item.name}</span>
                </a>
            </li>
        );
    }

    renderHeaderMenuItem(item, index) {
        const aClassName = classNames(
            'fiso-hawk-navigation__header-navlist-item-link',
            {
                'fiso-hawk-navigation__header-navlist-item-link--active': index === this.props.selectedHeaderTabIndex
            }
        );

        return (
            <li key={index} className="fiso-hawk-navigation__header-navlist-item">
                <a className={aClassName} href={item.link} itemProp="url">
                    <span className="fiso-hawk-navigation__header-navlist-item-icon-container">
                        <Fsvg name={item.fsvg} />
                    </span>
                    <span className="fiso-hawk-navigation__header-navlist-item-link-name" itemProp="name">
                        {item.name}
                    </span>
                </a>
            </li>
        );
    }

    renderUserInfo(loginUrl) {
        if (!this.props.userName) {
            return (
                <div className="fiso-hawk-navigation__sidebar-nav-list-button-wrapper">
                    <a
                        href={loginUrl}
                        className="fiso-hawk-navigation__sidebar-nav-list-link">
                        Log in
                    </a>
                </div>
            );
        }

        return (
            <a className="fiso-hawk-navigation__user-info" href={this.props.profileUrl}>
                <span className="fiso-hawk-navigation__user-image-container">
                    <ImageFallback fallbackSrc={this.props.fallbackUserImage}>
                        <img
                            src={this.props.userImage}
                            width={this.props.imageSize}
                            height={this.props.imageSize}
                            className="fiso-hawk-navigation__user-image"
                            title={this.props.userName}
                            alt={this.props.userName}
                            itemProp="image" />
                    </ImageFallback>
                </span>
                <span className="fiso-hawk-navigation__user-name">
                    {this.props.userName}
                </span>
            </a>
        );
    }

    renderUserLogout() {
        if (!this.props.userName) {
            return null;
        }

        return (
            <li key="signOut" className="fiso-hawk-navigation__sidebar-nav-list">
                <div className="fiso-hawk-navigation__sidebar-nav-list-button-wrapper">
                    <button className="fiso-hawk-navigation__sidebar-nav-list-button" onClick={this.props.onLogout}>
                        Log out
                    </button>
                </div>
            </li>
        );
    }

    renderHeaderLogo() {
        return (
            <a className="fiso-hawk-navigation__logo-container-link" href="/" itemProp="url">
                <Fsvg name={`watch-${this.props.sportDetails.route}`} itemProp="logo" />
                <h1 className="fiso-hawk-navigation__logo-heading" itemProp="name">Watch {this.props.sportDetails.label}</h1>
            </a>
        );
    }

    render() {
        const {loginUrl} = this.props;

        return (
            <div
                className={classNames(
                    'fiso-hawk-navigation',
                    'fsui-normalise',
                    `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`
                )}>
                <div className="fiso-hawk-navigation__header">
                    <div className="fiso-hawk-navigation__logo-container" itemScope={true} itemType="http://schema.org/Brand">
                        {this.renderHeaderLogo()}
                    </div>

                    <nav
                        className="fiso-hawk-navigation__header-nav"
                        itemScope={true}
                        itemType="http://www.schema.org/SiteNavigationElement">
                        <ScrollList direction="horizontal" iconNext="next-1" iconPrevious="back-1" fadingEdges={true}>
                            <ul className="fiso-hawk-navigation__header-navlist">
                                {this.props.navigationHeader.map(this.renderHeaderMenuItem, this)}
                            </ul>
                        </ScrollList>
                    </nav>
                </div>

                <input
                    type="checkbox"
                    id="fiso-hawk-navigation__toggle-sidebar-checkbox"
                    className="fiso-hawk-navigation__toggle-sidebar-checkbox" />

                <label
                    htmlFor="fiso-hawk-navigation__toggle-sidebar-checkbox"
                    className="fiso-hawk-navigation__toggle-sidebar-checkbox-label">
                    <span className="fiso-hawk-navigation__toggle-sidebar-hamburger">
                        Toggle Navigation
                    </span>
                </label>

                <div
                    className="fiso-hawk-navigation__sidebar"
                >
                    <div className="fiso-hawk-navigation__sidebar-scroll-area">
                        <nav className="fiso-hawk-navigation__sidebar-nav">
                            <ul
                                className="fiso-hawk-navigation__sidebar-nav-list"
                                itemScope={true}
                                itemType="http://www.schema.org/SiteNavigationElement">
                                <li key="userInfo" className="fiso-hawk-navigation__sidebar-nav-list">
                                    {this.renderUserInfo(loginUrl)}
                                </li>
                                {this.props.navigationSidebar.map(this.renderSideBarItems)}
                                {this.renderUserLogout()}
                            </ul>
                        </nav>
                    </div>
                </div>

                {this.props.shouldRenderActionItems && (
                    <div className="fiso-hawk-navigation__action">
                        {!this.props.userName && (
                            <a
                                className="fiso-hawk-navigation__action-login"
                                href={loginUrl}>
                                Log in
                            </a>
                        )}

                        {this.props.userName && (
                            <button
                                className="fiso-hawk-navigation__action-logout"
                                onClick={this.props.onLogout}>
                                Log out
                            </button>
                        )}

                        {!this.props.hasActiveSubscription && this.props.shouldRenderSubscribeButton && (
                            <a
                                className="fiso-hawk-navigation__action-subscribe"
                                href="/">
                                Subscribe
                            </a>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

Navigation.defaultProps = {
    onLogout: noop,
    // @TODO: Change the fallbackUserImage image source
    fallbackUserImage: 'https://resourcesssl.newscdn.com.au/cs/foxtemp/theme-assets/images/placeholders/default--headshot.png',
    navigationHeader: [],
    navigationSidebar: [],
    userInfo: {},
    imageSize: 48
};

Navigation.propTypes = {
    shouldRenderActionItems: propTypes.bool,
    shouldRenderSubscribeButton: propTypes.bool,
    hasActiveSubscription: propTypes.bool,
    loginUrl: propTypes.string,
    onLogout: propTypes.func,
    fallbackUserImage: propTypes.string,
    navigationHeader: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        fsvg: propTypes.string,
        link: propTypes.string
    })),
    navigationSidebar: propTypes.arrayOf(propTypes.shape({
        name: propTypes.string,
        border: propTypes.bool,
        link: propTypes.string
    })),
    profileUrl: propTypes.string,
    userImage: propTypes.string,
    imageSize: propTypes.number,
    userName: propTypes.string,
    selectedHeaderTabIndex: propTypes.number,
    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
