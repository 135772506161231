export default class ContribHlsTech {
  isUsed(adapter) {
    return !!this.getHls(adapter);
  }

  getRendition(adapter) {
    let ret = null;
    const media = this.getHls(adapter).playlists.media();
    if (adapter.player.qualityLevels) {
      const qualityLevels = adapter.player.qualityLevels();
      const selected = qualityLevels[qualityLevels.selectedIndex];
      if (selected) {
        ret = this.getNpawUtils().buildRenditionString(selected.width, selected.height, selected.bitrate);
      }
    } else if (media && media.attributes) {
      const att = media.attributes;
      if (att.RESOLUTION) {
        ret = this.getNpawUtils().buildRenditionString(att.RESOLUTION.width, att.RESOLUTION.height, att.BANDWIDTH);
      } else if (att.BANDWIDTH) {
        ret = this.getNpawUtils().buildRenditionString(att.BANDWIDTH);
      } else if (att.NAME) {
        ret = att.NAME;
      }
    }
    return ret;
  }

  getHls(adapter) {
    return adapter.getTech().vhs || adapter.getTech().hls || adapter.getTech().hls_ || adapter.getTech().hlsplayer;
  }

  getTotalBytes(adapter) {
    const hls = this.getHls(adapter);
    let ret = hls.bytesReceived;
    if (!ret && hls.stats) {
      ret = hls.stats.mediaBytesTransferred - adapter.acumBytes;
    }
    return ret;
  }

  getBitrate(adapter) {
    let ret = null;
    const media = this.getHls(adapter).playlists.media();
    if (adapter.player.qualityLevels) {
      const qualityLevels = adapter.player.qualityLevels();
      const selected = qualityLevels[qualityLevels.selectedIndex];
      if (selected) {
        ret = selected.bitrate;
      }
    } else if (media && media.attributes && media.attributes.BANDWIDTH) {
      ret = media.attributes.BANDWIDTH;
    } else if (adapter.player.tech_ && adapter.player.tech_.vhs && adapter.player.tech_.vhs.stats) {
      ret = adapter.player.tech_.vhs.stats.bandwidth;
    } else if (adapter.player.tech_ && adapter.player.tech_.hls && adapter.player.tech_.hls.bandwidth) {
      ret = adapter.player.tech_.hls.bandwidth;
    } else if (adapter.player.hls && adapter.player.hls.bandwidth) {
      ret = adapter.player.hls.bandwidth;
    }
    return ret;
  }
}