import React from 'react';

import type ChromecastSenderState from './state.js';

export const ChromecastSenderContext =
    React.createContext<ChromecastSenderState | null>(null);

/**
 * @returns An instance of the current player state
 */
export const useChromecastSenderContext = (): ChromecastSenderState | null =>
    React.useContext<ChromecastSenderState | null>(ChromecastSenderContext);
