import parseBif from './parse-bif';
import ThumbnailRegistry from './thumbnail-registry';

/**
 *  Represents a callback function that get the index of the video thumbnail at the specified point in time.
 *
 * @param seconds - a point in time, in seconds, in a video
 * @returns the index of the thumbnail at the specified time
 */

export type IndexOfSeconds = (seconds: number) => number;

/**
 * @param index - the index of a thumbnail
 * @returns the point in time, in seconds, at which the thumbnail occurs
 */
export type SecondsAtIndex = (index: number) => number | undefined;

/**
 *  Represents a callback function that get the URL of the video thumbnail at the specified index.
 *
 * @param index - the index of a video thumbnail
 * @returns the URL of the video thumbnail at the specified index
 */

export type UrlAtIndex = (index: number) => string | undefined;

/**
 * Index a BIF from an <code>ArrayBuffer</code>, generating a blob URL for each image, functions to locate them,
 * and a function to destroy the image blobs.
 *
 * @param bifBuffer - an <code>ArrayBuffer</code> containing a BIF
 * @returns the indexed BIF
 */

type BifRegistry = {
    indexOfSeconds: IndexOfSeconds;
    secondsAtIndex: SecondsAtIndex;
    urlAtIndex: UrlAtIndex;
    totalThumbnails: number;
    destroy: () => undefined;
    urls: string[];
};

export default function createThumbnailRegistry(
    bifBuffer: ArrayBuffer
): BifRegistry {
    const thumbnailRegistry = new ThumbnailRegistry();
    const urls: string[] = [];

    // Build the thumbnail index and images
    for (const {seconds, url} of parseBif(bifBuffer)) {
        thumbnailRegistry.add(seconds);
        urls.push(url);
    }

    thumbnailRegistry.ready();

    return {
        indexOfSeconds: (seconds: number) => thumbnailRegistry.indexOf(seconds),
        secondsAtIndex: (index: number) =>
            thumbnailRegistry.secondsAtIndex(index),
        urlAtIndex: (index: number) => urls[index],
        totalThumbnails: thumbnailRegistry.length,
        destroy: () => void urls.forEach((url) => URL.revokeObjectURL(url)),
        urls,
    };
}
