import result from 'lodash/result';

export default function getTennisSeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'french-open':
        case '41':
            return {
                id: 41,
                label: 'French Open',
                route: 'french-open'
            };

        case 'australian-open':
        case '42':
        case '284': // 284 not used, but just in case
            return {
                id: 42,
                label: 'Australian Open',
                route: 'australian-open'
            };

        case 'hopman-cup':
        case '48':
            return {
                id: 48,
                label: 'Hopman Cup',
                route: 'hopman-cup'
            };

        case 'wimbledon':
        case '52':
            return {
                id: 52,
                label: 'Wimbledon',
                route: 'wimbledon'
            };

        case 'us-open':
        case '53':
            return {
                id: 53,
                label: 'US Open',
                route: 'us-open'
            };

        case 'fed-cup':
        case '404':
        case '54': // no longer used... mick promised to merge all the data to 404 instead
            return {
                id: 404,
                label: 'Fed Cup',
                route: 'fed-cup'
            };

        case 'bnp-paribas-open':
        case '74':
            return {
                id: 74,
                label: 'BNP Paribas Open',
                route: 'bnp-paribas-open'
            };

        case 'miami-open-presented-by-itau':
        case '75':
            return {
                id: 75,
                label: 'Miami Open presented by Ita\u00FA', // unicode for JSX render (http://reactjs.cn/react/docs/jsx-gotchas.html)
                route: 'miami-open-presented-by-itau'
            };

        case 'monte-carlo-rolex-masters':
        case '76':
            return {
                id: 76,
                label: 'Monte Carlo Rolex Masters',
                route: 'monte-carlo-rolex-masters'
            };

        case 'internationali-bnl-d-italia':
        case '79':
            return {
                id: 79,
                label: 'Internationali BNL d\'Italia',
                route: 'internationali-bnl-d-italia'
            };

        case 'atp-world-tour':
        case '88':
            return {
                id: 88,
                label: 'ATP World Tour',
                route: 'atp-world-tour'
            };

        case 'mutua-madrilena-madrid-open':
        case '92':
            return {
                id: 92,
                label: 'Mutua Madrilena Madrid Open',
                route: 'mutua-madrilena-madrid-open'
            };

        case 'rogers-cup':
        case '103':
        case '145':
            return {
                id: 103,
                label: 'Rogers Cup',
                route: 'rogers-cup'
            };

        case 'shanghai-masters':
        case '109':
            return {
                id: 109,
                label: 'Shanghai Masters',
                route: 'shanghai-masters'
            };

        case 'western-and-southern-open':
        case '114':
            return {
                id: 114,
                label: 'Western & Southern Open',
                route: 'western-and-southern-open'
            };

        case 'davis-cup':
        case '118':
            return {
                id: 118,
                label: 'Davis Cup',
                route: 'davis-cup'
            };

        case 'luxembourg-open':
        case '150':
            return {
                id: 150,
                label: 'Luxembourg Open',
                route: 'luxembourg-open'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Tennis seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
