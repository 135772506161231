import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = IcProps;

const IC19JLive = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M16.294 24.51H89.336V67.76H16.294z"
            transform="translate(5 16)"
        />
        <path
            fill={black}
            d="M40.986 52.375L33.16 52.375 33.16 38.876 36.332 38.876 36.332 49.761 41.014 49.761zM46.366 52.375L43.194 52.375 43.194 38.876 46.366 38.876zM61.332 38.876L56.329 52.375 53.311 52.375 48.294 38.876 51.704 38.876 54.876 48.168 58.034 38.876zM72.19 52.375L63.26 52.375 63.26 38.876 72.022 38.876 72.022 41.405 66.432 41.405 66.432 44.2 71.715 44.2 71.715 46.729 66.418 46.729 66.418 49.859 72.19 49.859z"
            transform="translate(5 16)"
        />
        <path
            d="M64 0l-2.794 3.116 5.017 4.514H26.55C3.48 7.63 0 28.283 0 28.493l4.192.643c0-.713 2.935-17.314 22.359-17.314h39.56l-4.192 4.681 3.116 2.795L74.174 9.14 64.001 0z"
            transform="translate(5 16)"
        />
    </Ic>
);

IC19JLive.displayName = 'IC19JLive';

export default IC19JLive;
