import findKey from 'lodash/findKey';

import {
    type PlayerLayoutStyleValue,
    PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS,
    PLAYER_LAYOUT_STYLE,
} from './constants';

export default function getNextLayout(
    numOfScreensWithContent?: number
): PlayerLayoutStyleValue {
    return (
        (findKey(
            PLAYER_LAYOUT_STYLE_TO_NUM_VISIBLE_SCREENS,
            (screenNumber) => screenNumber === numOfScreensWithContent
        ) as PlayerLayoutStyleValue) || PLAYER_LAYOUT_STYLE.LAYOUT_SINGLE
    );
}
