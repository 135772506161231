import {
    mediaQuery,
    stylesWhen,
} from '@fsa-streamotion/styled-component-helpers';
import {Easing, Group, Tween} from '@tweenjs/tween.js';

import classnames from 'classnames';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import {parseToRgb} from 'polished';
import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styled from 'styled-components';

import {TRANSITION_DURATION_MS} from '../../../../common/animations';
import {
    SCREEN_1920_DESKTOP,
    SCREEN_768_TABLET,
    useMatchMedia,
} from '../../../../common/screen-sizes';
import BA26CarouselBtn, {
    NAV_BUTTON_WIDTH_PX,
    NAV_BUTTON_WIDTH_PX_LARGE,
} from '../../../atoms/ba/26-carousel-btn';

const StyledBA26CarouselBtn = styled(BA26CarouselBtn).attrs({
    minVisibleWidthPx: 0,
})`
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        ${stylesWhen('isVisible')`
            pointer-events: auto;
        `}
    `}
`;

const LeftCarouselBtn = styled(StyledBA26CarouselBtn).attrs({
    direction: 'left',
    className: 'CAM03Finite__left-carousel-button',
})`
    left: 0;
`;

const RightCarouselBtn = styled(StyledBA26CarouselBtn).attrs({
    direction: 'right',
    className: 'CAM03Finite__right-carousel-button',
})`
    right: 0;
`;

const Outer = styled.section`
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (hover: hover) {
        ${stylesWhen('isLeftButtonVisible')`
            &:hover {
                .CAM03Finite__left-carousel-button {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        `}

        ${stylesWhen('isRightButtonVisible')`
            &:hover {
                .CAM03Finite__right-carousel-button {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        `}
    }
`;

type InnerProps = {
    hasButtonGap: boolean;
    isCentered: boolean;
    fadeLeft?: boolean;
    fadeRight?: boolean;
};

const Inner = styled.div<InnerProps>`
    --smwplayer-nav-button-width: ${NAV_BUTTON_WIDTH_PX}px;
    overflow-x: scroll;
    scrollbar-width: none;
    overflow-y: hidden;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    ${mediaQuery<InnerProps>({minWidthPx: SCREEN_1920_DESKTOP})`
        --smwplayer-nav-button-width: ${NAV_BUTTON_WIDTH_PX_LARGE}px;
    `}

    ${stylesWhen<InnerProps>('hasButtonGap')`
        margin 0 var(--smwplayer-nav-button-width);
    `}

    ${stylesWhen<InnerProps>('isCentered')`
        text-align: center;
    `}

    ${stylesWhen<InnerProps>(
        ({fadeLeft, fadeRight}) => !!fadeLeft && !fadeRight
    )`
        mask-image: linear-gradient(to right, transparent, #000 var(--smwplayer-nav-button-width), #000 100%);
    `}

    ${stylesWhen<InnerProps>(
        ({fadeLeft, fadeRight}) => !fadeLeft && !!fadeRight
    )`
        mask-image: linear-gradient(to left, transparent, #000 var(--smwplayer-nav-button-width), #000 100%);
    `}

    ${stylesWhen<InnerProps>(
        ({fadeLeft, fadeRight}) => !!(fadeLeft && fadeRight)
    )`
        mask-image: linear-gradient(to right, transparent, #000 var(--smwplayer-nav-button-width), #000 calc(100% - var(--smwplayer-nav-button-width)), transparent 100%);
    `}

    &::-webkit-scrollbar {
        display: none;
    }
`;

const List = styled.ul<{isCentered?: boolean}>`
    display: ${({isCentered}) => (isCentered ? 'inline-grid' : 'grid')};
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ListItem = styled.li`
    grid-row: 1;
    margin: 0;
    padding: 0;
    height: 100%;
`;

type CarouselProps = {
    /** Optional additional class names */
    className?: string;
    /** Optional padding above and below items to compensate for vertical overflow. */
    verticalPadding?: string | number;
    /** Number of visible items to remain in view when paging */
    showPrevious?: number;
    /** Background color of the scrolling buttons */
    buttonBackgroundColor?: string;
    /** Are the scrolling buttons displayed inline? */
    isButtonsInline?: boolean;
    /** Are the carousel items centered when their total width is less than the carousel's width? */
    isCentered?: boolean;
    /** Item width */
    itemWidthPx?: number;
    /** Item (and therefore this carousel) height */
    itemHeightPx?: number;
    /** The gap between items, before the first item, and after the last item */
    itemGapPx?: number;
    /** Does the item gap include the outer edges? */
    hasOuterGap?: boolean;
    /** Items to display */
    children?: React.ReactNode;
    /** Is viewport more than 1920px */
    isLargeViewport: boolean;
};

type CarouselState = {
    isPaging: boolean;
    containerWidthPx: number;
    isButtonBackgroundTranslucent: boolean;
    navButtonWidthInPx: number;
    buttonBackgroundColor?: string;
    isButtonsInline?: boolean;
    isLeftButtonVisible?: boolean;
    isRightButtonVisible?: boolean;
};

class Carousel extends React.Component<CarouselProps, CarouselState> {
    static getInnerWidthPx(
        isButtonsInline: boolean,
        containerWidthPx: number,
        navButtonWidthInPx: number
    ): number {
        return isButtonsInline
            ? containerWidthPx
            : Math.max(0, containerWidthPx - navButtonWidthInPx * 2);
    }

    static getItemsPerPage({
        isButtonsInline,
        itemWidthPx,
        itemGapPx,
        containerWidthPx,
        navButtonWidthInPx,
    }: {
        isButtonsInline: boolean;
        itemWidthPx: number;
        itemGapPx: number;
        containerWidthPx: number;
        navButtonWidthInPx: number;
    }): number {
        const offsetPx = isButtonsInline ? 2 * navButtonWidthInPx : 0;

        return Math.max(
            0,
            Math.round(
                (Carousel.getInnerWidthPx(
                    isButtonsInline,
                    containerWidthPx,
                    navButtonWidthInPx
                ) -
                    offsetPx) /
                    (itemWidthPx + itemGapPx)
            ) - 1
        );
    }

    static getContentWidthPx({
        itemWidthPx,
        itemGapPx,
        children,
    }: {
        itemWidthPx: number;
        itemGapPx: number;
        children: React.ReactNode;
    }): number {
        return Math.max(
            0,
            (itemWidthPx + itemGapPx) * React.Children.count(children) -
                itemGapPx
        );
    }

    static isButtonBackgroundTranslucent(props: CarouselProps): boolean {
        try {
            if (!props.buttonBackgroundColor) {
                return true;
            }

            // parseToRgb is a bit fussy :(
            const buttonBackgroundColor = props.buttonBackgroundColor
                .trim()
                .toLowerCase();

            // parseToRgb doesn't handle this :(
            if (buttonBackgroundColor === 'transparent') {
                return true;
            }

            return get(parseToRgb(props.buttonBackgroundColor), 'alpha', 1) < 1;
        } catch (error) {
            // buttonBackgroundColor is probably a color name, which if valid, is opaque (except transparent which is handled above)
            return false;
        }
    }

    static getNavButtonWidth(isLargeViewport: boolean): number {
        return isLargeViewport
            ? NAV_BUTTON_WIDTH_PX_LARGE
            : NAV_BUTTON_WIDTH_PX;
    }

    static displayName = 'Carousel';

    static defaultProps = {
        showPrevious: 1,
        buttonBackgroundColor: 'transparent',
        isButtonsInline: true,
        itemWidthPx: 100,
        itemHeightPx: 100,
        itemGapPx: 7,
    };

    constructor(props: CarouselProps) {
        super(props);

        this.state = {
            isPaging: false,
            containerWidthPx: 0,
            isButtonBackgroundTranslucent:
                Carousel.isButtonBackgroundTranslucent(props),
            navButtonWidthInPx: Carousel.getNavButtonWidth(
                props.isLargeViewport
            ),
            buttonBackgroundColor: this.props.buttonBackgroundColor, // track buttonBackgroundColor changes
        };
    }

    static getDerivedStateFromProps(
        props: CarouselProps,
        state: CarouselState
    ): Partial<CarouselState> {
        const derivedState: Partial<CarouselState> = {};

        if (props.buttonBackgroundColor !== state.buttonBackgroundColor) {
            Object.assign(derivedState, {
                buttonBackgroundColor: props.buttonBackgroundColor,
                isButtonBackgroundTranslucent:
                    Carousel.isButtonBackgroundTranslucent(props),
            });
        }

        const newNavButtonWidthInPx = Carousel.getNavButtonWidth(
            props.isLargeViewport
        );

        if (newNavButtonWidthInPx !== state.navButtonWidthInPx) {
            derivedState.navButtonWidthInPx = newNavButtonWidthInPx;
        }

        return derivedState;
    }

    override componentDidMount(): void {
        this.updateContainerWidth();
        this.updateButtonVisibility();

        if (this.containerElement) {
            this.resizeObserver.observe(this.containerElement);
        }
    }

    override componentDidUpdate(): void {
        this.updateButtonVisibility();
    }

    override componentWillUnmount(): void {
        // Stop observing width changes
        if (typeof this.containerWidthAnimationFrameRequestId === 'number') {
            cancelAnimationFrame(this.containerWidthAnimationFrameRequestId);
        }

        this.resizeObserver.disconnect();

        // Stop any paging in progress
        if (typeof this.pagingAnimationFrameRequestId === 'number') {
            cancelAnimationFrame(this.pagingAnimationFrameRequestId);
        }

        if (this.tween) {
            this.tween.stop();
            this.tween = null;
        }
    }

    containerElement?: HTMLElement;
    innerContainerElement?: HTMLElement;
    listElement?: HTMLElement;
    containerWidthAnimationFrameRequestId?: number;
    pagingAnimationFrameRequestId?: number | null;
    tween?: Tween<{x: number}> | null;

    containerRef = (element: HTMLElement): void => {
        this.containerElement = element;
    };

    innerContainerRef = (element: HTMLDivElement): void => {
        if (element !== this.innerContainerElement) {
            if (this.innerContainerElement) {
                this.innerContainerElement.removeEventListener(
                    'scroll',
                    this.onScrollInnerContainerElement,
                    {passive: true}
                );
            }

            if (element) {
                element.addEventListener(
                    'scroll',
                    this.onScrollInnerContainerElement,
                    {passive: true}
                );
                this.updateButtonVisibility();
            }

            this.innerContainerElement = element;
        }
    };

    listRef = (element: HTMLUListElement): void => {
        this.listElement = element;

        if (element) {
            this.updateButtonVisibility();
        }
    };

    updateButtonVisibility = (): void => {
        if (!this.innerContainerElement || !this.listElement) {
            return;
        }

        const isLeftButtonVisible = this.innerContainerElement.scrollLeft > 0;
        const isRightButtonVisible =
            this.listElement.clientWidth -
                this.innerContainerElement.clientWidth -
                this.innerContainerElement.scrollLeft >
            0;

        if (
            isLeftButtonVisible !== this.state.isLeftButtonVisible ||
            isRightButtonVisible !== this.state.isRightButtonVisible
        ) {
            this.setState(() => ({isLeftButtonVisible, isRightButtonVisible}));
        }
    };

    onScrollInnerContainerElement = throttle(this.updateButtonVisibility, 10);

    updateContainerWidth = (): void => {
        const {clientWidth: containerWidthPx} = this.containerElement || {};

        if (
            typeof containerWidthPx === 'number' &&
            containerWidthPx !== this.state.containerWidthPx
        ) {
            this.containerWidthAnimationFrameRequestId = requestAnimationFrame(
                () => void this.setState(() => ({containerWidthPx}))
            );
        }
    };

    resizeObserver = new ResizeObserver(this.updateContainerWidth);

    getItemsPerPage(): number {
        const {
            isButtonsInline = false,
            itemWidthPx = Carousel.defaultProps.itemWidthPx,
            itemGapPx = Carousel.defaultProps.itemGapPx,
        } = this.props;
        const {containerWidthPx, navButtonWidthInPx} = this.state;

        return Carousel.getItemsPerPage({
            isButtonsInline,
            itemWidthPx,
            itemGapPx,
            containerWidthPx,
            navButtonWidthInPx,
        });
    }

    tweenGroup = new Group();

    updatePaging = (time: number): void => {
        if (this.state.isPaging) {
            this.pagingAnimationFrameRequestId = requestAnimationFrame(
                this.updatePaging
            );
            this.tweenGroup.update(time);
        } else {
            this.pagingAnimationFrameRequestId = null;
        }
    };

    onStopPaging = (callback = noop): void => {
        this.setState(
            () => ({isPaging: false}),
            () => {
                this.tween = null;
                callback();
            }
        );
    };

    getFirstItemIndex(): number {
        const {
            itemWidthPx = Carousel.defaultProps.itemWidthPx,
            itemGapPx = Carousel.defaultProps.itemGapPx,
            children,
        } = this.props;

        return this.innerContainerElement && itemWidthPx + itemGapPx > 0
            ? clamp(
                  Math.round(
                      this.innerContainerElement.scrollLeft /
                          (itemWidthPx + itemGapPx)
                  ),
                  0,
                  React.Children.count(children) - 1
              )
            : 0;
    }

    calculateScrollPx(items: number): number {
        const {
            itemWidthPx = Carousel.defaultProps.itemWidthPx,
            itemGapPx = Carousel.defaultProps.itemGapPx,
        } = this.props;
        const firstItemIndex = this.getFirstItemIndex() + items;
        const leftGradientOffsetPx = firstItemIndex
            ? this.state.navButtonWidthInPx
            : 0;
        const scrollPx =
            firstItemIndex * (itemWidthPx + itemGapPx) - leftGradientOffsetPx;
        const maxScrollPx = this.innerContainerElement
            ? this.innerContainerElement.scrollWidth -
              this.innerContainerElement.clientWidth
            : 0;

        return clamp(
            scrollPx || items * (itemWidthPx + itemGapPx),
            -leftGradientOffsetPx,
            maxScrollPx
        );
    }

    scrollItems(items: number, callback: () => void): void {
        this.setState(
            () => ({isPaging: true}),
            () => {
                const fromX = this.innerContainerElement?.scrollLeft || 0;
                const toX = this.calculateScrollPx(items);

                const tweenState = {x: fromX};
                const {
                    itemWidthPx = Carousel.defaultProps.itemWidthPx,
                    itemGapPx = Carousel.defaultProps.itemGapPx,
                } = this.props;
                const durationMs =
                    itemWidthPx + itemGapPx > 0
                        ? TRANSITION_DURATION_MS *
                          (Math.abs(fromX - toX) / (itemWidthPx + itemGapPx))
                        : 0;

                this.tween = new Tween(tweenState, this.tweenGroup)
                    .to({x: this.calculateScrollPx(items)}, durationMs)
                    .easing(Easing.Cubic.InOut)
                    .onUpdate(() => {
                        if (this.innerContainerElement) {
                            this.innerContainerElement.scrollLeft =
                                tweenState.x;
                        }
                    })
                    .onStop(() => {
                        this.onStopPaging(callback);
                    })
                    .onComplete(() => {
                        this.onStopPaging(callback);
                    })
                    .start();

                this.pagingAnimationFrameRequestId = requestAnimationFrame(
                    this.updatePaging
                );
            }
        );
    }

    scrollToPreviousPage = (callback: () => void = noop): void => {
        const itemsPerPage = this.getItemsPerPage();
        const remainingItems = Math.min(
            React.Children.count(this.props.children),
            this.getFirstItemIndex()
        );
        const itemsToScroll = -Math.max(
            0,
            Math.min(
                itemsPerPage -
                    Math.max(
                        0,
                        this.props.showPrevious ??
                            Carousel.defaultProps.showPrevious
                    ),
                remainingItems
            )
        );

        this.scrollItems(itemsToScroll || -1, callback);
    };

    scrollToNextPage = (callback: () => void = noop): void => {
        const itemsPerPage = this.getItemsPerPage();
        const remainingItems = Math.max(
            0,
            React.Children.count(this.props.children) -
                itemsPerPage -
                this.getFirstItemIndex()
        );
        const itemsToScroll = Math.max(
            0,
            Math.min(
                itemsPerPage -
                    Math.max(
                        0,
                        this.props.showPrevious ??
                            Carousel.defaultProps.showPrevious
                    ),
                remainingItems
            )
        );

        this.scrollItems(itemsToScroll || 1, callback);
    };

    onClickPreviousPage = (): void => {
        this.scrollToPreviousPage(
            () => void this.focusPreviousVisibleChild({didScroll: true})
        );
    };

    onClickNextPage = (): void => {
        this.scrollToNextPage(
            () => void this.focusNextVisibleChild({didScroll: true})
        );
    };

    onClickItem(index: number): void {
        // Stupid Firefox and Safari
        this.focusChild(index);
    }

    onKeyDownItem(index: number, event: React.KeyboardEvent): void {
        // Ignore if we're still paging
        if (this.state.isPaging) {
            return;
        }

        let scrolled = false;

        // eslint-disable-next-line default-case
        switch (event.key) {
            case 'ArrowLeft': {
                if (index > 0) {
                    const startX = this.innerContainerElement?.scrollLeft;

                    this.scrollToPreviousPage(() => {
                        this.focusPreviousVisibleChild({
                            fromIndex: index,
                            didScroll:
                                startX !==
                                this.innerContainerElement?.scrollLeft,
                        });
                    });
                    scrolled = true;
                }

                break;
            }

            case 'ArrowRight': {
                if (index < React.Children.count(this.props.children) - 1) {
                    const startX = this.innerContainerElement?.scrollLeft;

                    this.scrollToNextPage(() => {
                        this.focusNextVisibleChild({
                            fromIndex: index,
                            didScroll:
                                startX !==
                                this.innerContainerElement?.scrollLeft,
                        });
                    });
                    scrolled = true;
                }

                break;
            }
        }

        // Prevent left/right key from scrolling
        if (scrolled) {
            event.preventDefault();
        }
    }

    onKeyDownButton = (event: React.KeyboardEvent): void => {
        // Ignore if we're still paging
        if (this.state.isPaging) {
            return;
        }

        // eslint-disable-next-line default-case
        switch (event.key) {
            case 'ArrowLeft': {
                this.scrollToPreviousPage();
                break;
            }

            case 'ArrowRight': {
                this.scrollToNextPage();
                break;
            }
        }
    };

    focusChild(index: number): void {
        const child = this.listElement?.children?.[index]
            ?.children[0] as HTMLElement;

        if (child && typeof child.focus === 'function') {
            child.focus();
        }
    }

    focusNextVisibleChild = ({
        fromIndex,
        didScroll,
    }: {fromIndex?: number; didScroll?: boolean} = {}): void => {
        const {itemWidthPx: defaultItemWidthPx, itemGapPx: defaultItemGapPx} =
            Carousel.defaultProps;
        const {itemWidthPx = defaultItemWidthPx, itemGapPx = defaultItemGapPx} =
            this.props;
        const netItemWidth = itemWidthPx + itemGapPx;

        if (!netItemWidth || !this.innerContainerElement) {
            return;
        }

        const maxIndex = React.Children.count(this.props.children) - 1;

        const toIndex = Math.min(
            Math.ceil(this.innerContainerElement.scrollLeft / netItemWidth),
            maxIndex
        );

        if (didScroll) {
            this.focusChild(toIndex);
        } else if (fromIndex && fromIndex < maxIndex) {
            this.focusChild(fromIndex + 1);
        }
    };

    focusPreviousVisibleChild = ({
        fromIndex,
        didScroll,
    }: {fromIndex?: number; didScroll?: boolean} = {}): void => {
        const {itemWidthPx: defaultItemWidthPx, itemGapPx: defaultItemGapPx} =
            Carousel.defaultProps;
        const {itemWidthPx = defaultItemWidthPx, itemGapPx = defaultItemGapPx} =
            this.props;
        const netItemWidth = itemWidthPx + itemGapPx;

        if (!netItemWidth || !this.innerContainerElement) {
            return;
        }

        const maxIndex = React.Children.count(this.props.children) - 1;

        const toIndex = clamp(
            Math.floor(
                (this.innerContainerElement.scrollLeft +
                    this.innerContainerElement.clientWidth) /
                    netItemWidth
            ) - 2,
            0,
            maxIndex
        );

        if (didScroll) {
            this.focusChild(toIndex);
        } else if (fromIndex && fromIndex > 0) {
            this.focusChild(fromIndex - 1);
        }
    };

    override render(): React.ReactElement {
        const {
            buttonBackgroundColor,
            children,
            hasOuterGap,
            isButtonsInline,
            isCentered,
            itemGapPx,
            itemHeightPx,
            itemWidthPx,
            verticalPadding,
        } = this.props;

        const {
            isPaging,
            isLeftButtonVisible,
            isRightButtonVisible,
            isButtonBackgroundTranslucent,
        } = this.state;

        const outerGap = hasOuterGap ? `${itemGapPx}px` : 0;
        const listItems = React.Children.toArray(children);

        return (
            <Outer
                className={classnames('CAM03Finite', this.props.className)}
                ref={this.containerRef}
                {...{isLeftButtonVisible, isRightButtonVisible}}
            >
                <Inner
                    ref={this.innerContainerRef}
                    hasButtonGap={!isButtonsInline}
                    isCentered={!!isCentered}
                    {...(isButtonBackgroundTranslucent &&
                    (isLeftButtonVisible || isRightButtonVisible)
                        ? {
                              fadeLeft: isLeftButtonVisible,
                              fadeRight: isRightButtonVisible,
                          }
                        : null)}
                    style={{
                        paddingTop: verticalPadding,
                        paddingBottom: verticalPadding,
                    }}
                >
                    <List
                        ref={this.listRef}
                        isCentered={isCentered}
                        style={{
                            paddingLeft: outerGap,
                            paddingRight: outerGap,
                            gridAutoColumns: `${itemWidthPx}px`,
                            gridGap: `${itemGapPx}px`,
                            minHeight: itemHeightPx,
                            width: Carousel.getContentWidthPx({
                                itemWidthPx:
                                    itemWidthPx ??
                                    Carousel.defaultProps.itemWidthPx,
                                itemGapPx:
                                    itemGapPx ??
                                    Carousel.defaultProps.itemGapPx,
                                children,
                            }),
                        }}
                    >
                        {listItems.map((child, index) => (
                            <ListItem
                                key={
                                    React.isValidElement(child)
                                        ? child.key
                                        : index
                                }
                            >
                                {React.cloneElement(
                                    child as React.ReactElement,
                                    {
                                        onKeyDown: (
                                            event: React.KeyboardEvent
                                        ) => {
                                            this.onKeyDownItem(index, event);
                                            invoke(
                                                child,
                                                'props.onKeyDown',
                                                event
                                            );
                                        },
                                        onClick: (event: React.MouseEvent) => {
                                            this.onClickItem(index);
                                            invoke(
                                                child,
                                                'props.onClick',
                                                event
                                            );
                                        },
                                    }
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Inner>

                <LeftCarouselBtn
                    isVisible={isLeftButtonVisible || !isButtonsInline}
                    backgroundColor={buttonBackgroundColor}
                    hasTransparentFading={isButtonsInline}
                    disabled={!isLeftButtonVisible}
                    onClick={isPaging ? undefined : this.onClickPreviousPage}
                    onKeyDown={this.onKeyDownButton}
                />

                <RightCarouselBtn
                    isVisible={isRightButtonVisible || !isButtonsInline}
                    backgroundColor={buttonBackgroundColor}
                    hasTransparentFading={isButtonsInline}
                    disabled={!isRightButtonVisible}
                    onClick={isPaging ? undefined : this.onClickNextPage}
                    onKeyDown={this.onKeyDownButton}
                />
            </Outer>
        );
    }
}

export type Props = {
    /** Items to display */
    children?: React.ReactNode;
} & Omit<CarouselProps, 'isLargeViewport'>;

const CAM03Finite = ({children, ...props}: Props): React.ReactElement => {
    const isLargeViewport = useMatchMedia(SCREEN_1920_DESKTOP);

    return <Carousel {...{...props, isLargeViewport}}>{children}</Carousel>;
};

CAM03Finite.displayName = 'CAM03Finite';

export default CAM03Finite;
