import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';

const VideoCarouselSpoilerOverlay = ({
    buttonText = 'Click to view',
    onShowSpoilers = noop
}) => (
    <div className="fiso-hawk-video-carousel-spoiler-overlay">
        <div className="fiso-hawk-video-carousel-spoiler-overlay__container">
            <button
                type="button"
                className="fiso-hawk-video-carousel-spoiler-overlay__button"
                onClick={onShowSpoilers}>
                {buttonText}
            </button>
        </div>
    </div>
);

VideoCarouselSpoilerOverlay.propTypes = {
    buttonText: propTypes.string,
    onShowSpoilers: propTypes.func
};

export default VideoCarouselSpoilerOverlay;
