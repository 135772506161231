import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

import VoucherProductForm from './subscribe/voucher-product-form';
import CreateUserForm from './subscribe/create-user-form';
import PaymentForm from './subscribe/payment-form';

import Success from './subscribe/success';

export default class Subscribe extends React.PureComponent {
    renderLoadingSpinner() {
        if (!this.props.isLoading) {
            return null;
        }

        return (
            <aside className="fsui-normalise fiso-hawk-overlay-spinner">
                <LoadingSpinner />
            </aside>
        );
    }

    renderDescription() {
        const {teamDetail, isPubsClubs} = this.props;

        if (teamDetail) {
            if (teamDetail.id === 0) {
                // VIP
                return (
                    <div className="fiso-hawk-subscribe__header-description">
                        <p>
                            To activate your exclusive subscription, simply enter your unique voucher code below
                            and create an account. Please note, you will not be charged for this subscription.
                        </p>
                    </div>
                );
            } else {
                // Normal Team Page
                return (
                    <div className="fiso-hawk-subscribe__header-description">
                        <p>
                            All you need to do now is enter the unique voucher code your club provided you,
                            and either create a new Watch {this.props.sportDetails.label} account or sign
                            in (if you’ve already got an account). You will not be charged anything as your
                            Watch {this.props.sportDetails.label} season pass is included in your club membership.
                        </p>
                    </div>
                );
            }
        } else if (isPubsClubs) {
            return (
                <div className="fiso-hawk-subscribe__header-description">
                    <p>The Venues Season Pass entitles LIVE and on demand streaming in your selected venue or pub.</p>
                    <p>Available through Airplay and Chromecast.</p>
                </div>
            );
        }
    }

    renderHeaderImage() {
        const {specialHeaderImageUrl, specialHeaderText, teamDetail} = this.props;

        if (specialHeaderImageUrl) {
            return (
                <div className="fiso-hawk-subscribe__special-image-container">
                    <img
                        className="fiso-hawk-subscribe__special-image"
                        src={specialHeaderImageUrl}
                        alt={specialHeaderText} />
                </div>
            );
        } else if (teamDetail) {
            const {id, name, image} = teamDetail;

            if (id === 0) {
                // VIP - No header image.
                return null;
            } else {
                // Normal Team Page - Has header image.
                return (
                    <div className="fiso-hawk-subscribe__club-image">
                        <img alt={`logo of ${name}`} src={image} />
                    </div>
                );
            }
        }
    }

    renderHeader() {
        return !!(this.props.teamDetail || this.props.isPubsClubs) && (
            <div
                className="fiso-hawk-subscribe__header">
                {/* Cora doesn’t want this for now ...
                <p
                    className={classNames(
                        'fiso-hawk-subscribe__header-title',
                        {'fiso-hawk-subscribe__header-title--large': !(this.props.teamDetail || this.props.isPubsClubs)}
                    )}>
                    All the {this.props.sportDetails.label} you can take!
                </p> */}
                {this.renderDescription()}
            </div>
        );
    }

    renderSuccess() {
        if (!this.props.successfullySubscribed) {
            return null;
        }

        return (
            <Success
                successUrl={this.props.successUrl}
                sportDetails={this.props.sportDetails}
                email={this.props.userFormData.user.email} />
        );
    }

    renderForm() {
        if (this.props.successfullySubscribed) {
            return null;
        }

        const {isPaymentRequired, isPubsClubs, specialHeaderImageUrl, sportDetails, teamDetail} = this.props;

        return (
            <div
                className={classNames(
                    'fiso-hawk-subscribe__container',
                    {'fiso-hawk-subscribe__container--without-image-header': !teamDetail && !specialHeaderImageUrl}
                )}>
                {this.renderLoadingSpinner()}
                {this.renderHeaderImage()}
                {this.renderHeader()}
                <div
                    className={classNames(
                        'fiso-hawk-subscribe__row',
                        'fiso-hawk-subscribe__row--collapsed',
                        {'fiso-hawk-subscribe__row--headless': (!teamDetail && !isPubsClubs && !specialHeaderImageUrl)}
                    )}>
                    <div
                        className="fiso-hawk-subscribe__col fiso-hawk-subscribe__col--details">
                        <VoucherProductForm
                            {...this.props.voucherProductFormData}
                            {...{
                                isPubsClubs,
                                isVoucherless: !!specialHeaderImageUrl,
                                sportDetails,
                                teamDetail
                            }} />
                    </div>
                    <div
                        className="fiso-hawk-subscribe__col fiso-hawk-subscribe__col--create">
                        <CreateUserForm
                            {...this.props.userFormData}
                            {...{
                                isPaymentRequired,
                                sportDetails
                            }} />
                    </div>
                    <div
                        className="fiso-hawk-subscribe__col fiso-hawk-subscribe__col--pay">
                        {!isEmpty(this.props.paymentFormData) && (
                            <PaymentForm {...this.props.paymentFormData} sportDetails={sportDetails} />
                        )}
                        <p
                            className={classNames(
                                'fiso-hawk-subscribe__payment-error',
                                {'fiso-hawk-subscribe__payment-error--show': this.props.subscribeErrorMessage}
                            )}>
                            {this.props.subscribeErrorMessage}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <section className="fsui-normalise fiso-hawk-subscribe">
                {this.renderForm()}
                {this.renderSuccess()}
            </section>
        );
    }
}

Subscribe.defaultProps = {};

Subscribe.propTypes = {
    teamDetail: propTypes.shape({
        id: propTypes.number,
        name: propTypes.string,
        image: propTypes.string
    }),

    voucherProductFormData: propTypes.shape({
        initialVoucher: propTypes.string,
        isVoucherLoading: propTypes.bool,
        isVoucherOptional: propTypes.bool,
        isVoucherValid: propTypes.bool,
        voucherError: propTypes.string,

        products: propTypes.arrayOf(
            propTypes.shape({
                id: propTypes.number,
                paymentPlan: propTypes.shape({
                    name: propTypes.string
                }),
                price: propTypes.number
            })
        ),

        currencySymbol: propTypes.string,
        currentProduct: propTypes.shape({
            description: propTypes.string,
            id: propTypes.number,
            paymentPlan: propTypes.shape({
                name: propTypes.string
            }),
            price: propTypes.number,
            voucher: propTypes.string
        }),

        productDescriptionText: propTypes.shape({
            groupDescription: propTypes.oneOfType([
                propTypes.array,
                propTypes.bool
            ]),
            productShortDescription: propTypes.oneOfType([
                propTypes.array,
                propTypes.bool
            ]),
            productRemaningDescription: propTypes.oneOfType([
                propTypes.array,
                propTypes.bool
            ]),
            voucherDescription: propTypes.oneOfType([
                propTypes.array,
                propTypes.bool
            ])
        }),

        onProductChanged: propTypes.func,
        onVoucherChanged: propTypes.func
    }),

    userFormData: propTypes.shape({
        isLockedOff: propTypes.bool,
        isLoading: propTypes.bool,
        dataCollectionUrl: propTypes.string,
        termsUrl: propTypes.string,
        nrlPrivacyUrl: propTypes.string,
        aflPrivacyUrl: propTypes.string,
        privacyUrl: propTypes.string,
        favouriteTeamId: propTypes.number,

        teams: propTypes.arrayOf(
            propTypes.shape({
                id: propTypes.number,
                name: propTypes.string
            })
        ),
        existingUser: propTypes.shape({
            firstName: propTypes.string,
            email: propTypes.string
        }),
        user: propTypes.shape({
            email: propTypes.string
        }),
        createAccountAndLoginErrors: propTypes.arrayOf(
            propTypes.string
        ),
        formErrors: propTypes.shape({
            firstName: propTypes.arrayOf(
                propTypes.string
            ),
            lastName: propTypes.arrayOf(
                propTypes.string
            ),
            mobile: propTypes.arrayOf(
                propTypes.string
            ),
            email: propTypes.arrayOf(
                propTypes.string
            ),
            password: propTypes.arrayOf(
                propTypes.string
            )
        }),
        onClickSubmit: propTypes.func,
        redirectUrl: propTypes.string
    }),

    paymentFormData: propTypes.shape({
        isEnabled: propTypes.bool,
        isLoading: propTypes.bool,

        product: propTypes.object,
        brainTreeClientInstance: propTypes.object,
        orderErrors: propTypes.arrayOf(
            propTypes.string
        ),

        onSubmitCreditCardSubscription: propTypes.func,
        onSubmitPaypalSubscription: propTypes.func,

        submitButtonText: propTypes.string,

        error: propTypes.node,

        updateSuccess: propTypes.bool,

        paymentDetails: propTypes.shape({
            paymentType: propTypes.string
        })
    }),

    isPaymentRequired: propTypes.bool,
    isPubsClubs: propTypes.bool,
    isLoading: propTypes.bool,
    specialHeaderImageUrl: propTypes.string,
    specialHeaderText: propTypes.string,
    successfullySubscribed: propTypes.bool,
    subscribeErrorMessage: propTypes.string,
    successUrl: propTypes.string,
    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
