type FontOptions = {
    size?: number | string;
    lineHeight?: number | string;
    important?: boolean;
};

type Gibson = {
    (fontOptions?: FontOptions): string;
    semibold: (fontOptions?: FontOptions) => string;
    medium: (fontOptions?: FontOptions) => string;
    light: (fontOptions?: FontOptions) => string;
};

/**
 * @param fontOptions - The font customisation
 * @returns A string in the following format: 'size/lineHeight Gibson, sans-serif !important'
 */
const gibson: Gibson = ({size = 13, lineHeight = 1, important = false} = {}) =>
    `${
        Number.isFinite(size) ? size.toString().concat('px') : size
    }/${lineHeight} Gibson, sans-serif${important ? ' !important' : ''}`;

// below weights are named in accordance with https://docs.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass

/**
 * @param fontOptions - The font customisation
 * @returns A string in the following format: '600 size/lineHeight Gibson, sans-serif !important'
 */
gibson.semibold = (fontOptions) => `600 ${gibson(fontOptions)}`;

/**
 * @param fontOptions - The font customisation
 * @returns A string in the following format: '500 size/lineHeight Gibson, sans-serif !important'
 */
gibson.medium = (fontOptions) => `500 ${gibson(fontOptions)}`;

/**
 * @param fontOptions - The font customisation
 * @returns A string in the following format: '300 size/lineHeight Gibson, sans-serif !important'
 */
gibson.light = (fontOptions) => `300 ${gibson(fontOptions)}`;

export const BODY_MIN_STYLES_PX: {fontSize: number; lineHeight: number} = {
    fontSize: 11,
    lineHeight: 13,
};
export const HEADING_MIN_STYLES_PX: {fontSize: number; lineHeight: number} = {
    fontSize: 14,
    lineHeight: 18,
};

type EnforceMinFontStyle = {
    (initial?: number): number;
    heading: (initial?: number) => number;
};
/**
 * @param initial - the initial px fontsize
 * @returns The inital px fontsize or the projects minimum body fontsize. Whichever is bigger.
 */
export const enforceMinFontSize: EnforceMinFontStyle = (initial = 0) =>
    Math.max(initial, BODY_MIN_STYLES_PX.fontSize);

/**
 * @param initial - The initial px fontsize
 * @returns The inital px fontsize or the projects minimum heading fontsize. Whichever is bigger.
 */
enforceMinFontSize.heading = (initial = 0): number =>
    Math.max(initial, HEADING_MIN_STYLES_PX.fontSize);

/**
 * @param initial - The initial px lineHeight
 * @returns The inital px lineHeight or the projects minimum body lineHeight. Whichever is bigger.
 */
export const enforceMinLineHeight: EnforceMinFontStyle = (initial = 0) =>
    Math.max(initial, BODY_MIN_STYLES_PX.lineHeight);

/**
 * @param initial - The initial px lineHeight
 * @returns The inital px lineHeight or the projects minimum heading lineHeight. Whichever is bigger.
 */
enforceMinLineHeight.heading = (initial = 0) =>
    Math.max(initial, HEADING_MIN_STYLES_PX.lineHeight);

export default gibson;
