import {isPs4, isTizen, isXbox} from '@fsa-streamotion/browser-utils';

import type {MediaPlayerClass, TextTrackInfo} from 'dashjs';
import noop from 'lodash/noop';

import type PlaybackDash from '.';
import NativeCaptions from '../native/captions';

export default class DashCaptions extends NativeCaptions {
    override shouldHideCue = true;
    declare playbackHandler: MediaPlayerClass;
    declare playbackTech: PlaybackDash;

    private videoSizeDetector: number | undefined;
    #pendingTextTracksAddedEvent:
        | {
              streamId?: string;
              textTracksList: TextTrackInfo[];
          }
        | undefined;

    #isInitialTextTracksSetUp = false;

    constructor(playbackTech: PlaybackDash, playbackHandler: MediaPlayerClass) {
        super(playbackTech, playbackHandler);

        const {dashTTMLRenderingDiv, enableClosedCaptions} =
            playbackTech.options || {};

        Object.assign(this, {
            videoElement: playbackTech.videoElement,
        });

        this.playbackHandler.updateSettings({
            streaming: {text: {defaultEnabled: !!enableClosedCaptions}},
        });

        if (isPs4()) {
            // TextDecoder on PS4 doesn't behave the same as other devices and produces invalid XML which we can't decode.
            // As a workaround we can set it to undefined so Dash falls back to default decoding.
            Object.assign(window, {
                TextDecoder: undefined,
            });
        }

        if (dashTTMLRenderingDiv) {
            this.playbackHandler.attachTTMLRenderingDiv(dashTTMLRenderingDiv);
        }
    }

    override setup(): void {
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.TEXT_TRACKS_ADDED,
            this.onDashEventAllTextTracksAdded,
            this
        );
        this.playbackHandler.on(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.consumePendingTextTracksAddedEvent,
            this
        );
    }

    // Captions aren't persisting on Tizen devices
    // The actual captions text is present in the DOM but Dash.js isn't setting a height/width for the container.
    // We found that if we reset the captions after playback starts, Dash.js updates the captions div height/width.
    // (Initially raised in CTV-428)
    tizenWorkaround(): void {
        if (this.videoSizeDetector) {
            clearInterval(this.videoSizeDetector);
        }

        const tracks = this.playbackHandler.getTracksFor('text');

        if (tracks?.length) {
            this.videoSizeDetector = window.setInterval(() => {
                if (
                    this.videoElement?.videoWidth > 0 &&
                    this.videoElement?.videoHeight > 0
                ) {
                    if (this.currentTextTrackIndex >= 0) {
                        this.playbackHandler.setTextTrack(-1);
                        this.playbackHandler.setTextTrack(
                            this.currentTextTrackIndex
                        );
                    }

                    clearInterval(this.videoSizeDetector);
                }
            }, 1000);
        }
    }

    // This is used for native caption's implementation and triggered on
    // video-element 'addtrack' event which doesn't play well with dashjs
    override onEventTextTracksAddTrack = noop;

    /**
     * Some facts for multi period of stream:
     *   - TEXT_TRACKS_ADDED will be triggered for each period
     *   - TEXT_TRACKS_ADDED will be triggered before switching period when pre-buffering
     *   - `tracks` from event payload might be empty, e.g. event from switching to ad period
     * Based on the ASSUMPTION that a stream will not contain variable textTracks,
     * just filter out events with empty tracks and update textTrack in incoming PERIOD_SWITCH_COMPLETED once
     */
    onDashEventAllTextTracksAdded({
        tracks,
        streamId,
    }: {
        tracks?: TextTrackInfo[];
        streamId?: string;
    } = {}): void {
        if (!tracks?.length || this.#isInitialTextTracksSetUp) {
            return;
        }

        const textTracksList = tracks.map((textTrack) => ({
            ...textTrack,
            label:
                textTrack?.labels?.[0]?.text ||
                textTrack?.label ||
                textTrack?.lang,
        }));

        if (streamId === this.playbackHandler.getActiveStream()?.getId()) {
            this.#setInitialTextTracks(textTracksList);
        } else {
            // Too early to update textTracks and its index in TEXT_TRACKS_ADDED, just leave it to PERIOD_SWITCH_COMPLETED and only update it once
            this.#pendingTextTracksAddedEvent = {
                streamId,
                textTracksList,
            };
        }
    }

    consumePendingTextTracksAddedEvent(): void {
        if (isTizen()) {
            this.tizenWorkaround();
        }

        const isForCurrentStream =
            this.#pendingTextTracksAddedEvent?.streamId ===
            this.playbackHandler.getActiveStream()?.getId();

        if (this.#isInitialTextTracksSetUp || !isForCurrentStream) {
            return;
        }

        if (this.#pendingTextTracksAddedEvent) {
            this.#setInitialTextTracks(
                this.#pendingTextTracksAddedEvent.textTracksList
            );
            this.#pendingTextTracksAddedEvent = undefined;
        }
    }

    #setInitialTextTracks(textTracks: TextTrackInfo[]): void {
        this.textTracksList = textTracks;

        this.dispatchCaptionsUpdatedEvent();

        this.#isInitialTextTracksSetUp = true;
    }

    override set textTrack(index: number) {
        this.playbackHandler.setTextTrack(index);
        this.currentTextTrackIndex = index;

        this.dispatchCaptionsUpdatedEvent();
    }

    override disableTextTrack(): void {
        this.textTrack = -1;
    }

    override destroy(): void {
        // when we switch audio on xbox, after reset and restore player, subtitle will freeze, details in here https://github.com/fsa-streamotion/streamotion-web-app/pull/116#issue-1898044196
        if (isXbox()) {
            for (const textTrack of this.videoElement.textTracks) {
                textTrack.mode = 'disabled';
            }
        }

        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.TEXT_TRACKS_ADDED,
            this.onDashEventAllTextTracksAdded,
            this
        );
        this.playbackHandler.off(
            window.dashjs.MediaPlayer.events.PERIOD_SWITCH_COMPLETED,
            this.consumePendingTextTracksAddedEvent,
            this
        );

        if (this.videoSizeDetector) {
            clearInterval(this.videoSizeDetector);
        }

        super.destroy();
    }
}
