import React from 'react';
import propTypes from 'prop-types';

import ImageFallback from '@fsa-streamotion/streamotion-web-fs-ui/src/components/image-fallback/image-fallback';

const label = (title) => `Watch video for ${title}`;

const SpecialEventCard = ({title, imgSrc, fallbackImgSrc, onClick}) => (
    <div className="fsui-normalise fiso-hawk-match-card fiso-hawk-match-card--stackable">
        <section className="fiso-sc-match-card">
            <article className="fiso-sc-card">
                {/* @TODO: Microdata? */}
                <button
                    className="fiso-hawk-special-event-card__button"
                    onClick={onClick}
                    aria-label={label(title)}>
                    <figure className="fiso-hawk-special-event-card__figure">
                        <ImageFallback fallbackSrc={fallbackImgSrc}>
                            <img
                                className="fiso-hawk-special-event-card__img"
                                src={imgSrc}
                                alt={label(title)} />
                        </ImageFallback>
                        <figcaption className="fiso-hawk-special-event-card__figure-caption">
                            {title}
                        </figcaption>
                    </figure>
                </button>
            </article>
        </section>
    </div>
);

SpecialEventCard.defaultProps = {
    title: 'Special Event'
};

SpecialEventCard.propTypes = {
    title: propTypes.string,
    imgSrc: propTypes.string,
    fallbackImgSrc: propTypes.string,
    onClick: propTypes.func
};

export default SpecialEventCard;
