type Args = {
    tokens: string[];
    baseUrl?: string;
    extension?: string;
};

/**
 * Generate a set of configuration files from the specified base URL, tokens,
 * and extension.
 *
 * This generates one URL per token, where the first URL's file name (without
 * base URL and extension) is the first token and each subsequent file name
 * is the result of hyphenating the token with the previous file name.
 *
 * For example, given the tokens <code>['foo', 'bar', 'baz']</code>, the
 * following file names are produced:
 *
 * - foo
 * - foo-bar
 * - foo-bar-baz
 *
 * The provided base URL and extension are applied to each of these to form the
 * list of URLs which is returned.
 *
 * @param tokens    - tokens from which config file URLs are built, e.g. <code>['foo', 'bar', 'baz']</code>
 * @param baseUrl   - config files' base URL
 * @param extension - config files' extension, e.g. <code>'json'</code>
 * @returns the configuration file URLs
 */
export default function getConfigUrls({
    baseUrl,
    tokens,
    extension,
}: Args): string[] {
    return [
        ...new Set(
            tokens
                .reduce<string[][]>(
                    (acc, token) => [
                        ...acc,
                        [...(acc.slice(-1)?.[0] ?? []), token].filter(Boolean),
                    ],
                    [[]]
                )
                .map((tokens) => tokens.join('-'))
                .filter(Boolean)
        ),
    ].map((name) =>
        [[baseUrl, name].filter(Boolean).join('/'), extension]
            .filter(Boolean)
            .join('.')
    );
}
