import getLiveSpecialEventsStream from '../end-points/live-special-events';
import {DEFAULT_VIMOND_ENV} from '../../utils/constants';

import getMassagedLiveSpecialEventsData from './live-special-events-data-massager';

export default function getSpecialLiveEvents({
    sport,
    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return getLiveSpecialEventsStream({
        sport,
        vimondEnv
    })
        .map(getMassagedLiveSpecialEventsData);
}
