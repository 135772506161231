import merge from 'lodash/merge';

import getConfigUrls from './get-config-urls';

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Record<string, unknown>
        ? DeepPartial<T[P]>
        : T[P];
};

const RETRIES = 2;

/* eslint-disable import/export */

export default async function loadAndMergeConfigs<T>({
    baseUrl,
    tokens,
    extension,
}: {
    tokens: string[];
    baseUrl: string;
    extension?: string;
}): Promise<DeepPartial<T>>;

export default async function loadAndMergeConfigs<T>({
    baseUrl,
    tokens,
    signal,
    extension,
}: {
    tokens: string[];
    baseUrl: string;
    signal: AbortSignal;
    extension?: string;
}): Promise<DeepPartial<T> | undefined>;

/**
 * Load a set of configuration files and merge them together.
 *
 * The configuration file URLs are generated by {@link getConfigUrls}. This
 * will make parallel requests to these URLs and merge them together, such
 * that more-specific configurations will override less-specific configurations
 * where there are conflicting property values.
 *
 * Configuration specificity is proportional to the length of the URL. For
 * example, https://example.com/foo-bar.json is more specific than
 * https://example.com/foo.json.
 *
 * This allows for a combination of shared and specific configurations rather
 * than duplicating common configuration properties across all configuration
 * files of the same type.
 *
 * @param tokens    - tokens from which config file URLs are built, e.g. <code>['foo', 'bar', 'baz']</code>
 * @param baseUrl   - config files' base URL
 * @param extension - config files' extension, e.g. <code>'json'</code>
 * @param signal    - abort signal to cancel the request
 * @returns the merged configuration
 */
export default async function loadAndMergeConfigs<T>({
    baseUrl,
    tokens,
    signal,
    extension,
}: {
    tokens: string[];
    baseUrl: string;
    signal?: AbortSignal;
    extension?: string;
}): Promise<DeepPartial<T> | undefined> {
    const configUrls = getConfigUrls({baseUrl, tokens, extension});

    const configs: Array<DeepPartial<T> | undefined> = await Promise.all<
        DeepPartial<T> | undefined
    >(
        configUrls.map(async (configUrl) => {
            let lastError: Error | undefined;

            for (let attempt = 0; attempt < RETRIES; attempt++) {
                const response = await fetch(configUrl, {signal});

                if (!response.ok) {
                    if(response.status === 404) {
                        // If a config file isn't found, move on to the next one.
                        return undefined;
                    }

                    // Retry
                    continue;
                }

                try {
                    return (await response.json()) as DeepPartial<T>;
                } catch (error) {
                    if (
                        error instanceof DOMException &&
                        error.name === 'AbortError'
                    ) {
                        return undefined;
                    }

                    if (error instanceof Error) {
                        lastError = error;
                    }
                }
            }

            console.error(
                `Error while loading ${configUrl} (status: ${
                    (
                        (lastError as (Error & {status: number}) | undefined) ??
                        {}
                    ).status ?? 'N/A'
                })`,
                lastError
            );

            throw (
                lastError ??
                new Error(
                    'Failed to load configuration files. Please try again.'
                )
            );
        })
    );

    // Filter out configs that failed to load (typically 404)
    const loadedConfigs = configs.filter(
        (config): config is DeepPartial<T> => !!config
    );

    return merge({}, ...loadedConfigs);
}

/* eslint-enable import/export */
