import React from 'react';
import styled from 'styled-components';

import {THEME_ACCESSORS} from '../../../../common/theme-helpers';

const StyledContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${THEME_ACCESSORS.hudBackground};
    object-fit: contain;
`;

export type Props = {
    children?: React.ReactNode;
};

const GA72HudBg = React.forwardRef<HTMLDivElement, Props>(({children}, ref) => (
    <StyledContainer ref={ref}>{children}</StyledContainer>
));

GA72HudBg.displayName = 'GA72HudBg';

export default GA72HudBg;
