import React from 'react';
import propTypes from 'prop-types';
import omit from 'lodash/omit';

export default class FormSelect extends React.PureComponent {
    requiredOrNot() {
        return (this.props.required ? '' : ' (optional)');
    }

    render() {
        return (
            // I’m not using onBlur, because we want the pass information in the blue tile to update automatically, and understand the risks. This Select only gets used in two places on HAWK, for the "Choose your subscription" and "Your favourite AFL team", both on the /join page.
            /* eslint-disable jsx-a11y/no-onchange */
            <label className="fiso-hawk-form-select">
                <span className="fiso-hawk-form-select__label">
                    {this.props.title}{this.requiredOrNot()}
                </span>

                <select
                    {...omit(this.props, ['handleChange', 'children'])}
                    className="fiso-hawk-form-select__select"
                    onChange={(e) => { this.props.handleChange(e.target.name, e.target.value); }}>
                    {this.props.children}
                </select>
            </label>
        );
    }
}

FormSelect.defaultProps = {
    required: true
};

FormSelect.propTypes = {
    disabled: propTypes.bool, // eslint-disable-line react/no-unused-prop-types
    children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.element),
        propTypes.element
    ]),
    handleChange: propTypes.func,
    title: propTypes.string,
    required: propTypes.bool,
    name: propTypes.string  // eslint-disable-line react/no-unused-prop-types
};
