import {
    isMsEdgeLegacy,
    isSafariComputer,
    isWebmaf,
    isHisense,
    loadScript,
} from '@fsa-streamotion/browser-utils';

import type Hls from 'hls.js';
import inRange from 'lodash/inRange';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';

import {getShouldUseTizenAvplayer} from '../../utils/browser';
import PlaybackNative from '../native';
import type {CanPlaySourceParams} from '../types';
import HlsAudio from './audio';
import HlsBuffer from './buffer';
import HlsBufferEdge from './buffer--edge';
import HlsCaptions from './captions';
import {DEFAULT_HLSJS_CONFIG} from './constants';
import HlsCustomBitrate from './custom-bitrate';
import HlsDiagnostics from './diagnostics';
import HlsError from './error';
import HlsLivestream from './livestream';
import type {HlsjsConfig} from './types';

const PRELOAD_PROGRESS = {
    NONE: 0,
    METADATA: 1,
    AUTO: 2,
};

const DISABLE_SAFARI_COMPUTER_HLSJS = true;
const typeCheck = /^(audio|video|application)\/(x-|vnd\.apple\.)?mpegurl/i;
const fileExtensionCheck = /\.m3u8($|\?|;)/i;

async function loadLibrary({
    src,
    integrity,
}: {
    src: string;
    integrity?: string;
}): Promise<typeof Hls | undefined> {
    // eslint-disable-line import/prefer-default-export
    if (!window.Hls) {
        await loadScript({
            url: src,
            integrity,
            crossOrigin: 'anonymous',
            id: 'smweb-playertech-hlsjs-script',
        });
    }

    return window.Hls;
}

export default class PlaybackHls extends PlaybackNative {
    static override async canPlaySource({
        src,
        type,
        keySystems,
    }: CanPlaySourceParams): Promise<CanPlayTypeResult> {
        const windowObject = window || {
            MediaSource: undefined,
            WebKitMediaSource: undefined,
        };
        const mediaSource =
            windowObject.MediaSource || windowObject.WebKitMediaSource;

        // Hisense has native HLS support that we can defer to
        if (isWebmaf() || getShouldUseTizenAvplayer() || isHisense()) {
            return '';
        }

        if (DISABLE_SAFARI_COMPUTER_HLSJS === true && isSafariComputer()) {
            // If we've asked for Safari computers NOT to use hlsjs, say we can't.
            return '';
        }

        // We need to have MediaSource available in the browser.
        // typeof MediaSource is a object on PS4
        if (!isObject(mediaSource)) {
            return '';
        }

        // if (!Hls.isSupported()) {
        //     // No Hls support (tests MediaSource and stuff.)
        //     return '';
        // }

        // If we have keySystems, check we support that DRM
        if (
            !keySystems ||
            (await PlaybackHls.getAvailableKeySystems()).length
        ) {
            if (type && typeCheck.test(type)) {
                return 'probably';
            } else if (fileExtensionCheck.test(src)) {
                return 'probably';
            }
        }

        // Not sure about this source or type, so lets not try.
        return '';
    }

    static override async getAvailableKeySystems(/* keySystems = {} */): Promise<
        MediaKeySystemAccess[]
    > {
        // DRM support via hls.js is still experimental, and we don't want to use it on prod yet.
        // As such, we'll pretend that no key systems are available for hlsjs

        /*
        // @TODO Maybe add 'robustness' against audioCapabilities contentType, like 'SW_SECURE_CRYPTO' ?
        const requestMediaKeySystemAccessPromises = Object.entries(keySystems)
            .map(([keySystemName, {videoContentType, audioContentType, ...rest}]) => (
                window.navigator.requestMediaKeySystemAccess(keySystemName, [{
                    ...(audioContentType && {
                        audioCapabilities: [{contentType: audioContentType}],
                    }),
                    ...(videoContentType && {
                        videoCapabilities: [{contentType: videoContentType}],
                    }),
                    ...pick(rest, ['persistentState', 'distinctiveIdentifier']),
                }])
                    // The requestMediaKeySystemAccess actually throws an error if it fails, so we sanitise that here and filter it out later
                    .catch(() => null)
            ));

        return Promise.all(requestMediaKeySystemAccessPromises).then((keySystemAccesses) => keySystemAccesses.filter(Boolean));
        */
        return [];
    }

    hlsInstance: Hls | null = null;
    streamLoadedState = PRELOAD_PROGRESS.NONE;
    _activeKeySystemName: string | null = null;
    declare controllerLivestreamInstance: HlsLivestream;
    declare controllerAudioInstance: HlsAudio;
    declare controllerBufferInstance: HlsBuffer | HlsBufferEdge;
    declare controllerCaptionsInstance: HlsCaptions;
    declare controllerCustomBitrateInstance: HlsCustomBitrate;
    declare controllerError: HlsError;
    declare controllerDiagnostics: HlsDiagnostics;

    override preloadMetadata(): void {
        if (
            this.streamLoadedState < PRELOAD_PROGRESS.METADATA &&
            this.hlsInstance &&
            this.src
        ) {
            this.hlsInstance.loadSource(this.src);
            this.streamLoadedState = PRELOAD_PROGRESS.METADATA;
        }
    }

    override preloadAuto(): void {
        if (this.streamLoadedState < PRELOAD_PROGRESS.AUTO) {
            // If we don't have meta data yet, go get it.
            this.preloadMetadata();

            // TODO: Remove this if check after autoStartLoad is set to false in constructor
            // This will be after this hls.js bug is fixed: https://github.com/video-dev/hls.js/issues/1978
            if (!isMsEdgeLegacy()) {
                this.hlsInstance?.startLoad(this.options.startAt);
            }

            this.streamLoadedState = PRELOAD_PROGRESS.AUTO;
        }
    }

    #hlsjsConfig: Partial<HlsjsConfig> | undefined;

    /**
     * Get the hlsjs config, loading it if it hasn't already been loaded.
     *
     * This is a combination of the default hlsjs config and, if provided, the
     * external hlsjs config.
     *
     * @returns the combined hls config
     */
    async #getHlsjsConfig(): Promise<HlsjsConfig> {
        if (!this.#hlsjsConfig) {
            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Default hlsjs config:\n\n${JSON.stringify(
                    DEFAULT_HLSJS_CONFIG,
                    null,
                    2
                )}`
            );

            const externalHlsjsConfig = await this.options.getHlsjsConfig?.();

            if (externalHlsjsConfig || window.hlsConfig) {
                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] External hlsjs config:\n\n${JSON.stringify(
                        externalHlsjsConfig,
                        null,
                        2
                    )}`
                );

                // eslint-disable-next-line no-console
                console.debug(
                    `[PlayerTech] Window hlsjs config:\n\n${JSON.stringify(
                        window.hlsConfig,
                        null,
                        2
                    )}`
                );

                // Merge default and provided hls configs, preferring provided hls config values where there's a conflict.
                this.#hlsjsConfig = merge(
                    {},
                    DEFAULT_HLSJS_CONFIG,
                    externalHlsjsConfig,
                    window.hlsConfig
                );
            } else {
                this.#hlsjsConfig = DEFAULT_HLSJS_CONFIG;
            }

            // eslint-disable-next-line no-console
            console.debug(
                `[PlayerTech] Effective hlsjs config:\n\n${JSON.stringify(
                    this.#hlsjsConfig,
                    null,
                    2
                )}`
            );
        }

        return this.#hlsjsConfig ?? {};
    }

    override async setup(): Promise<boolean> {
        const {script, settings} = await this.#getHlsjsConfig();

        if (!script || !script.src) {
            return false;
        }

        // Load the hlsjs library if necessary.
        const Hls = await loadLibrary({
            src: script.src,
            integrity: script.integrity,
        });

        if (!Hls) {
            throw 'PlayerTech: hls.js was requested, but no suitable library was found.';
        }

        const hasWidevineDrm =
            !!this.keySystems?.['com.widevine.alpha']?.licenseUri &&
            this.availableKeySystems.some(
                ({keySystem}) => keySystem === 'com.widevine.alpha'
            );

        this.hlsInstance = new Hls({
            ...settings,

            debug: this.options.DEBUG_LIBS || false,

            xhrSetup: (xhr) => {
                if (this.options.withCredentials) {
                    xhr.withCredentials = true;
                }
            },

            capLevelToPlayerSize: this.options.capLevelToPlayerSize,

            // Don't automatically presume to start loading manifest. KTHX.
            // NOTE: If we set MS Edge to "autoStartLoad = false" initially it won't start playing until the user scrubs
            // This is necessary because of a hls.js bug https://github.com/video-dev/hls.js/issues/1978
            // @TODO: Remove this Edge workaround when possible
            autoStartLoad: isMsEdgeLegacy(),
            startPosition: this.options.startAt, // This is also over-ridden at this.preloadAuto -> startLoad, but you know, for sanity.

            ...(hasWidevineDrm && {
                widevineLicenseUrl:
                    this.keySystems?.['com.widevine.alpha']?.licenseUri,
                emeEnabled: true,
                // Add headers to license request if necessary
                licenseXhrSetup: async (xhr) => {
                    /*
                    The hls.js implementation of DRM is limited, and currently restricted to widevine
                    When we want playertech to support more complex DRM implementations we may need to either roll our own EME
                    or contribute to hls.js
                    TODO:
                        - pass in boolean isFirstRequest to getHttpRequestHeaders
                        - poll if licenseServerPollMs passed in
                        - pass through persistentState and distinctiveIdentifier when these options are supported by hls.js
                     */
                    const httpRequestHeaders =
                        (await this?.keySystems?.[
                            'com.widevine.alpha'
                        ]?.getHttpRequestHeaders?.()) || {};

                    Object.entries(httpRequestHeaders).forEach(
                        ([headerName, headerValue]) => {
                            xhr.setRequestHeader(headerName, headerValue);
                        }
                    );
                },
            }),
        });

        if (hasWidevineDrm) {
            this._activeKeySystemName = 'com.widevine.alpha';
        }

        if (isMsEdgeLegacy()) {
            this.controllerBufferInstance = new HlsBufferEdge(
                this,
                this.hlsInstance
            );
        } else {
            this.controllerBufferInstance = new HlsBuffer(
                this,
                this.hlsInstance
            );
        }

        this.controllerAudioInstance = new HlsAudio(this, this.hlsInstance);
        this.controllerAudioInstance.setup();

        this.controllerCaptionsInstance = new HlsCaptions(
            this,
            this.hlsInstance
        );
        this.controllerCaptionsInstance.setup();
        this.controllerCaptionsInstance.setupPiPListeners();

        this.controllerCustomBitrateInstance = new HlsCustomBitrate(
            this,
            this.hlsInstance
        );
        this.controllerLivestreamInstance = new HlsLivestream(
            this,
            this.hlsInstance
        );
        this.controllerError = new HlsError(this, this.hlsInstance);

        if (this.videoElement) {
            if (this.src) {
                this.controllerDiagnostics = new HlsDiagnostics(
                    this.hlsInstance,
                    this.videoElement,
                    this.src,
                    this.cdnProvider,
                    this.hasSsai
                );
            }

            this.videoElement.addEventListener(
                'canplaythrough',
                this._checkCurrentTimeStartAt
            );

            this.hlsInstance.attachMedia(this.videoElement);
        }

        return true;
    }

    _checkCurrentTimeStartAt = (): void => {
        if (!this.videoElement) {
            return;
        }

        this.videoElement.removeEventListener(
            'canplaythrough',
            this._checkCurrentTimeStartAt
        );

        // In some cases, it's possible to not end up playing where we expect on start.
        // This is mostly when hlsjs gets confused on audio discontinuity either on 0 or edge of live.
        // See if we're close to where we want to be, and if not, try and move us there.
        const {currentTime, duration} = this.videoElement;
        const requestingLiveEdge = this.options.startAt === -1;
        const requestedStartTime = requestingLiveEdge
            ? 0
            : this.options.startAt ?? -1;

        if (!this.controllerLivestreamInstance) {
            return;
        }

        const {live, onEdgeLeniency, bestGuessFragmentDuration} =
            this.controllerLivestreamInstance;

        let lowestOkayTime = requestedStartTime - onEdgeLeniency;
        let highestOkayTime = requestedStartTime + onEdgeLeniency;
        let adjustCurrentTimeTo: number;

        if (requestingLiveEdge && live) {
            lowestOkayTime = duration - onEdgeLeniency;
            highestOkayTime = duration;
        }

        // console.log('_checkCurrentTimeStartAt', {
        //     lowestOkayTime,
        //     highestOkayTime,
        //     currentTime,
        //     duration,
        //     requestedStartTime,
        //     requestingLiveEdge,
        //     live,
        //     onEdgeLeniency,
        //     bestGuessFragmentDuration,
        // });

        const isEdgeLegacyBrowser = isMsEdgeLegacy();
        const isInOkayRange = inRange(
            currentTime,
            lowestOkayTime,
            highestOkayTime
        );

        // NOTE: If we set MS Edge to "live" initially it won't start playing until the user scrubs
        // To get around this we can initially start it at 0, and then move it to edge later on
        // This is necessary because of a hls.js bug https://github.com/video-dev/hls.js/issues/1978
        // @TODO: Remove this Edge workaround when possible
        if (isEdgeLegacyBrowser) {
            this.videoElement.currentTime = 0;
        }

        if (!isInOkayRange || isEdgeLegacyBrowser) {
            if (requestingLiveEdge && live) {
                adjustCurrentTimeTo = duration - bestGuessFragmentDuration;
            } else {
                adjustCurrentTimeTo = requestedStartTime;
            }

            setTimeout(() => {
                setTimeout(() => {
                    if (!this.videoElement) {
                        return;
                    }

                    if (!isInOkayRange) {
                        console.warn(
                            `startAt discrepancy on canplaythrough. ${this.videoElement.currentTime} --> ${adjustCurrentTimeTo}`
                        );
                    }

                    this.videoElement.currentTime = adjustCurrentTimeTo;
                });
            });
        }
    };

    override async destroy(): Promise<void> {
        this.videoElement?.removeEventListener(
            'canplaythrough',
            this._checkCurrentTimeStartAt
        );
        this.hlsInstance?.destroy();
        this.hlsInstance = null;

        await super.destroy();
    }

    override play(): void {
        this.preloadAuto(); // Make sure we always have a stream ready.
        super.play(); // Proceed as normal thanks.
    }

    override get supportsAirPlay(): boolean {
        return false;
    }

    override showAirPlayTargetPicker(): void {
        console.warn('Unable to use showAirPlayTargetPicker for HLS.');
    }

    override get activeKeySystemName(): string | null {
        return this._activeKeySystemName;
    }
}
