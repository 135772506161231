/**
 * When isNaN() is just too confusing
 *
 * note: Trim your strings beforehand - any non-numeric characters in numberToCheck will cause a failure
 *
 * Only accepts numbers (including decimals).
 * No funny business like Infinite, NaN or scientific notation allowed.
 * Negative numbers allowed, as are decimals.
 * Leading or trailing zeroes are ok.
 * Doesn't try to parse your number, just lets you know if it is one
 *
 * Based on https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/parseFloat#A_stricter_parse_function
 *
 * @param numberToCheck - value to determine numericity of
 *
 * @returns false if numberToCheck is a simple number. true otherwise.
 */
export default function isReallyNaN(numberToCheck: unknown): boolean {
    let val: string;

    if (typeof numberToCheck === 'string') {
        val = numberToCheck;
    } else if (typeof numberToCheck === 'number') {
        val = numberToCheck.toString();
    } else {
        return true;
    }

    return !/^([-+])?([0-9]+(\.[0-9]+)?)$/.test(val);
}
