import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {PROXY_RESOURCE_URL} from '../../utils/constants';

/**
 * Get fixtures and resutls by fixtureId
 * http://ottapi-staging.foxsports.com.au/hawk-proxy-api/proxy/sports/afl/fixtures/1626/fixturesandresults.json
 * http://ottapi-staging.foxsports.com.au/hawk-proxy-api/proxy/sports/afl/fixtures/1624/fixturesandresults.json?assetTypes=live%2Cmatch
 * /proxy/sports/{sport}/fixtures/{fixtureId}/fixturesandresults.json
 *
 * @param  {String} sport                    e.g. afl
 * @param  {String} fixtureId                e.g.
 *
 * @param  {String}  assetTypes              e.g. // comma-seperated live, match, news ...etc
 * @param  {Boolean} is400Valid              e.g. false if a HTTP 400 response should return an error stream, true if it should return {}
 * @param  {Number}  freq                    e.g. polling frequency
 * @param  {String}  vimondEnv               e.g. 'prod'
 * @return {Observable}                      Stream containing fixturesandresults object
 */
export default function getFixturesAndResultsByFixtureId({
    sport,
    fixtureId,
    assetTypes,
    is400Valid = false,

    freq = 0,
    vimondEnv = 'prod'
}) {
    const url = PROXY_RESOURCE_URL[vimondEnv] +
        `sports/${sport}` +
        `/fixtures/${fixtureId}` +
        '/fixturesandresults.json' +
        (assetTypes ? `?assetTypes=${assetTypes}` : '');

    const isResponseValid = (superAgentObject) => superAgentObject.responseOk || (is400Valid && superAgentObject.responseStatus === 400);

    return getApiFromRetryWithError({
        url,
        freq,
        isResponseValid
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            } else if (response.responseStatus === 400 && is400Valid) {
                return {};
            }

            const errorMessage = `FS-HAWKOTT: unable to get fixtures and results by fixtureId data (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
