import bacon from 'baconjs';
import superagent from 'superagent';
import client from 'braintree-web/client';
import noop from 'lodash/noop';

import {streamFromApi} from '../../utils/api';

export default function ({
    baseUrl = ''
}) {
    const url = `${baseUrl}/braintree/client_token`;
    const request = superagent
        .get(url)
        .set('Content-Type', 'application/json');
    const clientTokenStream = streamFromApi(request).map('.body.token');

    return clientTokenStream.flatMapLatest((authorization) => {
        const configuration = {
            authorization
        };

        return bacon.fromBinder((sink) => {
            client.create(configuration, (clientError, clientInstance) => {
                if (clientError) {
                    sink(new bacon.Error(clientError));
                } else  {
                    sink(clientInstance);
                    sink(new bacon.End());
                }
            });

            return noop;
        });
    });
}
