import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {TV_GUIDE_API} from '../../utils/constants';

export default function ({
    freq = 0,
    channel = 10,
    size = 1
}) {
    const retries = (process && process.browser) ? 3 : 0;
    const cacheBustingHack = `?v=fiso${process && process.browser ? '_b' : '_s'}`;
    const url = `${TV_GUIDE_API}programmes/commingup.json?channel=${channel}&size=${size}&${cacheBustingHack}`;

    return getApiFromRetryWithError({
        url,
        freq,
        retries
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            }
            const errorMessage = `FS-HAWK-WIDGETS: Unable to get TV Guide ComingUp (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
