import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC25ArrowR = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M5.566 78.276L0 72.766 33.976 39.138 0 5.509 5.566 0 45.109 39.138z"
            transform="translate(30 11)"
        />
    </Ic>
);

IC25ArrowR.displayName = 'IC25ArrowR';

export default IC25ArrowR;
