import type {
    CommonErrorData,
    ErrorDatum,
    ScreenConfig,
    ErrorDetails,
} from '@fsa-streamotion/player-state';

import {version} from '../version';

type ParsedError = {
    activeKeySystemName?: string;
    message?: string;
    title?: string;
    timestamp: number;
    version: string;
    udid?: string;
    profileId?: string;
    player?: string;
    assetId?: string;
    appVersion?: string;
    customElementsConfig?: CommonErrorData['customElementsConfig'];
};

/**
 * Extract common error information from a screenConfig object (most often from `activeScreenConfig`) and error details.
 *
 * @param screenConfig - passed in, e.g. screenConfigByIndex, @see ScreenConfig
 * @param errorData - @see ErrorDatum, CommonErrorData
 * @param commonErrorData - @see CommonErrorData
 *
 * @returns The error object
 */
export default function parseError(
    {playerTech}: ScreenConfig,
    errorData: ErrorDatum | ErrorDetails,
    {additionalErrorData, customElementsConfig}: CommonErrorData
): ParsedError {
    const {diagnostics, message, title, commonAssetId} = errorData ?? {};
    const activeKeySystemName =
        diagnostics?.activeKeySystemName ??
        playerTech?.currentPlaybackHandler?.activeKeySystemName ??
        undefined;
    const {appVersion, assetId, udid, profileId, player} =
        additionalErrorData ?? {};

    return {
        activeKeySystemName,
        message,
        title,
        timestamp: Date.now(),
        version,
        udid,
        profileId,
        player,
        assetId: assetId ?? commonAssetId,
        appVersion,
        customElementsConfig,
    };
}
