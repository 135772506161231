import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import React from 'react';
import styled, {css} from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {Button} from '../../../../common/normalized-styled-components';
import {white} from '../../../../common/palette';
import {
    SCREEN_1920_DESKTOP,
    SCREEN_768_TABLET,
} from '../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../common/theme-helpers';
import {visuallyHiddenBaseStyles} from '../../../../common/visually-hidden';

const StyledButton = styled(Button)`
    appearance: none;
    display: flex;
    align-items: center;
    transition: ${transition('opacity')};
    opacity: 0.6;
    border: 0;
    background-color: transparent;
    padding: 0;
    color: ${white};

    &:focus-visible,
    &:hover {
        opacity: 0.8;
        outline: none;
    }

    &:active,
    &[aria-pressed='true'] {
        opacity: 1;
    }
`;

const labelBaseStyles = css`
    position: initial;
    margin-left: 7px;
    width: auto;
    height: auto;
    overflow: visible;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    font: ${gibson.medium({size: 14})};
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        font-size: 16px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 26px;
    `}
`;

const ButtonLabel = styled.span`
    ${labelBaseStyles}
    ${stylesWhen('shouldHideOnMobile')`
        ${visuallyHiddenBaseStyles}
        ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
            ${labelBaseStyles}
        `}
    `}
    ${stylesWhen('isLabelOnLeft')`
        margin-left: 0;
        margin-right: 14px;
    `}
`;

const IconContainer = styled.div`
    width: 30px;
    height: 30px;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 40px;
        height: 40px;
    `}

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 70px;
        height: 70px;
    `}
`;

export type Props = {
    'aria-label'?: string;
    'children': React.ReactNode;
    'label'?: string;
    'isLabelOnLeft'?: boolean;
    'disabled'?: boolean;
    'isPressed'?: boolean;
    'onClick'?: React.MouseEventHandler<HTMLButtonElement>;
    'onFocus'?: React.FocusEventHandler<HTMLButtonElement>;
    'onMouseEnter'?: React.MouseEventHandler<HTMLButtonElement>;
    'onMouseLeave'?: React.MouseEventHandler<HTMLButtonElement>;
    'shouldHideLabelOnMobile'?: boolean;
    'shouldUseParentContainer'?: boolean;
    'tabIndex'?: number;
    'onDoubleClick'?: React.MouseEventHandler<HTMLButtonElement>;
};

const BA01CtrlBtn = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            'aria-label': ariaLabel,
            children,
            label,
            isLabelOnLeft = false,

            disabled = false,
            isPressed = false,

            onClick,
            onFocus,
            onMouseEnter,
            onMouseLeave,
            shouldHideLabelOnMobile = false,
            shouldUseParentContainer = false,
            ...htmlAttributes
        },
        ref
    ) => (
        <StyledButton
            {...htmlAttributes}
            aria-pressed={isPressed}
            aria-label={ariaLabel}
            disabled={disabled}
            title={label || ariaLabel}
            onClick={onClick}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            {!!label && !!isLabelOnLeft && (
                <ButtonLabel
                    shouldHideOnMobile={shouldHideLabelOnMobile}
                    isLabelOnLeft={isLabelOnLeft}
                >
                    {label}
                </ButtonLabel>
            )}
            {shouldUseParentContainer ? (
                children
            ) : (
                <IconContainer>{children}</IconContainer>
            )}
            {!!label && !isLabelOnLeft && (
                <ButtonLabel shouldHideOnMobile={shouldHideLabelOnMobile}>
                    {label}
                </ButtonLabel>
            )}
        </StyledButton>
    )
);

BA01CtrlBtn.displayName = 'BA01CtrlBtn';

export default BA01CtrlBtn;
