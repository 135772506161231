import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = {
    color: string;
    secondaryColor: string;
} & IcProps;
const IC31Tick = ({
    color,
    secondaryColor,
    ...otherProps
}: Props): React.ReactElement => (
    <Ic {...otherProps}>
        <g transform="translate(8 8)">
            <circle fill={color} cx="41.887" cy="41.887" r="41.887" />
            <path
                fill={secondaryColor}
                d="M31.944 63.928L16.755 48.739 22.578 42.916 31.944 52.283 61.141 23.086 66.963 28.922z"
            />
        </g>
    </Ic>
);

IC31Tick.displayName = 'IC31Tick';

export default IC31Tick;
