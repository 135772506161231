/**
 * Converts provided string to html encoded safe string
 *
 * @param  {String} string input string
 * @return {String}        html safe string
 */
export function htmlEncode(string) {
    if (typeof string !== 'string') {
        return '';
    }

    return string
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
}

/**
 * Convert the specified string into a single-quoted JavaScript literal string suitable for
 * usage in a JavaScript code generator.
 *
 * If the parameter is <code>null</code> or <code>undefined</code>, it is returned as is.
 *
 * If the parameter is neither <code>null</code> nor <code>undefined</code> and is not a
 * string (which you should never do), <code>null</code> is returned.
 *
 * @param {String} s - a string
 * @returns {String} the JavaScript literal equivalent of the specified string
 */
export function asStringLiteral(s) {
    if (s === null || s === undefined) {
        return `${s}`;
    }

    if (typeof s !== 'string') {
        return 'null';
    }

    return `'${s.replace(/\\/g, '\\\\').replace(/'/g, '\\\'')}'`;
}
