import React from 'react';
import styled from 'styled-components';

import {fadeInOut} from '../../../../common/animations';
import CommonTransition from '../../../../common/common-transition';
import View, {type Props as ViewProps} from './view';

const Container = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    pointer-events: none;
    ${fadeInOut}
`;

export type Props = {
    /** A flag determining if the thumbnail carousel is visible */
    isVisible?: boolean;
} & ViewProps;

const TM03ThumbnailCarousel = ({
    urls,
    activeThumbnailUrlIndex,
    activeThumbnailContent,
    position,
    isVisible = true,
}: Props): React.ReactElement => (
    <CommonTransition in={isVisible}>
        <Container>
            <View
                {...{
                    urls,
                    activeThumbnailUrlIndex,
                    activeThumbnailContent,
                    position,
                }}
            />
        </Container>
    </CommonTransition>
);

TM03ThumbnailCarousel.displayName = 'TM03ThumbnailCarousel';

export default TM03ThumbnailCarousel;
