import React from 'react';
import bacon from 'baconjs';

import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml, handleStreamForIsoRender} from '@fsa/fs-commons/lib/iso/render';

import {createRoot} from 'react-dom/client';
import {isUserLoggedIn} from '../utils/auth';
import {MEDIA_OTT_URL} from '../utils/constants';
import getBannerConfig from '../streams/banner-config/get-banner-config';
import getUser from '../streams/user/user-get';

import bannerWidget from '../widgets/banner';

import PromoHeaderComponent from '../components/promo-header';

const ISO_ERROR_EXPIRES_IN = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN = 5 * 60; // 5 minutes.

function PromoHeader(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        bannerConfigPath: settings.bannerConfigPath || '/banner/offer-home/config.json',
        currency: settings.currency || 'AUD',
        promoHeaderConfigName: settings.promoHeaderConfigName,
        sport: settings.sport || 'afl',
        vimondEnv: settings.vimondEnv
    };
}

PromoHeader.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

PromoHeader.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: PromoHeader',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

PromoHeader.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <PromoHeaderComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

PromoHeader.prototype.getData = function (initialData = false) {
    const {promoHeaderConfigName, vimondEnv, sport} = this.config;

    const promoHeaderConfig$ = initialLaterOr(
        initialData && initialData.promoHeaderConfig$,
        getBannerConfig({bannerConfigPath: `/${sport}/promo-header/config/${promoHeaderConfigName}.json`})
            .flatMapError(() => ({})) // Just default to {} on errors so we render the default header
    );

    const userFirstName$ = isUserLoggedIn() ? getUser({sport, vimondEnv}).map('.firstName') : bacon.later(0, undefined);

    // Once we have the config and the optional user first name, we can determine what data the component should render
    const promoHeaderData$ = bacon.combineTemplate({
        promoHeaderConfig: promoHeaderConfigName ? promoHeaderConfig$ : {},
        userFirstName: userFirstName$
    }).map(({promoHeaderConfig, userFirstName}) => {
        const {promoImageSrc, promoTitleExpiredUser, promoTextExpiredUser, promoTitle, promoText} = promoHeaderConfig;
        const hasUser = !!userFirstName;

        // If there is a logged in user, the promo text and title may be optionally overridden
        return {
            promoImageSrc: promoImageSrc || `${MEDIA_OTT_URL}/${sport}/promo-header/home_banner_default.jpg`,

            promoTitle: (hasUser && !!promoTitleExpiredUser) ? promoTitleExpiredUser : promoTitle,
            promoText: (hasUser && !!promoTextExpiredUser) ? promoTextExpiredUser : promoText
        };
    });

    const bannerSettings = {
        currency: this.config.currency,
        vimondEnv,
        bannerConfigPath: `/${sport}${this.config.bannerConfigPath}`
    };

    const banner = bannerWidget(null, bannerSettings)
        .initComponentStream(initialData && initialData.banner);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            banner: banner.map('.reactElement'),
            userFirstName: userFirstName$,
            promoHeaderData: promoHeaderData$,
            sport
        }),
        iso: bacon.combineTemplate({
            banner: banner.map('.iso'),
            promoHeaderConfig$
        })
    });
};

PromoHeader.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <PromoHeaderComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <PromoHeaderComponent {...data.view} />,
            'hawkwidgets-promo-header',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

PromoHeader.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

export default function (element, settings) {
    return new PromoHeader(element, settings);
}

/**
 *  * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 *   */
pageBoot(PromoHeader, 'hawkwidgets-promo-header');
