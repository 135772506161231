import {
    ANALYTICS_EVENT_NAME,
    SCRUBBER_TYPE,
} from '@fsa-streamotion/player-state';
import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import noop from 'lodash/noop';
import {observer} from 'mobx-react-lite';
import {rgba} from 'polished';
import React, {useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';

import {transition} from '../../../../../common/animations';
import {black, red, white} from '../../../../../common/palette';
import {
    SCREEN_667_PHABLET_LANDSCAPE,
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
    SCREEN_2560_DESKTOP,
    useEffectiveViewport,
} from '../../../../../common/screen-sizes';
import {effectiveViewportMediaQuery} from '../../../../../common/theme-helpers';
import BA01CtrlBtn from '../../../../../component-library/atoms/ba/01-ctrl-btn';
import IC05Exp from '../../../../../component-library/atoms/ic/05-exp';
import IC06Play from '../../../../../component-library/atoms/ic/06-play';
import IC07Pau from '../../../../../component-library/atoms/ic/07-pau';
import IC14Skb from '../../../../../component-library/atoms/ic/14-skb';
import IC15Skf from '../../../../../component-library/atoms/ic/15-skf';
import IC19JLive from '../../../../../component-library/atoms/ic/19-j-live';
import IC20Live from '../../../../../component-library/atoms/ic/20-live';
import IC22Float from '../../../../../component-library/atoms/ic/22-float';
import IC28RelVid from '../../../../../component-library/atoms/ic/28-rel-vid';
import IC32PbRate from '../../../../../component-library/atoms/ic/32-pb-rate';
import IC72Min from '../../../../../component-library/atoms/ic/72-min';
import IC173KeyMPlayHead from '../../../../../component-library/atoms/ic/173-key-m-play-head';
import IC221NextUp from '../../../../../component-library/atoms/ic/221-next-up';
import IM01InputVol from '../../../../../component-library/molecules/im/01-input-vol';
import IM02Scrubber from '../../../../../component-library/molecules/im/02-scrubber';
import TM01DisplayTime from '../../../../../component-library/molecules/tm/01-display-time';
import HoverThumbnail from '../../../../../component-library/molecules/tm/02-hover-thumbnail/container';
import ThumbnailCarousel from '../../../../../component-library/molecules/tm/03-thumbnail-carousel/container';
import OR01LowerControls, {
    ScrubberAndButtonRowContainer,
} from '../../../../../component-library/organisms/or/01-lower-controls';
import {SEEK_ADJUSTMENT_S} from '../../../../utils/constants';
import {
    getNextPlaybackRate,
    PLAYBACK_RATE_TO_STRING,
} from '../../../../utils/playback-rate';
import {usePlayerContext} from '../../../context';
import getKeyDownHandler, {spaceKey} from '../../utils/keydown-handler';
import SplitViewLayoutSelector from '../split-view-layout-selector';
import MarkerButtonRow from './marker-button-row';

const UNDERLAY_MIN_HEIGHT = '160px';

const StyledDiv = styled.div`
    --smwplayer-underlay-height: ${UNDERLAY_MIN_HEIGHT};
    display: grid;
    grid-template-areas:
        'markers'
        'scrubber';
    grid-template-rows: 1fr auto;
    transition: ${transition('width')};
    width: ${({theme}) =>
        theme.isHudOpen ? `${theme.effectiveViewportWidthPx}px` : '100%'};
    height: 100%;

    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_768_TABLET})`
        --smwplayer-underlay-height: 211px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        --smwplayer-underlay-height: 235px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        --smwplayer-underlay-height: 256px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        --smwplayer-underlay-height: 380px;
    `}
    ${effectiveViewportMediaQuery({minWidthPx: SCREEN_2560_DESKTOP})`
        --smwplayer-underlay-height: 422px;
    `}

    &::before {
        position: absolute;
        bottom: 0;
        transform: translateY(
            ${({isVisible, isInAdBreak}) =>
                isInAdBreak || isVisible
                    ? 0
                    : 'var(--smwplayer-underlay-height)'}
        );
        transition: ${transition('transform')};
        background-image: linear-gradient(
            transparent,
            ${({isInAdBreak}) =>
                isInAdBreak ? `${rgba(black, 0.7)} 93%` : `${black} 60%`}
        );
        width: 100%;
        height: var(--smwplayer-underlay-height);
        min-height: ${UNDERLAY_MIN_HEIGHT};
        content: '';
        pointer-events: none;

        ${stylesWhen('isThumbnailCarouselVisible')`
            background-image: linear-gradient(${rgba(black, 0.6)}, ${rgba(
            black,
            0.4
        )} 60%, ${black} 100%);
        `}
    }
`;

const PositionedOR01LowerControls = styled(OR01LowerControls)`
    grid-area: scrubber;
    transition: ${transition('opacity', 'transform')};

    pointer-events: all;

    ${stylesWhen('shouldLowerControlsOpacity')`
        opacity: 0.5;
    `}
`;

const StyledScrubberAndButtonRowContainer = styled(
    ScrubberAndButtonRowContainer
)`
    grid-area: markers;
    margin-bottom: 0;
`;

const LowerControls = (
    _: unknown,
    ref: React.ForwardedRef<HTMLButtonElement>
): React.ReactElement => {
    const playerState = usePlayerContext();
    const {
        activeScreenConfig,
        generalConfig,
        globalActions,
        globalState,
        isSingleLayout,
        canShowThumbnailCarousel,
        handleInteraction,
    } = playerState ?? {};

    const {actions, playbackData, playerTech, videoState, keyEvent} =
        activeScreenConfig ?? {};
    const {scrubBarType} = playbackData ?? {};

    const {
        isLowerControlsVisible,
        isMuted,
        volume,
        isFullscreen,
        isUserHoldingControls,
        currentHoverPosition,
        activeScreenIndex,
        isHudVisible,
        upNext,
    } = globalState ?? {};

    const {setIsFullscreen, setTrayVisibility, updateUpNextData} =
        globalActions ?? {};

    const {
        canHaveRelatedVideos,
        canChangePlaybackSpeed,
        onPlayerInteractionByType,
        shouldOverrideScrubberBehaviour,
    } = generalConfig ?? {};

    const {
        bufferedTimeRanges,
        currentTime,
        duration,
        seekingTime,
        isSeeking,
        isPlaying,
        isLiveStream,
        isOnEdge,
        playbackRate,
        isPausedFor5Seconds,
        thumbnails,
        isKeyMomentsVisible,
        latestSecondsViewed,
        isInAdBreak,
        adCurrentTime,
        adDuration,
    } = videoState ?? {};

    const {
        setCurrentTime,
        setVolume,
        stepVolume,
        setIsMuted,
        togglePlayPause,
        setPlaybackRate,
        setCurrentTimeToEdge,
        toggleIsPopOutPlayer,
        toggleKeyMoments,
    } = actions ?? {};

    const shouldLowerControlsOpacity = isSingleLayout && isPausedFor5Seconds;
    const isPhabletLandscapeUp = useEffectiveViewport(
        SCREEN_667_PHABLET_LANDSCAPE
    );
    const isDesktopUp = useEffectiveViewport(SCREEN_1280_DESKTOP);
    // TODO: Modify this feature flag to common condition after the `scrubbarType` migration complete on Kayo and Flash.
    const shouldLockScrubberToEdge = scrubBarType
        ? SCRUBBER_TYPE[scrubBarType] === SCRUBBER_TYPE.none
        : shouldOverrideScrubberBehaviour?.(videoState);

    const keyMoments =
        (isHudVisible ? isDesktopUp : isPhabletLandscapeUp) &&
        keyEvent?.events?.length
            ? keyEvent.events.map(({displayTime, offset, ...rest}) => ({
                  ...rest,
                  displayTime,
                  offset,
                  onClick: () => actions?.setCurrentTime(displayTime - offset),
              }))
            : null;

    const skipBackButton = !shouldLockScrubberToEdge && !isInAdBreak && (
        <BA01CtrlBtn
            aria-label={`Rewind ${SEEK_ADJUSTMENT_S} seconds`}
            onClick={() => {
                if (
                    setCurrentTime &&
                    seekingTime &&
                    onPlayerInteractionByType
                ) {
                    setCurrentTime(seekingTime - SEEK_ADJUSTMENT_S);
                    onPlayerInteractionByType(
                        ANALYTICS_EVENT_NAME.SELECT_OPTION,
                        `-${SEEK_ADJUSTMENT_S}`
                    );
                }
            }}
            tabIndex={isLowerControlsVisible ? 0 : -1}
        >
            <IC14Skb color={white} />
        </BA01CtrlBtn>
    );

    const skipForwardButton = !shouldLockScrubberToEdge && !isInAdBreak && (
        <BA01CtrlBtn
            aria-label={`Skip ${SEEK_ADJUSTMENT_S} seconds`}
            onClick={() => {
                if (
                    setCurrentTime &&
                    seekingTime &&
                    onPlayerInteractionByType
                ) {
                    setCurrentTime(seekingTime + SEEK_ADJUSTMENT_S);
                    onPlayerInteractionByType(
                        ANALYTICS_EVENT_NAME.SELECT_OPTION,
                        `+${SEEK_ADJUSTMENT_S}`
                    );
                }
            }}
            tabIndex={isLowerControlsVisible ? 0 : -1}
        >
            <IC15Skf color={white} />
        </BA01CtrlBtn>
    );

    const playButton = !shouldLockScrubberToEdge && (
        <BA01CtrlBtn
            aria-label={isPlaying ? 'Pause' : 'Play'}
            onClick={togglePlayPause}
            tabIndex={isLowerControlsVisible ? 0 : -1}
        >
            {isPlaying ? <IC07Pau color={white} /> : <IC06Play color={white} />}
        </BA01CtrlBtn>
    );

    const rate =
        typeof playbackRate === 'number'
            ? PLAYBACK_RATE_TO_STRING[playbackRate]
            : undefined;
    const playbackSpeedButton = canChangePlaybackSpeed ? (
        <BA01CtrlBtn
            isPressed={playbackRate !== 1}
            aria-label="Toggle playback rate"
            onClick={() => {
                if (typeof playbackRate === 'number') {
                    const nextPlaybackRate = getNextPlaybackRate(playbackRate);

                    if (typeof nextPlaybackRate !== 'undefined') {
                        setPlaybackRate?.(nextPlaybackRate);
                    }
                }
            }}
            tabIndex={isLowerControlsVisible ? 0 : -1}
        >
            <IC32PbRate rate={rate} color={white} />
        </BA01CtrlBtn>
    ) : null;

    const goToLiveButton = isLiveStream ? (
        <BA01CtrlBtn
            isPressed={isOnEdge}
            onClick={setCurrentTimeToEdge}
            aria-label="Scrub To Live"
            tabIndex={isLowerControlsVisible ? 0 : -1}
        >
            {isOnEdge ? (
                <IC20Live textColor={white} color={red} />
            ) : (
                <IC19JLive color={white} />
            )}
        </BA01CtrlBtn>
    ) : null;

    const FullscreenIcon = isFullscreen ? IC72Min : IC05Exp;
    const fullscreenButton = (
        <BA01CtrlBtn
            isPressed={isFullscreen}
            aria-label="Toggle Fullscreen"
            onClick={() => {
                setIsFullscreen?.(!isFullscreen);
            }}
            tabIndex={isLowerControlsVisible ? 0 : -1}
        >
            <FullscreenIcon color={white} />
        </BA01CtrlBtn>
    );

    const displayTime = shouldLockScrubberToEdge ? null : (
        <TM01DisplayTime
            currentTime={isInAdBreak ? adCurrentTime : currentTime}
            duration={isInAdBreak ? adDuration : duration}
            isInAdBreak={isInAdBreak}
            isLiveStream={isLiveStream}
            isLiveLinear={playbackData?.metadata?.isPlaybackTypeLiveLinear}
            isOnEdge={isOnEdge}
            scrubBarType={scrubBarType}
        />
    );

    // No sense computing this more than once
    const isPopOutPlayerSupported = useMemo(
        () => playerTech?.supportsPopOutPlayer,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const popOutPlayerButton =
        isPhabletLandscapeUp && isPopOutPlayerSupported ? (
            <BA01CtrlBtn
                onClick={toggleIsPopOutPlayer}
                aria-label="Pop Out Player"
                tabIndex={isLowerControlsVisible ? 0 : -1}
            >
                <IC22Float color={white} />
            </BA01CtrlBtn>
        ) : null;

    const volumeSlider = (
        <IM01InputVol
            isMuted={isMuted}
            volume={volume}
            setVolumeFunction={setVolume}
            setMutedFunction={setIsMuted}
            tabIndex={isLowerControlsVisible ? 0 : -1}
        />
    );

    const relatedVideosButton =
        isPhabletLandscapeUp && canHaveRelatedVideos ? (
            <BA01CtrlBtn
                ref={ref}
                label="More Content"
                shouldHideLabelOnMobile={true}
                onClick={(event) => {
                    setTrayVisibility?.({lower: true, upper: false});
                    playerState?.handleInteraction(event.nativeEvent);
                    // prevent click events from bubbling and closing the player
                    event.preventDefault();
                    event.stopPropagation();
                }}
                tabIndex={isLowerControlsVisible ? 0 : -1}
            >
                <IC28RelVid color={white} />
            </BA01CtrlBtn>
        ) : null;

    const toggleKeyMomentsButton =
        keyMoments && keyMoments.length ? (
            <BA01CtrlBtn
                aria-label="Hide Key Moments"
                isPressed={!isKeyMomentsVisible}
                onClick={() => {
                    if (typeof activeScreenIndex !== 'undefined') {
                        toggleKeyMoments?.(activeScreenIndex);
                    }
                }}
            >
                <IC173KeyMPlayHead color={white} isOn={isKeyMomentsVisible} />
            </BA01CtrlBtn>
        ) : null;

    const hasThumbnails =
        !Array.isArray(thumbnails) &&
        thumbnails?.totalThumbnails &&
        thumbnails?.totalThumbnails > 0;
    const isThumbnailCarouselVisible =
        hasThumbnails && canShowThumbnailCarousel;
    const thumbnailCarousel = isThumbnailCarouselVisible && (
        <ThumbnailCarousel
            thumbnailRegistry={thumbnails}
            seekingTime={seekingTime}
            duration={duration}
        />
    );

    const getOnMouseDownUp = (): (() => void) => {
        const wasPlayingWhenScrubingStarted = isPlaying;

        playerState?.setIsUserHoldingControls(true);
        playerTech?.pause();

        return function onMouseUp(): void {
            // `setTimeout` is needed here because if we call `playTech.play()` and `playTech.pause()`
            //   in the same tick (when user clicks somewhere on scrubber), only the first one will be executed (weirdly)
            setTimeout(() => {
                if (wasPlayingWhenScrubingStarted) {
                    playerTech?.play();
                }

                playerState?.setIsUserHoldingControls(false);
            });
        };
    };

    const hoverThumbnail =
        hasThumbnails &&
        !isUserHoldingControls &&
        currentHoverPosition &&
        duration ? (
            <HoverThumbnail
                thumbnailRegistry={thumbnails}
                currentHoverPosition={currentHoverPosition}
                duration={duration}
            />
        ) : null;

    const cancelUpdateUpNextRef = useRef<(() => void) | void>();

    useEffect(function cancelUpdateUpNext() {
        return () => cancelUpdateUpNextRef.current?.();
    }, []);

    const nextEpisodeButton = !!upNext?.onNextVideoPlay && (
        <BA01CtrlBtn
            label="Next Up"
            shouldHideLabelOnMobile={true}
            onClick={() => {
                // If not a live stream use the existing up next data
                if (!isLiveStream) {
                    return upNext.onNextVideoPlay?.(activeScreenIndex);
                }

                // If it's a live stream update the up next data
                cancelUpdateUpNextRef.current?.();
                cancelUpdateUpNextRef.current = updateUpNextData?.(
                    playbackData,
                    (upNextData) => {
                        cancelUpdateUpNextRef.current = undefined;

                        // If there is any error in upadting upNext data
                        // as a fallback we want to use existing upNext data
                        const effectiveUpNext = upNextData ?? upNext;

                        playerState?.setUpNext(effectiveUpNext);
                        effectiveUpNext?.onNextVideoPlay?.(activeScreenIndex);
                    }
                );
            }}
        >
            <IC221NextUp />
        </BA01CtrlBtn>
    );

    const onSkipArrowKeyDownAction = useMemo(
        () => ({
            ArrowRight: isInAdBreak
                ? noop
                : (e: React.KeyboardEvent | Event) => {
                      if (seekingTime) {
                          setCurrentTime?.(seekingTime + SEEK_ADJUSTMENT_S);
                      }

                      onPlayerInteractionByType?.(
                          ANALYTICS_EVENT_NAME.SELECT_OPTION,
                          `+${SEEK_ADJUSTMENT_S}`
                      );
                      handleInteraction?.(
                          e instanceof Event ? e : e.nativeEvent
                      );
                  },
            ArrowLeft: isInAdBreak
                ? noop
                : (e: React.KeyboardEvent | Event) => {
                      if (seekingTime) {
                          setCurrentTime?.(seekingTime - SEEK_ADJUSTMENT_S);
                      }

                      onPlayerInteractionByType?.(
                          ANALYTICS_EVENT_NAME.SELECT_OPTION,
                          `-${SEEK_ADJUSTMENT_S}`
                      );
                      handleInteraction?.(
                          e instanceof Event ? e : e.nativeEvent
                      );
                  },
        }),
        [
            isInAdBreak,
            seekingTime,
            handleInteraction,
            onPlayerInteractionByType,
            setCurrentTime,
        ]
    );

    const mediaKeysAndActions = useMemo(
        () => ({
            ...onSkipArrowKeyDownAction,
            ArrowUp: () => stepVolume?.(0.1),
            ArrowDown: () => stepVolume?.(-0.1),
            [spaceKey]: () => togglePlayPause?.(),
        }),
        [onSkipArrowKeyDownAction, stepVolume, togglePlayPause]
    );

    const onSkipArrowKeyDownActionRef = useRef(onSkipArrowKeyDownAction);
    const mediaKeysAndActionsRef = useRef(mediaKeysAndActions);

    useEffect(
        function syncOnSkipArrowKeyDownActionRef() {
            onSkipArrowKeyDownActionRef.current = onSkipArrowKeyDownAction;
        },
        [onSkipArrowKeyDownAction]
    );

    useEffect(
        function syncMediaKeysAndActionsRef() {
            mediaKeysAndActionsRef.current = mediaKeysAndActions;
        },
        [mediaKeysAndActions]
    );

    const onSkipArrowKeyDown = getKeyDownHandler(onSkipArrowKeyDownActionRef);
    const onLowerControlsKeyDown = getKeyDownHandler(mediaKeysAndActionsRef);

    const scrubber = (
        <IM02Scrubber
            isPlayingAd={isInAdBreak}
            bufferedTimeRanges={bufferedTimeRanges}
            currentTime={isInAdBreak ? adCurrentTime : currentTime}
            duration={isInAdBreak ? adDuration : duration}
            seekingTime={seekingTime}
            isSeeking={isSeeking}
            isSeekingTooltipEnabled={!hasThumbnails}
            onChange={setCurrentTime}
            isUserHoldingControls={isUserHoldingControls}
            getOnMouseDownUp={getOnMouseDownUp}
            onKeyDown={onSkipArrowKeyDown}
            onChangeHover={(hoverState) =>
                void playerState?.setCurrentHoverPosition(hoverState)
            }
            tabIndex={isLowerControlsVisible ? 0 : -1}
            keyMoments={keyMoments}
            isKeyMomentsVisible={!isUserHoldingControls && isKeyMomentsVisible}
            shouldUseSlowReveal={!!keyEvent?.shouldUseSlowReveal}
            latestSecondsViewed={latestSecondsViewed}
            isLockedToEdge={shouldLockScrubberToEdge && !isInAdBreak}
        />
    );

    useEffect(() => {}, [isHudVisible]); // eslint-disable-line @typescript-eslint/no-empty-function, no-empty-function

    return (
        <StyledDiv
            isThumbnailCarouselVisible={isThumbnailCarouselVisible}
            isVisible={isLowerControlsVisible}
            isInAdBreak={isInAdBreak}
        >
            <SplitViewLayoutSelector />
            {!isInAdBreak &&
                Array.isArray(playbackData?.markers) &&
                !!playbackData?.markers?.length && (
                    <StyledScrubberAndButtonRowContainer>
                        <MarkerButtonRow />
                    </StyledScrubberAndButtonRowContainer>
                )}
            <PositionedOR01LowerControls
                hoverThumbnail={hoverThumbnail}
                toggleKeyMomentsButton={toggleKeyMomentsButton}
                displayTime={displayTime}
                fullscreenButton={fullscreenButton}
                goToLiveButton={goToLiveButton}
                isSeeking={isSeeking}
                isInAdBreak={isInAdBreak}
                isVisible={isLowerControlsVisible}
                playbackSpeedButton={playbackSpeedButton}
                playButton={playButton}
                popOutPlayerButton={popOutPlayerButton}
                relatedVideosButton={relatedVideosButton}
                scrubber={scrubber}
                shouldLowerControlsOpacity={shouldLowerControlsOpacity}
                skipBackButton={skipBackButton}
                skipForwardButton={skipForwardButton}
                thumbnailCarousel={thumbnailCarousel}
                volumeSlider={volumeSlider}
                onKeyDown={onLowerControlsKeyDown}
                nextButton={nextEpisodeButton}
                moveDownBy="var(--smwplayer-underlay-height)" // animate the controls out in sync with the gradient underlay
            />
        </StyledDiv>
    );
};

LowerControls.displayName = 'LowerControls';

export default observer(LowerControls, {forwardRef: true});
