import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC24ArrowL = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M39.542 78.276L0 39.138 39.542 0 45.109 5.509 11.133 39.138 45.109 72.766z"
            transform="translate(25 11)"
        />
    </Ic>
);

IC24ArrowL.displayName = 'IC24ArrowL';

export default IC24ArrowL;
