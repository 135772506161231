import bacon from 'baconjs';
import get from 'lodash/get';
import find from 'lodash/find';

import {setLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';

import {USER_LOCAL_STORAGE_KEY, LOCAL_STORAGE_EXPIRES_IN} from '../../utils/constants';

export default function (user) {
    if (!user) {
        return bacon.later(0, false);
    }

    const userProperties = get(user, 'properties', []);
    const globalUser = find(userProperties, {name: 'globalUser'});
    const glob = get(globalUser, 'value');

    const userDetails = {
        email: user.email,
        fullName: `${user.firstName} ${user.lastName}`,
        id: user.id
    };

    setLocalStorageValue({
        key: USER_LOCAL_STORAGE_KEY,
        value: glob ? Object.assign({}, userDetails, {glob}) : userDetails,
        expiresIn: LOCAL_STORAGE_EXPIRES_IN
    });

    return bacon.later(0, true);
}
