import {css} from 'styled-components';

import type {BaseProps, CssStrings, CssInterpolations} from './util/types';

type Args = {
    minWidthPx?: number;
    minHeightPx?: number;
    orientation?: 'portrait' | 'landscape';
};

/**
 * Convenience wrapper for `@media` media query. Supports nesting.
 *
 * @example Simple
 * ```
 * const MyStyledDiv = styled.div`
 *     ${mediaQuery({minWidth: 415})`
 *         color: red;
 *     `}
 * `;
 * ```
 *
 * @example Nested
 * ```
 * const MyStyledDiv = styled.div`
 *    ${mediaQuery({minWidth: 415})`
 *        color: red;
 *    `}
 *
 *    ${mediaQuery({minWidth: 768, orientation: 'landscape'})`
 *        font-weight: bold;
 *
 *        ${mediaQuery({minWidth: 1024})`
 *            text-dectoration: underline;
 *        `}
 *     `}
 * `;
 * ```
 *
 * @example Nested interpolations that require prop types
 *
 * ```
 * type Props = {
 *     wideColor: string;
 * }
 *
 * const MyStyledDiv = styled.div<Props>`
 *     ${mediaQuery({minWidth: 415})`
 *         color: ${({wideColor}) => wideColor};
 *     `}
 * `;
 * ```
 *
 * @typeParam P - React component props, optional if you don't have nested CSS interpolations
 * @param  minWidthPx    - `min-width` value (in pixels)
 * @param  minHeightPx   - `min-height` value (in pixels)
 * @param  orientation   - `orientation` value
 * @returns `styled-components` interpolation function
 */
export default function mediaQuery<P extends BaseProps = BaseProps>({
    minWidthPx,
    minHeightPx,
    orientation,
}: Args) {
    return (
        cssStrings: CssStrings<P>,
        ...cssInterpolations: CssInterpolations<P>
    ) => {
        const mediaQuery = [
            typeof minWidthPx !== 'undefined' && `(min-width: ${minWidthPx}px)`,
            typeof minHeightPx !== 'undefined' &&
                `(min-height: ${minHeightPx}px)`,
            typeof orientation !== 'undefined' &&
                `(orientation: ${orientation})`,
        ]
            .filter(Boolean)
            .join(' and ');

        return mediaQuery
            ? css`
                  @media ${mediaQuery} {
                      ${css<P>(cssStrings, ...cssInterpolations)}
                  }
              `
            : '';
    };
}
