import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';

import CountdownTimer from '@fsa-streamotion/streamotion-web-fs-ui/src/components/countdown-timer/countdown-timer';
import {getTimeFromNow} from '@fsa-streamotion/streamotion-web-fs-ui/src/js/utils/datetime';
import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

import AddToCalendar from './add-to-calendar';

export default class TimerReminder extends React.Component {
    constructor(props) {
        super(props);
        this.hideAddToCalendar = this.hideAddToCalendar.bind(this);
        this.state = {
            addToCalendar: false,
            countdownWarning: false,
            preMatchReminderDisabled: false,
            showPreMatchAddToCalendar: false,
            showMatchAddToCalendar: false
        };
    }

    componentDidMount() {
        if (this.props.preMatchTime) {
            this.countdownInterval = setInterval(() => {
                this.tick(this.props.preMatchTime);
            }, 1000);
        } else if (this.props.matchTime) {
            this.countdownInterval = setInterval(() => {
                this.tick(this.props.matchTime, 3600);
            }, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.countdownInterval);
    }

    tick(startTime, warningSeconds) {
        const secondsRemaining = getTimeFromNow(startTime);

        if (secondsRemaining <= 0 || secondsRemaining <= warningSeconds) {
            clearInterval(this.countdownInterval);
            this.preMatchReminderTimerEnd();
        }
    }

    preMatchReminderTimerEnd() {
        this.setState({
            preMatchReminderDisabled: true,
            countdownWarning: true
        });
    }

    showAddToCalendarPreMatch() {
        if (!this.state.showPreMatchAddToCalendar) {
            this.showAddToCalendar('showPreMatchAddToCalendar');
        }
    }

    showAddToCalendarMatch() {
        if (!this.state.showMatchAddToCalendar) {
            this.showAddToCalendar('showMatchAddToCalendar');
        }
    }

    hideAddToCalendar() {
        const newState = {
            showPreMatchAddToCalendar: false,
            showMatchAddToCalendar: false
        };

        this.setState(newState, () => {
            document.removeEventListener('click', this.hideAddToCalendar);
        });
    }

    showAddToCalendar(key) {
        const newState = {
            [key]: true
        };

        if (!this.state.addToCalendar) {
            newState.addToCalendar = true;
        }

        this.setState(newState, () => {
            document.addEventListener('click', this.hideAddToCalendar);
        });
    }

    renderCoundownTimer() {
        if (getTimeFromNow(this.props.matchTime) < 0) {
            return null;
        }

        const countdownTimerClassName = classNames(
            'fiso-hawk-timer-reminder__timer',
            {
                'fiso-hawk-timer-reminder__timer--warning': this.state.countdownWarning
            }
        );

        return (
            <div className={countdownTimerClassName}>
                <CountdownTimer
                    separator=":"
                    endTime={this.props.matchTime}
                    onTimerEnd={this.props.onTimerEnd} />
            </div>
        );
    }

    renderAddToCalendarMatch() {
        if (!this.props.matchTime) {
            return null;
        }

        return (
            <AddToCalendar {...this.props.matchReminder} init={this.state.addToCalendar} />
        );
    }

    renderAddToCalendarPreMatch() {
        if (!this.props.preMatchTime) {
            return null;
        }

        return (
            <AddToCalendar {...this.props.preMatchReminder} init={this.state.addToCalendar} />
        );
    }

    render() {
        if (!this.props.matchTime || getTimeFromNow(this.props.matchTime) < 0) {
            return null;
        }

        const preMatchReminderDisabled = !this.props.preMatchTime || this.state.preMatchReminderDisabled;
        const timerReminderClassName = classNames(
            'fiso-hawk-timer-reminder',
            {
                'fiso-hawk-timer-reminder--wide': this.props.hasWideButton
            }
        );
        const preMatchReminderButtonclassName = classNames(
            'fiso-hawk-timer-reminder__reminder-button',
            {
                'fiso-hawk-timer-reminder__reminder-button--disabled': preMatchReminderDisabled,
                'fiso-hawk-timer-reminder__reminder-button--show': this.state.showPreMatchAddToCalendar
            }
        );
        const matchReminderButtonclassName = classNames(
            'fiso-hawk-timer-reminder__reminder-button',
            {'fiso-hawk-timer-reminder__reminder-button--show': this.state.showMatchAddToCalendar}
        );

        return (
            <div className={timerReminderClassName}>
                {this.renderCoundownTimer()}
                <div className="fiso-hawk-timer-reminder__reminder-container">
                    <div className="fiso-hawk-timer-reminder__reminder">
                        <button
                            disabled={preMatchReminderDisabled}
                            className={preMatchReminderButtonclassName}
                            onClick={this.showAddToCalendarPreMatch.bind(this)}>
                            <span className="fiso-hawk-timer-reminder__reminder-button-content">
                                <span className="fiso-hawk-timer-reminder__reminder-button-svg">
                                    <Fsvg name="clock" />
                                </span>
                                <span className="fiso-hawk-timer-reminder__reminder-button-text">
                                    <h2 className="fiso-hawk-timer-reminder__reminder-button-heading">
                                        Set Pre Match Reminder
                                    </h2>
                                    <span className="fiso-hawk-timer-reminder__reminder-button-description">
                                        Pre game coverage
                                    </span>
                                </span>
                            </span>
                        </button>
                        {this.renderAddToCalendarPreMatch()}
                    </div>
                    <div className="fiso-hawk-timer-reminder__reminder">
                        <button
                            className={matchReminderButtonclassName}
                            onClick={this.showAddToCalendarMatch.bind(this)}>
                            <span className="fiso-hawk-timer-reminder__reminder-button-content">
                                <span className="fiso-hawk-timer-reminder__reminder-button-svg">
                                    <Fsvg name="clock" />
                                </span>
                                <span className="fiso-hawk-timer-reminder__reminder-button-text">
                                    <h2 className="fiso-hawk-timer-reminder__reminder-button-heading">
                                        Set Match Reminder
                                    </h2>
                                    <span className="fiso-hawk-timer-reminder__reminder-button-description">
                                        5 minutes before start
                                    </span>
                                </span>
                            </span>
                        </button>
                        {this.renderAddToCalendarMatch()}
                    </div>
                </div>
            </div>
        );
    }
}

TimerReminder.defaultProps = {
    onTimerEnd: noop
};

TimerReminder.propTypes = {
    hasWideButton: propTypes.bool,
    matchReminder: propTypes.object,
    matchTime: propTypes.string,
    preMatchReminder: propTypes.object,
    preMatchTime: propTypes.string,

    onTimerEnd: propTypes.func
};
