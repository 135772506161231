import {getPlatformName, getBrowserType} from '@fsa-streamotion/browser-utils';
import {
    loadAndMergeConfigs,
    getConfigUrls,
} from '@fsa-streamotion/merged-config';
import type {DashjsConfig, HlsjsConfig} from '@fsa-streamotion/player-tech';

const HLSJS_CONFIG_BASE_URL = {
    production:
        'https://resources.streamotion.com.au/production/common/video/hlsjs',
    staging: 'https://resources.streamotion.com.au/staging/common/video/hlsjs',
};

const DASHJS_CONFIG_BASE_URL = {
    production:
        'https://resources.streamotion.com.au/production/common/video/dashjs',
    staging: 'https://resources.streamotion.com.au/staging/common/video/dashjs',
};

type LoadConfigParamType = {
    baseUrl: string;
    productCode: string;
    prefix?: string;
};

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Record<string, unknown>
        ? DeepPartial<T[P]>
        : T[P];
};

/**
 * Use configTokens to load configuration files and merge them
 * into a single config object.
 *
 * @see {@link loadAndMergeConfigs} for further information
 *
 * @param params - Load config params
 * @returns The merged configs
 */
function loadConfig<T>({
    productCode,
    baseUrl,
    prefix,
}: LoadConfigParamType): Promise<DeepPartial<T>> {
    const extension = 'json';
    const tokens = [prefix, getPlatformName(), getBrowserType()].filter(
        Boolean
    ) as string[]; // filter in case
    const configUrls = getConfigUrls({baseUrl, tokens, extension});

    // eslint-disable-next-line no-console
    console.debug(
        `[${productCode}] Loading and merging configs from:\n${configUrls.map(
            (url) => `\n - ${url}`
        )}`
    );

    return loadAndMergeConfigs({
        baseUrl,
        tokens,
        extension,
    });
}

type Param = {
    resourcesEnv: 'production' | 'staging';
    productCode: string;
};
/**
 *
 * @param param - Params for get dash js config
 * @returns The function to get dash js config
 */

export const dashJsConfigGetter = (
    param: Param
): (() => Promise<DashjsConfig>) => {
    const {resourcesEnv, productCode} = param;

    return () =>
        loadConfig<DashjsConfig>({
            baseUrl: DASHJS_CONFIG_BASE_URL[resourcesEnv],
            prefix: 'dashjs',
            productCode,
        });
};

/**
 *
 * @param param - Params for get hls js config
 * @returns The function to get hls js config
 */
export const hlsJsConfigGetter = (
    param: Param
): (() => Promise<HlsjsConfig>) => {
    const {resourcesEnv, productCode} = param;

    return () =>
        loadConfig<HlsjsConfig>({
            baseUrl: HLSJS_CONFIG_BASE_URL[resourcesEnv],
            prefix: 'hlsjs',
            productCode,
        });
};
