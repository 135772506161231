import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import {silverLining} from '../../../../common/palette';
import {THEME_ACCESSORS} from '../../../../common/theme-helpers';

const StyledButton = styled.button`
    appearance: none;
    position: relative;
    transition: ${transition('background-color', 'color', 'opacity')};
    border: none;
    background: transparent;
    padding: 14px;
    width: 70px;
    height: 70px;
    color: ${silverLining};

    &[aria-selected='false'] {
        opacity: 0.3;
    }

    &[aria-selected='true']::before {
        background-color: ${silverLining};
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(14px);
        transition: ${transition('height')};
        border-radius: 1px;
        background-color: transparent;
        width: 3px;
        height: 42px;
        content: '';
    }

    /* stylelint-disable-next-line order/order */
    &:hover,
    &:focus {
        opacity: 1;
        outline: 0;
        background: ${THEME_ACCESSORS.brandColor};
        color: ${THEME_ACCESSORS.brandForegroundColor};
    }

    &[aria-selected='true']:hover::before,
    &[aria-selected='true']:focus::before {
        background-color: ${THEME_ACCESSORS.brandForegroundColor};
    }
`;

export type Props = {
    children?: React.ReactNode;
    isSelected?: boolean;
    onClick?: React.MouseEventHandler;
    tabIndex?: number;
    id?: string;
    onFocus?: React.FocusEventHandler<HTMLButtonElement>;
};

const IGM28HudBtn = React.forwardRef<HTMLButtonElement, Props>(
    ({children, isSelected, ...htmlAttributes}, ref) => (
        <StyledButton
            aria-selected={!!isSelected}
            {...htmlAttributes}
            ref={ref}
        >
            {children}
        </StyledButton>
    )
);

IGM28HudBtn.displayName = 'IGM28HudBtn';

export default IGM28HudBtn;
