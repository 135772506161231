/* eslint-disable import/prefer-default-export */
import {isServer} from './environment';

type TestWindowWidthArgs = {
    minPx?: number;
    maxPx?: number;
};

/**
 * Function wrapping matchMedia to allow for responsive javascript
 *
 * @param minPx   - min pixel width to test window against
 * @param maxPx   - max pixel width to test window against
 * @returns whether the window's width matches the media query
 */
export function testWindowWidth({minPx, maxPx}: TestWindowWidthArgs): boolean {
    if (isServer()) {
        return false;
    }

    const queryMin = minPx && `min-width: ${minPx}px`;
    const queryMax = maxPx !== undefined && `max-width: ${maxPx - 0.02}px`;
    const query = [queryMin, queryMax].filter(Boolean).join(' and ');

    return !query || window.matchMedia(`(${query})`).matches;
}
