export default class VisualonAdapter {
  getVersion() {
    return '7.0.2-visualon-analytics-js'
  }

  getPlayhead() {
    return this.player.getPosition();
  }

  getFramesPerSecond() {
    const analyticsInfo = this.player.getAnalyticsInfo();
    if (analyticsInfo && analyticsInfo.streamingFps) {
      try {
        return parseInt(analyticsInfo.streamingFps, 10);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  }

  getDroppedFrames() {
    const analyticsInfo = this.player.getAnalyticsInfo();
    if (analyticsInfo && analyticsInfo.droppedFrames) {
      return analyticsInfo.droppedFrames;
    }
    return 0;
  }

  getDuration() {
    return this.player.getDuration();
  }

  getBitrate() {
    return this.targetBitrate;
  }

  getTotalBytes() {
    var analyticsInfo = this.player.getAnalyticsInfo();
    if (analyticsInfo && analyticsInfo.downloadBytes) {
      return analyticsInfo.downloadBytes;
    }
  }

  getRendition() {
    return this.getNpawUtils().buildRenditionString(this.width, this.height, this.targetBitrate);
  }

  getIsLive() {
    return this.player.isLive();
  }

  getResource() {
    var analyticsInfo = this.player.getAnalyticsInfo();
    if (analyticsInfo && analyticsInfo.videoLinkURL) {
      return analyticsInfo.videoLinkURL;
    }
    return null;
  }

  getPlayerVersion() {
    return this.player.getVersion();
  }

  getPlayerName() {
    return 'VisualON';
  }

  registerListeners() {
    if (typeof voPlayer !== 'undefined' && this.player) {
      this.references = {};
      this.references[voPlayer.events.VO_OSMP_SRC_CB_OPEN_FINISHED] = this.openListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_PLAY_STARTED] = this.playListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_PLAY_PLAYING] = this.playingListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_PLAY_WAITING] = this.bufferListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_PLAY_PAUSED] = this.pauseListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_PLAY_COMPLETE] = this.endListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_SEEK_START] = this.seekListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_SEEK_COMPLETE] = this.seekEndListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_PLAY_TIME_UPDATED] = this.timeUpdatedListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_CB_ERROR_EVENTS] = this.errorListener.bind(this);
      this.references[voPlayer.events.VO_OSMP_SRC_ADAPTIVE_STREAMING_INFO_EVENT_BITRATE_CHANGE] = this.bitrateChangeListener.bind(this);
      for (var key in this.references) {
        this.player.addEventListener(key, this.references[key]);
      }
    }
    this._reset();
  }

  unregisterListeners() {
    if (typeof voPlayer !== 'undefined' && this.player && this.references) {
      for (let key in this.references) {
        this.player.off(key, this.references[key]);
      }
      delete this.references;
    }
  }

  bitrateChangeListener(e) {
    if (e.mediaType && e.mediaType === 'video') {
      this.targetBitrate = e.bandwidth;
      this.width = e.width;
      this.height = e.height;
    }
  }

  openListener(e) {
    this.fireStop({}, 'openListener');
    this._reset();
  }

  timeUpdatedListener(e) {

  }

  playListener(e) {
    this.fireStart({}, 'playListener');
  }

  pauseListener(e) {
    this.firePause({}, 'pauseListener');
  }

  playingListener(e) {
    this.fireStart({}, 'playingListener');
    this.fireJoin({}, 'playingListener');
    this.fireResume({}, 'playingListener');
    this.fireSeekEnd({}, 'playingListener');
    this.fireBufferEnd({}, 'playingListener');
  }

  seekListener(e) {
    this.fireSeekBegin({}, false, 'seekListener');
  }

  seekEndListener(e) {
    this.fireSeekEnd({}, 'seekEndListener');
  }

  endListener(e) {
    this.fireStop({}, 'endListener');
    this._reset();
  }

  bufferListener(e) {
    this.fireBufferBegin({}, false, 'bufferListener');
  }

  errorListener(e) {
    this.fireError(e.code, e.message, undefined, undefined, 'errorListener');
  }

  _reset() {
    this.width = null;
    this.height = null;
    this.targetBitrate = null;
  }
}