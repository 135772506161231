export default class GenericAdsAdapter {
  initProperties() {
    this.isGeneric = true;
  }

  getVersion() {
    return '7.0.4-videojs-ads-jsclass'
  }

  registerListeners() {
    this.references = {
      adstart: this.adStartListener.bind(this),
      adend: this.adEndListener.bind(this),
      adskip: this.adSkipListener.bind(this),
      adserror: this.errorListener.bind(this),
      'ads-click': this.clickListener.bind(this),
      'ima3-first-quartile': this.firstQuartileListener.bind(this),
      'ima3-midpoint': this.secondQuartileListener.bind(this),
      'ima3-third-quartile': this.thirdQuartileListener.bind(this),
      'ads-first-quartile': this.firstQuartileListener.bind(this),
      'ads-midpoint': this.secondQuartileListener.bind(this),
      'ads-third-quartile': this.thirdQuartileListener.bind(this)
    };
    if (this.player) {
      for (var key in this.references) {
        this.player.on(key, this.references[key]);
      }
    }
  }

  firstQuartileListener(e) {
    this.fireQuartile(1);
  }

  secondQuartileListener(e) {
    this.fireQuartile(2);
  }

  thirdQuartileListener(e) {
    this.fireQuartile(3);
  }

  unregisterListeners() {
    if (this.player && this.references) {
      for (var key in this.references) {
        this.player.off(key, this.references[key]);
      }
      delete this.references;
    }
  }

  adStartListener(e) {
    this.firePlayerLog('adStartListener', {});
    this.fireStart();
    this.fireJoin();
  }

  adEndListener(e) {
    this.firePlayerLog('adEndListener', {});
    this.fireStop();
  }

  adSkipListener(e) {
    this.firePlayerLog('adSkipListener', {});
    this.fireSkip();
  }

  errorListener(e) {
    this.firePlayerLog('adErrorListener', {});
    this.fireError();
  }

  clickListener(e) {
    this.firePlayerLog('adClickListener', {});
    this.fireClick();
  }
}