import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC266ClseCapOff = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M60.305 12L39.463 33.697l1.394-3.738c-.774-.757-3.632-3.61-10.896-3.61-7.74 0-15.242 5.59-15.242 14.85 0 5.389 2.492 9.44 6.135 11.87L5.55 69H0V12h60.305zM86 12v57H27.139l18.49-19.536c2.785 4.032 7.605 6.235 12.672 6.235 7.68 0 10.658-3.26 11.253-3.785l-2.143-5.474s-2.739 2.97-7.68 2.97c-4.11 0-7.313-2.335-8.276-6.102l9.99-10.555c3.164.527 5.047 2.122 5.787 2.797l2.084-5.59c-.4-.391-1.352-1.339-3.178-2.165L81.085 12H86zM30.854 32.58c3.71 0 6.106 1.488 7.322 2.459L25.948 47.768c-2.013-1.483-3.25-3.893-3.25-6.919 0-5.183 3.631-8.269 8.156-8.269z"
            transform="translate(7 8) translate(0 2)"
        />
        <path
            fillRule="nonzero"
            d="M6.875 0.066L81.923 79.17 78.932 82 4 3.019z"
            transform="translate(7 8) translate(0 2) matrix(-1 0 0 1 85.923 0)"
        />
    </Ic>
);

IC266ClseCapOff.displayName = 'IC266ClseCapOff';

export default IC266ClseCapOff;
