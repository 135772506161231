import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;
const IC10Mltv = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path d="M79.2.2H39.7c-1.5 0-2.8 1.2-2.8 2.8v27.8c0 1.5 1.2 2.8 2.8 2.8h39.5c1.5 0 2.8-1.2 2.8-2.8V2.9C82 1.4 80.7.2 79.2.2zm-2.8 27.7h-34V5.7h34v22.2zM44 48.4H1.4c-.8 0-1.4.6-1.4 1.4v30.7c0 .8.6 1.4 1.4 1.4H44c.8 0 1.4-.6 1.4-1.4V49.8c0-.8-.7-1.4-1.4-1.4zm-1.4 30.7H2.8V51.2h39.8v27.9zM1.9 40.8h5.6c.1-11.4 5.8-21.4 15-27.1L20.9 23l4.1.7 2.6-14.9c.1-.5 0-1.1-.3-1.6s-.8-.8-1.3-.9L11 3.9 10.2 8l8.6 1.5C8.5 16.3 2 28 1.9 40.8zm72.7-.2c-.1 11.4-5.8 21.4-15 27.1l1.6-9.3-4.1-.7-2.6 14.9c-.2 1.1.6 2.2 1.7 2.4l14.9 2.6.7-4.1-8.6-1.5c10.4-6.9 16.9-18.6 17-31.4h-5.6z" />
    </Ic>
);

IC10Mltv.displayName = 'IC10Mltv';

export default IC10Mltv;
