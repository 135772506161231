import {stylesWhenNot} from '@fsa-streamotion/styled-component-helpers';

import {observer} from 'mobx-react-lite';
import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';

import {usePlayerContext} from '../../context';

const StillWatchingContainer = styled.div`
    width: 100%;
    height: 100%;
    pointer-events: auto;

    ${stylesWhenNot('isStillWatchingMode')`
        display: none;
    `}
`;

const StillWatching = (): React.ReactElement => {
    const playerState = usePlayerContext();
    const stillWatchingRef = useRef<HTMLDivElement>(null);
    const {globalState, globalActions} = playerState ?? {};
    const {isStillWatchingMode} = globalState ?? {};
    const {clearStillWatching, pushStillWatchingElement} = globalActions ?? {};

    // To prevent any user interactions triggering other UIs
    const stopPropagation = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(function pushStillWatchingElementOnMount() {
        pushStillWatchingElement?.(stillWatchingRef?.current);

        return () => {
            pushStillWatchingElement?.(null);
            clearStillWatching?.();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StillWatchingContainer
            onClick={stopPropagation}
            onMouseMove={stopPropagation}
            isStillWatchingMode={isStillWatchingMode}
            ref={stillWatchingRef}
        />
    );
};

StillWatching.displayName = 'StillWatching';

export default observer(StillWatching);
