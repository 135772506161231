import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

// we will mostly deal with these two series
const sportSeriesIdMap = {
    afl: {
        1: 'AFL'
    },
    league: {
        1: 'NRL'
    }
};

const PLURAL_RULES = new Intl.PluralRules('en-AU', {type: 'ordinal'});

const PLURAL_ORDINALS = {one: 'st', two: 'nd', few: 'rd', other: 'th'};

function ordinalise(number) {
    return `${number}${PLURAL_ORDINALS[PLURAL_RULES.select(number)]}`;
}

export default class FixtureList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.renderTableLine = this.renderTableLine.bind(this);
    }

    renderTableLine(item, index) {
        const {seriesId, sportDetails} = this.props;
        const sport = sportDetails.apiName;
        const numberOfTeamsInFirstGroup = 8;
        const rowClass = classNames(
            'fiso-hawk-ladder__row',
            {'fiso-hawk-ladder__row--first-in-group': index < numberOfTeamsInFirstGroup},
            {'fiso-hawk-ladder__row--first-in-group-first-child': index === 0},
            {'fiso-hawk-ladder__row--first-in-group-last-child': index === numberOfTeamsInFirstGroup - 1}
        );

        const rankedOn = ordinalise(item.stats.position);

        return (
            <tr
                key={item.id}
                className={rowClass}
                itemType="http://schema.org/SportsTeam"
                aria-label={`${item.name}, ${rankedOn} on ${sportSeriesIdMap[sport][seriesId]} ladder with ${item.stats.points} points`}
            >
                <td
                    className="fiso-hawk-ladder__rank"
                    aria-label={`Rank position: ${item.stats.position}`}>
                    {item.stats.position}
                </td>
                <td className="fiso-hawk-ladder__team-flag" role="presentation">
                    <div itemProp="logo" className={`fiso-commons-logos--${sport}-1-80x60 fiso-commons-logos--${sport}-1-80x60-team-${item.id}`} />
                </td>
                <td className="fiso-hawk-ladder__team-name">
                    <abbr title={item.name} data-shortname={item.short_name} className="fiso-hawk-ladder__team-abbr"><span>{item.code}</span></abbr>
                </td>
                <td className="fiso-hawk-ladder__played" aria-label={`Played ${item.stats.played} times`}>{item.stats.played}</td>
                <td className="fiso-hawk-ladder__won" aria-label={`Won ${item.stats.won} times`}>{item.stats.won}</td>
                <td className="fiso-hawk-ladder__lost" aria-label={`Lost ${item.stats.lost} times`}>{item.stats.lost}</td>
                <td className="fiso-hawk-ladder__drawn" aria-label={`Drawn ${item.stats.drawn} times`}>{item.stats.drawn}</td>
                <td className="fiso-hawk-ladder__diff" aria-label={`${item.stats.difference} point${item.stats.difference === 1 ? '' : 's'} difference`}>{item.stats.difference}</td>
                <td className="fiso-hawk-ladder__for" aria-label={`For ${item.stats.for}`}>{item.stats.for}</td>
                <td className="fiso-hawk-ladder__against" aria-label={`Against ${item.stats.against}`}>{item.stats.against}</td>
                <td className="fiso-hawk-ladder__percentage" aria-label={`${item.stats.percentage}%`}>{item.stats.percentage}</td>
                <td className="fiso-hawk-ladder__points" aria-label={`${item.stats.points} points`}>{item.stats.points}</td>
            </tr>
        );
    }

    render() {
        const captionClasses = classNames(
            'fiso-hawk-ladder__table-caption',
            {'fiso-hawk-ladder__table-caption--hidden': this.props.hideTableCaption}
        );
        const {seriesId, sportDetails} = this.props;
        const sport = sportDetails.apiName;
        const captionText = `${sportSeriesIdMap[sport][seriesId]} Ladder`;

        return (
            <section
                className="fsui-normalise fiso-hawk-ladder"
                itemScope={true}
                itemType="http://schema.org/Table">

                <link
                    rel="stylesheet"
                    href={'/assets/images/team-logos/' +
                        `${sport}/${seriesId}/80x60/${seriesId}.css`} />

                <meta itemProp="dateModified" content={this.props.lastUpdated} />

                <table className="fiso-hawk-ladder__table">
                    <caption className={captionClasses}>{captionText}</caption>
                    <thead>
                        <tr
                            className={classNames(
                                'fiso-hawk-ladder__row',
                                `fiso-hawk-color-secondary--${sportDetails.route}`)}
                        >
                            <th className="fiso-hawk-ladder__rank">Rank</th>
                            <td className="fiso-hawk-ladder__insignia" role="presentation" />
                            <th className="fiso-hawk-ladder__team-name">Team</th>
                            <th className="fiso-hawk-ladder__played"><abbr title="Played">p</abbr></th>
                            <th className="fiso-hawk-ladder__won"><abbr title="Won">w</abbr></th>
                            <th className="fiso-hawk-ladder__lost"><abbr title="Lost">l</abbr></th>
                            <th className="fiso-hawk-ladder__drawn"><abbr title="Drawn">d</abbr></th>
                            <th className="fiso-hawk-ladder__diff"><abbr title="Points Difference">pd</abbr></th>
                            <th className="fiso-hawk-ladder__for"><abbr title="For">f</abbr></th>
                            <th className="fiso-hawk-ladder__against"><abbr title="Against">a</abbr></th>
                            <th className="fiso-hawk-ladder__percentage"><abbr title="Percentage">%</abbr></th>
                            <th className="fiso-hawk-ladder__points"><abbr title="Points">pts</abbr></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.ladderData.teams.map(this.renderTableLine)}
                    </tbody>
                </table>

            </section>
        );
    }
}

FixtureList.defaultProps = {
    hideTableCaption: true,
    lastUpdated: 'N/A'
};

FixtureList.propTypes = {
    hideTableCaption: propTypes.bool,

    ladderData: propTypes.shape({
        teams: propTypes.arrayOf(
            propTypes.shape({
                id: propTypes.number,
                name: propTypes.string,
                code: propTypes.string,
                stats: propTypes.shape({
                    against: propTypes.number,
                    drawn: propTypes.number,
                    lost: propTypes.number,
                    played: propTypes.number,
                    points: propTypes.number,
                    position: propTypes.number,
                    won: propTypes.number,
                    percentage: propTypes.number,
                    for: propTypes.number
                }),
                short_name: propTypes.string
            })
        )
    }),

    lastUpdated: propTypes.string,

    seriesId:  propTypes.number,

    sportDetails: propTypes.shape({
        route: propTypes.string
    })
};
