import bacon from 'baconjs';

/**
 * Polls a given stream until we determine we should no longer poll.
 *
 * @param  {Observable}  options.streamToPoll       Required: Stream to poll for data
 * @param  {Callback}    options.pollWhileCallback  Required: Callback to hit if we should stop polling
 * @param  {Mixed}       options.initialData        falsey no initial data, otherwise truey for initial start data
 * @param  {Number}      options.delayMs            If initialData provided, how long to wait before first poll
 *
 * @return {EventStream}                            EventStream with poll-stop logic applied.
 */
export default function pollUntil({
    streamToPoll,
    pollWhileCallback,

    initialData = false,
    delayMs = 30 * 1000
}) {
    if (delayMs > 24 * 60 * 60 * 1000) {
        console.error('FSCommons - pollUnit: Large delays not supported! Callbacks just happen immediately!');
    }

    const pollingStream = bacon
        .later(0, initialData) // Using later, as once still only seen by first subscriber
        .flatMapLatest((initialStreamData) => {
            if (initialStreamData) {
                return bacon
                    .later(delayMs, null)
                    .skip(1)
                    .concat(streamToPoll)
                    .startWith(initialStreamData);
            } else {
                return streamToPoll;
            }
        });

    return pollingStream
        .withStateMachine(null, (internalState, event) => {
            if (event.hasValue() && !pollWhileCallback(event.value())) {
                return [null, [event, new bacon.End()]];
            } else {
                // Normal event + not to end yet. Just pass it on through.
                return [null, [event]];
            }
        });
}
