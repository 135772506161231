import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classnames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';

const VideoErrorScreen = ({
    isOpen,
    player,
    onClickClose = noop
}) => {
    const classesComponent = classnames(
        'fsui-normalise fiso-hawk-video-modal',
        {'fiso-hawk-video-modal--showing': isOpen}
    );

    return (
        <div className={classesComponent}>
            <div className="fiso-hawk-video-modal__container">
                <div className="fiso-hawk-video-modal__close">
                    <button
                        aria-label="close"
                        className="fiso-hawk-video-modal__close-button"
                        onClick={onClickClose}>
                        <Fsvg name="cross" />
                    </button>
                </div>

                <div className="fiso-hawk-video-modal__player">
                    {player}
                </div>
            </div>
        </div>
    );
};

VideoErrorScreen.propTypes = {
    isOpen: propTypes.bool,

    player: propTypes.node,

    onClickClose: propTypes.func
};

export default VideoErrorScreen;
