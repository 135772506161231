import React from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import LoadingSpinner from '@fsa-streamotion/streamotion-web-fs-ui/src/components/loading-spinner/loading-spinner';

export default class ForgotPassword extends React.Component {
    handleSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.emailAddress.value);
    }

    renderLoadingSpinner() {
        if (!this.props.isLoading) {
            return null;
        }

        return <LoadingSpinner />;
    }

    renderSuccess() {
        if (!this.props.isSuccess) {
            return null;
        }

        return (
            <p className="fiso-hawk-forgot-password__text-info fiso-hawk-forgot-password__text-info--center">
                An email has been sent to your registered email address with information of how to reset your password.
            </p>
        );
    }

    renderError() {
        if (!this.props.error) {
            return null;
        }

        return (
            <p
                className="fiso-hawk-forgot-password__text-info fiso-hawk-forgot-password__text-info--error">
                Please use correct email address
            </p>
        );
    }

    renderForm() {
        if (this.props.isSuccess) {
            return null;
        }

        const buttonClass = classNames(
            'fiso-hawk-button',
            'fiso-hawk-button--primary',
            'fiso-hawk-button--full-width',
            'fiso-hawk-button--chevron-right',
            'fiso-hawk-button--extra-margin',
            `fiso-hawk-bg-secondary--${this.props.sportDetails.route}`
        );

        const formClass = classNames(
            'fiso-hawk-forgot-password__form',
            {
                'fiso-hawk-forgot-password__form--loading': this.props.isLoading
            }
        );

        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form className={formClass} onSubmit={this.handleSubmit.bind(this)} method="POST">
                <label className="fiso-hawk-forgot-password__label">
                    Please enter your email address
                    <input
                        ref={(input) => {
                            this.emailAddress = input;
                        }}
                        className="fiso-hawk-form-input__input"
                        name="username"
                        placeholder={`Enter your Watch ${this.props.sportDetails.label} email`}
                        autoComplete="off"
                        type="email"
                        required={true} />
                </label>
                <button
                    className={buttonClass}
                    type="submit">
                    Submit
                    <Fsvg name="next-1" />
                </button>
                {this.renderError()}
            </form>
        );
    }
    render() {
        return (
            <section className="fsui-normalise fiso-hawk-forgot-password">
                <h1 className="fiso-hawk-forgot-password__heading">Get a new password</h1>
                {this.renderLoadingSpinner()}
                {this.renderForm()}
                {this.renderSuccess()}
            </section>
        );
    }
}

ForgotPassword.defaultProps = {
    isLoading: false,
    login: noop
};

ForgotPassword.propTypes = {
    error: propTypes.string,

    isLoading: propTypes.bool,
    isSuccess: propTypes.bool,

    onSubmit: propTypes.func,

    sportDetails: propTypes.shape({
        label: propTypes.string,
        route: propTypes.string
    })
};
