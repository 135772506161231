import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'lodash/noop';
import moment from 'moment';

import Fsvg from '@fsa-streamotion/streamotion-web-fs-ui/src/components/fsvg/fsvg';
import RatioAsset from '@fsa-streamotion/streamotion-web-fs-ui/src/components/ratio-asset/ratio-asset';
import VideoThumbnail from '@fsa-streamotion/streamotion-web-fs-ui/src/components/video-thumbnail/video-thumbnail';

import {getHHMMSS} from  '../utils/helpers';

export default class ShowPage extends React.PureComponent {
    componentDidUpdate({selectedVideo}) {
        if (selectedVideo.id !== this.props.selectedVideo.id) {
            window.scrollTo(0, 0);
        }
    }

    renderVideoTile(video) {
        const videoTileClass = classnames(
            'fiso-hawk-show-page__video-tile',
            {
                'fiso-hawk-show-page__video-tile--selected': this.props.selectedVideo.id === video.id
            }
        );

        const clickVideoTile = () => {
            this.props.onClickVideoTile({id: video.id, title: video.title});
        };

        return (
            // can’t have a button within a button, so keep this as an anchor
            /* eslint-disable jsx-a11y/anchor-is-valid */
            <li
                key={video.id}
                className={videoTileClass}>
                <a
                    aria-label={`Play video ${video.title}`}
                    className="fiso-hawk-show-page__video-tile-btn"
                    onKeyUp={clickVideoTile}
                    onClick={clickVideoTile}
                    role="button"
                    tabIndex="-1">
                    <div className="fiso-hawk-show-page__video-tile-thumbnail">
                        <VideoThumbnail
                            duration={video.duration ? getHHMMSS(video.duration) : 'LIVE'} // video duration 0 means live stream
                            thumbnail={video.imageUrl}
                            size="full-width" />
                    </div>
                    <div className="fiso-hawk-show-page__video-tile-title">
                        <span>{video.title}</span>
                        <span className="fiso-hawk-show-page__video-tile-title--date">
                            {moment(video.liveBroadcastTime).format('MMM DD YYYY')}
                        </span>
                    </div>
                </a>
            </li>
        );
    }

    renderVideoList(videos, index) {
        return (
            <div key={index}>
                <ul className="fiso-hawk-show-page__videos-list">
                    {videos.map((video) => this.renderVideoTile(video))}
                </ul>
            </div>
        );
    }

    renderPlayer() {
        return (
            <div className="fiso-hawk-show-page__selected-video-player">
                {this.props.videoPlayer}
            </div>
        );
    }

    renderSelectedVideo() {
        const selectedVideoTileClass = classnames(
            'fiso-hawk-show-page__selected-video-container',
            {
                'fiso-hawk-show-page__selected-video-container--hide': !this.props.shouldRenderStartCard
            }
        );

        const selectedVideoTitleClass = classnames(
            'fiso-hawk-show-page__selected-video-title',
            {
                'fiso-hawk-show-page__selected-video-title--show-tablet': !this.props.shouldRenderStartCard
            }
        );

        return (
            <section className="fiso-hawk-show-page__selected-video">
                {this.renderPlayer()}
                <div className={selectedVideoTileClass}>
                    <RatioAsset ratio="16x9">
                        <img
                            alt={this.props.selectedVideo.title}
                            className="fiso-hawk-show-page__selected-video-thumbnail"
                            src={this.props.selectedVideo.imageUrl} />
                    </RatioAsset>
                    <button
                        className="fiso-hawk-show-page__selected-video-play"
                        onClick={() => {
                            this.props.onClickVideoPlay();
                        }}>
                        <Fsvg name="play-bordered" />
                    </button>
                </div>
                <div className={selectedVideoTitleClass}>
                    {this.props.selectedVideo.title}
                </div>
            </section>
        );
    }

    render() {
        return (
            <div className="fiso-hawk-show-page fsui-normalise">
                {this.renderSelectedVideo()}
                <section className="fiso-hawk-show-page__show-videos">
                    {!!this.props.currentAndFutureVideos.length && (
                        <React.Fragment>
                            <h3 className="fiso-hawk-show-page__videos-list-header">Upcoming</h3>
                            {this.renderVideoList(this.props.currentAndFutureVideos)}
                        </React.Fragment>
                    )}

                    {!!this.props.pastVideos.length && (
                        <React.Fragment>
                            <h3 className="fiso-hawk-show-page__videos-list-header">On demand</h3>
                            {this.renderVideoList(this.props.pastVideos)}
                        </React.Fragment>
                    )}
                </section>
            </div>
        );
    }
}

ShowPage.defaultProps = {
    selectedVideo: {},
    currentAndFutureVideos: [],
    pastVideos: [],

    onClickVideoPlay: noop,
    onClickVideoTile: noop
};

ShowPage.propTypes = {
    shouldRenderStartCard: propTypes.bool,

    selectedVideo: propTypes.object,
    currentAndFutureVideos: propTypes.arrayOf(
        propTypes.object
    ),

    pastVideos: propTypes.arrayOf(
        propTypes.object
    ),
    videoPlayer: propTypes.node,

    onClickVideoPlay: propTypes.func,
    onClickVideoTile: propTypes.func
};

