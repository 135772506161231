import {
    mediaQuery,
    stylesWhen,
    stylesWhenNot,
} from '@fsa-streamotion/styled-component-helpers';

import chunk from 'lodash/chunk';
import {observer} from 'mobx-react-lite';
import {rgba} from 'polished';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import gibson from '../../../../../../common/font-helper';
import {white} from '../../../../../../common/palette';
import {
    useEffectiveViewport,
    SCREEN_768_TABLET,
    SCREEN_1920_DESKTOP,
} from '../../../../../../common/screen-sizes';
import BA03QualityButton, {
    BUTTON_WIDTH_DEFAULT_PX,
    BUTTON_WIDTH_SCREEN_1920_PX,
} from '../../../../../../component-library/atoms/ba/03-quality-button';
import {
    IC110Dolby,
    IC147Stereo,
} from '../../../../../../component-library/atoms/ic';
import CAM03Finite from '../../../../../../component-library/molecules/cam/03-finite';
import {usePlayerContext} from '../../../../context';
import {Instructions} from './caption-options';
import VideoQualitySelector from './video-quality-selector';
import getAudioTrackLabelByChannelCount from '../../../../../../common/get-audio-track-label-by-channel-count';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const SelectorsContainer = styled.div`
    display: flex;
`;

const QualityItemWrapper = styled.div`
    height: 100%;

    ${stylesWhen('hasBorder')`
        border-left: 1px solid ${rgba(white, 0.15)};
        padding-left: 14px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-left: 35px;
        `}
    `}
`;

const SelectorLabel = styled.div`
    margin-bottom: 7px;
    font: ${gibson.medium({size: 14, lineHeight: 1.29})};

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin-bottom: 14px;
        font-size: 16px;
        line-height: 1.37;
    `}
`;

export const Selector = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 17px;

    ${stylesWhenNot('showTitle')`
        padding-top: 25px;

        ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
            padding-top: 35px;
        `}
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        gap: 21px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        gap: 19px;
    `}
`;

export const ButtonAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    list-style: none;
`;

const Information = styled(Instructions)`
    font-size: 16px;

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        font-size: 20px;
    `}
`;

const QualityOptions = (): React.ReactElement => {
    const {activeScreenConfig} = usePlayerContext() ?? {};
    const isLargeDesktopUp = useEffectiveViewport(SCREEN_1920_DESKTOP);

    const {
        actions: {setAudioTrackByIndex},
    } = activeScreenConfig ?? {actions: {}};
    const {audioTracks, currentAudioTrackIndex} =
        activeScreenConfig?.videoState ?? {};
    const [userSelectedAudioQualityIndex, setUserSelectedAudioIndexQuality] =
        useState(currentAudioTrackIndex);

    const hasAudioTracks =
        typeof audioTracks?.length === 'number' && audioTracks.length > 0;
    const audioTrackButtons = audioTracks?.map((track, index) => {
        const isActive = index === currentAudioTrackIndex;

        const {audioChannelConfiguration, channelCount, codec, language} =
            track ?? {};

        // Store preferred audio settings based on language, codec and channelConfig when the track is selected by the user
        const preferredAudioSettings = {
            audioChannelConfiguration,
            codec,
            language,
        };
        let icon = null;
        let shortLabel = '';

        switch (channelCount) {
            case 2:
                shortLabel = getAudioTrackLabelByChannelCount(channelCount);
                icon = (
                    <IC147Stereo size={isLargeDesktopUp ? '60px' : '40px'} />
                );
                break;
            case 6:
            case 8:
                shortLabel = getAudioTrackLabelByChannelCount(channelCount);
                icon = <IC110Dolby size={isLargeDesktopUp ? '36px' : '22px'} />;
                break;
            default:
                shortLabel = getAudioTrackLabelByChannelCount(channelCount);
                icon = (
                    <IC147Stereo size={isLargeDesktopUp ? '60px' : '40px'} />
                );
        }

        return {
            trackIndex: index,
            isActive,
            label: shortLabel,
            shortLabel,
            icon,
            onClick: () =>
                setAudioTrackByIndex?.(index, preferredAudioSettings),
        };
    });
    const audioTrackButtonGroupOfTwo = chunk(audioTrackButtons, 2);

    useEffect(
        function keepLocalAudioQualityIndexInSyncWithPlayerTech() {
            if (currentAudioTrackIndex !== userSelectedAudioQualityIndex) {
                setUserSelectedAudioIndexQuality(currentAudioTrackIndex);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentAudioTrackIndex]
    );

    return (
        <Container>
            <SelectorsContainer>
                <CAM03Finite
                    itemWidthPx={
                        isLargeDesktopUp
                            ? BUTTON_WIDTH_SCREEN_1920_PX
                            : BUTTON_WIDTH_DEFAULT_PX
                    }
                    itemHeightPx={41}
                    itemGapPx={isLargeDesktopUp ? 35 : 14}
                    isButtonsInline={true}
                >
                    {/* Quality Selector */}
                    <QualityItemWrapper hasBorder={false}>
                        <SelectorLabel>Quality Controls</SelectorLabel>
                        <Selector hasBorder={false} showTitle={true}>
                            <VideoQualitySelector />
                        </Selector>
                    </QualityItemWrapper>

                    {/* Audio Selector */}
                    {hasAudioTracks ? (
                        audioTrackButtonGroupOfTwo.map((selector, index) => {
                            const isFirstItem = index === 0;

                            return (
                                <QualityItemWrapper hasBorder={isFirstItem}>
                                    {isFirstItem && (
                                        <SelectorLabel>
                                            Audio Controls
                                        </SelectorLabel>
                                    )}
                                    <Selector
                                        showTitle={isFirstItem}
                                        key={`audio-quality_${index}`}
                                    >
                                        {selector.map((button, index) => (
                                            <BA03QualityButton
                                                key={`audio-quality-button_${index}`}
                                                isActive={button.isActive}
                                                label={button.label}
                                                shortLabel={button.shortLabel}
                                                icon={button.icon}
                                                onClick={() => {
                                                    setUserSelectedAudioIndexQuality(
                                                        button.trackIndex
                                                    );
                                                    button.onClick();
                                                }}
                                            />
                                        ))}
                                    </Selector>
                                </QualityItemWrapper>
                            );
                        })
                    ) : (
                        <QualityItemWrapper hasBorder={true}>
                            <SelectorLabel>Audio Controls</SelectorLabel>
                            <Information>
                                There’s currently{' '}
                                <b>no Audio Track information</b> available for
                                this stream
                            </Information>
                        </QualityItemWrapper>
                    )}
                </CAM03Finite>
            </SelectorsContainer>
            <Instructions>
                <b>Select</b> from the options available or hide the settings by{' '}
                <b>clicking</b> the Close button
            </Instructions>
        </Container>
    );
};

QualityOptions.displayName = 'QualityOptions';

export default observer(QualityOptions);
