import {DEFAULT_ERROR_PREFIX} from './constants';
import logError from './util/log-error';

/**
 * Normalise a string or number to a string
 * All other types will return defaultVal
 *
 * @param val         - value to normalise (e.g. string or number)
 * @param defaultVal  - default value (e.g. 'some default string')
 * @param errorPrefix - the prefix for the error message, defaults to `Transformers`
 *
 * @returns provided value or defaultVal
 */
export default function asString(
    val: unknown,
    defaultVal?: string,
    errorPrefix = DEFAULT_ERROR_PREFIX
): string | undefined {
    try {
        if (typeof val === 'string') {
            return val;
        }

        if (typeof val === 'number') {
            return val.toString();
        }
    } catch (e) {
        logError(`${errorPrefix}: couldn't normalise arg asString`, val, e);
    }

    return defaultVal;
}
