export default class StreamrootHlsJsTech {
  getTech(adapter) {
    const tech = adapter.getTech();
    return tech.hlsProvider;
  }

  getRendition(adapter) {
    let ret;
    const currentQuality = this.getTech(adapter).getQuality();
    if (typeof currentQuality !== 'undefined' && currentQuality.width && currentQuality.height && currentQuality.bitrate) {
      ret = this.getNpawUtils().buildRenditionString(currentQuality.width, currentQuality.height, currentQuality.bitrate);
    }
    return ret;
  }

  isUsed(adapter) {
    return !!this.getTech(adapter);
  }

  getHls(adapter) {
    return this.getTech(adapter);
  }

  getResource(adapter) {
    let ret = null;
    const currentQuality = this.getTech(adapter).getQuality();
    if (typeof currentQuality !== 'undefined' && currentQuality.url && currentQuality._urlId) {
      ret = currentQuality.url[currentQuality._urlId];
    }
    return ret;
  }

  getBitrate(adapter) {
    let ret;
    const currentQuality = this.getTech(adapter).getQuality();
    if (typeof currentQuality !== 'undefined' && currentQuality.bitrate) {
      ret = currentQuality.bitrate;
    }
    return ret;
  }
}
