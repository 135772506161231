import superagent from 'superagent';
import filter from 'lodash/filter';
import trimStart from 'lodash/trimStart';

import {VIMOND_MIDDLELAYER_URL, DEFAULT_VIMOND_ENV} from '../../utils/constants';
import {streamFromApiWithRetry, getVimondHeaders} from '../../utils/api';

export default function ({
    currency = '',
    productGroupUri,
    productStatus = 'ENABLED',
    sport = 'afl',

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    const url = `${VIMOND_MIDDLELAYER_URL[vimondEnv]}${trimStart(productGroupUri, '/')}`;
    const apiCall = () => superagent
        .get(url)
        .set(getVimondHeaders({sport, version: 2}));

    return streamFromApiWithRetry({apiCall}).flatMapLatest(({body}) => {
        const filterByCurrency = (currency) ? {currency} : {};
        const filterByEnabled = (productStatus) ? {productStatus} : {};

        const products = filter(body.products, Object.assign({}, filterByCurrency, filterByEnabled));

        return products;
    });
}
