import {
    mediaQuery,
    stylesWhenNot,
} from '@fsa-streamotion/styled-component-helpers';

import classnames from 'classnames';
import {rgba} from 'polished';
import React from 'react';
import styled from 'styled-components';

import {transition} from '../../../../common/animations';
import gibson from '../../../../common/font-helper';
import {black, white} from '../../../../common/palette';
import {
    SCREEN_768_TABLET,
    SCREEN_1024_DESKTOP,
    SCREEN_1280_DESKTOP,
    SCREEN_1920_DESKTOP,
} from '../../../../common/screen-sizes';

const GradientContainer = styled.section`
    display: flex;
    position: relative;
    align-items: center;
    transition: ${transition('opacity', 'transform')};
    padding: 7px 0 0;
    width: 100%;

    ${stylesWhenNot('isVisible')`
        transform: translateY(-100%);
        opacity: 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        padding: 14px 0 0;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        padding: 35px 0 0;
    `}

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-image: linear-gradient(
            ${({isInAdBreak}) =>
                isInAdBreak
                    ? `${rgba(black, 0.7)}, transparent`
                    : `${black}, ${rgba(black, 0)} 80%`}
        );
        width: 100%;
        height: 141px;
        content: '';
        pointer-events: none;
        z-index: -1; /* stylelint-disable-line */
    }
`;

const RowsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 14px 0 6px;
    width: 100%;
    overflow: hidden;

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 0 40px 0 29px;
    `}

    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 0 70px 0 51px;
    `}
`;

const LeftRow = styled.div`
    display: flex;
    flex: 1 0 0;
    align-items: center;
    overflow: hidden;
`;

const Title = styled.h3`
    flex-grow: 0;
    flex-shrink: 1;
    margin: 0;
    padding-left: 7px;
    width: 155px;
    overflow: hidden;
    text-shadow: 0 1px 1px rgba(${black}, 0.3);
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${white};
    font: ${gibson({size: 14, lineHeight: 1.57})};

    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        width: 202px;
        font: ${gibson({size: 16, lineHeight: 1.38})};
    `}
    ${mediaQuery({minWidthPx: SCREEN_1024_DESKTOP})`
        margin-left: 20px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        width: 327px;
        font: ${gibson({size: 26, lineHeight: 1.48})};
    `}
`;

const RightRow = styled.div`
    display: flex;
    flex-basis: 0;
    flex-grow: 0;
    justify-content: flex-end;
    gap: 14px;
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        flex-grow: 1;
        gap: 21px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        gap: 22px;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        gap: 35px;
    `}
`;

const ButtonContainer = styled.div`
    flex-shrink: 0;
`;

// TODO: Franco has used set heights, here is an alternative. Once we decide on a solution we will move into a component that we can import
const Divider = styled.div`
    margin: 7px 0;
    border-right: 1px solid ${rgba(white, 0.25)};
    width: 0;
    ${mediaQuery({minWidthPx: SCREEN_768_TABLET})`
        margin: 8px 0;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1280_DESKTOP})`
        margin: 6px 0;
    `}
    ${mediaQuery({minWidthPx: SCREEN_1920_DESKTOP})`
        margin: 11px 0;
        border-right-width: 2px;
    `}
`;

export type Props = {
    /** The Back icon wrapped in a button atom (BA01CtrlBtn) */
    backButton?: React.ReactNode;
    /** The Audio & Settings icon wrapped in a button atom (BA01CtrlBtn) */
    audioSettingsButton?: React.ReactNode;
    /** The Airplay icon wrapped in a button atom (BA01CtrlBtn) */
    airplayButton?: React.ReactNode;
    /** The Chromecast icon wrapped in a button atom (BA01CtrlBtn) */
    chromecastButton?: React.ReactNode;
    /** Title in the top left of Video Player */
    videoTitle?: string;
    /** Whether in ad break */
    isInAdBreak?: boolean;
    /** Visibility toggle */
    isVisible?: boolean;
    /** Function to handle keyboard inputs */
    onKeyDown?: React.KeyboardEventHandler;
    /** Additional classname to be applied */
    className?: string;
};

const OR03UpperControls = ({
    backButton,
    audioSettingsButton,
    airplayButton,
    chromecastButton,
    videoTitle,
    isInAdBreak,
    isVisible,
    onKeyDown,
    className,
}: Props): React.ReactElement =>
    isInAdBreak ? (
        <GradientContainer
            isVisible={true}
            isInAdBreak={isInAdBreak}
            className={classnames(className, 'OR03UpperControls')}
            onKeyDown={onKeyDown}
        >
            <RowsContainer>
                <LeftRow>
                    <ButtonContainer>{backButton}</ButtonContainer>
                </LeftRow>
                <RightRow>
                    {isVisible && airplayButton}
                    {isVisible && chromecastButton}
                </RightRow>
            </RowsContainer>
        </GradientContainer>
    ) : (
        <GradientContainer
            isVisible={isVisible}
            className={classnames(className, 'OR03UpperControls')}
            onKeyDown={onKeyDown}
        >
            <RowsContainer>
                <LeftRow>
                    <ButtonContainer>{backButton}</ButtonContainer>
                    <Title>{videoTitle}</Title>
                </LeftRow>
                <RightRow>
                    {!!audioSettingsButton && (
                        <React.Fragment>
                            {audioSettingsButton}
                            {(!!airplayButton || !!chromecastButton) && (
                                <Divider />
                            )}
                        </React.Fragment>
                    )}
                    {airplayButton}
                    {chromecastButton}
                </RightRow>
            </RowsContainer>
        </GradientContainer>
    );

OR03UpperControls.displayName = 'OR03UpperControls';

export default OR03UpperControls;
