import bacon from 'baconjs';

export const ROUTES = [ // eslint-disable-line import/prefer-default-export
    '',
    () => bacon.later(0, {pageType: '404'}),

    /\/(\w+)\/(\w+)/,
    (categoryId, videoId) => bacon.later(0, {categoryId, videoId, pageType: 'show+video'}),

    /\/(\w+)/,
    (categoryId) => bacon.later(0, {categoryId, pageType: 'show'})
];
