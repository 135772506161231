import result from 'lodash/result';

export default function getIcehockeySeriesNames(seriesRouteOrId) {
    switch (result(seriesRouteOrId, 'toString', '').toLowerCase()) {
        case 'nhl':
        case '55':
            return {
                id: 55,
                label: 'NHL',
                route: 'nhl'
            };

        default:
            // eslint-disable-next-line no-console
            console.info('FISO-commons: Unrecognised Icehockey seriesRouteOrId', seriesRouteOrId);

            return {};
    }
}
