export default class BitmovinReactNativeAdapter {

  onSourceLoaded(e) {
    this.duration = e.source.duration;
  }

  onAudioChanged(e) {}

  onVideoDownloadQualityChanged(e) {}

  onVideoPlaybackQualityChanged(e) {

    this.width = e.newVideoQuality.width
    this.height = e.newVideoQuality.height
    this.bitrate = e.newVideoQuality.bitrate
  }

  onReady(e) {}

  onPlay(e) {

    if (!this.flags.isStarted) {
      this.fireStart({}, e.name);
    }

    if(this.flags.isStarted){
      this.fireResume({}, e.name);
    }
    
  }

  onPlaying(e) {

    if (!this.flags.isJoined) {
      this.fireJoin({}, e.name);
    }

    if(this.flags.isStarted){
      this.fireResume({}, e.name);
    }

  }

  onTimeChanged(e) {

    this.playhead = e.currentTime;
    
  }

  onPaused(e) {

    this.firePause({}, e.name);

  }

  onSeek(e) {

    this.isSeeking = true;

    this.fireSeekBegin({}, false, e.name);
  }

  onSeeked(e) {
    
    if (this.seekIntervalId) {
      clearInterval(this.seekIntervalId);
    }

    this.isSeeking = false;
    this.seekIntervalId = setInterval(() => {
      if (!this.isSeeking) {
        this.fireSeekEnd({}, e.name);
        clearInterval(this.seekIntervalId);
        this.seekIntervalId = null;
      }
    }, 500);
    
  }

  onPlaybackFinished(e) {
    this.fireStop({}, e.name);    
  }

  onStallStarted(e) {
    this.fireBufferBegin({}, false, e.name)
  }

  onStallEnded(e) {
    if(this.chronos.buffer.getDeltaTime(false) < 50){
      this.cancelBuffer();
    }
    else{
      this.fireBufferEnd({}, e.name);
    }
  }

  onPlayerWarning(e) {
    this.fireError(e.code, e.message);
  }

  onPlayerError(e) {
    this.fireError(e.code, e.message);
  }

  onSourceError(e) {
    this.fireError(e.code, e.message);
  }

  onSourceWarning(e) {
    this.fireError(e.code, e.message);
  }

  getBitrate() {
    return this.bitrate
  }

  onDestroy(e) {
    this.fireStop()
  }
  
  getVersion() {
    return '7.0.1-bitmovin-react-native-jsclass';
  }

  getPlayhead() {
    return this.playhead;
  }

  getIsLive() {
    return !this.duration;
  }

  getDuration() {
    return this.duration;
  }

  getResource() {
    return (this.player && this.player.source && this.player.source.config) ? this.player.source.config.url : null;
  }

  getTitle() {
    return (this.player && this.player.source && this.player.source.config) ? this.player.source.config.title : null;
  }

  setPlatform(platform) {
    this.reactPlatform = platform;
  }

  getRendition() {
    if (this.width && this.height) {
      return this.getNpawUtils().buildRenditionString(this.width, this.height);
    }
  }

  getPlayerName() {
    return 'Bitmovin React Native';
  }

}
