import React from 'react';
import bacon from 'baconjs';

import {asNumber} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {handleStreamForIsoRender, initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {createRoot} from 'react-dom/client';
import getShows from '../streams/shows';
import ShowsComponent from '../components/shows';

const ISO_ERROR_EXPIRES_IN = 10;
const ISO_SUCCESS_EXPIRES_IN = 5 * 60;

function Shows(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;
    this.config = {
        categoryId: asNumber(settings.categoryId, null),
        page: asNumber(settings.page, null),
        size: asNumber(settings.size, null),
        sport: this.settings.sport || 'afl',

        vimondEnv: settings.vimondEnv || 'prod'
    };
}

Shows.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

Shows.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: Shows',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN,
                render: this.render.bind(this)
            }));
    });
};

Shows.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <ShowsComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

Shows.prototype.getData = function (initialData) {
    const {categoryId, page, size, sport, vimondEnv} = this.config;

    const showsStream = initialLaterOr(
        initialData && initialData.showsStream,
        getShows({
            categoryId,
            page,
            size,
            sport,
            vimondEnv
        })
    );

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            showsList: showsStream
        }),
        iso: bacon.combineTemplate({
            showsStream
        })
    });
};

Shows.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <ShowsComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <ShowsComponent {...data.view} />,
            'hawkwidgets-shows',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

Shows.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

pageBoot(Shows, 'hawkwidgets-shows');

export default function (element, settings) {
    return new Shows(element, settings);
}
