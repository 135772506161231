import get from 'lodash/get';
import moment from 'moment';

import {isPost} from '@fsa/fs-commons/lib/utils/match-status';

import {DEFAULT_VIMOND_ENV, LIVE_VIDEO_POLL_FREQUENCY, CONTENT_URL} from '../utils/constants';
import getFixturesAndResultsByFixtureId from './end-points/fixturesandresults-by-fixture-id';

export default function getMatchVideoData({
    sport,
    fixtureId,
    assetTypes,
    freq,

    vimondEnv = DEFAULT_VIMOND_ENV
}) {
    return getFixturesAndResultsByFixtureId({
        sport,
        fixtureId,
        vimondEnv,
        assetTypes,
        freq
    })
        .map((fixtureData) => getMassagedMatchVideoData(fixtureData));
}

export function getMassagedMatchVideoData(fixtureData = {}) {
    const awayTeamData = fixtureData.team_B || {};
    const homeTeamData = fixtureData.team_A || {};
    const videoData = get(fixtureData, 'videos[0]', {});
    const liveBroadcastTime = get(videoData, 'liveBroadcastTime', '');
    const timeToNextPoll = liveBroadcastTime ? moment(liveBroadcastTime).diff(liveBroadcastTime) : LIVE_VIDEO_POLL_FREQUENCY;
    const {sport} = fixtureData;
    const teamStartCardBaseUrl = `${CONTENT_URL}/${sport}/2017/teams/752/`;

    return {
        video: videoData,
        awayTeam: {
            fallbackLogo: `${teamStartCardBaseUrl}null_752_away.png`,
            logo: `${teamStartCardBaseUrl}${awayTeamData.id}_752_away.png`,
            name: awayTeamData.name || ''
        },
        hasVideo: hasMatchVideo(fixtureData),
        homeTeam: {
            fallbackLogo: `${teamStartCardBaseUrl}null_752_home.png`,
            logo: `${teamStartCardBaseUrl}${homeTeamData.id}_752_home.png`,
            name: homeTeamData.name || ''
        },
        timeToNextPoll,
        playbackUrl: get(videoData, 'playback.url', ''),
        title: get(videoData, 'title', ''),
        shouldRenderLive: get(videoData, 'live', false)
    };
}

export function hasMatchVideo(fixtureData) {
    const videoData = fixtureData.videos || [];
    const isPostMatch = isPost(fixtureData.match_status);

    return (videoData.length > 0) &&
        (isPostMatch ||
            moment()
                .utc()
                .isSameOrAfter(videoData[0].liveBroadcastTime));
}
