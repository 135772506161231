import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC265ClseCapOn = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M86 0v58H0V0h86zM28.566 15C20.66 15 13 20.714 13 30.179 13 39.583 20.418 45 28.444 45c7.844 0 10.884-3.333 11.492-3.869l-2.189-5.595-.017.018c-.242.251-3.01 3.017-7.827 3.017-5.046 0-8.755-3.452-8.755-8.75 0-5.297 3.709-8.452 8.33-8.452 4.56 0 7.175 2.202 8.087 3.036l2.128-5.715c-.79-.773-3.71-3.69-11.127-3.69zm29.064 0c-7.905 0-15.566 5.714-15.566 15.179C42.064 39.583 49.482 45 57.508 45c7.844 0 10.884-3.333 11.492-3.869l-2.189-5.595-.017.018c-.242.251-3.01 3.017-7.827 3.017-5.046 0-8.755-3.452-8.755-8.75 0-5.297 3.709-8.452 8.33-8.452 4.56 0 7.175 2.202 8.087 3.036l2.128-5.715c-.79-.773-3.71-3.69-11.127-3.69z"
            transform="translate(7 20) translate(0 1)"
        />
    </Ic>
);

IC265ClseCapOn.displayName = 'IC265ClseCapOn';

export default IC265ClseCapOn;
