import {SCRUBBER_TYPE} from '@fsa-streamotion/player-state';

import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useTheme} from 'styled-components';

import {white} from '../../../common/palette';
import BA01CtrlBtn from '../../../component-library/atoms/ba/01-ctrl-btn';
import BA99Marker from '../../../component-library/atoms/ba/99-marker';
import {
    IC06Play,
    IC07Pau,
    IC08Cc,
    IC14Skb,
    IC15Skf,
    IC221NextUp,
    IC265ClseCapOn,
    IC266ClseCapOff,
    IC19JLive,
    IC20Live,
} from '../../../component-library/atoms/ic';
import OR04ChromecastControls from '../../../component-library/organisms/or/04-chromecast-controls';
import {SEEK_ADJUSTMENT_S} from '../../utils/constants';
import {useChromecastSenderContext} from '../context';
import DisplayTime from './display-time';
import Scrubber from './scrubber';
import VolumeSlider from './volume-slider';

const ObservedStateWrapper = observer(() => {
    const chromecastSenderContext = useChromecastSenderContext();
    const {
        onClickChromecastButton,
        chromecastStatus,
        onClickClosedCaptionsButton,
        isChromecastConnected,
        isClosedCaptionsEnabled,
        errorMessage,
        isLiveStream,
        isOnEdge,
        onClickGoToLive,
        hasControls,
        isLoading,
        isVisible,
        markerButtonsData,
        markerCopy,
        markerTitle,
        mediaTitle,
        nextUpButtonCopy,
        onClickNextUp,
        isPaused,
        onClickPlay,
        onClickPause,
        currentTime,
        setCurrentTime,
        scrubBarType,
    } = chromecastSenderContext?.values ?? {};

    const theme = useTheme();

    // TODO: Modify this feature flag to common condition after the `scrubbarType` migration complete on Kayo and Flash.
    const shouldLockScrubberToEdge = scrubBarType
        ? SCRUBBER_TYPE[scrubBarType] === SCRUBBER_TYPE.none
        : false;

    const chromecastButton = onClickChromecastButton ? (
        <BA01CtrlBtn aria-label="Chromecast" onClick={onClickChromecastButton}>
            <IC08Cc
                accentColor={theme.brandColor}
                isConnected={isChromecastConnected}
            />
        </BA01CtrlBtn>
    ) : null;

    const closedCaptionsButton = onClickClosedCaptionsButton ? (
        <BA01CtrlBtn
            aria-label={
                isClosedCaptionsEnabled
                    ? 'Disable Closed Captions'
                    : 'Enable Closed Captions'
            }
            onClick={onClickClosedCaptionsButton}
            label="Closed Captions"
            shouldHideLabelOnMobile={true}
        >
            {isClosedCaptionsEnabled ? <IC265ClseCapOn /> : <IC266ClseCapOff />}
        </BA01CtrlBtn>
    ) : null;

    const goToLiveButton =
        isLiveStream && onClickGoToLive ? (
            <BA01CtrlBtn onClick={onClickGoToLive} aria-label="Jump to Live">
                {isOnEdge ? <IC20Live /> : <IC19JLive />}
            </BA01CtrlBtn>
        ) : null;

    const markerButtons = markerButtonsData?.length
        ? markerButtonsData.map(({copy, onClick}) => (
              <BA99Marker onClick={onClick} key={copy}>
                  {copy}
              </BA99Marker>
          ))
        : null;

    const nextUpButton =
        nextUpButtonCopy && onClickNextUp ? (
            <BA01CtrlBtn
                aria-label={nextUpButtonCopy}
                label={nextUpButtonCopy}
                onClick={onClickNextUp}
                shouldHideLabelOnMobile={true}
            >
                <IC221NextUp color={white} />
            </BA01CtrlBtn>
        ) : null;

    const playButton = !shouldLockScrubberToEdge && (
        <BA01CtrlBtn
            aria-label={isPaused ? 'Play' : 'Pause'}
            onClick={isPaused ? onClickPlay : onClickPause}
        >
            {isPaused ? <IC06Play /> : <IC07Pau />}
        </BA01CtrlBtn>
    );

    const onClickSkipBack = useCallback(() => {
        if (setCurrentTime && typeof currentTime === 'number') {
            setCurrentTime(currentTime - SEEK_ADJUSTMENT_S);
        }
    }, [setCurrentTime, currentTime]);

    const onClickSkipForward = useCallback(() => {
        if (setCurrentTime && typeof currentTime === 'number') {
            setCurrentTime(currentTime + SEEK_ADJUSTMENT_S);
        }
    }, [setCurrentTime, currentTime]);

    const skipBackButton = !shouldLockScrubberToEdge && (
        <BA01CtrlBtn onClick={onClickSkipBack} aria-label="Skip back">
            <IC14Skb />
        </BA01CtrlBtn>
    );

    const skipForwardButton = !shouldLockScrubberToEdge && (
        <BA01CtrlBtn onClick={onClickSkipForward} aria-label="Skip forward">
            <IC15Skf />
        </BA01CtrlBtn>
    );

    return (
        <OR04ChromecastControls
            className="sm-chromecast-sender"
            chromecastButton={chromecastButton}
            chromecastStatus={chromecastStatus}
            closedCaptionsButton={closedCaptionsButton}
            displayTime={shouldLockScrubberToEdge ? null : <DisplayTime />}
            errorMessage={errorMessage}
            goToLiveButton={goToLiveButton}
            hasControls={hasControls}
            isLoading={isLoading}
            isVisible={isVisible}
            markerButtons={markerButtons}
            markerCopy={markerCopy}
            markerTitle={markerTitle}
            mediaTitle={mediaTitle}
            nextUpButton={nextUpButton}
            playButton={playButton}
            scrubber={<Scrubber />}
            skipBackButton={skipBackButton}
            skipForwardButton={skipForwardButton}
            volumeSlider={<VolumeSlider />}
        />
    );
});

export default ObservedStateWrapper;
