export default class DashShakaTech {
  getBitrate(adapter) {
    return adapter.player.dash.shakaPlayer.getStats().streamBandwidth;
  }

  getResource(adapter) {
    var ret = '';
    var sp = adapter.player.dash.shakaPlayer;
    if (typeof sp.getManifestUri === 'function') {
      ret = sp.getManifestUri();
    } else if (typeof sp.getAssetUri === 'function') {
      ret = sp.getAssetUri();
    }
    return ret;
  }

  isUsed(adapter) {
    return adapter.player.dash && adapter.player.dash.shakaPlayer;
  }

  getRendition(adapter) {
    var shaka = adapter.player.dash.shakaPlayer;
    var stats = shaka.getStats();
    var ret = this.getNpawUtils().buildRenditionString(stats.width, stats.height, stats.streamBandwidth);
    var tracks = shaka.getVariantTracks();
    for (var i in tracks) {
      var track = tracks[i];
      if (track.active && track.type === 'video') {
        ret = this.getNpawUtils().buildRenditionString(track.width, track.height, track.bandwidth);
      }
    }
    return ret;
  }
}