import React from 'react';

import Ic, {type Props as IcProps} from '..';
import {black} from '../../../../common/palette';

export type Props = {
    /** The colour of the minus sign */
    accentColor?: string;
} & IcProps;

const IC134Minus = ({
    accentColor = black,
    ...args
}: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M42.62 0C19.083 0 0 19.082 0 42.62c0 23.54 19.082 42.621 42.62 42.621 23.54 0 42.621-19.082 42.621-42.62C85.234 19.085 66.156.008 42.621 0z"
            transform="translate(8 8)"
        />
        <path
            fill={accentColor}
            d="M21.911 38.428L21.911 46.813 62.631 46.813 62.631 38.428z"
            transform="translate(8 8)"
        />
    </Ic>
);

IC134Minus.displayName = 'IC134Minus';
export default IC134Minus;
