import React from 'react';
import {createRoot} from 'react-dom/client';
import bacon from 'baconjs';
import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import {getLocalStorageValue} from '@fsa/fs-commons/lib/utils/storage';
import {initialLaterOr} from '@fsa/fs-commons/lib/streams/helper';
import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {handleStreamForIsoRender, renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {asNumber, asString} from '@fsa/fs-commons/lib/utils/normalise-arg';
import {getSportNames as getSportDetails} from '@fsa/fs-commons/lib/utils/sport-names';
import {isPost} from '@fsa/fs-commons/lib/utils/match-status';

import {getCurrencySymbol} from '../utils/helpers';
import {DEFAULT_VIMOND_ENV, USER_LOCAL_STORAGE_KEY, MEDIA_OTT_URL} from '../utils/constants';

import getUserOrdersStream from '../streams/user/user-get-orders';
import getUserFutureOrdersStream from '../streams/user/user-get-future-orders';
import getProductGroups from '../streams/product-groups/get-product-groups';
import getProducts from '../streams/products/get-products';
import getFixturesAndResultsByFixtureId from '../streams/end-points/fixturesandresults-by-fixture-id';
import {getMassagedMatchDetailsData} from '../streams/match-details/match-details-massager';

import promoHeaderWidget from '../widgets/promo-header';
import OfferHomeComponent from '../components/offer-home';

import getVoucherProductFormData from './subscribe/voucher-product';

const ISO_ERROR_EXPIRES_IN_SECONDS = 10; // 10 secs
const ISO_SUCCESS_EXPIRES_IN_SECONDS = 5 * 60; // 5 minutes.
const DELAY_SCORE_UPDATE_MILLISECONDS = 15 * 1000; // 15 seconds

function OfferHome(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        appLinks: JSON.parse(this.settings.appLinks),
        currency: this.settings.currency,
        fixtureId: asNumber(this.settings.fixtureId, undefined),
        popularProduct: asNumber(this.settings.popularProduct, 1),
        productGroupId: asNumber(this.settings.productGroupId, undefined),
        promoHeaderConfigName: asString(this.settings.promoHeaderConfigName, undefined),
        pubsVenuesLink: this.settings.pubsVenuesLink || '',
        sport: this.settings.sport || 'afl',

        vimondEnv: settings.vimondEnv || DEFAULT_VIMOND_ENV
    };
}

OfferHome.prototype.init = function (initialData = {}) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

OfferHome.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier: 'HAWK: OfferHome',
                expirySecondOnFailure: ISO_ERROR_EXPIRES_IN_SECONDS,
                expirySecondOnSuccess: ISO_SUCCESS_EXPIRES_IN_SECONDS,
                render: this.render.bind(this)
            }));
    });
};

OfferHome.prototype.initComponentStream = function (initialData = {}) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <OfferHomeComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

OfferHome.prototype.getData = function (initialData = {}) {
    const currencyBus = new bacon.Bus();
    const {currency, vimondEnv, fixtureId, promoHeaderConfigName, sport, freq} = this.config;
    const sportDetails = getSportDetails(this.config.sport);

    const userId = get(getLocalStorageValue({key: USER_LOCAL_STORAGE_KEY}), 'id', null);

    const userAuth = bacon.later(0, null)
        .map(() => !!getLocalStorageValue({key: 'auth'}));

    const activeOrder$ = userId && getUserOrdersStream({
        userId,
        vimondEnv,
        sport
    })
        .map('.0');

    const futureOrder$ = userId && getUserFutureOrdersStream({
        userId,
        vimondEnv,
        sport
    })
        .map('.0');

    const isHidden$ = bacon.combineWith(
        userAuth, activeOrder$, futureOrder$,
        (userAuth, activeOrder, futureOrder) => !!(userAuth && (activeOrder || futureOrder))
    )
        .filter(() => process.browser) // ignore values on the server. Only allow values from Browser.
        .startWith(true);

    const currency$ = bacon.update(
        currency,
        [currencyBus], (prev, newValue) => newValue
    );

    const productGroups$ = initialLaterOr(
        initialData && initialData.productGroups,
        getProductGroups({sport, vimondEnv})
            .map('.productGroups')
    );

    const products$ = currency$
        .flatMapLatest((currency) => (
            initialLaterOr(
                initialData && initialData.products,

                productGroups$
                    .flatMapLatest((productGroups) => {
                        const productGroup = find(productGroups, {id: this.config.productGroupId});
                        const productGroupUri = get(productGroup, 'productsUri.uri', '');

                        return getProducts({productGroupUri, currency, vimondEnv, sport})
                            .map((products) => products
                                .slice(0, 3)
                                .sort((first, second) => first.sortIndex - second.sortIndex) // eslint-disable-line max-nested-callbacks
                            );
                    })
            )));

    const productVoucherData$ = products$
        .flatMapLatest((products) => (
            bacon.combineAsArray(
                products.map(({productGroupId, id}) => (
                    getVoucherProductFormData({
                        currency$,

                        voucher: this.config.voucher,
                        isVoucherOptional: true, // voucher is only mandatory for club's page
                        productGroupId,
                        chosenProductId: id,

                        sport,

                        initialData: {},
                        vimondEnv: this.config.vimondEnv
                    })
                )))
        ));

    const isVoucherLoading$ = productVoucherData$
        .map((voucherDataArray) => (
            voucherDataArray.some((voucherData) => (
                voucherData.isVoucherLoading
            ))
        ));

    const isVoucherValid$ = productVoucherData$
        .map((voucherDataArray) => (
            voucherDataArray.some((voucherData) => (
                voucherData.isVoucherValid
            ))
        ));

    // initialVoucher for all three products are the same, so just grab the first
    const initialVoucher$ = productVoucherData$
        .map((voucherDataArray) => (
            voucherDataArray.map(({initialVoucher}) => initialVoucher)
        ))
        .map('.0.initialVoucher');

    const voucherError$ = productVoucherData$
        .map((voucherDataArray) => ({
            errorsArray: voucherDataArray.filter(({voucherError}) => voucherError),
            voucherDataArrayLength: voucherDataArray.length
        }))
        .map(({errorsArray, voucherDataArrayLength}) => {
            if (errorsArray.length < voucherDataArrayLength) {
                // it worked on at least one of the products!
                return '';
            }

            // return the first error, who cares
            return get(errorsArray, '[0].voucherError', '');
        });

    const onVoucherChangedArray$ = productVoucherData$
        .map((voucherDataArray) => (
            voucherDataArray.map(({onVoucherChanged}) => onVoucherChanged)
        ));

    const voucherisedProducts$ = productVoucherData$
        .map((voucherDataArray) => (
            voucherDataArray.map(({currentProduct}) => currentProduct)
        ));

    const currencySymbol$ = currency$
        .map(getCurrencySymbol);

    // In the UK we're not contractually allowed to offer pubs and venues subscriptions, so don't show the link
    const disableVenuesLink$ = sportDetails.apiName === 'league' && products$
        .map((products) => get(products, '[0].currency') === 'GBP')
        .startWith(false)
        .skipDuplicates(isEqual);

    const supportedPlatformsUrls = {
        afl: 'https://help.watchafl.com.au/support/solutions/articles/19000041923-how-can-i-watch-the-stream-on-a-television-',
        nrl: 'https://help.watchnrl.com/support/solutions/articles/19000076417-how-can-i-watch-the-stream-on-a-television-'
    };

    // If we have a fixture ID we need to load a whole match details banner, so we optionally grab certain streams for that

    const matchCardToggleClickBus = new bacon.Bus();
    const showMatchScore$ = bacon.update(
        false,
        [matchCardToggleClickBus], (toggleChecked) => !toggleChecked
    ).skipDuplicates(isEqual);

    const getFixturesAndResults$ = bacon.later(0, fixtureId)
        .filter(Boolean) // only want to hit this stuff, if we actually have a fixture id
        .flatMapLatest((fixtureId) =>
            initialLaterOr(
                initialData && initialData.getFixturesAndResults$,
                getFixturesAndResultsByFixtureId({
                    sport,
                    fixtureId,
                    freq: 0,
                    vimondEnv,
                    is400Valid: true // The API returns a 400 if the fixture is not available
                })
            )
                .flatMapLatest((fixtureAndResults) => {
                    if (isPost(fixtureAndResults.match_status)) {
                        return fixtureAndResults;
                    }

                    return bacon.later(freq || 30000, null)
                        .skip(1)
                        .concat(
                            getFixturesAndResultsByFixtureId({
                                sport,
                                fixtureId,
                                freq,
                                vimondEnv,
                                is400Valid: true // The API returns a 400 if the fixture is not available
                            })
                                .delay(DELAY_SCORE_UPDATE_MILLISECONDS)
                        )
                        .startWith(fixtureAndResults);
                })
        )
        .toProperty({});

    const fixturesAndResults$ = getFixturesAndResults$
        .map((fixtureResults) => getMassagedMatchDetailsData(fixtureResults, this.config));

    const promoHeaderSettings = {
        currency,
        promoHeaderConfigName,
        sport,
        vimondEnv
    };

    const promoHeader$ = promoHeaderWidget(null, promoHeaderSettings)
        .initComponentStream(initialData && initialData.promoHeader$);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            isHidden: isHidden$,
            products: voucherisedProducts$,
            popularProduct: this.config.popularProduct,
            productGroupId: this.config.productGroupId,
            currencySymbol: currencySymbol$,
            appLinks: this.config.appLinks,
            sportDetails,
            disableVenuesLink: disableVenuesLink$,

            supportedPlatformsUrls,

            initialVoucher: initialVoucher$,
            isVoucherLoading: isVoucherLoading$,
            isVoucherValid: isVoucherValid$,
            onVoucherChangedArray: onVoucherChangedArray$,
            voucherError: voucherError$,

            promoHeader: promoHeader$.map('.reactElement'),
            mediaImage: `${MEDIA_OTT_URL}/${sportDetails.apiName}/join-media/app-image.png`,
            pubsVenuesLink: this.config.pubsVenuesLink,

            // fixture id stuffs;
            fixturesAndResults: fixturesAndResults$.map('.fixtureResults'),
            matchDetailType: fixturesAndResults$.map('.matchDetailType'),
            matchStatus: fixturesAndResults$.map('.matchStatus'),
            teamStartCard: fixturesAndResults$.map('.teamStartCard'),
            title: fixturesAndResults$.map('.title'),
            showMatchScore: showMatchScore$,
            onMatchCardToggleClick: (toggleChecked) => {
                matchCardToggleClickBus.push(toggleChecked);
            }
        }),
        iso: bacon.combineTemplate({
            productGroups: productGroups$,
            products: products$,
            getFixturesAndResults$,
            promoHeader$: promoHeader$.map('.iso')
        })
    });
};

OfferHome.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <OfferHomeComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <OfferHomeComponent {...data.view} />,
            'hawkwidgets-offer-home',
            {
                settings: this.settings,
                data: data.iso
            }
        );
    }
};

OfferHome.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

OfferHome.prototype.pageBoot = function () {
    pageBoot(OfferHome, 'hawkwidgets-offer-home');
};

export default function (element, settings) {
    return new OfferHome(element, settings);
}

/**
 * Calls the bootloader for the widget. The bootloader name is defined in fiso.js, e.g. 'video-mosaic'
 */
pageBoot(OfferHome, 'hawkwidgets-offer-home');
