import {stylesWhen} from '@fsa-streamotion/styled-component-helpers';

import property from 'lodash/property';
import React from 'react';
import styled from 'styled-components';

type VerticallyAlignType = {
    verticallyAlign?: 'top' | 'bottom';
};

const StyledDiv = styled.div.attrs<VerticallyAlignType>(({verticallyAlign}) => {
    if (typeof verticallyAlign === 'undefined') {
        return {};
    }

    const isAlignTopOrBottom = ['top', 'bottom'].includes(verticallyAlign);

    return isAlignTopOrBottom
        ? {
              style: {
                  position: 'absolute',
                  alignItems:
                      verticallyAlign === 'top' ? 'flex-start' : 'flex-end',
                  [verticallyAlign]: 0,
              },
          }
        : {};
})<VerticallyAlignType>`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    pointer-events: all;
`;

const Controls = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 30px;

    ${stylesWhen('horizontallyAlign')`
        position: absolute;
        ${property(
            'horizontallyAlign'
        )}: 0; /* interpolating the actual rule value here */
    `}
`;

type Props = {
    centreControls?: React.ReactNode;
    leftControls?: React.ReactNode;
    rightControls?: React.ReactNode;
    style?: object;
} & VerticallyAlignType;

const ScreenArea = ({
    verticallyAlign,
    leftControls,
    centreControls,
    rightControls,
    style,
}: Props): React.ReactElement => (
    <StyledDiv verticallyAlign={verticallyAlign} style={style}>
        {!!leftControls && (
            <Controls horizontallyAlign="left">{leftControls}</Controls>
        )}
        {!!centreControls && <Controls>{centreControls}</Controls>}
        {!!rightControls && (
            <Controls horizontallyAlign="right">{rightControls}</Controls>
        )}
    </StyledDiv>
);

ScreenArea.displayName = 'ScreenArea';

export default ScreenArea;
