import CircularJSON from 'circular-json';

exports.page = function (Widget, tagName) {
    // Make sure we're a browser, have fsWidgetsBoots and that
    // this particular tagName has been populated or just move on.
    if (!process.browser ||
        !window.fisoBoot ||
        typeof window.fisoBoot[tagName] != 'object') {
        return;
    }

    Object.keys(window.fisoBoot[tagName]).forEach(function (elementId) {
        const domNode = document.getElementById(elementId);

        if (!domNode) {
            console.error('fisoBoot: No element found for ID ' + elementId);

            return;
        }

        let fisoBootArguments = window.fisoBoot[tagName][elementId];
        let settings, data, widget;

        if (typeof fisoBootArguments == 'string') {
            fisoBootArguments = CircularJSON.parse(window.fisoBoot[tagName][elementId]);
        }

        if (fisoBootArguments.booted) {
            return;
        }

        settings = fisoBootArguments.settings;
        data     = fisoBootArguments.data;

        try {
            widget = new Widget(domNode, settings);
            widget.init(data);
            fisoBootArguments.booted = true;
        } catch (e) {
            console.error('fisoBoot: Unable to boot widget ' + tagName + ' for domNode ', domNode);
            throw e;
        }
    });
};
