import React from 'react';

import Ic, {type Props as IcProps} from '..';

export type Props = IcProps;

const IC110Dolby = (args: Props): React.ReactElement => (
    <Ic {...args}>
        <path
            d="M16.9217608 12.889913h16.3546699c16.9712534 0 31.1637336 16.8495957 31.1637336 36.7268099 0 20.4827378-14.1924802 37.0198943-31.1637336 37.0198943H16.9217608V12.889913zm93.2451202 0h16.401674v73.7467042h-16.401674c-16.9933727 0-31.1554385-16.5371565-31.1554385-37.0198943 0-19.8772142 14.1620658-36.7268099 31.1554385-36.7268099zM.0002765 99.5378665H143.459625V-.0002765H.0002765v99.538143z"
            transform="scale(0.7) translate(0 20)"
        />
    </Ic>
);

IC110Dolby.displayName = 'IC110Dolby';

export default IC110Dolby;
