import bacon from 'baconjs';

import {getApiFromRetryWithError} from '@fsa/fs-commons/lib/request-manager/streams';

import {TV_GUIDE_API} from '../../utils/constants';

export default function ({
    freq = 0,
    channel = 10,
    startDate,
    endDate
}) {
    const retries = (process && process.browser) ? 3 : 0;
    const cacheBustingHack = `?v=fiso${process && process.browser ? '_b' : '_s'}`;
    const url = `${TV_GUIDE_API}programmes.json?channel=${channel}&from=${startDate}&to=${endDate}&${cacheBustingHack}`;

    return getApiFromRetryWithError({
        url,
        freq,
        retries
    })
        .flatMapLatest((response) => {
            if (response.responseConsideredValid) {
                return response.parseResponseText();
            } else if (process.browser) {
                // If we timed out retrying, and we're client side,
                // return no results.
                return [];
            }
            // We're on the server and had issues, so throw an error
            const errorMessage = `FS-HAWK-WIDGETS: Unable to get TV Guide Programmes (${url}): ${response.responseStatus} ${response.superAgentErr}`; // eslint-disable-line max-len

            return new bacon.Error(errorMessage);
        });
}
