import type {MediaPlayerClass} from 'dashjs';
import round from 'lodash/round';

import NativeBuffer from '../native/buffer';

export default class DashBuffer extends NativeBuffer {
    // Interval that calculates how much % we have left during stalled buffers.
    intervalBufferState: number | null = null;
    bufferPercentage: number | null = 0;
    declare playbackHandler: MediaPlayerClass;

    override destroy(): void {
        super.destroy();

        if (this.intervalBufferState) {
            window.clearInterval(this.intervalBufferState);
        }
    }

    override trySetBufferStart(event: Event): boolean {
        if (super.trySetBufferStart(event)) {
            this.bufferPercentage = null;

            this.intervalBufferState = window.setInterval(
                this.monitorPlaybackProgress,
                this.bufferingMonitorInterval
            );

            return true;
        }

        return false;
    }

    override trySetBufferFinished(): boolean {
        if (super.trySetBufferFinished()) {
            if (this.intervalBufferState) {
                window.clearInterval(this.intervalBufferState);
            }

            this.bufferPercentage = null;

            return true;
        }

        return false;
    }

    monitorPlaybackProgress = (): void => {
        const dashMetrics = this.playbackHandler.getDashMetrics();
        let bufferPercentage =
            (dashMetrics.getCurrentBufferLevel('video') +
                dashMetrics.getCurrentBufferLevel('audio')) /
            2;

        if (isNaN(bufferPercentage)) {
            bufferPercentage = 0;
        }

        const bufferPercentageRounded = round(bufferPercentage, 2);

        // Throw new percentage if it's different!
        if (
            this.bufferPercentage === null ||
            this.bufferPercentage < bufferPercentage
        ) {
            this.bufferPercentage = bufferPercentage;

            this.triggerBufferingPercentage(bufferPercentageRounded); // Throw the event through the video element
        }
    };
}
