import React from 'react';
import bacon from 'baconjs';
import uniq from 'lodash/uniq';

import {page as pageBoot}  from '@fsa/fs-commons/lib/iso/boot';
import {renderToHtml} from '@fsa/fs-commons/lib/iso/render';
import {initialLaterOr, handleStreamForIsoRender} from '@fsa/fs-commons/lib/streams/helper';
import pollUntil from '@fsa/fs-commons/lib/streams/poll-until';

import {createRoot} from 'react-dom/client';
import getScoresLive from '../streams/matches/scores-live';
import getSpecialLiveEvents from '../streams/live-special-events';
import getSeriesConfig from '../streams/fixture-list/get-series-config';
import LiveEventsComponent from '../components/live-events';

import videoModalWidget from './video-modal';

const EXPIRY_SECONDS = 180; // 3 mins

function LiveEvents(element, settings) {
    this.element  = element;
    if (this.element) {
        this.root = createRoot(this.element);
    }
    this.settings = settings;

    this.config = {
        sport:      settings.sport,
        supportUrl: settings.supportUrl || 'https://www.foxsports.com.au',
        vimondEnv:  settings.vimondEnv || 'prod'
    };

    // Get the modal ready (yay 1 click)
    videoModalWidget();

    this.config.onClickVideo = (videoDetails) => {
        videoModalWidget().play({
            sport: this.config.sport,
            supportUrl: this.config.supportUrl,
            videoDetails,
            vimondEnv: this.config.vimondEnv
        });
    };
}

LiveEvents.prototype.init = function (initialData = false) {
    this.closeStreams = this.getData(initialData)
        .onValue(this.render.bind(this));
};

LiveEvents.prototype.initIso = function () {
    return new Promise((onResolve, onReject) => {
        this.closeStreams = this.getData()
            .take(1)
            .subscribe((event) => handleStreamForIsoRender({
                onReject,
                onResolve,
                event,

                identifier:            'HAWK: LiveEvents',
                expirySecondOnFailure: 10,
                expirySecondOnSuccess: EXPIRY_SECONDS,
                render:                this.render.bind(this)
            }));
    });
};

LiveEvents.prototype.initComponentStream = function (initialData = false) {
    const data         = this.getData(initialData);
    const reactElement = data.map((data) => <LiveEventsComponent {...data.view} />);
    const iso          = data.map('.iso');

    return bacon.combineTemplate({data, reactElement, iso});
};

LiveEvents.prototype.getData = function (initialData) {
    const {sport, vimondEnv} = this.config;

    const seriesIds$ = pollUntil({
        streamToPoll: getSeriesConfig({sport})
            .map(
                (seriesList) => seriesList.map((series) => series.id)
            ),
        pollWhileCallback: () => true,
        delayMs: 0,
        initialData: initialData && initialData.seriesIds
    });

    const liveMatches$ = initialLaterOr(
        initialData && initialData.liveMatches,
        seriesIds$.flatMapLatest((seriesIds) =>
            getScoresLive({
                sport,
                seriesIds,
                vimondEnv
            })
        )
    );

    const liveSpecialEvents$ = initialLaterOr(
        initialData && initialData.liveSpecialEvents,
        getSpecialLiveEvents({
            sport,
            vimondEnv
        })
    );

    // Combine live matches and events into a single array, sorted by their start times
    const liveEvents$ = bacon.combineWith(
        liveMatches$,
        liveSpecialEvents$,
        (liveMatches, liveSpecialEvents) => [
            ...liveMatches.map((item) => ({
                type: 'match',
                id: item.match_id,
                start: new Date(item.match_start_date),
                item
            })),
            ...liveSpecialEvents.map((item) => ({
                type: 'event',
                id: item.id,
                start: new Date(item.liveBroadcastTime),
                item
            }))
        ]
            .sort((a, b) => a.start - b.start)
    );

    const seriesList$ = liveMatches$
        .map((matches = []) =>
            matches
                .map(({series}) => series && series.id)
                .filter(Boolean)
        )
        .map(uniq);

    return bacon.combineTemplate({
        view: bacon.combineTemplate({
            liveEvents: liveEvents$,
            seriesList: seriesList$,
            sport,
            onPlayLiveEvent: this.config.onClickVideo
        }),
        iso: bacon.combineTemplate({
            seriesIds: seriesIds$,
            liveMatches: liveMatches$,
            liveSpecialEvents: liveSpecialEvents$
        })
    });
};

LiveEvents.prototype.render = function (data) {
    if (this.element && this.root) {
        this.root.render(
            <LiveEventsComponent {...data.view} />
        );
    } else {
        return renderToHtml(
            <LiveEventsComponent {...data.view} />,
            'hawkwidgets-live-events',
            {
                settings: this.settings,
                data:     data.iso
            }
        );
    }
};

LiveEvents.prototype.remove = function () {
    try {
        this.closeStreams();
    } catch (e) {} // eslint-disable-line no-empty

    try {
        if (this.element && this.root) {
            this.root.unmount();
        }
    } catch (e) {} // eslint-disable-line no-empty
};

LiveEvents.prototype.pageBoot = function () {
    pageBoot(LiveEvents, 'hawkwidgets-live-events');
};

pageBoot(LiveEvents, 'hawkwidgets-live-events');

export default function (element, settings) {
    return new LiveEvents(element, settings);
}
