/* Normalises old screen orientation methods to a promise like the new spec. */

/**
 *
 * Lock the screen's orientation.
 *
 * This uses <code>window.screen.orientation.lock</code>, falling back to
 * the browser-specific legacy fallback, if available, and mapping
 *
 * @param orientation - the orientation type
 * @returns a promise that resolves when the orientation has been locked
 * @throws {@link DOMException} if orientation lock via the standard API call fails
 * @throws {@link Error} if orientation lock via a legacy API call fails
 */
export async function lockOrientation(
    orientation: OrientationLockType
): Promise<void> {
    const windowScreen = window.screen;

    if (!windowScreen) {
        return;
    }

    // If the standard lock method exists, invoke it. If it fails, it will throw a DOMException.
    if (windowScreen.orientation?.lock) {
        windowScreen.orientation.lock(orientation);

        return;
    }

    const legacyLockOrientation =
        windowScreen.lockOrientation?.bind(windowScreen) ??
        windowScreen.mozLockOrientation?.bind(windowScreen) ??
        windowScreen.msLockOrientation?.bind(windowScreen);

    if (!legacyLockOrientation) {
        throw new Error(
            'PlayerTech: No lockOrientation key, or vendor prefixed variant, found on window.screen'
        );
    } else if (legacyLockOrientation(orientation) === false) {
        throw new Error('PlayerTech: Failed to lock screen orientation');
    }
}

/**
 * Unlock the screen's orientation.
 *
 * @throws {@link DOMException} if orientation unlock via the standard API call fails
 * @throws {@link Error} if orientation unlock via a legacy API call fails
 */
export function unlockOrientation(): void {
    const windowScreen = window.screen;

    if (!windowScreen) {
        return;
    }

    // If the standard unlock method exists, invoke it. If it fails, it will throw a DOMException.
    if (windowScreen.orientation?.unlock) {
        windowScreen.orientation.unlock();

        return;
    }

    // Otherwise, get the legacy unlock method if it exists.
    const legacyUnlockOrientation =
        windowScreen.unlockOrientation?.bind(windowScreen) ??
        windowScreen.mozUnlockOrientation?.bind(windowScreen) ??
        windowScreen.msUnlockOrientation?.bind(windowScreen);

    // Call the legacy unlock method if it exists. If it returned false indicating failure, throw
    // an error, because window.screen.orientation.unlock() throws an error when it fails.
    if (legacyUnlockOrientation?.() === false) {
        throw new Error('PlayerTech: Failed to unlock screen orientation');
    }
}
