import {
    SCREEN_1920_DESKTOP,
    SCREEN_768_TABLET,
    SCREEN_PHONE,
} from '../../../../common/screen-sizes';

// eslint-disable-next-line import/prefer-default-export
export const TILE_DIMENSIONS_BY_VIEWPORT = {
    [SCREEN_PHONE]: {
        heightPx: 116,
        widthPx: 205,
        gapPx: 12,
    },
    [SCREEN_768_TABLET]: {
        heightPx: 129,
        widthPx: 227,
        gapPx: 13,
    },
    [SCREEN_1920_DESKTOP]: {
        heightPx: 159,
        widthPx: 282,
        gapPx: 15,
    },
};
