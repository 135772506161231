import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import {StreamotionWebPlayer} from '@fsa-streamotion/player-skin';
import noop from 'lodash/noop';

const PLAYER_CONTAINER_STYLE = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 5,
};

export default function PlayerMount({onMount, playbackData, playerInstance, setVideoElementAndPlayerTech}) {
    useEffect(() => {
        if (!playbackData) {
            return noop;
        }

        playerInstance.open().then(() => {
            playerInstance.setPlaybackData(playbackData);
            const playerTech = playerInstance.getPlayerTech();
            const {videoElement} = playerTech;

            setVideoElementAndPlayerTech({videoElement, playerTech});
        });

        return () => {
            if (playerInstance.isOpen) {
                playerInstance.close();
            }
        };
    }, [playerInstance, playbackData, setVideoElementAndPlayerTech]);

    return <div ref={onMount} style={PLAYER_CONTAINER_STYLE} className="sm-player-mount" />;
}

PlayerMount.displayName = 'PlayerMount';

PlayerMount.propTypes = {
    onMount: propTypes.func,
    playbackData: propTypes.object,
    playerInstance: propTypes.instanceOf(StreamotionWebPlayer)
};
